function permFilter(val){
  // "bmp/api/v1/client/xjkh
  let flag = false
  if(this.permUrl){
      let ind= this.permUrl.findIndex((item) => item == val)
      if(ind > -1){
          flag = true
      }
  }
  return flag
}
export default {permFilter} 