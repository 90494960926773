<template>
  <div class="main-div customerList" style="background:none;padding:0;">
    <div v-if="showFlag == 1" style="background:#fff;padding:24px;">
      <p class="main-title">客户组列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.name"
            clearable
            placeholder="请输入客户组名称"
          >
          </el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.levelId"
            clearable
            placeholder="全部评级"
          >
            <el-option
              v-for="item in configurationData.client_level_config"
              :key="item.level_id"
              :label="item.level_name"
              :value="item.level_id"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">查询</div>
          <div class="main-button main-button-first" @click="resetList()">重置</div>
        </div>
      </div>
      <div class="main-btnBox m-r-16">
        <div class="main-button" v-show="common.permFilter(`${common.php}v1/client/xjkh`)" @click="showFlag = 2">新建客户组</div>
        <!-- <div class="main-button" v-show="common.permFilter(`${common.php}v1/client/khjj`)">删除客户组</div>
        <div class="main-button" >导出客户组</div> -->
      </div>
      <div>
        <el-table v-loading="loading" ref="customerGroupTable" :data="customerGroupList" border height="550" style="width: 100%;">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop="name" label="客户组名称" show-overflow-tooltip width="250"></el-table-column>
          <el-table-column prop="levelName" label="客户评级" ></el-table-column>
          <el-table-column prop="clientCount" label="关联客户数"></el-table-column>
          <el-table-column label="可用余额（美元）" >
            <template slot-scope="scope">
              <span>{{common.toThousands(scope.row.availableBalance,2)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="授信额度（美元）">
            <template slot-scope="scope">
              <span>{{scope.row.creditLine ? common.toThousands(scope.row.creditLine,2) : '0.00'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="欠款金额（美元）" >
            <template slot-scope="scope">
              <span>{{common.toThousands(scope.row.owedAmount,2)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="操作" width="240">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="editGroup(scope.row)">编辑</span>
              <span class="main-tableToInfo" @click="deleteGroup(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue @currentChange="init" @currentSizeChange="sizeInit" :pageSize="ajaxDataSearch.pageSize" :total="ajaxDataSearch.totals" :firstPage="firstPage"/>
      </div>
    </div>
    <div v-if="showFlag == 2">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="showFlag=1;relatedInfoList=[];addRelationData={clientCompanyGroupName:'',creditClientCompanyId:'',clientCompanyIds:[]}">返回</el-button>
        <span>{{addRelationData.id ? '编辑' :'新建'}}客户组</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">基本信息</p>
        <el-form label-position="right" label-width="100px" size="small">
          <el-form-item label="名称" required>
            <el-input style="width:300px;" v-model="addRelationData.clientCompanyGroupName" maxlength="16" show-word-limit placeholder="请输入客户组名称"></el-input>
          </el-form-item>
          <el-form-item label="授信">
            <el-select :disabled="creditSelectList.length < 1" style="width:300px;" v-model="addRelationData.creditClientCompanyId">
              <el-option v-for="(item,key) in creditSelectList" :key="key" :value="item.client_company_id" :label="item.client_company_name + ' | ' + item.credit_line_value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">
          <span>分组客户</span>
          <el-button size="mini" type="primary" style="margin-left:15px;" @click="showAddmodal()">添加客户</el-button>
          <i style="font-size: 9px;color: #B2B6BC;margin-left:20px;">客户加入分组后，消耗、回款、欠款将被合并，广告账户的充值将统一使用分组余额进行处理。</i>
        </p>
        <el-table :data="relatedInfoList" :selectable="checkSelect" border style="width: 100%">
          <el-table-column prop="client_company_name" label="客户名称"></el-table-column>
          <el-table-column prop="level_name" label="评级"></el-table-column>
          <el-table-column label="可用余额（美元）">
            <template slot-scope="scope">
              {{scope.row.available_balance}}
            </template>
          </el-table-column>
          <el-table-column prop="credit_line_value" label="授信额度（美元）"></el-table-column>
          <el-table-column prop="owed_amount" label="欠款金额（美元）"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="deleteRelatedInfo(scope.row,scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="background:#fff;padding:24px;">
        <div class="info-row">
          <div class="info-cell w-80">
            <div class="info-text calc-2w">
              <button class="main-button r" @click="saveAddRelation()">保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-pop" v-show="showBatchAdd">
      <div class="mask"></div>
      <div class="center" style="width:1300px;">
        <div class="head">
          <span>添加分组客户 <i>列表只显示状态为“已通过”的客户</i></span>
          <i class="gongzuotai gztguanbi" @click="showBatchAdd=false;customerPostData.client_company_name=''"></i>
        </div>
        <div class="body" style="padding-right:0px;overflow:scroll;">
          <div style="display:flex;align-items:center;margin-bottom:10px">
            <div class="main-inputBox" style="margin-bottom:0;">
              <el-input v-model="customerPostData.client_company_name" placeholder="请输入客户名称"></el-input>
            </div>
            <div class="main-selectBox" style="margin-bottom:0;">
              <div class="main-button" @click="getCustomerList(1)">查询</div>
              <div class="main-button main-button-first" >重置</div>
            </div>
          </div>
          <el-table style="width:98%" ref="multipleTable" :data="customerList" tooltip-effect="dark" @selection-change="handleSelectionChange">
            <el-table-column type="selection" :selectable="isDisabled" width="55"></el-table-column>
            <el-table-column label="客户名称" prop="client_company_name" width="440"></el-table-column>
            <el-table-column label="评级" prop="level_name"></el-table-column>
            <el-table-column label="可用余额（美元）" prop="available_balance"></el-table-column>
            <el-table-column label="授信额度（美元）" prop="credit_line_value"></el-table-column>
            <el-table-column label="欠款余额（美元）" prop="owed_amount"></el-table-column>
            <el-table-column label="归属分组" prop="group_name"></el-table-column>
          </el-table>
          <pagination-vue @currentSizeChange="customerListSize" @currentChange="getCustomerList" :pageSize="customerPostData.page_num" :total="customerPostData.totals" />
        </div>
        <div class="foot">
          <button class="main-button l" @click="cancel();customerPostData.client_company_name = ''">取消</button>
          <button class="main-button r" @click="confirmSelect()">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import moment from "moment";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      loading: true,
      firstPage: 1,
      showFlag: 1,
      ajaxDataSearch: {
        name:"",
        levelId:"",
        pageNum:1,
        pageSize:15,
        totals:0
      },
      configurationData: {
        client_level_config:[]
      },
      creditSelectList:[],
      addRelationData:{
        clientCompanyGroupName:"",
        creditClientCompanyId:"",
        clientCompanyIds:[]
      },
      showBatchAdd:false,
      customerPostData: {
        status:2,
        client_company_name: "",
        page:1,
        page_num:8,
        totals:0
      },
      relatedInfoList:[],
      customerList:[],
      customerGroupList:[],
      relatedInfoListss:[],
    };
  },

  methods: {
    isDisabled(row){
      for (let index = 0; index < this.relatedInfoList.length; index++) {
        if (this.relatedInfoList[index].client_company_id == row.client_company_id) {
          return false
        }
      }
      return true;
    },
    editGroup(row){
      this.showFlag = 2;
      this.getGroupInfo(row);
    },
    getGroupInfo(row){
      this.axios("GET", `${this.$javaBaseURL}clientCompanyGroup/details`,{
        id:row.id
      }).then(res => {
        if (res.code == 1) {
          this.groupInfo = res.data
          this.addRelationData.clientCompanyGroupName = this.groupInfo.name
          this.relatedInfoList = this.groupInfo.groupClients.map(item => {
            return {
              client_company_name:item.clientCompanyName,
              client_company_id:item.clientCompanyId,
              level_name:item.levelName,
              available_balance:item.availableBalance ? item.availableBalance : "0.00",
              credit_line_value:item.creditLine ? item.creditLine : "0.00",
              owed_amount:item.owedAmount ? item.owedAmount : "0.00"
            }
          })
          this.creditSelectList = this.relatedInfoList.filter(item =>{
            if (item.level_name != '预付') {
              return item
            }
          })
          this.addRelationData.creditClientCompanyId = this.groupInfo.creditClientCompanyId
          this.addRelationData.id = this.groupInfo.id
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    deleteGroup(row){
      this.$confirm('分组删除后，客户之间将没有关联关系！', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios("POST", `${this.$javaBaseURL}clientCompanyGroup/delete`,{
            id:row.id
          }).then(res => {
            if (res.code == 1) {
              this.$message({ message: '删除成功', type: "success" });
              this.init(1)
            } else if (res.code == 2) {
              this.common.tokenExpired(res.msg);
            } else {
              this.$message({ message: res.msg, type: "warning" });
            }
          });
        }).catch(() => {
        });
    },
    cancel(){
      this.showBatchAdd = false;
    },
    saveAddRelation(){
      if (!this.addRelationData.clientCompanyGroupName) {
        this.$message({ message: '客户组名称不能为空', type: "warning" });        
        return;
      }
      this.addRelationData.clientCompanyIds = this.relatedInfoList.map(item=>{
        return item.client_company_id
      })
      if (this.addRelationData.id) {
        this.axios("POST", `${this.$javaBaseURL}clientCompanyGroup/update`,this.addRelationData).then(res => {
          if (res.code == 1) {
            this.showFlag = 1;
            this.init(1)
            this.$message({ message: '编辑成功', type: "success" });
            this.addRelationData = {
              clientCompanyGroupName:"",
              creditClientCompanyId:"",
              clientCompanyIds:[]
            }
            this.relatedInfoList = []
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }else{
        this.axios("POST", `${this.$javaBaseURL}clientCompanyGroup/add`,this.addRelationData).then(res => {
          if (res.code == 1) {
            this.showFlag = 1;
            this.init(1)
            this.$message({ message: '保存成功', type: "success" });
            this.addRelationData = {
              clientCompanyGroupName:"",
              creditClientCompanyId:"",
              clientCompanyIds:[]
            }
            this.relatedInfoList = []
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }
    },
    deleteRelatedInfo(row,index){
      this.relatedInfoList.splice(index,1)
      this.creditSelectList = this.relatedInfoList.filter(item =>{
        if (item.level_name != '预付') {
          return item
        }
      })
      this.addRelationData.creditClientCompanyId = this.creditSelectList.length > 0 ? this.creditSelectList[0].client_company_id : ""
    },
    confirmSelect(){
      this.relatedInfoList = this.relatedInfoList.concat(this.relatedInfoListss)
      this.showBatchAdd = false;
      this.creditSelectList = this.relatedInfoList.filter(item =>{
        if (item.level_name != '预付') {
          return item
        }
      })
      this.addRelationData.creditClientCompanyId = this.creditSelectList.length > 0 ? this.creditSelectList[0].client_company_id : ""
    },
    handleSelectionChange(val){
      this.relatedInfoListss = val;
    },
    showAddmodal(){
      this.showBatchAdd = true;
      this.getCustomerList(1)
    },
    customerListSize(size){
      this.customerPostData.page_num = size ? size : 15
      this.getCustomerList(1)
    },
    getCustomerList(page){
      this.customerPostData.page = page ? page : 1
      this.axios("get", `${this.$phpBaseURL}v1/client/lists`,this.customerPostData).then(res => {
        if (res.code == 1) {
          this.customerList = res.data.data;
          this.customerPostData.totals = res.data.total;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    checkSelect(row, index) {
      let isChecked = true;
      if (row.accountStatus == 1 || row.accountStatus == 4) {
        // 判断里面是否存在某个参数
        isChecked = true;
      } else {
        isChecked = false;
      }
      return isChecked;
    },
    formatTimes(event){
      this.newRebatePostData.starttime = this.newRebatePostData.times[0]
      this.newRebatePostData.endtime = this.newRebatePostData.times[1]
    },
    updateValue(event) {
      this.saveRelatedCom.finance_id = event;
    },
    changeInput() {
      var pattern = /^[1-9][0-9]*$/ // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(this.ajaxDataSearch.amount_start)) {
        // input 框绑定的内容为空
        this.ajaxDataSearch.amount_start = ''
      }
      if (!pattern.test(this.ajaxDataSearch.amount_end)) {
        // input 框绑定的内容为空
        this.ajaxDataSearch.amount_end = ''
      }
    },
    resetList(){
      this.ajaxDataSearch = {
        name:"",
        levelId:"",
        pageNum:1,
        pageSize:15,
        totals:0
      }
      this.init(1)
    },
    sizeInit(pageSize){
      this.loading = true;
      this.ajaxDataSearch.pageSize = pageSize ? pageSize : 15
      this.init(1)
    },
    init(page) {
      this.loading = true;
      this.firstPage = page ? page : 1;
      this.ajaxDataSearch.pageNum = page?page:1;
      clearTimeout(this.timer);
      this.axios("get", `${this.$javaBaseURL}clientCompanyGroup/list`,this.ajaxDataSearch).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.customerGroupList = res.data.list;
          this.ajaxDataSearch.totals = res.data.total;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      this.init(1);
    },
  },
  filters: {
    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回未提交";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    this.init();
    if (this.$route.query.id) {
      this.editGroup(this.$route.query)
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.common.getConfigData('client_level,project_principal,project_authorization_list').then((res)=>{
      this.configurationData = res.data
    }).catch(err => {
      this.$message({ message: err.msg, type: "warning" });
    })
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.m-r-16 {
  .main-button {
    margin-right: 16px;
  }
}

.tabel-operate{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(1, 172, 228, 1);
  line-height: 22px;
  cursor: pointer;
  margin-right: 8px;
}
.main-title .el-button--text{
  padding: 0 20px;
}

.main-selectBox .authperson {
  border: #01ace4 solid 1px;
  border-radius: 4px;
  padding: 0px 8px;
  margin-right: 16px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  box-sizing: border-box;
  color: #01ace4;
  margin-top: 4px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  // transition: all .3s;
}
.main-selectBox .authperson i {
  color: #adb3bc;
  font-size: 14px;
  position: absolute;
  right: -9px;
  top: -14px;
  cursor: pointer;
  display: none;
}
.main-selectBox .authperson:hover i {
  display: block !important;
}
</style>
<style lang="scss">
.company-trade {
  max-width: 400px !important;
  &.el-select-dropdown__item {
    min-height: 28px;
    line-height: 28px;
    margin-bottom: 10px;
    height: auto;
    word-break: break-all;
    white-space: normal;
  }
}
.customerList .main-pop .el-table .cell{
  white-space: normal!important;
}
</style>