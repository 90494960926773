<template>
  <div class="main-div">
    <div v-show="showFlag==1">
      <p class="main-title">供应商明细表</p>
      <div v-loading="loading">
        <el-table :data="list" border height="550" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop label="采购申请单单号" width="220" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.purchase_sn">{{scope.row.purchase_sn}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="创建日期" width="180">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.created_at">{{scope.row.created_at}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="申请部门" width="220" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.department_name"
              >{{scope.row.department_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="申请人" width="180">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.username">{{scope.row.username}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目名称" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_name"
              >{{scope.row.project_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目编号" width="220" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.project_sn">{{scope.row.project_sn}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="采购类型" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.purchase_type_config" :key="v.recid">
                <span
                  v-if="v.recid == scope.row.purchase_type"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="服务类型" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.service_type_array">
                <span
                  v-if="v.recid == scope.row.service_type"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="采购预算" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.purchase_budget"
              >{{scope.row.purchase_budget}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="采购金额" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.purchase_amount"
              >{{scope.row.purchase_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="节省成本" width="180">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.cost_saving">{{scope.row.cost_saving}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="供应商级别" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.supplier_level_array">
                <span
                  v-if="v.recid == scope.row.supplier_level"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="供应商名称" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.supplier_name"
              >{{scope.row.supplier_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="注册资金" width="180">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.reg_money">{{scope.row.reg_money}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="成立时间" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.establish_date"
              >{{scope.row.establish_date}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="人员规模" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.personnel_size_array">
                <span
                  v-if="v.recid == scope.row.scale"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="联系人" width="180" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.contact">{{scope.row.contact}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="联系电话" width="180">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.phone">{{scope.row.phone}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="邮箱" width="220">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.email">{{scope.row.email}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="统一信用代码证" width="220">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.credit_code">{{scope.row.credit_code}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="银行账户名称" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.bank_account_name"
              >{{scope.row.bank_account_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="银行账号" width="220">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.bank_account"
              >{{scope.row.bank_account}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="开户行" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.opening_bank"
              >{{scope.row.opening_bank}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="支行" width="180">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.subbranch">{{scope.row.subbranch}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="发票税率" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.invoice_tax_rate_list" :key="v.recid">
                <span
                  v-if="v.recid == scope.row.invoice_tax_rate"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="付款周期" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.payment_period_list" :key="v.recid">
                <span
                  v-if="v.recid == scope.row.payment_cycle"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="预付比例" width="180">
            <template slot-scope="scope">
              <div v-for="v in configurationData.advance_ratio_list" :key="v.recid">
                <span
                  v-if="v.recid == scope.row.prepay_proportion"
                  style="cursor: pointer"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="实际付款金额" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.payment_amount"
              >{{scope.row.payment_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="实际付款日期" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.payment_date"
              >{{scope.row.payment_date}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="付款进度" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.payment_progress"
              >{{scope.row.payment_progress}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目完成情况反馈" width="180">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_feedback"
              >{{scope.row.project_feedback}}</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag==2">
      <p class="main-title" style="display: inline-block">编号：{{info.number}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">创建时间：{{info.created_at}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">申请部门：{{info.department_name}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">申请人：{{info.username}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">供应商名称:</p>
            <div class="info-text calc-5w">{{info.name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-3w">联系人:</p>
            <div class="info-text calc-3w">{{info.contact}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">注册资金:</p>
            <div class="info-text calc-4w">{{info.reg_money}}万</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">联系电话:</p>
            <div class="info-text calc-4w">{{info.phone}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">成立时间:</p>
            <div class="info-text calc-4w">{{info.establish_date}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">邮箱:</p>
            <div class="info-text calc-2w">{{info.email}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">人员规模:</p>
            <div class="info-text calc-4w">{{info.scale_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">其他联系方式:</p>
            <div class="info-text calc-6w">{{info.other_phone}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">注册地址:</p>
            <div class="info-text calc-4w">{{info.reg_address}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-8w">统一社会信用代码:</p>
            <div class="info-text calc-8w">{{info.credit_code}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">实际工作地址:</p>
            <div class="info-text calc-6w">{{info.work_address}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">其他:</p>
            <div class="info-text calc-2w">{{info.other_desc}}</div>
          </div>
        </div>
      </div>

      <div class="main-title_1">服务内容</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">服务类型:</p>
            <div class="info-text calc-4w">{{info.service_type_names}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">服务客户清单:</p>
            <div class="info-text calc-6w">{{info.service_client_list}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">服务擅长领域:</p>
            <div class="info-text calc-6w">{{info.service_good}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-14w">案例及合作价格附件内容描述:</p>
            <div class="info-text calc-14w">{{info.service_desc}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">其他:</p>
            <div class="info-text calc-2w">{{info.service_other}}</div>
          </div>
        </div>

        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">附件:</p>
            <div class="info-text calc-6w">
              <el-table :data="info.files" style="width: 100%">
                <el-table-column prop label="序号" type="index" width="180"></el-table-column>
                <el-table-column prop="file_name" label="文件" width="180"></el-table-column>
                <el-table-column prop="file_size" label="文件大小" width="180"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="main-title_1">财务信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">银行账户名称:</p>
            <div class="info-text calc-6w">{{info.bank_account_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">发票税率:</p>
            <div class="info-text calc-4w">{{info.invoice_tax_rate_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">银行账号:</p>
            <div class="info-text calc-4w">{{info.bank_account}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">付款周期:</p>
            <div class="info-text calc-4w">{{info.payment_cycle_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-3w">开户行:</p>
            <div class="info-text calc-3w">{{info.opening_bank}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">垫资能力:</p>
            <div class="info-text calc-4w">{{info.fund_situation}}万</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-2w">支行:</p>
            <div class="info-text calc-2w">{{info.subbranch}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">预付比例:</p>
            <div class="info-text calc-4w">{{info.prepay_proportion_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">其他:</p>
            <div class="info-text calc-2w">{{info.bank_other_desc}}</div>
          </div>
        </div>
        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!--<p class="info-title t-6w"></p>-->
            <div class="info-text calc-6w">
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      page: 1,
      total: 1,
      ajaxDataSearch: {
        name: "", //	否	string	供应商名称
        username: "", //	否	string	创建人
        level: "", //	否	string	供应商等级主键id，下拉框中的key值
        status: "", //	否	int	状态1：待审核2：合作中3：冻结4：审核失败
        service_type: "", //	否	string	服务类型
        scale: "", //	否	string	人员规模主键id
        contract_created_at: ["", ""] //	否	string
      },
      levelOptions: [],
      /*statusOptions: [
                 /!*状态1：待审核2：合作中3：冻结4：审核失败*!/
                 {
                 value: 1,
                 label: '待审核'
                 }, {
                 value: 2,
                 label: '合作中'
                 },{
                 value: 3,
                 label: '冻结'
                 },{
                 value: 4,
                 label: '审核失败'
                 },
                 ],*/

      project_progressOptions: {
        1: "客户询价阶段",
        2: "客户确认开始执行"
      },
      purchase_typeOptions: {
        1: "单一来源采购",
        2: "直接采购",
        3: "多方比价",
        4: "招标"
      },
      statusOptions: {
        1: "紧急采购",
        2: "非紧急采购"
      },
      check_statusOptions: {
        1: "未提交",
        2: "待审批",
        3: "审批",
        4: "通过",
        5: "驳回未提交"
      },
      list: [
        {
          id: "1",
          dept_id: "5BC6D4DAE00000013362A86A83D1A373",
          applicant: "1",
          project_name: "aaaaa",
          project_sn: "32435",
          purchase_type: 1,
          service_type: null,
          purchase_budget: "",
          purchase_amount: "0.00",
          cost_saving: "",
          supplier_level: "",
          supplier_name: "",
          reg_money: 0,
          establish_date: "2020-04-21",
          scale: null,
          contact: "",
          phone: "",
          email: null,
          credit_code: null,
          bank_account_name: null,
          bank_account: "",
          opening_bank: "",
          subbranch: "",
          invoice_tax_rate: "",
          payment_cycle: "",
          prepay_proportion: 0,
          payment_amount: "",
          payment_date: "",
          payment_progress: "",
          project_feedback: "",
          remark: null,
          created_at: "2020-04-21 15:26:12",
          updated_at: "2020-04-21 15:26:14",
          username: "test",
          department_name: "久其金建交付一部（需停用）"
        }
      ],
      info: {
        id: 2,
        supplier_id: "7133D0E800000001F4FF73776DCFE541",
        number: "GYSSQ02220120200004",
        dept_id: "6F666DF4C00000017E45C85CF11A6756",
        applicant: "5AB1EE2680000001D05C302118DAFDCC",
        name: "123",
        contact: "刘",
        reg_money: "1000万",
        phone: "15501111111",
        establish_date: "2020-04-01",
        email: "ha@126.com",
        scale: "710A59AE000000012E3936E66DF8BE89",
        other_phone: "",
        reg_address: "北京东城",
        credit_code: "51360827356549264E",
        work_address: "亦庄",
        other_desc: "",
        status: 0,
        service_type: "710A546C40000001847A5033E1D120AE",
        service_client_list: "abc",
        service_good: "微信",
        service_desc: null,
        service_other: null,
        bank_account_name: "123",
        invoice_tax_rate: "710A5ED740000001143F939376B90026",
        bank_account: "1010101010101010",
        payment_cycle: "710A6092800000017EB7A730438B1C3F",
        opening_bank: "招商银行",
        subbranch: "招商银行一桩",
        fund_situation: 2,
        prepay_proportion: "710A627C6000000198CFFAB859E55135",
        bank_other_desc: "",
        created_at: "2020-05-07 15:52:23",
        updated_at: "2020-05-07 15:52:23",
        level: "710F4966400000019703C52C7A4CEA14",
        username: null,
        department_name: "数传国内供应商管理部",
        scale_name: "20-30人",
        service_type_names: "框架采购",
        invoice_tax_rate_name: "6%",
        payment_cycle_name: "60-90天",
        prepay_proportion_name: "100%",
        level_name: "合格",
        files: [
          {
            id: 58325,
            supplier_id: "7133D0E800000001F4FF73776DCFE541",
            accessory_id: "4EF2FD50EA88AB681033A4E00071604F",
            file_name: "灿烂星光&瑞意-小度年货节-内容营销服务协议.docx",
            file_size: null,
            created_at: "2020-05-07 16:02:41"
          }
        ]
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        invoice_tax_rate_list: [],
        payment_period_list: [],
        advance_ratio_list: [],
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ],
        purchase_type_config: []
      }
    };
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {
      type: "purchase_list,supplier_detail_list"
    }).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;
        this.init();
        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });
  },
  methods: {
      sizeInit(size){
        this.pageSize = size ? size : 15;
        this.init(1)
      },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);
      if (page) {
        this.page = page;
      }
      let offset = (this.page - 1) * this.pageSize;

      this.axios(
        "get",
        `${this.$phpBaseURL}v1/suppliers/supplierDetailed?offset=${offset}&page_num=${this.pageSize}`,
        {}
      ).then(res => {
        this.loading = false;
        //                        console.log(res.data)
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
          //                    this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      if (this.ajaxDataSearch.contract_created_at == null) {
        this.ajaxDataSearch.contract_created_at = ["", ""];
      }
      this.init();
    },
    /*详情*/
    getInfo(id) {
      this.axios("get", `${this.$phpBaseURL}v1/suppliers/info`, {
        id: id
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    //频次统计
    addStatistics() {
      this.value++;
      this.common.statisticsAdd("供应商明细表列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>