<template>
  <div class="main-div media" style="background: none; padding: 0">
    <div v-show="showFlag == 1" style="background: #fff; padding: 24px">
      <p class="main-title">广告账户信息</p>
      <div>
        <div class="main-inputBox" style="width: 455px !important">
          <el-input
            v-model="ajaxDataSearch.accounts"
            clearable
            placeholder="请输入广告账户ID或广告账户名称，支持通过英文“,”分隔输入多个"
          ></el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.accountStatus"
            clearable
            placeholder="请选择账户状态"
          >
            <el-option
              v-for="(item, k) in configurationData.account_status_config"
              :key="k | ltrimm"
              :label="item"
              :value="k | ltrimm"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.platform"
            clearable
            placeholder="请选择平台"
          >
            <el-option
              v-for="(item, k) in configurationData.network_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox" style="width: 400px !important">
          <el-input
            v-model="ajaxDataSearch.clientCompanyName"
            clearable
            placeholder="请输入客户公司名称"
          ></el-input>
        </div>
        <div class="main-inputBox daterange" style="width: 255px !important">
          <el-date-picker
            v-model="ajaxDataSearch.applicationTimeArrange_"
            @change="formatTimes"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            clearable
            start-placeholder="下户开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">查询</div>
          <div class="main-button main-button-first" @click="resetList()">
            重置
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div class="main-btnBox m-r-16">
          <div class="main-button" @click="relateCustomer(1)">关联客户</div>
          <div class="main-button" @click="bindBM()">绑定BM</div>
          <div class="main-button" @click="unbindBM()">解绑BM</div>
          <div class="main-button" @click="bindPixel()">绑定Pixel</div>
          <div class="main-button" @click="unbindPixel()">解绑Pixel</div>
          <div class="main-button" @click="setViolation()">设置违规</div>
          <div class="main-button" @click="disconnectViolation()">解除违规</div>
          <div class="main-button" @click="exportExl()">导出报表</div>
        </div>
        <el-button type="text" @click="editTableColumns('media')"
          >自定义列表项</el-button
        >
      </div>
      <div class="need-reTd" v-loading="loading">
        <el-table
          ref="listTable"
          @selection-change="listChangeSelect"
          :data="list"
          border
          height="550"
          style="width: 100%"
        >
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png" />
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column type="selection" fixed></el-table-column>
          <el-table-column
            prop="accountName"
            label="广告账户名称"
            width="280"
            fixed
          ></el-table-column>
          <el-table-column
            prop="accountId"
            label="广告账户ID"
            min-width="180"
            fixed
          ></el-table-column>

          <el-table-column
            label="状态"
            width="100"
            v-if="mediaTableColumnVals.accountStatus"
          >
            <template slot-scope="scope">
              <span :class="scope.row.accountStatus | statusClassFilter">
                {{ scope.row.accountStatus == 0 ? "取消授权" : "" }}
                {{ scope.row.accountStatus == 1 ? "正常" : "" }}
                {{ scope.row.accountStatus == 2 ? "关闭" : "" }}
                {{ scope.row.accountStatus == 3 ? "封户" : "" }}
                {{ scope.row.accountStatus == 4 ? "空户" : "" }}
                {{ scope.row.accountStatus == 5 ? "违规" : "" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="可用余额"
            width="100"
            v-if="mediaTableColumnVals.accountBalance"
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.accountLimit > 0 ? scope.row.accountBalance : "-"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="accountCost"
            label="累计消耗"
            width="100"
            v-if="mediaTableColumnVals.accountCost"
          ></el-table-column>
          <el-table-column
            prop
            label="媒体平台"
            width="100"
            v-if="mediaTableColumnVals.platform"
          >
            <template slot-scope="scope">{{
              configurationData.network_array[scope.row.platform]
            }}</template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            width="180"
            prop="clientCompanyName"
            label="所属客户"
            v-if="mediaTableColumnVals.clientCompanyName"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            width="180"
            prop="accountCompanyName"
            label="开户公司"
            v-if="mediaTableColumnVals.accountCompanyName"
          ></el-table-column>
          <el-table-column
            prop="openTime"
            label="下户时间"
            width="180"
            v-if="mediaTableColumnVals.openTime"
          ></el-table-column>
          <el-table-column
            prop="violationRemark"
            label="违规原因"
            width="180"
            v-if="mediaTableColumnVals.violationRemark"
          ></el-table-column>

          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                title="详情"
                @click="getinfo(scope.row)"
                >详情</span
              >
              <el-popover placement="bottom" width="100" trigger="hover">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                  "
                >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.platform == 1 &&
                      scope.row.accountStatus &&
                      scope.row.accountStatus > 0
                    "
                    title="清零"
                    @click="openCloseReason(scope.row, 1)"
                    >清零</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.accountStatus == 1 && scope.row.clientCompanyId
                    "
                    title="充值"
                    @click="clientCompanys(scope.row, 2)"
                    >充值</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.accountStatus == 1 &&
                      scope.row.accountBalance > 0.01 &&
                      scope.row.clientCompanyId
                    "
                    title="拨款"
                    @click="creditAllocate(scope.row)"
                    >拨款</span
                  >
                  <span
                    class="tabel-operate"
                    title="关联客户"
                    @click="relateCustomer(2, scope.row)"
                    >关联客户</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.platform == 1"
                    title="绑定BM"
                    @click="bindBM(scope.row)"
                    >绑定BM</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.platform == 1"
                    title="解绑BM"
                    @click="unbindBM(scope.row)"
                    >解绑BM</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.platform == 1"
                    title="绑定Pixel"
                    @click="bindPixel(scope.row)"
                    >绑定Pixel</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.platform == 1"
                    title="解绑Pixel"
                    @click="unbindPixel(scope.row)"
                    >解绑Pixel</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.platform == 1 && scope.row.accountStatus != 5
                    "
                    title="设置违规"
                    @click="setViolation(scope.row)"
                    >设置违规</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.platform == 1 && scope.row.accountStatus == 5
                    "
                    title="解除违规"
                    @click="disconnectViolation(scope.row)"
                    >解除违规</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.accountStatus == 1 &&
                      scope.row.accountBalance > 0.01 &&
                      scope.row.clientCompanyId
                    "
                    title="减款"
                    @click="reductionRebates(scope.row)"
                    >减款</span
                  >
                </div>
                <span class="tabel-operate wk-span" slot="reference"
                  >更多操作</span
                >
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="ajaxDataSearch.pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag == 2">
      <div
        v-if="editID == 2"
        style="background: #fff; padding: 24px; margin-bottom: 24px"
      >
        <p class="main-title">充值设定</p>
        <el-form label-width="180px" size="mini" style="width: 500px">
          <el-form-item label="选择客户">
            <el-select
              v-model="rechargeclientCompany"
              filterable
              clearable
              remote
              value-key="clientCompanyId"
              :remote-method="remoteMethod"
              placeholder="客户公司名称"
              @clear="clearSearch"
              @focus="focusCom"
              @change="clientCompanyChange"
            >
              <el-option
                v-for="(item, index) in clientCompanyList"
                :key="index"
                :label="item.clientCompanyName"
                :value="item"
              ></el-option>
              <div
                style="float: right; margin-right: 10px; padding-bottom: 10px"
              >
                <el-pagination
                  @current-change="getRelatedComList"
                  :current-page="projectPostData.pageNum"
                  :page-size="projectPostData.pageSize"
                  layout="total, prev, pager, next"
                  :total="projectPostData.alltotal"
                ></el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="账户可用余额">{{ clientBalance }}</el-form-item>
          <el-form-item label="本次充值方式">
            <el-radio v-model="rechargeradio" label="1">充值相同金额</el-radio>
            <el-radio v-model="rechargeradio" label="2">自定义金额</el-radio>
          </el-form-item>
          <el-form-item v-if="rechargeradio == '1'" label="单账户充值金额">
            <el-input
              v-model="singleRechargeNumber"
              @blur="projectAmounts()"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">
          <span>{{ editID == 2 ? "充值账户" : "广告账户" }}</span>
          <el-button
            size="mini"
            type="primary"
            style="margin-left: 15px"
            @click="showAddAccAlert(false)"
            >添加广告账户</el-button
          >
        </p>
        <el-table
          :data="paramsInfo"
          :selectable="checkSelect"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="accountName"
            label="账户名称"
          ></el-table-column>
          <el-table-column prop="accountId" label="账户ID"></el-table-column>
          <el-table-column
            v-if="editID == 1"
            prop="clientCompanyName"
            label="归属客户"
          ></el-table-column>
          <el-table-column v-if="rechargeradio == '2'" label="充值金额">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.rechargeAmount"
                @blur="projectAmounts(scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="deleteAccount(scope.row, scope.$index)"
                >删除账户</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="editID == 1"
        style="background: #fff; padding: 24px; margin-bottom: 24px"
      >
        <p class="main-title">备注信息</p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 20 }"
          placeholder="请填写广告账户清零原因"
          v-model="ramarkstext"
        ></el-input>
      </div>
      <div style="background: #fff; padding: 24px">
        <div class="info-row">
          <div class="info-cell w-80">
            <div class="info-text calc-2w">
              <button class="main-button l" @click="cancelChargeClear()">
                取消
              </button>
              <button class="main-button r" @click="add">保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showFlag == 3">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1"
          >返回</el-button
        >
        <span>账户详情</span>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">基本信息</p>
        <div class="infoBox">
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-6w">广告账户名称:</p>
              <div class="info-text calc-6w">{{ info.accountName }}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-6w">广告账户ID:</p>
              <div class="info-text calc-6w">{{ info.accountId }}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">归属平台:</p>
              <div class="info-text calc-4w">
                {{ info.platform | platform_name }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">账户状态:</p>
              <div class="info-text calc-4w">
                {{ info.accountStatus | statusClassFilter }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">归属客户:</p>
              <div class="info-text calc-4w">{{ info.clientCompanyName }}</div>
            </div>
            <div class="info-cell w-33" v-if="info.platform == 1">
              <p class="info-title t-7w">开户公司（中）:</p>
              <div class="info-text calc-7w">{{ info.accountCompanyName }}</div>
            </div>
            <div class="info-cell w-33" v-else>
              <p class="info-title t-7w">开户公司（英）:</p>
              <div class="info-text calc-7w">
                {{ info.accountCompanyEnglishName }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">申请时间:</p>
              <div class="info-text calc-4w">{{ info.applicationTime }}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">开通时间:</p>
              <div class="info-text calc-4w">{{ info.openTime }}</div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">负责销售:</p>
              <div class="info-text calc-4w">
                {{
                  info.sales && info.sales.length > 0
                    ? info.sales.toString()
                    : "-"
                }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">负责AM:</p>
              <div class="info-text calc-4w">
                {{ info.am && info.am.length > 0 ? info.am.toString() : "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">
          财务信息
          <el-button type="text" @click="viewFinanceDetails()"
            >查看详情</el-button
          >
        </p>
        <div class="info-row flex-box">
          <div class="info-cell w-33">
            <p class="info-title t-7w">可用余额(美元):</p>
            <div class="info-text calc-7w">
              {{ common.toThousands(info.accountBalance, 2) }}
            </div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">累计消耗(美元):</p>
            <div class="info-text calc-7w">
              {{ common.toThousands(info.costLifetime, 2) }}
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">关联IO信息</p>
        <div class="info-row flex-box flex-space-between">
          <el-table :data="info.ios">
            <el-table-column label="IO名称" prop="ioName"></el-table-column>
            <el-table-column label="启用时间范围">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.predicateStarttime && scope.row.predicateEndtime
                  "
                >
                  {{ scope.row.predicateStarttime }} 至
                  {{ scope.row.predicateEndtime }}
                </div>
                <div
                  v-if="
                    scope.row.predicateStarttime && !scope.row.predicateEndtime
                  "
                >
                  {{ scope.row.predicateStarttime }} 至 {{ "长期有效" }}
                </div>
                <div
                  v-if="
                    !scope.row.predicateStarttime && scope.row.predicateEndtime
                  "
                >
                  至 {{ scope.row.predicateEndtime }}
                </div>
                <div
                  v-if="
                    !scope.row.predicateStarttime && !scope.row.predicateEndtime
                  "
                >
                  至 {{ "长期有效" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <el-button type="text" @click="openDetail(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">绑定的BM</p>
        <el-table :data="detailBmList">
          <el-table-column label="BM编号" prop="bmId"></el-table-column>
          <el-table-column label="绑定时间" prop="bindedTime"></el-table-column>
          <el-table-column label="操作人" prop="applicant"></el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="BmListSizeInit"
          @currentChange="getBMlist"
          :pageSize="bmPostData.pageSize"
          :total="bmPostData.totals"
          :firstPage="bmPostData.pageNum"
        />
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">绑定的Pixel</p>
        <el-table :data="detailPixelList">
          <el-table-column label="Pixel编号" prop="pixelId"></el-table-column>
          <el-table-column label="操作人" prop="applicant"></el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="pixelSizeInit"
          @currentChange="getPixellist"
          :pageSize="pixelPostData.pageSize"
          :total="pixelPostData.totals"
          :firstPage="pixelPostData.pageNum"
        />
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">关键时间信息</p>
        <div class="info-row flex-box flex-space-between">
          <el-table :data="info.crucialInfos">
            <el-table-column label="时间" prop="time"></el-table-column>
            <el-table-column label="操作" prop="operate"></el-table-column>
            <el-table-column label="说明" prop="remark"></el-table-column>
            <el-table-column label="操作人">
              <template slot-scope="scope">
                {{ scope.row.operator ? scope.row.operator : "-" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">历史归属客户</p>
        <div class="info-row flex-box flex-space-between">
          <el-table :data="info.relationRecords">
            <el-table-column
              label="归属客户"
              prop="clientCompanyName"
            ></el-table-column>
            <el-table-column label="说明">
              <template slot-scope="scope"
                >{{ scope.row.startDate }} ~ {{ scope.row.endDate }}</template
              >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-show="showFlag == 4">
      <div
        style="
          background: #fff;
          padding: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 24px;
        "
      >
        <i class="el-icon-back" @click="showFlag = 3"></i>
        <p class="main-title" style="margin-bottom: 0; margin-left: 20px">
          财务信息详情
        </p>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">基本信息</p>
        <div class="infoBox">
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-6w">广告账户名称:</p>
              <div class="info-text calc-6w">{{ info.accountName }}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-6w">广告账户ID:</p>
              <div class="info-text calc-6w">{{ info.accountId }}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">归属平台:</p>
              <div class="info-text calc-4w">
                {{ info.platform | platform_name }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-7w">可用余额(美元):</p>
              <div class="info-text calc-7w">{{ info.accountBalance }}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-7w">累计消耗(美元):</p>
              <div class="info-text calc-7w">{{ info.costLifetime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <div class="main-title withLinkBtn flex-box">
          <p>账户记录</p>
          <el-select
            style="width: 200px; margin-left: 20px"
            size="small"
            v-model="financedetail.dataType"
            @change="dataTypeChange()"
          >
            <el-option label="消耗" :value="1"></el-option>
            <el-option label="充值" :value="2"></el-option>
            <el-option label="账户清零" :value="3"></el-option>
            <el-option label="拨款" :value="4"></el-option>
          </el-select>
        </div>
        <el-table :data="financeDetailList">
          <el-table-column label="时间" prop="dateTime"></el-table-column>
          <el-table-column label="金额" prop="amount"></el-table-column>
          <el-table-column label="操作类型">
            <template slot-scope="scope">
              <div>{{ scope.row.dataType | dataTpFilter }}</div>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="viewFinanceDetailsSize"
          @currentChange="viewFinanceDetails"
          :pageSize="financedetail.pageSize"
          :total="financedetail.totals"
          :firstPage="financedetail.pageNum"
        />
      </div>
    </div>
    <div v-show="showFlag == 5">
      <div class="detail_header" style="padding: 24px; background: #fff">
        <el-button
          size="small"
          icon="el-icon-back"
          @click="
            showFlag = 1;
            relatedInfoList = [];
          "
          >返回</el-button
        >
        <span>关联客户</span>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">基本信息</p>
        <el-form label-position="right" label-width="100px" size="small">
          <el-form-item label="归属客户" required>
            <el-select
              v-model="addRelationData.clientCompanyId"
              filterable
              clearable
              remote
              style="width: 300px"
              value-key="clientCompanyId"
              :remote-method="remoteMethod"
              placeholder="请选择客户"
              @clear="clearSearch"
              @focus="focusCom"
              @change="clientCompanyChange"
            >
              <el-option
                v-for="(item, index) in clientCompanyList"
                :key="index"
                :label="item.clientCompanyName"
                :value="item.clientCompanyId"
              ></el-option>
              <div
                style="float: right; margin-right: 10px; padding-bottom: 10px"
              >
                <el-pagination
                  @current-change="getRelatedComList"
                  :current-page="projectPostData.pageNum"
                  :page-size="projectPostData.pageSize"
                  layout="total, prev, pager, next"
                  :total="projectPostData.alltotal"
                ></el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="归属时间" required>
            <el-date-picker
              style="width: 300px"
              v-model="addRelationData.relationDate"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">
          <span>广告账户信息</span>
          <el-button
            size="mini"
            type="primary"
            style="margin-left: 15px"
            @click="showAddAccAlert(true)"
            >添加广告账户</el-button
          >
        </p>
        <el-table
          :data="relatedInfoList"
          :selectable="checkSelect"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="accountName"
            label="账户名称"
          ></el-table-column>
          <el-table-column prop="accountId" label="账户ID"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span :class="scope.row.accountStatus | statusClassFilter">
                {{ scope.row.accountStatus == 0 ? "取消授权" : "" }}
                {{ scope.row.accountStatus == 1 ? "正常" : "" }}
                {{ scope.row.accountStatus == 2 ? "关闭" : "" }}
                {{ scope.row.accountStatus == 3 ? "封户" : "" }}
                {{ scope.row.accountStatus == 4 ? "空户" : "" }}
                {{ scope.row.accountStatus == 5 ? "违规" : "" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="clientCompanyName"
            label="归属客户"
          ></el-table-column>
          <el-table-column
            prop="accountCompanyName"
            label="归属开户公司"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="deleteAddAcc(scope.row, scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="background: #fff; padding: 24px">
        <div class="info-row">
          <div class="info-cell w-80">
            <div class="info-text calc-2w">
              <button class="main-button r" @click="saveAddRelation()">
                保存
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 拨款 -->
    <div v-show="showFlag == 6">
      <div class="detail_header" style="padding: 24px; background: #fff">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1"
          >返回</el-button
        >
        <span>拨款</span>
      </div>
      <div style="padding: 24px; background: #fff">
        <el-form label-width="160px" size="mini" label-position="right">
          <el-form-item label="转出广告账户：">
            <span>{{ currentRow.accountName }}</span> |
            <span>{{ currentRow.accountId }}</span>
          </el-form-item>
          <el-form-item label="转出账户余额：">
            <span>{{ maxfundingBalance }}</span
            >美元
          </el-form-item>
          <el-form-item label="转入广告账户：">
            <el-select
              style="width: 300px"
              v-model="selectInaccountData.accountId"
              filterable
              remote
              :remote-method="accountremoteMethod"
              clearable
              placeholder="请选择广告账户"
            >
              <el-option
                v-for="item in selectInAccountList"
                :key="item.accountId"
                :label="item.accountName"
                :value="item.accountId"
              ></el-option>
              <div
                style="float: right; margin-right: 10px; padding-bottom: 10px"
              >
                <el-pagination
                  @current-change="getSelectInAccount"
                  :current-page="selectInaccountData.pageNum"
                  :page-size="selectInaccountData.pageSize"
                  layout="total, prev, pager, next"
                  :total="selectInaccountData.total"
                ></el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="转入金额：">
            <div>
              <el-input
                v-model="zhuanrujine"
                type="number"
                style="width: 300px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveBoKuan">立即转入</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 拨款 -->
    <div v-show="showFlag == 7">
      <div class="detail_header" style="padding: 24px; background: #fff">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1"
          >返回</el-button
        >
        <span
          >减款
          <i style="margin-left: 10px; font-size: 12px; color: #ccc"
            >预付客户减款金额将退回到客户可用余额，授信客户不退回</i
          ></span
        >
      </div>
      <div style="padding: 24px; background: #fff">
        <el-form label-width="160px" size="mini" label-position="right">
          <el-form-item label="转出广告账户：">
            <span>{{ currentRow.accountName }}</span> |
            <span>{{ currentRow.accountId }}</span>
          </el-form-item>
          <el-form-item label="转出账户余额：">
            <span>{{ maxfundingBalance }}</span
            >美元
          </el-form-item>
          <el-form-item label="减款金额：">
            <div>
              <el-input
                v-model="reduceJine"
                type="number"
                style="width: 300px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="减款原因：">
            <el-input
              v-model="reduceRemark"
              type="textarea"
              style="width: 300px"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="reduces()">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="main-pop" v-show="removeViolationDialog">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>解除违规 <i>解除违规需要输入解除原因</i></span>
          <i
            class="gongzuotai gztguanbi"
            @click="removeViolationDialog = false"
          ></i>
        </div>
        <div class="body" style="padding-right: 0px; overflow: scroll">
          <el-form label-position="left" label-width="100px">
            <el-form-item label="解除原因：" required>
              <el-input
                style="width: 340px"
                type="textarea"
                placeholder="请输入解除违规的原因，最多支持20个字"
                v-model="removeViolationRemark"
                maxlength="20"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot">
          <button
            class="main-button l"
            @click="
              removeViolationDialog = false;
              removeViolationRemark = '';
            "
          >
            取消
          </button>
          <button class="main-button r" @click="removeVioHandle()">确定</button>
        </div>
      </div>
    </div>
    <!-- 添加广告账户 -->
    <div class="main-pop" v-show="showBatchAdd">
      <div class="mask"></div>
      <div class="center" style="width: 900px">
        <div class="head">
          <span>添加广告账户</span>
          <i class="gongzuotai gztguanbi" @click="showBatchAdd = false"></i>
        </div>
        <div class="body" style="padding-right: 0px; overflow: scroll">
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <div class="main-inputBox" style="width: 420px !important">
              <el-input
                v-model="alertGetAccList.accounts"
                clearable
                placeholder="请输入账户名称或账户ID，支持用英文“,”分隔输入多个"
              ></el-input>
            </div>
            <div class="main-inputBox" v-if="editID == 1">
              <!-- <el-select placeholder="请选择开户公司" filterable allow-create default-first-option v-model="alertGetAccList.accountCompanyId">
                <el-option v-for="item in accountListOptions" :key="item.accountCompanyId" :label="item.accountCompanyName" :value="item.accountCompanyId"></el-option>
              </el-select>-->
              <el-select
                v-model="alertGetAccList.clientCompanyId"
                filterable
                clearable
                remote
                :remote-method="remoteMethod"
                placeholder="客户公司名称"
                @focus="clientCompanyfocus"
                @change="clientCompanyChange"
              >
                <el-option
                  v-for="(item, index) in clientCompanyList"
                  :key="index"
                  :label="item.clientCompanyName"
                  :value="item.clientCompanyId"
                ></el-option>
                <div
                  style="float: right; margin-right: 10px; padding-bottom: 10px"
                >
                  <el-pagination
                    @current-change="getRelatedComList"
                    :current-page="projectPostData.pageNum"
                    :page-size="projectPostData.pageSize"
                    layout="total, prev, pager, next"
                    :total="projectPostData.alltotal"
                  ></el-pagination>
                </div>
              </el-select>
            </div>
            <div class="main-selectBox">
              <div class="main-button" @click="enterSearch(alertGetAccList)">
                查询
              </div>
              <div class="main-button main-button-first" @click="resetList1()">
                重置
              </div>
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="fbzhtableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              :selectable="isDisabled"
              width="55"
            ></el-table-column>
            <el-table-column
              label="账户名称"
              prop="accountName"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="账户ID" prop="accountId"></el-table-column>
            <el-table-column
              label="客户公司"
              prop="clientCompanyName"
            ></el-table-column>
          </el-table>
          <pagination-vue
            :isSevenPagin="true"
            @currentSizeChange="getAccListSize"
            @currentChange="getAccList"
            :pageSize="alertGetAccList.pageSize"
            :total="alertGetAccList.total"
            :firstPage="firstPage"
          />
        </div>
        <div class="foot">
          <button class="main-button l" @click="showBatchAdd = false">
            取消
          </button>
          <button class="main-button r" @click="subfbzh">确定</button>
        </div>
      </div>
    </div>
    <el-dialog title="无上限Facebook广告账户" :visible.sync="noLimitDialog">
      <div style="max-height: 500px; overflow: scroll">
        <el-table :data="noLimitList">
          <el-table-column
            property="accountId"
            label="账户ID"
          ></el-table-column>
          <el-table-column label="平台">
            <template slot-scope="scope">
              {{ scope.row.platform | platform_name }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination-vue
        @currentSizeChange="getQueryNoLimitSizeInit"
        @currentChange="getQueryNoLimit"
        :pageSize="noLimitQuery.pageSize"
        :total="noLimitQuery.total"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeNolimitDialog()">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="自定义列表项" :visible.sync="editTableColumnDialog">
      <el-table
        size="small"
        :data="mediaTableColumns"
        style="width: 96%; margin: 0 auto"
      >
        <el-table-column label="列表项">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="是否展示">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">不可修改</span>
            <el-switch v-else v-model="scope.row.value"></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editTableColumnDialog = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="hideColumns()"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import wx from "@/assets/img/wx.png";
import yl from "@/assets/img/yl.png";
import zfb from "@/assets/img/zfb.png";

export default {
  name: "",
  components: {
    paginationVue,
  },
  data() {
    return {
      reduceJine: "",
      reduceRemark: "",
      noLimitList: [],
      noLimitDialog: false,
      removeViolationRemark: "",
      removeViolationDialog: false,
      zhuanrujine: "",
      selectInAccountList: [],
      selectInaccountData: {
        clientCompanyId: "",
        accountId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      maxfundingBalance: "0.00",
      editTableColumnDialog: false,
      mediaTableColumnVals: {
        accountName: true,
        accountId: true,
        accountStatus: true,
        accountBalance: true,
        accountCost: true,
        platform: true,
        clientCompanyName: true,
        accountCompanyName: true,
        openTime: true,
        violationRemark: true,
      },
      mediaTableColumns: [
        {
          id: "accountName",
          name: "广告账户名称",
          edit: false,
          value: false,
        },
        {
          id: "accountId",
          name: "广告账户ID",
          edit: false,
          value: false,
        },
        {
          id: "accountStatus",
          name: "状态",
          edit: true,
          value: true,
        },
        {
          id: "accountBalance",
          name: "可用余额",
          edit: true,
          value: true,
        },
        {
          id: "accountCost",
          name: "累计消耗",
          edit: true,
          value: true,
        },
        {
          id: "platform",
          name: "媒体平台",
          edit: true,
          value: true,
        },
        {
          id: "clientCompanyName",
          name: "所属客户",
          edit: true,
          value: true,
        },
        {
          id: "accountCompanyName",
          name: "开户公司",
          edit: true,
          value: true,
        },
        {
          id: "openTime",
          name: "下户时间",
          edit: true,
          value: true,
        },
        {
          id: "violationRemark",
          name: "违规原因",
          edit: true,
          value: true,
        },
      ],
      viewIOs: false,
      relatedInfoList: [],
      addRelationData: {
        clientCompanyId: "",
        relationDate: "",
        accountIds: [],
      },
      selectedRows: [],
      rechargeclientCompany: {},
      ramarkstext: "",
      currentRow: {},
      rechargeradio: "1",
      rechargeclientCompanyId: "",
      singleRechargeNumber: "",
      clientBalance: 0,
      fbzhtableData: [],
      multipleSelection: [],
      showBatchAdd: false,
      bigOutAmount: "",
      inIDs: [],
      showAppropriationrow: {
        projectName: "",
        accountId: "",
      },
      firstPage: 1,
      loading: true,
      visible: false,
      istrue: false,
      token: "",
      msg: "",
      wx: wx,
      yl: yl,
      zfb: zfb,
      showFlag: 1,
      total: 1,
      pageSizeClient: 10, //客户下拉
      pageNumClient: 1, //客户下拉
      ajaxDataSearch: {
        accounts: "",
        accountCompanyId: "",
        clientCompanyName: "", //	否	string	客户公司名称
        accountCompanyName: "", //	否	string	账户公司名称
        platform: "", //	否	int	平台:1 facebook,2 twitter,3 google,5 tiktok
        accountStatus: "", //账户状态:1 正常,2 因空户暂停,3 代理商暂停,4 封户,5 无消耗
        openTimeStart: "",
        openTimeEnd: "",
        pageNum: 1,
        pageSize: 15,
        applicationTimeArrange_: "", //申请时间(格式:2020/04/16)
      },
      alertGetAccList: {
        clientCompanyId: "",
        accounts: "",
        total: 0,
        pageNum: 1,
        pageSize: 7,
      },
      accountStatus: "",
      paramsInfo: [],
      rechargeMediaAdd: {
        platform: "", //平台:1 facebook;2 twitter,3 google,5 tiktok
        accountId: "", //广告账户ID
        rechargeAmount: "", //充值金额,
        status: "",
      },
      pausePop: false,
      accountStatusOptions: [
        {
          value: "2",
          label: "因空户暂停",
        },
        {
          value: "3",
          label: "代理商暂停",
        },
      ],
      optionsTrade: [],
      options: [
        {
          value: "1",
          label: "授信充值",
        },
      ],
      optionsStatus: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "审核失败",
        },
      ],
      closeReasonOptions: [
        {
          value: 1,
          label: "客户要求",
        },
        {
          value: 2,
          label: "账户违规",
        },
        {
          value: 3,
          label: "与客户解除合作",
        },
      ],
      list: [],
      clientCompanyList: [],
      accountCompanyList: [],
      projectList: [],
      accountList: [],
      creditAmount: "",
      accountPlatformName: "",
      accountPlatform: "",
      accountCost: "",
      info: {
        basic_data: {
          project_numbering: "vrg34gtrht",
          project_id: "wefew43yt45h",
          project_name: "cdcdcd",
          business_type: 1,
          project_manager_id: "4F81D19F600000011924017E51357265",
          approval_status: 0,
          status: 0,
          sales_id: "4F81D19F600000011924017E51357265",
          project_created_at: "2020-04-23 16:33:18",
          cycle_start: "2020-04-23",
          cycle_end: "2020-04-23",
          applicant_id: "4F81D19F600000011924017E51357265",
          remark: "cscs",
          customer_product_type: "vsvs",
          project_overview: "sssss",
          date: "2020-04-23",
          estimated_income: "0.00",
          estimated_cost: "0.00",
          estimated_outsourcing_cost: "0.00",
          project_cost: "0.00",
          estimated_gross_profit: "0.00",
          estimated_gross_margin: "0.00",
          estimated_taxe: "0.00",
          estimated_after_tax: "0.00",
          amount_income: "0.00",
          cost_amount: "0.00",
          paid_outgoing_amount: "0.00",
          settlement_amount_received: "0.00",
          change_ratio: "0.00",
          client_company_name: "宇宙company",
          industry_name:
            "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等",
          contract_name: null,
          department_name: null,
          contact_name: null,
          project_manager_name: "王凯",
          sales_name: "王凯",
          applicant_name: "王凯",
        },
        project_member: {
          member_id: "dgerg45yrherg432",
          dep_id: "efdsvsdf2",
          department_name: "部门名称cs",
          user_name: "王玉影_cscs",
        },
        expected_pay_out_cost_details: [
          {
            purchasing_channel: "1",
            supplier_name: "1",
            business_content: "1",
            business_cycle: "1",
            purchase_amount: "1.00",
            remark: "1",
          },
        ],
        expected_project_cost_details: [
          {
            project_cost_content: "1",
            purchase_amount: "1.00",
            remark: "1",
          },
        ],
        project_schedule_happening: [
          {
            amount_income: "0.00",
            cost_amount: "0.00",
            paid_outgoing_amount: "0.00",
            settlement_amount_received: "0.00",
            change_ratio: "0.00",
          },
        ],
        project_tender_file: [
          {
            file_name: "cs",
            file_size: 0,
          },
        ],
        project_meeting_summary: [],
        project_purchase_acceptance_file: [],
        project_case_report_file: [],
        project_case_file: [],
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {},
        is_overdue_config: {},
        client_status_array: {},
        client_source_array: {},
        client_connection_relation_array: {},
        client_relation_company_array: {},
        is_contain_config: {},
        platform_array: {},
        contact_status_array: {},
        gender_array: {},
        contract_status_array: {},
        business_type_array: {},
        contract_type_array: {},
        contract_subdivision_array: {},
        is_guarantee_archive_prepaid_array: {},
        project_status_array: {},
        project_approval_status: {},
        file_upload_status: {},
        platform_name_array: {},
        return_point_ratio_accuracy_array: {},
        departments_data: [],
        industry_data: [],
      },
      editStatusPop: false,
      project_id: "",
      project_status: "",
      openZhPop: false,
      radio: "1",
      editingProObj: {
        project_id: "fwef2fre",
        project_name: "今日头条-cscs",
        business_type: 1,
        project_manager_id: "dgerg45yrherg432",
        approval_status: 1,
        status: 1,
        sales_id: "dgerg45yrherg432",
        project_created_at: "2020-04-20 16:31:44",
        project_cycle: "",
        applicant_id: "dgerg45yrherg432",
        remark: "cs",
        client_company_name: "client_company--cscs",
        level_id: "耗费iuwehiw76gfie",
        level_consume_value: "一事一议",
        industry_name: "cs",
        contract_name: "合同名称cs",
        contract_id: "扫地yiewbieb",
        contract_start: "2020-04-16 16:50:35",
        contract_end: "2020-04-16 16:50:35",
        name: "cs",
        contact_name: "contact_name--cscs",
        status_name: "正常",
        approval_status_name: "",
        business_type_name: "整合营销",
        project_manager_name: "王玉影_cscs",
        sales_name: "王玉影_cscs",
        applicant_name: "王玉影_cscs",
        is_change: "1",
        project_numbering: "dsifh8273yfgf",
      },
      accountInfo: {
        account_info: [
          {
            account: "这是平台账号1",
            max_number_accounts: 0,
            daily_account_opening_limit: 0,
            is_prepaid_recharge: 0,
            credit_amount: "",
            distribution_credit_amount: "0.00",
            sales_id: "",
            front_end_id: "",
            am_id: "",
            optimizer_id: "",
            contract_start_time: null,
            contract_end_time: null,
            email_address: "",
          },
        ],
        project_principal: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯",
          },
        ],
        sales_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯",
          },
        ],
        front_end_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯",
          },
        ],
        am_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯",
          },
        ],
        optimizer_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯",
          },
        ],
      },
      ajaxDataOpen: {
        project_id: "", //	是	string	项目ID；如：968485f47cc0b7ac7cf36d4868689866
        account: "", //	是	string	账户；如：36d4868689866
        account_password: "", //	是	string	密码；如：968485f47cc0
        max_number_accounts: "", //	是	int	最大开户数量；如：1
        daily_account_opening_limit: "", //	是	int	每日开户上限；如：1
        is_prepaid_recharge: "1", //	是	int	是否是预付；如：1
        credit_amount: "", //	是	int	客户可用授信额度；如：1
        distribution_credit_amount: "", //	是	int	分配授信额度；如：1
        contract_id: "", //	是	string	合同ID；如：968485f47cc0b7ac7cf36d4868689866
        contract_start_time: "", //	是	string	合同有效期开始时间；如：2020-04-28
        contract_end_time: "", //	是	string	合同有效期结束时间；如：2020-04-28
        sales_id: "", //	是	int	销售ID；如：1
        front_end_id: "", //	是	int	前端ID；如：1
        am_id: "", //	是	int	AMID；如：1
        optimizer_id: "", //	是	int	优化师ID；如：1
        email_address: "", //	是	string	邮箱地址；如：9023878237@qq.com
      },
      doPauseId: "",
      editZhPop: false,

      uploadAction: `${this.$javaBaseURL}accountMedia/accountImport`,
      uploadHeaders: {
        "Content-Type": "multipart/form-data",
        token: this.token,
      },
      connectFormData: {
        //关联开户公司
        clientCompanyId: "",
        projectId: "",
        accountCompanyId: "",
      },
      clientCompanyChangeOptionsLoading: false,
      clientCompanyChangeOptions: [], //客户公司下拉
      projectOptions: [], //项目列表下拉
      accountListOptions: [], //开户公司下拉
      selectRow: "", //关联账户选中的行
      connectRule: {
        projectId: [
          { required: true, message: "请选择项目", trigger: "change" },
        ],
        accountCompanyId: [
          { required: true, message: "请选择开户公司", trigger: "change" },
        ],
      },
      closeReasonForm: {
        closeReason: "", //关闭原因
      },
      closeReasonRules: {
        closeReason: [
          { required: true, message: "请选择关闭原因", trigger: "change" },
        ],
      },
      close_reason_config: [], //关闭原因---后台返回的筛选项，key是字符串的，列表返回的是数值类型
      addLoading: false, //下拉是否在异步加载中
      totalClient: 0, //客户公司下拉框数据
      clientCompanyNameSearch: "", //关联开户公司
      isClick: false, //关联开户公司弹框是否点击过li了
      showAppropriation: false,
      projectPostData: {
        alltotal: 0,
        pageSize: 15,
        pageNum: 1,
        clientCompanyName: "",
      },
      editID: 1,
      bmPostData: {
        accountId: "",
        pageNum: 1,
        pageSize: 15,
        totals: 0,
      },
      pixelPostData: {
        accountId: "",
        pageNum: 1,
        pageSize: 10,
        totals: 0,
      },
      detailBmList: [],
      detailPixelList: [],
      financedetail: {
        accountId: "",
        platform: "",
        dataType: "",
        pageNum: 1,
        pageSize: 15,
        totals: 0,
      },
      financeDetailList: [],
      whichOpe: true,
      errorNotify: {},
      noLimitQuery: {
        pageSize: 15,
        pageNum: 1,
        total: 0,
      },
      dialogArr: [],
    };
  },
  filters: {
    ltrimm(value) {
      return value.replace(/\s*/g, "");
    },
    statusClassFilter(value) {
      //申请状态:1 正常,2 关闭,3 封户, 4空户
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 3:
          m = "shenpi-bohui";
          break;
        case 4:
          m = "shenpi-yiguoqi";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
    platform_name(value) {
      let text = "";
      switch (value) {
        case 1:
          text = "Facebook";
          break;
        case 2:
          text = "Twitter";
          break;
        case 3:
          text = "Google";
          break;
        case 5:
          text = "Tiktok";
          break;
        default:
          break;
      }
      return text;
    },
    dataTpFilter(value) {
      let t = "";
      switch (value) {
        case 1:
          t = "消耗";
          break;
        case 2:
          t = "充值";
          break;
        case 3:
          t = "账户清零";
          break;
        case 4:
          t = "拨款";
          break;
        default:
          break;
      }
      return t;
    },
    closeReason(value) {
      //关闭原因：账户关闭原因:1 客户要求,2 账户违规,3 与客户解除合作
      let t = "";
      switch (value) {
        case 1:
          t = "客户要求";
          break;
        case 2:
          t = "账户违规";
          break;
        case 3:
          t = "与客户解除合作";
          break;
        default:
          break;
      }
      return t;
    },
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    this.uploadHeaders.token = this.token;

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };

    if (localStorage.getItem("mediaTableColumnVals")) {
      this.mediaTableColumnVals = JSON.parse(
        localStorage.getItem("mediaTableColumnVals")
      );
      this.mediaTableColumns = this.mediaTableColumns.map((item) => {
        item.value = this.mediaTableColumnVals[item.id];
        return item;
      });
    }
  },
  mounted() {
    this.common
      .getConfigData(
        "client_level,project_principal,project_authorization_list"
      )
      .then((res) => {
        this.configurationData = res.data;
      })
      .catch((err) => {
        this.$message({ message: err.msg, type: "warning" });
      });

    if (this.$route.params.from == "ioDetail") {
      this.showFlag = this.$route.params.showFlag;
      this.getinfo(this.$route.params);
    }
    if (this.$route.query.mediapostdata) {
      this.ajaxDataSearch = JSON.parse(this.$route.query.mediapostdata);
    }
    if (this.$route.query.from == "customer") {
      this.ajaxDataSearch.clientCompanyName = this.$route.query.client_company_name;
    }
    this.init();
    this.getQueryNoLimit();
  },
  watch: {
    "noLimitQuery.total": {
      handler(newV, oldV) {
        if (newV > 0) {
          const h = this.$createElement;
          this.$notify.closeAll();
          this.errorNotify = this.$notify.error({
            title: "提示",
            message: h("p", null, [
              h(
                "span",
                null,
                `有${this.noLimitQuery.total}个广告账户无消耗上限`
              ),
              h(
                "el-button",
                {
                  style: {
                    "margin-left": "20px",
                  },
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: this.viewNolimitList,
                  },
                },
                "查看"
              ),
            ]),
            showClose: false,
            offset: 0,
            duration: 0,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    reduces() {
      // if (Number(this.reduceJine) > Number(this.maxfundingBalance)) {
      //   this.$message({
      //     showClose: true,
      //     message: "转入金额应小于转出账户余额",
      //     type: "error",
      //   });
      //   return;
      // }
      this.operates([
        {
          platform: 1,
          accountId: this.currentRow.accountId,
          operateType: 7,
          operateContent: "",
          operateAmount: this.reduceJine,
          remark: this.reduceRemark,
        },
      ]);
    },
    reductionRebates(rows) {
      this.currentRow = rows;
      //获取 余额
      this.getMaxfundingBalance(rows.accountId);
      this.showFlag = 7;
      this.getSelectInAccount(1, "", this.currentRow.clientCompanyId);
    },
    exportExl() {
      let selAccounts = [];
      if (this.relatedInfoList.length > 0) {
        selAccounts = this.relatedInfoList.map((item) => {
          return item.accountId;
        });
      }
      console.log(this.ajaxDataSearch);
      window.open(
        `/bm/api/accountMedia/export?accountCompanyId=${this.ajaxDataSearch.accountCompanyId}&accountCompanyName=${this.ajaxDataSearch.accountCompanyName}&accountStatus=${this.ajaxDataSearch.accountStatus}&accounts=${this.ajaxDataSearch.accounts}&clientCompanyName=${this.ajaxDataSearch.clientCompanyName}&openTimeEnd=${this.ajaxDataSearch.openTimeEnd}&openTimeStart=${this.ajaxDataSearch.openTimeStart}&platform=${this.ajaxDataSearch.platform}&selAccounts=${selAccounts}`
      );
    },
    closeNolimitDialog() {
      this.noLimitDialog = false;
      this.getQueryNoLimit(1);
    },
    viewNolimitList() {
      this.noLimitDialog = true;
    },
    getQueryNoLimitSizeInit(size) {
      this.noLimitQuery.pageSize = size ? size : 15;
    },
    getQueryNoLimit(page) {
      this.noLimitQuery.pageNum = page ? page : 1;
      this.axios(
        "get",
        `${this.$javaBaseURL}accountMedia/queryNoLimit`,
        this.noLimitQuery
      ).then((res) => {
        if (res.code == 1) {
          this.noLimitQuery.total = res.data.total;
          this.noLimitList = res.data.list;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    removeVioHandle() {
      console.log(this.relatedInfoList);
      if (!this.removeViolationRemark) {
        this.$message({ message: "请输入解除违规原因", type: "warning" });
        return;
      }
      let postdata = {
        remark: this.removeViolationRemark,
        violations: [],
      };
      postdata.violations = this.relatedInfoList.map((item) => {
        return {
          accountId: item.accountId,
        };
      });
      const loading = this.$loading({
        lock: true,
        text: "Loading",
      });
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountMedia/delViolation`,
        postdata
      ).then((res) => {
        loading.close();
        if (res.code == 1) {
          this.init();
          this.removeViolationDialog = false;
          this.removeViolationRemark = "";
        } else {
          this.$message({ showClose: true, message: res.msg, type: "error" });
        }
      });
    },
    disconnectViolation(row) {
      if (row) {
        if (row.accountStatus != 5) {
          this.$message({ message: "包含了非违规的广告账户", type: "warning" });
          return;
        }
        this.relatedInfoList = [row];
        this.removeViolationDialog = true;
      } else {
        if (this.relatedInfoList.length == 0) {
          this.$message({ message: "最少要选中一条广告账户", type: "warning" });
        } else {
          let fl_ = true;
          try {
            this.relatedInfoList.forEach((item) => {
              if (item.accountStatus != 5) {
                this.$message({
                  message: "包含了非违规的广告账户",
                  type: "warning",
                });
                fl_ = false;
                throw new Error();
              }
            });
          } catch (error) {}
          if (fl_) {
            this.removeViolationDialog = true;
          }
        }
      }
    },
    setViolation(row) {
      let flag_ = true;
      console.log(this.relatedInfoList);
      try {
        if (this.relatedInfoList.length > 0) {
          this.relatedInfoList.forEach((item) => {
            if (item.platform != 1) {
              this.$message({
                message: "只能选择Facebook广告账户",
                type: "warning",
              });
              flag_ = false;
              throw new Error();
            }
          });
        }
      } catch (e) {}
      if (flag_) {
        this.$router.push({
          path: "/operations",
          query: {
            from: "media",
            showFlag: 9,
            mediapostdata: JSON.stringify(this.ajaxDataSearch),
          },
        });
        let arr = [];
        if (row) {
          arr = [row];
        } else {
          arr = this.relatedInfoList;
        }
        this.saveSelectedToStorage(JSON.stringify(arr));
      }
    },
    saveBoKuan() {
      if (!this.selectInaccountData.accountId) {
        this.$message({
          showClose: true,
          message: "请选择转入广告账户",
          type: "error",
        });
        return;
      }
      if (Number(this.zhuanrujine) > Number(this.maxfundingBalance)) {
        this.$message({
          showClose: true,
          message: "转入金额应小于转出账户余额",
          type: "error",
        });
        return;
      }
      this.operates([
        {
          platform: this.currentRow.platform,
          accountId: this.currentRow.accountId,
          operateType: 6,
          operateContent: this.selectInaccountData.accountId,
          operateAmount: this.zhuanrujine,
        },
      ]);
    },
    operates(param) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
      });
      console.log(param);
      this.axios("POST", `${this.$javaBaseURL}accountOperate/add`, param).then(
        (res) => {
          if (res.code == 1) {
            loading.close();
            this.$confirm("操作完成，您可以进行以下操作", "提示", {
              confirmButtonText: "继续操作",
              cancelButtonText: "查看操作结果",
              type: "warning",
            })
              .then(() => {
                this.selectInaccountData = {
                  clientCompanyId: "",
                  accountId: "",
                  pageNum: 1,
                  pageSize: 10,
                  total: 0,
                };
                this.zhuanrujine = "";
                this.reduceJine = "";
                this.reduceRemark = "";
              })
              .catch(() => {
                this.$router.push({
                  path: "/accountOpApply",
                });
              });
          } else {
            this.$message({ showClose: true, message: res.msg, type: "error" });
          }
        }
      );
    },
    accountremoteMethod(query) {
      this.getSelectInAccount(1, query, this.currentRow.clientCompanyId);
    },
    getSelectInAccount(page, query, clientCompanyId) {
      this.selectInaccountData.pageNum = page ? page : 1;
      this.selectInaccountData.accountId = query ? query : "";
      this.selectInaccountData.clientCompanyId = clientCompanyId
        ? clientCompanyId
        : "";
      this.axios(
        "get",
        `${this.$javaBaseURL}accountOperate/selectInAccount`,
        this.selectInaccountData
      ).then((res) => {
        if (res.code == 1) {
          this.selectInAccountList = res.data.list;
          this.selectInaccountData.total = res.data.total;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    creditAllocate(rows) {
      this.currentRow = rows;
      //获取 余额
      this.getMaxfundingBalance(rows.accountId);
      this.showFlag = 6;
      this.getSelectInAccount(1, "", this.currentRow.clientCompanyId);
    },
    getMaxfundingBalance(outAccountId) {
      this.axios("GET", `${this.$javaBaseURL}accountOperate/maxAmount`, {
        outAccountId: outAccountId,
      }).then((res) => {
        if (res.code == 1) {
          this.maxfundingBalance = res.data;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    saveSelectedToStorage(arr) {
      sessionStorage.setItem("selectedRow", arr);
    },
    bindBM(row) {
      this.$router.push({
        path: "/operations",
        query: {
          from: "media",
          operateType: 1,
          showFlag: 5,
          isBindBM: true,
          // selectedRow:JSON.stringify(row)
        },
      });
      let arr = [];
      if (row) {
        arr = [row];
      } else {
        arr = this.relatedInfoList;
      }
      this.saveSelectedToStorage(JSON.stringify(arr));
    },
    unbindBM(row) {
      this.$router.push({
        path: "/operations",
        query: {
          from: "media",
          operateType: 2,
          showFlag: 6,
          isBindBM: false,
        },
      });
      let arr = [];
      if (row) {
        arr = [row];
      } else {
        arr = this.relatedInfoList;
      }
      this.saveSelectedToStorage(JSON.stringify(arr));
    },
    bindPixel(row) {
      this.$router.push({
        path: "/operations",
        query: {
          from: "media",
          operateType: 3,
          showFlag: 7,
          isBindPixel: true,
        },
      });
      let arr = [];
      if (row) {
        arr = [row];
      } else {
        arr = this.relatedInfoList;
      }
      this.saveSelectedToStorage(JSON.stringify(arr));
    },
    unbindPixel(row) {
      this.$router.push({
        path: "/operations",
        query: {
          from: "media",
          operateType: 4,
          showFlag: 8,
          isBindPixel: false,
        },
      });
      let arr = [];
      if (row) {
        arr = [row];
      } else {
        arr = this.relatedInfoList;
      }
      this.saveSelectedToStorage(JSON.stringify(arr));
    },
    hideColumns() {
      this.mediaTableColumns.forEach((ele) => {
        this.mediaTableColumnVals[ele.id] = ele.value;
      });
      console.log(this.mediaTableColumnVals);
      localStorage.setItem(
        "mediaTableColumnVals",
        JSON.stringify(this.mediaTableColumnVals)
      );
      this.editTableColumnDialog = false;
    },
    editTableColumns(viewpage) {
      this.editTableColumnDialog = true;
    },
    isDisabled(row) {
      for (let index = 0; index < this.relatedInfoList.length; index++) {
        if (this.relatedInfoList[index].accountId == row.accountId) {
          return false;
        }
      }
      return true;
    },
    deleteAddAcc(row, index) {
      this.relatedInfoList.splice(index, 1);
    },
    saveAddRelation() {
      let flag = true;
      if (!this.addRelationData.clientCompanyId) {
        this.$message({ message: "请先选择归属客户", type: "warning" });
        return;
      }
      if (!this.addRelationData.relationDate) {
        this.$message({ message: "请先选择归属时间", type: "warning" });
        return;
      }
      this.relatedInfoList.forEach((item) => {
        console.log(
          item.clientCompanyId + " , " + this.addRelationData.clientCompanyId
        );
        if (item.clientCompanyId != this.addRelationData.clientCompanyId) {
          flag = false;
        }
      });
      this.relatedInfoList.forEach((item) => {
        this.addRelationData.accountIds.push(item.accountId);
      });
      if (flag) {
        this.saveRelationMet();
      } else {
        this.axios(
          "POST",
          `${this.$javaBaseURL}accountMedia/relationCheck`,
          this.addRelationData
        ).then((res) => {
          if (res.code == 1) {
            if (res.data) {
              this.$confirm("确定要将广告账户替换给新的客户吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.saveRelationMet();
                })
                .catch(() => {});
            } else {
              this.$message({ message: res.msg, type: "warning" });
            }
          } else if (res.code == 30005) {
            this.$confirm(
              "广告账户归属新客户的时间与当前归属IO时间冲突！",
              "提示",
              {
                confirmButtonText: "查看归属",
                cancelButtonText: "关闭",
                type: "warning",
              }
            )
              .then(() => {
                window.open("/#/errorIOs?id=" + res.data.errorIds.join(","));
              })
              .catch(() => {});
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }
    },
    saveRelationMet() {
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountMedia/addRelation`,
        this.addRelationData
      ).then((res) => {
        if (res.code == 1) {
          this.$message({ message: "关联成功", type: "success" });
          this.showFlag = 1;
          this.addRelationData = {
            clientCompanyId: "",
            relationDate: "",
            accountIds: [],
          };
          this.init();
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else if (res.code == 30005) {
          this.$confirm(
            "广告账户归属新客户的时间与当前归属IO时间冲突！",
            "提示",
            {
              confirmButtonText: "查看归属",
              cancelButtonText: "关闭",
              type: "warning",
            }
          )
            .then(() => {
              window.open("/#/errorIOs?id=" + res.data.errorIds.join(","));
            })
            .catch(() => {});
        } else if (res.code == 30006) {
          this.$message({ message: res.msg, type: "warning" });
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    relateCustomer(opeType, row) {
      if (opeType == 1) {
      } else {
        this.relatedInfoList.push(row);
      }
      this.showFlag = 5;
    },
    listChangeSelect(val) {
      this.relatedInfoList = val;
    },
    dataTypeChange() {
      this.viewFinanceDetails(1);
    },
    viewFinanceDetailsSize(size) {
      this.financedetail.pageSize = size ? size : 15;
      this.viewFinanceDetails(1);
    },
    viewFinanceDetails(page) {
      this.showFlag = 4;
      this.financedetail.pageNum = page ? page : 1;
      this.financedetail.accountId = this.info.accountId;
      this.financedetail.platform = this.info.platform;
      this.axios(
        "GET",
        `${this.$javaBaseURL}accountMedia/financeDetails`,
        this.financedetail
      ).then((res) => {
        if (res.code == 1) {
          this.financeDetailList = res.data.list;
          this.financedetail.pageNum = res.data.pageNum;
          this.financedetail.totals = res.data.total;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    openDetail(data) {
      this.$router.push({
        name: "ioDetail",
        query: {
          id: data.ioId,
          from: "media",
          param: JSON.stringify({
            showFlag: 3,
            id: this.info.id,
            accountId: this.info.accountId,
            from: "ioDetail",
          }),
        },
      });
    },
    focusCom() {
      if (this.clientCompanyList.length == 0) {
        this.projectPostData.clientCompanyName = "";
      }
      this.getRelatedComList();
    },
    clearSearch() {
      this.projectPostData.clientCompanyName = "";
      this.getRelatedComList();
    },
    projectAmounts(index) {
      if (index != null) {
        if (isNaN(parseFloat(this.paramsInfo[index].rechargeAmount))) {
          this.$set(this.paramsInfo[index], "rechargeAmount", 0.0);
        } else {
          this.$set(
            this.paramsInfo[index],
            "rechargeAmount",
            this.common.toThousands(this.paramsInfo[index].rechargeAmount, 2)
          );
        }
      } else {
        if (isNaN(parseFloat(this.singleRechargeNumber))) {
          this.singleRechargeNumber = 0.0;
        } else {
          this.singleRechargeNumber = this.common.toThousands(
            this.singleRechargeNumber,
            2
          );
        }
      }
    },
    cancelChargeClear() {
      this.paramsInfo = [];
      this.relatedInfoList = [];
      this.showFlag = 1;
      this.alertGetAccList.clientCompanyId = "";
      this.projectPostData.clientCompanyName = "";
      this.ramarkstext = "";
      this.$forceUpdate();
      this.getRelatedComList();
      if (this.errorNotify.id) {
        this.$notify.closeAll();
      }
    },
    clientCompanyfocus(event) {
      this.projectPostData.pageNum = 1;
      this.getRelatedComList();
    },
    getinfo(row) {
      this.bmPostData.accountId = row.accountId;
      this.pixelPostData.accountId = row.accountId;
      this.axios("GET", `${this.$javaBaseURL}accountMedia/details`, {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
      this.getBMlist();
      this.getPixellist();
      this.showFlag = 3;
    },
    BmListSizeInit(size) {
      this.bmPostData.pageSize = size ? size : 15;
      this.getBMlist(1);
    },
    getBMlist(page) {
      this.bmPostData.pageNum = page ? page : 1;
      this.axios(
        "GET",
        `${this.$javaBaseURL}accountMedia/bmList`,
        this.bmPostData
      ).then((res) => {
        if (res.code == 1) {
          this.detailBmList = res.data.list;
          this.bmPostData.pageNum = res.data.pageNum;
          this.bmPostData.totals = res.data.total;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    pixelSizeInit(size) {
      this.pixelPostData.pageSize = size ? size : 15;
      this.getPixellist(1);
    },
    getPixellist(page) {
      this.pixelPostData.pageNum = page ? page : 1;
      this.axios(
        "GET",
        `${this.$javaBaseURL}accountMedia/pixelList`,
        this.pixelPostData
      ).then((res) => {
        if (res.code == 1) {
          this.detailPixelList = res.data.list;
          this.pixelPostData.pageNum = res.data.pageNum;
          this.pixelPostData.totals = res.data.total;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    async openCloseReason(row, val) {
      this.editID = val;
      this.currentRow = row;
      let ress = await this.checkBlackList(row);
      if (ress.code == 1) {
        if (ress.data == 1) {
          this.$message({
            message: "该用户在有效黑名单中，暂时不能操作",
            type: "warning",
          });
          return false;
        } else {
          this.$router.push({
            path: "/accZero",
            query: {
              from: "media",
              currentAccount: JSON.stringify(this.currentRow),
              mediapostdata: JSON.stringify(this.ajaxDataSearch),
            },
          });
        }
      } else {
        this.$message({ message: ress.msg, type: "error" });
        return false;
      }
    },
    showAddAccAlert(bol) {
      if (!this.rechargeclientCompanyId && this.editID == 2) {
        this.$message({ message: "请先选择客户", type: "warning" });
        return;
      }
      this.whichOpe = bol;
      this.showBatchAdd = true;
      this.getAccList();
    },
    checkSelect(row, index) {
      let isChecked = true;
      if (row.accountStatus == 1 || row.accountStatus == 4) {
        // 判断里面是否存在某个参数
        isChecked = true;
      } else {
        isChecked = false;
      }
      return isChecked;
    },
    //充值申请 确定
    add() {
      if (this.editID == 1) {
        this.zeroClear();
        return;
      }
      if (!this.rechargeclientCompanyId) {
        this.$message({ message: "请选择客户", type: "warning" });
        return;
      }
      if (this.rechargeradio == 1) {
        if (!this.singleRechargeNumber) {
          this.$message({ message: "请输入单账户充值金额", type: "warning" });
          return;
        } else if (this.singleRechargeNumber > 99999999) {
          this.$message({
            message: "输入的充值金额较大，请重新输入",
            type: "warning",
          });
          return;
        } else {
          for (let i = 0; i < this.paramsInfo.length; i++) {
            this.paramsInfo[i].rechargeAmount = this.singleRechargeNumber;
          }
        }
      }
      if (this.rechargeradio == 2) {
        this.singleRechargeNumber = 0;
        for (let i = 0; i < this.paramsInfo.length; i++) {
          if (
            !this.paramsInfo[i].rechargeAmount ||
            Number(this.paramsInfo[i].rechargeAmount) < 1
          ) {
            this.$message({ message: "请填写完整充值金额", type: "warning" });
            return;
          }
        }
      }
      if (this.paramsInfo.length < 1) {
        this.$message({ message: "请选择广告账户", type: "warning" });
        return;
      } else {
        for (let i = 0; i < this.paramsInfo.length; i++) {
          this.paramsInfo[i].rechargeAmount = this.paramsInfo[i].rechargeAmount;
          this.paramsInfo[i].clientCompanyId = this.rechargeclientCompanyId;
        }
      }
      this.paramsInfo.map((i) => {
        i.rechargeAmount =
          i.rechargeAmount.indexOf(",") > 0
            ? i.rechargeAmount.replace(/\,/g, "")
            : i.rechargeAmount;
        i.rechargeAmount = parseFloat(i.rechargeAmount);
        return i;
      });
      const loading = this.$loading({
        lock: true,
        text: "Loading",
      });
      this.axios(
        "POST",
        `${this.$javaBaseURL}recharge/add`,
        this.paramsInfo
      ).then((res) => {
        if (res.code == 1) {
          this.$confirm("操作完成，您可以进行以下操作", "提示", {
            confirmButtonText: "继续操作",
            cancelButtonText: "查看操作结果",
            type: "warning",
          })
            .then(() => {
              // this.cancelChargeClear();
              this.rechargeradio = "1";
              this.singleRechargeNumber = "";
              this.paramsInfo = [];
            })
            .catch(() => {
              this.$router.push({
                name: "rechargeMedia",
              });
            });
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
        loading.close();
      });
    },
    zeroClear() {
      var arr = [];
      if (this.paramsInfo.length < 1) {
        this.$message({ message: "请选择广告账户", type: "warning" });
        return;
      }
      if (!this.ramarkstext) {
        this.$message({ message: "请填写清零原因", type: "warning" });
        return;
      }
      this.paramsInfo.forEach((item) => {
        arr.push({
          platform: 1,
          accountId: item.accountId,
          operateType: 5,
          remark: this.ramarkstext,
        });
      });
      this.$confirm("确定要清零吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios("POST", `${this.$javaBaseURL}accountOperate/add`, arr).then(
            (res) => {
              if (res.code == 1) {
                this.init();
                this.cancelChargeClear();
                this.$message({ message: "清零成功", type: "success" });
              } else if (res.code == 20001) {
                this.common.tokenExpired(res.msg);
              } else {
                this.$message({ message: res.msg, type: "warning" });
              }
            }
          );
        })
        .catch(() => {});
    },
    deleteAccount(row, index) {
      this.paramsInfo.splice(index, 1);
    },
    getAccListSize(size) {
      this.alertGetAccList.pageSize = size ? size : 7;
      this.getAccList(1);
    },
    getAccList(page) {
      if (this.editID == 1) {
        this.alertGetAccList.platform = 1;
      } else {
        this.alertGetAccList.clientCompanyId = this.rechargeclientCompanyId;
        this.alertGetAccList.platform = null;
      }
      page = page ? page : 1;
      this.init(page, this.alertGetAccList);
    },
    resetList1() {
      this.alertGetAccList = {
        clientCompanyId: "",
        accounts: "",
        total: 0,
        pageNum: 1,
        pageSize: 7,
      };
      this.getAccList();
    },
    // 重置
    resetList() {
      this.ajaxDataSearch = {
        accounts: "",
        clientCompanyName: "", //	否	string	客户公司名称
        accountCompanyName: "", //	否	string	账户公司名称
        platform: "", //	否	int	平台:1 facebook,2 twitter,3 google,5 tiktok
        accountStatus: "", //账户状态:1 正常,2 因空户暂停,3 代理商暂停,4 封户,5 无消耗
        openTimeStart: "",
        openTimeEnd: "",
        pageNum: 1,
        pageSize: 10,
        applicationTimeArrange_: "", //申请时间(格式:2020/04/16)
      };
      this.init();
    },
    // 获取可用余额
    clientCompanyChange() {
      if (this.errorNotify.id) {
        this.$notify.closeAll();
      }
      this.rechargeclientCompanyId = this.rechargeclientCompany.clientCompanyId;
      this.projectPostData.clientCompanyName =
        this.rechargeclientCompany.clientCompanyName;
      this.accountCompanyChangeList(this.rechargeclientCompanyId);
      if (this.showFlag == 2) {
        this.checkClientStatus(this.rechargeclientCompanyId);
      }
      this.axios("get", `${this.$javaBaseURL}clientCompany/getClientBalance`, {
        clientCompanyId: this.rechargeclientCompanyId,
      }).then((res) => {
        if (res.code == 1) {
          this.clientBalance = res.data.balance ? res.data.balance : 0;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    checkClientStatus(clientCompanyId) {
      this.axios("get", `${this.$phpBaseURL}v1/client/checkClientStatus`, {
        client_company_id: clientCompanyId,
      }).then((res) => {
        if (res.code == 1) {
          this.errorNotify = this.$notify.error({
            offset: 100,
            duration: 0,
            title: "客户异常",
            showClose: false,
            message: res.data.reason,
          });
          console.log(this.errorNotify);
        } else {
          if (this.errorNotify.id) {
            this.$notify.closeAll();
            this.errorNotify = {};
          }
        }
      });
    },
    // 充值
    async clientCompanys(row, val) {
      this.currentRow = row;
      this.editID = val;
      this.projectPostData.clientCompanyName = row.clientCompanyName
        ? row.clientCompanyName
        : "";
      this.rechargeclientCompanyId = row.clientCompanyId;
      this.paramsInfo.push({
        clientCompanyId: this.rechargeclientCompanyId,
        accountId: row.accountId,
        accountName: row.accountName,
        platform: row.platform,
        rechargeAmount: 0.0,
      });
      this.getRelatedComList();
      this.showFlag = 2;
      // this.checkClientStatus(row.clientCompanyId)
      // 检查 开户公司是否在黑名单
      // let ress = await this.checkBlackList(row);
      // if (ress.code == 1) {
      //   if (ress.data == 1) {
      //     this.$message({
      //       message: "该用户在有效黑名单中，暂时不能充值",
      //       type: "warning"
      //     });
      //     return false;
      //   }
      // } else {
      //   this.$message({message: ress.msg,type: "error"});
      //   return false;
      // }
    },
    remoteMethod(query) {
      this.projectPostData.clientCompanyName = query;
      this.getRelatedComList();
    },
    //客户公司下拉框
    getRelatedComList(page) {
      var that = this;
      this.projectPostData.pageNum = page ? page : 1;
      this.axios(
        "get",
        `${this.$javaBaseURL}clientCompany/selectAll`,
        this.projectPostData
      ).then((res) => {
        if (res.code == 1) {
          this.clientCompanyList = res.data.list;
          this.projectPostData.alltotal = res.data.total;
          if (this.projectPostData.alltotal == 1) {
            this.$nextTick(function () {
              that.rechargeclientCompany = that.clientCompanyList[0];
              that.rechargeclientCompanyId =
                that.clientCompanyList[0].clientCompanyId;
              that.projectPostData.clientCompanyName =
                that.clientCompanyList[0].clientCompanyName;
              that.clientCompanyChange();
            });
          }
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    formatTimes(event) {
      console.log(event);
      if (this.ajaxDataSearch.applicationTimeArrange_) {
        this.ajaxDataSearch.openTimeStart =
          this.ajaxDataSearch.applicationTimeArrange_[0];
        this.ajaxDataSearch.openTimeEnd =
          this.ajaxDataSearch.applicationTimeArrange_[1];
      } else {
        this.ajaxDataSearch.openTimeStart = "";
        this.ajaxDataSearch.openTimeEnd = "";
      }
    },
    sizeInit(size) {
      this.ajaxDataSearch.pageSize = size ? size : 15;
      this.init(1);
    },
    init(page, data) {
      var postdata = {};
      if (data) {
        data.pageNum = page ? page : 1;
      } else {
        this.ajaxDataSearch.pageNum = page ? page : 1;
        this.loading = true;
      }
      this.firstPage = page;
      this.axios(
        "get",
        `${this.$javaBaseURL}accountMedia/list`,
        data
          ? data
          : {
              accounts: this.ajaxDataSearch.accounts,
              accountCompanyId: this.ajaxDataSearch.accountCompanyId,
              clientCompanyName: this.ajaxDataSearch.clientCompanyName,
              accountCompanyName: this.ajaxDataSearch.accountCompanyName,
              platform: this.ajaxDataSearch.platform,
              accountStatus: this.ajaxDataSearch.accountStatus,
              openTimeStart: this.ajaxDataSearch.openTimeStart,
              openTimeEnd: this.ajaxDataSearch.openTimeEnd,
              pageNum: this.ajaxDataSearch.pageNum,
              pageSize: this.ajaxDataSearch.pageSize,
            }
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          if (data) {
            this.fbzhtableData = res.data.list;
            this.alertGetAccList.total = res.data.total;
            this.alertGetAccList.pageSize = res.data.pageSize;
            // if (this.whichOpe) { // 关联账户
            //   this.relatedInfoList.forEach((item)=>{
            //     this.fbzhtableData.forEach((row,index)=>{
            //       if (item.accountId == row.accountId) {
            //         this.fbzhtableData[index].disabled = true
            //       }else{
            //         this.fbzhtableData[index].disabled = false
            //       }
            //     })
            //   })
            //   console.log(this.fbzhtableData);
            // }
          } else {
            this.list = res.data.list;
            this.total = res.data.total;
            this.pageSize = res.data.pageSize;
          }
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch(data) {
      // if (data) {
      //   data.clientCompanyId = this.rechargeclientCompanyId
      // }
      this.firstPage = 1;
      this.init(1, data);
    },
    //根据客户公司ID获取开户公司列表
    accountCompanyChangeList(clientCompanyId) {
      console.log(clientCompanyId);

      this.axios(
        "get",
        `${this.$javaBaseURL}accountCompany/select?clientCompanyId=${clientCompanyId}`,
        {}
      ).then((res) => {
        if (res.code == 1) {
          this.accountListOptions = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //验证账号开户公司是否在黑名单中
    checkBlackList(obj) {
      return new Promise((resolve) => {
        var req_api = `accountCompany/selectBlacklistByName?accountCompanyName=${obj.accountCompanyName}`;
        this.axios("GET", `${this.$javaBaseURL}${req_api}`, {})
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            this.$message({
              showClose: true,
              message: err,
              type: "error",
            });
            console.log(err);
            reject(err);
          });
      });
    },
    getErrorStatusMsg(status) {
      var str = "";
      switch (status) {
        case 0:
          str = "账户不存在，请检查广告账户是否正确";
          break;
        case 2:
          str = "账户已关闭，不能执行账户操作";
          break;
        case 3:
          str = "账户已封户，不能执行账户操作";
          break;
        case 4:
          str = "账户已解除授权，不能执行账户操作";
          break;
        case 5:
          str = "开户公司在黑名单中，不能执行账户操作";
          break;
        case 6:
          str = "账户已清零，不能执行账户操作";
          break;
        case 7:
          str = "账户已关停，不能执行账户操作";
          break;
        default:
          break;
      }
      console.log(str);
      return str;
    },
    subfbzh() {
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach((elem) => {
          this.paramsInfo.push({
            clientCompanyId: this.rechargeclientCompanyId,
            accountId: elem.accountId,
            accountName: elem.accountName,
            platform: elem.platform,
            rechargeAmount: 0.0,
          });
          this.relatedInfoList.push(elem);
        });
        this.multipleSelection = [];
        this.showBatchAdd = false;
      } else {
        this.$message({ message: "请选择账户", type: "warning" });
      }
    },
    delfbzh() {
      var msg = "";
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach((ele) => {
          if (ele.errorStattus != 1) {
            msg += ele.accountId + ",";
          }
        });
        msg += "这些账户不符合批量操作的标准，是否删除这些数据？";
        this.$alert(msg, "确认", {
          confirmButtonText: "确定",
          callback: (action) => {
            for (let i = 0; i < this.multipleSelection.length; i++) {
              for (let k = 0; k < this.fbzhtableData.length; k++) {
                if (
                  this.multipleSelection[i].accountId ==
                  this.fbzhtableData[k].accountId
                ) {
                  this.fbzhtableData.splice(k, 1);
                }
              }
            }
          },
        });
      } else {
        this.$message({ message: "请选择账户", type: "warning" });
      }
    },
    getImportxlsx() {
      window.open(`${this.$javaBaseURL}download/Import.xlsx`);
    },
    fbzhbeforeUpload(file) {
      var that = this;
      let fd = new FormData();
      fd.append("file", file);
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.axios("POST", `${this.$javaBaseURL}batch/dataImport`, fd).then(
        (res) => {
          if (res.code == 1) {
            this.$message({ message: res.msg, type: "success" });
            this.fbzhtableData = res.data;
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getBatchList() {
      this.showBatchAdd = true;
    },
    getIDsList(id) {
      this.axios(
        "get",
        `${this.$javaBaseURL}accountMedia/selectAppropriationAccount`,
        {
          projectId: id,
          pageSize: 10000,
          pageNum: 1,
        }
      ).then((res) => {
        if (res.code == 1) {
          this.inIDs = res.data.list;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getMaxAmount(id) {
      this.axios("get", `${this.$javaBaseURL}appropriation/maxAmount`, {
        outAccountId: id,
      }).then((res) => {
        if (res.code == 1) {
          this.bigOutAmount = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // fb拨款申请
    appropriationAdd(row) {
      console.log(row);
      this.showAppropriationrow = row;
      this.showAppropriation = true;
      this.getMaxAmount(row.accountId);
      this.getIDsList(row.projectId);
    },
    download() {
      window.location.href = `${this.$javaBaseURL}download/Import.xlsx`;
    },
    beforeUpload(file) {
      let fd = new FormData();
      fd.append("file", file);
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountMedia/accountImport`,
        fd
      ).then((res) => {
        if (res.code == 1) {
          this.init();
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //频次统计
    addStatistics() {
      this.common.statisticsAdd("媒体平台账户列表", "1");
    },
  },
  //beforeDestroy 销毁前状态
  beforeDestroy() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tabel-operate {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(1, 172, 228, 1);
  line-height: 22px;
  cursor: pointer;
  margin-right: 8px;
}
.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}
.main-inputBox {
  width: 200px !important;
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}

.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style>
.el-table-column--selection .cell {
  display: flex !important;
  justify-content: center;
  padding: 0% !important;
}
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>