<template>
  <div class="main-div">
    <!-- 列表 start -->
    <div v-show="showFlag==1">
      <p class="main-title" style="margin-bottom: 24px">FB拨款申请记录表</p>
      <!-- 列表搜索项 start -->
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.outCompanyName"
            placeholder="请输入拨出方开户公司"
            clearable
            @change="enterSearch"
          >
            <i class="el-icon-search el-input__icon" slot="suffix" @click="enterSearch"></i>
          </el-input>
        </div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.inCompanyName"
            placeholder="请输入拨入方开户公司"
            clearable
            @change="enterSearch"
          >
            <i class="el-icon-search el-input__icon" slot="suffix" @click="enterSearch"></i>
          </el-input>
        </div>
        <!-- <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.platform"
            @change="enterSearch"
            clearable
            placeholder="请选择平台"
          >
            <el-option
              v-for="(item,k) in configurationData.platform_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div> -->
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.orderStatus"
            @change="enterSearch"
            clearable
            placeholder="订单状态"
          >
            <el-option
              v-for="(v,k) in configurationData.appropriation_order_status"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <!-- <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.type"
            @change="enterSearch"
            clearable
            placeholder="请选择拨款类型"
          >
            <el-option
              v-for="(v,k) in configurationData.appropriation_type"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div> -->
      </div>
      <!-- 列表搜索项 end -->
      <div v-loading="loading">
        <el-table :data="dataList" border style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop label="序号" type="index" width="80"></el-table-column>
          <el-table-column label="项目名称" prop="projectName" width="200"></el-table-column>
          <!-- <el-table-column prop label="平台" width="150">
            <template slot-scope="scope">{{configurationData.platform_array[scope.row.platform]}}</template>
          </el-table-column> -->
          <!-- <el-table-column prop="outCompanyName" label="拨出方开户公司" width="200"></el-table-column> -->
          <el-table-column prop="outAccountId" label="转出账户ID" width="220"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="inCompanyName" label="拨入方开户公司" width="200"></el-table-column> -->
          <el-table-column show-overflow-tooltip prop="inAccountId" label="转入账户ID" width="200"></el-table-column>
          <!-- <el-table-column prop label="拨款类型" width="150">
            <template slot-scope="scope">{{configurationData.appropriation_type[scope.row.type]}}</template>
          </el-table-column> -->
          <el-table-column prop="amount" label="转出金额" width="150"></el-table-column>
          <el-table-column prop label="订单状态" width="200">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :class="scope.row.orderStatus | statusClassFilter"
                :title="scope.row.status"
              >{{configurationData.appropriation_order_status[scope.row.orderStatus]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="applicationTime" label="申请时间" width="200"></el-table-column>
          <!-- <el-table-column prop="examineTime" label="审批时间" width="200"></el-table-column> -->
          <el-table-column prop="applicant" label="申请人" width="200"></el-table-column>
          <el-table-column prop label="操作" width="150">
            <template slot-scope="scope">
              <span
                v-show="scope.row.orderStatus==1 && common.permFilter(`${common.java}appropriation/details`)"
                class="main-tableToInfo"
                @click="showFlag=2;getInfo(scope.row.id)"
              >审批</span>
              <span class="main-tableToInfo" @click="showFlag=3;getInfo(scope.row.id)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <!-- 列表 end -->

    <!-- 审批页面 start -->
    <div v-show="showFlag==2">
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">拨款类型:</p>
            <div class="info-text calc-4w">{{configurationData.appropriation_type[info.type]}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-7w">拨出方开户公司:</p>
            <div class="info-text calc-7w">{{info.outCompanyName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">拨出方账户:</p>
            <div class="info-text calc-5w">{{info.outAccountId}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-7w">拨入方开户公司:</p>
            <div class="info-text calc-7w">{{info.inCompanyName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">拨入方账户:</p>
            <div class="info-text calc-5w">{{info.inAccountId}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">拨款金额:</p>
            <div class="info-text calc-5w">{{info.amount}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">申请时间:</p>
            <div class="info-text calc-5w">{{info.applicationTime}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">审批状态:</p>
            <div class="info-text calc-5w">{{info.outCompanyName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">备注:</p>
            <div class="info-text calc-5w">{{info.examineRemarks}}</div>
          </div>
        </div>

        <div class="info-row" style="display:inline-block;width:33%">
          <div class="info-cell info-cell-self">
            <p class="info-title t-5w">审批状态:</p>
            <el-select
              v-model="examineDatas.orderStatus"
              clearable
              placeholder="请选择审批状态"
              @change="dealSelect"
            >
              <el-option
                v-for="(item,index) in examineStatus"
                :key="index"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="info-row" style="display:inline-block;width:66%">
          <div class="info-cell info-cell-self" style="width:100%;">
            <p class="info-title" style="width:50px">备注:</p>
            <el-input
              v-model="examineDatas.examineRemarks"
              clearable
              placeholder="请输入备注"
              style="width:calc(100% - 50px)"
            ></el-input>
          </div>
        </div>
        <p class="split-line"></p>
        <section class="info-cell examin-history" style="width:100%">
          <label>申请历史:</label>
          <div style="display:block" class="history-wrap">
            <p class="history" v-for="item in info.examineRecords">
              <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
              <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
              <i>{{item.examineRemarks}}</i>
            </p>
          </div>
        </section>
        <!-- <section class="info-cell examin-history" style="width:100%;">
                <label>附件:</label>
                <p class="history">
                    <i style="width:160px;display:inline-block">mmm</i>
                    <div class="download-self main-button" >
                        <i class="gongzuotai gztxiazai"></i>
                            下载
                    </div>
                </p>
        </section>-->
        <div class="info-row">
          <div class="info-text calc-2w">
            <div class="main-button" @click="showFlag=1">返回</div>
            <div class="main-button" @click="examine()">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审批页面 end -->
    <!-- 以下是详情页面 -->
    <div v-show="showFlag==3">
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">项目名称:</p>
            <div class="info-text calc-4w">{{info.projectName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">拨款类型:</p>
            <div class="info-text calc-4w">{{configurationData.appropriation_type[info.type]}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-7w">拨出方开户公司:</p>
            <div class="info-text calc-7w">{{info.outCompanyName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">拨出方账户:</p>
            <div class="info-text calc-5w">{{info.outAccountId}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-7w">拨入方开户公司:</p>
            <div class="info-text calc-7w">{{info.inCompanyName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">拨入方账户:</p>
            <div class="info-text calc-5w">{{info.inAccountId}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">可拨款金额:</p>
            <div class="info-text calc-5w">{{info.outAccountBalance}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">拨款金额:</p>
            <div class="info-text calc-5w">{{info.amount}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">申请时间:</p>
            <div class="info-text calc-5w">{{info.applicationTime}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">审批状态:</p>
            <div class="info-text calc-5w">{{info.outCompanyName}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-3w">申请人:</p>
            <div class="info-text calc-5w">{{info.applicant}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">备注:</p>
            <div class="info-text calc-5w">{{info.examineRemarks}}</div>
          </div>
        </div>
        <p class="split-line"></p>
        <section class="info-cell examin-history" style="width:100%">
          <label>申请历史:</label>
          <div style="display:block" class="history-wrap">
            <p class="history" v-for="item in info.examineRecords">
              <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
              <i style="width:200px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
              <i>{{item.examineRemarks || item.examineError}}</i>
            </p>
          </div>
        </section>

        <div class="info-row">
          <div class="info-text calc-2w">
            <div class="main-button" @click="showFlag=1">返回</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 15,
      total: 100,
      ajaxDataSearch: {
        outCompanyName: "", //拨出方开户公司名称
        inCompanyName: "", //拨入方开户公司名称
        platform: "", //平台:1 facebook,2 twitter,3 google,5 tiktok
        orderStatus: "", //订单状态:1 待审批,2 处理中,3 拨款成功,4 拨款失败(拒绝拨款),5 操作失败(处理过程出现异常)
        type: "" //拨款类型:1 余额拨款,2 授信拨款
      },
      dataList: [],
      configurationData: {
        appropriation_type: {},
        appropriation_order_status: {},
        platform_array: {}
      },
      info: {},
      examineDatas: {
        id: "",
        orderStatus: "",
        examineRemarks: ""
      },
      //审批状态
      examineStatus: {
        3: "审批通过",
        4: "审批失败"
      }
    };
  },
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;

    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      if (res.code == 1) {
        this.configurationData = res.data;
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });
    this.init();

    this.timer = setTimeout(this.addStatistics, 5000);

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  filters: {
    statusClassFilter(value) {
      // 订单状态:1 待审批,2 处理中,3 拨款成功,4 拨款失败(拒绝拨款),5 操作失败(处理过程出现异常)
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-yikaihu";
          break;
        case 3:
          m = "shenpi-tongguo";
          break;
        case 4:
          m = "shenpi-bohui";
          break;
        case 5:
          m = "shenpi-yiguoqi";
          break;

        default:
          break;
      }
      return m;
    },
    examineFilter(val) {
      let text = "";
      switch (val) {
          case 1:
              text = "待审核";
              break;
          case 2:
              text = "处理中";
              break;
          case 3:
              text = "拨款成功";
              break;
          case 4:
              text = "拨款失败（拒绝拨款）";
              break;
          case 5:
              text = "操作失败(处理过程出现异常)";
              break;
      }
      return text;
    }
  },
  methods: {
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      let obj = this.ajaxDataSearch;
      obj.pageNum = page ? page : 1;
      obj.pageSize = 15;

      this.axios("get", `${this.$javaBaseURL}appropriation/list`, obj).then(
        res => {
          this.loading = false;
          if (res.code == 1) {
            this.dataList = res.data.list;
            this.total = res.data.total;
            this.pageSize = res.data.pageSize;
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    enterSearch() {
      this.firstPage = 1;
      this.init();
    },
    /*详情*/
    getInfo(id) {
      this.axios("GET", `${this.$javaBaseURL}appropriation/details`, {
        id: id
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data;
          this.examineDatas.id = this.info.id;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //审批提交
    examine() {
      if (!this.examineDatas.orderStatus) {
        this.$message({ message: "请选择审批状态", type: "warning" });
        return false;
      }
      var req_api = `appropriation/examine?id=${this.examineDatas.id}&orderStatus=${this.examineDatas.orderStatus}&examineRemarks=${this.examineDatas.examineRemarks}`;

      this.axios("POST", `${this.$javaBaseURL}${req_api}`, {}).then(res => {
        if (res.code == 1) {
          this.showFlag = 1;
          this.common.statisticsAdd("FB拨款审批审批", "2");
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }

        this.init();
      });
    },
    //处理下拉框数据 审批状态
    dealSelect(selVal) {
      this.examineDatas.orderStatus = selVal;
    },

    //频次统计
    addStatistics() {
      this.value++;
      this.common.statisticsAdd("拨款列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main-inputBox {
  width: 186px !important;
}

.info-cell-self {
  .el-select {
    width: auto;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input__suffix {
    top: 4px;
  }
}
</style>