<template>
  <div class="main-div accountOpApply" style="background:none;padding:0;">
    <div class="whitebg" v-show="showFlag==1">
      <p class="main-title" style="margin-bottom: 32px">Facebook操作列表</p>
      <div>
        <div class="main-inputBox" style="width:auto!important;display:inline-flex;">
          <el-select v-model="searchType" style="width:200px;" @change="searchTypeChange()">
            <el-option label="客户名称" :value="1"></el-option>
            <el-option label="开户公司名称（英文）" :value="2"></el-option>
            <el-option label="开户公司名称（中文）" :value="3"></el-option>
            <el-option label="广告账户名称" :value="4"></el-option>
            <el-option label="广告账户ID" :value="5"></el-option>
          </el-select>
          <el-input style="width:200px;" v-if="searchType == 1" v-model="ajaxDataSearch.clientCompanyName"></el-input>
          <el-input style="width:200px;" v-if="searchType == 2" v-model="ajaxDataSearch.accountCompanyEnglishName"></el-input>
          <el-input style="width:200px;" v-if="searchType == 3" v-model="ajaxDataSearch.accountCompanyName"></el-input>
          <el-input style="width:200px;" v-if="searchType == 4" v-model="ajaxDataSearch.accountName"></el-input>
          <el-input style="width:200px;" v-if="searchType == 5" v-model="ajaxDataSearch.accountId"></el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.operateType"
            clearable
            placeholder="全部操作"
          >
            <el-option
              v-for="(v,k) in configurationData.account_operation_type"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.examineStatus"
            clearable
            placeholder="请选择申请状态"
          >
            <el-option
              v-for="(v,k) in configurationData.account_approval_status_list"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <el-date-picker
          style="margin-right:20px;"
          v-model="searchTimeRange"
          @change="formatTimes"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="daterange"
          clearable
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        ></el-date-picker>
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">查询</div>
          <div class="main-button main-button-first" @click="resetList()">重置</div>
        </div>
      </div>
      <div style="margin-bottom:20px;">
        <div class="main-button" @click="bindBM()">绑定BM</div>
        <div class="main-button" @click="unbindBM()">解绑BM</div>
        <div class="main-button" @click="bindPixel()">绑定Pixel</div>
        <div class="main-button" @click="unbindPixel()">解绑Pixel</div>
      </div>
      <div v-loading="loading">
        <el-table :data="dataList" border style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop="accountName" label="广告账户" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="accountId" label="广告账户ID" width="160"></el-table-column>
          <el-table-column prop label="申请状态" width="150">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.examineStatus == 4" class="item" effect="dark" :content="scope.row.examineError" placement="top-start">
                <span :class="scope.row.examineStatus | statusClassFilter" :title="configurationData.account_approval_status_list[scope.row.examineStatus]">
                {{configurationData.account_approval_status_list[scope.row.examineStatus]}}
                </span>
              </el-tooltip>
              <span v-else :class="scope.row.examineStatus | statusClassFilter" :title="configurationData.account_approval_status_list[scope.row.examineStatus]">
                {{configurationData.account_approval_status_list[scope.row.examineStatus]}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop label="操作类型 | 备注"  show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.operateType == 6"> {{ configurationData.account_operation_type[scope.row.operateType]}} | 向{{scope.row.operateContent}}拨款{{scope.row.operateAmount}}</div>
              <div v-else>{{configurationData.account_operation_type[scope.row.operateType]}} | {{scope.row.operateContent}}</div>
            </template>
          </el-table-column>
          <el-table-column prop label="开户公司" width="220" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.accountCompanyName"
              >{{scope.row.accountCompanyName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="操作时间" width="180">
            <template slot-scope="scope">
              <span>{{scope.row.createdAt}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="操作" width="160">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="showFlag=3;getInfo(scope.row.id)">详情</span>
              <span class="main-tableToInfo" v-if="scope.row.examineStatus == 4" @click="resubmit(scope.row)">重新提交</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue @currentSizeChange="sizeInit" @currentChange="init" :pageSize="pageSize" :total="total" :firstPage="firstPage" />
      </div>
    </div>
    <!-- 拨款 -->
    <div v-show="showFlag==2">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1;selectInAccountList=[]">返回</el-button>
        <span>拨款</span>
      </div>
      <div style="padding:24px;background:#fff;">
        <el-form label-width="160px" size="mini" label-position="right">
          <el-form-item label="转出广告账户：">
            <span>{{currentRow.accountName}}</span> | <span>{{currentRow.accountId}}</span>
          </el-form-item>
          <el-form-item label="转出账户余额：">
            <span>{{maxfundingBalance}}</span>美元
          </el-form-item>
          <el-form-item label="转入广告账户：">
            <el-select
              style="width:300px"
              v-model="selectInaccountData.accountId"
              filterable
              remote
              :remote-method="accountremoteMethod"
              clearable
              placeholder="请选择广告账户"
            >
              <el-option v-for="item in selectInAccountList" :key="item.accountId" :label="item.accountName" :value="item.accountId"></el-option>
              <div style="float: right;margin-right:10px;padding-bottom: 10px">
                <el-pagination
                  @current-change="getSelectInAccount"
                  :current-page="selectInaccountData.pageNum"
                  :page-size="selectInaccountData.pageSize"
                  layout="total, prev, pager, next"
                  :total="selectInaccountData.total"
                ></el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="转入金额：">
            <div>
              <el-input v-model="zhuanrujine" type="number" style="width:300px" ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveBoKuan">立即转入</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-show="showFlag==3">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1">返回</el-button>
        <span>FB账户操作详情</span>
      </div>
      <div style="background:#fff;padding:24px;">
        <div class="infoBox">
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-5w">开户公司:</p>
              <div class="info-text calc-5w">{{info.accountCompanyName}}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-5w">媒体平台:</p>
              <div class="info-text calc-5w">{{configurationData.platform_array[info.platform]}}</div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-5w">账户:</p>
              <div class="info-text calc-5w">{{info.accountId}}</div>
            </div>
          </div>
          <div class="info-row" style="margin-bottom:0;">
            <div class="info-cell w-33">
              <p class="info-title t-5w">申请操作:</p>
              <div
                class="info-text calc-5w"
              >{{configurationData.account_operation_type[info.operateType]}}</div>
            </div>
            <div class="info-cell w-50">
              <p class="info-title t-6w">绑定/解绑内容:</p>
              <div class="info-text calc-6w">{{info.operateContent}}</div>
            </div>
          </div>
          <section
            class="info-cell examin-history"
            style="width:100%"
            v-if="info.examineRecords && info.examineRecords.length>0"
            v-show="showFlag==3"
          >
            <label>申请历史:</label>
            <div style="display:block" class="history-wrap">
              <p class="history" v-for="(item,key) in info.examineRecords" :key="key">
                <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
                <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
                <i>{{item.examineRemarks}}</i>
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      selectInAccountList:[],
      zhuanrujine:"",
      selectInaccountData:{
        clientCompanyId:"",
        accountId:"",
        pageNum:1,
        pageSize:10,
        total:0
      },
      currentRow:{},
      maxfundingBalance:"0.00",
      searchType:1,
      PixeltableData:[],
      firstPage: 1,
      loading: true,
      msg: "",
      showFlag: 1,
      pageSize: 15,
      total: 100,
      searchTimeRange:[],
      ajaxDataSearch: {
        accountId: "", //账户ID
        accountName:"",
        accountCompanyName:"",
        accountCompanyEnglishName: "", //开户公司名称
        clientCompanyName: "", //操作类型:1 绑定BM,2 解绑BM,3 绑定Pixel,4 解绑Pixel,5 账户清零,6 账户关停
        operateType: "", //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
        examineStatus: "",
        operateTimeStart: "",
        operateTimeEnd:"",
        pageNum:1,
        pageSize:15
      },
      dataList: [],
      configurationData: {
        account_operation_type: {},
        account_approval_status: {},
        platform_array: {}
      },
      info: {
        clientCompanyName: "",
        accountCompanyName: "",
        platform: "",
        accountId: "",
        operateType: "",
        operateContent: "",
        examineStatus: "",
        applicationTime: "",
        examineTime: ""
      },
      review: {
        id: "", //开户申请ID
        examineStatus: "", //审批状态:1 待审核,2 通过,3 审批失败(驳回),4 操作失败
        examineRemarks: "" //审批备注
      },
      bindBMradiotype:'1',
      bindPixelradiotype:'1',
      sameBMNames:"",
      samePixelNames:"",
      bangdingBMArr:[],
      bangdingPixelArr:[],
      showBatchAdd:false,
      alertGetAccList:{
        accounts: "", //账号id
        platform: 1, //平台
        total:0,
        pageNum: 1, //当前第几页
        pageSize: 7
      },
      rechargeclientCompanyId:"",
      clientCompanyList: [],
      projectPostData: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
        clientCompanyName:""
      },
      nonexistentAccStr:"",
      shownoexist:false,
      nonexistentAcc:[],
      fbzhtableData: [],
      paramsInfo:[],
      multipleSelection:[],
      isBindBM:false,
      operateType:"",
      rightdrawer:false,
      bmPostData:{
        accountId:"",
        pageNum:1,
        pageSize:15,
        totals:0
      },
      BMtableData:[],
      waitUnbindBMs:[],
      selectedBindBMList:[],
      selectUnbindBMForOneAccountIndex:'',
      rightdrawerPixel:false,
      PixelPostData:{
        accountId:"",
        pageNum:1,
        pageSize:15
      },
      waitUnbindPixels:[],
      selectedBindPixelList:[]
    };
  },
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      if (res.code == 1) {
        this.configurationData = res.data;
        this.init();
      } else if (res.code == 2) {
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });
    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
    if (this.$route.query.mediapostdata) {
      console.log(this.$route.query.mediapostdata);
      this.ajaxDataSearch = JSON.parse(this.$route.query.mediapostdata)
    }
  },
  filters: {
    statusClassFilter(value) {
      //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 3:
          m = "shenpi-bohui";
          break;
        case 4:
          m = "shenpi-yiguoqi";
          break;
        case 5:
          m = "shenpi-zhixingzhong";
          break;
        default:
          break;
      }
      return m;
    },
    examineFilter(val) {
      // 审批状态:1 审批状态:1 待审核,2 通过,3 驳回,4 操作失败
      let text = "";
      switch (val) {
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "操作成功";
          break;
        case 3:
          text = "驳回";
          break;
        case 4:
          text = "操作失败";
          break;

          break;
      }
      return text;
    }
  },
  methods: {
    accountremoteMethod(query){
      this.getSelectInAccount(1,query,this.currentRow.clientCompanyId)
    },
    getSelectInAccount(page,query,clientCompanyId){
      this.selectInaccountData.pageNum = page ? page : 1;
      this.selectInaccountData.accountId = query ? query : "";
      this.selectInaccountData.clientCompanyId = clientCompanyId ? clientCompanyId : "";
      this.axios(
        "get",
        `${this.$javaBaseURL}accountOperate/selectInAccount`,
        this.selectInaccountData
      ).then(res => {
        if (res.code == 1) {
          this.selectInAccountList = res.data.list;
          this.selectInaccountData.total = res.data.total;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    saveBoKuan(){
      if (!this.selectInaccountData.accountId) {
        this.$message({showClose: true,message: '请选择转入广告账户',type: "error"});
        return
      }
      if (Number(this.zhuanrujine) > Number(this.maxfundingBalance)) {
        this.$message({showClose: true,message: '转入金额应小于转出账户余额',type: "error"});        
        return
      }
      this.operates([{
        platform:this.currentRow.platform,
        accountId:this.currentRow.accountId,
        operateType:6,
        operateContent:this.selectInaccountData.accountId,
        operateAmount:this.zhuanrujine
      }])
      
    },
     operates(param){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
      });
      console.log(param);
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountOperate/add`,
        param
      ).then(res => {
        if (res.code == 1) {
          loading.close();
          this.$confirm('操作完成，您可以进行以下操作', '提示', {
            confirmButtonText: '继续操作',
            cancelButtonText: '查看操作结果',
            type: 'warning'
          }).then(() => {
            this.selectInaccountData = {
              clientCompanyId:"",
              accountId:"",
              pageNum:1,
              pageSize:10,
              total:0
            }
            this.zhuanrujine = ""
          }).catch(() => {
            this.$router.push({
              path:"/accountOpApply"
            })       
          });
        } else {
          this.$message({showClose: true,message: res.msg,type: "error"});
        }
      });
    },
    saveSelectedToStorage(arr){
      sessionStorage.setItem('selectedRow',arr)
    },
    resubmit(row){
      console.log(row);
      this.axios(
        "get",
        `${this.$javaBaseURL}accountMedia/list`,
        {
          accounts: row.accountId,
        }
      ).then(res => {
        if (res.code == 1) {
          if (row.operateType == 1) { //绑定BM
            this.bindBM(res.data.list)
          }else if (row.operateType == 2) {
            this.unbindBM(res.data.list)
          }else if (row.operateType == 3) {
            this.bindPixel(res.data.list)
          }else if (row.operateType == 4) {
            this.unbindPixel(res.data.list)
          }else if (row.operateType == 5) {
            this.$router.push({
              path: "/accZero",
              query: {
                from: "media",
                currentAccount: JSON.stringify(res.data.list[0]),
                mediapostdata: JSON.stringify(this.ajaxDataSearch)
              }
            })
          }else if (row.operateType == 6) {
            this.showFlag = 2;
            this.currentRow = res.data.list[0]
          }
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    bindBM(arr) {
      this.saveSelectedToStorage(JSON.stringify(arr))
      this.$router.push({
        path:'/operations',
        query:{
          from:'accountOpApply',
          operateType:1,
          showFlag:5,
          isBindBM:true,
          mediapostdata:JSON.stringify(this.ajaxDataSearch)
        }
      })
    },
    unbindBM(arr) {
      this.saveSelectedToStorage(JSON.stringify(arr))
      this.$router.push({
        path:'/operations',
        query:{
          from:'accountOpApply',
          operateType:2,
          showFlag:6,
          isBindBM:false,
          mediapostdata:JSON.stringify(this.ajaxDataSearch)
        }
      })
    },
    bindPixel(arr) {
      this.saveSelectedToStorage(JSON.stringify(arr))
      this.$router.push({
        path:'/operations',
        query:{
          from:'accountOpApply',
          operateType:3,
          showFlag:7,
          isBindPixel:true,
          mediapostdata:JSON.stringify(this.ajaxDataSearch)
        }
      })
    },
    unbindPixel(arr) {
      this.saveSelectedToStorage(JSON.stringify(arr))
      this.$router.push({
        path:'/operations',
        query:{
          from:'accountOpApply',
          operateType:4,
          showFlag:8,
          isBindPixel:false,
          mediapostdata:JSON.stringify(this.ajaxDataSearch)
        }
      })
    },
    showAddAccAlert(){
      this.accountCompanyChangeList(this.rechargeclientCompanyId)
      this.showBatchAdd = true;
      this.getAccList();
    },
    accountCompanyChangeList(clientCompanyId) {
      this.axios(
        "get",
        `${this.$javaBaseURL}accountCompany/select?clientCompanyId=${clientCompanyId}`,
        {}
      ).then(res => {
        if (res.code == 1) {
          this.accountListOptions = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getAccListSize(size) {
      this.alertGetAccList.pageSize = size ? size : 7;
      this.getAccList(1);
    },
    getAccList(page){
      this.alertGetAccList.clientCompanyId = this.rechargeclientCompanyId
      this.getAccountLists(page ? page : 1,this.alertGetAccList)
    },
    getAccountLists(page,data) {
      if (page) {
        data.pageNum = page
      }
      this.axios("get", `${this.$javaBaseURL}accountMedia/list`,data).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.fbzhtableData = res.data.list;
          this.alertGetAccList.total = res.data.total;
          this.alertGetAccList.pageSize = res.data.pageSize;
          this.nonexistentAcc = res.data.nonexistent
          this.nonexistentAccStr = res.data.nonexistent ? res.data.nonexistent.join('\n') : ""
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    subfbzh(){
      if (this.multipleSelection.length>0) {
        this.multipleSelection.forEach(elem => {
          if (this.operateType == 1 || this.operateType == 2) { // 绑定/解绑bm
            this.bangdingBMArr.push({
              accountId:elem.accountId,
              accountName:elem.accountName,
              bmCount:elem.bmCount,
              platform:elem.platform,
              operateType:this.operateType,
              operateContent:"",
            })
          }
          if (this.operateType == 3 || this.operateType == 4) { // 绑定/解绑pixel
            this.bangdingPixelArr.push({
              accountId:elem.accountId,
              accountName:elem.accountName,
              pixelCount:elem.pixelCount,
              platform:elem.platform,
              operateType:this.operateType,
              operateContent:"",
            })
          }
        });
        
        this.multipleSelection = []
        this.showBatchAdd=false
      }else{
        this.$message({message: '请选择账户', type: 'warning'});        
      }
    },
    resetList1(){
      this.alertGetAccList = {
        accountCompanyId:"",
        accounts:"",
        total:0,
        pageNum:1,
        pageSize:7
      }
      this.getAccList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    remoteMethod(query){
      this.projectPostData.clientCompanyName = query
      this.getRelatedComList()
    },
    clientCompanyfocus(event){
      this.projectPostData.clientCompanyName = ''
      this.projectPostData.pageNum = 1
      this.getRelatedComList()
    },
    getRelatedComList(page){
      this.projectPostData.pageNum = page ? page :1;
      this.axios("get", `${this.$javaBaseURL}clientCompany/selectAll`, this.projectPostData).then(
        res => {
          if (res.code == 1) {
            this.clientCompanyList = res.data.list;
            this.projectPostData.total = res.data.total;
            this.projectPostData.pageSize = res.data.per_page;
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    searchTypeChange(){
      switch (this.searchType) {
        case 1:
          this.ajaxDataSearch.accountCompanyEnglishName = ""
          this.ajaxDataSearch.accountCompanyName = ""
          this.ajaxDataSearch.accountName = ""
          this.ajaxDataSearch.accountId = ""
          break;
        case 2:
          this.ajaxDataSearch.clientCompanyName = ""
          this.ajaxDataSearch.accountCompanyName = ""
          this.ajaxDataSearch.accountName = ""
          this.ajaxDataSearch.accountId = ""
          break;
        case 3:
          this.ajaxDataSearch.clientCompanyName = ""
          this.ajaxDataSearch.accountCompanyEnglishName = ""
          this.ajaxDataSearch.accountName = ""
          this.ajaxDataSearch.accountId = ""
          break;
        case 4:
          this.ajaxDataSearch.clientCompanyName = ""
          this.ajaxDataSearch.accountCompanyEnglishName = ""
          this.ajaxDataSearch.accountCompanyName = ""
          this.ajaxDataSearch.accountId = ""
          break;
        case 5:
          this.ajaxDataSearch.clientCompanyName = ""
          this.ajaxDataSearch.accountCompanyEnglishName = ""
          this.ajaxDataSearch.accountCompanyName = ""
          this.ajaxDataSearch.accountName = ""
          break;
        default:
          break;
      }
    },
    formatTimes(){
      if (this.searchTimeRange) {
        this.ajaxDataSearch.operateTimeStart = this.searchTimeRange[0]
        this.ajaxDataSearch.operateTimeEnd = this.searchTimeRange[1]
      }else{
        this.ajaxDataSearch.operateTimeStart = ""
        this.ajaxDataSearch.operateTimeEnd = ""
      }
    },
    sizeInit(size){
      this.ajaxDataSearch.pageSize = size ? size : 15;
      this.init(1)
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      let obj = this.ajaxDataSearch;
      obj.pageNum = page ? page : 1;
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("get", `${this.$javaBaseURL}accountOperate/list`, obj).then(
        res => {
          this.loading = false;

          if (res.code == 1) {
            this.dataList = res.data.list;
            this.total = res.data.total;
            this.pageSize = res.data.pageSize;
          } else if (res.code == 2) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    enterSearch() {
      this.firstPage = 1;
      this.init();
    },
    resetList(){
      this.ajaxDataSearch = {
        accountId: "", //账户ID
        accountName:"",
        accountCompanyName:"",
        accountCompanyEnglishName: "", //开户公司名称
        clientCompanyName: "", //操作类型:1 绑定BM,2 解绑BM,3 绑定Pixel,4 解绑Pixel,5 账户清零,6 账户关停
        operateType: "", //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
        examineStatus: "",
        operateTimeStart: "",
        operateTimeEnd:"",
        pageNum:1,
        pageSize:10
      }
      this.enterSearch()
    },
    /*详情*/
    getInfo(id) {
      this.axios("get", `${this.$javaBaseURL}accountOperate/details`, {
        id: id
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //FB账户操作审批提交
    doReview(id) {
      if (!this.review.examineStatus) {
        this.$message({ message: "请选择审批状态", type: "warning" });
        return false;
      }
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountOperate/examine?id=${id}&examineStatus=${this.review.examineStatus}&examineRemarks=${this.review.examineRemarks}`,
        {}
      ).then(res => {
        if (res.code == 1) {
          this.common.statisticsAdd("FB账户操作申请审批", "2");
          this.showFlag = 1;
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //频次统计
    addStatistics() {
      this.common.statisticsAdd("FB账户操作审批列表", "1");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.searchedAcc{
  line-height: 30px;
  margin-bottom: 0;
  font-size: 14px;
  span{
    color: red;
  }
}
.accountOpApply{
  > div.whitebg{
    background-color: #fff;
    padding: 32px;
  }
  .main-pop{
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
  }
  /deep/.el-radio__label{
    font-size: 14px;
  }
}

</style>