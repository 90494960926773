<template>
  <div class="main-div media" style="background:none;padding:0;">
    <div style="padding:20px;background:#fff;" v-show="showFlag == 1">
      <p class="main-title">全部清零记录</p>
      <div>
        <div class="main-inputBox">
           <el-select
            v-model="ajaxDataSearch.clientCompanyId"
            filterable
            clearable
            remote
            :remote-method="remoteMethod"
            placeholder="全部客户"
            @focus="clientCompanyfocus"
          >
            <el-option
              v-for="(item,index) in clientCompanyList"
              :key="index"
              :label="item.clientCompanyName"
              :value="item.clientCompanyId"
            ></el-option>
            <div style="float: right;margin-right:10px;padding-bottom: 10px">
              <el-pagination
                @current-change="getRelatedComList"
                :current-page="projectPostData.pageNum"
                :page-size="projectPostData.pageSize"
                layout="total, prev, pager, next"
                :total="projectPostData.alltotal"
              ></el-pagination>
            </div>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.platform"
            clearable
            placeholder="全部平台"
          >
            <el-option
              v-for="(item,k) in configurationData.network_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.examineStatus"
            clearable
            placeholder="全部状态"
          >
            <el-option
              v-for="(item,k)  in configurationData.account_clear_examine_status"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox daterange" style="width: 255px!important;">
          <el-date-picker
            v-model="applicationTimeArrange_"
            @change="formatTimes"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            clearable
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div class="main-inputBox" style="width: 455px!important;">
          <el-input
            v-model="ajaxDataSearch.accounts"
            clearable
            placeholder="请输入广告账户ID或广告账户名称，支持通过英文“,”分隔输入多个"
          >
          </el-input>
        </div>
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">查询</div>
          <div class="main-button main-button-first" @click="resetList()">重置</div>
        </div>
      </div>
      <div class="main-btnBox m-r-16">
        <div class="main-button" @click="showFlag = 2;paramsInfo=[]">账户清零</div>
      </div>
      <div class="need-reTd" v-loading="loading">
        <el-table :data="list" border height="550" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column show-overflow-tooltip prop="accountName" label="广告账户名称"></el-table-column>
          <el-table-column prop="accountId" label="广告账户ID" ></el-table-column>
          <el-table-column label="状态" width="130">
            <template slot-scope="scope">
              <span>{{configurationData.account_clear_examine_status[scope.row.examineStatus]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="清零余额（美元）" width="150">
            <template slot-scope="scope">
              <div>{{scope.row.operateAmount > 0 ? common.toThousands(scope.row.operateAmount):"-"}}</div>
            </template>
          </el-table-column>
          <el-table-column label="媒体平台" >
            <template slot-scope="scope">{{configurationData.network_array[scope.row.platform]}}</template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="clientCompanyName" label="所属客户"></el-table-column>
          <el-table-column prop="createdAt" label="创建时间" width="180"></el-table-column>
          <el-table-column prop label="操作" width="180">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="getinfo(scope.row)">详情</span>
              <span class="main-tableToInfo" @click="resolveEx(scope.row)" v-if="scope.row.platform != 1 && scope.row.examineStatus == 1">处理</span>
              <span class="main-tableToInfo" v-if="scope.row.examineStatus == 4" @click="reSubmit(scope.row)">重新提交</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue @currentSizeChange="sizeInit" @currentChange="init" :pageSize="ajaxDataSearch.pageSize" :total="total" :firstPage="firstPage" />
      </div>
    </div>
    <!-- 清零 -->
    <div v-show="showFlag == 2">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1;goMedia()">返回</el-button>
        <span>账户清零</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">
          <span>广告账户</span>
          <el-button size="mini" type="primary" style="margin-left:15px;" @click="showAddAccAlert()">添加广告账户</el-button>
        </p>
        <el-table :data="paramsInfo" :selectable="checkSelect" border style="width: 100%">
          <el-table-column prop="accountName" label="账户名称"></el-table-column>
          <el-table-column prop="accountId" label="账户ID"></el-table-column>
          <el-table-column label="账户余额（美元）">
            <template slot-scope="scope">
              <div>
                {{scope.row.accountLimit > 0 ? scope.row.accountBalance : "-"}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="账户状态">
            <template slot-scope="scope">
              <span>{{configurationData.account_status_config[scope.row.accountStatus]}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="clientCompanyName" label="归属客户"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="deleteAccount(scope.row,scope.$index)">删除账户</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">备注信息</p>
        <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 20}" placeholder="请填写广告账户清零原因" v-model="ramarkstext"></el-input>
      </div>
      <div style="background:#fff;padding:24px;">
        <div class="info-row">
          <div class="info-cell w-80">
            <div class="info-text calc-2w">
              <button class="main-button l" @click="showFlag = 1;goMedia()">取消</button>
              <button class="main-button r" @click="add">保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <div v-show="showFlag == 3">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1">返回</el-button>
        <span>广告账户清零</span>
      </div>
      <div class="info_section">
        <p class="main-title">广告账户清零详情</p>
        <el-row class="elrow_item" type="flex" justify="space-between">
          <el-col :span="7">
            <div class="label_">广告账户名称：</div>
            <div class="value_">{{detailInfo.accountName}}</div>
          </el-col>
          <el-col :span="7">
            <div class="label_">广告账户ID：</div>
            <div class="value_">{{detailInfo.accountId}}</div>
          </el-col>
          <el-col :span="7">
            <div class="label_">归属平台：</div>
            <div class="value_">{{configurationData.network_array[detailInfo.platform]}}</div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="space-between">
          <el-col :span="7">
            <div class="label_">申请单余额（美元）：</div>
            <div class="value_">{{common.toThousands(detailInfo.accountBalance)}}</div>
          </el-col>
          <el-col :span="7">
            <div class="label_">状态：</div>
            <div class="value_">{{configurationData.account_clear_examine_status[detailInfo.examineStatus]}}</div>
          </el-col>
          <el-col :span="7">
            <div class="flex_">
              <div class="label_">归属客户：</div>
              <div class="value_">{{detailInfo.clientCompanyName}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="space-between">
          <el-col :span="7">
            <div class="label_">实际清零余额（美元）：</div>
            <div class="value_">{{detailInfo.operateAmount ? common.toThousands(detailInfo.operateAmount) : 0}}</div>
          </el-col>
          <el-col :span="7">
            <div class="label_">申请人：</div>
            <div class="value_">{{detailInfo.applicant}}</div>
          </el-col>
          <el-col :span="7">
            <div class="label_">处理人：</div>
            <div class="value_">{{detailInfo.operator}}</div>
          </el-col>
        </el-row>
        <el-row v-if="detailInfo.examineError" class="elrow_item" type="flex" justify="space-between">
          <el-col :span="20">
            <div class="label_">操作结果：</div>
            <div class="value_" :class="{'red':detailInfo.examineStatus==4?true:false}">{{detailInfo.examineError}}</div>
          </el-col>
        </el-row>
      </div>
      <div class="info_section">
        <p class="main-title">申请清零原因</p>
        <el-row class="elrow_item" type="flex" justify="space-between">
          <el-col :span="24">
            <div style="margin:0 24px;font-size:14px;line-height:30px;">{{detailInfo.remark}}</div>
          </el-col>
        </el-row>
      </div>
      <div class="info_section">
        <p class="main-title">时间</p>
        <el-row class="elrow_item" type="flex" justify="space-between">
          <el-col :span="24">
            <div class="timeinfo"><span>{{detailInfo.createdAt}}</span> <span>{{detailInfo.applicant}}</span> <span>{{'创建申请单'}}</span></div>
            <div class="timeinfo"><span>{{detailInfo.examineTime}}</span> <span>{{detailInfo.operator}}</span> <span>{{configurationData.account_clear_examine_status[detailInfo.examineStatus]}}</span></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 处理 -->
    <div class="main-pop" v-show="showDeal">
      <div class="mask"></div>
      <div class="center" >
        <div class="head">
          <span>处理</span>
          <i class="gongzuotai gztguanbi" @click="showDeal=false"></i>
        </div>
        <div class="body">
          <el-form size="small" label-width="120px">
            <el-form-item label="处理状态：" required>
              <el-select v-model="examinepostdata.examineStatus" placeholder="请选择处理状态">
                <el-option label="操作完成" :value="2"></el-option>
                <el-option label="操作失败" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="实际清零余额：">
              <el-input type="text" @blur="amoutInput" v-model="examinepostdata.operateAmount"></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input type="textarea" :rows="5" v-model="examinepostdata.examineRemarks" maxlength="200" show-word-limit></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot">
          <button class="main-button l" @click="showDeal=false;">取消</button>
          <button class="main-button r" @click="resolveSubmit()">确定</button>
        </div>
      </div>
    </div>
    <!-- 添加广告账户 -->
    <div class="main-pop" v-show="showBatchAdd">
      <div class="mask"></div>
      <div class="center" style="width:900px;">
        <div class="head">
          <span>添加广告账户</span>
          <i class="gongzuotai gztguanbi" @click="showBatchAdd=false"></i>
        </div>
        <div class="body" style="padding-right:0px;overflow:scroll;">
          <div style="display:flex;align-items:center;margin-bottom:10px">
            <div class="main-inputBox" style="width: 400px!important;margin-bottom:0;">
              <el-input v-model="alertGetAccList.accounts" clearable placeholder="请输入账户名称或账户ID，支持用英文“,”分隔输入多个"></el-input>
            </div>
            <div class="main-inputBox" style="margin-bottom:0;">
              <el-select
                v-model="rechargeclientCompanyId"
                filterable
                clearable
                remote
                :remote-method="remoteMethod"
                placeholder="客户公司名称"
                @focus="clientCompanyfocus"
                @change="clientCompanyChange"
              >
                <el-option
                  v-for="(item,index) in clientCompanyList"
                  :key="index"
                  :label="item.clientCompanyName"
                  :value="item.clientCompanyId"
                ></el-option>
                <div style="float: right;margin-right:10px;padding-bottom: 10px">
                  <el-pagination
                    @current-change="getRelatedComList"
                    :current-page="projectPostData.pageNum"
                    :page-size="projectPostData.pageSize"
                    layout="total, prev, pager, next"
                    :total="projectPostData.alltotal"
                  ></el-pagination>
                </div>
              </el-select>
            </div>
            <div class="main-selectBox" style="margin-bottom:0;">
              <div class="main-button" @click="getAccList(1,alertGetAccList)">查询</div>
              <div class="main-button main-button-first" @click="resetList1()">重置</div>
            </div>
          </div>
          <div class="searchedAcc" v-if="nonexistentAcc">已找到{{alertGetAccList.total}}个广告账户 <span>有{{nonexistentAcc.length}}个广告账户ID不存在</span> <el-button type="text" @click="shownoexist = true">详情</el-button></div>
          <el-table ref="multipleTable" :data="fbzhtableData" tooltip-effect="dark" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="账户名称" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="账户ID" prop="accountId"></el-table-column>
            <el-table-column label="账户状态">
              <template slot-scope="scope">
                <span :class="scope.row.accountStatus | statusClassFilter">
                  {{scope.row.accountStatus == 0 ? '取消授权' : ''}}
                  {{scope.row.accountStatus == 1 ? '正常' : ''}}
                  {{scope.row.accountStatus == 2 ? '关闭' : ''}}
                  {{scope.row.accountStatus == 3 ? '封户' : ''}}
                  {{scope.row.accountStatus == 4 ? '空户' : ''}}
                  {{scope.row.accountStatus == 5 ? '违规' : ''}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="客户公司" prop="clientCompanyName"></el-table-column>
          </el-table>
          <pagination-vue :isSevenPagin="true" @currentSizeChange="getAccListSize" @currentChange="getAccList" :pageSize="alertGetAccList.pageSize" :total="alertGetAccList.total" :firstPage="firstPage"/>
        </div>
        <div class="foot">
          <button class="main-button l" @click="showBatchAdd=false;resetList1()">取消</button>
          <button class="main-button r" @click="subfbzh">确定</button>
        </div>
      </div>
    </div>
    <!-- 不存在的账户列表 -->
    <el-dialog title="不存在的账户列表" :visible.sync="shownoexist">
      <div style="padding:20px;">
        <el-input
          autosize
          type="textarea"
          v-model="nonexistentAccStr">
        </el-input>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import { log } from "util";

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      showDeal:false,
      nonexistentAccStr:"",
      shownoexist:false,
      nonexistentAcc:[],
      configurationData:{
        network_array:{},
        account_status_config:{},
        account_clear_examine_status:{}
      },
      ramarkstext:"",
      currentRow:{},
      rechargeradio:"1",
      rechargeclientCompanyId:"",
      singleRechargeNumber:"",
      clientBalance:0,
      fbzhtableData: [],
      multipleSelection: [],
      showBatchAdd: false,
      bigOutAmount: "",
      inIDs: [],
      showAppropriationrow: {
        projectName: "",
        accountId: ""
      },
      firstPage: 1,
      loading: true,
      timer: "",
      visible: false,
      istrue: false,
      token: "",
      msg: "",
      showFlag: 1,
      total: 1,
      alertGetAccList:{
        clientCompanyId:"",
        accountCompanyId:"",
        accounts:"",
        total:0,
        pageNum:1,
        pageSize:7
      },
      accountStatus: "",
      paramsInfo: [],
      rechargeMediaAdd: {
        platform: "", //平台:1 facebook;2 twitter,3 google,5 tiktok
        accountId: "", //广告账户ID
        rechargeAmount: "", //充值金额,
        status: ""
      },
      pausePop: false,
      list: [],
      clientCompanyList: [],
      accountCompanyList: [],
      projectList: [],
      accountList: [],
      creditAmount: "",
      accountPlatformName: "",
      accountPlatform: "",
      accountCost: "",
      accountLimit: "",
      /*返回客户管理下搜索项用到的数据接口*/
      editStatusPop: false,
      project_id: "",
      project_status: "",
      openZhPop: false,
      radio: "1",
      editID:1,
      accountListOptions: [], //开户公司下拉
      projectPostData: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
        clientCompanyName:""
      },
      applicationTimeArrange_:"", //申请时间(格式:2020/04/16)
      ajaxDataSearch: {
        accounts:"",
        accountCompanyId:"",
        platform: "", //	否	int	平台:1 facebook,2 twitter,3 google,5 tiktok
        examineStatus: "", //账户状态:1 正常,2 因空户暂停,3 代理商暂停,4 封户,5 无消耗
        openTimeStart:"",
        openTimeEnd:"",
        pageNum:1,
        pageSize:15,
      },
      detailInfo:{},
      isresubmit:false,
      examinepostdata:{
        id:"",
        examineStatus:"",
        operateAmount:"",
        examineRemarks:""
      },
      currentRecord:{}
    };
  },
  filters: {
    ltrimm(value){
      return value.replace(/\s*/g,"");
    },
    statusClassFilter(value) {
      //申请状态:1 正常,2 关闭,3 封户, 4空户
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 3:
          m = "shenpi-bohui";
          break;
        case 4:
          m = "shenpi-yiguoqi";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    this.common.getConfigData('client_level,project_principal,project_authorization_list').then((res)=>{
      this.configurationData = res.data
    }).catch(err => {
      this.$message({ message: err.msg, type: "warning" });      
    })

    //点击回车进行搜索
    let that=this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  mounted() {
    this.init()

    console.log(this.$route.query);
    if (this.$route.query.from == 'media') {
      this.showFlag = 2
      var a_ = [JSON.parse(this.$route.query.currentAccount)]
      a_.forEach(elem => {
        this.paramsInfo.push({
          clientCompanyId:this.rechargeclientCompanyId,
          accountId:elem.accountId,
          accountName:elem.accountName,
          platform:elem.platform,
          accountLimit:elem.accountLimit,
          accountBalance:elem.accountBalance,
          accountStatus:elem.accountStatus,
          clientCompanyName:elem.clientCompanyName,
          rechargeAmount:0.00
        })
      })
    }
  },
  methods: {
    amoutInput(){
      this.examinepostdata.operateAmount = this.common.toThousands(this.examinepostdata.operateAmount,2)
      console.log(this.examinepostdata.operateAmount);
    },
    goMedia(){
      if (this.$route.query.from == 'media') {
        this.$router.push({
          path:"/media",
          query:{
            mediapostdata:this.$route.query.mediapostdata
          }
        })
      }
    },
    resolveSubmit(){
      this.examinepostdata.id = this.currentRecord.id
      console.log(this.examinepostdata);
      if (!this.examinepostdata.examineStatus) {
        this.$message({ message: "请选择处理状态", type: "warning" });           
        return;
      }

      if (this.examinepostdata.examineStatus == 2 && !this.examinepostdata.operateAmount) {
        this.$message({ message: "请填写实际清零余额", type: "warning" });   
        return;
      }
      if (this.examinepostdata.examineStatus == 3 && !this.examinepostdata.examineRemarks) {
        this.$message({ message: "请填写备注", type: "warning" });   
        return;
      }

      this.axios("POST",`${this.$javaBaseURL}accountClear/examine`,this.examinepostdata).then(res => {
        if (res.code == 1) {
          this.$message({ message: "处理成功", type: "success" });
          this.showDeal = false
          this.init()          
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });

    },
    resolveEx(row){
      console.log(row);
      this.showDeal = true
      this.currentRecord = row
    },
    reSubmit(row){
      this.isresubmit = true
      this.alertGetAccList.accounts = row.accountId
      this.showFlag = 2;
      this.getAccList();
    },
    clientCompanyChange() {
      // this.accountCompanyChangeList(this.rechargeclientCompanyId)
    },
    clientCompanyfocus(event){
      this.projectPostData.clientCompanyName = ''
      this.projectPostData.pageNum = 1
      this.getRelatedComList()
    },
    getinfo(row){
      this.axios("get", `${this.$javaBaseURL}accountClear/details`,{
        id:row.id
      }).then(res => {
        if (res.code == 1) {
          this.showFlag = 3
          this.detailInfo = res.data
          console.log(this.detailInfo);
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    async openCloseReason(row,val){
      this.editID = val
      this.currentRow = row
      let ress = await this.checkBlackList(row);
      if (ress.code == 1) {
        if (ress.data == 1) {
          this.$message({
            message: "该用户在有效黑名单中，暂时不能充值",
            type: "warning"
          });
          return false;
        }
      } else {
        this.$message({message: ress.msg,type: "error"});
        return false;
      }
      this.showFlag = 2;
    },
    showAddAccAlert(){
      this.isresubmit = false
      this.accountCompanyChangeList(this.rechargeclientCompanyId)
      this.showBatchAdd = true;
      this.getAccList();
    },
    checkSelect (row,index) {
      let isChecked = true;
      if (row.accountStatus == 1 || row.accountStatus == 4) { // 判断里面是否存在某个参数
        isChecked = true
      } else {
        isChecked = false
      }
      return isChecked
    },
    add() {
      if (this.editID == 1) {
        this.zeroClear()
        return
      }
    },
    zeroClear(){
      var arr = [];
      if (this.paramsInfo.length <1) {
        this.$message({ message: "请选择广告账户", type: "warning" });
        return;
      }
      if (!this.ramarkstext) {
        this.$message({ message: "请填写清零原因", type: "warning" });
        return;
      }
      this.paramsInfo.forEach(item => {
        arr.push({
          platform:item.platform,
          accountId:item.accountId,
          operateType:5,
          remark:this.ramarkstext
        })
      });

      this.$confirm('确定要清零吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
        });
        this.axios("POST",`${this.$javaBaseURL}accountClear/add`,arr).then(res => {
          if (res.code == 1) {
            this.$confirm('操作完成，您可以进行以下操作', '提示', {
              confirmButtonText: '继续操作',
              cancelButtonText: '查看操作结果',
              type: 'warning'
            }).then(() => {
              this.ramarkstext = "";
              this.paramsInfo = [];
            }).catch(() => {
              this.ramarkstext = "";
              this.paramsInfo = [];
              this.showFlag = 1;
            });
          } else if (res.code == 20001) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
          loading.close();
        });
      }).catch(() => {
                
      });
    },
    deleteAccount(row,index){
      this.paramsInfo.splice(index,1)
    },
    getAccList(page){
      // if (!this.isresubmit) {
      //   this.alertGetAccList.accounts = ""
      // }
      this.alertGetAccList.clientCompanyId = this.rechargeclientCompanyId
      this.getAccountLists(page ? page : 1,this.alertGetAccList)
    },
    getAccListSize(size){
      this.alertGetAccList.pageSize = size ? size : 7;
      this.getAccList(1)
    },
    getAccountLists(page,data) {
      if (page) {
        data.pageNum = page
      }
      this.axios("get", `${this.$javaBaseURL}accountMedia/list`,data).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.fbzhtableData = res.data.list;
          if (this.isresubmit) {
            this.multipleSelection = this.fbzhtableData
            this.subfbzh()
          }
          this.alertGetAccList.total = res.data.total;
          this.alertGetAccList.pageSize = res.data.pageSize;
          this.nonexistentAcc = res.data.nonexistent
          this.nonexistentAccStr = res.data.nonexistent ? res.data.nonexistent.join('\n') : ""
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    resetList1(){
      this.alertGetAccList = {
        accountCompanyId:"",
        accounts:"",
        total:0,
        pageNum:1,
        pageSize:7
      }
      this.getAccList()
    },
    resetList(){
      this.ajaxDataSearch = {
        accounts:"",
        accountCompanyId:"",
        platform: "", //	否	int	平台:1 facebook,2 twitter,3 google,5 tiktok
        examineStatus: "", //账户状态:1 正常,2 因空户暂停,3 代理商暂停,4 封户,5 无消耗
        openTimeStart:"",
        openTimeEnd:"",
        pageNum:1,
        pageSize:10,
      }
      this.init()
    },
    remoteMethod(query){
      console.log(query);
      this.projectPostData.clientCompanyName = query
      this.getRelatedComList()
    },
    //客户公司下拉框
    getRelatedComList(page){
      this.projectPostData.pageNum = page ? page :1;
      this.axios("get", `${this.$javaBaseURL}clientCompany/selectAll`, this.projectPostData).then(
        res => {
          if (res.code == 1) {
            this.clientCompanyList = res.data.list;
            this.projectPostData.total = res.data.total;
            this.projectPostData.pageSize = res.data.per_page;
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    formatTimes(event){
      console.log(this.applicationTimeArrange_);
      if(this.applicationTimeArrange_){
        this.ajaxDataSearch.openTimeStart = this.applicationTimeArrange_[0]
        this.ajaxDataSearch.openTimeEnd = this.applicationTimeArrange_[1]
      }else{
        this.ajaxDataSearch.openTimeStart = ""
        this.ajaxDataSearch.openTimeEnd = ""
      }
    },
    sizeInit(size){
      this.ajaxDataSearch.pageSize = size ? size : 15
      this.init(1)
    },
    init(page) {
      this.ajaxDataSearch.pageNum = page ? page : 1
      this.loading = true;
      clearTimeout(this.timer);
      this.firstPage = page;
      this.axios("get", `${this.$javaBaseURL}accountClear/list`,{
        accounts: this.ajaxDataSearch.accounts,
        clientCompanyId: this.ajaxDataSearch.clientCompanyId,
        platform: this.ajaxDataSearch.platform,
        examineStatus: this.ajaxDataSearch.examineStatus,
        openTimeStart: this.ajaxDataSearch.openTimeStart,
        openTimeEnd: this.ajaxDataSearch.openTimeEnd,
        pageNum: this.ajaxDataSearch.pageNum,
        pageSize: this.ajaxDataSearch.pageSize
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
          this.pageSize = res.data.pageSize;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch(data) {
      this.firstPage = 1;
      this.init(1,data);
    },
    //根据客户公司ID获取开户公司列表
    accountCompanyChangeList(clientCompanyId) {
      this.axios(
        "get",
        `${this.$javaBaseURL}accountCompany/select?clientCompanyId=${clientCompanyId}`,
        {}
      ).then(res => {
        if (res.code == 1) {
          this.accountListOptions = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //验证账号开户公司是否在黑名单中
    checkBlackList(obj) {
      return new Promise(resolve => {
        var req_api = `accountCompany/selectBlacklistByName?accountCompanyName=${obj.accountCompanyName}`;
        this.axios("GET", `${this.$javaBaseURL}${req_api}`, {})
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            this.$message({
              showClose: true,
              message: err,
              type: "error"
            });
            console.log(err);
            reject(err);
          });
      });
    },
    getErrorStatusMsg(status){
      var str = ''
      switch (status) {
        case 0:
          str = '账户不存在，请检查广告账户是否正确'
          break;
        case 2:
          str = '账户已关闭，不能执行账户操作'
          break;
        case 3:
          str = '账户已封户，不能执行账户操作'
          break;
        case 4:
          str = '账户已解除授权，不能执行账户操作'
          break;
        case 5:
          str = '开户公司在黑名单中，不能执行账户操作'
          break;
        case 6:
          str = '账户已清零，不能执行账户操作'
          break;
        case 7:
          str = '账户已关停，不能执行账户操作'
          break;
        default:
          break;
      }
       console.log(str);
        return str;
    },
    subfbzh(){
      if (this.multipleSelection.length>0) {
        this.multipleSelection.forEach(elem => {
          this.paramsInfo.push({
            clientCompanyId:this.rechargeclientCompanyId,
            accountId:elem.accountId,
            accountName:elem.accountName,
            platform:elem.platform,
            accountLimit:elem.accountLimit,
            accountBalance:elem.accountBalance,
            accountStatus:elem.accountStatus,
            clientCompanyName:elem.clientCompanyName,
            rechargeAmount:0.00
          })
        });
        this.multipleSelection = []
        this.showBatchAdd=false
      }else{
        this.$message({message: '请选择账户', type: 'warning'});        
      }
    },
    delfbzh(){
      var msg = ''
      if (this.multipleSelection.length>0) {
        this.multipleSelection.forEach(ele => {
          if (ele.errorStattus != 1) {
            msg+=ele.accountId+','
          }
        });
        msg += '这些账户不符合批量操作的标准，是否删除这些数据？'
        this.$alert(msg, '确认', {
          confirmButtonText: '确定',
          callback: action => {
            for (let i = 0; i < this.multipleSelection.length; i++) {
              for (let k = 0; k < this.fbzhtableData.length; k++) {
                if (this.multipleSelection[i].accountId == this.fbzhtableData[k].accountId) {
                  this.fbzhtableData.splice(k,1)
                }
              }
            }
          }
        });
      }else{
        this.$message({message: '请选择账户', type: 'warning'});        
      }
    },
    getImportxlsx(){
      window.open(`${this.$javaBaseURL}download/Import.xlsx`)
    },
    fbzhbeforeUpload (file) {
      var that = this
      let fd = new FormData();
      fd.append('file', file);
      axios.defaults.headers.common['Content-Type'] = "multipart/form-data";
      this.axios('POST', `${this.$javaBaseURL}batch/dataImport`, fd).then(res => {
          if (res.code == 1) {
            this.$message({message: res.msg, type: 'success'});
            this.fbzhtableData = res.data
          } else if(res.code == 20001){
              //token过期处理
              this.common.tokenExpired(res.msg);
          } else {
              this.$message({message: res.msg, type: 'warning'});
          }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
  },
  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info_section{
  background:#fff;
  padding:24px;
  margin-bottom:24px;
  .timeinfo{
    line-height: 30px;
    color: #333;
    font-size: 14px;
    span:nth-child(2){
      margin: 0 20px;
    }
  }
}
.searchedAcc{
  line-height: 30px;
  margin-bottom: 0;
  font-size: 14px;
  span{
    color: red;
  }
}
.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}
.main-inputBox {
  width: 200px !important;
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}

.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style>
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>