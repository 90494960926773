<template>
  <div class="main-div frequencyStatistics">
    <p class="main-title">频次统计</p>

    <div>
      <div class="main-inputBox">
        <el-input
          v-model="queryOptions.name"
          clearable
          placeholder="请输入姓名"
          @keyup.enter.native="changeQuery"
          @change="changeQuery()"
        >
        </el-input>
      </div>
      <div class="main-selectBox">
        <el-select
          v-model="queryOptions.mod"
          clearable
          placeholder="请选择列表/功能"
          @keyup.enter.native="changeQuery"
          @change="changeQuery()"
        >
          <el-option
            :title="item.mod"
            v-for="(item,index) in configurationData.mod_action_type"
            :key="index"
            :label="item.mod"
            :value="item.mod"
          ></el-option>
        </el-select>
      </div>
      <div class="main-inputBox daterange" style="width: 255px!important;">
        <el-date-picker
          v-model="timeRange"
          @change="changeQuery"
          @keyup.enter.native="changeQuery"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="创建开始日期"
          clearable
          end-placeholder="结束日期"
          placeholder="创建时间"
        ></el-date-picker>
      </div>
    </div>

    <div v-loading="loading">
      <el-table :data="list" border height="550" style="width: 100%;">
        <template slot="empty">
          <div class="nodata">
            <img src="@/assets/img/nodata.png">
            <span>暂无数据</span>
          </div>
        </template>

        <el-table-column prop="date" label="日期"></el-table-column>

        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>

        <el-table-column prop="mod" label="列表/功能"></el-table-column>

        <el-table-column prop="usage_count" label="使用次数" width="150"></el-table-column>
        <section slot="append">
          <div class="append-row">
            <span class="summary">本页合计</span>
            <span class="sum-number">{{curPageNum}}</span>
          </div>
        </section>
        <section slot="append">
          <div class="append-row">
            <span class="summary">当前合计</span>
            <span class="sum-number">{{total}}</span>
          </div>
        </section>
      </el-table>
      <pagination-vue
        @currentChange="getList"
        :pageSize="pageSize"
        :total="page_total"
        :firstPage="firstPage"
      />
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import moment from "moment";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      list: [],
      curPageNum: "",
      timeRange: [], //时间段
      configurationData: [],
      total: 0,
      page_total: 0,
      pageSize: 0,
      queryOptions: {
        name: "",
        mod: "", //模块(列表)
        action_type: "", //string	操作类型
        start_date: "", //string	开始时间
        end_date: "", //结束时间
        page: 1
      }
    };
  },
  filters: {},
  created() {
    this.getOptions();
  },
  watch: {},
  computed: {},
  methods: {
    getOptions() {
      //返回整个筛选项
      // console.log('axios:',axios)
      let param = {
        type: "mod_action_type"
      };
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, param).then(
        res => {
          if (res.code == 1) {
            this.configurationData = res.data;
            this.getList();
          } else if (res.code == 2) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
          this.loading = false;
        }
      );
    },
    getList(page) {
      this.loading = true;
      this.firstPage = page;
      //获取日志接口
      if (page) {
        this.queryOptions.page = page;
      }
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/action_log/statistics`,
        this.queryOptions
      ).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.list.data;
          this.page_total = res.data.list.total;
          this.pageSize = res.data.list.per_page;
          this.total = this.total ? parseInt(res.data.total) : 0; //当前合计
          this.curPageNum = res.data.current_page_total;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        }
      });
    },
    changeQuery(data) {
      this.firstPage = 1;

      this.queryOptions.start_date = data && data[0] ? data[0] : "";
      this.queryOptions.end_date = data && data[1] ? data[1] : "";

      this.getList(1);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep {
  .append-row {
    border-top: solid 1px #ebeef5;
    border-bottom: none;
  }
}
</style>
<style>
.append-row {
  border-bottom: 1px solid #ebeef5;
  display: flex;
}
.append-row .summary {
  flex: auto;
  text-indent: 12px;
  line-height: 48px;
}
.append-row .sum-number {
  flex: 0 0 150px;
  width: 150px;
  border-left: 1px solid #ebeef5;
  text-indent: 24px;
  height: 48px;
  line-height: 48px;
  /* box-sizing: border-box; */
}
</style>