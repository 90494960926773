<template>
  <div class="main-div accountOpApply" style="background:none;padding:0;">
    <!-- 绑定BM -->
    <div v-show="showFlag == 5" style="background:none;padding:0">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="goback()">返回</el-button>
        <span>绑定BM</span>
      </div>
      <div style="padding:24px;background:#fff;">
        <el-form label-position="right" label-width="100px" size="small">
          <el-form-item label="绑定方式：">
            <el-radio v-model="bindBMradiotype" label="1">绑定相同的BM</el-radio>
            <el-radio v-model="bindBMradiotype" label="2">绑定不同的BM</el-radio>
          </el-form-item>
          <el-form-item v-show="bindBMradiotype == '1'" label="BM：">
            <el-input style="width:300px;" v-model="sameBMNames" placeholder="绑定多个BM时，用英文‘,’进行分隔"></el-input>
          </el-form-item>
          <el-form-item label="广告账户：">
            <div style="margin-bottom:20px;">
              <el-button type="text" size="medium" @click="showAddAccAlert()">添加账号</el-button>
            </div>
            <el-table :data="bangdingBMArr" border style="width: 100%">
              <el-table-column prop="accountName" label="广告账户"></el-table-column>
              <el-table-column v-if="bindBMradiotype == '2'" label="本次绑定的BM">
                <template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.operateContent"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="已绑定BM数">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="viewRelatedBM(scope.row.accountId)"
                  >{{scope.row.bmCount}}</el-button>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="deletebangdingBM(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="bindBMhandle(false)">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 解绑BM -->
    <div v-show="showFlag == 6" style="background:none;padding:0">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="goback()">返回</el-button>
        <span>解绑BM</span>
      </div>
      <div style="padding:24px;background:#fff;">
        <el-form label-position="right" label-width="100px" size="small">
          <el-form-item label="解绑方式：">
            <el-radio v-model="bindBMradiotype" label="1">解绑相同的BM</el-radio>
            <el-radio v-model="bindBMradiotype" label="2">解绑不同的BM</el-radio>
          </el-form-item>
          <el-form-item v-show="bindBMradiotype == '1'" label="BM：">
            <el-input style="width:300px;" v-model="sameBMNames" placeholder="解绑多个BM时，用英文“，”分隔"></el-input>
          </el-form-item>
          <el-form-item label="广告账户：">
            <div style="margin-bottom:20px;">
              <el-button type="text" size="medium" @click="showAddAccAlert()">添加账号</el-button>
            </div>
            <el-table :data="bangdingBMArr" border style="width: 100%">
              <el-table-column prop="accountName" label="广告账户" width="300" show-overflow-tooltip></el-table-column>
              <el-table-column label="本次解绑的BM" v-if="bindBMradiotype == 2">
                <template slot-scope="scope">
                  <el-tag
                    style="margin-right:20px;"
                    v-for="item in scope.row.operateContentArrs"
                    :key="item.bmId"
                    closable
                    :disable-transitions="false"
                    @close="removeSelectedBMinAccList(scope.$index,item)"
                  >{{item.bmId}}</el-tag>
                  <el-button
                    type="text"
                    @click="selectUnbindBMForOneAcc(scope.row,scope.$index)"
                  >选择BM</el-button>
                </template>
              </el-table-column>
              <el-table-column label="已绑定BM数" v-if="bindBMradiotype == 1">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="viewRelatedBM(scope.row.accountId)"
                  >{{scope.row.bmCount}}</el-button>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" @click="deletebangdingBM(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="unbindConfirm(false)">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 绑定Pixel -->
    <div v-show="showFlag == 7" style="background:none;padding:0">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="goback()">返回</el-button>
        <span>绑定Pixel</span>
      </div>
      <div style="padding:24px;background:#fff;">
        <el-form label-position="right" label-width="100px" size="small">
          <el-form-item label="绑定方式：">
            <el-radio v-model="bindPixelradiotype" label="1">绑定相同的Pixel</el-radio>
            <el-radio v-model="bindPixelradiotype" label="2">绑定不同的Pixel</el-radio>
          </el-form-item>
          <el-form-item v-show="bindPixelradiotype == '1'" label="Pixel ID：">
            <el-input
              style="width:300px;"
              v-model="samePixelNames"
              placeholder="绑定多个Pixel时，用英文‘,’进行分隔"
            ></el-input>
          </el-form-item>
          <el-form-item label="广告账户：">
            <div style="margin-bottom:20px;">
              <el-button type="text" size="medium" @click="showAddAccAlert()">添加账号</el-button>
            </div>
            <el-table :data="bangdingPixelArr" border style="width: 100%">
              <el-table-column prop="accountName" label="广告账户"></el-table-column>
              <el-table-column v-if="bindPixelradiotype == '2'" label="本次绑定的Pixel">
                <template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.operateContent"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="已绑定Pixel数">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="viewRelatedPixel(scope.row.accountId)"
                  >{{scope.row.pixelCount}}</el-button>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="deletebangdingPixel(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="bindPixelhandle(false)">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 解绑Pixel -->
    <div v-show="showFlag == 8" style="background:none;padding:0">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="goback()">返回</el-button>
        <span>解绑Pixel</span>
      </div>
      <div style="padding:24px;background:#fff;">
        <el-form label-position="right" label-width="100px" size="small">
          <el-form-item label="解绑方式：">
            <el-radio v-model="bindPixelradiotype" label="1">解绑相同的Pixel</el-radio>
            <el-radio v-model="bindPixelradiotype" label="2">解绑不同的Pixel</el-radio>
          </el-form-item>
          <el-form-item v-show="bindPixelradiotype == '1'" label="Pixel：">
            <el-input
              style="width:300px;"
              v-model="samePixelNames"
              placeholder="解绑多个Pixel时，用英文“,”分隔"
            ></el-input>
          </el-form-item>
          <el-form-item label="广告账户：">
            <div style="margin-bottom:20px;">
              <el-button type="text" size="medium" @click="showAddAccAlert()">添加账号</el-button>
            </div>
            <el-table :data="bangdingPixelArr" border style="width: 100%">
              <el-table-column prop="accountName" label="广告账户" width="300" show-overflow-tooltip></el-table-column>
              <el-table-column label="本次解绑的Pixel" v-if="bindPixelradiotype == 2">
                <template slot-scope="scope">
                  <el-tag
                    style="margin-right:20px;"
                    v-for="item in scope.row.operateContentArrs"
                    :key="item.pixelId"
                    closable
                    :disable-transitions="false"
                    @close="removeSelectedPixelinAccList(scope.$index,item)"
                  >{{item.pixelId}}</el-tag>
                  <el-button
                    type="text"
                    @click="selectUnbindPixelForOneAcc(scope.row,scope.$index)"
                  >选择Pixel</el-button>
                </template>
              </el-table-column>
              <el-table-column label="已绑定Pixel数" v-if="bindPixelradiotype == 1">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="viewRelatedPixel(scope.row.accountId)"
                  >{{scope.row.pixelCount}}</el-button>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" @click="deletebangdingPixel(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label>
            <el-button size="small" type="primary" @click="unbindPixelConfirm(false)">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 设置违规 -->
    <div v-show="showFlag == 9" style="background:none;padding:0;">
      <div class="detail_header" style="padding:24px;background:#fff;">
        <el-button size="small" icon="el-icon-back" @click="goback()">返回</el-button>
        <span>设置违规</span>
      </div>
      <div style="padding:24px;background:#fff;">
        <div style="display:flex;align-items:center;margin-bottom:20px;">
          违规广告账户
          <el-button
            size="small"
            type="primary"
            style="margin-left:20px;"
            @click="showAddAccAlert()"
          >添加广告账号</el-button>
        </div>
        <div>
          <div class="main-button main-button-first" @click="multiDeleteViaAccs()">批量删除</div>
        </div>
        <div style="margin-top:20px;">
          <el-table
            :data="viaAccounts"
            border
            style="width: 100%"
            ref="listTable"
            @selection-change="listChangeSelect"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="accountName" label="账户名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="accountId" label="账户ID" show-overflow-tooltip></el-table-column>
            <el-table-column
              prop="clientCompanyName"
              label="归属客户"
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="accountCompanyName"
              label="开户公司"
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="违规原因" width="300">
              <template slot="header"> 
                <div>
                  <span style="color:red;margin-right:10px;">*</span>
                  <span>违规原因</span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark" size="small" placeholder="请输入违规原因"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteItemViaAcc(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="padding:24px;background:#fff;margin-top:24px;">
        <button class="main-button r" @click="saveViolation()">确定</button>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span style="color:red;font-size:14px;margin:0 40px;">设置成违规的广告账户，将被清零且不能充值</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="setViolation(true)">设置违规并清零</el-button>
        <el-button size="small" type="primary" @click="setViolation(false)">仅设置违规</el-button>
      </span>
    </el-dialog>

    <!-- 已绑定的BM弹窗 -->
    <el-drawer title="已绑定的BM" size="40%" :visible.sync="rightdrawer" direction="rtl">
      <div style="padding:24px;" v-show="bindBMradiotype == '1'">
        <el-table :data="BMtableData" style="width: 100%">
          <el-table-column prop="bmId" label="BM编号"></el-table-column>
          <el-table-column prop="bindedTime" label="绑定时间"></el-table-column>
        </el-table>
      </div>
      <div style="padding:24px;" v-show="bindBMradiotype == '2'">
        <el-table
          @selection-change="bindedBMListChange"
          ref="bindedBMmultipleTable"
          :data="BMtableData"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="bmId" label="BM编号"></el-table-column>
          <el-table-column prop="bindedTime" label="绑定时间"></el-table-column>
        </el-table>
        <div style="margin-top:30px;">
          <el-button size="small" @click="rightdrawer = false">取消</el-button>
          <el-button size="small" type="primary" @click="choosedBM()">提交</el-button>
        </div>
      </div>
      <pagination-vue
        @currentSizeChange="getBmlistSizeInit"
        @currentChange="getBmlist"
        :pageSize="bmPostData.pageSize"
        :total="bmPostData.totals"
      />
    </el-drawer>
    <!-- 已绑定的Pixel弹窗 -->
    <el-drawer title="已绑定的Pixel" size="40%" :visible.sync="rightdrawerPixel" direction="rtl">
      <div style="padding:24px;" v-show="bindPixelradiotype == '2'">
        <el-table
          @selection-change="bindedPixelListChange"
          ref="bindedPixelmultipleTable"
          :data="PixeltableData"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="pixelId" label="Pixel ID编号"></el-table-column>
        </el-table>
        <div style="margin-top:30px;">
          <el-button size="small" @click="rightdrawerPixel = false">取消</el-button>
          <el-button size="small" type="primary" @click="choosedPixel()">提交</el-button>
        </div>
      </div>
      <div style="padding:24px;" v-show="bindPixelradiotype == '1'">
        <el-table :data="PixeltableData" style="width: 100%">
          <el-table-column prop="pixelId" label="Pixel ID编号"></el-table-column>
        </el-table>
      </div>
      <pagination-vue
        @currentSizeChange="getPixellistInit"
        @currentChange="getPixellist"
        :pageSize="PixelPostData.pageSize"
        :total="PixelPostData.totals"
      />
    </el-drawer>
    <!-- 添加广告账户 -->
    <div class="main-pop" v-show="showBatchAdd">
      <div class="mask"></div>
      <div class="center" style="width:1100px;">
        <div class="head">
          <span>添加广告账户</span>
          <i class="gongzuotai gztguanbi" @click="showBatchAdd=false"></i>
        </div>
        <div
          v-if="showFlag == 9 || showFlag == 10"
          class="body"
          style="padding-right:0px;overflow:scroll;"
        >
          <el-tabs :tab-position="'left'" v-model="activeTabName">
            <el-tab-pane label="列表搜索" name="first">
              <div style="display:flex;align-items:center;margin-bottom:10px">
                <div class="main-inputBox" style="width: 400px!important;margin-bottom:0;">
                  <el-input
                    v-model="alertGetAccList.accounts"
                    clearable
                    placeholder="请输入账户名称或账户ID，支持用英文“,”分隔输入多个"
                  ></el-input>
                </div>
                <div class="main-inputBox" style="margin-bottom:0;">
                  <el-select
                    v-model="rechargeclientCompanyId"
                    filterable
                    clearable
                    remote
                    :remote-method="remoteMethod"
                    placeholder="客户公司名称"
                    @focus="clientCompanyfocus"
                  >
                    <el-option
                      v-for="(item,index) in clientCompanyList"
                      :key="index"
                      :label="item.clientCompanyName"
                      :value="item.clientCompanyId"
                    ></el-option>
                    <div style="float: right;margin-right:10px;padding-bottom: 10px">
                      <el-pagination
                        @current-change="getRelatedComList"
                        :current-page="projectPostData.pageNum"
                        :page-size="projectPostData.pageSize"
                        layout="total, prev, pager, next"
                        :total="projectPostData.alltotal"
                      ></el-pagination>
                    </div>
                  </el-select>
                </div>
                <div class="main-selectBox" style="margin-bottom:0;">
                  <div class="main-button" @click="getAccList(1,alertGetAccList)">查询</div>
                  <div class="main-button main-button-first" @click="resetList1()">重置</div>
                </div>
              </div>
              <div class="searchedAcc" v-if="nonexistentAcc">
                已找到{{alertGetAccList.total}}个广告账户
                <span>有{{nonexistentAcc.length}}个广告账户ID不存在</span>
                <el-button type="text" @click="shownoexist = true">详情</el-button>
              </div>
              <el-table
                style="width:98%;"
                ref="multipleTable"
                :data="fbzhtableData"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="账户名称" prop="accountName" show-overflow-tooltip></el-table-column>
                <el-table-column label="账户ID" prop="accountId"></el-table-column>
                <el-table-column label="账户状态">
                  <template slot-scope="scope">
                    <span :class="scope.row.accountStatus | statusClassFilter">
                      {{scope.row.accountStatus == 0 ? '取消授权' : ''}}
                      {{scope.row.accountStatus == 1 ? '正常' : ''}}
                      {{scope.row.accountStatus == 2 ? '关闭' : ''}}
                      {{scope.row.accountStatus == 3 ? '封户' : ''}}
                      {{scope.row.accountStatus == 4 ? '空户' : ''}}
                      {{scope.row.accountStatus == 5 ? '违规' : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="客户公司" prop="clientCompanyName"></el-table-column>
              </el-table>
              <pagination-vue
                :isSevenPagin="true"
                @currentSizeChange="getAccListSize"
                @currentChange="getAccList"
                :pageSize="alertGetAccList.pageSize"
                :total="alertGetAccList.total"
                :firstPage="firstPage"
              />
            </el-tab-pane>
            <el-tab-pane label="上传表格" name="second">
              <el-form label-width="100px">
                <el-form-item label="上传文件" required>
                  <el-upload
                    class="upload-demo"
                    accept=".xls, .xlsx"
                    action="#"
                    :before-upload="beforeExcelUpload"
                    :http-request="uploadHttpRequest"
                  >
                    <el-button size="small" type="primary">点击文件</el-button>
                  </el-upload>
                  <el-button type="text" @click="downTemp()">下载模板</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-else class="body" style="padding-right:0px;overflow:scroll;">
          <div style="display:flex;align-items:center;margin-bottom:10px">
            <div class="main-inputBox" style="width: 400px!important;margin-bottom:0;">
              <el-input
                v-model="alertGetAccList.accounts"
                clearable
                placeholder="请输入账户名称或账户ID，支持用英文“,”分隔输入多个"
              ></el-input>
            </div>
            <div class="main-inputBox" style="margin-bottom:0;">
              <el-select
                v-model="rechargeclientCompanyId"
                filterable
                clearable
                remote
                :remote-method="remoteMethod"
                placeholder="客户公司名称"
                @focus="clientCompanyfocus"
              >
                <el-option
                  v-for="(item,index) in clientCompanyList"
                  :key="index"
                  :label="item.clientCompanyName"
                  :value="item.clientCompanyId"
                ></el-option>
                <div style="float: right;margin-right:10px;padding-bottom: 10px">
                  <el-pagination
                    @current-change="getRelatedComList"
                    :current-page="projectPostData.pageNum"
                    :page-size="projectPostData.pageSize"
                    layout="total, prev, pager, next"
                    :total="projectPostData.alltotal"
                  ></el-pagination>
                </div>
              </el-select>
            </div>
            <div class="main-selectBox" style="margin-bottom:0;">
              <div class="main-button" @click="getAccList(1,alertGetAccList)">查询</div>
              <div class="main-button main-button-first" @click="resetList1()">重置</div>
            </div>
          </div>
          <div class="searchedAcc" v-if="nonexistentAcc">
            已找到{{alertGetAccList.total}}个广告账户
            <span>有{{nonexistentAcc.length}}个广告账户ID不存在</span>
            <el-button type="text" @click="shownoexist = true">详情</el-button>
          </div>
          <el-table
            style="width:98%;"
            ref="multipleTable"
            :data="fbzhtableData"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="账户名称" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="账户ID" prop="accountId"></el-table-column>
            <el-table-column label="账户状态">
              <template slot-scope="scope">
                <span :class="scope.row.accountStatus | statusClassFilter">
                  {{scope.row.accountStatus == 0 ? '取消授权' : ''}}
                  {{scope.row.accountStatus == 1 ? '正常' : ''}}
                  {{scope.row.accountStatus == 2 ? '关闭' : ''}}
                  {{scope.row.accountStatus == 3 ? '封户' : ''}}
                  {{scope.row.accountStatus == 4 ? '空户' : ''}}
                  {{scope.row.accountStatus == 5 ? '违规' : ''}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="客户公司" prop="clientCompanyName"></el-table-column>
          </el-table>
          <pagination-vue
            :isSevenPagin="true"
            @currentSizeChange="getAccListSize"
            @currentChange="getAccList"
            :pageSize="alertGetAccList.pageSize"
            :total="alertGetAccList.total"
            :firstPage="firstPage"
          />
        </div>
        <div class="foot">
          <button class="main-button l" @click="showBatchAdd=false;resetList1()">取消</button>
          <button class="main-button r" @click="subfbzh">确定</button>
        </div>
      </div>
    </div>

    <!-- 不存在的账户列表 -->
    <el-dialog title="不存在的账户列表" :visible.sync="shownoexist">
      <div style="padding:20px;">
        <el-input autosize type="textarea" v-model="nonexistentAccStr"></el-input>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      activeTabName:"first",
      dialogVisible: false,
      uploadList: [],
      selectedViaAccs: [],
      viaAccounts: [],
      searchType: 1,
      PixeltableData: [],
      firstPage: 1,
      loading: true,
      msg: "",
      showFlag: 1,
      pageSize: 15,
      total: 100,
      searchTimeRange: [],
      ajaxDataSearch: {
        accountId: "", //账户ID
        accountName: "",
        accountCompanyName: "",
        accountCompanyEnglishName: "", //开户公司名称
        clientCompanyName: "", //操作类型:1 绑定BM,2 解绑BM,3 绑定Pixel,4 解绑Pixel,5 账户清零,6 账户关停
        operateType: "", //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
        examineStatus: "",
        operateTimeStart: "",
        operateTimeEnd: "",
        pageNum: 1,
        pageSize: 15
      },
      dataList: [],
      configurationData: {
        account_operation_type: {},
        account_approval_status: {},
        platform_array: {}
      },
      info: {
        clientCompanyName: "",
        accountCompanyName: "",
        platform: "",
        accountId: "",
        operateType: "",
        operateContent: "",
        examineStatus: "",
        applicationTime: "",
        examineTime: ""
      },
      review: {
        id: "", //开户申请ID
        examineStatus: "", //审批状态:1 待审核,2 通过,3 审批失败(驳回),4 操作失败
        examineRemarks: "" //审批备注
      },
      bindBMradiotype: "1",
      bindPixelradiotype: "1",
      sameBMNames: "",
      samePixelNames: "",
      bangdingBMArr: [],
      bangdingPixelArr: [],
      showBatchAdd: false,
      alertGetAccList: {
        accounts: "", //账号id
        platform: 1, //平台
        total: 0,
        pageNum: 1, //当前第几页
        pageSize: 7
      },
      rechargeclientCompanyId: "",
      clientCompanyList: [],
      projectPostData: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
        clientCompanyName: ""
      },
      nonexistentAccStr: "",
      shownoexist: false,
      nonexistentAcc: [],
      fbzhtableData: [],
      paramsInfo: [],
      multipleSelection: [],
      isBindBM: false,
      operateType: "",
      rightdrawer: false,
      bmPostData: {
        accountId: "",
        pageNum: 1,
        pageSize: 15,
        totals: 0
      },
      BMtableData: [],
      waitUnbindBMs: [],
      selectedBindBMList: [],
      selectUnbindBMForOneAccountIndex: "",
      rightdrawerPixel: false,
      PixelPostData: {
        accountId: "",
        pageNum: 1,
        pageSize: 15
      },
      waitUnbindPixels: [],
      selectedBindPixelList: []
    };
  },
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      if (res.code == 1) {
        this.configurationData = res.data;
      } else if (res.code == 2) {
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });
    this.showFlag = this.$route.query.showFlag;
    this.operateType = this.$route.query.operateType;
    if (this.showFlag == 5 || this.showFlag == 6) {
      this.isBindBM = this.$route.query.isBindBM;
    } else if (this.showFlag == 7 || this.showFlag == 8) {
      this.isBindPixel = this.$route.query.isBindPixel;
    }

    if (this.showFlag == 5 || this.showFlag == 6) {
      const arr = JSON.parse(sessionStorage.getItem("selectedRow"));
      console.log(arr);
      
      if (arr && arr.length > 0) {
        arr.forEach(element => {
          this.bangdingBMArr.push(element);
        });
      }
    } else if (this.showFlag == 7 || this.showFlag == 8) {
      const arr = JSON.parse(sessionStorage.getItem("selectedRow"));
      if (arr && arr.length > 0) {
        arr.forEach(element => {
          this.bangdingPixelArr.push(element);
        });
      }
    }

    if (this.showFlag == 9) {
      this.viaAccounts = JSON.parse(sessionStorage.getItem("selectedRow"));
    }
  },
  filters: {
    statusClassFilter(value) {
      //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 3:
          m = "shenpi-bohui";
          break;
        case 4:
          m = "shenpi-yiguoqi";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
    examineFilter(val) {
      // 审批状态:1 审批状态:1 待审核,2 通过,3 驳回,4 操作失败
      let text = "";
      switch (val) {
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "操作成功";
          break;
        case 3:
          text = "驳回";
          break;
        case 4:
          text = "操作失败";
          break;

          break;
      }
      return text;
    }
  },
  methods: {
    setViolation(bol) {
      let postdata = {
        isClear: 0,
        violations: []
      };
      postdata.violations = this.viaAccounts.map(item => {
        return{
          accountId:item.accountId,
          remark:item.remark
        }
      })
      if (bol) {
        postdata.isClear = 5
      } 
      const loading = this.$loading({
        lock: true,
      });
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountMedia/addViolation`,
        postdata
      ).then(res => {
        loading.close()
        if (res.code == 1) {
          if (bol) {
            this.$confirm('操作完成，您可以进行以下操作', '提示', {
              confirmButtonText: '查看清零结果',
              cancelButtonText: '关闭',
              type: 'warning'
            }).then(() => {
              this.$router.push({
                name:"accountOpApply"
              })  
            }).catch(() => {
              this.$router.push({
                name:"media",
                query:{
                  mediapostdata:this.$route.query.mediapostdata
                }
              })      
            });
          }else {
            this.$message({ message: "操作成功", type: "success" });
            this.$router.push({
              name:"media",
              query:{
                mediapostdata:this.$route.query.mediapostdata
              }
            }) 
          }
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    saveViolation() {
      let _flag = true
      this.viaAccounts.forEach(item=>{
        try {
          if (!item.remark) {
            _flag = false;
            throw error();
          }
        } catch (error) {
          
        }
        
      })
      if (_flag) {
        this.dialogVisible = true;        
      }else{
        this.$message({ message: "请输入违规原因", type: "warning" });
      }
    },
    beforeExcelUpload(file) {
      console.log(file);
      //文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      }
      return fileType;

      // 文件大小限制为10M
      // const fileLimit = file.size / 1024 / 1024 < 10;
      // if(!fileLimit) {
      //     this.$message.error('上传文件大小不超过10M！');
      // }
      //  && fileLimit
    },
    uploadHttpRequest(param) {
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("file", param.file); //添加文件对象
      console.log(formData);
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountMedia/importViolation`,
        formData
      ).then(res => {
        if (res.code == 1) {
          console.log(res);
          this.uploadList = res.data;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    downTemp() {
      window.open("/bm/api/download/设置违规.xlsx");
    },
    multiDeleteViaAccs() {
      if (this.selectedViaAccs.length == 0) {
        this.$message({ message: "请最少选中一条数据", type: "warning" });
        return;
      }
      this.selectedViaAccs.forEach(item => {
        this.viaAccounts.forEach((elem, index) => {
          if (item.accountId == elem.accountId) {
            this.viaAccounts.splice(index, 1);
          }
        });
      });
      this.saveSelectedToStorage(JSON.stringify(this.viaAccounts));
    },
    deleteItemViaAcc(index) {
      this.viaAccounts.splice(index, 1);
      this.saveSelectedToStorage(JSON.stringify(this.viaAccounts));
    },
    saveSelectedToStorage(arr) {
      sessionStorage.setItem("selectedRow", arr);
    },
    listChangeSelect(val) {
      this.selectedViaAccs = val;
    },
    goback() {
      this.$router.push({
        name: this.$route.query.from,
        query:{
          mediapostdata:this.$route.query.mediapostdata?this.$route.query.mediapostdata:""
        }
      });
    },
    bindedPixelListChange(val) {
      this.selectedBindPixelList = val;
    },
    removeSelectedBMinAccList(index, item) {
      this.bangdingBMArr[index].operateContentArrs.splice(
        this.bangdingBMArr[index].operateContentArrs.indexOf(item),
        1
      );
    },
    removeSelectedPixelinAccList(index, item) {
      this.bangdingPixelArr[index].operateContentArrs.splice(
        this.bangdingPixelArr[index].operateContentArrs.indexOf(item),
        1
      );
    },
    choosedBM() {
      this.waitUnbindBMs = [...this.selectedBindBMList];
      if (this.selectUnbindBMForOneAccountIndex != null) {
        this.$set(
          this.bangdingBMArr[this.selectUnbindBMForOneAccountIndex],
          "operateContentArrs",
          this.waitUnbindBMs
        );
        this.$forceUpdate();
      }
      this.rightdrawer = false;
    },
    choosedPixel() {
      this.waitUnbindPixels = [...this.selectedBindPixelList];
      if (this.selectUnbindPixelForOneAccountIndex != null) {
        this.$set(
          this.bangdingPixelArr[this.selectUnbindPixelForOneAccountIndex],
          "operateContentArrs",
          this.waitUnbindPixels
        );
        this.$forceUpdate();
      }
      this.rightdrawerPixel = false;
    },
    dealStr(arr) {
      console.log(arr);
      if (!arr) {
        return "";
      }
      let s = "";
      arr.forEach(item => {
        console.log(item);
        if (item.bmId) {
          s += item.bmId + ",";
        } else {
          s += item.pixelId + ",";
        }
      });
      return s.substr(0, s.length - 1);
    },
    unbindConfirm(bol) {
      var str = "";
      if (this.bindBMradiotype == 1) {
        if (!this.sameBMNames) {
          this.$message({
            showClose: true,
            message: "请输入BM",
            type: "error"
          });
          return;
        }
        this.bangdingBMArr.map(item => {
          item.operateContent = this.sameBMNames;
          return item;
        });
      } else {
        this.bangdingBMArr.map(i => {
          i.operateContent = this.dealStr(i.operateContentArrs);
        });
      }
      console.log(this.bangdingBMArr);
      this.operates(this.bangdingBMArr);
    },
    unbindPixelConfirm(bol) {
      var str = "";
      console.log(this.bangdingPixelArr);
      if (this.bindPixelradiotype == 1) {
        if (!this.samePixelNames) {
          this.$message({
            showClose: true,
            message: "请输入Pixel",
            type: "error"
          });
          return;
        }
        this.bangdingPixelArr.map(item => {
          item.operateContent = this.samePixelNames;
          return item;
        });
      } else {
        this.bangdingPixelArr.map(i => {
          i.operateContent = this.dealStr(i.operateContentArrs);
        });
      }
      this.operates(this.bangdingPixelArr);
    },
    bindedBMListChange(val) {
      this.selectedBindBMList = val;
    },
    operates(param) {
      const loading = this.$loading({
        lock: true,
        text: "Loading"
      });
      console.log(param);
      this.axios("POST", `${this.$javaBaseURL}accountOperate/add`, param).then(
        res => {
          if (res.code == 1) {
            loading.close();
            this.$confirm("操作完成，您可以进行以下操作", "提示", {
              confirmButtonText: "继续操作",
              cancelButtonText: "查看操作结果",
              type: "warning"
            })
              .then(() => {
                this.rebackbtn();
              })
              .catch(() => {
                this.$router.push({
                  path: "/accountOpApply"
                });
              });
          } else {
            this.$message({ showClose: true, message: res.msg, type: "error" });
          }
        }
      );
    },
    viewRelatedBM(id) {
      this.rightdrawer = true;
      this.bmPostData.accountId = id ? id : this.rows.accountId;
      this.getBmlist();
    },
    viewRelatedPixel(id) {
      this.rightdrawerPixel = true;
      this.PixelPostData.accountId = id ? id : this.rows.accountId;
      this.getPixellist();
    },
    rebackbtn() {
      this.askVisible = false;
      this.bindBMradiotype = "1";
      this.bindPixelradiotype = "1";

      this.bmPostData = { accountId: "", pageNum: 1, pageSize: 10 };
      this.PixelPostData = { accountId: "", pageNum: 1, pageSize: 10 };

      this.bangdingBMArr = [];
      this.bangdingPixelArr = [];

      this.sameBMNames = "";
      this.samePixelNames = "";

      this.bencibangdingbmid = "";
      this.bencibangdingpixelid = "";

      if (this.$refs.multipleTable1) {
        this.$refs.multipleTable1.clearSelection();
      }
      if (this.$refs.bindedBMmultipleTable) {
        this.$refs.bindedBMmultipleTable.clearSelection();
      }
      if (this.$refs.bindedPixelmultipleTable) {
        this.$refs.bindedPixelmultipleTable.clearSelection();
      }
      this.waitUnbindBMs = [];
      this.waitUnbindPixels = [];
    },
    deletebangdingBM(index) {
      this.bangdingBMArr.splice(index, 1);
    },
    deletebangdingPixel(index) {
      this.bangdingPixelArr.splice(index, 1);
    },
    selectUnbindBMForOneAcc(row, index) {
      this.selectedBindBMList = this.waitUnbindBMs;
      this.selectUnbindBMForOneAccountIndex = index;
      console.log(row.operateContent, index);
      this.viewRelatedBM(row.accountId);
    },
    selectUnbindPixelForOneAcc(row, index) {
      this.selectedBindPixelList = this.waitUnbindBMs;
      this.selectUnbindPixelForOneAccountIndex = index;
      this.viewRelatedPixel(row.accountId);
    },
    getBmlist(page) {
      this.bmPostData.pageNum = page ? page : 1;
      this.axios(
        "GET",
        `${this.$javaBaseURL}accountMedia/bmList`,
        this.bmPostData
      ).then(res => {
        if (res.code == 1) {
          this.BMtableData = res.data.list;
          this.bmPostData.pageNum = res.data.pageNum;
          this.bmPostData.totals = res.data.total;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getBmlistSizeInit(size) {
      this.bmPostData.pageSize = size ? size : 15;
      this.getBmlist(1);
    },
    getPixellist(page) {
      this.PixelPostData.pageNum = page ? page : 1;
      this.axios(
        "GET",
        `${this.$javaBaseURL}accountMedia/pixelList`,
        this.PixelPostData
      ).then(res => {
        if (res.code == 1) {
          this.PixeltableData = res.data.list;
          this.PixelPostData.totals = res.data.total;
          this.PixelPostData.pageNum = res.data.pageNum;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error"
          });
        }
      });
    },
    getPixellistInit(size) {
      this.PixeltableData.pageSize = size ? size : 15;
      this.getPixellist(1);
    },
    bindBMhandle(bol) {
      if (this.bindBMradiotype == 1) {
        if (!this.sameBMNames) {
          this.$message({
            showClose: true,
            message: "请输入BM",
            type: "error"
          });
          return;
        }
        this.bangdingBMArr.map(item => {
          item.operateContent = this.sameBMNames;
          return item;
        });
      }
      this.operates(this.bangdingBMArr);
    },
    bindPixelhandle(bol) {
      if (this.bindPixelradiotype == 1) {
        if (!this.samePixelNames) {
          this.$message({
            showClose: true,
            message: "请输入Pixel ID",
            type: "error"
          });
          return;
        }
        this.bangdingPixelArr.map(item => {
          item.operateContent = this.samePixelNames;
          return item;
        });
      }
      console.log(this.bangdingPixelArr);
      this.operates(this.bangdingPixelArr);
    },
    bindBM() {
      this.isBindBM = true;
      this.operateType = 1;
      this.showFlag = 5;
    },
    unbindBM(row) {
      this.isBindBM = false;
      this.operateType = 2;
      this.showFlag = 6;
    },
    bindPixel(row) {
      this.isBindPixel = true;
      this.operateType = 3;
      this.showFlag = 7;
    },
    unbindPixel(row) {
      this.isBindPixel = false;
      this.operateType = 4;
      this.showFlag = 8;
    },
    showAddAccAlert() {
      this.accountCompanyChangeList(this.rechargeclientCompanyId);
      this.showBatchAdd = true;
      this.getAccList();
    },
    accountCompanyChangeList(clientCompanyId) {
      this.axios(
        "get",
        `${this.$javaBaseURL}accountCompany/select?clientCompanyId=${clientCompanyId}`,
        {}
      ).then(res => {
        if (res.code == 1) {
          this.accountListOptions = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getAccListSize(size) {
      this.alertGetAccList.pageSize = size ? size : 7;
      this.getAccList(1);
    },
    getAccList(page) {
      this.alertGetAccList.clientCompanyId = this.rechargeclientCompanyId;
      this.getAccountLists(page ? page : 1, this.alertGetAccList);
    },
    getAccountLists(page, data) {
      if (page) {
        data.pageNum = page;
      }
      this.axios("get", `${this.$javaBaseURL}accountMedia/list`, data).then(
        res => {
          this.loading = false;
          if (res.code == 1) {
            this.fbzhtableData = res.data.list;
            this.alertGetAccList.total = res.data.total;
            this.alertGetAccList.pageSize = res.data.pageSize;
            this.nonexistentAcc = res.data.nonexistent;
            this.nonexistentAccStr = res.data.nonexistent
              ? res.data.nonexistent.join("\n")
              : "";
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    subfbzh() {
      if (this.showFlag == 9 && this.activeTabName == 'second') {
        this.uploadList.forEach(item => {
          this.viaAccounts.push(item);
        });
        this.showBatchAdd = false;
        return;
      }
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach(elem => {
          if (this.operateType == 1 || this.operateType == 2) {
            // 绑定/解绑bm
            this.bangdingBMArr.push({
              accountId: elem.accountId,
              accountName: elem.accountName,
              bmCount: elem.bmCount,
              platform: elem.platform,
              operateType: this.operateType,
              operateContent: ""
            });
          }
          if (this.operateType == 3 || this.operateType == 4) {
            // 绑定/解绑pixel
            this.bangdingPixelArr.push({
              accountId: elem.accountId,
              accountName: elem.accountName,
              pixelCount: elem.pixelCount,
              platform: elem.platform,
              operateType: this.operateType,
              operateContent: ""
            });
          }
          if (this.showFlag == 9) {
            this.viaAccounts.push({
              accountId: elem.accountId,
              accountName: elem.accountName,
              clientCompanyName: elem.clientCompanyName,
              accountCompanyName: elem.accountCompanyName,
              platform: elem.platform,
              viaAccounts: ""
            });
          }
        });

        this.multipleSelection = [];
        this.showBatchAdd = false;
        this.resetList1();
      } else {
        this.$message({ message: "请选择账户", type: "warning" });
      }
    },
    resetList1() {
      this.alertGetAccList = {
        accountCompanyId: "",
        accounts: "",
        total: 0,
        pageNum: 1,
        pageSize: 7
      };

      this.getAccList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    remoteMethod(query) {
      this.projectPostData.clientCompanyName = query;
      this.getRelatedComList();
    },
    clientCompanyfocus(event) {
      this.projectPostData.clientCompanyName = "";
      this.projectPostData.pageNum = 1;
      this.getRelatedComList();
    },
    getRelatedComList(page) {
      this.projectPostData.pageNum = page ? page : 1;
      this.axios(
        "get",
        `${this.$javaBaseURL}clientCompany/selectAll`,
        this.projectPostData
      ).then(res => {
        if (res.code == 1) {
          this.clientCompanyList = res.data.list;
          this.projectPostData.total = res.data.total;
          this.projectPostData.pageSize = res.data.per_page;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    sizeInit(size) {
      this.ajaxDataSearch.pageSize = size ? size : 15;
      this.init(1);
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      let obj = this.ajaxDataSearch;
      obj.pageNum = page ? page : 1;
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("get", `${this.$javaBaseURL}accountOperate/list`, obj).then(
        res => {
          this.loading = false;

          if (res.code == 1) {
            this.dataList = res.data.list;
            this.total = res.data.total;
            this.pageSize = res.data.pageSize;
          } else if (res.code == 2) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    enterSearch() {
      this.firstPage = 1;
      this.init();
    },
    resetList() {
      this.ajaxDataSearch = {
        accountId: "", //账户ID
        accountName: "",
        accountCompanyName: "",
        accountCompanyEnglishName: "", //开户公司名称
        clientCompanyName: "", //操作类型:1 绑定BM,2 解绑BM,3 绑定Pixel,4 解绑Pixel,5 账户清零,6 账户关停
        operateType: "", //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
        examineStatus: "",
        operateTimeStart: "",
        operateTimeEnd: "",
        pageNum: 1,
        pageSize: 10
      };
      this.enterSearch();
    },
    //频次统计
    addStatistics() {
      this.common.statisticsAdd("FB账户操作审批列表", "1");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.searchedAcc {
  line-height: 30px;
  margin-bottom: 0;
  font-size: 14px;
  span {
    color: red;
  }
}
.accountOpApply {
  > div.whitebg {
    background-color: #fff;
    padding: 32px;
  }
  .main-pop {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
  }
  /deep/.el-radio__label {
    font-size: 14px;
  }
  .el-tabs__item {
    padding-right: 10px;
  }
}
</style>