<template>
  <nav :class="{'retract':!retract}" v-if="navList">
    <ul class="nav-wrapper" v-show="retract">
      <li v-for="(e,i) in navList" :key="i" class="item">
        <h3 v-show="e.checked && e.is_show==1" :class="{'active':e.isOpen}" @click="itemClick(i)">
          <router-link :to="e.menu_to?e.menu_to:''" tag="span">
            <i class="gongzuotai" :class="e.icon"></i>
            {{e.title}}
          </router-link>
          <i class="gongzuotai gztxialakuangjiantou arrowTransform" v-if="navActive==i"></i>
        </h3>

        <ul class="clild-wrapper" v-show="e.checked && e.is_show==1 && e.children.length && e.isOpen">
          <li
            v-for="(es,is) in e.children"
            :key="is"
            class="items"
            @click="itemsClick('clild'+ i + is,es,i)"
            :class="{'select':navClildActive == 'clild' + i + is}"
            v-if="es.is_show==1 && es.checked">
            <router-link :to="es.menu_to?es.menu_to:''" tag="span">{{es.title}}</router-link>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="nav-wrapper-shrink" v-show="!retract">
      <li v-for="(e,i) in navList" :key="i" class="item" @mousemove="itemMousemove(i)">
        <h3 v-show="e.checked && e.is_show==1" :class="{'active':navActive==i}" @click="itemClick(i)" >
          <router-link :to="e.menu_to?e.menu_to:''" tag="span">
            {{e.menu_to}}
            <i class="gongzuotai" :class="e.icon"></i>
          </router-link>
        </h3>
        <ul class="clild-wrap" v-show="e.checked && e.is_show==1 && e.isHoverOpen && navActive == i" @mouseout="itemOut(i)">
          <li
            v-for="(es,is) in e.children"
            :key="is"
            class="items"
            @click="itemsClick('clild'+ i + is,es,i)"
            :class="{'select':navClildActive == 'clild' + i + is}"
            v-if="es.is_show==1 && es.checked">
            <router-link :to="es.menu_to?es.menu_to:''" tag="span">{{es.title}}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import store from "@/store/index.js";
export default {
  name: "Nav",
  data() {
    return {
      navActive: "-1",
      navClildActive: "-1",
      permUrl: [],
      retract: true,
      lastChildIndex: "", //上一次点击的子菜单
      lastClickRouterIndex: "", //上一次点击的子菜单的index
      isHoverOpen: false
    };
  },
  computed: {
    navList() {
      return this.$store.state.navList;
    },
    stop() {
      return this.$route.query.stop;
    },
    isChildMenuShow() {
      //子菜单ul 隐藏的条件：1.点击所在一级菜单 2.点击其他一级菜单下的二级菜单
      let mark = true;
    },
    time() {
      return new Date().getTime();
    }
  },
  watch: {
    stop(val) {
      if (val) {
        this.getHight(val);
      }
    }
  },
  created() {
    if (this.navList) {
      this.getPerm(this.navList);
      this.$store.commit("setButtonPower", this.permUrl);
      this.init();
    }
  },
  mounted() {
  },
  methods: {
    //详情页跳到列表页的高亮
    getHight(val) {
      this.navList.forEach((item, index) => {
        if (item.children) {
          let ind = item.children.findIndex(e => e.menu_to == val);

          if (ind > -1) {
            this.navActive = index;
            this.navList[index].isOpen = true;
            // 二级导航定位
            this.navClildActive = "clild" + index + ind;
          }
        } else {
          if (item.to == val) {
            this.navActive = index;
            this.navClildActive = "";
          }
        }
      });
      let curRouter = {
        navActive: this.navActive,
        navClildActive: this.navClildActive,
        open: true
      };
      sessionStorage.removeItem("curRouter");
      sessionStorage.setItem("curRouter", JSON.stringify(curRouter));
    },
    //获取菜单数据
    getMenu(role_id) {
      this.axios("GET", `${this.$phpBaseURL}v1/role/updateinfo`, {
        id: role_id
      }).then(res => {
        if (res.code == 1) {
          this.navList = res.data.perm["0"].children;
          this.getPerm(this.navList);
          this.$store.commit("setButtonPower", this.permUrl);
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    getPerm(arr) {
      //递归获取新数组
      let that = this;
      arr.forEach(item => {
        if (item.children.length == 0) {
          if (item.checked) {
            that.permUrl.push(item.perm_url);
          }
        } else {
          that.getPerm(item.children);
        }
      });
    },
    init() {
      // 一级导航定位
      if (this.$route.query.form == "login") {
        this.navActive = 0;
        this.navList[0].isOpen = true;
        this.navClildActive = "clild00";
        return;
      }
      let routerInfo = sessionStorage.getItem("curRouter");
      if (routerInfo) {
        routerInfo = JSON.parse(routerInfo);
        this.navActive = routerInfo.navActive;
        //此处有个大坑，隐藏的坑，17301066306^.^，底下是等号赋值，接下来，用set赋值就不能被视图更新了
        // this.navList[this.navActive].isOpen=routerInfo.open;
        this.$set(this.navList[this.navActive], "isOpen", routerInfo.open);
        // 二级导航定位
        this.navClildActive = routerInfo.navClildActive;
      }
    },
    itemClick(index, clild = 0) {
      this.navActive = index;
      this.$set(this.navList[index], "isOpen", !this.navList[index].isOpen);
      let curRouter = {
        navActive: this.navActive,
        navClildActive: "",
        open: this.navList[index].isOpen
      };
      sessionStorage.removeItem("curRouter");
      sessionStorage.setItem("curRouter", JSON.stringify(curRouter));
      if (this.navList[index].to != "") {
        this.$emit("crumbs", this.$route.meta.crumbs);
      }
    },
    itemMousemove(index) {
      this.navActive = index;
      this.$set(this.navList[index], "isHoverOpen", true);
    },
    itemsClick(index, item, parentIndex) {
      this.$notify.closeAll()
      let a = new Date().getTime();
      //重复点击同一个路由问题
      if (this.lastClickRouterIndex && index == this.lastClickRouterIndex) {
        this.$router.push(item.menu_to + "?time=" + a);
      }
      // 以下这段是为了点击子菜单的时候，父级菜单高亮
      this.navList.forEach((item, i) => {
        if (parentIndex === i) {
          this.$set(this.navList[i], "isOpen", true);
        } else {
          this.$set(this.navList[i], "isOpen", false);
        }
      });
      if (
        item.to == "/customer" ||
        item.to == "/contacts" ||
        item.to == "/suppliers" ||
        item.to == "/contract" ||
        item.to == "/project"
      ) {
        this.$store.commit("curRouter", true);
      }
      this.navClildActive = index;
      let curRouter = {
        navActive: parentIndex,
        navClildActive: this.navClildActive,
        open: this.navList[parentIndex].isOpen
      };
      sessionStorage.removeItem("curRouter");
      sessionStorage.setItem("curRouter", JSON.stringify(curRouter));
      this.lastClickRouterIndex = index;
      this.$emit("crumbs", this.$route.meta.crumbs);
    },
    navShrink() {
      this.retract = false;
    },
    navExhibition() {
      this.retract = true;
    },
    itemOut(index) {
      this.navList[index].isHoverOpen = false;
    },
    headTo(data) {
      this.init();
    }
  },
  beforeRouteEnter(to, from, next) {
    // do someting
    next(vm => {});
  }
};
</script>

<style scoped lang="scss">
nav {
  width: 240px;
  background: #fff;
  box-shadow: 1px 4px 6px 0px rgba(206, 206, 206, 0.34);

  &.retract {
    width: 48px;
  }
  .nav-wrapper {
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    .item {
      h3 {
        height: 48px;
        position: relative;
        color: #848a9a;
        cursor: pointer;
        font-size: 14px;
        span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            display: inline-block;
            width: 50px;
            text-align: center;
          }
        }
        .iconArrow {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%) scale(0.5);
          font-size: 12px;
        }
        &:hover {
          color: #01ace4 !important;
        }
        &.active {
          color: #01ace4 !important;
        }
      }
      .clild-wrapper {
        .items {
          height: 48px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-left: 50px;
          box-sizing: border-box;
          position: relative;
          &:hover {
            background: rgba(1, 172, 228, 0.05);
            &:after {
              content: "";
              display: block;
              width: 4px;
              height: 48px;
              background: #01ace4;
              border-radius: 0px 4px 4px 0px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &.select {
            background: rgba(1, 172, 228, 0.05);
            &:after {
              content: "";
              display: block;
              width: 4px;
              height: 48px;
              background: #01ace4;
              border-radius: 0px 4px 4px 0px;
              position: absolute;
              top: 0;
              left: 0;
            }
            span {
              color: #01ace4 !important;
            }
          }
          span {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            color: #848a9a;
            font-size: 14px;
          }
        }
      }
    }
  }
  .nav-wrapper-shrink {
    .item {
      position: relative;
      h3 {
        height: 48px;
        position: relative;
        cursor: pointer;
        span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            display: inline-block;
            width: 50px;
            text-align: center;
          }
        }
        &.active {
          background: rgba(1, 172, 228, 0.05);
          span {
            i {
              color: #01ace4;
            }
          }
          &:after {
            content: "";
            display: inline-block;
            box-sizing: border-box;
            width: 0;
            height: 100%;
            border: 4px solid transparent;
            border-left-color: #fe6f5d;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .clild-wrap {
        width: 216px;
        position: absolute;
        right: -226px;
        top: 0;
        padding: 4px;
        z-index: 5;
        background: white;
        box-shadow: 0 0 10px #ccc;
        border-radius: 4px;
        .items {
          height: 48px;
          box-sizing: border-box;
          cursor: pointer;
          span {
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 24px;
            box-sizing: border-box;
            font-size: 14px;
          }
          &:hover {
            background: rgba(255, 110, 90, 0.05);
          }
        }
      }
    }
  }
}
.gztxialakuangjiantou {
  position: absolute;
  right: 8px;
  top: 16px;
  font-size: 12px !important;
  color: #848a9a !important;
}

.arrowTransform {
  // transition: 0.3s;
  transform-origin: center;
  transform: rotateZ(180deg) scale(0.5);
}
.arrowTransformReturn {
  // transition: 0.3s;
  transform-origin: center;
  transform: rotateZ(0deg) scale(0.7);
}
// //  定义进入前与离开后状态
.name-enter,
.name-leave-to {
  max-height: 0px;
}
// 定义离开前与进入后状态
.name-leave,
.name-enter-to {
  max-height: 1000px;
}
// 定义进出过程
.name-enter-active {
  transition: max-height 5.3s;
}
.name-leave-active {
  transition: max-height 0.2s;
}
</style>