import axios from 'axios';
import store from '@/store/index.js';
import { Message } from 'element-ui';
import router from "@/router"; // 路由配置
import $ from 'jquery'
async function config(config) {
  return new Promise((resolve, reject) => {
    console.info('wx.config', config)
    wx.config(config)
    wx.ready(resolve)
    wx.error(reject)
  }).then(() => {
    console.info('wx.ready')
  }, error => {
    console.error('wx.error', error)
    // throw error
  })
}
async function agentConfig(config) {
  return new Promise((success, fail) => {
    console.info('wx.agentConfig', config)
    wx.agentConfig({
      ...config,
      success,
      fail
    })
  }).then(res => {
    console.info('wx.agentConfig success', res)
    return res
  }, error => {
    console.error('wx.agentConfig fail', error)
    // throw error
  })
}

export default {
  php:  'bmp/api/',
  java:  'bm/api/',
  configurationData: {},
  /*打开eip*/
  openEip(id) {
    axios.defaults.headers.common['language'] = store.state.lang;
    axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
    this.axios('get', `${this.php}v1/${id}`, {
      token: JSON.parse(localStorage.getItem('user_info')).token,
    }).then(res => {
      if (res.code == 1) {
        window.open(res.data.url, '_blank');
      } else {
        Message({
          message: res.msg,
          type: 'warning'
        });
      }
    })
  },

  jqAjax(url, data, type, seccFun, errFun) {
    $.ajax({
      url: url,
      data: data,
      type: (type !== '' ? type : 'post'),
      //contentType:'application/json',
      dataType: "jsonp",
      cache: false,
      beforeSend: function (XMLHttpRequest) {
        var apiToken = "token";
        var tokenStr = JSON.parse(localStorage.getItem('user_info')).token;
        XMLHttpRequest.setRequestHeader(apiToken, tokenStr && tokenStr != '' ? tokenStr : '');
      },
      success: (data) => {
        if (seccFun) {
          seccFun(data)
        }
      },
      error: (jqXHR, error, errorThrown) => {
        if (errFun) {
          errFun(jqXHR, error, errorThrown)
          return false
        } else {
          /*var arr=location.href.split('/');
           arr[arr.length-1]='500';
           location.href=arr.join('/');*/
        }
        // alert(jqXHR.status)
      }
    })
  },

  //公共请求方法
  axios(method, url, params = {}) {
    axios.defaults.headers.common['language'] = store.state.lang;
    if (localStorage.getItem('token')) {
      axios.defaults.headers.common['token'] = localStorage.getItem('token');
    }
    if (method.toLowerCase() == 'get') {
      return new Promise((resolve, reject) => {
        axios.get(url, {
          params: params
        })
          .then(response => {
            if (response.data.code == 20030) { //token过期、错误
              localStorage.removeItem('token');
              //location.href=location.origin+'/web/login';
            } else {
              resolve(response.data);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios({
          method: method,
          url: url,
          data: params
        })
          .then(response => {
            if (response.data.code == 20030) { //token过期、错误
              localStorage.removeItem('token');
              //location.href=location.origin+'/web/login';
            } else {
              resolve(response.data);
            }
          })
          .catch(err => {
            reject(err)
          });
      });
    }
  },

  /*
   频次统计
   @param mod 模块(列表)
   @param type 类型(1列表 2功能)
   */
  statisticsAdd(mod, type) {
    this.axios('POST', `${this.php}v1/action_log/statisticsAdd`, {
      mod: mod,
      type: type
    }).then(res => {
      if (res.code == 1) {
        //成功
        console.log(mod + " 统计成功");
      } else {
        //失败
      }
    })
  },
  // 获取搜索项列表
  getConfigData(type) {
    if (localStorage.getItem('token')) {
      axios.defaults.headers.common['token'] = localStorage.getItem('token');
    }
    return new Promise((resolve, reject) => {
      this.axios("POST", `${this.php}v1/config/datas`, {
        type: type
      }).then(response => {
        if (response.data.code == 20030) { //token过期、错误
          localStorage.removeItem('token');
        } else {
          resolve(response);
        }
      }).catch(err => {
        reject(err);
      });
    });


  },
  // 获取个人信息
  getPersonalInit() {
    if (localStorage.getItem('token')) {
      axios.defaults.headers.common['token'] = localStorage.getItem('token');
    }
    return new Promise((resolve, reject) => {
      axios.get(`${this.php}organization/v1/companys/oneself`, {
        params: {}
      })
        .then(response => {
          if (response.data.code == 20030) { //token过期、错误
            localStorage.removeItem('token');
            //location.href=location.origin+'/web/login';
          } else {
            resolve(response.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 上传图片
  uploadedImg(data) {
    if (localStorage.getItem('token')) {
      axios.defaults.headers.common['token'] = localStorage.getItem('token');
      axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return new Promise((resolve, reject) => {
      axios.post(`${this.php}organization/v1/companys/uploadLogo`, data)
        .then(response => {
          if (response.data.code == 20030) { //token过期、错误
            Message.error('token过期!')
            localStorage.removeItem('token');
            location.href = location.origin + '/web/login';
          } else {
            resolve(response.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 星号处理手机号
  noPassByMobile(str) {
    if (null != str && str != undefined) {
      var pat = /(\d{3})\d*(\d{4})/;
      return str.replace(pat, '$1****$2');
    } else {
      return "";
    }
  },
  permFilter(val) { //过滤按钮权限
    // "bmp/api/v1/client/xjkh
    let permUrl = store.state.buttonPower
    let flag = false
    if (permUrl) {
      let ind = permUrl.findIndex((item) => item == val)
      if (ind > -1) {
        flag = true
      }
    }
    return flag
  },
  /**
   * selectInit elements-ui select 下拉框数据格式化
   * arr 数组
   * value 返回后台的ID
   * label 显示在页面上的文案
   * options 子级数据
   * */
  selectInit(arr, value, label, options) {
    var newArr = [];
    arr.forEach(e => {
      var arrs = {};
      arrs.value = e[value];
      arrs.label = e[label];
      if (options) {
        arrs.options = [];
        arrs.options.push({
          value: e[options][value],
          label: e[options][label],
        })
      }
      newArr.push(arrs)
    });
    return newArr;
  },
  getRequest() { //将url中的参数/值转换成Object
    var url = window.location.search ? window.location.search : window.location.hash; //获取url中"?"符后的字串
    //console.log(window.parent.document.getElementById("behavior_iframe").contentWindow.location.search)
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var index = url.indexOf("?");
      var str = url.substr(index + 1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  },
  getSignature(url) { //获取签名
    this.axios('GET', this.php + 'qywechat/v1/getSignPackage', {
      appId: store.state.corpid,
      agentid: store.state.agentid,
      url: url
    })
      .then(res => {
        if (res.code == 1) {
          if (/MicroMessenger/i.test(navigator.userAgent)) { //判断是不是微信内置浏览器
            config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.cropSign.appId, // 必填，企业微信的corpID
              timestamp: res.data.cropSign.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.cropSign.nonceStr, // 必填，生成签名的随机串
              signature: res.data.cropSign.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
              jsApiList: ['checkJsApi'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来 //'openEnterpriseChat','selectEnterpriseContact',
            })
          }
          agentConfig({
            corpid: res.data.agentSign.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: res.data.agentSign.agentid, // 必填，企业微信的应用id
            timestamp: res.data.agentSign.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.agentSign.nonceStr, // 必填，生成签名的随机串
            signature: res.data.agentSign.signature, // 必填，签名，见附录1
            jsApiList: [], //必填 //'selectExternalContact','getCurExternalContact'
            success: function (res) { // 回调
            },
            fail: function (res) {
              if (res.errMsg.indexOf('function not exist') > -1) {
                // alert('版本过低请升级')
                Message.error('版本过低请升级');
              }
            }
          })
        } else {
          // Message({message:'授权信息获取失败，将影响页面使用，请退出重新登陆', type: 'warning' });
        }
      })
  },
  getSignatureIds(url) {
    let _this = this;
    this.axios('GET', this.php + 'organization/v1/companys/authDetailInfo', {}).then(res => {
      if (res.code == 1) {
        store.commit('setCorpid', res.data.corp_id);
        store.commit('setAgentid', res.data.app_agentid);
        _this.getSignature(url);
      } else {
        // Message({message:'授权信息获取失败，将影响页面使用，请退出重新登陆', type: 'warning' });
      }
    })
  },
  platformFilter(val, objparam) {
    let text = "-";
    if (val) {
      let keys = Object.keys(objparam);
      let values = Object.values(objparam);
      let ind = keys.findIndex(item => item == val);
      text = values[ind];
    }
    return text;
  },
  tokenExpired(msg) {
    //过期跳页
    Message({
      message: msg,
      type: 'warning'
    });
    localStorage.removeItem('user_info');
    localStorage.removeItem('token');
    router.push('/login');
  },
  toThousands(s, n) {
    if (!s) {
      s = 0.00
      return
    } else {
      s = s.toString()
    }
    let is = false
    if (s.indexOf('-') == 0) {
      s = s.substr(1);
      is = true
    }
    if (n >= 0 && n <= 20) {
      s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    } else {
      s = parseFloat((s + "").replace(/[^\d\.-]/g, "")) + "";
    }
    var l = s.split(".")[0].split("").reverse(),
      r = s.split(".")[1];
    r = (r == null ? "" : "." + r);
    let t = "";
    for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
    }

    let res = t.split("").reverse().join("") + r
    if (is) {
      res = '-' + res
    }
    return res;
  },
  trim(s) {
    return s.replace(/(^\s*)|(\s*$)/g, "");
  }
}