<template>
  <div v-loading="loading" class="iodetail">
    <div class="main-div title" style="display:flex;align-items:center;">
      <el-button size="mini" @click="backHistory">返回</el-button>
      <p style="margin-left:20px;">{{ioName}}</p>
    </div>
    <div class="main-div">
      <section class="section_">
        <div class="section_title">
          <p>客户投放计划（Plans）</p>
          <div class="main-button" @click="addNewPlan()">New Plan</div>
        </div>
        <el-table :data="planList" border style="width: 100%">
          <el-table-column type="index" width="150px" label="#"></el-table-column>
          <el-table-column width="150px" label="Network">
            <template slot-scope="scope">
              <span v-for="(item,index) in scope.row.network" :key="index">{{getNetworkName(item) }}</span> 
            </template>
          </el-table-column>
          <el-table-column label="Type">
            <template slot-scope="scope">
              {{scope.row.data && scope.row.data.length > 0 ? country_type_list[scope.row.data[0].group.type] : ''}}
            </template>
          </el-table-column>
          <el-table-column label="Country" show-overflow-tooltip>
            <template
              slot-scope="scope"
            >{{scope.row.data && scope.row.data.length > 0 ? scope.row.data[0].group.value : ''}}</template>
          </el-table-column>
          <el-table-column label="Price">
            <template
              slot-scope="scope"
            >{{scope.row.data[0].paid_type ? (scope.row.data[0].paid_type == 'cost' ? 'cost' :((scope.row.data && scope.row.data.length>0 && scope.row.data[0].paid_detail && scope.row.data[0].paid_detail.section) ? getPrice(scope.row.data[0].paid_detail.section) : scope.row.data[0].paid_detail.price)) : ''}}</template>
          </el-table-column>
          <el-table-column label="Date">
            <template
              slot-scope="scope"
            >{{scope.row.data && scope.row.data.length > 0 ? scope.row.data[0].starttime + '-' + (scope.row.data[0].endtime ? scope.row.data[0].endtime : '长期有效') : ''}}</template>
          </el-table-column>
          <el-table-column width="150px" label="Edit">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="editPlanMethod(scope.row,scope.$index)">Edit</span>
              <span class="main-tableToInfo" @click="delPlanMethod(scope.$index)">Delete</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </div>
    <div class="main-div">
      <section class="section_">
        <div class="section_title">
          <p>关联Account ID</p>
          <div class="search_form"></div>
          <div class="main-button" @click="addNewLink()">New Link</div>
        </div>
        <el-table :data="linkList" border style="width: 100%">
          <el-table-column width="150px" type="index" label="#"></el-table-column>
          <el-table-column prop="network" width="150px" label="Network">
            <template slot-scope="scope">
              <div>
                {{getNetworkName(scope.row.network)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Details">
            <template slot-scope="scope">
              <div style="white-space:break-spaces;display:flex;">
                <span>Account ID Equal : </span><div style="flex:1;">{{scope.row.predicate && scope.row.predicate.length>0 && scope.row.predicate[0].value ? (scope.row.predicate[0].value.starttime ? 'Date Between : '+scope.row.predicate[0].value.starttime +'-'+scope.row.predicate[0].value.endtime : scope.row.predicate[0].value) : ''}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150px" label="Edit">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="editLinkMethod(scope.row,scope.$index)">Edit</span>
              <span class="main-tableToInfo" @click="delLinkMethod(scope.$index)">Delete</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </div>
    <div class="main-div">
      <section class="section_">
        <div class="section_title">
          <p>Remarks</p>
          <div class="search_form"></div>
          <div class="main-button" @click="addNewRemark()">New Remark</div>
        </div>
        <el-table :data="remarkList" border style="width: 100%">
          <el-table-column type="index" width="150px" label="#"></el-table-column>
          <el-table-column prop="remark" label="Remark"></el-table-column>
          <el-table-column width="150px" label="Edit">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="editRemarkMethod(scope.row,scope.$index)">Edit</span>
              <span class="main-tableToInfo" @click="delRemarkMethod(scope.$index)">Delete</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </div>
    <div class="main-div">
      <section class="section_">
        <div class="section_title">
          <p>核减/加收费率</p>
          <div class="search_form"></div>
          <div>
            <span style="color:#f00;font-size:12px;">收入核减10% , Ratio录入0.1 ; 加收10% , 录入-0.1（转化为小数）</span>
            <div class="main-button" @click="addSpend()">New Ratio</div>
          </div>
        </div>
        <el-table :data="spendList" border style="width: 100%">
          <el-table-column type="index" width="150px" label="#"></el-table-column>
          <el-table-column prop="network" label="Network">
            <template slot-scope="scope">
              <div>
                {{getNetworkName(scope.row.network)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="starttime" label="Starttime"></el-table-column>
          <el-table-column prop="endtime" label="Endtime"></el-table-column>
          <el-table-column prop="ratio" label="Ratio"></el-table-column>
          <el-table-column width="150px" label="Edit">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="editRatioMethod(scope.row,scope.$index)">Edit</span>
              <span class="main-tableToInfo" @click="delRatioMethod(scope.$index)">Delete</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </div>
    <div class="main-div">
      <section class="section_">
        <div class="section_title">
          <p>收入返点</p>
          <div class="search_form"></div>
          <div>
            <span style="color:#f00;font-size:12px;">收入返10个点 , Ratio录入0.1（转化为小数）</span>
            <div class="main-button" v-show="clientRebateType == 2" @click="addNewRebate()">New Rebate</div>
          </div>
        </div>
        <el-table :data="rebateList" border style="width: 100%">
          <el-table-column type="index" width="150px" label="#"></el-table-column>
          <el-table-column prop="network" label="Network">
            <template slot-scope="scope">
              <div>
                {{getNetworkName(scope.row.network)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="starttime" label="Starttime"></el-table-column>
          <el-table-column prop="endtime" label="Endtime"></el-table-column>
          <el-table-column label="Ratio">
            <template
              slot-scope="scope"
            >{{ratio_type_list[scope.row.ratio_type]}} {{scope.row.ratio_type == 1?scope.row.ratio:(scope.row.ratio_type == 2 ? '':getsectionRatio(scope.row.section_ratio))}}</template>
          </el-table-column>
          <el-table-column width="150px" label="Edit">
            <template slot-scope="scope">
              <span class="main-tableToInfo" v-show="clientRebateType == 2" @click="editRebateMethod(scope.row,scope.$index)">Edit</span>
              <span class="main-tableToInfo" v-show="clientRebateType == 2" @click="delRebateMethod(scope.$index)">Delete</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </div>
    <div class="main-div title">
      <div class="main-button" @click="submitDetail()">Submit</div>
      <div class="main-button danger" @click="deleteIO()">Delete</div>
    </div>
    <!-- edit plan -->
    <div class="main-pop large" v-show="showEditPlan">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">Edit Plan</span>
          <i class="gongzuotai gztguanbi" @click="hide()"></i>
        </div>
        <div class="body">
          <p>客户计划投放平台（可多选）</p>
          <el-checkbox-group v-model="editPlanItem.network" style="margin:20px 0;">
            <el-checkbox v-for="(i,k) in configurationData.io_plan_network" :key="k" :label="k" :value="k">{{i}}</el-checkbox>
          </el-checkbox-group>
          <div class="main-button" style="margin-bottom:10px;" @click="addNewPlanStage()">New Stage</div>
          <dl>
            <dt>▪ 历史投放数据必须对应计划，投放阶段中止可为计划添加“End Time”，但不要“Delete Plan”，否则将造成历史数据混乱！</dt>
            <dd>・Start Time：必须正确填写，代表广告投放只能在“此日期之后”开始，若实际投放始于此日期之前，将导致数据未匹配问题。</dd>
            <dd>・End Time：若无，则不填写，代表该投放计划截止日期。</dd>
            <dd>・Country：若不要求对投放国家负责请选择Global，默认指定国家选择Accept，支持多个英文、中文、简称按分隔符同时录入。</dd>
            <dd>・结算方式：默认选择CPA Regular（常规CPA）配合录入美金单价，自投客户一般选择Cost（按消耗）。</dd>
          </dl>
          <el-form
            v-for="(planitem,key) in editPlanItem.data"
            :key="key"
            size="mini"
            label-position="left"
            label-width="100px"
            style="border-bottom:1px solid #333;"
          >
            <el-form-item label="Time" style="margin-bottom:10px;margin-top:20px;">
              <div style="display:flex;align-items:center;">
                <el-date-picker
                  style="width:150px;"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  size="mini"
                  v-model="editPlanItem.data[key].starttime"
                  type="date"
                  @blur="selectPlanRange(key)"
                  placeholder="Start Time">
                </el-date-picker>
                <span style="margin:0 20px;"> - </span>
                <el-date-picker
                  style="width:150px;"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  size="mini"
                  v-model="editPlanItem.data[key].endtime"
                  type="date"
                  :picker-options="planPickerOptions"
                  placeholder="End Time">
                </el-date-picker>
                <el-button type="danger" style="margin-left:20px;" @click="deleteStagePlan(planitem,key)">Delete Stage</el-button>
              </div>
            </el-form-item>
            <el-form-item label="Country">
              <div style="display:flex;">
                <el-select
                  size="mini"
                  v-model="editPlanItem.data[key].group.type"
                  style="width:150px;"
                >
                  <el-option
                    v-for="(i,k) in country_type_list_obj"
                    :key="k"
                    :label="i.label"
                    :value="i.value"
                  ></el-option>
                </el-select>
                <el-input
                  v-model="editPlanItem.data[key].group.value"
                  placeholder="Separated countrys by a ,，/。！？"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item v-show="editPlanItem.data[key].group.type!='country_reject'">
              <div style="display:flex;align-items:center;">
                <el-select
                  @change="paidTypeSelect(key)"
                  size="mini"
                  v-model="editPlanItem.data[key].paid_type"
                  style="width:220px;"
                >
                  <el-option v-for="(i,k) in paid_type" :key="k" :label="i.label" :value="i.value"></el-option>
                </el-select>
                <div class="main-button" @click="addPlanSection(key)" v-show='editPlanItem.data[key].paid_type != "cpa_regular" &&editPlanItem.data[key].paid_type != "cpc_regular" &&editPlanItem.data[key].paid_type != "cost"' style="margin-left:20px;">New</div>
              </div>
            </el-form-item>
            <el-form-item v-if="editPlanItem.data[key].paid_type!='cost'">
              <el-form
                :inline="true"
                size="mini"
                label-position="left"
                v-show="editPlanItem.data[key].paid_type == 'cpa_regular' || editPlanItem.data[key].paid_type == 'cpc_regular'"
              >
                <el-form-item label="Pirce">
                  <el-input style="width:200px;" v-model="editPlanItem.data[key].paid_detail.price"></el-input>
                </el-form-item>
              </el-form>
              <el-form
                :inline="true"
                size="mini"
                label-position="left"
                v-show="editPlanItem.data[key].paid_type == 'cpa_section_with_percentage' || editPlanItem.data[key].paid_type == 'cpc_section_with_percentage'"
              >
                <el-form-item label="Percentage">
                  <el-input style="width:200px;" v-model="editPlanItem.data[key].paid_detail.percentage"></el-input>
                </el-form-item>
              </el-form>
              <el-form
                v-for="(detail,inx) in editPlanItem.data[key].paid_detail.section"
                :key="inx"
                :inline="true"
                size="mini"
                label-position="left"
                v-show="editPlanItem.data[key].paid_type == 'cpa_section' || editPlanItem.data[key].paid_type == 'cpc_section' || editPlanItem.data[key].paid_type == 'cpa_section_with_percentage' || editPlanItem.data[key].paid_type == 'cpc_section_with_percentage'"
              >
                <el-form-item label="From">
                  <el-input
                    style="width:100px"
                    v-model="editPlanItem.data[key].paid_detail.section[inx].start"
                  ></el-input>
                </el-form-item>
                <el-form-item label="To">
                  <el-input
                    style="width:100px"
                    v-model="editPlanItem.data[key].paid_detail.section[inx].end"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Price">
                  <el-input
                    style="width:100px"
                    v-model="editPlanItem.data[key].paid_detail.section[inx].price"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="inx>0">
                  <el-button type="danger" @click="delPLanSection(key)">Delete</el-button>
                </el-form-item>
              </el-form>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="hide()">Close</button>
          <button class="main-button r" @click="saveEditPlan()">OK</button>
        </div>
      </div>
    </div>
    <!-- edit link -->
    <div class="main-pop large" v-show="showEditLink">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">Edit Link</span>
          <i class="gongzuotai gztguanbi" @click="hide()"></i>
        </div>
        <div class="body">
          <el-form size="mini" label-position="left" label-width="100px">
            <el-form-item label="Network">
              <el-select style="width:200px;" @change="linkNetworkSelect()" v-model="editLinkItem.network" size="mini">
                <el-option
                  v-for="(i,k) in configurationData.network_array"
                  :key="i"
                  :label="i"
                  :value="k">
                </el-option>
              </el-select>
              <div class="main-button" v-if="editLinkItem.predicate.length<5" style="margin-bottom:10px;margin-left:20px;" @click="addPreicateItem()">New Preicate</div>
            </el-form-item>
          </el-form>
          <div v-for="(linkitem,keyi) in editLinkItem.predicate" :key="keyi" style="margin-bottom:20px;">
            <el-select style="width:200px;" v-model="linkitem.key" size="mini" placeholder="select..." @change="linkTypeChange(linkitem,keyi)">
              <el-option
                v-for="(item,itemkey) in link_predicate_key[editLinkItem.network]"
                :key="itemkey"
                :label="item.name"
                :disabled="item.disabled"
                :value="item.type">
              </el-option>
            </el-select>
            <el-select style="width:150px;" v-model="linkitem.type" @change="linkItemKeyChange(keyi)" size="mini" placeholder="select...">
              <el-option
                v-for="(item,itemkey) in predicate_type[linkitem.key]"
                :key="itemkey"
                :label="item.type"
                :value="item.value">
              </el-option>
            </el-select>
            <div v-if="linkitem.key == 'date'" style="display:inline-flex;align-items:center;">
              <el-date-picker
                style="width:150px;"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                size="mini"
                v-model="linkitem.value.starttime"
                type="date"
                placeholder="Start Time">
              </el-date-picker>
              <span style="margin:0 5px;"> - </span>
              <el-date-picker
                style="width:150px;"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                size="mini"
                v-model="linkitem.value.endtime"
                type="date"
                :picker-options="planPickerOptions"
                placeholder="End Time">
              </el-date-picker>
            </div>
            <el-input v-else size="mini" v-model="linkitem.value" style="width:320px;" placeholder="多个广告账户用英文','和空格分隔"></el-input>
            <el-button size="mini" type="danger" @click="delPreicateItem(keyi)">Delete</el-button>
          </div>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="hide()">Close</button>
          <button class="main-button r" @click="saveEditLink()">OK</button>
        </div>
      </div>
    </div>
    <!-- edit remark -->
    <div class="main-pop large" v-show="showRemarkLink">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">Edit Remark</span>
          <i class="gongzuotai gztguanbi" @click="hide()"></i>
        </div>
        <div class="body">
          <el-input
            type="textarea"
            placeholder=""
            v-model="editRemarkItem.remark">
          </el-input>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="hide()">Close</button>
          <button class="main-button r" @click="saveRemarkItem()">OK</button>
        </div>
      </div>
    </div>
    <!-- edit radio -->
    <div class="main-pop large" v-show="showRatioLink">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">Edit Ratio</span>
          <i class="gongzuotai gztguanbi" @click="hide()"></i>
        </div>
        <div class="body">
          <el-form label-position="left" size="mini" label-width="100px">
            <el-form-item label="Network">
              <el-select style="width:200px;" v-model="editRatioItem.network" >
                <el-option
                  v-for="(i,k) in configurationData.network_array"
                  :key="k"
                  :label="i"
                  :value="k">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Time">
              <!-- <el-date-picker
                style="width:350px;"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                size="mini"
                type="daterange"
                range-separator="-"
                unlink-panels
                :picker-options="datepickerOptions"
                start-placeholder="Start Time"
                v-model="editRatioItem.daterange"
                end-placeholder="End Time"
                @blur="spendTimeRange"
              ></el-date-picker> -->
              <div style="display:flex;align-items:center;">
                <el-date-picker
                  style="width:150px;"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  size="mini"
                  v-model="editRatioItem.starttime"
                  type="date"
                  @blur="ratioTimeRange"
                  placeholder="Start Time">
                </el-date-picker>
                <span style="margin:0 20px;"> - </span>
                <el-date-picker
                  style="width:150px;"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  size="mini"
                  v-model="editRatioItem.endtime"
                  type="date"
                  :picker-options="planPickerOptions"
                  placeholder="End Time">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="Ratio">
              <el-input v-model="editRatioItem.ratio"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="hide()">Close</button>
          <button class="main-button r" @click="saveSpendItem()">OK</button>
        </div>
      </div>
    </div>
    <!-- edit rebate -->
    <div class="main-pop large" v-show="showEditRebate">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">Edit Rebate</span>
          <i class="gongzuotai gztguanbi" @click="hide()"></i>
        </div>
        <div class="body">
          <el-form label-position="left" size="mini" label-width="100px">
            <el-form-item label="Network">
              <el-select style="width:200px;" v-model="editRebateItem.network" size="mini">
                <el-option
                  v-for="(i,k) in configurationData.network_array"
                  :key="k"
                  :label="i"
                  :value="k">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Time">
              <!-- <el-date-picker
                style="width:350px;"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                size="mini"
                type="daterange"
                range-separator="-"
                unlink-panels
                :picker-options="datepickerOptions"
                start-placeholder="Start Time"
                v-model="editRebateItem.daterange"
                end-placeholder="End Time"
                @blur="spendTimeRange"
              ></el-date-picker> -->
              <div style="display:flex;align-items:center;">
                <el-date-picker
                  style="width:150px;"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  size="mini"
                  v-model="editRebateItem.starttime"
                  type="date"
                  @blur="spendTimeRange"
                  placeholder="Start Time">
                </el-date-picker>
                <span style="margin:0 20px;"> - </span>
                <el-date-picker
                  style="width:150px;"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  size="mini"
                  v-model="editRebateItem.endtime"
                  type="date"
                  :picker-options="planPickerOptions"
                  placeholder="End Time">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="Ratio">
              <div style="display:flex;">
                <el-select style="width:130px;" v-model="editRebateItem.ratio_type" size="mini">
                  <el-option
                    v-for="item in ratioType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-input v-if="editRebateItem.ratio_type == 1" v-model="editRebateItem.ratio"></el-input>
                <div v-if="editRebateItem.ratio_type == 3" class="main-button" style="margin-bottom:10px;margin-left:20px;" @click="addNewCost()">New Cost</div>
              </div>
            </el-form-item>
            <!-- <el-form-item label="Cost">
              <span>模板：</span>
              <el-select style="width:130px;" v-model="editRebateItem.ratio_type" size="mini">
                <el-option
                  v-for="item in ratioType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-button type="success" @click="openTplList()">新建模板</el-button>
            </el-form-item> -->
            <el-form-item v-if="editRebateItem.ratio_type == 3">
              <el-form
                v-for="(detail,inx) in editRebateItem.section_ratio"
                :key="inx"
                :inline="true"
                size="mini"
                label-position="left"
              >
                <el-form-item label="From">
                  <el-input
                    style="width:100px"
                    v-model="detail.start"
                  ></el-input>
                </el-form-item>
                <el-form-item label="To">
                  <el-input
                    style="width:100px"
                    v-model="detail.end"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Ratio">
                  <el-input
                    style="width:100px"
                    v-model="detail.ratio"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="inx>0">
                  <el-button type="danger" @click="delCost(inx)">Delete</el-button>
                </el-form-item>
              </el-form>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="hide()">Close</button>
          <button class="main-button r" @click="saveEditRebate()">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import { mkdir, mkdirSync } from "fs";

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      loading: false,
      ratioType:[
        {
          value:1,
          label:'固定返点'
        },{
          value:3,
          label:'阶梯返点'
        },
      ],
      datepickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      link_predicate_key: {
        "1":[
          {
            "name":"Account ID",
            "type":"account",
            disabled:false,
          },
          {
            "name":"Campaign Name",
            disabled:false,
            "type":"campain_name"
          },
          {
            "name":"Promote Link",
            disabled:false,
            "type":"promote_link"
          },
          {
            "name":"Date",
            disabled:false,
            "type":"date"
          },
          {
            "name":"Device",
            disabled:false,
            "type":"platform"
          }
        ],
        "3" :[
          {
            "name":"Account",
            disabled:false,
            "type":"account"
          },
          {
            "name":"Campaign",
            disabled:false,
            "type":"campaign"
          },
          {
            "name":"Promote Link",
            disabled:false,
            "type":"promote_link"
          },
          {
            "name":"Date",
            disabled:false,
            "type":"date"
          },
          {
            "name":"Device",
            disabled:false,
            "type":"platform"
          }
        ],
        "2" :[
          {
            "name":"Ad Account",
            disabled:false,
            "type":"account"
          },
          {
            "name":"Campaign",
            disabled:false,
            "type":"campaign"
          },
          {
            "name":"Promote Link",
            disabled:false,
            "type":"promote_link"
          },
          {
            "name":"Date",
            disabled:false,
            "type":"date"
          },
          {
            "name":"Device",
            disabled:false,
            "type":"platform"
          }
        ],
        "4" :[{
            "name":"Id",
            disabled:false,
            "type":"Id"
          }
        ],
        "5" : [{
            "name":"Account ID",
            disabled:false,
            "type":"account"
          }
        ]
      },
      predicate_type:{
        'account':[
          {
            value:"equal",
            type:"Equal"
          },{
            value:"not_equal",
            type:"Not Equal"
          },
        ],
        'campain_name':[
          {
            value:"equal",
            type:"Equal"
          },{
            value:"not_equal",
            type:"Not Equal"
          },{
            value:"prefix",
            type:"Prefix"
          },
        ],
        'promote_link':[
          {
            value:"equal",
            type:"Equal"
          },{
            value:"not_equal",
            type:"Not Equal"
          },{
            value:"prefix",
            type:"Prefix"
          },
        ],
        'date':[
          {
            value:'between',
            type:'Between'
          }
        ],
        'platform':[
          {
            value:"equal",
            type:"Equal"
          },{
            value:"not_equal",
            type:"Not Equal"
          },
        ],
        'mcc':[
          {
            value:"equal",
            type:"Equal"
          },{
            value:"not_equal",
            type:"Not Equal"
          },
        ],
        'campaign':[
          {
            value:"equal",
            type:"Equal"
          },{
            value:"not_equal",
            type:"Not Equal"
          },{
            value:"prefix",
            type:"Prefix"
          },
        ],
        'Id':[
          {
            value:"equal",
            type:"Equal"
          }
        ]
      },
      showEditPlan: false,
      showEditLink: false,
      showRemarkLink: false,
      showRatioLink:false,
      showEditRebate:false,
      planpostdata: {
        country: "",
        state: "",
        platform: "",
        date: ""
      },
      toastName: "",
      showToast: false,
      io_id: "",
      checkoutlist: [],
      showFlag: 1,
      iostatusoptions: [
        {
          label: "激活",
          value: 1
        },
        {
          label: "暂停",
          value: 2
        },
        {
          label: "删除",
          value: 3
        }
      ],
      planList: [],
      linkList: [],
      rebateList: [],
      remarkList: [],
      spendList: [],
      country_type_list: {
        country_accept: "Accept",
        country_reject: "Reject",
        country_global: "Global"
      },
      country_type_list_obj: [
        {
          value: "country_accept",
          label: "Accept"
        },
        {
          value: "country_reject",
          label: "Reject"
        },
        {
          value: "country_global",
          label: "Global"
        }
      ],
      paid_type: [
        {
          value: "cpa_regular",
          label: "CPA Regular"
        },
        {
          value: "cpa_section",
          label: "CPA Section"
        },
        {
          value: "cpa_section_with_percentage",
          label: "CPA Section With Percentage"
        },
        {
          value: "cpc_regular",
          label: "CPC Regular"
        },
        {
          value: "cpc_section",
          label: "CPC Section"
        },
        {
          value: "cpc_section_with_percentage",
          label: "CPC Section With Percentage"
        },
        {
          value: "cost",
          label: "Cost"
        }
      ],
      ratio_type_list: {
        "1": "固定返点",
        "2": "跟随平台返点",
        "3": "阶梯返点"
      },
      editPlanItem: {
        network: ['1'],
        created_at: "",
        daterange: [],
        data: [
          {
            group: { type: "country_accept", value: "" },
            endtime: moment().format("YYYY-MM-DD"),
            paid_detail: { price: "0.00" },
            paid_type: "cpa_regular",
            starttime: moment().format("YYYY-MM-DD")
          }
        ]
      },
      editPlanItemIndex:0,
      editLinkItem:{
        "network": '1',
        "predicate": [
            {
              "key": "account",
              "type": "equal",
              "value": ""
            }
        ],
      },  
      copyeditLinkItem:{},
      editLinkItemIndex:0,
      editRemarkItem:{
        remark:"",
      },
      editRemarkItemIndex:0,
      editRatioItem:{
        endtime: moment().format("YYYY-MM-DD"),
        network: "1",
        ratio: "0",
        starttime: moment().format("YYYY-MM-DD"),
      },
      editRatioItemIndex:0,
      editRebateItem:{
        endtime: moment().format("YYYY-MM-DD"),
        network: "1",
        ratio: "0",
        ratio_type: 1,
        section_ratio: [],
        starttime: moment().format("YYYY-MM-DD"),
        status: 1,
      },
      editRebateItemIndex:0,
      clientRebateType:1,
      ioName:"",
      configurationData: {
        io_plan_network:{},
        network_array:{
          1:"Facebook",
          2:"Twitter",
          3:"Google",
          5:"Tiktok"
        }
      },
      planPickerOptions:{},
      choosedPreicate:[]
    };
  },
  filters: {},
  mounted() {
    this.common.getConfigData('client_level,project_principal,project_authorization_list').then((res)=>{
      this.configurationData = res.data
    }).catch(err => {
      this.$message({ message: err.msg, type: "warning" });      
    })
  },
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    this.io_id = this.$route.query.id;
    if (this.io_id) {
      this.getIOdetail();
    }
  },
  methods: {
    linkTypeChange(item,key){
      console.log(item);
      if (this.editLinkItem.predicate[key].key == 'date') {
        this.editLinkItem.predicate[key].type = ""
        this.editLinkItem.predicate[key].value = {
          starttime:"",
          endtime:""
        }
      }else{
        this.editLinkItem.predicate[key].type = ""
        this.editLinkItem.predicate[key].value = ""
      }
    },
    backHistory(){
      if (this.$route.query.from) {
        if (this.$route.query.from == 'ioList') {
          this.$router.push({
            name:this.$route.query.from,
            params:this.$route.query.param ? JSON.parse(this.$route.query.param) : {}
          })
        }else{
          this.$router.push({
            name:this.$route.query.from,
            query:{
              id:this.$route.query.param
            }
          })
        }
        
      }
    },
    getNetworkName(val){
      val = Number(val)
      var s = ''
      switch (val) {
        case 1:
          s='Facebook'
          break;
        case 2:
          s='Twitter'
          break;
        case 3:
          s='Adwords'
          break;
        case 4:
          s='AppsFlyer'
          break;
        default:
          s='Tiktok'
          break;
      }
      return s;
    },
    deleteStagePlan(planitem,key){
      this.editPlanItem.data.splice(key,1)
    },
    deleteIO(){
      this.$confirm('请确认是否删除IO?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios("post", `${this.$phpBaseURL}v1/io/destroy`, {
          id: this.io_id,
          project_id: this.io_id,
        }).then(res => {
          if (res.code == 1) {
            this.$message({ message: res.msg, type: "success" });
            location.href="/#/ioList"
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }).catch(() => {
            
      });
      
    },
    addSpend(){
      this.spendList.push(
        {
          "starttime": moment().format("YYYY-MM-DD"),
          "endtime": moment().format("YYYY-MM-DD"),
          "network": "1",
          "ratio": "0"
        },
      )
    },
    openTplList(){
      this.$route.name
    },
    delRebateMethod(key){
      this.rebateList.splice(key,1)
      this.$forceUpdate();
    },
    addNewRebate(){
      this.rebateList.push({
        endtime: moment().format("YYYY-MM-DD"),
        network: "1",
        ratio: "0",
        ratio_type: 1,
        section_ratio: [],
        starttime: moment().format("YYYY-MM-DD"),
        status: 1,
      })
    },
    saveEditRebate(){
      this.$set(this.rebateList, this.editRebateItemIndex, this.editRebateItem);
      this.showEditRebate = false
      this.$forceUpdate();
    },
    delCost(index){
      this.editRebateItem.section_ratio.splice(index,1)
    },
    addNewCost(){
      this.editRebateItem.section_ratio.push({
        end: "0",
        ratio: "0",
        start: "0",
      })
    },
    saveSpendItem(){
      this.$set(this.spendList, this.editRatioItemIndex, this.editRatioItem);
      this.showRatioLink = false
      this.$forceUpdate();
    },
    spendTimeRange(){
      var that = this;
      this.planPickerOptions = {
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date(that.editRebateItem.starttime));
        }
      }
    },
    editRatioMethod(data,key){
      var that = this;
      data.network = data.network.toString()
      this.editRatioItem = JSON.parse(JSON.stringify(data));
      
      this.editRatioItemIndex = key;
      this.showRatioLink = true;
      this.editRatioItem.daterange = [this.editRatioItem.starttime? this.editRatioItem.starttime: moment().format("YYYY-MM-DD"),this.editRatioItem.endtime?this.editRatioItem.endtime:moment().format("YYYY-MM-DD")]
      console.log(this.editRatioItem);
      this.planPickerOptions = {
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date(that.editPlanItem.data[key].starttime));
        }
      }
    },
    delRatioMethod(key){
      this.spendList.splice(key,1)
    },
    addNewRemark(){
      this.remarkList.push({
        remark:"",
      })
    },
    delRemarkMethod(key){
      this.remarkList.splice(key,1)
      this.$forceUpdate();
    },
    editRemarkMethod(data,key){
      this.editRemarkItem = JSON.parse(JSON.stringify(data));
      this.editRemarkItemIndex = key;
      this.showRemarkLink = true
    },
    saveRemarkItem(){
      this.$set(this.remarkList, this.editRemarkItemIndex, this.editRemarkItem);
      this.showRemarkLink = false
      this.$forceUpdate();
    },
    saveEditLink(){
      var arr = [];
      var arr1 = [];
      if (this.editLinkItem.predicate) {
        this.editLinkItem.predicate.forEach((item,index)=>{
          arr1.push(item.key)
          if (item.key == 'account' && item.type == 'equal') {
            arr.push(item.value)
          }
          if (item.key == 'date') {
            this.editLinkItemStarttime = item.value.starttime;
            this.editLinkItemEndtime = item.value.endtime;
          }
        })
      }
      if (Array.from(new Set(arr1)).length < arr1.length) {
        this.$message({ message:'请勿添加多条相同类型的Preicate', type: "warning" });     
        return
      }
      if (arr.length>0) {
        this.axios("post", `${this.$phpBaseURL}v1/io/checkAccountId`, {
          io_id: this.io_id,
          account_ids: arr.join(),
          platform:this.editLinkItem.network,
          starttime:this.editLinkItemStarttime,
          endtime:this.editLinkItemEndtime,
        }).then(res => {
          if (res.code == 1) {
            this.$set(this.linkList, this.editLinkItemIndex, this.editLinkItem);
            this.showEditLink = false;
            this.$forceUpdate();
          } else {
            var s = ''
            s = s + (res.data.no_account_arr&&res.data.no_account_arr.length>0 ? ', 不属于该客户的：'+res.data.no_account_arr : '') + (res.data.close_arr&&res.data.close_arr.length>0 ? '已关闭的：'+res.data.close_arr : '') + (res.data.fh_arr&&res.data.fh_arr.length>0 ? "封户的广告账户的："+res.data.fh_arr : '') + (res.data.qx_auth_arr&&res.data.qx_auth_arr.length>0 ? '取消授权的：'+res.data.qx_auth_arr : '')
            this.$message({ message: res.msg+s, type: "warning" });
          }
        });
      }else{
        this.editLinkItem.predicate.map((item,index)=>{
          var o = item;
          if (o.key == 'date') {
            return {
              daterange:[o.starttime,o.endtime],
              key:"date",
              type:o.type,
              value:{
                starttime:o.starttime,
                endtime:o.endtime
              }
            }
          }else{
            return o
          }
        })
        this.$set(this.linkList, this.editLinkItemIndex, this.editLinkItem);
        this.showEditLink = false;
        this.$forceUpdate();
        
      }
    },
    delPreicateItem(key){
      console.log(this.editLinkItem);
      var that = this
      var deletedItem = this.editLinkItem.predicate.splice(key,1)
      this.link_predicate_key[this.editLinkItem.network].forEach((element,index) => {
        console.log(element.type, deletedItem[0].key,index);
        if (element.type == deletedItem[0].key) {
          that.$set(that.link_predicate_key[that.editLinkItem.network][index],'disabled',false)          
        }
      });
    },
    linkNetworkSelect(){
      console.log(this.editLinkItem.predicate);
      this.editLinkItem.predicate = [{
        "key": "",
        "type": "",
        "value": ""
      }]
      this.$forceUpdate();
    },
    addPreicateItem(){
      var that = this
      // this.editLinkItem.predicate.map(item => {
      //   that.link_predicate_key[this.editLinkItem.network].forEach((element,index) => {          
      //     if (item.key == element.type) {
      //       if (that.choosedPreicate.indexOf(element)<0) {
      //         that.choosedPreicate.push(element)
      //       }
      //       that.$set(that.link_predicate_key[that.editLinkItem.network][index],'disabled',true)
      //     }
      //   });
      // })
      this.editLinkItem.predicate.push({
        "key": "",
        "type": "",
        "value": ""
      })
    },
    linkItemKeyChange(index){
      // if (this.editLinkItem.predicate[index].key == 'date') {
      //   this.editLinkItem.predicate[index].value = {
      //     starttime:"",
      //     endtime:""
      //   }
      // }else{
      //   this.editLinkItem.predicate[index].value = ""
      // }
    },
    editLinkMethod(data,index){
      var that = this
      data.network = data.network.toString()
      this.editLinkItem = JSON.parse(JSON.stringify(data))
      console.log(this.editLinkItem);
      
      this.copyeditLinkItem = JSON.parse(JSON.stringify(data))
      this.editLinkItemIndex = index
      this.showEditLink = true;
      that.choosedPreicate = []
    },
    addNewLink(){
      this.linkList.push({
        "network": "1",
        "predicate": [
          {
            "key": "account",
            "type": "equal",
            "value": ""
          }
        ]
      })
      this.editLinkMethod(this.linkList[this.linkList.length-1],this.linkList.length-1)
    },
    // plan列表中删除
    delPlanMethod(key){
      this.planList.splice(key,1)
    },
    delLinkMethod(key){
      this.linkList.splice(key,1)
    },
    // 新增plan stage
    addNewPlanStage(){
      this.editPlanItem.data.push({
        group: { type: "country_accept", value: "" },
        endtime: moment().format("YYYY-MM-DD"),
        paid_detail: { price: "0.00" },
        paid_type: "cpa_regular",
        starttime: moment().format("YYYY-MM-DD")
      })
    },
    // 删除plan paid_detail中的section
    delPLanSection(key){
      this.editPlanItem.data[key].paid_detail.section.splice(key,1)
      this.$forceUpdate();
    },
    // paid type下拉操作
    paidTypeSelect(key) {
      console.log(this.editPlanItem.data[key].paid_type);
      if (this.editPlanItem.data[key].paid_type == 'cost') {
        delete this.editPlanItem.data[key].paid_detail       
      }else{
        this.editPlanItem.data[key].paid_detail={
          price:""
        }
        if (
          this.editPlanItem.data[key].paid_type != "cpa_regular" &&
          this.editPlanItem.data[key].paid_type != "cpc_regular"
        ) {
          delete this.editPlanItem.data[key].paid_detail.price
          this.editPlanItem.data[key].paid_detail.section = [
            {
              start: "0",
              end: "0",
              price: "0"
            }
          ];
          if (this.editPlanItem.data[key].paid_type == 'cpa_section_with_percentage' || this.editPlanItem.data[key].paid_type == 'cpc_section_with_percentage') {
            this.editPlanItem.data[key].paid_detail.percentage = 0
          }
        }else{
          delete this.editPlanItem.data[key].paid_detail.section,this.editPlanItem.data[key].paid_detail.percentage 
          this.editPlanItem.data[key].paid_detail.price = '0'
        }
      }
      
    },
    // 新增plan paid_detail中的section
    addPlanSection(key) {
      this.editPlanItem.data[key].paid_detail.section.push({
        start: "0",
        end: "0",
        price: "0"
      });
      this.$forceUpdate();
    },
    saveEditPlan() {
      console.log(this.editPlanItem);
      
      this.$set(this.planList, this.editPlanItemIndex, this.editPlanItem);
      this.showEditPlan = false;
      this.$forceUpdate();
    },
    datePickerFocus(key) {
      console.log("focus", this.editPlanItem.data[key].daterange);
    },
    // plan 时间选择
    selectPlanRange(key) {
      var that = this;
      this.planPickerOptions = {
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date(that.editPlanItem.data[key].starttime));
        }
      }
    },
    ratioTimeRange(){
      var that = this;
      this.planPickerOptions = {
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date(that.editRatioItem.starttime));
        }
      }
    },
    selectLinkRange(key) {
      this.editLinkItem.predicate[key].starttime = this.editLinkItem.predicate[
        key
      ].daterange[0];
      this.editLinkItem.predicate[key].endtime = this.editLinkItem.predicate[
        key
      ].daterange[1];
    },
    // 编辑某一条plan
    editPlanMethod(item,key) {
      var that = this
      console.log(item);
      
      this.editPlanItemIndex = key
      this.editPlanItem = JSON.parse(JSON.stringify(item));
      this.showEditPlan = true;

      this.planPickerOptions = {
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date(that.editPlanItem.data[that.editPlanItemIndex].starttime));
        }
      }
      
      this.editPlanItem.data.forEach(ele => {
        if (!ele.daterange) {
          ele.daterange = [];
          ele.daterange[0] = ele.starttime
            ? ele.starttime
            : moment().format("YYYY-MM-DD");
          ele.daterange[1] = ele.endtime
            ? ele.endtime
            : ""; 
        }
        console.log(ele.daterange);
        
      });
    },
    // 新增一条客户投放计划
    addNewPlan() {
      this.planList.push({
        network: ['1'],
        daterange: [],
        data: [
          {
            group: { type: "country_accept", value: "" },
            endtime: moment().format("YYYY-MM-DD"),
            paid_detail: { price: "0.00" },
            paid_type: "cpa_regular",
            starttime: moment().format("YYYY-MM-DD")
          }
        ]
      });
      this.editPlanMethod(this.planList[this.planList.length-1],this.planList.length-1)
    },
    // submit按钮
    submitDetail() {
      this.loading = true;
      this.axios("post", `${this.$phpBaseURL}v1/io/ioDetailSubmit`, {
        io_id: this.io_id,
        plans: this.planList,
        links: this.linkList,
        remarks: this.remarkList,
        spends: this.spendList,
        rebates: this.rebateList
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.$message({ message: res.msg, type: "success" });
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 拼接返点字符串
    getsectionRatio(data) {
      var str = "";
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        str +=
          element.start + " - " + element.end + " : " + element.ratio + "/";
        return str;
      }
    },
    // 拼接价格字符串
    getPrice(data) {
      var str = "";
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        str +=
          element.start + " - " + element.end + " : " + element.price + "/";
        return str;
      }
    },
    hide() {
      this.showToast = false;
      this.showEditPlan = false;
      this.showEditLink = false;
      this.showRemarkLink = false;
      this.showRatioLink = false;
      this.showEditRebate = false;
    },
    // 弹窗
    selectBtn(name, value) {
      this.showToast = true;
      this.toastName = name;
    },
    // 获取io详情
    getIOdetail() {
      this.axios("get", `${this.$phpBaseURL}v1/io/ioDetail`, {
        io_id: this.io_id
      }).then(res => {
        if (res.code == 1) {
          this.ioName = res.data.name;
          this.clientRebateType = res.data.client_rebate;
          this.planList = res.data.plans;
          this.linkList = res.data.links;
          this.rebateList = res.data.rebates;
          this.remarkList = res.data.remarks;
          this.spendList = res.data.spends;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    editRebateMethod(data,key) {
      var that = this
      data.network = data.network.toString()
      this.editRebateItem = JSON.parse(JSON.stringify(data));
      this.editRebateItemIndex = key;
      this.showEditRebate = true;
      this.editRebateItem.daterange = [this.editRebateItem.starttime? this.editRebateItem.starttime: moment().format("YYYY-MM-DD"),this.editRebateItem.endtime?this.editRebateItem.endtime:moment().format("YYYY-MM-DD")]
      this.planPickerOptions = {
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date(that.editRebateItem.starttime));
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.iodetail {
  .main-div.title {
    font-size: 30px;
    color: #2679b5;
    font-weight: lighter;
  }
  .section_ {
    width: 100%;
    .section_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ebeef5;
      padding: 10px;
      > p {
        font-size: 20px;
      }
      > div.search_form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        > div {
          margin-left: 5px;
          width: 130px;
        }
        ::v-deep .el-button {
          margin-left: 5px;
          // border-radius: 0;
        }
        ::v-deep .el-input__inner {
          // border-radius: 0;
        }
      }
      .main-button {
        min-width: 62px;
        margin-right: 0;
      }
    }
    ::v-deep .el-table th {
      padding: 10px 0;
    }
  }
  .main-pop.large .center {
    width: 800px;
    dl {
      dt {
        font-size: 12px;
        line-height: 20px;
      }
      dd {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .body {
      overflow: scroll;
    }
  }
}
</style>
