import { render, staticRenderFns } from "./ioDetail.vue?vue&type=template&id=3695e1bf&scoped=true&"
import script from "./ioDetail.vue?vue&type=script&lang=js&"
export * from "./ioDetail.vue?vue&type=script&lang=js&"
import style0 from "./ioDetail.vue?vue&type=style&index=0&id=3695e1bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3695e1bf",
  null
  
)

export default component.exports