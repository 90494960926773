<template>
  <div class="main-div">
    <div v-show="showFlag==1">
      <p class="main-title">角色权限列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.role_name"
            placeholder="请输入角色名称"
            clearable
            @change="enterSearch"
          ></el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.status"
            @change="enterSearch"
            clearable
            placeholder="请选择角色状态"
            style="width:168px"
          >
            <el-option
              v-for="(item,k) in optionsStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div
          class="main-button"
          style="margin-right:0px;float:right"
          v-show="common.permFilter(`${common.php}v1/perm/getList`)"
          @click="addPop = true;getTree()"
        >新建角色</div>
      </div>
      <!--<div style="margin-bottom: 32px">-->
      <div v-loading="loading">
        <el-table :data="list" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <!--<el-table-column
                            prop=""
                            label="角色ID"
                            width="">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.id">{{scope.row.id}}</span>
                        </template>
          </el-table-column>-->
          <el-table-column prop="name" label="角色名称" width></el-table-column>
          <el-table-column prop="status" label="角色状态" width>
            <template slot-scope="scope">
              <div v-for="v in optionsStatus">
                <span
                  :class="scope.row.status | statusClassFilter"
                  style="cursor: pointer"
                  v-if="v.value == scope.row.status"
                  :title="v.label"
                >{{v.label}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop label="角色描述" width>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.description">{{scope.row.description}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" width></el-table-column>
          <el-table-column prop label="操作" width>
            <template slot-scope="scope">
              <span
                v-show="common.permFilter(`${common.php}v1/role/updateinfo`)"
                class="main-tableToInfo"
                @click="editPop=true;getInfo(scope.row)"
              >编辑</span>
              <div class="main-20px"></div>
              <span
                v-show="common.permFilter(`${common.php}v1/role/destroy`)"
                class="main-tableToInfo"
                @click="delPopFun(scope.row)"
              >删除</span>
              <div class="main-20px"></div>
              <span
                v-show="common.permFilter(`${common.php}v1/role/opera`)"
                class="main-tableToInfo"
                v-if="scope.row.status == 1"
                @click="openOrClo(scope.row,2)"
              >禁用</span>
              <div v-if="scope.row.status == 1" class="main-20px"></div>
              <span
                v-show="common.permFilter(`${common.php}v1/role/opera`)"
                class="main-tableToInfo"
                v-if="scope.row.status == 2"
                @click="openOrClo(scope.row,1)"
              >解禁</span>
              <div class="main-20px"></div>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>

    <!--新增-->
    <div class="main-pop editPop" v-show="addPop">
      <div class="mask"></div>
      <div class="center" style="width:516px;max-height: 835px">
        <div class="head">
          <span>新建角色</span>
          <i class="gongzuotai gztguanbi" @click="addPop=false"></i>
        </div>
        <div class="body" style>
          <div class="inline top">
            <p class="info-title t-4w" style="line-height: 10px">角色名称:</p>
            <br>
            <div>
              <div class="main-inputBox" style="width: 465px">
                <el-input v-model="addObj.name" placeholder="角色名称"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">角色权限:</p>
            <br>
            <div>
              <div
                class="main-inputBox"
                style="padding-left: 20px;width: 445px!important;height: 300px;overflow-y: scroll;"
              >
                <el-tree
                  style="height: 260px;overflow-y: scroll;"
                  @check="haha"
                  :data="tree.children"
                  show-checkbox
                  default-expand-all
                  node-key="id"
                  ref="tree"
                  highlight-current
                  :props="defaultProps"
                ></el-tree>
              </div>
            </div>

            <p class="info-title" style="line-height: 10px">角色描述:</p>
            <br>
            <div>
              <div class="main-inputBox" style="width: 465px">
                <el-input v-model="addObj.description" placeholder="角色描述"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="addPop=false">取消</button>
          <button class="main-button r" @click="doAdd()">确定</button>
        </div>
      </div>
    </div>
    <!--编辑角色-->
    <div class="main-pop editPop" v-show="editPop">
      <div class="mask"></div>
      <div class="center" style="width:516px;max-height: 835px">
        <div class="head">
          <span>编辑角色</span>
          <i class="gongzuotai gztguanbi" @click="editPop=false"></i>
        </div>
        <div class="body" style>
          <div class="inline top">
            <p class="info-title t-4w" style="line-height: 10px">角色名称:</p>
            <br>
            <div>
              <div class="main-inputBox" style="width: 465px">
                <el-input v-model="editingProObj.name" placeholder="角色名称"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">角色权限:</p>
            <br>
            <div>
              <div>
                <div
                  class="main-inputBox"
                  style="padding-left: 20px;width: 445px!important;height: 260px;overflow-y: scroll;"
                >
                  <el-tree
                    style="height: 260px;overflow-y: scroll;"
                    @check="haha"
                    :data="tree.children"
                    show-checkbox
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :props="defaultProps"
                  ></el-tree>
                </div>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">角色描述:</p>
            <br>
            <div>
              <div class="main-inputBox" style="width: 465px">
                <el-input v-model="editingProObj.description" placeholder="角色描述"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="editPop=false">取消</button>
          <button class="main-button r" @click="doEdit()">确定</button>
        </div>
      </div>
    </div>

    <!--删除角色-- 暂时不用-->
    <div class="main-pop editPop" v-show="delPop">
      <div class="mask"></div>
      <div class="center" style="width:516px;max-height: 835px">
        <div class="head">
          <span>删除角色</span>
          <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
        </div>
        <div class="body" style>
          <div class="inline top">确定删除此角色？</div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="delPop=false">取消</button>
          <button class="main-button r" @click="delPop=false;doDel(delId)">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      timer: "",
      defaultProps: {
        children: "children",
        label: "title"
      },
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 0,
      date: ["", ""],
      ajaxDataSearch: {
        role_name: "", //	否	string	角色名称；如：销售
        status: "" //	否	int	角色状态1 正常 2 禁用
      },
      optionsStatus: [
        /*角色状态1 正常 2 禁用*/
        {
          value: 1,
          label: "正常"
        },
        {
          value: 2,
          label: "禁用"
        }
      ],
      list: [
        {
          id: "90ad70453f65442284cbd206abe215c2",
          name: "销售2",
          description: "销售2角色",
          created_at: "2020-04-14 16:58:27",
          updated_at: "2020-04-14 17:14:18",
          status: 1
        }
      ],
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      },

      editPop: false,
      editingProObj: {
        id: "90ad70453f65442284cbd206abe215c2",
        name: "销售2",
        description: "销售2角色",
        created_at: "2020-04-14 16:58:27",
        updated_at: "2020-04-14 17:14:18",
        status: 1
      },
      perm: [],
      tree: {
        id: "8ce22ca8635d4501bbf5733e37192777",
        perm_url: "bmp/api/v1",
        title: "全部权限",
        parent_id: "81f28db9166b4ec6acf2baf3dbefea89",
        checked: false,
        children: [
          {
            id: "eb7ddd9559a94a598163a9119449de90",
            perm_url: "bm/api/accountMedia",
            title: "媒体平台账户",
            parent_id: "8ce22ca8635d4501bbf5733e37192777",
            checked: false,
            children: [
              {
                id: "dbbb30238cce4bf5b432d7d9eb9931d2",
                perm_url: "bm/api/accountMedia/list",
                title: "媒体平台账户列表",
                parent_id: "eb7ddd9559a94a598163a9119449de90",
                checked: false,
                children: []
              }
            ]
          },
          {
            id: "e0b7fc80651748b3a334c63a28f90daf",
            perm_url: "bmp/api/v1/user",
            title: "用户管理",
            parent_id: "8ce22ca8635d4501bbf5733e37192777",
            checked: false,
            children: [
              {
                id: "a44375d2d41641f18891b2f37d9bbb81",
                perm_url: "bmp/api/v1/user/lists",
                title: "查看用户列表信息",
                parent_id: "e0b7fc80651748b3a334c63a28f90daf",
                checked: false,
                children: []
              },
              {
                id: "6f95d28da1424f4ea2b256a4e26e5704",
                perm_url: "bmp/api/v1/user/update",
                title: "编辑用户信息",
                parent_id: "e0b7fc80651748b3a334c63a28f90daf",
                checked: false,
                children: []
              }
            ]
          }
        ]
      },
      addPop: false,
      addObj: {
        name: "",
        description: "",
        perm: []
      },
      delPop: false,
      delId: ""
    };
  },
  filters: {
    statusClassFilter(value) {
      //申请状态:1 正常,2 禁用
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-tongguo";
          break;
        case 2:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;
        this.init();
        this.getTree();

        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  methods: {
    haha(obj, node, data) {
      let a = node.checkedNodes;
      a.forEach(item => {
        item.checked = true;
      });
      this.$refs.tree.setCheckedNodes(a);
    },
    getCheckedNodes() {
      console.log("---:", this.$refs.tree.getCheckedKeys(false));
    },
    sizeInit(size){
      this.ajaxDataSearch.page_num = size ? size : 15
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      let obj = this.ajaxDataSearch;
      obj.page = page ? page : 1;
      this.axios("get", `${this.$phpBaseURL}v1/role/getList`, obj).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getTree() {
      this.addObj.name = "";
      this.addObj.description = "";
      /*权限树*/
      this.axios("get", `${this.$phpBaseURL}v1/perm/getList`, {}).then(res => {
        //                        console.log(res.data)
        if (res.code == 1) {
          this.tree = res.data[0];
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      this.init();
    },

    /*详情*/
    getInfo(obj) {
      this.editingProObj = obj;
      this.axios("get", `${this.$phpBaseURL}v1/role/updateinfo`, {
        id: obj.id
      }).then(res => {
        if (res.code == 1) {
          this.tree = res.data.perm[0];
          if (res.data.have_perms) {
            this.$refs.tree.setCheckedKeys(res.data.have_perms);
          }
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*计算perm*/
    permData(arr, level) {
      let perm = [];
      for (let i in this.tree.children) {
        let tmp = {
          id: this.tree.children[i].id,
          children: []
        };
        for (let o in this.tree.children[i].children) {
          if (this.tree.children[i].children[o].checked) {
            tmp.children.push({ id: this.tree.children[i].children[o].id });
          }
        }
        perm.push(tmp);
      }

      return perm;
    },

    /*新增*/
    doAdd() {
      if (!this.addObj.name) {
        this.$message({
          message: "请输入角色名称",
          type: "warning"
        });
        return false;
      }
      if (!this.addObj.description) {
        this.$message({
          message: "请输入角色描述",
          type: "warning"
        });
        return false;
      }
      // this.addObj.perm = this.permData();
      this.addObj.perm = this.$refs.tree.getCheckedKeys(false);
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/role/insertrole`,
        this.addObj
      ).then(res => {
        if (res.code == 1) {
          this.addPop = false;
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*编辑*/
    doEdit() {
      if (!this.editingProObj.name) {
        this.$message({
          message: "请输入角色名称",
          type: "warning"
        });
        return false;
      }
      if (!this.editingProObj.description) {
        this.$message({
          message: "请输入角色描述",
          type: "warning"
        });
        return false;
      }
      //  "id": this.editingProObj.id,
      //     "name": this.editingProObj.name,
      //     "description": this.editingProObj.description,
      //     "perm":this.permData()
      this.$refs.tree.getCheckedKeys(false);
      this.axios("POST", `${this.$phpBaseURL}v1/role/update`, {
        id: this.editingProObj.id,
        name: this.editingProObj.name,
        description: this.editingProObj.description,
        perm: this.$refs.tree.getCheckedKeys(false)
      }).then(res => {
        if (res.code == 1) {
          this.editPop = false;
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*禁用或解禁*/
    openOrClo(obj, flag) {
      this.axios("POST", `${this.$phpBaseURL}v1/role/opera`, {
        id: obj.id,
        status: flag
      }).then(res => {
        if (res.code == 1) {
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*删除*/
    delPopFun(obj) {
      this.delId = obj.id;
      // this.delPop = true;
      this.doDel(this.delId);
    },
    doDel(id) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.axios("POST", `${this.$phpBaseURL}v1/role/destroy`, {
            id: id
          }).then(res => {
            if (res.code == 1) {
              // this.delPop = false;
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.init();
            } else if (res.code == 2) {
              //token过期处理
              this.common.tokenExpired(res.msg);
            } else {
              this.$message({ message: res.msg, type: "warning" });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("角色权限列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editPop {
}

.main-selectBox {
  margin-right: 66px;
}
</style>