<template>
    <div class="gatherdata main-div">
        <div>
            <p class="main-title">管理数据汇总表</p>
            <div>
                <div class="main-inputBox gatherdata-self-shy">
                    <el-date-picker @change="init()"
                                    v-model="ajaxDataSearch.year_month"
                                    type="month"
                                    value-format="yyyy-MM" format="yyyy-MM"
                                    :clearable="false"
                                    placeholder="选择年-月">
                    </el-date-picker>
                </div>
                <div class="main-btnBox">
                    <a class="download_a" ref="" id="estimated_temp" href=""></a>
                    <div class="main-button" v-show="common.permFilter(`${common.php}v1/importTemp`)" @click="download()">预计数据模板下载</div>
                    <div v-show="common.permFilter(`${common.php}v1/data/import`)" class="main-button main-button-first" @click="selectFile()">预计数据导入
                        <input id="file" type="file" value="" style="display: none">
                    </div>

                    <a class="download_a" ref="" id="estimated_temp" href=""></a>
                    <div v-show="common.permFilter(`${common.php}v1/importWagesTemp`)" class="main-button main-button-first" @click="downloadWages()">部门月度工资模板下载</div>
                    <div v-show="common.permFilter(`${common.php}v1/data/importWages`)" class="main-button main-button-first" @click="selectFileWages()">部门月度工资导入
                        <input id="fileWages" type="file" value="" style="display: none">
                    </div>
                </div>
            </div>
        </div>
        <main v-loading="loading" class="nest-table-self">
            <h2 class="tablehead">汇总分析表</h2>
            <div class="table-wrap-gatherdata">
                <table border="1" class="table-left" align="center">
                    <tr>
                        <td style="height:80px" colspan="2" class="bg-gray">数据类别</td>
                        <td style="height:80px">科目</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td rowspan="7" width="124" class="bg-gray">业绩完成</td>
                        <td rowspan="3" width="124">实际数据</td>
                        <td width="124">收入</td>
                    </tr>
                    <tr>
                        <td>毛利</td>
                    </tr>
                    <tr>
                        <td>平均毛利率</td>
                    </tr>
                    <tr>
                        <td rowspan="2">预计数据</td>
                        <td>收入</td>
                    </tr>
                    <tr>
                        <td>毛利</td>
                    </tr>
                    <tr>
                        <td rowspan="2">完成比率</td>
                        <td>税后收入</td>
                    </tr>
                    <tr>
                        <td>税后毛利</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td rowspan="3" class="bg-gray">业绩收入占比（每类业务收入占全部业务收入的比重）</td>
                        <td colspan="2">媒介类</td>
                    </tr>
                    <tr>
                        <td colspan="2">营销类</td>
                    </tr>
                    <tr>
                        <td colspan="2">技术类</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td rowspan="2" class="bg-gray">应收账款</td>
                        <td colspan="2">账内应收</td>
                    </tr>
                    <tr>
                        <td colspan="2">逾期应收</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td rowspan="2" class="bg-gray">合同签约</td>
                        <td colspan="2">续签客户</td>
                    </tr>
                    <tr>
                        <td colspan="2">新客户</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td colspan="3" class="bg-gray">客户数量</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td colspan="3" class="bg-gray">项目数量</td>
                    </tr>
                    <!-- ------ -->
                    <tr>
                        <td colspan="3" class="bg-gray">人员数量</td>
                    </tr>
                    <tr>
                        <td colspan="3" class="bg-gray">人效</td>
                    </tr>
                </table>
                <table class="table-right">
                    <tr>
                        <td colspan="2" v-for="(v,k) in obj">
                            <div v-for="item in configurationData.departments_data">
                                <span v-if="v.dept_id==item.department_id">{{item.department_name}}</span>
                            </div>
                        </td>
                        <!--<td colspan="2">营销二部</td>
                        <td colspan="2">营销三部</td>-->
                        <td>合计</td>
                    </tr>
                    <tr>
                        <template v-for="(v,k) in obj">
                            <td>{{v.month}}月</td>
                            <td>累计</td>
                        </template>

                        <!--<td>1月</td>
                        <td>累计</td>
                        <td>1月</td>
                        <td>累计</td>-->
                        <td>部门合计</td>
                    </tr>

                    <!-- 以下根据对象个数循环有几个tr，且根据有几个营销部门循环td ,不太好循环，不能确定是哪个字段-->
                    <tr><!--业绩完成-实际数据-收入-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.actual_income}}</td>
                            <td>{{v.actual_income_sum}}</td>
                        </template>
                        <td>{{rowSum('actual_income_sum')}}</td>
                    </tr>
                    <tr><!--业绩完成-实际数据-毛利-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.actual_gross_profit}}</td>
                            <td>{{v.actual_gross_profit_sum}}</td>
                        </template>
                        <td>{{rowSum('actual_gross_profit_sum')}}</td>
                    </tr>
                    <tr><!--业绩完成-实际数据-平均毛利率-->
                        <template v-for="(v,k) in obj">
                            <td>{{addPercentSign(v.actual_gross_margin)}}</td>
                            <td>{{addPercentSign(v.actual_gross_margin_sum)}}</td>
                        </template>
                        <td>{{rowSum('actual_gross_margin_sum')}}%</td>
                    </tr>


                    <tr><!--业绩完成-预计数据-收入-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.estimated_income}}</td>
                            <td>{{v.estimated_income_sum}}</td>
                        </template>
                        <td>{{rowSum('estimated_income_sum')}}</td>
                    </tr>
                    <tr><!--业绩完成-预计数据-毛利-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.estimated_gross_profit}}</td>
                            <td>{{v.estimated_gross_profit_sum}}</td>
                        </template>
                        <td>{{rowSum('estimated_gross_profit_sum')}}</td>
                    </tr>


                    <tr><!--业绩完成-完成比率-税后收入-->
                        <template v-for="(v,k) in obj">
                            <td>{{addPercentSign(v.after_tax_income)}}</td>
                            <td>{{addPercentSign(v.after_tax_income_sum)}}</td>
                        </template>
                        <td>{{rowSum('after_tax_income_sum')}}%</td>
                    </tr>
                    <tr><!--业绩完成-完成比率-税后毛利-->
                        <template v-for="(v,k) in obj">
                            <td>{{addPercentSign(v.after_tax_gross_profit)}}</td>
                            <td>{{addPercentSign(v.after_tax_gross_profit_sum)}}</td>
                        </template>
                        <td>{{rowSum('after_tax_gross_profit_sum')}}%</td>
                    </tr>


                    <tr><!--业绩收入占比-媒介类-->
                        <template v-for="(v,k) in obj">
                            <td>{{addPercentSign(v.media_income_ratio)}}</td>
                            <td>{{addPercentSign(v.media_income_ratio_sum)}}</td>
                        </template>
                        <td>{{rowSum('media_income_ratio_sum')}}%</td>
                    </tr>
                    <tr><!--业绩收入占比-营销类-->
                        <template v-for="(v,k) in obj">
                            <td>{{addPercentSign(v.sale_income_ratio)}}</td>
                            <td>{{addPercentSign(v.sale_income_ratio_sum)}}</td>
                        </template>
                        <td>{{rowSum('sale_income_ratio_sum')}}%</td>
                    </tr>
                    <tr><!--业绩收入占比-技术类-->
                        <template v-for="(v,k) in obj">
                            <td>{{addPercentSign(v.tech_income_ratio)}}</td>
                            <td>{{addPercentSign(v.tech_income_ratio_sum)}}</td>
                        </template>
                        <td>{{rowSum('tech_income_ratio_sum')}}%</td>
                    </tr>


                    <tr><!--应收账款-账内应收-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.term_amount}}</td>
                            <td>{{v.term_amount_sum}}</td>
                        </template>
                        <td>{{rowSum('term_amount_sum')}}</td>
                    </tr>
                    <tr><!--应收账款-逾期应收-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.overdue_amount}}</td>
                            <td>{{v.overdue_amount_sum}}</td>
                        </template>
                        <td>{{rowSum('overdue_amount_sum')}}</td>
                    </tr>


                    <tr><!--合同签约-续签客户-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.renewal_client}}</td>
                            <td>{{v.renewal_client_sum}}</td>
                        </template>
                        <td>{{rowSum('renewal_client_sum')}}</td>
                    </tr>
                    <tr><!--合同签约-新客户-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.new_client}}</td>
                            <td>{{v.new_client_sum}}</td>
                        </template>
                        <td>{{rowSum('new_client_sum')}}</td>
                    </tr>


                    <tr><!--客户数量-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.client_num}}</td>
                            <td>{{v.client_num_sum}}</td>
                        </template>
                        <td>{{rowSum('client_num_sum')}}</td>
                    </tr>
                    <tr><!--项目数量-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.project_num}}</td>
                            <td>{{v.project_num_sum}}</td>
                        </template>
                        <td>{{rowSum('project_num_sum')}}</td>
                    </tr>
                    <tr><!--人员数量-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.staff_num}}</td>
                            <td>{{v.staff_num_sum}}</td>
                        </template>
                        <td>{{rowSum('staff_num_sum')}}</td>
                    </tr>
                    <tr><!--人效（税后利润/部门月度工资，其中月度工资包含社保、公积金、十三薪）-->
                        <template v-for="(v,k) in obj">
                            <td>{{v.efficiency}}</td>
                            <td>{{v.efficiency_sum}}</td>
                        </template>
                        <td>{{rowSum('efficiency_sum')}}</td>
                    </tr>

                </table>
            </div>
        </main>
    </div>
</template>
<script>
    import axios from 'axios';
    import store from '@/store/index.js';
    import $ from 'jquery';
    export default {
        name: "addRole",

        data(){
            return {
                loading:true,
                timer: '',
                ajaxDataSearch: {
                    year_month: '',
                },
                obj: [
                    {
                        "id": 8,
                        "year": 2020,
                        "month": 4,
                        "dept_id": "4D46A8E420000001423A04F876203E21",
                        "actual_income": "1.00",
                        "actual_income_sum": "1.00",
                        "actual_gross_profit": "0.00",
                        "actual_gross_profit_sum": "0.00",
                        "actual_gross_margin": "0.00",
                        "actual_gross_margin_sum": "0.00",
                        "estimated_income": "0.00",
                        "estimated_income_sum": "0.00",
                        "estimated_gross_profit": "0.00",
                        "estimated_gross_profit_sum": "0.00",
                        "after_tax_income": "0.00",
                        "after_tax_income_sum": "0.00",
                        "after_tax_gross_profit": "0.00",
                        "after_tax_gross_profit_sum": "0.00",
                        "media_income_ratio": "0.00",
                        "media_income_ratio_sum": "0.00",
                        "sale_income_ratio": "0.00",
                        "sale_income_ratio_sum": "0.00",
                        "tech_income_ratio": "0.00",
                        "tech_income_ratio_sum": "0.00",
                        "term_amount": "0.00",
                        "term_amount_sum": "0.00",
                        "overdue_amount": "0.00",
                        "overdue_amount_sum": "0.00",
                        "renewal_client": 0,
                        "renewal_client_sum": 0,
                        "new_client": 0,
                        "new_client_sum": 0,
                        "client_num": 0,
                        "client_num_sum": 0,
                        "project_num": 0,
                        "project_num_sum": 0,
                        "staff_num": 0,
                        "staff_num_sum": 0,
                        "efficiency": 0,
                        "efficiency_sum": 0,
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 8,
                        "year": 2020,
                        "month": 4,
                        "dept_id": "4D46A8E420000001423A04F876203E21",
                        "actual_income": "2.00",
                        "actual_income_sum": "2.00",
                        "actual_gross_profit": "0.00",
                        "actual_gross_profit_sum": "0.00",
                        "actual_gross_margin": "0.00",
                        "actual_gross_margin_sum": "0.00",
                        "estimated_income": "0.00",
                        "estimated_income_sum": "0.00",
                        "estimated_gross_profit": "0.00",
                        "estimated_gross_profit_sum": "0.00",
                        "after_tax_income": "0.00",
                        "after_tax_income_sum": "0.00",
                        "after_tax_gross_profit": "0.00",
                        "after_tax_gross_profit_sum": "0.00",
                        "media_income_ratio": "0.00",
                        "media_income_ratio_sum": "0.00",
                        "sale_income_ratio": "0.00",
                        "sale_income_ratio_sum": "0.00",
                        "tech_income_ratio": "0.00",
                        "tech_income_ratio_sum": "0.00",
                        "term_amount": "0.00",
                        "term_amount_sum": "0.00",
                        "overdue_amount": "0.00",
                        "overdue_amount_sum": "0.00",
                        "renewal_client": 0,
                        "renewal_client_sum": 0,
                        "new_client": 0,
                        "new_client_sum": 0,
                        "client_num": 0,
                        "client_num_sum": 0,
                        "project_num": 0,
                        "project_num_sum": 0,
                        "staff_num": 0,
                        "staff_num_sum": 0,
                        "efficiency": 0,
                        "efficiency_sum": 0,
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 8,
                        "year": 2020,
                        "month": 4,
                        "dept_id": "4D46A8E420000001423A04F876203E21",
                        "actual_income": "3.00",
                        "actual_income_sum": "3.10",
                        "actual_gross_profit": "0.00",
                        "actual_gross_profit_sum": "0.00",
                        "actual_gross_margin": "0.00",
                        "actual_gross_margin_sum": "0.00",
                        "estimated_income": "0.00",
                        "estimated_income_sum": "0.00",
                        "estimated_gross_profit": "0.00",
                        "estimated_gross_profit_sum": "0.00",
                        "after_tax_income": "0.00",
                        "after_tax_income_sum": "0.00",
                        "after_tax_gross_profit": "0.00",
                        "after_tax_gross_profit_sum": "0.00",
                        "media_income_ratio": "0.00",
                        "media_income_ratio_sum": "0.00",
                        "sale_income_ratio": "0.00",
                        "sale_income_ratio_sum": "0.00",
                        "tech_income_ratio": "0.00",
                        "tech_income_ratio_sum": "0.00",
                        "term_amount": "0.00",
                        "term_amount_sum": "0.00",
                        "overdue_amount": "0.00",
                        "overdue_amount_sum": "0.00",
                        "renewal_client": 0,
                        "renewal_client_sum": 0,
                        "new_client": 0,
                        "new_client_sum": 0,
                        "client_num": 0,
                        "client_num_sum": 0,
                        "project_num": 0,
                        "project_num_sum": 0,
                        "staff_num": 0,
                        "staff_num_sum": 0,
                        "efficiency": 0,
                        "efficiency_sum": 0,
                        "created_at": null,
                        "updated_at": null
                    }
                ],
                /*返回客户管理下搜索项用到的数据接口*/
                configurationData: {
                    "receivable_client_status": {
                        /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
                    },
                    "is_overdue_config": {
                        /*"1": "是",
                         "2": "否"*/
                    },
                    "client_status_array": {
                        /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
                    },
                    "client_source_array": {
                        /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
                    },
                    "client_connection_relation_array": {
                        /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
                    },
                    "client_relation_company_array": {
                        /* "1": "拥有者",
                         "2": "共享者"*/
                    },
                    "is_contain_config": {
                        /*"1": "有",
                         "2": "无"*/
                    },
                    "platform_array": {
                        /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
                    },
                    "contact_status_array": {
                        /*"1": "审批通过",
                         "2": "审批失败"*/
                    },
                    "gender_array": {
                        /*"1": "男",
                         "2": "女"*/
                    },
                    "contract_status_array": {
                        /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
                    },
                    "business_type_array": {
                        /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
                    },
                    "contract_type_array": {
                        /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
                    },
                    "contract_subdivision_array": {
                        /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
                    },
                    "is_guarantee_archive_prepaid_array": {
                        /*"1": "是",
                         "2": "否"*/
                    },
                    "project_status_array": {
                        /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
                    },
                    "project_approval_status": {
                        /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
                    },
                    "file_upload_status": {
                        /*"1": "上传成功",
                         "2": "上传失败"*/
                    },
                    "platform_name_array": {
                        /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
                    },
                    "return_point_ratio_accuracy_array": {
                        /*"1": "预估",
                         "2": "确认"*/
                    },
                    "departments_data": [
                        /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
                    ],
                    "industry_data": [
                        /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
                    ]
                },
            }
        },
        mounted() {
            axios.defaults.headers.common['language'] = store.state.lang;
            axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
            this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {

//                console.log(res.data)
                if (res.code == 1) {
                    this.configurationData = res.data;

                    this.init();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics, 5000);

                } else if(res.code == 2){

                    //token过期处理
                    this.common.tokenExpired(res.msg);

                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }

            });
            
            //页面加载初始化时间控件，默认为当前时间前一个月
            var date = new Date();
            this.ajaxDataSearch.year_month = date.getFullYear() + "-" + date.getMonth();

        },
        methods: {
            init(page){
                clearTimeout(this.timer);
                this.axios('get', `${this.$phpBaseURL}v1/data/getList`, this.ajaxDataSearch).then(res => {
                    this.loading=false;

                    //console.log(res.data)
                    if (res.code == 1) {
                        this.obj = res.data;
                        //this.total = res.data.total;
                        //this.pageSize = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            rowSum(key){
                return this.obj.reduce(function (sum, item) {
                    return sum + parseFloat(item[key]);
                }, 0)
            },

            //添加百分号
            addPercentSign(val){
                if (!val) {
                    val = '0.00';
                }
                return val + '%';
            },

            /*预计数据导入*/
            download() {
                window.location.href = `${this.$phpBaseURL}v1/importTemp`;
            },
            selectFile(){
                let that = this;
                document.getElementById('file').click();

                let fileInput = document.getElementById('file');
                fileInput.addEventListener('change', function () {
//              that.fileMsg = fileInput.files[0].name;
                    that.upload();
                })
            },
            upload(){
                let excel = document.getElementById('file').files[0];
                let wfForm = new FormData();
                wfForm.append('file', excel);
                this.axios('post', `${this.$phpBaseURL}v1/data/import`, wfForm).then(res => {
                    if (res.code == 1) {
                        this.$message({message: '导入成功', type: 'success'});
                        this.addPop = false;
                        this.init();
                        $("#file").replaceWith('<input id="file" type="file" value="" style="display: none">');

//              document.getElementById('fileParent').replaceChild(document.getElementById('file'),'<input id="file" type="file" value="" style="display: none">');
                        /*fileParent*/
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                        $("#file").replaceWith('<input id="file" type="file" value="" style="display: none">');

                    }
                })
//            document.getElementById('fileParent').replaceChild(document.getElementById('file'),'<input id="file" type="file" value="" style="display: none">');
//                $("#file").replaceWith('<input id="file" type="file" value="" style="display: none">');
            },

            /*部门月度工资导入*/
            downloadWages() {
                window.location.href = `${this.$phpBaseURL}v1/importWagesTemp`;
            },
            selectFileWages(){
                let that = this;
                document.getElementById('fileWages').click();

                let fileInput = document.getElementById('fileWages');
                fileInput.addEventListener('change', function () {
                    that.uploadWages();
                })
            },
            uploadWages(){
                let excel = document.getElementById('fileWages').files[0];
                let wfForm = new FormData();
                wfForm.append('file', excel);
                this.axios('post', `${this.$phpBaseURL}v1/data/importWages`, wfForm).then(res => {
                    if (res.code == 1) {
                        this.$message({message: '导入成功', type: 'success'});
                        this.addPop = false;
                        this.init();
                        $("#fileWages").replaceWith('<input id="fileWages" type="file" value="" style="display: none">');

//              document.getElementById('fileParent').replaceChild(document.getElementById('file'),'<input id="file" type="file" value="" style="display: none">');
                        /*fileParent*/
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                        $("#fileWages").replaceWith('<input id="fileWages" type="file" value="" style="display: none">');

                    }
                })
//            document.getElementById('fileParent').replaceChild(document.getElementById('file'),'<input id="file" type="file" value="" style="display: none">');
//                $("#file").replaceWith('<input id="file" type="file" value="" style="display: none">');
            },

            //频次统计
            addStatistics() {
                this.common.statisticsAdd('管理数据汇总表列表','1')
            },

        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }

    }
</script>

<style scoped lang="scss">
    table {
        width: auto !important;
    }

    .gatherdata {
        .nest-table-self {
            .table-left {
                min-width: 372px !important;
                // max-width: 372px;
            }
            .table-right {
                min-width: calc(100% - 372px);
                // tr:last-child td {
                //     height: 47px;
                // }
            }
        }
  
  }
  .table-wrap-gatherdata{
    display: -webkit-box!important;
    width: 100%;
    overflow: auto;
  }
</style>