<template>
  <div class="main-div cusomerList">
    <p class="main-title">操作日志</p>
    <div>
      <div class="main-inputBox">
        <el-input
          v-model="queryOptions.user_name"
          clearable
          placeholder="请输入用户名"
          @keyup.enter.native="changeQuery"
          @change="changeQuery()"
        >

        </el-input>
      </div>
      <div class="main-inputBox">
        <el-input
          v-model="queryOptions.name"
          clearable
          placeholder="请输入姓名"
          @keyup.enter.native="changeQuery"
          @change="changeQuery()"
        >

        </el-input>
      </div>
      <div class="main-selectBox">
        <el-select
          v-model="queryOptions.mod"
          clearable
          placeholder="请选择列表"
          @keyup.enter.native="changeQuery"
          @change="changeQuery()"
        >
          <el-option
            :title="item.mod"
            v-for="(item,index) in configurationData.mod_list"
            :key="index"
            :label="item.mod"
            :value="item.mod"
          >
          </el-option>
        </el-select>
      </div>
      <div class="main-selectBox">
        <el-select
          v-model="queryOptions.action_type"
          clearable
          placeholder="请选择操作"
          @keyup.enter.native="changeQuery"
          @change="changeQuery()"
        >
          <el-option
            :title="item.action_type"
            v-for="(item,index) in configurationData.action_type_list"
            :key="index"
            :label="item.action_type"
            :value="item.action_type"
          >
          </el-option>
        </el-select>
      </div>
      <div class="main-inputBox daterange" style="width: 255px!important;">
        <el-date-picker
          v-model="timeRange"
          @change="changeQuery"
          @keyup.enter.native="changeQuery"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="创建开始日期"
          clearable
          end-placeholder="结束日期"
          placeholder="创建时间"
        >
        </el-date-picker>
      </div>
    </div>

    <div v-loading="loading">
      <el-table
        :data="list"
        border
        height="550"
        style="width: 100%;"
      >
        <template slot="empty">
          <div class="nodata">
            <img src="@/assets/img/nodata.png">
            <span>暂无数据</span>
          </div>
        </template>
        <el-table-column
          prop="action_time"
          label="日期"
          width="200"
        >
        </el-table-column>

        <el-table-column
          prop="username"
          show-overflow-tooltip
          label="用户名"
          width="200"
        >
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              :title="scope.row.username"
            >{{scope.row.username}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="mod"
          label="列表"
          width="200"
        >
          <template slot-scope="scope">
            <span
              class="main-tableToInfo"
              @click="zhPop=true;getZh(scope.row.client_company_id)"
            >{{scope.row.mod}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="action_type"
          label="操作类型"
          width="150"
        >
          <template slot-scope="scope">
            <span
              class="main-tableToInfo"
              @click="xsPop=true;getXs(scope.row.client_company_id)"
            >{{scope.row.action_type}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="日志内容"
          show-overflow-tooltip
          width="150"
        >
          <template slot-scope="scope">
            <span
              class="main-tableToInfo"
              @click="AMPop=true;getAM(scope.row.client_company_id)"
            >{{scope.row.description}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="action_ip"
          label="IP"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop=""
          label="操作"
          min-width="150"
        >
          <template slot-scope="scope">
            <span
              v-show="common.permFilter(`${common.php}v1/action_log/detail`)"
              class="main-tableToInfo"
              @click="goDetail(scope.row)"
            >详情</span>
          </template>
        </el-table-column>
      </el-table>  
      <pagination-vue
        @currentSizeChange="sizeGetList"
        @currentChange="getList"
        :pageSize="pageSize"
        :total="total"
        :firstPage="firstPage"
      />
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from '@/components/paginationVue.vue';
import axios from 'axios';
import store from '@/store/index.js';
import moment from 'moment';
export default {
  name: '',
  components: {
    paginationVue
  },
  data() {
    return {
        firstPage:1,
      loading:true,
      list:[],
      timeRange:[],//时间段
      configurationData:[],
      total:0,
      pageSize:0,
      queryOptions:{
        user_name:'',
        name:'',
        mod:'',//模块(列表)
        action_type:'',//string	操作类型
        start_date:'',//string	开始时间
        end_date:'',//结束时间
        page:1,
      }
    }
  },
  filters: {
    
  },
  created() {
    this.getOptions();
  },
  watch: {
  
  },
  computed: {
  
  },
  methods: {
    getOptions(){

      //返回整个筛选项
        // console.log('axios:',axios)
        let param = {
          type:'mod_list,action_type_list'
        }
        axios.defaults.headers.common['language'] = store.state.lang;
        axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
            this.axios('POST',`${this.$phpBaseURL}v1/config/datas`,param).then(res=>{
                if(res.code == 1){
                    this.configurationData = res.data;
                    this.getList()
                } else if(res.code == 2){
                    //token过期处理
                    this.common.tokenExpired(res.msg);
                }else{
                    this.$message({ message: res.msg, type: 'warning' });
                }
                this.loading=false;
            })
    },
    sizeGetList(size){
      this.queryOptions.page_num = size ? size : 15;
      this.getList(1)
    },
    getList(page){
      this.loading=true;
      this.firstPage=page;
      //获取日志接口
      if(page){
        this.queryOptions.page = page
      }
      this.axios('POST',`${this.$phpBaseURL}v1/action_log/lists`,this.queryOptions).then(res=>{
          this.loading=false;
          if(res.code == 1){
           this.list = res.data.data
           this.total = res.data.total
           this.pageSize = res.data.per_page
          } else if(res.code == 2){
              //token过期处理
              this.common.tokenExpired(res.msg);
          }
      })
    },
    changeQuery(data){
        this.firstPage=1;

        var startDate='';
        var endDate='';
        if(data) {
            startDate=data[0];
            endDate=data[1];
        }

        this.queryOptions.start_date = startDate;
        this.queryOptions.end_date = endDate;

        this.getList(1);
    },
    goDetail(row){
      let token = JSON.parse(localStorage.getItem('user_info')).token
      // let url = `${this.doMainUrl}${this.$phpBaseURL}v1/action_log/detail?token=${token}&id=${row.id}&url=${window.location.href}`
      let url = `${this.doMainUrl}${this.$phpBaseURL}v1/action_log/detail?token=${token}&id=${row.id}`
      window.open(url,'_blank')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
<style>
</style>