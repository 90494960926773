<template>
  <div class="main-div" style="background: none; padding: 0px;">
    <div v-if="showFlag==1" style="background:#fff;padding:24px;">
      <p class="main-title">广告账户开户审批</p>
      <div>
        <div class="main-inputBox" style="width:auto!important;display:inline-flex;">
          <el-select style="width:150px;" placeholder="客户名称" v-model="searchType" @change="searchTypeChange()">
            <el-option :value="2" label="开户公司名称">开户公司名称</el-option>
            <el-option :value="3" label="申请单编号">申请单编号</el-option>
            <el-option :value="1" label="客户名称">客户名称</el-option>
          </el-select>
          <el-input
            v-if="searchType == 1"
            style="width:200px;font-size:14px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.clientCompanyName"
          >
          </el-input>
          <el-input
            v-if="searchType == 2"
            style="width:200px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.accountCompanyName"
          >
          </el-input>
          <el-input
            v-if="searchType == 3"
            style="width:200px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.requisitionId"
          >
          </el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.platform"
            clearable
            placeholder="请选择平台"
          >
            <el-option
              v-for="(item,k) in configurationData.network_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.examineStatus"
            clearable
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="(item,k) in configurationData.application_status_config"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox daterange" style="width: 255px!important;">
          <el-date-picker
            v-model="applicationTimearr"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            clearable
            start-placeholder="申请开始日期"
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
         <div class="main-inputBox" style="width:200px!important">
          <div class="main-button" @click="init(1)">搜索</div>
          <div class="main-button" @click="reset()">重置</div>
        </div>
      </div>
      <div>
        <div class="main-btnBox m-r-16">
          <div class="main-button" @click="exportOpenList()">导出数据</div>
        </div>
      </div>
      <div class="need-reTd" v-loading="loading">
        <el-table :data="list" border style="width: 100%" @selection-change="indexListChange">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="申请单编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.requisitionId}}
            </template>
          </el-table-column>
          <el-table-column label="客户名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.clientCompanyName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="开户数量" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.accountOpenQuantity}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="开户公司" show-overflow-tooltip>
            <template slot-scope="scope">
              <span  v-if="scope.row.platform==1">{{scope.row.accountCompanyName}}</span>
              <span  v-if="scope.row.platform!=1">{{scope.row.accountCompanyEnglishName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="媒体平台">
            <template slot-scope="scope">
              <span>{{configurationData.network_array[scope.row.platform]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="审核状态">
            <template slot-scope="scope">
              {{scope.row.examineStatus | examineFilter}}
            </template>
          </el-table-column>
          <el-table-column prop label="创建时间">
            <template slot-scope="scope">
              {{scope.row.applicationTime}}
            </template>
          </el-table-column>
          <el-table-column prop label="操作" width="200">
            <template slot-scope="scope">
              <span v-show="scope.row.platform==1 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,2)" >详情</span>
              <span v-show="scope.row.platform==2 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,4)">详情</span>
              <span v-show="scope.row.platform==3 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,6)" >详情</span>
              <span v-show="scope.row.platform==5 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,8)">详情</span>
              <span v-show="scope.row.platform==1 && scope.row.examineStatus==1 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,3,true)">审批</span>
              <span v-show="scope.row.platform==2 && scope.row.examineStatus==1 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,5,true)">审批</span>
              <span v-show="scope.row.platform==3 && scope.row.examineStatus==1 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,7,true)" >审批</span>
              <span v-show="scope.row.platform==5 && scope.row.examineStatus==1 && common.permFilter(`${common.java}open/details`)" class="main-tableToInfo" @click="getInfo(scope.row,9,true)" >审批</span>
              <span class="main-tableToInfo" v-show="scope.row.platform==2 && scope.row.examineStatus==2" @click="getInfo(scope.row,5,true,true)">关联广告账户ID</span>
              <span class="main-tableToInfo" v-show="scope.row.platform==3 && scope.row.examineStatus==2" @click="getInfo(scope.row,7,true,true)">关联广告账户ID</span>
              <span class="main-tableToInfo" v-show="scope.row.platform==5 && scope.row.examineStatus==2" @click="getInfo(scope.row,9,true,true)">关联广告账户ID</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue @currentSizeChange="sizeInit" @currentChange="init" :pageSize="ajaxDataSearch.pageSize" :total="total" :firstPage="firstPage"/>
      </div>
    </div>
    <!-- Facebook开户申请审批 -->
    <div v-if="showFlag==2 ||showFlag==3">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="cancel()">返回</el-button>
        <span>{{showFlag == 3 ? 'Facebook开户审批' : 'Facebook开户申请单详情'}}</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">基本信息</p>
        <div class="infoBox">
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="label_">申请单编号：</div>
              <div class="value_">{{info.requisitionId}}</div>
            </el-col>
            <el-col :span="7">
              <div class="label_">申请状态：</div>
              <div class="value_">{{info.examineStatus}}</div>
            </el-col>
            <el-col :span="9">
              <div class="label_">申请时间：</div>
              <div class="value_">{{info.applicationTime}}</div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">申请账户数：</div>
                <div class="value_">{{info.accountOpenQuantity}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="flex_">
                <div class="label_">归属客户：</div>
                <div class="value_">{{info.clientCompanyName || '-'}}</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="label_">负责销售：</div>
              <div class="value_">{{info.salesInfo}}</div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">负责AM：</div>
                <div class="value_">{{info.amInfo}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              
            </el-col>
            <el-col :span="9">
              
            </el-col>
          </el-row>
          <el-row v-if="showFlag == 1 && info.examineRemarks" class="elrow_item" type="flex" justify="space-between">
            <el-col :span="20">
              <div class="label_">审批原因：</div>
              <div class="value_ red">{{info.examineRemarks}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">开户公司</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="15">
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="flex_">
                  <div class="label_">公司名称（中文）：</div>
                  <div class="value_">{{info.accountCompanyChineseName}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex_">
                  <div class="label_">公司类别：</div>
                  <div class="value_">{{info.accountCompanyIndustry}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="flex_">
                  <div class="label_">公司名称（英文）：</div>
                  <div class="value_" v-if="showFlag == 2">{{info.accountCompanyEnglishName}}</div>
                  <div class="value_" v-else>
                    <el-input size="small" v-model="examineDatas.accountCompanyEnglishName"></el-input>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex_">
                  <div class="label_">公司地址：</div>
                  <div class="value_">{{info.accountCompanyEnglishAddress}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="label_">统一社会信用代码：</div>
                <div class="value_">{{info.businessLicenseNumber}}</div>
              </el-col>
              <el-col :span="1">
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="20">
                <div class="flex_">
                  <div class="label_">官网链接：</div>
                  <div class="value_"><a class="link_url" :href="info.officialWebsiteLink" target="_blank">{{info.officialWebsiteLink}}</a></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="label_">联系电话：</div>
                <div class="value_">{{info.contactInformation || '-'}}</div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="flex_">
                  <div class="label_">联系人邮箱：</div>
                  <div class="value_">{{info.clientEmail}}</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7">
            <el-row class="elrow_item" type="flex" justify="center">
              <el-col :span="20">
                <div style="display:flex;align-items:start;justify-content:center;">
                  <el-image 
                    style="width: 200px;height: 300px;border-radius:6px;"
                    :src="info.businessLicenseLink" 
                    :preview-src-list="srcList">
                  </el-image>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">推广网站 & 粉丝页 & 应用编号</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">推广网站：</div>
              <div class="value_">
                <div v-if="info.productLink.length>0">
                  <a v-for="(i,k) in info.productLink" class="link_url" style="margin-right:20px;" :key="k" :href="i" target="_blank">{{i}}</a>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">粉丝页ID：</div>
              <div class="value_">
                <div style="display:flex;flex-wrap:wrap;text-align:center;" v-if="info.pageInfo && info.pageInfo.length > 0">
                  <div v-for="(i,key) in info.pageInfo" :key="key" style="margin-right:20px;">
                    <a class="link_url" :href="'https://www.facebook.com/'+i.pageId" target="_blank">{{i.pageId}}</a>
                    <p>{{i.pageName}}</p>
                  </div>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">应用编号：</div>
              <div class="value_">
                <div v-if="info.promoteObjectText && info.promoteObjectText.length>0">
                  <span v-for="(i,k) in info.promoteObjectText" style="margin-right:20px;" :key="k">{{i}}</span>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="info.accountInfo && !isAccountRelate " style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告账户信息 ({{info.accountInfo.length || 0}})</p>
        <el-table
          :data="info.accountInfo"
          border
          style="width: 100%">
          <el-table-column
            prop="accountName"
            label="广告账户名称">
          </el-table-column>
          <el-table-column
            prop="accountId"
            label="广告账户ID">
          </el-table-column>
          <el-table-column
            prop="timeZoneName"
            label="时区">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="info.accountInfo && isAccountRelate" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告账户</p>
        <el-table
          :data="info.accountInfo"
          border
          style="width: 100%">
          <el-table-column
            prop="accountName"
            label="广告账户名称">
          </el-table-column>
          <el-table-column
            label="广告账户ID">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.accountId"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="timeZoneName"
            label="时区">
          </el-table-column>
        </el-table>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告策划经销商</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_" style="flex: 0 0 300px;">申请者是否与广告策划经销商进行合作？</div>
              <div class="value_" style="padding-left:30px;">
                {{info.usedResellerLink ? '是， ' : '否 '}} {{info.agentInfo}}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start" v-if="showFlag == 3">
          <el-col :span="24">
            <div class="flex_" style="align-items:center;">
              <div class="label_" style="flex: 0 0 200px;">广告策划经销商的BM编号：</div>
              <div class="value_">
                <el-input v-model="examineDatas.planningAgencyBusinessID" size="small" style="width:400px"></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">支付信息</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">支付类型：</div>
              <div class="value_">
                预支额度
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_" style="align-items:center;">
              <div class="label_">预设花费上限：</div>
              <div class="value_" v-if="showFlag == 2">
                {{info.spendLimit?info.spendLimit+'美元':'-'}}
              </div>
              <div class="value_" v-if="showFlag == 3" style="display:flex;align-items:center;">
                <!-- <el-input v-model="examineDatas.spendLimit" size="small" ></el-input>  -->
                <el-input placeholder="请输入" v-model="examineDatas.spendLimit" size="small">
                  <template slot="append">美元</template>
                </el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-show="showFlag==3" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">账号审批</p>
        <div class="infoBox">
          <section v-if="info.examineRecords && info.examineRecords.length>0 && !isAccountRelate" class="info-cell examin-history" style="width:100%">
            <label>申请历史:</label>
            <div style="display:block" class="history-wrap">
              <p class="history" v-for="(item,index) in info.examineRecords" :key="index">
                <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
                <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
                <i>{{item.examineRemarks}}</i>
              </p>
            </div>
          </section>
          <section
            class="info-cell examin-history"
            style="width:100%;"
            v-if="info.examineRecords && !isAccountRelate && info.examineRecords[0] && info.examineRecords[0].attachment && info.examineRecords[0].attachment !=='null'"
          >
            <label>附件:</label>
            <div class="history">
              <i style="max-width:500px;display:inline-block;margin-right:16px">{{attachment}}</i>
              <div class="download-self main-button" @click="downloadAttachment()">
                <i class="gongzuotai gztxiazai"></i>
                下载
              </div>
            </div>
          </section>
          
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">关联客户:</p>
              <div class="info-text ">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-input v-if="disabledd" :disabled="true" v-model="projectPostData.clientCompanyName"></el-input>
                  <el-select
                    v-else
                    v-model="examineDatas.clientCompanyId"
                    value-key="clientCompanyId"
                    filterable
                    clearable
                    remote
                    :remote-method="remoteMethod"
                    placeholder="请选择要关联的客户"
                    @change="changeCompany"
                  >
                    <el-option
                      v-for="(item,index) in clientCompanyList"
                      :key="index"
                      :label="item.clientCompanyName"
                      :value="item.clientCompanyId"
                    ></el-option>
                    <div style="float: right;margin-right:10px;padding-bottom: 10px">
                      <el-pagination
                        @current-change="getRelatedComList"
                        :current-page="projectPostData.pageNum"
                        :page-size="projectPostData.pageSize"
                        layout="total, prev, pager, next"
                        :total="projectPostData.alltotal"
                      ></el-pagination>
                    </div>
                  </el-select>
                </div>
                <el-button type="text" style="margin-left:10px;padding:7px 0;" @click="openEip('xjkh')">新建客户</el-button>                
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">审批状态:</p>
              <div class="info-text calc-4w">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-select
                    v-model="examineDatas.examineStatus"
                    @change="dealSelect"
                    clearable
                    placeholder="审批状态"
                  >
                    <el-option
                      v-for="item in optionsStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="info-row" v-show="!isAccountRelate && examineDatas.examineStatus == 2">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">关联IO:</p>
              <div class="info-text ">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-select
                    filterable
                    v-model="examineDatas.ioId"
                    placeholder="请选择IO"
                  >
                    <el-option
                      v-for="item in ioList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <el-button type="text" style="margin-left:10px;padding:7px 0;" @click="createIO()">新建IO</el-button>                
              </div>
            </div>
          </div>
          <div class="info-row" v-if="!isAccountRelate">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">行业:</p>
              <div class="info-text calc-9w">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                <el-select size="mini" @change="selectIndustryF" value-key="industryCode" v-model="examineDatas.advertiserIndustryitem">
                  <el-option v-for="(item) in allIndustryList"
                    :key="item.industryCode"
                    :value="item"
                    :label="item.industryName"
                  ></el-option>
                </el-select>
                </div>
              </div>
            </div>
            <div class="info-cell w-33" v-show="examineDatas.advertiserIndustryitem.industryCode">
              <p class="info-title t-4w">子行业:</p>
              <div class="info-text calc-4w">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                <el-select size="mini" value-key="industryCode" v-model="examineDatas.subIndustry">
                  <el-option v-for="(item) in childIndustryList"
                    :key="item.industryCode"
                    :value="item.industryCode"
                    :label="item.industryName"
                  ></el-option>
                </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">审核描述：</p>
              <div class="info-text calc-9w">
                <el-input type="textarea" :rows="2" v-model="examineDatas.examineRemarks" placeholder></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showFlag==3" style="background:#fff;padding:24px;margin-bottom:24px;">
        <div class="info-row">
          <div class="info-cell w-80">
            <p class="info-title"></p>
            <div class="info-text">
              <div class="main-button" @click="examine(info.platform)">确定</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Twitter开户申请审批 -->
    <div v-show="showFlag==4 ||showFlag==5">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="cancel()">返回</el-button>
        <span>{{showFlag == 5 ? 'Twitter开户审批' : 'Twitter开户申请单详情'}}</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">基本信息</p>
        <div class="infoBox">
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">申请单编号：</div>
                <div class="value_">{{info.requisitionId}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="flex_">
                <div class="label_">申请状态：</div>
                <div class="value_">{{info.examineStatus}}</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="flex_">
                <div class="label_">申请时间：</div>
                <div class="value_">{{info.applicationTime}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">账户时区：</div>
                <div class="value_">{{info.timeZoneName}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="flex_">
                <div class="label_">投放国家：</div>
                <div class="value_">{{info.serviceCountry}}</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="flex_">
                <div class="label_">投放目标：</div>
                <div class="value_">{{info.serviceTargetIdArray ? info.serviceTargetIdArray.join('，') : '-'}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">申请广告账户数：</div>
                <div class="value_">{{info.accountOpenQuantity}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="label_">归属预计出价（元）：</div>
              <div class="value_">{{info.estimatedBid || '-'}}</div>
            </el-col>
            <el-col :span="9">
              <div class="flex_">
                <div class="label_">归属客户：</div>
                <div class="value_">{{info.clientCompanyName || '-'}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="label_">负责销售：</div>
              <div class="value_">{{info.salesInfo}}</div>
            </el-col>
            <el-col :span="7">
              <div class="label_">负责AM：</div>
              <div class="value_">{{info.amInfo}}</div>
            </el-col>
            <el-col :span="9" v-if="showFlag == 1 && info.examineRemarks">
              <div class="label_">审批原因：</div>
              <div class="value_ red">{{info.examineRemarks}}</div>
            </el-col>
            <el-col :span="9" v-else>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">开户公司</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="15">
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="flex_">
                  <div class="label_">公司名称（英文）：</div>
                  <div class="value_">{{info.accountCompanyEnglishName}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex_">
                  <div class="label_">公司类别：</div>
                  <div class="value_">{{info.accountCompanyIndustry}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="flex_">
                  <div class="label_">统一社会信用代码：</div>
                  <div class="value_">{{info.businessLicenseNumber}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex_">
                  <div class="label_">公司地址（英文）：</div>
                  <div class="value_">{{info.accountCompanyEnglishAddress}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="20">
                <div class="flex_">
                  <div class="label_">官网链接：</div>
                  <div class="value_"><a class="link_url" :href="info.officialWebsiteLink" target="_blank">{{info.officialWebsiteLink}}</a></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="label_">联系人：</div>
                <div class="value_">{{info.contactPerson || '-'}}</div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="label_">联系电话：</div>
                <div class="value_">{{info.contactInformation || '-'}}</div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="flex_">
                  <div class="label_">联系人邮箱：</div>
                  <div class="value_">{{info.clientEmail}}</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7">
            <el-row class="elrow_item" type="flex" justify="center">
              <el-col :span="20">
                <div style="display:flex;align-items:start;justify-content:center;">
                  <el-image 
                    style="width: 200px;height: 300px;border-radius:6px;"
                    :src="info.businessLicenseLink" 
                    :preview-src-list="srcList">
                  </el-image>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">推广网站 & 粉丝页 & 应用编号</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">推广网站：</div>
              <div class="value_">
                <div v-if="info.productLink.length>0">
                  <a v-for="(i,k) in info.productLink" class="link_url" style="margin-right:20px;" :key="k" :href="i" target="_blank">{{i}}</a>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">粉丝页ID：</div>
              <div class="value_">
                <div style="display:flex;" v-if="info.pageLink">
                  <a class="link_url" :href="info.pageLink" target="_blank">{{info.pageLink}}</a>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">应用编号：</div>
              <div class="value_">
                <div v-if="info.promoteObjectText && info.promoteObjectText.length>0">
                  <span v-for="(i,k) in info.promoteObjectText" style="margin-right:20px;" :key="k">{{i}}</span>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="info.accountInfo && isAccountRelate" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告账户</p>
        <el-table
          :data="info.accountInfo"
          border
          style="width: 100%">
          <el-table-column
            label="广告账户名称">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.accountName"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="广告账户ID">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.accountId"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="defaultRecharge"
            label="充值金额（美元）">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="(info.accountInfo && !isAccountRelate )" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告账户</p>
        <el-table
          :data="info.accountInfo"
          border
          style="width: 100%">
          <el-table-column
            prop="accountName"
            label="广告账户名称">
          </el-table-column>
          <el-table-column
            v-if="showFlag == 4"
            prop="accountId"
            label="广告账户ID">
          </el-table-column>
          <el-table-column
            prop="defaultRecharge"
            label="充值金额（美元）">
          </el-table-column>
        </el-table>
      </div>
      <div v-show="showFlag==5" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">账号审批</p>
        <div class="infoBox">
          <section v-if="info.examineRecords && info.examineRecords.length>0 && !isAccountRelate" class="info-cell examin-history" style="width:100%">
            <label>申请历史:</label>
            <div style="display:block" class="history-wrap">
              <p class="history" v-for="(item,index) in info.examineRecords" :key="index">
                <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
                <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
                <i>{{item.examineRemarks}}</i>
              </p>
            </div>
          </section>
          <section
            class="info-cell examin-history"
            style="width:100%;"
            v-if="info.examineRecords && !isAccountRelate && info.examineRecords[0] && info.examineRecords[0].attachment && info.examineRecords[0].attachment !=='null'"
          >
            <label>附件:</label>
            <div class="history">
              <i style="max-width:500px;display:inline-block;margin-right:16px">{{attachment}}</i>
              <div class="download-self main-button" @click="downloadAttachment()">
                <i class="gongzuotai gztxiazai"></i>
                下载
              </div>
            </div>
          </section>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">关联客户:</p>
              <div class="info-text ">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-input v-if="disabledd" :disabled="true" v-model="projectPostData.clientCompanyName"></el-input>
                  <el-select
                    v-model="examineDatas.clientCompanyId"
                    v-else
                    value-key="clientCompanyId"
                    filterable
                    clearable
                    remote
                    :remote-method="remoteMethod"
                    placeholder="请选择要关联的客户"
                    @change="changeCompany"
                  >
                    <el-option
                      v-for="(item,index) in clientCompanyList"
                      :key="index"
                      :label="item.clientCompanyName"
                      :value="item.clientCompanyId"
                    ></el-option>
                    <div style="float: right;margin-right:10px;padding-bottom: 10px">
                      <el-pagination
                        @current-change="getRelatedComList"
                        :current-page="projectPostData.pageNum"
                        :page-size="projectPostData.pageSize"
                        layout="total, prev, pager, next"
                        :total="projectPostData.alltotal"
                      ></el-pagination>
                    </div>
                  </el-select>
                </div>
                <el-button type="text" style="margin-left:10px;padding:7px 0;" @click="openEip('xjkh')">新建客户</el-button>                
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">审批状态:</p>
              <div class="info-text calc-4w">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-select
                    v-model="examineDatas.examineStatus"
                    @change="dealSelect"
                    clearable
                    placeholder="审批状态"
                  >
                    <el-option
                      v-for="item in optionsStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="info-row" v-if="!isAccountRelate && examineDatas.examineStatus == 2">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">关联IO:</p>
              <div class="info-text">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-select
                    v-model="examineDatas.ioId"
                    placeholder="请选择IO"
                  >
                    <el-option
                      v-for="item in ioList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <el-button type="text" style="margin-left:10px;padding:7px 0;" @click="createIO()">新建IO</el-button>
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">审核描述：</p>
              <div class="info-text calc-9w">
                <el-input type="textarea" :rows="2" v-model="examineDatas.examineRemarks" placeholder></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showFlag==5" style="background:#fff;padding:24px;margin-bottom:24px;">
        <div class="info-row">
          <div class="info-cell w-80">
            <p class="info-title"></p>
            <div class="info-text">
              <div class="main-button" @click="examine(info.platform)">确定</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Google开户申请审批 -->
    <div v-if="showFlag==6 ||showFlag==7">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="cancel()">返回</el-button>
        <span>{{showFlag == 7 ? 'Google开户审批' : 'Google开户申请单详情'}}</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">基本信息</p>
        <div class="infoBox">
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">申请单编号：</div>
                <div class="value_">{{info.requisitionId}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="flex_">
                <div class="label_">申请状态：</div>
                <div class="value_">{{info.examineStatus}}</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="flex">
                <div class="label_">申请时间：</div>
                <div class="value_">{{info.applicationTime}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">账户时区：</div>
                <div class="value_">{{info.timeZoneName}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="flex_">
                <div class="label_">开户币种：</div>
                <div class="value_">{{info.currency}}</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="flex_">
                <div class="label_">媒体平台：</div>
                <div class="value_">{{'Google'}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">申请账户数：</div>
                <div class="value_">{{info.accountOpenQuantity}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="flex_">
                <div class="label_">归属客户：</div>
                <div class="value_">{{info.clientCompanyName || '-'}}</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="flex_">
                <div class="label_">负责销售：</div>
                <div class="value_">{{info.salesInfo}}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="flex_">
                <div class="label_">负责AM：</div>
                <div class="value_">{{info.amInfo}}</div>
              </div>
            </el-col>
            <el-col :span="7">
              
            </el-col>
            <el-col :span="9">
              
            </el-col>
          </el-row>
          <el-row v-if="showFlag == 6 && info.examineRemarks" class="elrow_item" type="flex" justify="space-between">
            <el-col :span="20">
              <div class="label_">审批原因：</div>
              <div class="value_ red">{{info.examineRemarks}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">开户公司</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="15">
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="flex_">
                  <div class="label_">公司名称（英文）：</div>
                  <div class="value_">{{info.accountCompanyEnglishName}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex_">
                  <div class="label_">公司类别：</div>
                  <div class="value_">{{info.accountCompanyIndustry}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="13">
                <div class="flex_">
                  <div class="label_">统一社会信用代码：</div>
                  <div class="value_">{{info.businessLicenseNumber}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex_">
                  <div class="label_">公司地址（英文）：</div>
                  <div class="value_">{{info.accountCompanyEnglishAddress}}</div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="20">
                <div class="flex_">
                  <div class="label_">官网链接：</div>
                  <div class="value_"><a class="link_url" :href="info.officialWebsiteLink" target="_blank">{{info.officialWebsiteLink}}</a></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="label_">联系人：</div>
                <div class="value_">{{info.contactPerson || '-'}}</div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="label_">联系电话：</div>
                <div class="value_">{{info.contactInformation || '-'}}</div>
              </el-col>
            </el-row>
            <el-row class="elrow_item" type="flex" justify="flex-start">
              <el-col :span="10">
                <div class="flex_">
                  <div class="label_">联系人邮箱：</div>
                  <div class="value_">{{info.clientEmail}}</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7">
            <el-row class="elrow_item" type="flex" justify="center">
              <el-col :span="20">
                <div style="display:flex;align-items:start;justify-content:center;">
                  <el-image 
                    style="width: 200px;height: 300px;border-radius:6px;"
                    :src="info.businessLicenseLink" 
                    :preview-src-list="srcList">
                  </el-image>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">推广网站 & 粉丝页 & 应用编号</p>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">推广网站：</div>
              <div class="value_">
                <div v-if="info.productLink.length>0">
                  <a v-for="(i,k) in info.productLink" class="link_url" style="margin-right:20px;" :key="k" :href="i" target="_blank">{{i}}</a>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">粉丝页ID：</div>
              <div class="value_">
                <div style="display:flex;" v-if="info.pageLink">
                  <a class="link_url" :href="info.pageLink" target="_blank">{{info.pageLink}}</a>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="elrow_item" type="flex" justify="flex-start">
          <el-col :span="24">
            <div class="flex_">
              <div class="label_">应用编号：</div>
              <div class="value_">
                <div v-if="info.promoteObjectText && info.promoteObjectText.length>0">
                  <span v-for="(i,k) in info.promoteObjectText" style="margin-right:20px;" :key="k">{{i}}</span>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="info.accountInfo && !isAccountRelate" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告账户</p>
        <el-table
          :data="info.accountInfo"
          border
          style="width: 100%">
          <el-table-column
            prop="accountName"
            label="广告账户名称">
          </el-table-column>
          <el-table-column
            v-if="showFlag == 6"
            prop="accountId"
            label="广告账户ID">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="info.accountInfo && isAccountRelate" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">广告账户</p>
        <el-table
          :data="info.accountInfo"
          border
          style="width: 100%">
          <el-table-column
            prop="accountName"
            label="广告账户名称">
          </el-table-column>
          <el-table-column
            label="广告账户ID">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.accountId"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="defaultRecharge"
            label="充值金额（美元）">
          </el-table-column>
        </el-table>
      </div>
      <div v-show="showFlag==7" style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">账号审批</p>
        <div class="infoBox">
          <section v-if="info.examineRecords && info.examineRecords.length>0 && !isAccountRelate" class="info-cell examin-history" style="width:100%">
            <label>申请历史:</label>
            <div style="display:block" class="history-wrap">
              <p class="history" v-for="(item,index) in info.examineRecords" :key="index">
                <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
                <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
                <i>{{item.examineRemarks}}</i>
              </p>
            </div>
          </section>
          <section
            class="info-cell examin-history"
            style="width:100%;"
            v-if="info.examineRecords && info.examineRecords[0] && info.examineRecords[0].attachment && info.examineRecords[0].attachment !=='null' && !isAccountRelate"
          >
            <label>附件:</label>
            <div class="history">
              <i style="max-width:500px;display:inline-block;margin-right:16px">{{attachment}}</i>
              <div class="download-self main-button" @click="downloadAttachment()">
                <i class="gongzuotai gztxiazai"></i>
                下载
              </div>
            </div>
          </section>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">关联客户:</p>
              <div class="info-text ">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-input v-if="disabledd" :disabled="true" v-model="projectPostData.clientCompanyName"></el-input>
                  <el-select
                    v-model="examineDatas.clientCompanyId"
                    v-else
                    filterable
                    clearable
                    remote
                    :remote-method="remoteMethod"
                    placeholder="请选择要关联的客户"
                    value-key="clientCompanyId"
                    @change="changeCompany"
                  >
                    <el-option
                      v-for="(item,index) in clientCompanyList"
                      :key="index"
                      :label="item.clientCompanyName"
                      :value="item.clientCompanyId"
                    ></el-option>
                    <div style="float: right;margin-right:10px;padding-bottom: 10px">
                      <el-pagination
                        @current-change="getRelatedComList"
                        :current-page="projectPostData.pageNum"
                        :page-size="projectPostData.pageSize"
                        layout="total, prev, pager, next"
                        :total="projectPostData.alltotal"
                      ></el-pagination>
                    </div>
                  </el-select>
                </div>
                <el-button type="text" style="margin-left:10px;padding:7px 0;" @click="openEip('xjkh')">新建客户</el-button>                
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">审批状态:</p>
              <div class="info-text calc-4w">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-select
                    v-model="examineDatas.examineStatus"
                    @change="dealSelect"
                    clearable
                    placeholder="审批状态"
                  >
                    <el-option
                      v-for="item in optionsStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="info-row" v-if="!isAccountRelate && examineDatas.examineStatus == 2">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">关联IO:</p>
              <div class="info-text">
                <div class="main-selectBox" style="vertical-align: top;margin: 0">
                  <el-select
                    v-model="examineDatas.ioId"
                    placeholder="请选择IO"
                  >
                    <el-option
                      v-for="item in ioList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <el-button type="text" style="margin-left:10px;padding:7px 0;" @click="createIO()">新建IO</el-button>                
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w" style="line-height: 32px">审核描述：</p>
              <div class="info-text calc-9w">
                <el-input type="textarea" :rows="2" v-model="examineDatas.examineRemarks" placeholder></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showFlag==7" style="background:#fff;padding:24px;margin-bottom:24px;">
        <div class="info-row">
          <div class="info-cell w-80">
            <p class="info-title"></p>
            <div class="info-text">
              <div class="main-button" @click="examine(info.platform)">确定</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TikTok开户申请审批 -->
    <div v-show="showFlag==8 ||showFlag==9">
      <div style="background:#fff;padding:24px;display:flex;align-items:center;margin-bottom:24px;">
          <i class="el-icon-back" @click="cancel()"></i>
          <p class="main-title" style="margin-bottom:0;margin-left:20px;">开户申请信息</p>
        </div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">申请时间:</p>
            <div class="info-text calc-4w">{{info.applicationTime}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">创建人:</p>
            <div class="info-text calc-4w">{{info.userName}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">开户数量:</p>
            <div class="info-text calc-4w">{{info.accountOpenQuantity}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-2w">AM:</p>
            <div class="info-text calc-2w">{{info.amId}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">公司名称:</p>
            <div class="info-text calc-4w">
              <!-- {{info.applicationTime}} -->
            </div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">注册地区:</p>
            <div class="info-text calc-4w">{{info.accountOpenQuantity}}</div>
          </div>
        </div>

        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-2w">行业:</p>
            <div class="info-text calc-2w">{{info.amId}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">开户时区:</p>
            <div class="info-text calc-4w">
              <!-- {{info.applicationTime}} -->
            </div>
          </div>
        </div>

        <div class="info-row-line"></div>

        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">账户名称:</p>
            <div class="info-text calc-4w">
              <!-- {{info.applicationTime}} -->
            </div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">投放方式:</p>
            <div class="info-text calc-4w">
              <template v-if="info.serviceType==1">自投</template>
              <template v-if="info.serviceType==2">代投</template>
            </div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">邮箱:</p>
            <div class="info-text calc-2w">{{info.clientEmail}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">密码:</p>
            <div class="info-text calc-4w">{{info.bytedancePassword}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">联系人名称:</p>
            <div class="info-text calc-4w">{{info.contactPerson}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">联系电话:</p>
            <div class="info-text calc-2w">{{info.contactInformation}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">推广链接:</p>
            <div class="info-text calc-4w">{{info.productLink}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">营业执照编号:</p>
            <div class="info-text calc-4w">{{info.businessLicenseNumber}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">营业执照:</p>
              <el-image 
                style="width: 192px;height: 108px;border-radius:4px;"
                :src="info.businessLicenseLink" 
                :preview-src-list="srcList">
              </el-image>
          </div>
        </div>
        <!--row13-->
        <div class="info-row-line"></div>
        <section
          class="info-cell examin-history"
          style="width:100%"
          v-if="info.examineRecords && info.examineRecords.length>0"
        >
          <label>申请历史:</label>
          <div style="display:block" class="history-wrap">
            <p class="history" v-for="(item,index) in info.examineRecords" :key="index">
              <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
              <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
              <i>{{item.examineRemarks}}</i>
            </p>
          </div>
        </section>

        <!-- info.examineRecords && info.examineRecords[0].attachment -->
        <section
          class="info-cell examin-history"
          style="width:100%;"
          v-if="info.examineRecords && info.examineRecords[0] && info.examineRecords[0].attachment && info.examineRecords[0].attachment!=='null'"
        >
          <label>附件:</label>
          <div class="history">
            <i style="width:500px;display:inline-block">{{attachment}}</i>
            <div class="download-self main-button" @click="downloadAttachment()">
              <i class="gongzuotai gztxiazai"></i>
              下载
            </div>
          </div>
        </section>
        <div v-show="showFlag==9" class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w" style="line-height: 32px">审批状态:</p>
            <div class="info-text calc-4w">
              <div class="main-selectBox" style="vertical-align: top;margin: 0">
                <el-select
                  v-model="examineDatas.examineStatus"
                  @change="dealSelect"
                  clearable
                  placeholder="审批状态"
                >
                  <el-option
                    v-for="item in optionsStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="info-cell w-60">
            <p class="info-title t-2w" style="line-height: 32px">备注:</p>
            <div class="info-text calc-2w">
              <div class="main-inputBox" style="margin: 0;width: 100%">
                <el-input v-model="examineDatas.examineRemarks" placeholder></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="info-row" style="margin-top: 40px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <p class="info-title"></p>
            <div class="info-text">
              <div v-show="showFlag==9" class="main-button" @click="examine(info.platform)">确定</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 账户关联 -->
    <el-dialog
      title="申请关联账户"
      :visible.sync="connectVisible"
      class="common-dialog"
      width="696px"
      :before-close="cancelContact"
    >
      <main>
        <section class="detail">
          <div class="ele">
            <label>客户公司:</label>
            <span>{{connectDetail.clientCompanyName}}</span>
          </div>
          <div class="ele">
            <label>项目:</label>
            <span>{{connectDetail.projectName}}</span>
          </div>
          <div class="ele">
            <label>开户公司:</label>
            <span>{{connectDetail.accountCompanyName}}</span>
          </div>
          <div class="ele">
            <label>平台:</label>
            <span>{{configurationData.platform_array[connectDetail.platform]}}</span>
          </div>
          <div class="ele">
            <label>AM:</label>
            <span>{{connectDetail.amName}}</span>
          </div>
          <div class="ele">
            <label>销售:</label>
            <span>{{connectDetail.salesName}}</span>
          </div>
          <div class="ele">
            <label>开户数量:</label>
            <span>{{connectDetail.accountOpenQuantity}}</span>
          </div>
          <div class="ele" id="t0">
            <label>系统时间:</label>
            <span>{{connectDetail.systemDate}}</span>
          </div>
        </section>
        <el-form
          :label-position="'top'"
          label-width="80px"
          class="el-form-self"
          :model="connectForm"
          ref="connectValidateForm"
          :rules="connectRules"
        >
          <el-form-item label="广告账户:" class="ads">
            <template v-if="connectAccountList.length<=0 && !dontShowAccount">
              <el-input
                type="textarea"
                v-model="connectForm.accountIds"
                placeholder="多个账户之间使用中英文,分隔"
              ></el-input>
              <span class="ok" @click="isHaveConnectAccount($event,'','','')">OK</span>
            </template>
            <template v-if="connectAccountList.length>0 || dontShowAccount">
              <section style="display: block;">
                <div style="display:flex" v-for="(item,index) in connectAccountList" :key="index">
                  <div class="input-edit-wrap" v-show="item.canEdit">
                    <el-input
                      v-model="item.accountId"
                      :ref="'test'+index"
                      v-focus="item.canEdit"
                      @blur="onblur(item,index)"
                    ></el-input>
                  </div>
                  <div
                    class="cantEdit"
                    v-show="!item.canEdit"
                    :style="{color:(item.relation?'red':'')}"
                  >
                    {{item.accountId}}
                    <i
                      v-if="item.relation"
                      style="font-size:12px;tranform:scale(0.9);display:inline-block"
                    >(重复关联)</i>
                  </div>
                  <span class="accountspan">
                    <i v-if="item.accountName">
                      <el-tooltip :content="item.accountName" placement="top" enterable>
                        <span>{{item.accountName}}</span>
                      </el-tooltip>
                    </i>
                    <i style="color:Red" v-else>没有相关账户</i>
                  </span>

                  <span
                    class="accountEditSpan"
                    @click="canEdit(item,index)"
                    style="margin-right:15px;"
                  >
                    <i class="el-icon-edit"></i>&nbsp;&nbsp;&nbsp;编辑
                  </span>
                  <span class="accountEditSpan" @click="toDel(item,index)">
                    <i class="el-icon-delete"></i>
                  </span>
                </div>
              </section>
            </template>
          </el-form-item>
          <section class="updateEmail">
            <h2>状态更新邮件信息</h2>
            <div class="wrap">
              <el-form-item label="最终状态" prop="examineStatus">
                <el-select v-model="connectForm.examineStatus">
                  <el-option key="5" label="已开户" :value="5"></el-option>
                  <el-option key="6" label="被拒绝" :value="6"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注:">
                <!-- <el-input v-model="connectForm.examineRemarks"></el-input> -->
                <el-autocomplete
                  popper-class="my-autocomplete"
                  v-model="connectForm.examineRemarks"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入备注"
                  @select="handleSelect"
                >
                  <i class="el-icon-edit el-input__icon" slot="suffix"></i>
                  <template slot-scope="{ item }">
                    <!-- <div class="name">{{ item.id }}</div> -->
                    <span class="addr">{{ item.value }}</span>
                  </template>
                </el-autocomplete>
              </el-form-item>
              <el-form-item>
                <span slot="label">附件:</span>
                <el-upload
                  ref="upload"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :before-upload="beforeAvatarUploadFile"
                  :show-file-list="false"
                  :file-list="fileList"
                >
                  <div class="main-button">上传附件</div>
                </el-upload>
                <div class="filename">
                  <i>{{accountFilename}}</i>
                  <span class="del" @click="delFile()">删除</span>
                </div>
              </el-form-item>
            </div>
          </section>
        </el-form>
      </main>
      <div class="dialog-buttons">
        <span
          class="main-button l"
          style="background:white;border:#DFE2E7 solid 1px;color:#788090"
          @click="cancelContact()"
        >取消</span>
        <span class="main-button" @click="confirmConnect()">确定</span>
      </div>
    </el-dialog>
    <el-dialog
      title="添加邮箱提示"
      :visible.sync="inputEmailToast"
      class="common-dialog"
      width="696px"
    >
      <main>
        <el-form label-position="right" size="small">
          <el-form-item label="邮箱号：" required>
            <el-input style="width:300px;" v-model="emailAddress" placeholder="请输入邮箱号"></el-input>
          </el-form-item>
        </el-form>
      </main>
      <div class="dialog-buttons">
        <span
          class="main-button l"
          style="background:white;border:#DFE2E7 solid 1px;color:#788090"
          @click="emailAddress = '';inputEmailToast = false;examineDatas.clientRemindEmail = ''"
        >取消</span>
        <span class="main-button" @click="reExamine()">确定</span>
      </div>
    </el-dialog>
    <!-- 新建IO -->
    <!-- <div class="main-pop" v-show="createandedit">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">快速创建IO</span>
          <i class="gongzuotai gztguanbi" @click="cancel()"></i>
        </div>
        <div class="body">
          <div class="flexd" style="margin-bottom:10px;">
            <label>关联项目</label>
            <div>
              <el-select
                style="width:300px;"
                size="mini"
                v-model="newIOpostdata.project_id"
                filterable
                clearable
                placeholder="请选择"
                @change="updateValue"
              >
                <el-option
                  v-for="(item,k) in projectList"
                  :key="k"
                  :label="item.project_name"
                  :value="item.project_id"
                ></el-option>
                <div style="float: right;margin-right:10px;padding-bottom: 10px">
                  <el-pagination
                    @current-change="getProjectList"
                    :current-page="projectPostData.pageNum"
                    :page-size="projectPostData.pageSize"
                    layout="total, prev, pager, next"
                    :total="projectPostData.total"
                  ></el-pagination>
                </div>
              </el-select>
            </div>
          </div>
          <p style="margin-bottom:10px;font-size:12px;color:#f00;">为此项目添加已存在IO,请在IO列表搜索到目标IO,编辑其归属项目</p>
          <div class="flexd">
            <label>IO名称</label>
            <el-input
              v-model="newIOpostdata.name"
              style="width:300px;"
              size="mini"
              placeholder="新IO命名规范请参考已有IO"
            ></el-input>
          </div>
          <div class="flexd">
            <label class="longlabel">跟随项目返点规则</label>
            <el-radio v-model="newIOpostdata.project_rebate" :label="1">是</el-radio>
            <el-radio v-model="newIOpostdata.project_rebate" :label="2">否</el-radio>
          </div>
          <div class="flexd">
            <label>IO状态</label>
            <el-radio v-model="newIOpostdata.status" :label="1">激活</el-radio>
            <el-radio v-model="newIOpostdata.status" :label="2">暂停</el-radio>
          </div>
          <div class="flexd">
            <label>投放类型</label>
            <el-radio v-model="newIOpostdata.delivery_type" :label="1">自投</el-radio>
            <el-radio v-model="newIOpostdata.delivery_type" :label="2">代投</el-radio>
          </div>
          <div class="flexd">
            <label>结算类型</label>
            <el-radio v-model="newIOpostdata.settlement_type" :label="1">常规消费制</el-radio>
            <el-radio v-model="newIOpostdata.settlement_type" :label="2">项目完成制(合同包含签约额)</el-radio>
          </div>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="cancel()">取消</button>
          <button class="main-button r" @click="saveRate()">确定</button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      selectedIndexList:[],
      emailAddress:"",
      clientCompanyList:[],
      srcList:[],
      searchType:2,
      projectList:[],
      projectPostData: {
        alltotal: 0,
        pageSize: 10,
        pageNum: 1,
        clientCompanyName:""
      },
      createandedit:false,
      firstPage: 1,
      loading: true,
      connectVisible: false,
      msg: "",
      // EntExplainImg: EntExplainImg,
      showFlag: 1,
      pageSize: 10,
      total: 1,
      applicationTimearr: "",
      ajaxDataSearch: {
        clientCompanyName: "", //	否	string	客户公司名称
        accountCompanyName: "", //	否	string	账户公司名称
        requisitionId: "",
        isFirst: "", //	是否首次开户:1 是,2 否
        platform: "", //	否	int	平台:1 facebook,2 twitter,3 google,5 tiktok
        examineStatus: "", //	申请状态:1 待审核,2 通过,3 驳回
        applicationTimeStart:"",
        applicationTimeEnd:"",
        pageNum:1,
        pageSize:15
      },
      optionsTrade: [],
      optionsStatus: [
        {
          value: "2",
          label: "审核通过"
        },
        {
          value: "4",
          label: "审核驳回"
        }
      ],
      examineDatas: {
        id: "",
        examineStatus: "",
        examineRemarks: "",
        ioId:"",
        accountCompanyEnglishName:"",
        clientCompanyId:"",
        advertiserIndustry:"",
        subIndustry:"",
        spendLimit:"0.01",
        planningAgencyBusinessID:"",
        advertiserIndustryitem:{},
      },
      list: [],
      info: {
        businessLicenseLink:"",
        productLink:[],
        isFirst: "",
        project_member: {
          member_id: "dgerg45yrherg432",
          dep_id: "efdsvsdf2",
          department_name: "部门名称cs",
          user_name: "王玉影_cscs"
        },
        expected_pay_out_cost_details: [
          {
            purchasing_channel: "1",
            supplier_name: "1",
            business_content: "1",
            business_cycle: "1",
            purchase_amount: "1.00",
            remark: "1"
          }
        ],
        expected_project_cost_details: [
          {
            project_cost_content: "1",
            purchase_amount: "1.00",
            remark: "1"
          }
        ],
        project_schedule_happening: [
          {
            amount_income: "0.00",
            cost_amount: "0.00",
            paid_outgoing_amount: "0.00",
            settlement_amount_received: "0.00",
            change_ratio: "0.00"
          }
        ],
        project_tender_file: [
          {
            file_name: "cs",
            file_size: 0
          }
        ],
        project_meeting_summary: [],
        project_purchase_acceptance_file: [],
        project_case_report_file: [],
        project_case_file: []
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        network_array:{},
        open_approval_status: {},
        is_first_open_account_config: {},
        client_type_config: {},
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: []
      },
      editStatusPop: false,
      project_id: "",
      project_status: "",
      openZhPop: false,
      radio: "1",
      editingProObj: {
        project_id: "fwef2fre",
        project_name: "今日头条-cscs",
        business_type: 1,
        project_manager_id: "dgerg45yrherg432",
        approval_status: 1,
        status: 1,
        sales_id: "dgerg45yrherg432",
        project_created_at: "2020-04-20 16:31:44",
        project_cycle: "",
        applicant_id: "dgerg45yrherg432",
        remark: "cs",
        client_company_name: "client_company--cscs",
        level_id: "耗费iuwehiw76gfie",
        level_consume_value: "一事一议",
        industry_name: "cs",
        contract_name: "合同名称cs",
        contract_id: "扫地yiewbieb",
        contract_start: "2020-04-16 16:50:35",
        contract_end: "2020-04-16 16:50:35",
        name: "cs",
        contact_name: "contact_name--cscs",
        status_name: "正常",
        approval_status_name: "",
        business_type_name: "整合营销",
        project_manager_name: "王玉影_cscs",
        sales_name: "王玉影_cscs",
        applicant_name: "王玉影_cscs",
        is_change: "1",
        project_numbering: "dsifh8273yfgf"
      },
      accountInfo: {
        account_info: [
          {
            account: "这是平台账号1",
            max_number_accounts: 0,
            daily_account_opening_limit: 0,
            is_prepaid_recharge: 0,
            credit_amount: "",
            distribution_credit_amount: "0.00",
            sales_id: "",
            front_end_id: "",
            am_id: "",
            optimizer_id: "",
            contract_start_time: null,
            contract_end_time: null,
            email_address: ""
          }
        ],
        project_principal: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        sales_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        front_end_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        am_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        optimizer_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ]
      },
      ajaxDataOpen: {
        project_id: "", //	是	string	项目ID；如：968485f47cc0b7ac7cf36d4868689866
        account: "", //	是	string	账户；如：36d4868689866
        account_password: "", //	是	string	密码；如：968485f47cc0
        max_number_accounts: "", //	是	int	最大开户数量；如：1
        daily_account_opening_limit: "", //	是	int	每日开户上限；如：1
        is_prepaid_recharge: "1", //	是	int	是否是预付；如：1
        credit_amount: "", //	是	int	客户可用授信额度；如：1
        distribution_credit_amount: "", //	是	int	分配授信额度；如：1
        contract_id: "", //	是	string	合同ID；如：968485f47cc0b7ac7cf36d4868689866
        contract_start_time: "", //	是	string	合同有效期开始时间；如：2020-04-28
        contract_end_time: "", //	是	string	合同有效期结束时间；如：2020-04-28
        sales_id: "", //	是	int	销售ID；如：1
        front_end_id: "", //	是	int	前端ID；如：1
        am_id: "", //	是	int	AMID；如：1
        optimizer_id: "", //	是	int	优化师ID；如：1
        email_address: "" //	是	string	邮箱地址；如：9023878237@qq.com
      },
      editZhPop: false,
      connectDetail: {
        clientCompanyName: "", //客户公司
        accountCompanyName: "", //开户公司名称
        projectName: "", //项目名称
        platform: "", //平台
        amName: "", //AM
        accountOpenQuantity: "", //开户数量
        salesName: "", //销售
        systemDate: "" //系统时间
      },
      selRow: {}, //选中的行
      connectAccountList: [], //关联账户列表
      connectForm: {
        openListId: "", //开户申请列表ID
        accountIds: "", //否	string	开下来的广告账户ID,多个用逗号分隔
        examineStatus: "", //是	string	5 已开户,6 平台审核被拒
        examineRemarks: "", //否	string	备注
        attachment: ""
      },
      fileList: [],
      accountFilename: "",
      dontShowAccount: false, //如果是重复关联，为了防止输入框闪过，等点击OK调完接口在显示
      isRepeat: false, //是否重复关联
      remarksOptions: [],
      connectRules: {
        examineStatus: [
          { required: true, message: "请选择最终状态", trigger: "blur" }
        ]
      },
      allIndustryList:[],
      childIndustryList:[],
      ioList:[],
      newIOpostdata:{},
      isAccountRelate:false,
      currentRowData:{},
      disabledd:false,
      inputEmailToast:false,
      errorNotify:{},
    };
  },
  directives: {
    focus: {
      componentUpdated(el, { value }) {
        if (value) {
          el.children[0].focus();
        }
      }
    },
    blur: {
      componentUpdated(el, { value }) {
        if (!value) {
          el.children[0].blur();
        }
      }
    }
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.common.getConfigData('client_level,project_principal,project_authorization_list').then((res)=>{
      this.configurationData = res.data
    }).catch(err => {
      this.$message({ message: err.msg, type: "warning" });      
    })
    this.init()
    if (this.$route.query.from == 'createIO') {
      this.showFlag = localStorage.getItem('showFlag')
      this.getInfo(JSON.parse(localStorage.getItem('khemItem')),localStorage.getItem('showFlag'),true);
      this.examineDatas.examineStatus = '2'
      this.examineDatas.ioId = this.$route.query.io_id
    }
    //点击回车进行搜索
    let that=this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  filters: {
    statusClassFilter(value) {
      //申请状态:1 待审核,2 通过,3 驳回 5已开户 6平台审核被拒
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 3:
          m = "shenpi-bohui";
          break;
        case 5:
          m = "shenpi-yikaihu";
          break;
        case 6:
          m = "shenpi-pingtaibeiju";
          break;
        default:
          break;
      }
      return m;
    },
    examineFilter(val) {
      // 审批状态:1 待审批,2 审批通过,3 驳回,5 已开户,6 平台审核被拒
      let text = "";
      switch (val) {
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "待媒体平台审核";
          break;
        case 3:
          text = "已通过";
          break;
        case 4:
          text = "初审未通过";
          break;
        case 5:
          text = "平台审核未通过";
          break;
        default:
          break;
      }
      return text;
    }
  },
  computed: {
    attachment() {
      let file = this.info.examineRecords[0].attachment;
      if (file) {
        file = file.split("_")[1];
      }
      return file;
    },

    target() {
      let t = "";
      let m = (this.info.serviceTargetId || "").split(",");
      let o = {
        1: "Brand Awarenes",
        2: "App Installs",
        3: "Drive Traffic to Website"
      };
      if (m && m.length > 0) {
        m.forEach(item => {
          if (item.length > 0) {
            if (t.length > 0) {
              t = t + "," + o[Number(item)];
            } else {
              t = t + o[Number(item)];
            }
          }
        });
      }
      return t;
    }
  },
  methods: {
    exportOpenList(){
      var postdata = {
        clientCompanyName:this.ajaxDataSearch.clientCompanyName,
        accountCompanyName:this.ajaxDataSearch.accountCompanyName,
        requisitionId:this.ajaxDataSearch.requisitionId,
        platform:this.ajaxDataSearch.platform,
        examineStatus:this.ajaxDataSearch.examineStatus,
        applicationTimeStart:this.ajaxDataSearch.applicationTimeStart,
        applicationTimeEnd:this.ajaxDataSearch.applicationTimeEnd,
        requisitionIdArrays:[]
      }
      if (this.selectedIndexList.length > 0) {
        postdata.requisitionIdArrays = this.selectedIndexList.map(item =>{
          return item.requisitionId
        })
      }
      console.log(postdata);
      window.open(`/bm/api/open/export?clientCompanyName=${postdata.clientCompanyName}&accountCompanyName=${postdata.accountCompanyName}&requisitionId=${postdata.requisitionId}&platform=${postdata.platform}&examineStatus=${postdata.examineStatus}&applicationTimeStart=${postdata.applicationTimeStart}&applicationTimeEnd=${postdata.applicationTimeEnd}&requisitionIdArrays=${postdata.requisitionIdArrays}`)
    },
    indexListChange(val){
      this.selectedIndexList = val
    },
    reExamine(){
      this.examineDatas.clientRemindEmail = this.emailAddress
      this.examine(this.info.platform)
    },
    searchTypeChange(){
      if (this.searchType == 1) {
        this.ajaxDataSearch.accountCompanyName = ""
        this.ajaxDataSearch.requisitionId = ""
      }else if(this.searchType == 2){
        this.ajaxDataSearch.clientCompanyName = ""
        this.ajaxDataSearch.requisitionId = ""
      }else{
        this.ajaxDataSearch.clientCompanyName = ""
        this.ajaxDataSearch.accountCompanyName = ""
      }
    },
    changeCompany(){
      this.getIOlist(1,this.examineDatas.clientCompanyId)
    },
    clientCompanyChange() {
      this.accountCompanyChangeList(this.rechargeclientCompanyId)
    },
    accountCompanyChangeList(clientCompanyId) {
      this.axios(
        "get",
        `${this.$javaBaseURL}accountCompany/select?clientCompanyId=${clientCompanyId}`,
        {}
      ).then(res => {
        if (res.code == 1) {
          this.accountListOptions = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    focusCom(){
      this.getRelatedComList()
    },
    remoteMethod(query){
      this.projectPostData.clientCompanyName = query
      this.getRelatedComList()
    },
    //客户公司下拉框
    getRelatedComList(page){
      this.projectPostData.pageNum = page ? page :1;
      this.axios("get", `${this.$javaBaseURL}clientCompany/selectAll`, this.projectPostData).then(
        res => {
          if (res.code == 1) {
            this.clientCompanyList = res.data.list;
            this.projectPostData.alltotal = res.data.total;
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    /*打开eip*/
    openEip(id) {
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("get", `${this.$phpBaseURL}v1/client/${id}`, {
        token: JSON.parse(localStorage.getItem("user_info")).token
      }).then(res => {
        if (res.code == 1) {
          this.eipUrl = res.data.url;
          window.open(res.data.url, "_blank");
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 跳转到新建IO页面
    createIO(){
      this.$router.push({name: 'createIO',query:{from:'open',showFlag:this.showFlag}})
      localStorage.setItem('khemItem',JSON.stringify(this.currentRowData))
      localStorage.setItem('showFlag',this.showFlag)
    },
    sizeInit(size){
      this.ajaxDataSearch.pageSize = size ? size : 15
      this.init(1)
    },
    // 获取开户列表
    init(page) {
      this.loading = true;
      this.firstPage = page;
      this.ajaxDataSearch.pageNum = page ? page : 1
      this.ajaxDataSearch.clientCompanyName = this.common.trim(this.ajaxDataSearch.clientCompanyName)
      this.ajaxDataSearch.accountCompanyName = this.common.trim(this.ajaxDataSearch.accountCompanyName)
      this.ajaxDataSearch.requisitionId = this.common.trim(this.ajaxDataSearch.requisitionId)
      this.axios("get", `${this.$javaBaseURL}open/list`, this.ajaxDataSearch).then(res => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
          this.ajaxDataSearch.pageSize = res.data.pageSize;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
        this.loading = false;
      });
    },
    // 搜索重置
    reset() {
      this.applicationTimearr = []
      this.ajaxDataSearch = {
        clientCompanyName: "", //	否	string	客户公司名称
        accountCompanyName: "", //	否	string	账户公司名称
        requisitionId: "",
        isFirst: "", //	是否首次开户:1 是,2 否
        platform: "", //	否	int	平台:1 facebook,2 twitter,3 google,5 tiktok
        examineStatus: "", //	申请状态:1 待审核,2 通过,3 驳回
        applicationTimeStart:"",
        applicationTimeEnd:"",
        pageNum:1,
        pageSize:15
      },
      this.init(1);
    },
    // 搜索
    enterSearch() {
      this.firstPage = 1;
      this.ajaxDataSearch.applicationTimeStart = this.applicationTimearr[0]
      this.ajaxDataSearch.applicationTimeEnd = this.applicationTimearr[1]
      this.init(1);
    },
    /*详情*/
    async getInfo(obj, showFlagVal,isShenp,isAccountRelate) {
      var that = this
      this.currentRowData = obj;
      this.disabledd = false
      if (obj.clientCompanyId) {
        this.examineDatas.clientCompanyId = obj.clientCompanyId
        this.projectPostData.clientCompanyName = obj.clientCompanyName
        this.disabledd = true
      }
      this.getRelatedComList()
      this.getSelectIndustry()
      if (isShenp) {
        this.axios("get", `${this.$phpBaseURL}v1/client/checkClientStatus`, {
          client_company_id: obj.clientCompanyId
        }).then(res=>{
          if (res.code == 1) {
            this.errorNotify = this.$notify.error({
              offset:100,
              duration: 0,
              title: '客户异常',
              showClose: false,
              message: res.data.reason
            });
            console.log(this.errorNotify);
          }else{
            if (this.errorNotify.id) {
              this.$notify.closeAll()
              this.errorNotify = {}
            }
          }
        })
      }
      this.axios("get", `${this.$javaBaseURL}open/details`, {
        id: obj.id
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data;
          console.log(this.info);
          if (this.info.examineStatus == "初审未通过" || this.info.examineStatus == "平台审核未通过") {
            this.errorNotify = this.$notify.error({
              offset:100,
              duration: 0,
              showClose: false,
              title: this.info.examineStatus,
              message: this.info.examineRemarks
            });
          }else{
            if (this.errorNotify.id) {
              this.$notify.closeAll()
              this.errorNotify = {}
            }
          }
          this.srcList = [];
          this.srcList.push(this.info.businessLicenseLink)
          this.info.isFirst = obj.isFirst;
          this.getIOlist()
          if (isShenp) {
            this.axios("get", `${this.$javaBaseURL}accountCompany/selectBlacklistByName`, {
              accountCompanyName: this.info.platform == 1 ? this.info.accountCompanyChineseName : this.info.accountCompanyEnglishName
            }).then(res => {
              if (res.code == 1) {
                if (res.data == 0) {
                  this.showFlag = showFlagVal;
                  this.examineDatas.id = obj.id;
                  this.isAccountRelate = isAccountRelate
                  if (isAccountRelate) {
                    this.optionsStatus = [
                      {
                        value: "3",
                        label: "平台审核通过"
                      },
                      {
                        value: "5",
                        label: "平台审核未通过"
                      }
                    ]
                  }else{
                    if (this.errorNotify.id) {
                      this.optionsStatus = [
                        {
                          value: "4",
                          label: "审核驳回"
                        }
                      ]
                    }else{
                      this.optionsStatus = [
                        {
                          value: "2",
                          label: "审核通过"
                        },
                        {
                          value: "4",
                          label: "审核驳回"
                        }
                      ]
                    }
                    
                  }
                  this.$forceUpdate();
                }else{
                  this.$message({ message: "该开户公司在黑名单中，暂时不能进行开户审批", type: "warning" });
                }
              } else if (res.code == 20001) {
                this.common.tokenExpired(res.msg);
              } else {
                this.$message({ message: res.msg, type: "warning" });
              }
            });
            
            this.examineDatas.accountCompanyEnglishName = this.info.accountCompanyEnglishName
          }else{
            this.showFlag = showFlagVal;
            this.examineDatas.id = obj.id;
          }
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
      
    },
    //审批提交
    examine(platform) {
      if (platform == 1) {
        if (this.examineDatas.examineStatus == 2 && (!this.examineDatas.advertiserIndustry || !this.examineDatas.subIndustry)) {
          this.$message({ message: "请选择行业，子行业", type: "warning" });          
          return false;
        }
        if (this.info.usedResellerLink && !this.examineDatas.planningAgencyBusinessID) {
          this.$message({ message: "请填写广告策划经销商的BM编号", type: "warning" });          
          return false;          
        }
      }
      
      if (!this.examineDatas.clientCompanyId) {
        this.$message({ message: "请选择关联客户", type: "warning" });
        return false;
      }
      if (!this.examineDatas.examineStatus) {
        this.$message({ message: "请选择审批状态", type: "warning" });
        return false;
      }
      if (this.examineDatas.examineStatus == 2 && !this.examineDatas.ioId) {
        this.$message({ message: "请选择IO", type: "warning" });
        return false;
      }

      var flag_ = true;
      if (this.isAccountRelate ) { // && this.examineDatas.examineStatus == 3
        this.info.accountInfo.forEach(ele => {
          if (!ele.accountId) {
            flag_ = false
            this.$message({ message: '请填写完整广告账户ID', type: "warning" });
            return false;
          }
        });
        if (flag_) {
          this.axios("POST", `${this.$javaBaseURL}open/saveRelation`, {
            openListId:this.examineDatas.id,
            accountInfo:this.info.accountInfo,
            examineStatus:this.examineDatas.examineStatus,
            examineRemarks:this.examineDatas.examineRemarks
          }).then(res => {
            if (res.code == 1) {
              this.showFlag = 1;
              this.inputEmailToast = false;
              this.$message({ message: '关联广告账户ID成功', type: "success" });
              this.init();
            } else if (res.code == 20001) {
              this.common.tokenExpired(res.msg);
            } else {
              this.$message({ message: res.msg, type: "warning" });
            }
          });
        }
        
      }else{
        this.axios("POST", `${this.$javaBaseURL}open/examine`, this.examineDatas).then(res => {
          if (res.code == 1) {
            // this.common.statisticsAdd("开户申请审批", "2");
            this.showFlag = 1;
            this.init();
            this.isAccountRelate = false;
            this.inputEmailToast = false;
          } else if(res.code == 300064){
            this.$message({ message: res.msg, type: "warning" });
            this.inputEmailToast = true
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }
      
    },
    // 获取项目列表
    getProjectList(page) {
      this.axios("POST", `${this.$phpBaseURL}v1/project/lists`, {
        project_name: this.projectPostData.project_name,
        page: page ? page : 1
      }).then(res => {
        if (res.code == 1) {
          this.projectList = res.data.data;
          this.projectPostData.total = res.data.total;
          this.projectPostData.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    updateValue(event) {
      this.newIOpostdata.project_id = event;
    },
    cancel(){
      if (this.$route.query.from == 'createIO') {
        location.href = '/#/open'        
      }else{
        if (this.errorNotify.id){
          this.$notify.closeAll()
        }
        this.showFlag = 1;
        this.examineDatas = {
          id: "",
          examineStatus: "",
          examineRemarks: "",
          ioId:"",
          accountCompanyEnglishName:"",
          clientCompanyId:"",
          advertiserIndustry:"",
          subIndustry:"",
          spendLimit:"0.01",
          planningAgencyBusinessID:"",
          advertiserIndustryitem:{},
        }
      }
    },
    getIOlist(page,clientCompanyId) {
      this.axios(
        "get",
        `${this.$phpBaseURL}v1/io/ioSelect`,
        {
          page:page || 1,
          client_company_id:clientCompanyId?clientCompanyId:this.currentRowData.clientCompanyId
        }
      ).then(res => {
        if (res.code == 1) {
          this.ioList = res.data.data;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    selectIndustryF(data){
      this.examineDatas.advertiserIndustry = data.industryCode
      this.childIndustryList = data.fbIndustryList
    },
    getSelectIndustry(){
      this.axios("get", `${this.$javaBaseURL}open/selectIndustry`).then(res => {
        if (res.code == 1) {
          this.allIndustryList = res.data
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    
    checkBlackList(obj) {
      //验证账号开户公司是否在黑名单中
      return new Promise(resolve => {
        var req_api = `accountCompany/selectBlacklistByName?accountCompanyName=${obj.accountCompanyName}`;
        this.axios("GET", `${this.$javaBaseURL}${req_api}`, {})
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            this.$message({
              showClose: true,
              message: err,
              type: "error"
            });
            reject(err);
          });
      });
    },
    //处理下拉框数据 审批状态
    dealSelect(selVal) {
      this.examineDatas.examineStatus = selVal;
    },
    
    beforeAvatarUploadMainImg() {},
    showConnect(row) {
      // 关联账户-http://scrmapidoc.evermotion.cn/web/#/38?page_id=2132
      //显示关联账户弹框
      this.connectVisible = true;
      this.selRow = row;
      this.connectForm.openListId = row.id;
      this.getRemarkOptions();
      this.getConnectData();
    },
    cancelContact() {
      //关闭关联账户弹框
      this.dontShowAccount = false;
      this.isRepeat = false;
      this.connectAccountList = [];
      this.connectVisible = false;
      this.connectForm.openListId = "";
      this.connectForm.accountIds = "";
      this.connectForm.examineStatus = "";
      this.connectForm.examineRemarks = "";
      this.connectForm.attachment = "";
    },
    getRemarkOptions() {
      //根据平台设置备注选项
      // 1: "facebook"
      // 2: "twitter"
      // 3: "google"
      // 5: "tiktok"
      // 6: "Facebook -新客"
      let platform = this.selRow.platform;
      switch (platform) {
        case 1:
          this.remarksOptions = [
            {
              value:
                "请务必提醒客户创建自己的BM！！！请务必当天即使用账户，避免60天不使用账户被封"
            }
          ];
          break;
        case 2:
          this.remarksOptions = [
            {
              value:
                "需授权：@ashleewang53：Account administrator&Can compose promotable Tweets. @PandaMoboTwi：Account administrator&Can compose promotable Tweets. @PandaMoboCyrus：Ad manager"
            }
          ];
          break;
        case 3:
          this.remarksOptions = [
            { value: "账户未设置预算;" },
            { value: "账户设置为预算无上限;" },
            { value: "账户预算已按要求设置;" },
            { value: "账户设置为预算;" }
          ];
          break;
        default:
          break;
      }
    },
    querySearch(queryString, cb) {
      //饿了么方法
      var restaurants = this.remarksOptions;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    getConnectData() {
      // 查询‘关联账户’页面数据
      let id = this.selRow.id;
      this.dontShowAccount = true;
      this.axios("GET", `${this.$javaBaseURL}open/queryRelation`, {
        id
      }).then(res => {
        this.connectForm.accountIds = res.data.accountIds; //关联的账户ID，如果关联过，就需要回显
        this.connectDetail.clientCompanyName = res.data.clientCompanyName; //客户公司名称
        this.connectDetail.accountCompanyName = res.data.accountCompanyName; //开户公司名称
        this.connectDetail.projectName = res.data.projectName; //项目名称
        this.connectDetail.platform = res.data.platform; //平台
        this.connectDetail.accountOpenQuantity = res.data.accountOpenQuantity; //开户数量
        this.connectDetail.salesName = res.data.salesName; //销售
        this.connectDetail.systemDate = res.data.systemDate; //系统时间
        this.connectDetail.amName = res.data.amName; //AM
        this.connectForm.examineStatus =
          res.data.examineStatus == 5 || res.data.examineStatus == 6
            ? res.data.examineStatus
            : "";
        this.connectForm.examineRemarks = res.data.examineRemarks;
        this.connectForm.attachment = res.data.attachment;
        this.accountFilename = res.data.attachment
          ? res.data.attachment.split("_")[1]
          : "";
        if (this.connectForm.accountIds) {
          this.dontShowAccount = true;
          this.isHaveConnectAccount("", "", "", "");
        } else {
          this.dontShowAccount = false;
        }
      });
    },
    isHaveConnectAccount(event, param, flag, index) {
      //param成了事件，因为点击事件默认有事件, @click="isHaveConnectAccount('')"，给加个(''),并且要两个参数
      //查询账户名称和是否已经关联
      let accountIds = this.connectForm.accountIds;
      if (param) {
        accountIds = param;
      }
      // accountIds=param?param:1,accountIds:parma:'1'----低级错误啊
      this.axios("GET", `${this.$javaBaseURL}open/queryAccountId`, {
        openListId: this.selRow.id,
        accountIds: accountIds
      }).then(res => {
        if (flag) {
          //blur产生的事件
          if (res.data.length == 0) {
            this.$set(this.connectAccountList[index], "accountName", "");
            // this.connectAccountList[index].accountName = ''
          } else {
            this.$set(
              this.connectAccountList[index],
              "accountName",
              res.data[0].accountName
            );
            // this.connectAccountList[index].accountName = res.data.accounName
          }
        } else {
          this.connectAccountList = res.data;
          this.dontShowAccount = false;

          //重复关联不可提交
          this.isRepeat = this.checkIsRepeat(this.connectAccountList);
        }

        if (this.connectAccountList.length == 0) {
          this.$message({
            message: "没有关联账户",
            type: "warning"
          });
        }
      });
    },
    checkIsRepeat(datas) {
      //检测是否重复关联
      var return_flag = false;
      for (var pp in this.connectAccountList) {
        if (this.connectAccountList[pp]["relation"]) {
          return_flag = true;
          break;
        }
      }
      return return_flag;
    },
    beforeAvatarUploadFile(file) {
      if (file.size > 1024 * 1024 * 100) {
        this.$message({
          message: "上传文件不能大于100M",
          type: "warning"
        });
        return;
      }
      let param = new FormData();
      param.append("file", file, file.name);
      //此处.,param不能带{},kengdie
      this.axios(
        "POST",
        `${this.$javaBaseURL}open/uploadAttachment`,
        param
      ).then(res => {
        this.accountFilename = file.name;
        this.connectForm.attachment = JSON.parse(res).data;
      });
    },
    //关联账户提交
    confirmConnect() {
      //不可重复绑定账户
      if (this.isRepeat) {
        this.$message({
          message: "该账户已经重复关联，请删除后，重新提交",
          type: "warning"
        });
        return false;
      }
      this.$refs["connectValidateForm"].validate(valid => {
        if (valid) {
          let accountIds = this.connectAccountList.map(item => item.accountId);
          this.connectForm.accountIds = accountIds.join(",");
          let param = `openListId=${this.connectForm.openListId}&accountIds=${this.connectForm.accountIds}&examineStatus=${this.connectForm.examineStatus}&examineRemarks=${this.connectForm.examineRemarks}&attachment=${this.connectForm.attachment}`;
          this.axios(
            "POST",
            `${this.$javaBaseURL}open/saveRelation?${param}`,
            ""
          ).then(res => {
            if (res.code == 1) {
              this.common.statisticsAdd("关联账户", "2");
              this.$message({
                showClose: true,
                message: "操作成功",
                type: "success"
              });
              this.connectVisible = false;
              this.connectForm.openListId = "";
              this.connectForm.accountIds = "";
              this.connectForm.examineStatus = "";
              this.connectForm.examineRemarks = "";
              this.connectForm.attachment = "";
              this.init();
            } else if (res.code == 20001) {
              //token过期处理
              this.common.tokenExpired(res.msg);
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error"
              });
            }
            //    this.connectAccountList = res.data
          });
        }
      });
    },
    canEdit(item, index) {
      //编辑广告账户
      this.$set(item, "canEdit", true);
    },
    delFile() {
      (this.accountFilename = ""), //此为文件原名
        (this.connectForm.attachment = ""); //此为上传后 后台返回的路径
      this.fileList = []; //此为组件的文件列表
    },
    onblur(item, index) {
      //失去焦点时
      item.canEdit = false;
      let newarr = this.connectAccountList.map(item => item.accountId);
      let param = newarr.join(",");
      this.isHaveConnectAccount("", param, true, index);
    },
    toDel(item, index) {
      this.connectAccountList.splice(index, 1);

      //重复关联不可提交
      this.isRepeat = this.checkIsRepeat(this.connectAccountList);
    },
    downloadAttachment() {
      let that = this;
      let url = `${this.doMainUrl}bm/api/relationAttachment/${this.info.examineRecords[0].attachment}`;
      // window.open(url, '_blank')
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function(e) {
        var url = window.URL.createObjectURL(xhr.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = that.attachment;
        a.click();
      };
      xhr.send();
    },
    //频次统计
    addStatistics() {
      this.value++;
      this.common.statisticsAdd("媒体账户开户审批列表", "1");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.link_url{
  color: #409eff;
  text-decoration: none;
}
.main-inputBox {
  width: 175px;
}
.common-dialog {
  .detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ele {
      width: 50%;
      flex: 0 0 50%;
      margin-bottom: 16px;
      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788090;
        padding-right: 8px;
      }
      &span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #192744;
      }
    }
  }
}

.updateEmail {
  h2 {
    margin-bottom: 16px;
  }
  .wrap {
    border: #dfe2e7 solid 1px;
    border-radius: 5px;
    width: 592.3px;
    padding: 24px;
    box-sizing: border-box;
  }
}

.filename {
  display: flex;
  justify-content: space-between;
  .del {
    width: 50px;
    color: rgba(1, 172, 228, 1);
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
  }
}
</style>
<style>
.flexd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.flexd label {
  width: 90px;
  font-size: 14px;
}
.flexd label.longlabel {
  width: 150px;
}
.el-form-self .ads .el-form-item__content {
  display: flex;
  align-items: center;
}

.el-form-self .ads .el-form-item__content .ok {
  width: 50px;
  color: rgba(1, 172, 228, 1);
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
}

.el-form-self .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.el-select-dropdown ul {
  max-width: 100% !important;
}

.accountspan {
  white-space: nowrap;
  margin-left: 8px;
  width: 245px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.accountEditSpan {
  white-space: nowrap;
  margin-left: 8px;
  color: #01ace4;
  cursor: pointer;
}

.input-edit-wrap {
  width: 260px;
}

.input-edit-wrap .el-input__inner {
  width: 260px;
}

.cantEdit {
  font-size: 14px;
  color: #606266;
  display: inline-block;
  padding: 0 15px;
  width: 245px;
  box-sizing: border-box;
}
</style>
