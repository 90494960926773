<template>
  <div class="main-div customerList" style="background: none; padding: 0">
    <div v-show="showFlag == 1" style="background: #fff; padding: 24px">
      <p class="main-title">客户列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.client_company_name"
            clearable
            placeholder="请输入客户名称"
          >
          </el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.status"
            clearable
            placeholder="客户状态"
          >
            <el-option
              v-for="(v, k) in configurationData.client_status_array"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.level_id"
            clearable
            placeholder="全部评级"
          >
            <el-option
              v-for="item in configurationData.client_level_config"
              :key="item.level_id"
              :label="item.level_name"
              :value="item.level_id"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.platform_account"
            clearable
            placeholder="平台账号状态"
          >
            <el-option
              v-for="(v, k) in configurationData.platform_account_array"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.group_id"
            filterable
            clearable
            remote
            :remote-method="groupIdRemote"
            placeholder="全部客户组"
            @focus="focusIngroup()"
          >
            <el-option
              v-for="item in groupSelectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
            <div style="float: right; margin-right: 10px; padding-bottom: 10px">
              <el-pagination
                @current-change="getGroupSelect"
                :current-page="getGroupPostData.pageNum"
                :page-size="getGroupPostData.pageSize"
                layout="prev, pager, next"
                :total="getGroupPostData.total"
              ></el-pagination>
            </div>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.source"
            filterable
            placeholder="全部来源渠道"
          >
            <el-option
              v-for="(v, k) in configurationData.client_company_source"
              :key="k"
              :label="v"
              :value="k"
            >
            </el-option>
          </el-select>
        </div>
        <div class="main-selectBox" style="width: 300px">
          <el-select
            v-model="ajaxDataSearch.sale_id_arr"
            multiple
            filterable
            placeholder="全部销售"
          >
            <el-option
              v-for="(v, k) in allSales"
              :key="k"
              :label="v.user_name"
              :value="v.relate_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="main-selectBox" style="width: 300px">
          <el-select
            v-model="ajaxDataSearch.am_id_arr"
            multiple
            filterable
            placeholder="全部AM"
          >
            <el-option
              v-for="(v, k) in allAMs"
              :key="k"
              :label="v.user_name"
              :value="v.relate_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">查询</div>
          <div class="main-button main-button-first" @click="resetList()">
            重置
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div class="main-btnBox m-r-16">
          <div
            class="main-button"
            v-show="common.permFilter(`${common.php}v1/client/xjkh`)"
            @click="openEip('xjkh')"
          >
            新建客户
          </div>
          <div
            class="main-button main-button-first"
            v-show="common.permFilter(`${common.php}v1/client/khjj`)"
            @click="openEip('khjj')"
          >
            客户交接
          </div>
          <div
            class="main-button main-button-first"
            v-show="common.permFilter(`${common.php}v1/client/khgx`)"
            @click="openEip('khgx')"
          >
            客户共享
          </div>
          <div
            class="main-button main-button-first"
            v-show="
              common.permFilter(
                `${common.php}v1/client/getCustomerSharingApplication`
              )
            "
            @click="openEip('khsp')"
          >
            客户审核
          </div>
          <div
            class="main-button main-button-first"
            v-show="
              common.permFilter(`${common.php}v1/client/getShareConfirmation`)
            "
            @click="openEip('getShareConfirmation')"
          >
            共享确认
          </div>
          <div class="main-button main-button-first" @click="exportexl()">
            导出数据
          </div>
        </div>
        <el-button type="text" @click="editTableColumns()"
          >自定义列表项</el-button
        >
      </div>
      <div>
        <el-table
          v-loading="loading"
          :data="customerList"
          border
          height="550"
          style="width: 100%"
        >
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png" />
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column
            fixed
            prop="client_company_name"
            label="客户名称"
            show-overflow-tooltip
            min-width="230"
          ></el-table-column>
          <el-table-column
            prop="level_name"
            label="客户级别"
            width="100"
            v-if="tableColumnVals.level_name"
          ></el-table-column>
          <el-table-column v-if="tableColumnVals.available_balance" label="可用余额（美元）" width="150">
            <template slot-scope="scope">
              <span>{{
                scope.row.group_name
                  ? common.toThousands(scope.row.group_available_balance, 2) +
                    "（组）"
                  : common.toThousands(scope.row.available_balance, 2)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="tableColumnVals.credit_line_value" label="授信额度（美元）" width="150">
            <template slot-scope="scope">
              <span>{{
                scope.row.group_name
                  ? scope.row.group_credit_line
                    ? common.toThousands(scope.row.group_credit_line, 2) +
                      "（组）"
                    : "0.00"
                  : scope.row.credit_line_value
                  ? common.toThousands(scope.row.credit_line_value, 2)
                  : "0.00"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="tableColumnVals.owed_amount" label="欠款金额（美元）" width="150">
            <template slot-scope="scope">
              <span>{{
                scope.row.group_name
                  ? common.toThousands(scope.row.group_owed_amount, 2) +
                    "（组）"
                  : common.toThousands(scope.row.owed_amount, 2)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="tableColumnVals.status" label="客户状态" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :class="scope.row.status | statusClassFilter"
                :title="scope.row.status"
                >{{ scope.row.status | checkStatus }}</span
              >
            </template>
          </el-table-column>
          <el-table-column v-if="tableColumnVals.platform_account" label="平台账号" width="120">
            <template slot-scope="scope">
              {{ scope.row.platform_account == 1 ? "未开通" : "已开通" }}
            </template>
          </el-table-column>
          <el-table-column v-if="tableColumnVals.group_name" label="客户组" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.group_name ? scope.row.group_name : "未分组" }}
            </template>
          </el-table-column>
          <el-table-column
            label="客户来源"
            width="120"
            show-overflow-tooltip
            v-if="tableColumnVals.source"
          >
            <template slot-scope="scope">
              {{ configurationData.client_company_source[scope.row.source] }}
            </template>
          </el-table-column>
          <el-table-column
            label="销售负责人"
            width="120"
            show-overflow-tooltip
            v-if="tableColumnVals.sale_name"
          >
            <template slot-scope="scope">
              {{ showName(scope.row.sale_name) }}
            </template>
          </el-table-column>
          <el-table-column
            label="AM负责人"
            width="120"
            show-overflow-tooltip
            v-if="tableColumnVals.am_name"
          >
            <template slot-scope="scope">
              {{ showName(scope.row.am_name) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="240">
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                title="详情"
                @click="
                  showFlag = 2;
                  getInfo(scope.row);
                "
                >详情</span
              >
              <span
                class="main-tableToInfo"
                title="开户申请"
                @click="goOpenApp(scope.row)"
                >开户申请</span
              >
              <el-popover
                placement="bottom"
                width="100"
                trigger="hover"
                v-if="
                  scope.row.status == 6 ||
                  scope.row.status == 2 ||
                  (scope.row.platform_account == 1 && scope.row.status == 2) ||
                  scope.row.platform_account == 2 ||
                  (scope.row.status == 2 && scope.row.platform_account == 2) ||
                  (scope.row.platform_account == 2 &&
                    scope.row.account_status == 2) ||
                  (scope.row.platform_account == 2 &&
                    scope.row.account_status == 1)
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                  "
                >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.status == 2"
                    @click="relateFinacalID(scope.row)"
                    >关联财务客户</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.platform_account == 1 && scope.row.status == 2
                    "
                    @click="platformOpe(scope.row)"
                    >开通平台账号</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.platform_account == 2"
                    @click="seeShare(scope.row)"
                    >快捷登录</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.status == 2 && scope.row.platform_account == 2
                    "
                    @click="getAuth(scope.row)"
                    >授权登录</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.platform_account == 2 &&
                      scope.row.account_status == 2
                    "
                    @click="startAndStop(scope.row)"
                    >启用账号</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="
                      scope.row.platform_account == 2 &&
                      scope.row.account_status == 1
                    "
                    @click="startAndStop(scope.row)"
                    >关闭账号</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.status == 2"
                    @click="createRebate(scope.row, false)"
                    >添加返点</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.status == 2"
                    @click="changeAMS(scope.row)"
                    >AM负责人</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.status == 2"
                    @click="changeSalers(scope.row)"
                    >销售负责人</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.source == 2 && scope.row.status == 1"
                    @click="examineCustomer(scope.row)"
                    >审核客户</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.status == 2"
                    @click="setCustomerErrorS(scope.row, true)"
                    >设置异常客户状态</span
                  >
                  <span
                    class="tabel-operate"
                    v-if="scope.row.status == 6"
                    @click="setCustomerErrorS(scope.row, false)"
                    >解除客户异常状态</span
                  >
                </div>
                <span class="tabel-operate wk-span" slot="reference"
                  >更多操作</span
                >
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentChange="init"
          @currentSizeChange="sizeInit"
          :pageSize="ajaxDataSearch.page_num"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <!-- 客户信息 -->
    <div v-show="showFlag == 2">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1"
          >返回</el-button
        >
        <span>客户详情</span>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">客户信息</p>
        <div class="infoBox">
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户名称:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.client_company_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户组:</p>
              <div class="info-text calc-4w">
                {{
                  info.client_company_group && info.client_company_group.name
                    ? info.client_company_group.name
                    : "未分组"
                }}
                <span @click="changeGroup()" class="bluefont">修改分组</span>
              </div>
              <!--  -->
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-6w">关联财务客户:</p>
              <div class="info-text calc-6w">
                {{ info.basic_info.finance_name }}
                <span
                  v-if="info.basic_info.finance_id"
                  @click="changeFinacalID"
                  class="bluefont"
                  >修改财务客户</span
                >
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户状态:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.client_status | checkStatus }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户行业:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.industry_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">主营业务:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.main_business }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">所在地区:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.company_address }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">详细地址:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.detailed_address }}
              </div>
            </div>
            <div class="info-cell w-33" style="position: relative">
              <div style="position: absolute">
                <el-image
                  style="width: 192px; height: 108px; border-radius: 4px"
                  :src="info.basic_info.business_license_link"
                  :preview-src-list="prevsrcList"
                >
                </el-image>
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户评级:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.level_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">定级原因:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.reason_rating }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell">
              <p class="info-title t-10w">统一社会信用代码:</p>
              <div class="info-text calc-10w">
                {{ info.basic_info.business_license_number }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户来源:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.client_source_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">备注:</p>
              <div class="info-text calc-4w">{{ info.basic_info.remark }}</div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-5w">销售负责人:</p>
              <div class="info-text calc-5w">
                {{
                  info.basic_info.sale_name &&
                  info.basic_info.sale_name.length > 0
                    ? getNames(info.basic_info.sale_name)
                    : "-"
                }}
                <span
                  v-if="info.basic_info.client_status == 2"
                  class="bluefont"
                  style="margin-left: 20px"
                  @click="changeSalers()"
                  >修改销售负责人</span
                >
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">AM:</p>
              <div class="info-text calc-4w">
                {{
                  info.basic_info.am_name && info.basic_info.am_name.length > 0
                    ? getNames(info.basic_info.am_name)
                    : "-"
                }}
                <span
                  v-if="info.basic_info.client_status == 2"
                  class="bluefont"
                  style="margin-left: 20px"
                  @click="changeAMS()"
                  >修改AM负责人</span
                >
              </div>
            </div>
          </div>
          <div class="info-row" v-if="info.basic_info.exception_reason">
            <div class="info-cell w-33">
              <p class="info-title t-7w" style="color: #f00">审核原因:</p>
              <div class="info-text calc-7w" style="color: #f00">
                {{ info.basic_info.exception_reason }}
              </div>
            </div>
          </div>
          <div class="info-row" v-if="info.basic_info.exception_reason">
            <div class="info-cell w-33">
              <p class="info-title t-7w" style="color: #f00">设置异常原因:</p>
              <div class="info-text calc-7w" style="color: #f00">
                {{ info.basic_info.exception_reason }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p
          class="main-title withLinkBtn"
          v-if="info.client_company_group && info.client_company_group.name"
        >
          财务信息
          <i style="color: rgba(120, 128, 144, 1); font-size: 14px"
            >已使用客户组的账务规则</i
          ><el-button type="text" @click="viewEditGroup()"
            >查看客户组</el-button
          >
        </p>
        <p class="main-title withLinkBtn" v-else>财务信息</p>
        <div class="info-row flex-box flex-space-between">
          <div class="info-cell">
            <p class="info-title t-7w">授信额度(美元):</p>
            <div class="info-text calc-7w">
              {{
                info.client_company_group && info.client_company_group.name
                  ? common.toThousands(info.client_company_group.credit_line, 2)
                  : common.toThousands(info.basic_info.credit_line_value, 2)
              }}
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">可用余额(美元):</p>
            <div class="info-text calc-7w">
              {{
                info.client_company_group && info.client_company_group.name
                  ? common.toThousands(
                      info.client_company_group.available_balance,
                      2
                    )
                  : common.toThousands(info.basic_info.available_balance, 2)
              }}
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">欠款金额(美元):</p>
            <div class="info-text calc-7w">
              {{
                info.client_company_group && info.client_company_group.name
                  ? common.toThousands(info.client_company_group.owed_amount, 2)
                  : common.toThousands(info.basic_info.owed_amount, 2)
              }}
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">累计消耗(美元):</p>
            <div class="info-text calc-7w">
              {{
                info.client_company_group && info.client_company_group.name
                  ? common.toThousands(info.client_company_group.cost_sum, 2)
                  : common.toThousands(info.basic_info.total_expend, 2)
              }}
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">累计回款(美元):</p>
            <div class="info-text calc-7w">
              {{
                info.client_company_group && info.client_company_group.name
                  ? common.toThousands(info.client_company_group.receipt_sum, 2)
                  : common.toThousands(info.basic_info.total_payback, 2)
              }}
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">关联广告信息</p>
        <div class="info-row flex-box flex-space-between">
          <div class="info-cell">
            <p class="info-title t-7w">关联IO数:</p>
            <div class="info-text calc-7w">
              <span @click="clickTo(1)" class="bluefont">{{
                info.client_ads_list.io_num
              }}</span>
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">广告账户数:</p>
            <div class="info-text calc-7w">
              <span @click="clickTo(2)" class="bluefont">{{
                info.client_ads_list.ads_num
              }}</span>
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">开户公司数:</p>
            <div class="info-text calc-7w">
              {{ info.client_ads_list.open_comp_num }}
            </div>
          </div>
          <div class="info-cell">
            <p class="info-title t-7w">合同数:</p>
            <div class="info-text calc-7w">
              <span @click="clickTo(3)" class="bluefont">{{
                info.client_ads_list.contract_num
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">
          <span>Panda账户管理平台信息</span>
          <span
            v-if="info.basic_info.client_status != 2"
            style="margin-left: 20px; font-size: 12px; font-weight: normal"
            >审核通过后可用</span
          >
          <span v-else>
            <el-button
              type="text"
              v-if="info.basic_info.platform_account == 1"
              @click="platformOpe(info.basic_info)"
              >开通账号</el-button
            >
            <el-button
              type="text"
              @click="platformOpe(info.basic_info, 'edit')"
              v-else
              >修改账号信息</el-button
            >
            <el-button
              type="text"
              v-if="
                info.basic_info.platform_account == 2 &&
                info.basic_info.account_status == 1
              "
              >关闭账号</el-button
            >
          </span>
        </p>
        <div class="info-row flex-box">
          <div class="info-cell w-50">
            <p class="info-title t-7w">账户名称:</p>
            <div class="info-text calc-7w">
              {{ info.platform_users ? info.platform_users.account : "-" }}
            </div>
          </div>
          <div class="info-cell w-50">
            <p class="info-title t-7w">接收通知邮箱:</p>
            <div class="info-text calc-7w">
              {{
                info.platform_users ? info.platform_users.email_address : "-"
              }}
            </div>
          </div>
        </div>
        <div class="info-row flex-box">
          <div class="info-cell w-50">
            <p class="info-title t-11w">每开户公司最大开户数:</p>
            <div class="info-text calc-11w">
              {{
                info.platform_users
                  ? info.platform_users.daily_account_opening_limit
                  : "-"
              }}
            </div>
          </div>
          <!-- <div class="info-cell w-50">
            <p class="info-title t-9w">每日开户上限:</p>
            <div class="info-text calc-9w">{{info.platform_users ? info.platform_users.daily_account_opening_limit : '-'}}</div>
          </div> -->
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <el-tabs>
          <el-tab-pane label="联系人">
            <el-table :data="contactUserData" border style="width: 100%">
              <el-table-column
                prop="contact_name"
                label="姓名"
              ></el-table-column>
              <el-table-column prop="phone" label="手机号"></el-table-column>
              <el-table-column
                prop="dep_name"
                label="所属部门"
              ></el-table-column>
              <el-table-column prop="job" label="职务"></el-table-column>
              <el-table-column prop="email" label="邮箱"></el-table-column>
              <el-table-column prop label="操作" min-width="100">
                <template slot-scope="scope">
                  <span
                    class="main-tableToInfo"
                    @click="contactDetail(scope.row)"
                    >详情</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <pagination-vue
              @currentChange="getContactList"
              @currentSizeChange="getContactListsizeInit"
              :pageSize="customerDetailPostD.pageSize"
              :total="customerDetailPostD.total"
            />
          </el-tab-pane>
          <el-tab-pane label="关联客户">
            <el-table :data="relatedCustomerData" border style="width: 100%">
              <el-table-column
                type="index"
                label="序号"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="company_name"
                label="关联公司名称"
              ></el-table-column>
              <el-table-column
                prop="related_relationship_name"
                label="关联关系"
              ></el-table-column>
            </el-table>
            <pagination-vue
              @currentChange="getrelatedCustomerList"
              @currentSizeChange="getrelatedCustomerListsizeInit"
              :pageSize="relatedCustomerDetailPostD.pageSize"
              :total="relatedCustomerDetailPostD.total"
            />
          </el-tab-pane>
          <el-tab-pane label="担保信息">
            <el-table :data="guaranteeinforList" border style="width: 100%">
              <el-table-column
                prop="guarantee_company_name"
                label="担保公司名称"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="guarantee_amount"
                label="担保金额"
              ></el-table-column>
              <el-table-column
                prop="actual_controller_name"
                label="实际控制人姓名"
              ></el-table-column>
              <el-table-column
                prop="actual_controller_guarantee_amount"
                label="实际控制人担保金额"
              ></el-table-column>
              <el-table-column label="是否有多甲方合同">
                <template slot-scope="scope">
                  {{ scope.row.is_multiple_contract == 1 ? "有" : "无" }}
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="连带客户公司名称"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
            <pagination-vue
              @currentChange="getguaranteeinforList"
              @currentSizeChange="getguaranteeinforListSizeInit"
              :pageSize="guaranteeinforDetailPostD.pageSize"
              :total="guaranteeinforDetailPostD.total"
            />
          </el-tab-pane>
          <el-tab-pane label="返点规则">
            <div
              class="flex-box flex-space-between"
              style="margin-bottom: 20px"
            >
              <div>
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="info.basic_info.platform_account == 1"
                  @click="createRebate(null, true)"
                  >添加返点规则</el-button
                >
                <span
                  v-if="info.basic_info.platform_account == 1"
                  style="font-size: 12px"
                  >开通panda管理平台账号后可用</span
                >
              </div>
              <div>
                <el-select
                  @change="getRebateLists(1)"
                  :disabled="info.basic_info.platform_account == 1"
                  size="mini"
                  v-model="rebateListsDetailPostD.network"
                  placeholder="全部媒体平台"
                >
                  <el-option
                    v-for="(
                      item, key
                    ) in configurationData.client_rebate_network_array"
                    :key="key"
                    :label="item"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-table :data="rebateLists" border style="width: 100%">
              <el-table-column label="平台" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(i, k) in scope.row.network_show" :key="k">{{
                    i
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="starttime"
                label="开始时间"
              ></el-table-column>
              <el-table-column
                prop="endtime"
                label="结束时间"
              ></el-table-column>
              <el-table-column label="返点比例" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.ratio_type == 1"
                    >固定返点：{{ scope.row.ratio }}%</span
                  >
                  <span v-if="scope.row.ratio_type == 2"
                    >跟随平台返点：{{ scope.row.ratio }}%</span
                  >
                  <span v-if="scope.row.ratio_type == 3"
                    >阶梯返点：<span
                      v-for="(inx, key) in scope.row.section_ratio"
                      :key="key"
                      >{{
                        (inx.start ? inx.start : "1") +
                        "-" +
                        (inx.end ? inx.end : "至最大") +
                        ":" +
                        inx.ratio +
                        "% "
                      }}</span
                    ></span
                  >
                </template>
              </el-table-column>
              <el-table-column prop label="操作" min-width="100">
                <template slot-scope="scope">
                  <span
                    v-show="
                      common.permFilter(`${common.php}v1/client/editRebate`)
                    "
                    class="main-tableToInfo"
                    @click="editRebate(scope.row)"
                    >编辑</span
                  >
                  <span
                    v-show="
                      common.permFilter(`${common.php}v1/client/delRebate`)
                    "
                    class="main-tableToInfo"
                    @click="deleteRebate(scope.row)"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <pagination-vue
              @currentChange="getRebateLists"
              @currentSizeChange="getRebateListsSizeInit"
              :pageSize="rebateListsDetailPostD.page_num"
              :total="rebateListsDetailPostD.total"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title withLinkBtn">关键时间信息</p>
        <el-table :data="debuffRecords" border style="width: 100%">
          <el-table-column prop="operate_at" label="时间"></el-table-column>
          <el-table-column prop="operation" label="操作"></el-table-column>
          <el-table-column prop="reason" label="说明"></el-table-column>
          <el-table-column label="操作人">
            <template slot-scope="scope">
              {{ scope.row.user.name }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--关联财务客户-->
    <div class="main-pop" v-show="relatedCom">
      <div class="mask"></div>
      <div class="center" style="width: 700px">
        <div class="head">
          <span>关联财务客户</span>
          <i class="gongzuotai gztguanbi" @click="relatedCom = false"></i>
        </div>
        <div class="body">
          <el-form size="mini" label-width="120px" label-position="left">
            <el-form-item label="选择财务客户：">
              <el-select
                v-model="saveRelatedCom.finance_id"
                filterable
                clearable
                remote
                :remote-method="remoteMethod"
                placeholder="请选择财务客户 || 组织机构"
                @change="updateValue"
              >
                <el-option
                  v-for="item in relatedComList"
                  :key="item.recid"
                  :label="item.st_name + ' || ' + item.finance_company"
                  :value="item.recid"
                ></el-option>
                <div
                  style="float: right; margin-right: 10px; padding-bottom: 10px"
                >
                  <el-pagination
                    @current-change="customerGroupInit"
                    :current-page="projectPostData.pageNum"
                    :page-size="projectPostData.pageSize"
                    layout="total, prev, pager, next"
                    :total="projectPostData.total"
                  ></el-pagination>
                </div>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot">
          <button
            class="main-button l"
            @click="
              relatedCom = false;
              saveRelatedCom.finance_id = '';
            "
          >
            取消
          </button>
          <button class="main-button r" @click="saveRelatedComMet()">
            确定
          </button>
        </div>
      </div>
    </div>
    <!--开通平台账户-->
    <div class="main-pop" v-show="showPlatform">
      <div class="mask"></div>
      <div class="center" style="width: 580px">
        <div class="head">
          <span>{{ platformText }}</span>
          <i class="gongzuotai gztguanbi" @click="showPlatform = false"></i>
        </div>
        <div class="body">
          <el-form size="mini" label-width="180px" label-position="left">
            <el-form-item label="账户名" required>
              <el-input
                :disabled="platformText == '编辑平台账户'"
                v-model="ktplatform.account"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" required>
              <el-input
                v-model="ktplatform.account_password"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="每开户公司最大开户数" required>
              <el-input
                type="number"
                min="0"
                v-model="ktplatform.daily_account_opening_limit"
              ></el-input>
            </el-form-item>
            <el-form-item label="收信邮箱" required>
              <el-input v-model="ktplatform.email_address"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot">
          <button class="main-button l" @click="showPlatform = false">
            取消
          </button>
          <button class="main-button r" @click="openPlatformMet()">确定</button>
        </div>
      </div>
    </div>
    <!-- 登录授权 -->
    <div class="main-pop openZhPop" v-show="authPop">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>登录授权</span>
          <i class="gongzuotai gztguanbi" @click="authPop = false"></i>
        </div>
        <div class="body" style="padding-right: 0px; padding-bottom: 80px">
          <p class="info-title t-4w" style="line-height: 32px">已授权:</p>
          <div class="main-selectBox" style="width: 394px">
            <span
              class="authperson"
              v-for="(item, key) in authPerson"
              :key="key"
            >
              {{ item.name }}
              <i class="gongzuotai gztchahao" @click="delAuthPerson(item)"></i>
            </span>
          </div>
          <p class="info-title t-4w" style="line-height: 32px">被授权人:</p>
          <div class="main-selectBox" style="width: 394px">
            <el-select
              v-model="user_id"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="(
                  item, index
                ) in configurationData.project_authorization_list"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="authPop = false">取消</button>
          <button class="main-button r" @click="confirmAuth()">确定</button>
        </div>
      </div>
    </div>
    <!-- 修改销售负责人 -->
    <div class="main-pop openZhPop" v-show="editSalers">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>销售负责人</span>
          <i class="gongzuotai gztguanbi" @click="editSalers = false"></i>
        </div>
        <div class="body" style="padding-right: 0px; padding-bottom: 80px">
          <p class="info-title t-6w" style="line-height: 32px">已选负责人：</p>
          <div class="main-selectBox" style="width: 340px; height: auto">
            <span
              class="authperson"
              v-for="(item, key) in selectedSalers"
              :key="key"
            >
              {{ item.name }}
              <i
                class="gongzuotai gztchahao"
                @click="delSelectedSalers(item, key)"
              ></i>
            </span>
          </div>
          <p class="info-title t-6w" style="line-height: 32px">添加负责人：</p>
          <div class="main-selectBox" style="width: 340px">
            <el-select
              value-key="id"
              size="small"
              v-model="addSalers"
              clearable
              filterable
              placeholder="请选择"
              @change="changeSelectSalers()"
            >
              <el-option
                v-for="(
                  item, index
                ) in configurationData.project_authorization_list"
                :key="index"
                :label="item.name"
                :value="item"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="editSalers = false">
            取消
          </button>
          <button class="main-button r" @click="confirmSalers()">确定</button>
        </div>
      </div>
    </div>
    <!-- 修改AM -->
    <div class="main-pop openZhPop" v-show="editAMs">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>AM负责人</span>
          <i class="gongzuotai gztguanbi" @click="editAMs = false"></i>
        </div>
        <div class="body" style="padding-right: 0px; padding-bottom: 80px">
          <p class="info-title t-6w" style="line-height: 32px">已选负责人：</p>
          <div class="main-selectBox" style="width: 340px">
            <span
              class="authperson"
              v-for="(item, key) in selectedAMs"
              :key="key"
            >
              {{ item.name }}
              <i
                class="gongzuotai gztchahao"
                @click="delSelectedAMs(item, key)"
              ></i>
            </span>
          </div>
          <p class="info-title t-6w" style="line-height: 32px">添加负责人：</p>
          <div class="main-selectBox" style="width: 340px">
            <el-select
              size="small"
              v-model="addAMs"
              value-key="id"
              clearable
              filterable
              placeholder="请选择"
              @change="changeSelectAMs()"
            >
              <el-option
                v-for="(
                  item, index
                ) in configurationData.project_authorization_list"
                :key="index"
                :label="item.name"
                :value="item"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="editAMs = false">取消</button>
          <button class="main-button r" @click="confirmAMs()">确定</button>
        </div>
      </div>
    </div>
    <!-- 返点规则 -->
    <div class="main-pop openZhPop" v-show="rebateToast">
      <div class="mask"></div>
      <div class="center" style="width: 876px">
        <div class="head">
          <span>返点规则</span>
          <i class="gongzuotai gztguanbi" @click="rebateToast = false"></i>
        </div>
        <div class="body" style="padding-bottom:40px;overflow:scroll;x">
          <el-form
            size="mini"
            :model="newRebatePostData"
            label-width="80px"
            style="width: 50%"
          >
            <el-form-item label="媒体平台">
              <el-select
                v-model="newRebatePostData.network"
                placeholder="请选择媒体"
              >
                <el-option
                  v-for="(item, key) in configurationData.network_array"
                  :key="key"
                  :label="item"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有效期">
              <el-date-picker
                v-model="newRebatePostData.times"
                @change="formatTimes"
                @blur="formatTimes"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="daterange"
                clearable
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="返点方式">
              <el-radio-group v-model="newRebatePostData.ratio_type">
                <el-radio :label="1">固定返点</el-radio>
                <el-radio :label="3">阶梯返点</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="返点比例"
              v-if="newRebatePostData.ratio_type == 1"
            >
              <el-input v-model="newRebatePostData.ratio"
                ><template slot="append">%</template></el-input
              >
            </el-form-item>
          </el-form>
          <el-table
            v-if="newRebatePostData.ratio_type == 3"
            :data="newRebatePostData.section_ratio"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column
              label="阶段"
              type="index"
              width="100"
            ></el-table-column>
            <el-table-column label="最低消耗（美元）" width="200">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.start"
                  placeholder="请输入金额"
                  @blur="costBlur(scope.row, scope.$index)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="最高消耗（美元）" width="200">
              <template slot-scope="scope">
                <p
                  v-if="
                    scope.$index == newRebatePostData.section_ratio.length - 1
                  "
                >
                  至最大
                </p>
                <p
                  v-if="
                    scope.$index + 1 !=
                      newRebatePostData.section_ratio.length &&
                    newRebatePostData.section_ratio[scope.$index + 1].start
                  "
                >
                  {{
                    newRebatePostData.section_ratio[scope.$index + 1].start - 1
                  }}
                </p>
                <p
                  v-if="
                    scope.$index + 1 !=
                      newRebatePostData.section_ratio.length &&
                    newRebatePostData.section_ratio.length > 1 &&
                    !newRebatePostData.section_ratio[scope.$index + 1].start
                  "
                >
                  请输入阶段{{ scope.$index + 2 }}的最低消耗
                </p>
              </template>
            </el-table-column>
            <el-table-column label="返点比例（%）" width="200">
              <template slot-scope="scope">
                <el-input size="mini" v-model="scope.row.ratio"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="removeRebate(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-button
            @click="addSectionRatio()"
            style="margin-top: 20px"
            v-if="newRebatePostData.ratio_type == 3"
            size="mini"
            icon="el-icon-plus"
            >添加</el-button
          >
        </div>
        <div class="foot">
          <button class="main-button l" @click="rebateToast = false">
            取消
          </button>
          <button class="main-button r" @click="submitNewRebate()">确定</button>
        </div>
      </div>
    </div>
    <!--关联公司弹窗-->
    <div class="main-pop" v-show="compPop">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>关联公司</span>
          <i class="gongzuotai gztguanbi" @click="compPop = false"></i>
        </div>
        <div class="body">
          <el-table :data="compList" style="width: 100%">
            <template slot="empty">
              <div class="nodata">
                <img src="@/assets/img/nodata.png" />
                <span>暂无数据</span>
              </div>
            </template>
            <el-table-column
              prop
              label="序号"
              type="index"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="company_name"
              label="公司名称"
              width
            ></el-table-column>
          </el-table>
        </div>
        <div class="foot">
          <button class="main-button l" @click="compPop = false">取消</button>
          <button class="main-button r" @click="compPop = false">确定</button>
        </div>
      </div>
    </div>
    <!--关联账户弹窗-->
    <div class="main-pop" v-show="zhPop">
      <div class="mask"></div>
      <div class="center" style="width: 876px">
        <div class="head">
          <span>关联账户</span>
          <i class="gongzuotai gztguanbi" @click="zhPop = false"></i>
        </div>
        <div class="body">
          <el-table :data="zhList" style="width: 100%">
            <template slot="empty">
              <div class="nodata">
                <img src="@/assets/img/nodata.png" />
                <span>暂无数据</span>
              </div>
            </template>
            <el-table-column
              prop
              label="序号"
              type="index"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="project_name"
              label="项目名称"
              width
            ></el-table-column>
            <el-table-column
              prop="open_company_name"
              label="开户公司名称"
              width
            ></el-table-column>
            <el-table-column
              prop="platform_name"
              label="平台"
              width
            ></el-table-column>
            <el-table-column
              prop="account_id"
              label="账户ID"
              width
            ></el-table-column>
          </el-table>
        </div>
        <div class="foot">
          <button class="main-button l" @click="zhPop = false">取消</button>
          <button class="main-button r" @click="zhPop = false">确定</button>
        </div>
      </div>
    </div>
    <!--eip弹窗-->
    <div class="main-pop" v-show="eipPop">
      <div class="mask"></div>
      <div class="center" style="width: 1000px">
        <div class="head">
          <span>{{ eipTitle }}</span>
          <i class="gongzuotai gztguanbi" @click="eipPop = false"></i>
        </div>
        <div class="body">
          <iframe :href="eipUrl"></iframe>
        </div>
        <div class="foot">
          <button class="main-button l" @click="eipPop = false">取消</button>
          <button class="main-button r" @click="eipPop = false">确定</button>
        </div>
      </div>
    </div>
    <!-- 选择客户组 -->
    <div class="main-pop" v-show="modalCustomerGroup">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span
            >选择客户组
            <i style="margin: 0 40px 0 20px">加入客户组后将使用客户组规则</i>
            <el-tooltip
              class="item"
              effect="dark"
              content="1.将使用客户组的统一授信 2.充值使用客户组的可用余额 3.回款记录将被加入客户组"
              placement="top-start"
              ><span
                class="bluefont"
                style="color: rgba(1, 172, 228, 1); font-size: 12px"
                >了解规则</span
              ></el-tooltip
            ></span
          >
          <i
            class="gongzuotai gztguanbi"
            @click="modalCustomerGroup = false"
          ></i>
        </div>
        <div class="body">
          <el-form size="mini" label-width="90px" label-position="left">
            <el-form-item label="客户组：" required>
              <el-select
                v-model="customerGroupId"
                filterable
                clearable
                remote
                :remote-method="remoteMethod1"
                placeholder="未分组"
                @clear="clearSelectedGroup()"
              >
                <el-option
                  v-for="item in customerGroupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
                <div
                  style="float: right; margin-right: 10px; padding-bottom: 10px"
                >
                  <el-pagination
                    @current-change="customerGroupInit"
                    :current-page="groupAjaxDataSearch.pageNum"
                    :page-size="groupAjaxDataSearch.pageSize"
                    layout="prev, pager, next"
                    :total="groupAjaxDataSearch.totals"
                  ></el-pagination>
                </div>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot" style="display: flex; justify-content: space-between">
          <el-button type="primary" size="small">新建客户组</el-button>
          <div>
            <button class="main-button l" @click="modalCustomerGroup = false">
              取消
            </button>
            <button class="main-button r" @click="saveRelatedGroup()">
              确定
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 设置异常客户状态 -->
    <div class="main-pop" v-show="setCustomerErrorToast">
      <div class="mask"></div>
      <div class="center" style="width: 600px">
        <div class="head">
          <span
            >{{ isSetCustomerError ? "设置客户异常状态" : "解除客户异常状态" }}
            <i>{{
              isSetCustomerError
                ? "被标记为异常的客户无法进行申请开户、充值等操作"
                : "将被标记为异常的客户恢复成正常状态"
            }}</i></span
          >
          <i
            class="gongzuotai gztguanbi"
            @click="setCustomerErrorToast = false"
          ></i>
        </div>
        <div class="body">
          <el-form size="small" label-position="right" label-width="110px">
            <el-form-item label="设置原因：" required>
              <el-input
                v-model="customerErrorReason"
                type="text"
                maxlength="20"
                show-word-limit
                :placeholder="
                  isSetCustomerError
                    ? '请输入标记客户异常的原因，最多支持20个字'
                    : '请输入解除客户异常的原因，最多支持20个字'
                "
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot">
          <button
            class="main-button l"
            @click="
              setCustomerErrorToast = false;
              customerErrorReason = '';
            "
          >
            取消
          </button>
          <button class="main-button r" @click="clientSetOrClear()">
            确定
          </button>
        </div>
      </div>
    </div>
    <!-- 客户审核 -->
    <div v-show="showFlag == 3">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1"
          >返回</el-button
        >
        <span>客户详情</span>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <p class="main-title">客户信息</p>
        <div class="infoBox">
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户名称:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.client_company_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户组:</p>
              <div class="info-text calc-4w">
                {{
                  info.client_company_group && info.client_company_group.name
                    ? info.client_company_group.name
                    : "未分组"
                }}
                <span @click="changeGroup()" class="bluefont">修改分组</span>
              </div>
              <!--  -->
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-6w">关联财务客户:</p>
              <div class="info-text calc-6w">
                {{ info.basic_info.finance_name }}
                <span
                  v-if="info.basic_info.finance_id"
                  @click="changeFinacalID"
                  class="bluefont"
                  >修改财务客户</span
                >
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户状态:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.client_status | checkStatus }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户行业:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.industry_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">主营业务:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.main_business }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">所在地区:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.company_address }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">详细地址:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.detailed_address }}
              </div>
            </div>
            <div class="info-cell w-33" style="position: relative">
              <div style="position: absolute">
                <el-image
                  style="width: 192px; height: 108px; border-radius: 4px"
                  :src="info.basic_info.business_license_link"
                  :preview-src-list="prevsrcList"
                >
                </el-image>
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户评级:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.level_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">定级原因:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.reason_rating }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell">
              <p class="info-title t-10w">统一社会信用代码:</p>
              <div class="info-text calc-10w">
                {{ info.basic_info.business_license_number }}
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-4w">客户来源:</p>
              <div class="info-text calc-4w">
                {{ info.basic_info.client_source_name }}
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">备注:</p>
              <div class="info-text calc-4w">{{ info.basic_info.remark }}</div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-cell w-33">
              <p class="info-title t-5w">销售负责人:</p>
              <div class="info-text calc-5w">
                {{
                  info.basic_info.sale_name &&
                  info.basic_info.sale_name.length > 0
                    ? getNames(info.basic_info.sale_name)
                    : "-"
                }}
                <span
                  v-if="info.basic_info.client_status == 2"
                  class="bluefont"
                  style="margin-left: 20px"
                  @click="changeSalers()"
                  >修改销售负责人</span
                >
              </div>
            </div>
            <div class="info-cell w-33">
              <p class="info-title t-4w">AM:</p>
              <div class="info-text calc-4w">
                {{
                  info.basic_info.am_name && info.basic_info.am_name.length > 0
                    ? getNames(info.basic_info.am_name)
                    : "-"
                }}
                <span
                  v-if="info.basic_info.client_status == 2"
                  class="bluefont"
                  style="margin-left: 20px"
                  @click="changeAMS()"
                  >修改AM负责人</span
                >
              </div>
            </div>
          </div>
          <div class="info-row" v-if="info.basic_info.exception_reason">
            <div class="info-cell w-33">
              <p class="info-title t-7w" style="color: #f00">审核原因:</p>
              <div class="info-text calc-7w" style="color: #f00">
                {{ info.basic_info.exception_reason }}
              </div>
            </div>
          </div>
          <div class="info-row" v-if="info.basic_info.exception_reason">
            <div class="info-cell w-33">
              <p class="info-title t-7w" style="color: #f00">设置异常原因:</p>
              <div class="info-text calc-7w" style="color: #f00">
                {{ info.basic_info.exception_reason }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <div style="display: flex; align-items: center">
          <p class="main-title" style="margin-bottom: 0">用户审核</p>
          <el-alert
            v-if="info.basic_info.point == '营业执照不正确'"
            :closable="false"
            style="width: 500px; margin-left: 30px"
            title="填写的公司名称与营业执照上的公司名称不匹配"
            type="error"
            show-icon
          >
          </el-alert>
        </div>
        <el-form
          size="small"
          style="width: 500px; margin-top: 30px"
          label-width="100px"
          label-position="right"
        >
          <el-form-item label="审核状态：" required>
            <el-select v-model="examineStatus">
              <el-option label="通过" value="2">通过</el-option>
              <el-option label="不通过" value="5">不通过</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核原因：">
            <el-input
              type="textarea"
              placeholder="请填写审核原因"
              v-model="examineText"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="background: #fff; padding: 24px; margin-bottom: 24px">
        <el-button type="primary" @click="examineSubmit()">提交</el-button>
      </div>
    </div>
    <el-dialog title="自定义列表项" :visible.sync="editTableColumnDialog">
      <el-table
        size="small"
        :data="tableColumns"
        style="width: 96%; margin: 0 auto"
      >
        <el-table-column label="列表项">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="是否展示">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">不可修改</span>
            <el-switch v-else v-model="scope.row.value"></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editTableColumnDialog = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="hideColumns()"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import moment from "moment";
export default {
  name: "",
  components: {
    paginationVue,
  },
  data() {
    return {
      tableColumnVals: {
        client_company_name: true,
        level_name: true,
        available_balance: true,
        credit_line_value: true,
        owed_amount: true,
        status: true,
        platform_account: true,
        accountCompanyName: true,
        group_name: true,
        source: true,
        sale_name: true,
        am_name: true
      },
      tableColumns: [
        {
          id: "client_company_name",
          name: "客户名称",
          edit: false,
          value: false,
        },
        {
          id: "level_name",
          name: "客户级别",
          edit: true,
          value: true,
        },
        {
          id: "available_balance",
          name: "可用余额",
          edit: true,
          value: true,
        },
        {
          id: "credit_line_value",
          name: "授信额度",
          edit: true,
          value: true,
        },
        {
          id: "owed_amount",
          name: "欠款金额",
          edit: true,
          value: true,
        },
        {
          id: "status",
          name: "客户状态",
          edit: true,
          value: true,
        },
        {
          id: "platform_account",
          name: "平台账号",
          edit: true,
          value: true,
        },
        {
          id: "group_name",
          name: "客户组",
          edit: true,
          value: true,
        },
        {
          id: "source",
          name: "客户来源",
          edit: true,
          value: true,
        },
        {
          id: "sale_name",
          name: "销售负责人",
          edit: true,
          value: true,
        },
        {
          id: "am_name",
          name: "AM负责人",
          edit: true,
          value: true,
        },
      ],
      editTableColumnDialog: false,
      examineStatus: "",
      examineText: "",
      prevsrcList: [],
      allSales: [],
      allAMs: [],
      debuffRecords: [],
      customerErrorReason: "",
      setCustomerErrorToast: false,
      isSetCustomerError: true,
      groupSelectList: [],
      getGroupPostData: {
        name: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      customerGroupId: "",
      customerGroupList: [],
      groupAjaxDataSearch: {
        name: "",
        levelId: "",
        pageNum: 1,
        pageSize: 15,
        totals: 0,
      },
      modalCustomerGroup: false,
      selectedSalers: [],
      selectedAMs: [],
      addSalers: {},
      addAMs: {},
      editSalers: false,
      editAMs: false,
      rebateToast: false,
      platformText: "开通平台账户",
      relatedComList: [],
      relatedCom: false,
      loading: true,
      //频次统计 定时器
      timer: "",
      msg: "",
      firstPage: 1,
      showFlag: 1,
      pageSize: 15,
      total: 1,
      ajaxDataSearch: {
        source: "",
        sale_id: "",
        am_id: "",
        sale_id_arr: [],
        am_id_arr: [],
        group_id: "",
        client_company_name: "",
        status: "",
        level_id: "",
        platform_account: "",
        balance_type: "",
        amount_start: "",
        amount_end: "",
        client_company_id: "",
        start_date: "",
        end_date: "",
        industry_id: "",
        page: 1,
        page_num: 15,
      },
      optionsTrade: [
        {
          value: "1",
          label: "行业1",
        },
        {
          value: "2",
          label: "行业2",
        },
      ],
      optionsStatus: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "审核失败",
        },
      ],
      customerList: [],
      compList: [
        {
          company_id: "dsgfwef23r",
          company_name: "cs",
        },
      ],
      zhList: [
        {
          project_name: "cscs",
          account_id: "1",
          open_company_name: "1",
          platform_name: "pingtai",
        },
      ],
      xsList: [
        {
          updated_at: "2020-04-14 18:26:57",
          user_real_name: null,
          relationship_with_customers_name: "guanxi",
        },
      ],
      AMList: [
        {
          created_at: null,
          project_name: "cscs",
          user_real_name: null,
        },
      ],
      info: {
        basic_info: {
          client_company_name: "cscs",
          industry_id: "23rdgtrh45fdbd",
          level_id: "fbert54ht",
          company_address: "1",
          detailed_address: "1",
          client_leader_id: "1",
          client_source_name: "xxxxx",
          main_business: null,
          reason_rating: "",
          remark: "ewihf239hfe",
          industry_name: "weuhf928hfiuew",
          level_name: "cscs",
          user_real_name: "王凯",
        },
        company_list: [
          {
            company_id: "dsgfwef23r",
            company_name: "cs",
          },
        ],
        client_ads_list: {},
        guarantee_list: [
          {
            guarantee_company_name: "aa",
            guarantee_amount: "0.00",
            actual_controller_name: "bb",
            actual_controller_guarantee_amount: "0.00",
            is_multiple_contract: 0,
          },
        ],
      },
      configurationData: {client_company_source:{}},
      compPop: false,
      zhPop: false,
      xsPop: false,
      AMPop: false,
      eipTitle: "",
      eipPop: false,
      eipUrl: "",
      formm: "",
      saveRelatedCom: {
        client_company_id: "",
        finance_id: "",
      },
      projectPostData: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
        st_name: "",
      },
      ktplatform: {
        client_company_id: "",
        account: "",
        account_password: "",
        daily_account_opening_limit: "",
        email_address: "",
      },
      showPlatform: false,
      selRow: {}, //选中的行
      authPop: false,
      authPerson: [], //登录授权弹框--被授权人
      user_id: "", //选中的被授权人
      customerDetailPostD: {
        client_company_id: "",
        recid: "",
        page: 1,
        total: 0,
        pageSize: 15,
      },
      relatedCustomerDetailPostD: {
        client_company_id: "",
        page: 1,
        total: 0,
        pageSize: 15,
      },
      guaranteeinforDetailPostD: {
        client_company_id: "",
        page: 1,
        total: 0,
        pageSize: 15,
      },
      rebateListsDetailPostD: {
        client_company_id: "",
        page: 1,
        page_num: 15,
        total: 0,
        network: "",
      },
      contactUserData: [],
      relatedCustomerData: [],
      guaranteeinforList: [],
      rebateLists: [],
      newRebatePostData: {
        client_company_id: "",
        starttime: "",
        endtime: "",
        network: "",
        ratio_type: 1,
        ratio: "",
        section_ratio: [],
        times: "",
      },
      getrebateFlag: false,
    };
  },
  methods: {
    hideColumns() {
      this.tableColumns.forEach((ele) => {
        this.tableColumnVals[ele.id] = ele.value;
      });
      localStorage.setItem(
        "tableColumnVals",
        JSON.stringify(this.tableColumnVals)
      );
      this.editTableColumnDialog = false;
    },
    editTableColumns() {
      this.editTableColumnDialog = true;
    },
    examineSubmit() {
      console.log({
        recid: this.info.basic_info.recid,
        status: this.examineStatus,
        failed_reason: this.examineText,
      });
      if (!this.examineStatus) {
        this.$message({ message: "请选择审核状态", type: "warning" });
        return;
      }
      if (this.examineStatus == 5 && !this.examineText) {
        this.$message({ message: "请填写审核原因", type: "warning" });
        return;
      }
      this.axios("post", `${this.$phpBaseURL}v1/client/clientExamine`, {
        recid: this.info.basic_info.recid,
        status: this.examineStatus,
        failed_reason: this.examineText,
      }).then((res) => {
        if (res.code == 1) {
          this.showFlag = 1;
          this.$message({ message: "审核成功", type: "success" });
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    examineCustomer(row) {
      this.showFlag = 3;
      this.getInfo(row);
    },
    showName(arr) {
      if (!arr) {
        return "";
      }
      let str = "";
      arr.forEach((item) => {
        str += item.name + " ";
      });
      return str;
    },
    getAsleAndAm() {
      this.axios("GET", `${this.$phpBaseURL}v1/client/getAsleAndAm`).then(
        (res) => {
          if (res.code == 1) {
            this.allSales = res.data.sale;
            this.allAMs = res.data.am;
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    clickTo(val) {
      if (val == 1) {
        this.$router.push({
          path: "/ioList",
          query: {
            searchType: 2,
            from: "customer",
            client_company_name: this.info.basic_info.client_company_name,
          },
        });
      } else if (val == 2) {
        this.$router.push({
          path: "/media",
          query: {
            from: "customer",
            client_company_name: this.info.basic_info.client_company_name,
          },
        });
      } else {
        this.$router.push({
          path: "/contract",
          query: {
            from: "customer",
            client_company_name: this.info.basic_info.client_company_name,
          },
        });
      }
    },
    clientSetOrClear() {
      let type_ = 1;
      if (!this.customerErrorReason) {
        this.$message({ message: "请输入标记客户异常等原因", type: "warning" });
        return;
      }
      if (this.isSetCustomerError) {
        type_ = 1;
      } else {
        type_ = 2;
      }
      this.axios("POST", `${this.$phpBaseURL}v1/client/setOrClear`, {
        recid: this.selRow.recid,
        type: type_,
        reason: this.customerErrorReason,
      }).then((res) => {
        if (res.code == 1) {
          this.customerErrorReason = "";
          this.$message({ message: res.msg, type: "success" });
          this.setCustomerErrorToast = false;
          this.init();
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    setCustomerErrorS(row, bol) {
      this.selRow = row;
      this.setCustomerErrorToast = true;
      this.isSetCustomerError = bol;
    },
    saveRelatedGroup() {
      if (!this.customerGroupId) {
        this.$message({ message: "请选择客户组", type: "warning" });
        return;
      }
      this.axios("POST", `${this.$javaBaseURL}clientCompanyGroup/addClient`, {
        clientCompanyId: this.info.basic_info.client_company_id,
        groupId: this.customerGroupId,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({ message: "修改成功", type: "success" });
          this.getInfo(this.selRow);
          this.init();
          this.modalCustomerGroup = false;
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    clearSelectedGroup() {
      this.groupAjaxDataSearch.name = "";
      this.customerGroupId = "";
      this.customerGroupInit();
    },
    focusIngroup() {
      this.getGroupSelect(1);
    },
    groupIdRemote(query) {
      this.getGroupPostData.name = query;
      this.getGroupSelect(1);
    },
    getGroupSelect(page) {
      this.getGroupPostData.pageNum = page ? page : 1;
      this.axios(
        "get",
        `${this.$javaBaseURL}clientCompanyGroup/ccgSelect`,
        this.getGroupPostData
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.groupSelectList = res.data.list;
          this.getGroupPostData.total = res.data.total;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    viewEditGroup() {
      this.$router.push({
        name: "customerGroup",
        query: {
          showFlag: 2,
          id: this.selRow.group_id,
        },
      });
    },
    remoteMethod1(query) {
      this.groupAjaxDataSearch.name = query;
      this.customerGroupInit();
    },
    customerGroupInit(page) {
      this.groupAjaxDataSearch.pageNum = page ? page : 1;
      clearTimeout(this.timer);
      this.axios(
        "get",
        `${this.$javaBaseURL}clientCompanyGroup/list`,
        this.groupAjaxDataSearch
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.customerGroupList = res.data.list;
          this.groupAjaxDataSearch.totals = res.data.total;
          if (this.groupAjaxDataSearch.name) {
            this.customerGroupId =
              this.customerGroupList.length > 0
                ? this.customerGroupList[0].id
                : "";
          }
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    changeGroup() {
      this.modalCustomerGroup = true;
    },
    changeSelectSalers() {
      this.selectedSalers.push(this.addSalers);
      this.salersHandle();
    },
    delSelectedSalers(item, key) {
      this.selectedSalers.splice(key, 1);
      this.salersHandle();
    },
    changeSelectAMs() {
      this.selectedAMs.push(this.addAMs);
      this.amsHandle();
    },
    delSelectedAMs(item, key) {
      this.selectedAMs.splice(key, 1);
      this.amsHandle();
    },
    getNames(arr) {
      var str = "";
      arr.forEach((item) => {
        str += item.name + " ";
      });
      return str;
    },
    confirmSalers() {
      let arr = [];
      for (let i = 0; i < this.selectedSalers.length; i++) {
        const element = this.selectedSalers[i];
        arr.push(element.id);
      }
      this.axios("POST", `${this.$phpBaseURL}v1/client/uptAmOrSale`, {
        recid: this.info.basic_info.recid || this.selRow.recid,
        sale_id: arr,
        am_id: [],
      }).then((res) => {
        if (res.code == 1) {
          this.$message({ message: res.msg, type: "success" });
          this.editSalers = false;
          this.getInfo(this.selRow);
          this.addSalers = {};
          this.init();
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    salersHandle() {
      let arr = this.configurationData.project_authorization_list;
      this.configurationData.project_authorization_list.forEach((item) => {
        item.disabled = false;
        this.selectedSalers.map((i) => {
          if (i.id == item.id) {
            item.disabled = true;
          }
        });
        return item;
      });
    },
    confirmAMs() {
      let arr = [];
      for (let i = 0; i < this.selectedAMs.length; i++) {
        const element = this.selectedAMs[i];
        arr.push(element.id);
      }
      this.axios("POST", `${this.$phpBaseURL}v1/client/uptAmOrSale`, {
        recid: this.info.basic_info.recid || this.selRow.recid,
        sale_id: [],
        am_id: arr,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({ message: res.msg, type: "success" });
          this.editAMs = false;
          this.getInfo(this.selRow);
          this.addAMs = {};
          this.init();
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    changeSalers(row) {
      if (row) {
        this.selRow = row;
        this.selectedSalers = row.sale_name;
      }
      this.salersHandle();
      this.editSalers = true;
    },
    amsHandle() {
      let arr = this.configurationData.project_authorization_list;
      this.configurationData.project_authorization_list.forEach((item) => {
        item.disabled = false;
        this.selectedAMs.map((i) => {
          if (i.id == item.id) {
            item.disabled = true;
          }
        });
        return item;
      });
    },
    changeAMS(row) {
      if (row) {
        this.selRow = row;
        this.selectedAMs = row.am_name;
      }
      this.amsHandle();
      this.editAMs = true;
    },
    costBlur(row, index) {
      if (index > 0) {
        this.newRebatePostData.section_ratio[index - 1].end =
          this.newRebatePostData.section_ratio[index].start - 1;
      } else {
      }
    },
    relateFinacalID(row) {
      this.relatedCom = true;
      this.saveRelatedCom.client_company_id = row.client_company_id;
      if (row.finance_id) {
        this.saveRelatedCom.finance_id = row.finance_id;
        var flag_ = true;
        this.relatedComList.forEach((item) => {
          if (item.recid == row.finance_id) {
            flag_ = false;
            return;
          }
        });
        if (flag_) {
          this.relatedComList.unshift({
            recid: row.finance_id,
            st_name: row.finance_name,
            finance_company: row.finance_company,
          });
        }
      }
    },
    changeFinacalID() {
      this.relatedCom = true;
      this.saveRelatedCom.client_company_id = this.selRow.client_company_id;
      this.saveRelatedCom.finance_id = this.info.basic_info.finance_id;
      var flag_ = true;
      this.relatedComList.forEach((item) => {
        if (item.recid == this.info.basic_info.finance_id) {
          flag_ = false;
          return;
        }
      });
      if (flag_) {
        this.relatedComList.unshift({
          recid: this.info.basic_info.finance_id,
          st_name: this.info.basic_info.finance_name,
          finance_company: this.info.basic_info.finance_company,
        });
      }
    },
    goOpenApp(row) {
      this.axios("POST", `${this.$javaBaseURL}open/application`, {
        platform: 1,
        clientCompanyId: row.client_company_id,
      }).then((res) => {
        if (res.code == 1) {
          this.$router.push({
            name: "openApplication",
            query: {
              from: "CustomerList",
              param: JSON.stringify(this.ajaxDataSearch),
              fburl: res.data,
              clientCompanyId: row.client_company_id,
            },
            params: {},
          });
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    exportexl() {
      const toke_ = JSON.parse(localStorage.getItem("user_info")).token;
      window.open(
        `${this.$phpBaseURL}v1/client/clientExport?client_company_name=${this.ajaxDataSearch.client_company_name}&status=${this.ajaxDataSearch.status}&level_id=${this.ajaxDataSearch.level_id}&platform_account=${this.ajaxDataSearch.platform_account}&balance_type=${this.ajaxDataSearch.balance_type}&amount_start=${this.ajaxDataSearch.amount_start}&amount_end=${this.ajaxDataSearch.amount_end}&client_company_id=${this.ajaxDataSearch.client_company_id}&start_date=${this.ajaxDataSearch.start_date}&end_date=${this.ajaxDataSearch.end_date}&industry_id=${this.ajaxDataSearch.industry_id}&token=${toke_}`
      );
    },
    remoteMethod(query) {
      console.log(query);
      this.projectPostData.st_name = query;
      this.getRelatedComList();
    },
    editRebate(row) {
      console.log(row);
      this.rebateToast = true;
      this.newRebatePostData = row;
      this.newRebatePostData.times = [
        this.newRebatePostData.starttime,
        this.newRebatePostData.endtime,
      ];
    },
    deleteRebate(row) {
      this.axios("POST", `${this.$phpBaseURL}v1/client/delRebate`, {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.rebateToast = false;
          this.getRebateLists(1);
          this.$message({ message: res.msg, type: "success" });
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    contactDetail(row) {
      window.open(`/#/contacts?contact_name=${row.contact_name}`);
    },
    submitNewRebate() {
      if (!this.newRebatePostData.network) {
        this.$message({ message: "请选择媒体平台", type: "warning" });
        return;
      } else if (!this.newRebatePostData.starttime) {
        this.$message({ message: "请选择有效期", type: "warning" });
        return;
      } else if (!this.newRebatePostData.ratio_type) {
        this.$message({ message: "请选择返点方式", type: "warning" });
        return;
      }
      if (this.newRebatePostData.ratio_type == 1) {
        if (!this.newRebatePostData.ratio) {
          this.$message({ message: "请填写返点比例", type: "warning" });
          return;
        } else if (
          Number(this.newRebatePostData.ratio) < 0.01 ||
          Number(this.newRebatePostData.ratio) > 100
        ) {
          this.$message({
            message: "返点比例只允许输入0.01-100之间的数字",
            type: "warning",
          });
          return;
        }
      }
      if (
        this.newRebatePostData.ratio_type == 3 &&
        this.newRebatePostData.section_ratio.length > 0
      ) {
        for (let i = 0; i < this.newRebatePostData.section_ratio.length; i++) {
          const ele = this.newRebatePostData.section_ratio[i];
          console.log(ele);
          if (
            !ele.ratio ||
            Number(ele.ratio) < 0.01 ||
            Number(ele.ratio) > 100
          ) {
            // f_ = false
            this.$message({
              message: "返点比例只允许输入0.01-100之间的数字",
              type: "warning",
            });
            return;
          }
        }
      }
      console.log(this.newRebatePostData.id);
      if (this.newRebatePostData.id) {
        this.axios(
          "POST",
          `${this.$phpBaseURL}v1/client/editRebate`,
          this.newRebatePostData
        ).then((res) => {
          if (res.code == 1) {
            this.rebateToast = false;
            this.$message({ message: res.msg, type: "success" });
            this.init();
            if (this.getrebateFlag) {
              this.getRebateLists();
            }
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      } else {
        this.axios(
          "POST",
          `${this.$phpBaseURL}v1/client/addRebate`,
          this.newRebatePostData
        ).then((res) => {
          if (res.code == 1) {
            this.rebateToast = false;
            this.$message({ message: res.msg, type: "success" });
            this.init();
            if (this.getrebateFlag) {
              this.getRebateLists();
            }
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }
    },
    removeRebate(index) {
      this.newRebatePostData.section_ratio.splice(index, 1);
    },
    addSectionRatio() {
      this.newRebatePostData.section_ratio.push({
        start: "",
        end: "",
        ratio: "",
      });
    },
    formatTimes(event) {
      this.newRebatePostData.starttime = this.newRebatePostData.times[0];
      this.newRebatePostData.endtime = this.newRebatePostData.times[1];
    },
    createRebate(row, bl) {
      this.getrebateFlag = bl;
      this.newRebatePostData = {
        client_company_id: "",
        starttime: "",
        endtime: "",
        network: "",
        ratio_type: 1,
        ratio: "",
        section_ratio: [],
        times: "",
      };
      this.rebateToast = true;
      this.newRebatePostData.client_company_id = row
        ? row.client_company_id
        : this.selRow.client_company_id;
    },
    getRebateLists(page) {
      this.rebateListsDetailPostD.page = page ? page : 1;
      this.axios(
        "GET",
        `${this.$phpBaseURL}v1/client/rebateLists`,
        this.rebateListsDetailPostD
      ).then((res) => {
        if (res.code == 1) {
          this.rebateLists = res.data.data;
          this.rebateListsDetailPostD.total = res.data.total;
          this.rebateListsDetailPostD.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getRebateListsSizeInit(size) {
      this.rebateListsDetailPostD.page_num = size ? size : 15;
      this.getRebateLists(1);
    },
    getguaranteeinforListSizeInit(size) {
      this.guaranteeinforDetailPostD.pageSize = page ? page : 1;
      this.getguaranteeinforList(1);
    },
    getguaranteeinforList(page) {
      this.guaranteeinforDetailPostD.page = page ? page : 1;
      this.axios(
        "GET",
        `${this.$phpBaseURL}v1/client/guaranteeLists`,
        this.guaranteeinforDetailPostD
      ).then((res) => {
        if (res.code == 1) {
          this.guaranteeinforList = res.data.data;
          this.guaranteeinforDetailPostD.total = res.data.total;
          this.guaranteeinforDetailPostD.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getrelatedCustomerList(page) {
      this.relatedCustomerDetailPostD.page = page ? page : 1;
      this.axios(
        "GET",
        `${this.$phpBaseURL}v1/client/clientCompanys`,
        this.relatedCustomerDetailPostD
      ).then((res) => {
        if (res.code == 1) {
          this.relatedCustomerData = res.data.data;
          this.relatedCustomerDetailPostD.total = res.data.total;
          this.relatedCustomerDetailPostD.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getContactListsizeInit(size) {
      this.customerDetailPostD.page_num = size ? size : 15;
      this.getContactList(1);
    },
    getrelatedCustomerListsizeInit(size) {
      this.relatedCustomerDetailPostD.pageSize = size ? size : 15;
    },
    getContactList(page) {
      this.customerDetailPostD.page = page ? page : 1;
      this.axios(
        "GET",
        `${this.$phpBaseURL}v1/client/clientContactPersons`,
        this.customerDetailPostD
      ).then((res) => {
        if (res.code == 1) {
          this.contactUserData = res.data.data;
          this.customerDetailPostD.total = res.data.total;
          this.customerDetailPostD.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getAuth(row) {
      //授权
      this.authPop = true;
      this.selRow = row;
      console.log("selrow:", this.selRow);
      this.getAuthPerson();
    },
    getAuthPerson() {
      //获取授权信息
      let param = {
        client_company_id: this.selRow.client_company_id,
      };
      this.axios(
        "GET",
        `${this.$phpBaseURL}v1/platformUser/authorizationLists`,
        param
      ).then((res) => {
        if (res.code == 1) {
          this.authPerson = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //删除授权人
    delAuthPerson(item) {
      let param = {
        client_company_id: this.selRow.client_company_id,
        user_id: item.user_id,
      };
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/platformUser/authorizationDel`,
        param
      ).then((res) => {
        if (res.code == 1) {
          this.$message({ message: "删除成功", type: "success" });
          this.getAuthPerson();
          this.authPop = false;
          //刷新页面
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //确定添加授权人
    confirmAuth() {
      let param = {
        client_company_id: this.selRow.client_company_id,
        user_id: this.user_id,
      };
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/platformUser/authorization`,
        param
      ).then((res) => {
        if (res.code == 1) {
          this.$message({ message: "添加成功", type: "success" });
          this.authPop = false;
          this.user_id = "";
          //功能频次统计
          this.common.statisticsAdd("项目授权", "2");
          //刷新页面
          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    seeShare(item) {
      this.common.statisticsAdd("登录账户管理平台", "2");
      let param = {
        client_company_id: item.client_company_id,
      };
      this.axios("POST", `${this.$phpBaseURL}v1/client/ampLogin`, param).then(
        (res) => {
          if (res.code == 1) {
            let str = JSON.stringify(res.data);
            let token = res.data.token;
            let target_url = `${this.$panda_url}login?token=${token}`;
            window.open(target_url, "_blank");
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    startAndStop(item) {
      var url = `${this.$phpBaseURL}v1/platformUser/lockingAccount`;
      if (item.account_status == 2) {
        url = `${this.$phpBaseURL}v1/platformUser/unlockAccount`;
      }
      this.$confirm(
        item.account_status == 2
          ? "确定要启用账号"
          : "关闭账号后，用户将不能登录账号管理平台",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.axios("POST", url, {
            account_id: item.account_id,
          }).then((res) => {
            if (res.code == 1) {
              this.init();
              this.$message({ message: res.msg, type: "success" });
            } else if (res.code == 2) {
              this.common.tokenExpired(res.msg);
            } else {
              this.$message({ message: res.msg, type: "warning" });
            }
          });
        })
        .catch(() => {});
    },
    openPlatformMet() {
      if (!this.ktplatform.account) {
        this.$message({ message: "请输入账户名", type: "warning" });
        return;
      } else if (
        !this.ktplatform.account_password &&
        !this.ktplatform.client_company_id
      ) {
        this.$message({ message: "请输入密码", type: "warning" });
        return;
      } else if (!this.ktplatform.daily_account_opening_limit) {
        this.$message({
          message: "每开户公司最大开户数不能为空",
          type: "warning",
        });
        return;
      } else if (!this.ktplatform.email_address) {
        this.$message({ message: "接收通知邮箱不能为空", type: "warning" });
        return;
      }
      var url = "";
      if (this.platformText == "编辑平台账户") {
        url = `${this.$phpBaseURL}v1/platformUser/editAccount`;
      } else {
        url = `${this.$phpBaseURL}v1/platformUser/openAccount`;
      }
      this.axios("POST", url, this.ktplatform).then((res) => {
        if (res.code == 1) {
          this.showPlatform = false;
          this.init();
          this.$message({ message: res.msg, type: "success" });
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    platformOpe(item, o) {
      if (o == "edit") {
        this.platformText = "编辑平台账户";
      } else {
        this.platformText = "开通平台帐户";
      }
      if (this.info.platform_users && this.info.platform_users.account) {
        this.ktplatform = JSON.parse(JSON.stringify(this.info.platform_users));
      }
      if (item.finance_id) {
        // 已绑定
        this.showPlatform = true;
        this.ktplatform.client_company_id = item.client_company_id;
      } else {
        this.$confirm("关联财务客户后，才可以开通账户", "提示", {
          confirmButtonText: "去关联",
          cancelButtonText: "取消",
        }).then(() => {
          this.relatedCom = true;
          this.saveRelatedCom.client_company_id = item.client_company_id;
        });
      }
    },
    saveRelatedComMet() {
      console.log(this.saveRelatedCom);
      var url = `${this.$phpBaseURL}v1/client/bindFinance`;
      if (!this.saveRelatedCom.finance_id) {
        this.$message({ message: "请选择财务客户", type: "warning" });
        return;
      }
      this.axios("POST", url, this.saveRelatedCom).then((res) => {
        if (res.code == 1) {
          this.relatedCom = false;
          this.init();
          this.info.basic_info.finance_id = this.saveRelatedCom.finance_id;
          this.info.basic_info.finance_name = res.data;
          this.$message({ message: "关联成功", type: "success" });
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    updateValue(event) {
      this.saveRelatedCom.finance_id = event;
    },
    changeInput() {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(this.ajaxDataSearch.amount_start)) {
        // input 框绑定的内容为空
        this.ajaxDataSearch.amount_start = "";
      }
      if (!pattern.test(this.ajaxDataSearch.amount_end)) {
        // input 框绑定的内容为空
        this.ajaxDataSearch.amount_end = "";
      }
    },
    resetList() {
      this.ajaxDataSearch = {
        sale_id_arr: [],
        sale_id: "",
        am_id_arr: [],
        am_id: "",
        source: "",
        client_company_name: "",
        status: "",
        level_id: "",
        platform_account: "",
        balance_type: "",
        amount_start: "",
        amount_end: "",
        client_company_id: "",
        start_date: "",
        end_date: "",
        industry_id: "",
        page: "",
      };
      this.init(1);
    },
    getRelatedComList(page) {
      this.projectPostData.page = page ? page : 1;
      this.axios(
        "get",
        `${this.$phpBaseURL}v1/clientFinance/selectDatas`,
        this.projectPostData
      ).then((res) => {
        if (res.code == 1) {
          this.relatedComList = res.data.data;
          this.projectPostData.total = res.data.total;
          this.projectPostData.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    sizeInit(pageSize) {
      this.loading = true;
      this.ajaxDataSearch.page_num = pageSize ? pageSize : 15;
      this.init(1);
    },
    init(page) {
      this.loading = true;
      this.firstPage = page ? page : 1;
      this.ajaxDataSearch.page = page ? page : 1;
      clearTimeout(this.timer);
      this.ajaxDataSearch.sale_id =
        this.ajaxDataSearch.sale_id_arr.length > 0
          ? this.ajaxDataSearch.sale_id_arr.join(",")
          : "";
      this.ajaxDataSearch.am_id =
        this.ajaxDataSearch.am_id_arr.length > 0
          ? this.ajaxDataSearch.am_id_arr.join(",")
          : "";
      this.axios(
        "get",
        `${this.$phpBaseURL}v1/client/lists`,
        this.ajaxDataSearch
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.customerList = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      this.init(1);
    },
    /*详情*/
    getInfo(item) {
      this.selRow = item;
      this.customerDetailPostD.client_company_id = item.client_company_id;
      this.rebateListsDetailPostD.client_company_id = item.client_company_id;
      this.remoteMethod1(item.group_name);
      this.$store.commit("curRouter", false);
      this.axios("get", `${this.$phpBaseURL}v1/client/detail`, {
        recid: item.recid,
      }).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.selectedSalers = res.data.basic_info.sale_name;
          this.selectedAMs = res.data.basic_info.am_name;
          this.getContactList();
          this.getrelatedCustomerList();
          this.getguaranteeinforList();
          this.getRebateLists();
          this.getDebuffRecords(item.recid);
          this.prevsrcList = [];
          this.prevsrcList.push(res.data.basic_info.business_license_link);
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getDebuffRecords(id) {
      this.axios("get", `${this.$phpBaseURL}v1/client/debuffRecord`, {
        recid: id,
      }).then((res) => {
        if (res.code == 1) {
          this.debuffRecords = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*关联公司*/
    getComp(id) {
      this.axios("POST", `${this.$phpBaseURL}v1/client/relatedCompany`, {
        client_company_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.compList = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*关联账户*/
    getZh(id) {
      this.axios("POST", `${this.$phpBaseURL}v1/client/relatedAccount`, {
        client_company_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.zhList = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*关联销售*/
    getXs(id) {
      this.axios("POST", `${this.$phpBaseURL}v1/client/relatedSales`, {
        client_company_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.xsList = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*关联AM*/
    getAM(id) {
      this.axios("POST", `${this.$phpBaseURL}v1/client/relatedAm`, {
        client_company_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.AMList = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*打开eip*/
    openEip(id) {
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("get", `${this.$phpBaseURL}v1/client/${id}`, {
        token: JSON.parse(localStorage.getItem("user_info")).token,
      }).then((res) => {
        console.log(res.data);
        if (res.code == 1) {
          this.eipUrl = res.data.url;
          window.open(res.data.url, "_blank");
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*跳转合同详情*/
    toContractList(obj) {
      localStorage.setItem("client_company_name", obj.client_company_name);
      localStorage.setItem("firstToContractList", "1");

      this.$router.push({ path: "/contract", query: { stop: "/contract" } });
    },
    formatDate(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("客户列表", "1");
    },
  },
  filters: {
    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回未提交";
          break;
        case 6:
          text = "异常";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        case 6:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
  },
  created() {
    this.getAsleAndAm();
    if (this.$route.query.param) {
      this.ajaxDataSearch = JSON.parse(this.$route.query.param);
    }
    //获取url参数
    this.formm = this.$route.query.form;
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.init();
    this.getRelatedComList();
    if (this.formm != "login") {
      //频次统计
      this.timer = setTimeout(this.addStatistics, 5000);
    }
    //点击回车进行搜索
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
    if (localStorage.getItem("tableColumnVals")) {
      this.tableColumnVals = JSON.parse(
        localStorage.getItem("tableColumnVals")
      );
      this.tableColumns = this.tableColumns.map((item) => {
        item.value = this.tableColumnVals[item.id];
        return item;
      });
    }
  },
  computed: {
    curRouter() {
      return this.$store.state.curRouter;
    },
  },
  watch: {
    curRouter(val) {
      if (val) {
        this.showFlag = 1;
      }
    },
  },
  mounted() {
    this.common
      .getConfigData(
        "client_level,project_principal,project_authorization_list"
      )
      .then((res) => {
        this.configurationData = res.data;
      })
      .catch((err) => {
        this.$message({ message: err.msg, type: "warning" });
      });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
<style scoped lang="scss">
.m-r-16 {
  .main-button {
    margin-right: 16px;
  }
}

.tabel-operate {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(1, 172, 228, 1);
  line-height: 22px;
  cursor: pointer;
  margin-right: 8px;
}
.main-title .el-button--text {
  padding: 0 20px;
}

.main-selectBox .authperson {
  border: #01ace4 solid 1px;
  border-radius: 4px;
  padding: 0px 8px;
  margin-right: 16px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  box-sizing: border-box;
  color: #01ace4;
  margin-top: 4px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  // transition: all .3s;
}
.main-selectBox .authperson i {
  color: #adb3bc;
  font-size: 14px;
  position: absolute;
  right: -9px;
  top: -14px;
  cursor: pointer;
  display: none;
}
.main-selectBox .authperson:hover i {
  display: block !important;
}
</style>
<style lang="scss">
.company-trade {
  max-width: 400px !important;
  &.el-select-dropdown__item {
    min-height: 28px;
    line-height: 28px;
    margin-bottom: 10px;
    height: auto;
    word-break: break-all;
    white-space: normal;
  }
}
</style>