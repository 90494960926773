<template>
  <div class="wrapper">
    <head-vue
      :crumbs="crumbs"
      @navShrink="navShrink"
      @navExhibition="navExhibition"
      @headTo="headTo"
    />
    <section class="subject">
      <nav-vue @crumbs="getCrumbs" ref="retract"/>
      <div class="view-area">
        <router-view :key="$route.fullPath"/>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/index.js";
import moment from "moment";
import headVue from "./Head";
import navVue from "./Nav";
export default {
  name: "index",
  components: {
    headVue,
    navVue
  },
  data() {
    return {
      crumbs: ""
    };
  },
  computed: {
    lasttime() {
      return new Date().getTime();
    }
  },
  mounted() {},
  methods: {
    getCrumbs(crumbs) {
      this.crumbs = crumbs;
    },
    navShrink() {
      this.$refs.retract.navShrink();
    },
    navExhibition() {
      this.$refs.retract.navExhibition();
    },
    headTo(data) {
      this.$refs.retract.headTo(data);
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  .subject {
    height: calc(100% - 48px);
    display: flex;
    background: #f8f7fc;
    /*justify-content: space-between;*/
    .view-area {
      flex: 1;
      background: #f8f7fc;
      overflow-x: auto;
      margin-left: 3px;
      & > div {
        min-width: 1000px;
      }
    }
  }
}
</style>