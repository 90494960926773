import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import common from '@/assets/js/common';
import cm from '@/assets/js/cm';
import '@/assets/css/reset.css';
import filters from '@/filter';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/libs/pulic/js/tools";
import '@/assets/css/newElenemtUi.css';
import '@/assets/css/main.css';
Vue.use(ElementUI);

Vue.prototype.$layer = window.layer;

Vue.prototype.common = common;
Vue.prototype.$cm = cm;
// //请求接口

let base_url = "";
let php_base_url = "";
let java_base_url = "";
let panda_url = "";

var env_val = "dev"

if (env_val == 'pro') {
  Vue.prototype.doMainUrl = process.env.VUE_APP_API_URL_ZS;
  base_url = process.env.VUE_APP_API_URL_ZS;
  panda_url = process.env.VUE_APP_PANDA_PRO_URL;

} else if (env_val == 'dev') {
  Vue.prototype.doMainUrl = process.env.VUE_APP_API_URL;
  base_url = process.env.VUE_APP_API_URL;
  php_base_url = process.env.VUE_APP_PHP_API_URL;
  java_base_url = process.env.VUE_APP_JAVA_API_URL;
  panda_url = process.env.VUE_APP_PANDA_DEV_URL;

} else if (env_val == 'local') {
  base_url = '/api';
  panda_url = process.env.VUE_APP_PANDA_DEV_URL;
}

//请求接口
axios.defaults.baseURL = base_url;
// axios.defaults.phpBaseURL = php_base_url;
// axios.defaults.javaBaseURL = java_base_url;
Vue.prototype.$phpBaseURL = php_base_url + "bmp/api/";
Vue.prototype.$javaBaseURL = java_base_url + "bm/api/";
Vue.prototype.$panda_url = panda_url;

Vue.prototype.axios = common.axios;
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");