<template>
    <div class="main-div">
        <p class="main-title" style="margin-bottom: 32px">客户风险授权记录</p>
        <div>
            <div class="main-inputBox">
                <el-input v-model="ajaxDataSearch.clientCompanyName" placeholder="请输入客户公司" clearable @change="enterSearch">
                    <i class="el-icon-search el-input__icon" slot="suffix" @click="enterSearch"></i>
                </el-input>
            </div>
            <div class="main-inputBox">
                <el-input v-model="ajaxDataSearch.accountCompanyName" placeholder="请输入开户公司" clearable @change="enterSearch">
                    <i class="el-icon-search el-input__icon" slot="suffix" @click="enterSearch"></i>
                </el-input>
            </div>
            <div class="main-selectBox">
                <el-select v-model="ajaxDataSearch.status" @change="enterSearch" clearable placeholder="客户风险授权状态">
                    <el-option
                            v-for="(v,k) in configurationData.authorize_config"
                            :key="k"
                            :label="v"
                            :value="k">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-loading="loading">
            <el-table
                    :data="authList"
                    border
                    style="width: 100%">
                <template slot="empty">
                    <div class="nodata">
                        <img src="@/assets/img/nodata.png">
                        <span>暂无数据</span>
                    </div>
                </template>
                <el-table-column
                        prop=""
                        label="序号"
                        type="index"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="clientCompanyName"
                        label="客户公司"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="accountCompanyName"
                        label="开户公司"
                        width="">
                </el-table-column>
                <el-table-column
                        label="客户风险授权状态"
                        width="">
                    <template slot-scope="scope">
                        <span :class="scope.row.status | statusClassFilter">{{configurationData.authorize_config[scope.row.status]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="authorizeTime"
                        label="客户风险授权时间"
                        width="">
                </el-table-column>
            </el-table>
            <pagination-vue
                    @currentChange="init"
                    :pageSize="pageSize"
                    :total="total"
                    :firstPage="firstPage"
            />
        </div>
    </div>
</template>
<script>
    import paginationVue from '@/components/paginationVue.vue'
    import axios from 'axios';
    import store from '@/store/index.js';
    export default {
        name: '',
        components: {
            paginationVue
        },
        data () {
            return {
                firstPage:1,
                loading:true,
                timer:'',
                msg: '',
                pageSize: 15,
                total: 100,
                ajaxDataSearch: {
                    clientCompanyName: '',
                    accountCompanyName: '',
                    status: ''
                },
                authList: [],
                configurationData: {
                    authorize_config: {}
                }
            }
        },
        created(){
            console.log(localStorage.getItem('user_info'));
            if (!localStorage.getItem('user_info')) {
                this.$router.push('/');
                return                
            }
            axios.defaults.headers.common['language'] = store.state.lang;
            axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
            this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
                if (res.code == 1) {
                    this.configurationData = res.data;

                    this.init();
                    this.timer = setTimeout(this.addStatistics, 5000);
                } else if(res.code == 2){
                    //token过期处理
                    this.common.tokenExpired(res.msg);
                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }
            })

            //点击回车进行搜索
            let that=this;
            document.onkeypress = function(e) {
                var keycode = document.all ? event.keyCode : e.which;
                if (keycode == 13) {
                    that.init();
                    return false;
                }
            };
        },
        filters: {
            statusClassFilter(value) {
                //申请状态:1 已授权,2 未授权
                let m = '';
                switch (value) {
                    case 1:
                        m = 'shenpi-tongguo'
                        break;
                    case 2:
                        m = 'shenpi-bohui'
                        break;
                    default:
                        break;
                }
                return m
            },
        },
        methods: {
            init(page){
                this.loading=true;
                this.firstPage=page;
                clearTimeout(this.timer);

                let obj = this.ajaxDataSearch;
                obj.pageNum = page ? page : 1;
                obj.pageSize = 15;
                axios.defaults.headers.common['language'] = store.state.lang;
                axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
                this.axios('get', `${this.$javaBaseURL}authorize/list`, obj).then(res => {
                    this.loading=false;
                    if (res.code == 1) {
                        this.authList = res.data.list;
                        this.total = res.data.total;
                        this.pageSize = res.data.pageSize;
                    } else if(res.code == 20001){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            enterSearch(){
                this.firstPage=1;
                this.init();
            },

            //频次统计
            addStatistics() {
                this.value ++;
                this.common.statisticsAdd('客户风险授权记录列表','1')
            },
        },
        //mounted 挂载结束状态
        mounted() {
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .shouquan {
        padding: 3px 8px;
        color: rgba(38, 196, 184, 1);
        background: rgba(38, 196, 184, 0.15);

    }
</style>
