<template>
  <div class="main-div">
    <div v-show="showFlag==1">
      <p class="main-title">项目列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.project_name"
            clearable
            placeholder="请输入项目名称"
            @change="enterSearch"
          >
          </el-input>
        </div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.client_company_name"
            clearable
            placeholder="请输入客户名称"
            @change="enterSearch"
          >
          </el-input>
        </div>
        <div class="main-inputBox daterange date-picker-input-width">
          <el-date-picker
            v-model="ajaxDataSearch.project_created_at"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            clearable
            start-placeholder="创建开始日期"
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.business_type"
            @change="enterSearch"
            clearable
            placeholder="业务类型"
          >
            <el-option
              v-for="item in configurationData.business_type_array"
              :key="item.recid"
              :label="item.st_name"
              :value="item.recid"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.project_manager_id"
            @change="enterSearch"
            clearable
            placeholder="负责人"
          >
            <el-option
              v-for="item in configurationData.project_principal"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.dep_id"
            @change="enterSearch"
            clearable
            placeholder="归属部门"
          >
            <el-option
              v-for="item in configurationData.departments_data"
              :key="item.department_id"
              :label="item.department_name"
              :value="item.department_id"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.account_status"
            @change="enterSearch"
            clearable
            placeholder="panda账户管理平台状态"
          >
            <el-option
              v-for="(item,k) in configurationData.panda_account_status_config"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="main-btnBox">
        <div
          class="main-button"
          v-show="common.permFilter(`${common.php}v1/project/getNewProject`)"
          @click="common.openEip('project/getNewProject')"
        >新建项目</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/project/getProjectApproval`)"
          @click="common.openEip('project/getProjectApproval')"
        >项目审批</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/project/getProjectCaseClosed`)"
          @click="common.openEip('project/getProjectCaseClosed')"
        >项目结案</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/project/getProjectChangeApplication`)"
          @click="common.openEip('project/getProjectChangeApplication')"
        >项目变更申请</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/project/getProjectChangeProcessing`)"
          @click="common.openEip('project/getProjectChangeProcessing')"
        >项目变更处理</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/project/getProjectClosed`)"
          @click="common.openEip('project/getProjectClosed')"
        >项目关闭</div>
      </div>
      <div>
        <el-table v-loading="loading" :data="projectList" border height="550" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column width="50px" label="序号" type="index" ></el-table-column>
          <el-table-column prop="project_numbering" label="项目编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="项目名称" prop="project_name" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="panda账户管理平台账户名称" width="220px" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.account}}</span>
            </template>
          </el-table-column>
          <el-table-column label="panda账户管理平台账户状态" width="220px">
            <template slot-scope="scope">
              <span>{{scope.row.account_status_show}}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.client_company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目负责人">
            <template slot-scope="scope">
              <span>{{scope.row.project_manager_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="sales_name" label="销售人员"></el-table-column>
          <el-table-column prop label="归属部门" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.department_name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="审批状态">
            <template
              slot-scope="scope"
            >{{scope.row.approval_status == 2 ? '审批通过' : (scope.row.approval_status == 1?'待审批':(scope.row.approval_status == 5?'驳回未提交':'未提交'))}}</template>
          </el-table-column>
          <el-table-column prop label="操作" width="200px">
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                v-show="common.permFilter(`${common.php}v1/project/detail`)"
                @click="showFlag=2;getInfo(scope.row.project_id)"
              ><i title="详情" style="font-size:20px;" class="iconfont gzticon_jishiben-"></i></span>
              <span
                class="main-tableToInfo"
                v-show="scope.row.account_total==0 && scope.row.approval_status==2 && common.permFilter(`${common.php}v1/project/openAccount`)"
                @click="openZh(scope.row)"
              ><i title="开通panda账户管理平台账户" style="font-size:20px;" class="iconfont gztyunzhanghu"></i></span>
              <span
                class="main-tableToInfo"
                v-show="scope.row.account_total>0 && scope.row.approval_status==2 && common.permFilter(`${common.php}v1/project/editAccount`)"
                @click="editZhPop=true;editZh(scope.row)"
              ><i title="编辑panda账户管理平台账户" style="font-size:18px;" class="iconfont gztzhanghuguanli"></i></span>
              <span
                class="main-tableToInfo"
                v-show="scope.row.approval_status==2 && scope.row.account_total>0 && scope.row.account_status == 1"
                @click="stopStart(scope.row)"
              ><i title="停用" class="iconfont gzttingyong"></i></span>
              <span
                class="main-tableToInfo"
                v-show="scope.row.approval_status==2 && scope.row.account_total>0 && scope.row.account_status == 2"
                @click="stopStart(scope.row)"
              ><i title="恢复" class="iconfont gztqiyong"></i></span>
              <span
                class="main-tableToInfo"
                v-show="scope.row.approval_status==2"
                @click="applyAds(scope.row)"
              ><i title="广告账户开户申请" class="iconfont gztguanggao"></i></span>
              <a
                style="text-decoration: none"
                class="main-tableToInfo"
                v-show="scope.row.approval_status==2 && scope.row.account_total>0 && scope.row.is_login==1 && common.permFilter(`${common.php}v1/project/autologin`)"
                @click="seeShare(scope.row.project_id)"
                target="_black"
              ><i title="快捷登录" class="iconfont gzticon-shortcut"></i></a>
              <span
                class="main-tableToInfo"
                v-show="scope.row.approval_status==2 && scope.row.account_total>0 && common.permFilter(`${common.php}v1/project/editAccount`)"
                @click="getAuth(scope.row)"
                target="_black"
              ><i title="登录授权" style="font-size:20px;" class="iconfont gztshouquan"></i></span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag==2">
      <p class="main-title">项目编号:{{info.basic_data.project_numbering}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">客户名称:</p>
            <div class="info-text calc-4w">{{info.basic_data.client_company_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">项目名称:</p>
            <div class="info-text calc-4w">{{info.basic_data.project_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">业务类型:</p>
            <div class="info-text calc-4w">
              <template v-for="v in configurationData.business_type_array">
                <span
                  v-if="v.recid == info.basic_data.business_type"
                  :title="v.st_name"
                >{{v.st_name}}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">归属部门:</p>
            <div class="info-text calc-4w">{{info.basic_data.department_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">项目负责人:</p>
            <div class="info-text calc-5w">{{info.basic_data.project_manager_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">销售人员:</p>
            <div class="info-text calc-5w">{{info.basic_data.sales_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-100">
            <p class="info-title t-9w">附件内容：</p>
            <div class="info-text calc-9w">
              <div
                class="main-button download-self"
                v-show="common.permFilter(`${common.php}v1/project/projectDownload`)"
                @click="downloadfile()"
                style="margin-bottom:16px;"
              >
                <i class="gongzuotai gztxiazai"></i>
                下载
              </div>
              <el-table :data="info.project_file" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="150"></el-table-column>
                <el-table-column prop="file_name" label="文件" width></el-table-column>
                <el-table-column prop label="文件大小" width>
                  <template
                    slot-scope="scope"
                    v-if="scope.row.file_size"
                  >{{(scope.row.file_size / 1024 / 1024).toFixed(2)}}MB</template>
                  <template v-else>-</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-80">
            <p class="info-title t-2w">备注:</p>
            <div class="info-text calc-2w">{{info.basic_data.remark}}</div>
          </div>
        </div>
        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <p class="info-title t-2w"></p>
            <div class="info-text calc-2w">
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showFlag==3">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="Facebook" name="1">
          <el-button size="mini" type="primary" @click="goFacebook">跳转到Facebook的OE链接开户</el-button>
          <el-button type="text" @click.prevent="copy(facebookUrl)">复制链接</el-button>
        </el-tab-pane>
        <el-tab-pane label="Twitter" name="2">
          <el-form
            label-position="left"
            style="width:810px"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="申请时间">
              <el-date-picker
                type="datetime"
                disabled
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期"
                v-model="applicationData.applicationTime"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="开户数量" required>
              <el-input v-model="applicationData.accountOpenQuantity" type="number" min="0"></el-input>
            </el-form-item>
            <el-form-item label="默认充值金额" required>
              <el-input v-model="applicationData.defaultRecharge" type="number" min="5000"></el-input>
            </el-form-item>
            <el-form-item label="开户公司（英文）" required>
              <el-input v-model="applicationData.accountCompanyEnglishName"></el-input>
            </el-form-item>
            <el-form-item label="公司地址（英文）" required>
              <el-input v-model="applicationData.accountCompanyEnglishAddress"></el-input>
            </el-form-item>
          </el-form>
          <el-form
            label-position="left"
            :inline="true"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="客户类别" required>
              <el-select
                style="width:250px;"
                v-model="applicationData.clientType"
                clearable
                placeholder="客户类别"
              >
                <el-option
                  v-for="(item,k) in TWClientType"
                  :key="k"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input style="width:250px;" v-model="applicationData.contactPerson"></el-input>
            </el-form-item>
          </el-form>
          <el-form
            label-position="left"
            :inline="true"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="联系方式">
              <el-select
                style="width:250px;"
                v-model="applicationData.contactInformation"
                clearable
                placeholder="联系方式"
              >
                <el-option
                  v-for="(item,k) in configurationData.project_status_array"
                  :key="k"
                  :label="item"
                  :value="k"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品链接" required>
              <el-input v-model="applicationData.productLink"></el-input>
              <p
                style="width:250px;font-size:12px;line-height: 16px;margin-top: 10px;color: #ddd;"
              >手游:请提供Google Play/App store;APP类:请提供Google Play/App store;电商类:请提供网站链接或者亚马逊店铺链接,产品搜索结果链接无法提交开户</p>
            </el-form-item>
          </el-form>
          <el-form
            label-position="left"
            :inline="true"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="粉丝页链接" required>
              <el-input v-model="applicationData.pageLink"></el-input>
              <p
                style="width:250px;font-size:12px;line-height: 16px;margin-top: 10px;color: #ddd;"
              >若要开在广告主粉丝页下，请填入粉丝页链接，没有填入的默认自建粉丝页。Twitter粉丝页面需与推广网站或产品匹配</p>
            </el-form-item>
            <el-form-item label="官网链接" required>
              <el-input v-model="applicationData.officialWebsiteLink"></el-input>
              <p
                style="width:250px;font-size:12px;line-height: 16px;margin-top: 10px;color: #ddd;"
              >区别与产品链接和粉丝页链接,是该产品或公司的官方网站链接,官网为简体中文无法开户</p>
            </el-form-item>
          </el-form>
          <el-form
            label-position="left"
            :inline="true"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="投放目标" required>
              <el-checkbox-group
                v-model="applicationData.serviceTargetId"
                style="display: flex;flex-direction: column;width:250px;"
              >
                <el-checkbox :label="1">Brand Awarenes</el-checkbox>
                <el-checkbox :label="2">App Installs</el-checkbox>
                <el-checkbox :label="3">Drive Traffic to Website</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="营业执照">
              <el-upload
                class="avatar-uploader"
                :headers="uploaderHeader"
                :action="common.java+'open/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img
                  v-if="applicationData.businessLicenseLink"
                  :src="applicationData.businessLicenseLink"
                  class="avatar"
                >
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <p
                style="width:250px;font-size:12px;line-height: 16px;margin-top: 10px;color: #f00;"
              >注：请随表附上贵公司营业执照扫描件一份（或其他资质证明）</p>
            </el-form-item>
          </el-form>
          <el-form
            label-position="left"
            :inline="true"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="预计出价" required>
              <el-input v-model="applicationData.estimatedBid" type="number"></el-input>
              <p
                style="width:250px;font-size:12px;line-height: 16px;margin-top: 10px;color: #ddd;"
              >For example, desired price is (1) USD 5.50 per install OR (2) USD 2 per website click OR (3)Your answer</p>
            </el-form-item>
            <el-form-item label="投放国家" required>
              <el-select
                filterable
                v-model="applicationData.serviceCountry"
                clearable
                placeholder="投放国家"
              >
                <el-option
                  v-for="(item,k) in countryList"
                  :key="k"
                  :label="item.countryName"
                  :value="item.code"
                ></el-option>
              </el-select>
              <p
                style="width:250px;font-size:12px;line-height: 16px;margin-top: 10px;color: #ddd;"
              >区别与产品链接和粉丝页链接,是该产品或公司的官方网站链接,官网为简体中文无法开户</p>
            </el-form-item>
          </el-form>
          <el-form
            style="width:810px"
            label-position="left"
            :model="applicationData"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="投放方式" required>
              <el-select v-model="applicationData.serviceType" clearable placeholder="投放方式">
                <el-option
                  v-for="(item,k) in serviceTypeList"
                  :key="k"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="投放时区" required>
              <el-select v-model="applicationData.timeZone" clearable placeholder="投放时区">
                <el-option
                  v-for="(item,k) in timeZoneList"
                  :key="k"
                  :label="item.timeZone"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" required>
              <el-input v-model="applicationData.remarks"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitApply(2)">提交审核</el-button>
              <el-button @click="showFlag = 1">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Google" name="3">
          <el-form
            style="width:810px"
            label-position="left"
            :model="googleapplydata"
            size="mini"
            label-width="150px"
          >
            <el-form-item label="申请时间">
              <el-date-picker
                type="datetime"
                disabled
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期"
                v-model="googleapplydata.applicationTime"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="开户数量" required>
              <el-input type="number" min="0" v-model="googleapplydata.accountOpenQuantity"></el-input>
            </el-form-item>
            <el-form-item label="账户命名" required>
              <el-input v-model="googleapplydata.accountNamestr" placeholder="请输入账户命名，以中文逗号分割"></el-input>
            </el-form-item>
            <el-form-item label="开户时区" required>
              <el-select v-model="googleapplydata.timeZone" clearable placeholder="开户时区">
                <el-option
                  v-for="(item,k) in timeZoneList"
                  :key="k"
                  :label="item.timeZone"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="币种" required>
              <el-select v-model="googleapplydata.currency" clearable placeholder="币种">
                <el-option
                  v-for="(item,k) in currencyList"
                  :key="k"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitApply(3)">提交审核</el-button>
              <el-button @click="showFlag = 1">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--项目状态弹窗-->
    <div class="main-pop" v-show="editStatusPop">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>项目状态</span>
          <i class="gongzuotai gztguanbi" @click="editStatusPop=false"></i>
        </div>
        <div class="body" style="padding-right:0px">
          <p class="info-title t-4w" style="line-height: 32px">项目状态:</p>
          <div class="main-selectBox" style="width:394px;">
            <el-select v-model="project_status" clearable placeholder="项目状态">
              <el-option
                v-for="(item,k) in configurationData.project_status_array"
                :key="k"
                :label="item"
                :value="k"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="editStatusPop=false">取消</button>
          <button class="main-button r" @click="editStatusPop=false;doEditStatus()">确定</button>
        </div>
      </div>
    </div>
    <!--开通账户弹窗-->
    <div class="main-pop openZhPop" v-show="openZhPop">
      <div class="mask"></div>
      <div class="center" style="width:696px;max-height: 835px">
        <div class="head">
          <span>开通panda账户管理平台账户</span>
          <i class="gongzuotai gztguanbi" @click="openZhPop=false"></i>
        </div>
        <div class="body" style="max-height: 550px;">
          <div class="w-50 inline top">
            <p class="info-title t-4w" style="line-height: 10px">
              账户名
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <!-- <el-input required v-model="accountInfo.account_info.account" placeholder="系统生成" > -->
                <el-input required v-model="ajaxDataOpen.account" placeholder="8~20位字母和数字组成"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              最大开户数量
              <span style="color:red"></span>:
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="ajaxDataOpen.max_number_accounts"
                  placeholder="请输入最大开户数量"
                  type="number"
                ></el-input>
              </div>
            </div>
            <div style="margin-bottom: 24px">
              <template v-if="editingProObj.level_name != '其他'">
                <el-radio
                  v-model="ajaxDataOpen.is_prepaid_recharge"
                  @change="isPre=true"
                  label="1"
                >预付</el-radio>
                <el-radio
                  v-model="ajaxDataOpen.is_prepaid_recharge"
                  @change="isPre=false"
                  label="2"
                >授信</el-radio>
              </template>
              <template v-if="editingProObj.level_name == '其他'">
                <el-radio v-model="ajaxDataOpen.is_prepaid_recharge" label="1">预付</el-radio>
                <el-radio v-model="ajaxDataOpen.is_prepaid_recharge" label="2" disabled>授信</el-radio>
              </template>
            </div>
            <p class="info-title" style="line-height: 10px">
              分配授信金额:
              <span style="color:red" v-show="isPre==false">*</span>
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="ajaxDataOpen.distribution_credit_amount"
                  type="number"
                  placeholder
                  v-if="editingProObj.level_name != '其他' && !isPre"
                ></el-input>
                <el-input
                  v-model="ajaxDataOpen.distribution_credit_amount"
                  type="number"
                  placeholder
                  disabled
                  v-else-if="editingProObj.level_name != '其他' && isPre"
                ></el-input>

                <el-input
                  v-model="ajaxDataOpen.distribution_credit_amount"
                  type="number"
                  placeholder
                  disabled
                  v-if="editingProObj.level_name == '其他'"
                ></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">项目:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.project_name " disabled placeholder></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              销售
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select v-model="ajaxDataOpen.sales_id" clearable placeholder="请选择销售">
                  <el-option
                    v-for="(v,k) in configurationData2"
                    :key="v.id"
                    :label="v.name"
                    :value="v.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              AM
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select v-model="ajaxDataOpen.am_id" clearable placeholder="请选择AM">
                  <el-option
                    v-for="item in accountInfo.am_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="w-50 inline top">
            <p class="info-title t-4w" style="line-height: 10px">密码:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  type="password"
                  v-model="accountInfo.account_info.account_password"
                  placeholder="系统生成"
                  disabled
                ></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              每日开户上限
              <span style="color:red"></span>:
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="ajaxDataOpen.daily_account_opening_limit"
                  type="number"
                  placeholder="请输入每日开户上限"
                ></el-input>
              </div>
            </div>
            <p
              class="info-title"
              style="height: 18px;line-height: 18px;margin-right: 16px;margin-bottom: 24px"
            >客户可用授信额度:</p>
            {{editingProObj.level_consume_value}}
            <br>
            <p class="info-title" style="line-height: 10px">
              优化师
              <span style="color:red">*</span>
            </p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select v-model="ajaxDataOpen.optimizer_id" clearable placeholder="请选择优化师">
                  <el-option
                    v-for="item in accountInfo.optimizer_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              邮箱地址
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="ajaxDataOpen.email_address" placeholder="请输入邮箱地址"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="openZhPop=false">取消</button>
          <button class="main-button r" @click="doOpenZh()">确定</button>
        </div>
      </div>
    </div>
    <!--编辑账户弹窗-->
    <div class="main-pop openZhPop" v-show="editZhPop">
      <div class="mask"></div>
      <div class="center" style="width:696px;max-height: 835px">
        <div class="head">
          <span>编辑panda账户管理平台账户</span>
          <i class="gongzuotai gztguanbi" @click="editZhPop=false"></i>
        </div>
        <div class="body" style="max-height: 550px;">
          <div class="w-50 inline top">
            <p class="info-title t-4w" style="line-height: 10px">账户名:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="accountInfo.account_info.account" disabled placeholder="系统生成"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              最大开户数量
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="accountInfo.account_info.max_number_accounts"
                  placeholder="请输入最大开户数量"
                  type="number"
                ></el-input>
              </div>
            </div>
            <div style="margin-bottom: 24px">
              <template>
                <el-radio
                  v-model="accountInfo.account_info.is_prepaid_recharge"
                  :label="1"
                  disabled
                >预付</el-radio>
                <el-radio
                  v-model="accountInfo.account_info.is_prepaid_recharge"
                  :label="2"
                  disabled
                >授信</el-radio>
              </template>
            </div>
            <p class="info-title" style="line-height: 10px">分配授信金额:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="accountInfo.account_info.distribution_credit_amount"
                  type="number"
                  placeholder
                  v-if="editingProObj.level_name != '其他' && accountInfo.account_info.is_prepaid_recharge==2"
                ></el-input>
                <el-input
                  v-model="accountInfo.account_info.distribution_credit_amount"
                  type="number"
                  placeholder
                  disabled
                  v-else-if="editingProObj.level_name != '其他' && accountInfo.account_info.is_prepaid_recharge==1"
                ></el-input>

                <el-input
                  v-model="accountInfo.account_info.distribution_credit_amount"
                  type="number"
                  placeholder
                  disabled
                  v-if="editingProObj.level_name == '其他'"
                ></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">项目:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.project_name " disabled placeholder></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              销售
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select
                  v-model="accountInfo.account_info.sales_id"
                  clearable
                  placeholder="请选择销售"
                >
                  <el-option
                    v-for="(v,k) in configurationData2"
                    :key="v.id"
                    :label="v.name"
                    :value="v.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">
              AM
              <span style="color:red">*</span>:
            </p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select v-model="accountInfo.account_info.am_id" clearable placeholder="请选择AM">
                  <el-option
                    v-for="item in accountInfo.am_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!--<p class="info-title" style="line-height: 10px">邮箱地址:</p><br>
                        <div>
                            <div class="main-inputBox">
                                <el-input v-model="accountInfo.account_info.email_address" placeholder="发送通知（账户名、密码）到对应邮箱" >
                                </el-input>
                            </div>
            </div>-->
          </div>
          <div class="w-50 inline top">
            <p class="info-title t-4w" style="line-height: 10px">密码:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="accountInfo.account_info.account_password"
                  type="password"
                  placeholder="系统生成"
                  disabled
                ></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">每日开户上限:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="accountInfo.account_info.daily_account_opening_limit"
                  type="number"
                  placeholder
                ></el-input>
              </div>
            </div>
            <p
              class="info-title"
              style="height: 18px;line-height: 18px;margin-right: 16px;margin-bottom: 24px"
            >客户可用授信额度:</p>
            {{accountInfo.account_info.credit_amount}}
            <br>
            <!-- <p class="info-title" style="line-height: 10px">合同:</p><br>
                        <div>
                            <div class="main-inputBox">
                                <el-input v-model="editingProObj.contract_name" disabled placeholder="" >
                                </el-input>
                            </div>
                        </div>
                        <p class="info-title" style="line-height: 10px">合同有效期:</p><br>
                        <div>
                            <div class="main-inputBox">
                                <el-input :value="accountInfo.account_info.contract_start_time +' ~ '+accountInfo.account_info.contract_end_time" disabled placeholder="" >
                                </el-input>
                            </div>
            </div>-->
            <!--<p class="info-title" style="line-height: 10px">前端:</p><br>
                        <div>
                            <div class="main-selectBox" style="width: 290px">
                                <el-select v-model="accountInfo.account_info.front_end_id" clearable  placeholder="请选择前端">
                                    <el-option
                                            v-for="item in accountInfo.front_end_list"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
            </div>-->
            <p class="info-title" style="line-height: 10px">优化师:</p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select
                  v-model="accountInfo.account_info.optimizer_id"
                  clearable
                  placeholder="请选择优化师"
                >
                  <el-option
                    v-for="item in accountInfo.optimizer_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">邮箱地址:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input
                  v-model="accountInfo.account_info.email_address"
                  placeholder="发送通知（账户名、密码）到对应邮箱"
                ></el-input>
              </div>
            </div>
            <!--<p class="info-title" style="line-height: 10px">&nbsp;</p><br>-->
            <div class="main-button" @click="sendEm()">发送邮件</div>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="editZhPop=false">取消</button>
          <button class="main-button r" @click="doEditZh()">确定</button>
        </div>
      </div>
    </div>
    <!-- 登录授权 -->
    <div class="main-pop openZhPop" v-show="authPop">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span>登录授权</span>
          <i class="gongzuotai gztguanbi" @click="authPop=false"></i>
        </div>
        <div class="body" style="padding-right:0px;padding-bottom:80px">
          <p class="info-title t-4w" style="line-height: 32px">已授权:</p>
          <div class="main-selectBox" style="width:394px;">
            <span class="authperson" v-for="item in authPerson">
              {{item.name}}
              <i class="gongzuotai gztchahao" @click="delAuthPerson(item)"></i>
            </span>
          </div>
          <p class="info-title t-4w" style="line-height: 32px">被授权人:</p>
          <div class="main-selectBox" style="width:394px;">
            <el-select v-model="user_id" clearable filterable placeholder="请选择">
              <el-option
                v-for="(item,index) in configurationData.project_authorization_list"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="authPop=false">取消</button>
          <button class="main-button r" @click="confirmAuth()">确定</button>
        </div>
      </div>
    </div>
    <!--停用启用弹窗-->
    <div class="main-pop" v-show="delPop">
      <div class="mask"></div>
      <div class="center" style="width:250px;">
        <div class="head">
          <span style="font-size: 24px">提示</span>
          <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
        </div>
        <div
          class="body"
          style="margin: auto"
        >{{operate_item.account_status == 1 ? '确定要停用该项目的超级账户?' : '确定要恢复该项目的超级账户?'}}</div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="delPop=false">取消</button>
          <button class="main-button r" @click="confirmOpe()">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import moment from "moment";
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      activeTabName: "1",
      operate_item: {},
      delPop: false,
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 0,
      user_id: "", //选中的被授权人
      ajaxDataSearch: {
        account_status: "",
        project_name: "", //否	string	项目名称；如：今日头条-cscs
        client_company_name: "", //否	string	客户名称；如：今日头条-cscs
        project_created_at: ["", ""], //否	string	项目创建时间；如：2020-04-24
        business_type: "", //是	int	业务类(1:整合营销,2:媒介投放,3:技术产品)；如：1
        project_manager_id: "", //是	int	项目负责人即客户负责人；如：1
        dep_id: "", //否	string	部门ID；如：968485f47cc0b7ac7cf36d4868689866
        approval_status: "", //否	int	审批状态(1:待审批,2:审批通过,3:审批失败)
        status: "" //否	int	合同状态(1:正常,2:暂停,3:延期,4:取消)
      },
      optionsTrade: [],
      optionsStatus: [
        {
          value: "1",
          label: "待审核"
        },
        {
          value: "2",
          label: "审核通过"
        },
        {
          value: "3",
          label: "审核失败"
        }
      ],
      projectList: [],
      projectSpeedLists: [
        {
          amount_income: 0.0,
          cost_amount: 0.0,
          paid_outgoing_amount: 0.0,
          settlement_amount_received: 0.0
        }
      ],
      payOutCostIsTrue: true,
      projectCostIsTrue: true,
      info: {
        basic_data: {
          project_numbering: "vrg34gtrht",
          project_id: "wefew43yt45h",
          project_name: "cdcdcd",
          business_type: 1,
          project_manager_id: "4F81D19F600000011924017E51357265",
          approval_status: 0,
          status: 0,
          sales_id: "4F81D19F600000011924017E51357265",
          project_created_at: "2020-04-23 16:33:18",
          cycle_start: "2020-04-23",
          cycle_end: "2020-04-23",
          applicant_id: "4F81D19F600000011924017E51357265",
          remark: "cscs",
          customer_product_type: "vsvs",
          project_overview: "sssss",
          date: "2020-04-23",
          estimated_income: "0.00",
          estimated_cost: "0.00",
          estimated_outsourcing_cost: "0.00",
          project_cost: "0.00",
          estimated_gross_profit: "0.00",
          estimated_gross_margin: "0.00",
          estimated_taxe: "0.00",
          estimated_after_tax: "0.00",
          amount_income: "0.00",
          cost_amount: "0.00",
          paid_outgoing_amount: "0.00",
          settlement_amount_received: "0.00",
          change_ratio: "0.00",
          client_company_name: "宇宙company",
          industry_name:
            "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等",
          contract_name: null,
          department_name: null,
          contact_name: null,
          project_manager_name: "王凯",
          sales_name: "王凯",
          applicant_name: "王凯"
        },
        project_member: [
          {
            member_id: "dgerg45yrherg432",
            dep_id: "efdsvsdf2",
            department_name: "部门名称cs",
            user_name: "王玉影_cscs"
          }
        ],
        expected_pay_out_cost_details: [
          {
            purchasing_channel: "1",
            supplier_name: "1",
            business_content: "1",
            business_cycle: "1",
            purchase_amount: "1.00",
            remark: "1"
          }
        ],
        expected_project_cost_details: [
          {
            project_cost_content: "1",
            purchase_amount: "1.00",
            remark: "1"
          }
        ],
        project_schedule_happening: [
          {
            amount_income: "0.00",
            cost_amount: "0.00",
            paid_outgoing_amount: "0.00",
            settlement_amount_received: "0.00",
            change_ratio: "0.00"
          }
        ],
        project_tender_file: [
          {
            file_name: "cs",
            file_size: 0
          }
        ],
        project_meeting_summary: [],
        project_purchase_acceptance_file: [],
        project_case_report_file: [],
        project_case_file: [],
        project_file: [
          {
            file_name: "cs",
            file_size: 0
          }
        ]
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      },
      configurationData2: {},
      editStatusPop: false,
      project_id: "",
      project_status: "",
      openZhPop: false,
      radio: "1",
      isPre: true,
      editingProObj: {
        project_id: "fwef2fre",
        project_name: "今日头条-cscs",
        business_type: 1,
        project_manager_id: "dgerg45yrherg432",
        approval_status: 1,
        status: 1,
        sales_id: "dgerg45yrherg432",
        project_created_at: "2020-04-20 16:31:44",
        project_cycle: "",
        applicant_id: "dgerg45yrherg432",
        remark: "cs",
        client_company_name: "client_company--cscs",
        level_id: "耗费iuwehiw76gfie",
        level_name: "其他",
        level_consume_value: "一事一议",
        industry_name: "cs",
        contract_name: "合同名称cs",
        contract_id: "扫地yiewbieb",
        contract_start: "2020-04-16 16:50:35",
        contract_end: "2020-04-16 16:50:35",
        name: "cs",
        contact_name: "contact_name--cscs",
        status_name: "正常",
        approval_status_name: "",
        business_type_name: "整合营销",
        project_manager_name: "王玉影_cscs",
        sales_name: "王玉影_cscs",
        applicant_name: "王玉影_cscs",
        is_change: "1",
        project_numbering: "dsifh8273yfgf"
      },
      accountInfo: {
        account_info: [
          {
            account: "这是平台账号1",
            max_number_accounts: "",
            daily_account_opening_limit: "",
            is_prepaid_recharge: "0",
            credit_amount: "",
            distribution_credit_amount: "0.00",
            sales_id: "",
            front_end_id: "",
            am_id: "",
            optimizer_id: "",
            contract_start_time: null,
            contract_end_time: null,
            email_address: ""
          }
        ],
        project_principal: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        sales_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        front_end_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        am_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        optimizer_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ]
      },
      ajaxDataOpen: {
        project_id: "", //	是	string	项目ID；如：968485f47cc0b7ac7cf36d4868689866
        account: "", //	是	string	账户；如：36d4868689866
        account_password: "", //	是	string	密码；如：968485f47cc0
        max_number_accounts: "", //	是	int	最大开户数量；如：1
        daily_account_opening_limit: "", //	是	int	每日开户上限；如：1
        is_prepaid_recharge: "1", //	是	int	是否是预付；如：1
        credit_amount: "", //	是	int	客户可用授信额度；如：1
        distribution_credit_amount: "", //	是	int	分配授信额度；如：1
        contract_id: "", //	是	string	合同ID；如：968485f47cc0b7ac7cf36d4868689866
        contract_start_time: "", //	是	string	合同有效期开始时间；如：2020-04-28
        contract_end_time: "", //	是	string	合同有效期结束时间；如：2020-04-28
        sales_id: "", //	是	int	销售ID；如：1
        //                    front_end_id:'',//	是	int	前端ID；如：1
        am_id: "", //	是	int	AMID；如：1
        optimizer_id: "", //	是	int	优化师ID；如：1
        email_address: "" //	是	string	邮箱地址；如：9023878237@qq.com
      },
      editZhPop: false,
      authPop: false, //授权弹框
      project_id: "", //选中的项目ID
      selRow: {}, //选中的行
      authPerson: [], //登录授权弹框--被授权人
      applicationData: {
        openListId: "",
        platform: "",
        applicationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        serviceType: "",
        isFirst: "",
        amId: "",
        accountOpenQuantity: "",
        timeZone: "",
        isRechargeAmount: "",
        remarks: "",
        accountCompanyChineseName: "",
        accountCompanyEnglishName: "",
        firstClassAgent: "",
        accountCompanyBmId: "",
        accountCompanyIndustry: "",
        advertiserIndustry: "",
        clientName: "",
        clientEmail: "",
        officialWebsiteLink: "",
        isSecondaryAgent: "",
        secondaryAgentBmId: "",
        businessLicenseNumber: "",
        accountCompanyChineseAddress: "",
        accountCompanyEnglishAddress: "",
        accountCompanyPostalCode: "",
        pageLink: "",
        pageId: "",
        verification: "",
        productLink: "",
        promoteObject: "",
        businessLicenseLink: "",
        isRecharge: "",
        rechargeRemarks: "",
        defaultRecharge: 5000,
        clientType: "",
        contactPerson: "",
        contactInformation: "",
        serviceTargetId: [],
        estimatedBid: "",
        serviceCountry: "",
        accountBudget: "",
        agentId: "",
        isContainMcc: "",
        currency: "",
        registrationArea: "",
        bytedanceEmail: "",
        bytedancePassword: "",
        accountOpeningRemarks: "",
        isAgree: "",
        accountName: ""
      },
      googleapplydata: {
        applicationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        accountOpenQuantity: "",
        accountNamestr: "",
        timeZone: "",
        currency: ""
      },
      currencyList: [
        {
          label: "美元",
          value: "1"
        },
        {
          label: "人民币",
          value: "2"
        },
        {
          label: "港元",
          value: "3"
        }
      ],
      timeZoneList: [],
      facebookUrl: "",
      TWClientType: [],
      countryList: [],
      serviceTypeList: [
        {
          label: "自投",
          value: "1"
        },
        {
          label: "代投",
          value: "2"
        }
      ],
      applyadsData: {},
      uploaderHeader: {}
    };
  },
  computed: {
    curRouter() {
      return this.$store.state.curRouter;
    }
  },
  watch: {
    curRouter(val) {
      if (val) {
        this.showFlag = 1;
      }
      console.log("val:", val);
    }
  },

  filters: {
    formatTime(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },

    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回未提交";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
    statusProjectClassFilter(value) {
      // 项目状态(1:正常,2:暂停,3:延期,4:取消
      let m = "";
      switch (value) {
        case 1:
          m = "status-zhengchang";
          break;
        case 2:
          m = "status-zanting";
          break;
        case 3:
          m = "status-yanqi";
          break;
        case 4:
          m = "status-quxiao";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.uploaderHeader.language = store.state.lang;
    this.uploaderHeader.token = JSON.parse(
      localStorage.getItem("user_info")
    ).token;

    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {
      type: "project_principal,project_authorization_list"
    }).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;

        this.search_item_data();

        this.init();

        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
    this.getTimeZoneList();
    this.getTWClientType();
    this.getCountrys();
  },
  methods: {
    // 复制
    copy(text) {
      var textArea = document.createElement("textarea");
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$message({
            message: "复制成功",
            type: "success"
          });
        }
      } catch (err) {
        console.log("err:", err);
      }

      document.body.removeChild(textArea);
    },
    //跳转连接
    goFacebook() {
      window.open(this.facebookUrl, "_blank");
    },
    // 获取时区
    getTimeZoneList() {
      this.axios("GET", `${this.$javaBaseURL}open/getTimeZone`).then(res => {
        if (res.code == 1) {
          this.timeZoneList = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 获取国家
    getCountrys() {
      this.axios("GET", `${this.$javaBaseURL}open/getCountry`).then(res => {
        if (res.code == 1) {
          this.countryList = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 获取twitter客户类别
    getTWClientType() {
      this.axios("GET", `${this.$javaBaseURL}open/getTWClientType`).then(res => {
        if (res.code == 1) {
          this.TWClientType = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 申请开户按钮
    submitApply(value) {
      var postdata = {};
      this.googleapplydata.accountName = [];
      if (value == 3) {
        // google申请
        this.googleapplydata.platform = 3;
        this.googleapplydata.projectId = this.applyadsData.project_id;
        this.googleapplydata.clientCompanyId = this.applyadsData.client_company_id;
        if (
          this.googleapplydata.accountNamestr.indexOf("，") > -1 &&
          this.googleapplydata.accountNamestr.length > 0
        ) {
          this.googleapplydata.accountName = this.googleapplydata.accountNamestr.split(
            "，"
          );
        } else if (
          this.googleapplydata.accountNamestr.length > 0 &&
          this.googleapplydata.accountNamestr.indexOf("，") == -1
        ) {
          this.googleapplydata.accountName.push(
            this.googleapplydata.accountNamestr
          );
        } else {
          this.googleapplydata.accountName = [];
        }

        if (this.googleapplydata.accountOpenQuantity < 1) {
          this.$message({ message: "请输入开户数量", type: "warning" });
          return;
        } else if (
          this.googleapplydata.accountName < 1 ||
          this.googleapplydata.accountName.length ==
            this.googleapplydata.accountName
        ) {
          this.$message({
            message: "请输入与开户数量相同值的账户命名",
            type: "warning"
          });
          return;
        } else if (this.googleapplydata.timeZone == "") {
          this.$message({ message: "请选择开户时区", type: "warning" });
          return;
        } else if (this.googleapplydata.currency == "") {
          this.$message({ message: "请选择币种", type: "warning" });
          return;
        } else {
          postdata = this.googleapplydata;
        }
      } else {
        // twitter申请
        this.applicationData.platform = 2;
        this.applicationData.projectId = this.applyadsData.project_id;
        this.applicationData.clientCompanyId = this.applyadsData.client_company_id;
        if (this.applicationData.accountOpenQuantity < 1) {
          this.$message({ message: "请输入开户数量", type: "warning" });
          return;
        } else if (this.applicationData.defaultRecharge < 5000) {
          this.$message({
            message: "请输入默认充值金额且大于5000",
            type: "warning"
          });
          return;
        } else if (this.applicationData.accountCompanyEnglishName == "") {
          this.$message({ message: "请输入开户公司（英文）", type: "warning" });
          return;
        } else if (this.applicationData.accountCompanyEnglishAddress == "") {
          this.$message({ message: "请输入公司地址（英文）", type: "warning" });
          return;
        } else if (this.applicationData.clientType == "") {
          this.$message({ message: "请选择客户类别", type: "warning" });
          return;
        } else if (this.applicationData.productLink == "") {
          this.$message({ message: "请输入产品链接", type: "warning" });
          return;
        } else if (this.applicationData.pageLink == "") {
          this.$message({ message: "请输入粉丝页地址", type: "warning" });
          return;
        } else if (this.applicationData.officialWebsiteLink == "") {
          this.$message({ message: "请输入官网地址", type: "warning" });
          return;
        } else if (this.applicationData.serviceTargetId.length < 1) {
          this.$message({ message: "请选择投放目标", type: "warning" });
          return;
        } else if (this.applicationData.estimatedBid == "") {
          this.$message({ message: "请输入预计出价", type: "warning" });
          return;
        } else if (this.applicationData.serviceCountry == "") {
          this.$message({ message: "请选择投放国家", type: "warning" });
          return;
        } else if (this.applicationData.serviceType == "") {
          this.$message({ message: "请选择投放方式", type: "warning" });
          return;
        } else if (this.applicationData.timeZone == "") {
          this.$message({ message: "请选择投放时区", type: "warning" });
          return;
        } else if (this.applicationData.remarks == "") {
          this.$message({ message: "请输入备注", type: "warning" });
          return;
        } else {
          postdata = this.applicationData;
        }
      }
      this.axios("POST", `${this.$javaBaseURL}open/application`, postdata).then(
        res => {
          if (res.code == 1) {
            console.log(res.data);
            this.$message({ message: "操作成功", type: "success" });
            this.showFlag = 3;
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.code == 1) {
        this.applicationData.businessLicenseLink = URL.createObjectURL(
          file.raw
        );
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
    applyAds(item) {
      this.showFlag = 3;
      this.applyadsData = item;
      this.axios("POST", `${this.$javaBaseURL}open/application`, {
        platform: 1,
        projectId: this.applyadsData.project_id,
        clientCompanyId: this.applyadsData.client_company_id
      }).then(res => {
        res = JSON.parse(res);
        if (res.code == 1) {
          console.log(res.data);
          this.facebookUrl = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    stopStart(item) {
      console.log(item);
      this.operate_item = item;
      this.delPop = true;
    },
    confirmOpe() {
      var url = `${this.$phpBaseURL}v1/project/unlockAccount`;
      if (this.operate_item.account_status == 1) {
        url = `${this.$phpBaseURL}v1/project/lockingAccount`;
      }
      this.axios("POST", url, {
        account_id: this.operate_item.account_id
      }).then(res => {
        if (res.code == 1) {
          this.$message({ message: res.msg, type: "success" });
          this.init();
          this.delPop = false;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);
      var startDate = "";
      var endDate = "";
      if (this.ajaxDataSearch.project_created_at) {
        startDate = this.ajaxDataSearch.project_created_at[0];
        endDate = this.ajaxDataSearch.project_created_at[1];
      }
      this.axios("POST", `${this.$phpBaseURL}v1/project/lists`, {
        account_status: this.ajaxDataSearch.account_status,
        project_name: this.ajaxDataSearch.project_name,
        client_company_name: this.ajaxDataSearch.client_company_name,
        start_date: startDate, //	否	string	搜索合同的创建日期；如：2020-04-23
        end_date: endDate,
        business_type: this.ajaxDataSearch.business_type,
        project_manager_id: this.ajaxDataSearch.project_manager_id,
        dep_id: this.ajaxDataSearch.dep_id,
        approval_status: this.ajaxDataSearch.approval_status,
        status: this.ajaxDataSearch.status,
        page: page ? page : 1
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.projectList = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    search_item_data() {
      /*搜索项下拉框：销售，优化师，AM 用户数据 1-销售，2-优化师，3-AM*/
      this.axios("POST", `${this.$phpBaseURL}v1/config/roleUserdatas`, {
        type: 1
      }).then(res => {
        //                console.log(res.data)
        if (res.code == 1) {
          this.configurationData2 = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      if (this.ajaxDataSearch.project_created_at == null) {
        this.ajaxDataSearch.project_created_at = ["", ""];
      }
      this.init();
    },
    /*详情*/
    getInfo(id) {
      this.project_id = id;
      this.$store.commit("curRouter", false);
      this.axios("POST", `${this.$phpBaseURL}v1/project/detail`, {
        project_id: id
      }).then(res => {
        //    console.log(res.data);
        if (res.code == 1) {
          this.info = res.data;
          this.projectSpeedLists = [res.data.basic_data];

          //预计外付成本明细
          this.payOutCostIsTrue = true;
          if (res.data.expected_pay_out_cost_details.length == 0) {
            this.payOutCostIsTrue = false;
          }

          //预计项目费用明细
          this.projectCostIsTrue = true;
          if (res.data.expected_project_cost_details.length == 0) {
            this.projectCostIsTrue = false;
          }
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*编辑项目状态*/
    editStatus(obj) {
      console.log(obj);
      this.project_status = obj.status.toString();
      this.project_id = obj.project_id;
    },
    doEditStatus() {
      this.axios("POST", `${this.$phpBaseURL}v1/project/editStatus`, {
        project_id: this.project_id,
        status: this.project_status
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.common.statisticsAdd("项目状态变更", "2");

          this.init();
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*开通账户*/
    openZh(obj) {
      this.project_id = obj.project_id;
      this.editingProObj = obj;

      this.axios("POST", `${this.$phpBaseURL}v1/project/getAccountInfo`, {
        project_id: this.project_id,
        type: 1
      }).then(res => {
        // console.log(res.data)

        if (res.code == 1) {
          this.accountInfo = res.data;

          //客户可用授信额度判断:'一事一议'
          if (obj.level_name == "白名单") {
            this.editingProObj.level_consume_value = "一事一议";
          } else {
            this.editingProObj.level_consume_value = this.accountInfo.account_info.credit_amount;
          }
          this.ajaxDataOpen.sales_id = this.accountInfo.sales_id;
          this.ajaxDataOpen.account = this.accountInfo.account;
          this.ajaxDataOpen.max_number_accounts = this.accountInfo.max_number_accoun;
          this.ajaxDataOpen.daily_account_opening_limit = this.accountInfo.daily_account_opening_limit;
          this.ajaxDataOpen.am_id = this.accountInfo.am_id;
          this.ajaxDataOpen.optimizer_id = this.accountInfo.optimizer_id;
          this.email_address = this.accountInfo.email_address;
          this.ajaxDataOpen.is_prepaid_recharge = this.accountInfo.is_prepaid_recharge;
          this.ajaxDataOpen.email_address = this.accountInfo.email_address;
          this.openZhPop = true;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    doOpenZh() {
      this.ajaxDataOpen.project_id = this.editingProObj.project_id;
      // this.ajaxDataOpen.account = this.accountInfo.account_info.account;
      this.ajaxDataOpen.account_password = this.accountInfo.account_info.account_password;
      this.ajaxDataOpen.credit_amount = this.editingProObj.level_consume_value;
      this.ajaxDataOpen.contract_id = this.editingProObj.contract_id;
      this.ajaxDataOpen.contract_start_time = this.editingProObj.contract_start;
      this.ajaxDataOpen.contract_end_time = this.editingProObj.contract_end;
      let r = /^[a-zA-Z0-9]{8,20}$/;
      let o_l = this.ajaxDataOpen.daily_account_opening_limit; //每日开户上限
      let a_m_a = this.ajaxDataOpen.max_number_accounts; //最大开户数量
      // if(!r.test(this.ajaxDataOpen.account)){
      //     this.$message({ message: '账号由8~20位数字和字母组成', type: 'warning' });
      //     return
      // }

      let n = /^[0-9]+$/; //正整数校验
      if (!n.test(Number(a_m_a)) || Number(a_m_a) == 0) {
        this.$message({
          message: "最大开户数量为大于0的正整数",
          type: "warning"
        });
        return false;
      }
      if (!n.test(Number(o_l)) || Number(o_l) == 0) {
        this.$message({
          message: "每日开户上限为大于0的正整数",
          type: "warning"
        });
        return false;
      }
      if (o_l && a_m_a) {
        if (Number(o_l) > Number(a_m_a)) {
          this.$message({
            message: "每日开户上限不能大于最大开户数量",
            type: "warning"
          });
          return false;
        }
      }

      const numReg = new RegExp("^[0-9]*$");
      const strReg = new RegExp("^[a-zA-Z]*$");
      const reg = new RegExp("^[a-zA-Z0-9]*$");
      if (this.ajaxDataOpen.account === "") {
        this.$message({ message: "请输入账户名", type: "warning" });
        return;
      } else if (
        !this.ajaxDataOpen.account ||
        this.ajaxDataOpen.account.length < 8
      ) {
        this.$message({ message: "账户名不能小于8位字符", type: "warning" });
        return;
      } else if (this.ajaxDataOpen.account.length > 20) {
        this.$message({ message: "账户名不能超过20位字符", type: "warning" });
        return;
      } else if (numReg.test(this.ajaxDataOpen.account)) {
        this.$message({ message: "账户名必须包含字母", type: "warning" });
        return;
      } else if (strReg.test(this.ajaxDataOpen.account)) {
        this.$message({ message: "账户名必须包含数字", type: "warning" });
        return;
      } else if (!reg.test(this.ajaxDataOpen.account)) {
        this.$message({ message: "账户名不能使用特殊符号", type: "warning" });
        return;
      }

      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/project/openAccount`,
        this.ajaxDataOpen
      ).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.common.statisticsAdd("开通账户管理平台账户", "2");

          this.init();
          this.openZhPop = false;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    /*编辑账户*/
    editZh(obj) {
      this.project_id = obj.project_id;
      this.editingProObj = obj;
      this.axios("POST", `${this.$phpBaseURL}v1/project/getAccountInfo`, {
        project_id: this.project_id,
        type: 2
      }).then(res => {
        // console.log(res.data)
        if (res.code == 1) {
          this.accountInfo = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    doEditZh() {
      //检测每日开户上限是否是正整数
      let r = /^[0-9]+$/; //正整数校验
      let o_l = this.accountInfo.account_info.daily_account_opening_limit;
      let a_m_a = this.accountInfo.account_info.max_number_accounts;
      if (!r.test(a_m_a)) {
        this.$message({ message: "最大开户数量为正整数", type: "warning" });
        return false;
      }
      if (!r.test(o_l)) {
        this.$message({ message: "每日开户上限为正整数", type: "warning" });
        return false;
      }
      if (o_l && a_m_a) {
        if (Number(o_l) > Number(a_m_a)) {
          this.$message({
            message: "每日开户上限不能大于最大开户数量",
            type: "warning"
          });
          return false;
        }
      }
      this.axios("POST", `${this.$phpBaseURL}v1/project/editAccount`, {
        project_id: this.editingProObj.project_id, //	是	string	项目ID；如：968485f47cc0b7ac7cf36d4868689866
        max_number_accounts: this.accountInfo.account_info.max_number_accounts, //	是	int	最大开户数量；如：1
        daily_account_opening_limit: this.accountInfo.account_info
          .daily_account_opening_limit, //	是	int	每日开户上限；如：1
        distribution_credit_amount: this.accountInfo.account_info
          .distribution_credit_amount, //	是	int	分配授信额度；如：1
        sales_id: this.accountInfo.account_info.sales_id, //	是	int	销售ID；如：1
        //                    front_end_id:this.accountInfo.account_info.front_end_id,//	是	int	前端ID；如：1
        am_id: this.accountInfo.account_info.am_id, //	是	int	AMID；如：1
        optimizer_id: this.accountInfo.account_info.optimizer_id, //	是	int	优化师ID；如：1
        email_address: this.accountInfo.account_info.email_address //	是	string	邮箱地址；如：9023878237@qq.com
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.common.statisticsAdd("编辑账户管理平台账户", "2");

          this.$message({ message: "修改成功", type: "success" });
          this.init();
          this.editZhPop = false;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    sendEm() {
      this.axios("POST", `${this.$phpBaseURL}v1/project/sendEmail`, {
        project_id: this.editingProObj.project_id,
        email_address: this.accountInfo.account_info.email_address
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.$message({ message: "发送成功", type: "success" });
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    downloadfile() {
      let token = JSON.parse(localStorage.getItem("user_info")).token;
      let url = `${this.$phpBaseURL}v1/project/projectDownload?token=${token}&project_id=${this.project_id}`;
      window.open(url, "_blank");
    },

    //登录panda
    seeShare(val) {
      this.common.statisticsAdd("登录账户管理平台", "2");

      let param = {
        p_id: val
      };
      this.axios("POST", `${this.$phpBaseURL}v1/project/ampLogin`, param).then(
        res => {
          if (res.code == 1) {
            console.log("res:" + res);

            let str = JSON.stringify(res.data);
            let token = res.data.token;

            let target_url = `${this.$panda_url}login?token=${token}`;
            window.open(target_url, "_blank");
          } else {
            this.$message.error(res.msg);
          }
        }
      );

      //                let target_url=`${this.$panda_url}login?cypher=${val}`;
      //                window.open(target_url, '_blank');
    },

    getAuth(row) {
      //授权
      this.authPop = true;
      this.selRow = row;
      console.log("selrow:", this.selRow);
      this.getAuthPerson();
    },
    getAuthPerson() {
      //获取授权信息
      let param = {
        project_id: this.selRow.project_id
      };
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/project/projectAuthorizationDetail`,
        param
      ).then(res => {
        if (res.code == 1) {
          this.authPerson = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    delAuthPerson(item) {
      //删除授权人
      let param = {
        project_id: this.selRow.project_id,
        user_id: item.user_id
      };
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/project/projectAuthorizationDel`,
        param
      ).then(res => {
        if (res.code == 1) {
          this.$message({ message: "删除成功", type: "success" });
          this.getAuthPerson();

          this.authPop = false;

          //刷新页面
          this.init(this.firstPage);
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    confirmAuth() {
      //确定添加授权人
      let param = {
        project_id: this.selRow.project_id,
        user_id: this.user_id
      };
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/project/projectAuthorization`,
        param
      ).then(res => {
        if (res.code == 1) {
          this.$message({ message: "添加成功", type: "success" });
          this.authPop = false;

          //功能频次统计
          this.common.statisticsAdd("项目授权", "2");

          //刷新页面
          this.init(this.firstPage);
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("项目列表", "1");
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info-row {
  margin-bottom: 24px;
}
.zanwushuju {
  text-align: center !important;
  color: #909399;
}
.openZhPop {
  .main-inputBox {
    width: 290px;
  }
}
.main-info-paper_td {
  height: auto;
  min-height: 40px;
}
.status-zhengchang {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(38, 196, 184, 1);
    border-radius: 50%;
  }
}
.status-zanting {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(247, 185, 77, 1);
    border-radius: 50%;
  }
}
.status-yanqi {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(254, 102, 100, 1);
    border-radius: 50%;
  }
}
.status-quxiao {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(173, 179, 188, 1);
    border-radius: 50%;
  }
}
.main-selectBox .authperson {
  border: #01ace4 solid 1px;
  border-radius: 4px;
  padding: 0px 8px;
  margin-right: 16px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  box-sizing: border-box;
  color: #01ace4;
  margin-top: 4px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  // transition: all .3s;
}
.main-selectBox .authperson i {
  color: #adb3bc;
  font-size: 14px;
  position: absolute;
  right: -9px;
  top: -14px;
  cursor: pointer;
  display: none;
}
.main-selectBox .authperson:hover i {
  display: block !important;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  border-radius: 6px;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

