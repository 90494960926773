<template>
    <div class="mediaDelivery main-div">
        <el-tabs v-model="activeName" class="tab-shine" type="card" @tab-click="handleClick">
            <el-tab-pane label="媒介投放客户数据表--日" name="first">
                <!-- 搜索项 start -->
                <div class="tab-1">
                    <div class="main-inputBox">
                        <el-input v-model="ajaxDataSearch.project_name" placeholder="请输入项目名称" @change="enterMediaCustomerList">
                        </el-input>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.user_id" @change="enterMediaCustomerList" clearable placeholder="请选择销售">
                            <el-option
                                    v-for="(item,index) in configurationData.sales_list"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.dept_id" @change="enterMediaCustomerList" clearable placeholder="请选择部门">
                            <el-option :title="item.department_name"
                                       v-for="item in configurationData.departments_data"
                                       :key="item.department_id"
                                       :label="item.department_name"
                                       :value="item.department_id">
                            </el-option>

                        </el-select>
                    </div>
                    <div class="main-selectBox sel-width-limit">
                        <el-select v-model="ajaxDataSearch.industry" @change="enterMediaCustomerList" clearable placeholder="请选择行业" class="limit-width-sel">
                            <el-option :title="item.industry_name"
                                       v-for="item in configurationData.industry_data"
                                       :key="item.industry_id"
                                       :label="item.industry_name"
                                       :value="item.industry_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox w150">
                        <el-select v-model="ajaxDataSearch.company_id" @change="enterMediaCustomerList" clearable placeholder="请选择客户公司">
                            <el-option
                                    v-for="(item,index) in clientCompanyList"
                                    :key="index"
                                    :label="item.clientCompanyName"
                                    :value="item.clientCompanyId">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="main-selectBox w150">
                        <el-select v-model="ajaxDataSearch.client_level" @change="enterMediaCustomerList" clearable placeholder="请选择客户级别">
                            <el-option :title="item.level_name"
                                       v-for="item in configurationData.client_level_config"
                                       :key="item.level_id"
                                       :label="item.level_name"
                                       :value="item.level_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox w150">
                        <el-select v-model="ajaxDataSearch.platform" @change="enterMediaCustomerList" clearable placeholder="请选择投放平台">
                            <el-option
                                    v-for="(item,index) in configurationData.platform_name_array"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-inputBox daterange date-picker-input-width">
                        <el-date-picker
                                v-model="ajaxDataSearch.applicationTime"
                                @change="enterMediaCustomerList"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                type="daterange" clearable start-placeholder="立项开始日期"
                                end-placeholder="结束日期"
                                placeholder="创建时间">
                        </el-date-picker>
                    </div>
                </div>
                <!-- 搜索项 end -->

                <div class="main-btnBox">
                    <a class="download_a" ref="" id="export_day" href=""></a>
                    <div class="main-button" @click="enterExport">导出</div>
                </div>

                <div v-loading="loading1">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            @selection-change="handleSelectionChange">
                        <template slot="empty">
                            <div class="nodata">
                                <img src="@/assets/img/nodata.png">
                                <span>暂无数据</span>
                            </div>
                        </template>
                        <!-- <el-table-column
                          prop="date"
                          label="客户状态"
                          width="180">
                            <template slot-scope="scope">
                              <span class="custom-status unusual">异常用户</span>
                           </template>
                        </el-table-column> -->
                        <el-table-column
                                prop="department_name"
                                label="部门"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="user_name"
                                show-overflow-tooltip
                                label="销售"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="client_company_name"
                                label="客户公司"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="industry_name"
                                label="行业"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="level_name"
                                label="客户级别"
                                width="140">
                        </el-table-column>
                        <el-table-column
                                prop="project_name"
                                label="项目名称"
                                width="180"
                                show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span style="cursor: pointer">{{scope.row.project_name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop=""
                                label="投放平台"
                                width="100">
                            <template slot-scope="scope">
                                <span style="width: 200px">{{configurationData.platform_name_array[scope.row.platform]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="project_time"
                                label="立项时间"
                                width="200"
                                show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span style="cursor: pointer">{{scope.row.project_time}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="consume"
                                label="消耗"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="client_rebate_ratio"
                                label="对客返点比例"
                                width="150">
                            <template slot-scope="scope">
                                <span style="cursor: pointer" :title="scope.row.client_rebate_ratio">{{addPercentSign(scope.row.client_rebate_ratio)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="client_rebate"
                                label="对客返点"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="platform_rebate_ratio"
                                label="平台返点比例"
                                width="150">
                            <template slot-scope="scope">
                                <span style="cursor: pointer" :title="scope.row.platform_rebate_ratio">{{addPercentSign(scope.row.platform_rebate_ratio)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="platform_rebate"
                                label="平台返点"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="income"
                                label="收入"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="gross_profit"
                                label="毛利"
                                min-width="150">
                        </el-table-column>
                        <!-- <el-table-column
                          prop="address"
                          label="异常状态"
                          width="150">
                        </el-table-column> -->

                    </el-table>
                    <pagination-vue
                            @currentChange="getMediaCustomerList"
                            :pageSize="pageSize"
                            :total="total"
                    />
                </div>
            </el-tab-pane>

            <el-tab-pane label="媒介投放客户数据表--月" name="second">
                <!-- 搜索项 start -->
                <div class="tab-1">
                    <div class="main-inputBox">
                        <el-input v-model="ajaxDataSearchMonth.project_name" placeholder="请输入项目名称" @change="enterMediaCustomerMonthList">
                            <i class="el-icon-search el-input__icon" slot="suffix" @click="enterMediaCustomerMonthList"></i>
                        </el-input>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearchMonth.user_id" @change="enterMediaCustomerMonthList" clearable placeholder="请选择销售">
                            <el-option
                                    v-for="(item,index) in configurationData.sales_list"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearchMonth.dept_id" @change="enterMediaCustomerMonthList" clearable placeholder="请选择部门">
                            <el-option :title="item.department_name"
                                       v-for="item in configurationData.departments_data"
                                       :key="item.department_id"
                                       :label="item.department_name"
                                       :value="item.department_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearchMonth.industry" @change="enterMediaCustomerMonthList" clearable placeholder="请选择行业">
                            <el-option :title="item.industry_name"
                                       v-for="item in configurationData.industry_data"
                                       :key="item.industry_id"
                                       :label="item.industry_name"
                                       :value="item.industry_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox w150">
                        <el-select v-model="ajaxDataSearchMonth.company_id" @change="enterMediaCustomerMonthList" clearable placeholder="请选择客户公司">
                            <el-option
                                    v-for="(item,index) in clientCompanyList"
                                    :key="index"
                                    :label="item.clientCompanyName"
                                    :value="item.clientCompanyId">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="main-selectBox w150">
                        <el-select v-model="ajaxDataSearchMonth.client_level" @change="enterMediaCustomerMonthList" clearable placeholder="请选择客户评级">
                            <el-option
                                    v-for="item in configurationData.client_level_config"
                                    :key="item.level_id"
                                    :label="item.level_name"
                                    :value="item.level_id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox w150">
                        <el-select v-model="ajaxDataSearchMonth.platform" @change="enterMediaCustomerMonthList" clearable placeholder="请选择投放平台">
                            <el-option
                                    v-for="(item,k) in configurationData.platform_array"
                                    :key="k"
                                    :label="item"
                                    :value="k">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-inputBox daterange date-picker-input-width">
                        <el-date-picker
                                v-model="ajaxDataSearchMonth.applicationTime"
                                @change="enterMediaCustomerMonthList"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                type="daterange"
                                clearable
                                start-placeholder="立项开始日期"
                                end-placeholder="结束日期"
                                placeholder="创建时间">
                        </el-date-picker>
                    </div>
                </div>
                <!-- 搜索项 end -->

                <div class="main-btnBox">
                    <a class="download_a" ref="" id="export_month" href=""></a>
                    <div class="main-button" @click="enterExportMonth">导出</div>
                </div>

                <div v-loading="loading2">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            @selection-change="handleSelectionChange">
                        <template slot="empty">
                            <div class="nodata">
                                <img src="@/assets/img/nodata.png">
                                <span>暂无数据</span>
                            </div>
                        </template>
                        <!-- <el-table-column
                          prop="date"
                          label="客户状态"
                          width="180">
                            <template slot-scope="scope">
                              <span class="custom-status unusual">异常用户</span>
                           </template>
                        </el-table-column> -->
                        <el-table-column
                                prop="department_name"
                                label="部门"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="user_name"
                                show-overflow-tooltip
                                label="销售"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="client_company_name"
                                label="客户公司"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="industry_name"
                                label="行业"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="level_name"
                                label="客户级别"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="project_name"
                                label="项目名称"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop=""
                                label="投放平台"
                                width="100">
                            <template slot-scope="scope">
                                {{configurationData.platform_name_array[scope.row.platform]}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="project_time"
                                label="立项时间"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="consume"
                                label="消耗"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="client_rebate_ratio"
                                label="对客返点比例"
                                width="150">
                            <template slot-scope="scope">
                                <span style="cursor: pointer" :title="scope.row.client_rebate_ratio">{{addPercentSign(scope.row.client_rebate_ratio)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="client_rebate"
                                label="对客返点"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="platform_rebate_ratio"
                                label="平台返点比例"
                                width="150">
                            <template slot-scope="scope">
                                <span style="cursor: pointer" :title="scope.row.platform_rebate_ratio">{{addPercentSign(scope.row.platform_rebate_ratio)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="platform_rebate"
                                label="平台返点"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="income"
                                label="收入"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="gross_profit"
                                label="毛利"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop=""
                                label="未开票（未回款）"
                                width="150">
                            <template slot-scope="scope">
                                <span class="main-tableToInfo" @click="moneyBack=true;getProjectMoneyBack(scope.row.project_id)">{{scope.row.no_invoice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop=""
                                label="（已开票）未回款"
                                width="150">
                            <template slot-scope="scope">
                                <span class="main-tableToInfo" @click="moneyBack=true;getProjectMoneyBack(scope.row.project_id)">{{scope.row.invoiced}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop=""
                                label="已回款"
                                width="150">
                            <template slot-scope="scope">
                                <span class="main-tableToInfo" @click="moneyBack=true;getProjectMoneyBack(scope.row.project_id)">{{scope.row.money_back}}</span>
                            </template>
                        </el-table-column>

                    </el-table>
                    <pagination-vue
                            @currentChange="getMediaCustomerMonthList"
                            :pageSize="pageSize"
                            :total="total"
                            :firstPage="firstPage"
                    />
                </div>

            </el-tab-pane>
            <!-- 媒介投放客户数据总表-回款记录 弹窗start -->
            <div class="main-pop" v-show="moneyBack">
                <div class="mask"></div>
                <div class="center" style="width: 696px">
                    <div class="head">
                        <span>回款情况</span>
                        <i class="gongzuotai gztguanbi" @click="moneyBack=false"></i>
                    </div>
                    <div class="body">
                        <el-table
                                :data="projectMoneyBackList"
                                style="width: 100%">
                            <template slot="empty">
                                <div class="nodata">
                                    <img src="@/assets/img/nodata.png">
                                    <span>暂无数据</span>
                                </div>
                            </template>
                            <el-table-column
                                    prop=""
                                    label="序号"
                                    type="index"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="client_company_name"
                                    label="客户"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="project_name"
                                    label="项目"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="money_back"
                                    label="已回款金额"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="back_time"
                                    label="回款时间"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="invoiced"
                                    label="（已开票）未回款"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="no_invoice"
                                    label="未开票（未回款）"
                                    min-width="200">
                            </el-table-column>
                        </el-table>
                        <!--<pagination-vue @currentChange="init" :pageSize="pageSize" :total="total"/>-->
                    </div>
                    <div class="foot">
                        <button class="main-button l" @click="moneyBack=false">取消</button>
                        <button class="main-button r" @click="moneyBack=false">确定</button>
                    </div>
                </div>
            </div>
            <!-- 媒介投放客户数据总表-回款记录 弹窗 end -->

            <el-tab-pane label="平台完成情况" name="third">
                <div class="tab-1">
                    <div class="main-inputBox gatherdata-self-shy">
                        <el-date-picker
                                @change="getPlatformList" value-format="yyyy" format="yyyy"
                                type="year" clearable v-model="ajaxDataSearch3.year"
                                placeholder="年份">
                        </el-date-picker>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch3.quarter" @change="getPlatformList" clearable placeholder="请选择季度">
                            <el-option
                                    v-for="(item,index) in quarterArr"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch3.dep_id" @change="getPlatformList" clearable placeholder="请选择部门">
                            <el-option
                                    v-for="(item,index) in configurationData.departments_data"
                                    :key="item.department_id"
                                    :label="item.department_name"
                                    :value="item.department_id"
                            ></el-option>
                        </el-select>
                    </div>

                </div>
                <div class="main-btnBox">
                    <div v-show="common.permFilter(`${common.php}v1/media/xinkeStore`)" class="main-button" @click="editFacebookNew()">新建Facebook-新客数据</div>
                    <div v-show="common.permFilter(`${common.php}v1/media/platformCompletimeStore`)" class="main-button main-button-first" @click="editKPI()">新建季度KPI</div>
                </div>
                <div v-loading="loading3">
                    <el-table
                            v-if="tableData3"
                            :data="tableData3"
                            style="width: 100%">
                        <template slot="empty">
                            <div class="nodata">
                                <img src="@/assets/img/nodata.png">
                                <span>暂无数据</span>
                            </div>
                        </template>
                        <el-table-column
                                label="平台"
                                width="180">
                            <template slot-scope="scope">

                                <span v-if="scope.row.platform_name">{{scope.row.platform_name}}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="quarter_kpi"
                                label="季度KPI"
                                width="180">
                        </el-table-column>

                        <el-table-column
                                prop="curr_cost"
                                label="目前消耗"
                                width="180">
                            <!-- <template slot-scope="scope">
                              <span class="">异常用户</span>
                            </template> -->
                        </el-table-column>
                        <el-table-column
                                prop="completion"
                                label="完成情况"
                                width="180">
                            <template slot-scope="scope">
                                <span style="cursor: pointer" :title="scope.row.completion">{{addPercentSign(scope.row.completion)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="department_name"
                                label="BU"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="contribution_rate"
                                label="贡献率"
                                width="180">
                            <template slot-scope="scope">
                                <span style="cursor: pointer" :title="scope.row.contribution_rate">{{addPercentSign(scope.row.contribution_rate)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="income"
                                label="收入"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="cost"
                                label="成本"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="gross_profit"
                                label="毛利"
                                min-width="180">
                        </el-table-column>
                    </el-table>
                    <pagination-vue
                            @currentChange="init3"
                            :pageSize="pageSize3"
                            :total="total3"
                    />
                </div>
            </el-tab-pane>

            <el-tab-pane label="KA客户消耗变化" name="fourth">
                <div v-show="showFlagKa == 1">
                    <div class="tab-1">
                        <div class="main-selectBox w150">
                            <el-select v-model="kaInfo.client_company_id"
                                       @change="enterKaList"
                                       filterable
                                       clearable
                                       placeholder="请选择客户公司">
                                <el-option
                                        v-for="(item,index) in clientCompanyList"
                                        :key="index"
                                        :label="item.clientCompanyName"
                                        :value="item.clientCompanyId">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="main-selectBox">
                            <el-select v-model="kaInfo.industry_id" @change="enterKaList" clearable placeholder="请选择行业">
                                <el-option :title="item.industry_name"
                                           v-for="item in configurationData.industry_data"
                                           :key="item.industry_id"
                                           :label="item.industry_name"
                                           :value="item.industry_id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="main-selectBox w150">
                            <el-select v-model="kaInfo.platform" @change="enterKaList" clearable placeholder="请选择投放平台">
                                <el-option
                                        v-for="(item,index) in configurationData.platform_name_array"
                                        :key="index"
                                        :label="item"
                                        :value="index"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="main-inputBox gatherdata-self-shy">
                            <el-date-picker @change="enterKaList"
                                            v-model="kaInfo.month"
                                            type="date"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div v-loading="loading4">
                        <el-table
                                :data="kaList"
                                border
                                height="550"
                                style="width: 100%">
                            <template slot="empty">
                                <div class="nodata">
                                    <img src="@/assets/img/nodata.png">
                                    <span>暂无数据</span>
                                </div>
                            </template>
                            <el-table-column
                                    prop="client_company_name"
                                    label="客户公司"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="industry_name"
                                    label="客户行业"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="department_name"
                                    label="部门"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="user_name"
                                    label="销售"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop=""
                                    label="投放平台"
                                    width="150">
                                <template slot-scope="scope">
                                    {{configurationData.platform_array[scope.row.platform]}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="day_8_14"
                                    label="近8-14天"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="day_7"
                                    label="近7天"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="consume_diff"
                                    label="消耗差"
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop=""
                                    label="每日消耗图表"
                                    min-width="200">
                                <template slot-scope="scope">
                                    <span class="main-tableToInfo" @click="showFlagKa=2;getKaInfo(scope.row)">查看详情</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <pagination-vue
                                @currentChange="getKaList"
                                :pageSize="pageSize4"
                                :total="total4"
                                :firstPage="firstPage"
                        />
                    </div>
                </div>

                <div v-show="showFlagKa == 2">
                    <p style="display: inline-block;margin-right: 20px">客户公司：
                        <template v-if="kaDetail && kaDetail.client_company_name">{{kaDetail.client_company_name}}</template>
                    </p>
                    <p style="display: inline-block;margin-right: 20px">投放平台：
                        <template v-if="kaDetail && kaDetail.platform">{{configurationData.platform_name_array[kaDetail.platform]}}</template>
                    </p>

                    <div style="width: 1000px;height: 500px" id="chart"></div>
                    <div class="main-button" @click="showFlagKa = 1">返回</div>
                </div>

            </el-tab-pane>

            <el-tab-pane label="消耗增长最多客户TOP10" name="fifth">
                <!-- 搜索项 start -->
                <div class="tab-1">
                    <div class="main-selectBox w150">
                        <el-select v-model="topInfo.client_company_id"
                                   @change="getUpData"
                                   filterable
                                   clearable
                                   placeholder="请选择客户公司">
                            <el-option
                                    v-for="(item,index) in clientCompanyList"
                                    :key="index"
                                    :label="item.clientCompanyName"
                                    :value="item.clientCompanyId">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="main-selectBox">
                        <el-select v-model="topInfo.industry_id" @change="getUpData" clearable placeholder="请选择行业">
                            <el-option :title="item.industry_name"
                                       v-for="item in configurationData.industry_data"
                                       :key="item.industry_id"
                                       :label="item.industry_name"
                                       :value="item.industry_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox w150">
                        <el-select v-model="topInfo.level_id" @change="getUpData" clearable placeholder="请选择客户评级">
                            <el-option
                                    v-for="item in configurationData.client_level_config"
                                    :key="item.level_id"
                                    :label="item.level_name"
                                    :value="item.level_id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-inputBox gatherdata-self-shy">
                        <el-date-picker
                                v-model="topInfo.data_date"
                                @change="getUpData" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                type="date" clearable
                                placeholder="时间">
                        </el-date-picker>
                    </div>
                </div>
                <el-table
                        v-loading="loading5"
                        :data="upList"
                        border
                        height="550"
                        style="width: 100%">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column
                            prop="client_company_name"
                            label="客户公司"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="industry_name"
                            label="客户行业"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="department_name"
                            label="部门"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="user_name"
                            label="销售"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="level_name"
                            label="客户评级"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="day_8_14"
                            label="近8-14天"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="day_7"
                            label="近7天"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="consume_add"
                            label="消耗增长金额"
                            minwidth="200">
                    </el-table-column>
                </el-table>

            </el-tab-pane>

            <el-tab-pane label="消耗下降最多客户TOP10" name="sixth">
                <!-- 搜索项 start -->
                <div class="tab-1">
                    <div class="main-selectBox w150">
                        <el-select v-model="topInfo.client_company_id"
                                   @change="getDownData"
                                   filterable
                                   clearable
                                   placeholder="请选择客户公司">
                            <el-option
                                    v-for="(item,index) in clientCompanyList"
                                    :key="index"
                                    :label="item.clientCompanyName"
                                    :value="item.clientCompanyId">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="topInfo.industry_id" @change="getDownData" clearable placeholder="请选择行业">
                            <el-option :title="item.industry_name"
                                       v-for="item in configurationData.industry_data"
                                       :key="item.industry_id"
                                       :label="item.industry_name"
                                       :value="item.industry_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox w150">
                        <el-select v-model="topInfo.level_id" @change="getDownData" clearable placeholder="请选择客户评级">
                            <el-option
                                    v-for="item in configurationData.client_level_config"
                                    :key="item.level_id"
                                    :label="item.level_name"
                                    :value="item.level_id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="main-inputBox gatherdata-self-shy">
                        <el-date-picker
                                v-model="topInfo.data_date"
                                @change="getDownData" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                type="date" clearable
                                placeholder="时间">
                        </el-date-picker>
                    </div>
                </div>
                <el-table
                        v-loading="loading6"
                        :data="downList"
                        border
                        height="550"
                        style="width: 100%">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column
                            prop="client_company_name"
                            label="客户公司"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="industry_name"
                            label="客户行业"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="department_name"
                            label="部门"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="user_name"
                            label="销售"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="level_name"
                            label="客户评级"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="day_8_14"
                            label="近8-14天"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="day_7"
                            label="近7天"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="consume_cut"
                            label="消耗下降金额"
                            min-width="200">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <el-dialog
                title="新建新客数据"
                width="338px"
                :visible.sync="eidtFacebookVisible"
                class="el-dialog-self"
        >
            <p class="line"></p>
            <main>
                <el-form label-position="top" label-width="80px" :model="addFacebookFormData" ref="facebookForm">
                    <el-form-item label="请选择年份" class="el-select-32">
                        <el-date-picker
                                value-format="yyyy" format="yyyy"
                                type="year" clearable v-model="addFacebookFormData.year"
                                placeholder="年份">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="请选择季度" class="el-select-32">
                        <el-select v-model="addFacebookFormData.quarter" clearable placeholder="请选择季度">
                            <el-option
                                    v-for="(item,index) in quarterArr"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="季度KPI" class="el-select-32">
                        <el-input v-model="addFacebookFormData.quarter_kpi" @change="countCompletionStatus()" placeholder="0.00"></el-input>
                    </el-form-item>
                    <el-form-item label="目前消耗" class="el-select-32">
                        <el-input v-model="addFacebookFormData.curr_cost" @change="countCompletionStatus()" placeholder="0.00"></el-input>
                    </el-form-item>
                    <el-form-item label="完成情况" class="el-select-32">
                        <template>{{completionStatus}}</template>
                    </el-form-item>
                    <el-form-item label="请选择部门" class="el-select-32">
                        <el-select v-model="addFacebookFormData.dep_id" clearable placeholder="请选择部门">
                            <el-option :title="item.department_name"
                                       v-for="item in configurationData.departments_data"
                                       :key="item.department_id"
                                       :label="item.department_name"
                                       :value="item.department_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="贡献率" class="el-select-32">
                        <el-input v-model="addFacebookFormData.contribution_rate" placeholder="0.00"></el-input>
                    </el-form-item>
                    <el-form-item label="收入" class="el-select-32">
                        <el-input v-model="addFacebookFormData.income" placeholder="0.00"></el-input>
                    </el-form-item>
                    <el-form-item label="成本" class="el-select-32">
                        <el-input v-model="addFacebookFormData.cost" placeholder="0.00"></el-input>
                    </el-form-item>
                    <el-form-item label="毛利" class="el-select-32">
                        <el-input v-model="addFacebookFormData.gross_profit" placeholder="0.00"></el-input>
                    </el-form-item>
                </el-form>
            </main>
            <p class="line"></p>
            <div class="dialog-buttons">
                <button class="main-button l" @click="cancleFacebook">取消</button>
                <button class="main-button r" v-if="!isFacebookLoading" @click="addFacebook">确定</button>
                <button class="main-button r" v-else :loading="true">确定</button>
            </div>
        </el-dialog>
        <el-dialog
                title="新建季度KPI"
                width="338px"
                :visible.sync="eidtDataVisible"
                class="el-dialog-self"
        >
            <p class="line"></p>
            <main>
                <el-form label-position="top" label-width="80px" :model="kpiFormData">
                    <el-form-item label="请选择年份" class="el-select-32">
                        <el-date-picker
                                value-format="yyyy" format="yyyy"
                                type="year" clearable v-model="kpiFormData.year"
                                placeholder="年份">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="请选择季度" class="el-select-32">
                        <el-select v-model="kpiFormData.quarter" clearable placeholder="请选择季度">
                            <el-option
                                    v-for="(item,index) in quarterArr"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="季度KPI" class="el-select-32">
                        <el-input v-model="kpiFormData.quarter_kpi" placeholder="0.00"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="完成情况" class="el-select-32">
                      <el-input></el-input>
                    </el-form-item> -->
                    <el-form-item label="请选择平台" class="el-select-32">
                        <el-select v-model="kpiFormData.platform" clearable placeholder="请选择平台">
                            <el-option
                                    v-for="(item,index) in configurationData.platform_array"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="dialog-buttons" style="padding:0px 0px 16px 16px">
                    <!--<el-button class="main-button l" @click="cancleKPI">取消</el-button> -->

                    <button class="main-button l" @click="cancleKPI" style="background:white;border:#DFE2E7 solid 1px;color:#788090">取消</button>
                    <button class="main-button r" v-if="!isKPILoading" @click="addKpi">确定</button>
                    <button class="main-button r" v-else :loading="true">确定</button>
                </div>
            </main>
        </el-dialog>
    </div>
</template>

<script>
    import paginationVue from '@/components/paginationVue.vue'
    import axios from "axios";
    import store from '@/store/index.js';
    import moment from 'moment';
    import echarts from 'echarts';
    export default {
        name: "gatherManagement",
        components: {
            paginationVue
        },
        data(){
            return {
                firstPage:1,
                loading1:true,
                loading2:true,
                loading3:true,
                loading4:true,
                loading5:true,
                loading6:true,
                timer: '',
                eidtFacebookVisible: false,//编辑Facebook弹框
                eidtDataVisible: false,//编辑KPI弹框
                isFacebookLoading: false,//Facebook新加 弹框的确认是不是加载中
                isKPILoading: false,//KPI新加 弹框的确认是不是加载中
                msg: "",
//            activeName:'first',
                activeName: 'first',
                pageSize: 15,
                total: 1,
                pageSize2: 15,
                total2: 1,
                pageSize3: 15,
                total3: 1,
                pageSize4: 15,
                total4: 1,
                tableData: [],

                ajaxDataSearch: {
                    project_name: '',
                    user_id: '',
                    dept_id: '',
                    industry: '',
                    company_id: '',
                    client_level: '',
                    platform: '',
                    applicationTime: ['', ''],
                },
                moneyBack: false,
                projectMoneyBackList: [],
                ajaxDataSearchMonth: {
                    project_name: '',
                    user_id: '',
                    dept_id: '',
                    industry: '',
                    company_id: '',
                    client_level: '',
                    platform: '',
                    applicationTime: ['', ''],
                },
                clientCompanyList: [],
                /*返回客户管理下搜索项用到的数据接口*/
                configurationData: {},
                upList: [],
                downList: [],
                kaList: [],
                showFlagKa: 1,
                kadaterange: moment().format('YYYY-MM-DD'),
                kaAjaxDData: {
                    client_company_id: '',//	否	string	公司id
                    industry_id: '',//	否	string	行业id
                    platform: '',//	否	string	平台：1facebook;2twitter,3google,5tiktok
                    from_date: moment().subtract(14, 'days').format('YYYY-MM-DD'),//	否	string	开始日期
                    to_date: moment().format('YYYY-MM-DD'),//	否	string	结束日期
                },
                kaDetail: {
                    client_company_name: '',
                    platform: '',
                    industry: '',
                    info: {
                        "2": [
                            {
                                "data_date": "2020-04-26",
                                "consume": "3.00"
                            },
                            {
                                "data_date": "2020-04-27",
                                "consume": "2.00"
                            },
                            {
                                "data_date": "2020-04-28",
                                "consume": "1.00"
                            }
                        ],
                        "1": [
                            {
                                "data_date": "2020-04-26",
                                "consume": "6.00"
                            },
                            {
                                "data_date": "2020-04-27",
                                "consume": "4.00"
                            },
                            {
                                "data_date": "2020-04-28",
                                "consume": "2.00"
                            }
                        ],
                        "3": [
                            {
                                "data_date": "2020-04-26",
                                "consume": "9.00"
                            },
                            {
                                "data_date": "2020-04-27",
                                "consume": "4.00"
                            },
                            {
                                "data_date": "2020-04-28",
                                "consume": "1.00"
                            }
                        ],
                        "4": [
                            {
                                "data_date": "2020-04-26",
                                "consume": "10.00"
                            },
                            {
                                "data_date": "2020-04-27",
                                "consume": "2.00"
                            },
                            {
                                "data_date": "2020-04-28",
                                "consume": "1.00"
                            }
                        ]
                    }
                },
                topInfo: {
                    client_company_id: '',//公司id，如：lxn123
                    industry_id: '',//行业id
                    level_id: '',	//评级id
                    data_date: ''//数据日期
                },
                kaInfo: {
                    client_company_id: '',//公司id，如：lxn123
                    industry_id: '',//行业id
                    platform: '',//平台 1facebook;2twitter,3google,5tiktok
                    year: '',
                    month: ''
                },


                /*平台完成情况相关*/
                /*搜索项*/
                ajaxDataSearch3: {
                    year: '',//	否	string	年份
                    quarter: '',//	否	string	季度：1-第一季度，2-第二季度，3-第三季度，4-第四季度
                    dep_id: '',//	否	部门id
                },
                tableData3: [],

                quarterArr: {
                    /*季度：*/
                    1: '第一季度',
                    2: '第二季度',
                    3: '第三季度',
                    4: '第四季度',
                },

                platformList: [],
                platformOptions: [],
                completionStatus: 0,//完成情况
                addFacebookFormData: { //添加Facebook数据
                    "year": '',
                    "quarter": '',//季度
                    "quarter_kpi": 90.8,//季度KPI
                    "curr_cost": 3,//目前消耗
                    "dep_id": "6F6678A7400000016DBBC69EF398A340",//部门id，BU值
                    "contribution_rate": 3,//贡献率
                    "income": 3,
                    "cost": 3,
                    "gross_profit": 3
                },
                kpiFormData: {
                    //添加KPI数据
                    "year": '',
                    "quarter": '',
                    "platform": '',
                    "quarter_kpi": ''
                },
            }
        },
        created(){
            /*返回媒介投放客户数据总表下搜索项用到的数据接口*/
            axios.defaults.headers.common['language'] = store.state.lang;
            window.token = JSON.parse(localStorage.getItem('user_info')).token;
            axios.defaults.headers.common['token'] = window.token;

            this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {
                type: 'client_level,sales_list'
            }).then(res => {

                if (res.code == 1) {
                    this.configurationData = res.data;
                    //异步造成过滤报错
                    this.handleClick();
                    this.clientCompanys();

                } else if(res.code == 2){

                    //token过期处理
                    this.common.tokenExpired(res.msg);

                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }
            });
            // this.getQueryOptions('getQueryOptions',()=>{
            //   this.platformOptions = res.data
            // })
           

        },
        mounted() {
        },
        methods: {

            //添加百分号
            addPercentSign(val){
                if (!val) {
                    val = '0.00';
                }
                return val + '%';
            },

            enterExport(){
                var export_url = `${this.$phpBaseURL}v1/media/exportDay?`;
                var id_str = 'export_day';
                this.enterExportCommon(export_url, id_str, this.ajaxDataSearch);
            },

            enterExportMonth(){
                var export_url = `${this.$phpBaseURL}v1/media/exportMonth?`;
                var id_str = 'export_month';
                this.enterExportCommon(export_url, id_str, this.ajaxDataSearchMonth);
            },

            enterExportCommon(export_url, id_str, ajaxDatas){
                console.log(export_url);

                var export_obj = document.getElementById(id_str);

                var query_obj = {
                    project_name: ajaxDatas.project_name,
                    user_id: ajaxDatas.user_id,
                    dept_id: ajaxDatas.dept_id,
                    industry: ajaxDatas.industry,
                    company_id: ajaxDatas.company_id,
                    client_level: ajaxDatas.client_level,
                    platform: ajaxDatas.platform,
                    from_date: ajaxDatas.applicationTime[0],
                    to_date: ajaxDatas.applicationTime[1],

                };
                for (var p in query_obj) {

                    export_url += p + '=' + query_obj[p] + '&';

                }
                export_url += 'token=' + window.token;

                console.log('export_url:');
                console.log(export_url);

                export_obj.href = export_url;
                export_obj.click();

            },

            enterExport_20200615(page){

                var startDate='';
                var endDate='';
                if(this.ajaxDataSearch.applicationTime) {
                    startDate=this.ajaxDataSearch.applicationTime[0];
                    endDate=this.ajaxDataSearch.applicationTime[1];
                }
                this.axios('GET', `${this.$phpBaseURL}v1/media/exportDay`, {
                    project_name: this.ajaxDataSearch.project_name,
                    user_id: this.ajaxDataSearch.user_id,
                    dept_id: this.ajaxDataSearch.dept_id,
                    industry: this.ajaxDataSearch.industry,
                    company_id: this.ajaxDataSearch.company_id,
                    client_level: this.ajaxDataSearch.client_level,
                    platform: this.ajaxDataSearch.platform,
                    from_date: startDate,
                    to_date: endDate,
                    page: page ? page : 1,

                }).then(res => {
                    console.log('res:::');
                    console.log(res);


                    // const content = res
                    // const blob = new Blob([content])
                    // const fileName = '导出信息.xlsx'
                    // if ('download' in document.createElement('a')) { // 非IE下载
                    //   const elink = document.createElement('a')
                    //   elink.download = fileName
                    //   elink.style.display = 'none'
                    //   elink.href = URL.createObjectURL(blob)
                    //   document.body.appendChild(elink)
                    //   elink.click()
                    //   URL.revokeObjectURL(elink.href) // 释放URL 对象
                    //   document.body.removeChild(elink)
                    // } else { // IE10+下载
                    //   navigator.msSaveBlob(blob, fileName)
                    // }

                    const content = res;
                    const blob = new Blob([content], {type: 'application/ms-excel'});
                    const fileName = '测试表格123.csv';
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }


                    // let blob = new Blob([res], {type: "application/vnd.ms-excel"});
                    // const aLink = document.createElement("a");
                    // // let blob = new Blob([res], {type: "text/csv"});
                    // aLink.href = URL.createObjectURL(blob);
                    // aLink.setAttribute('download', '媒介投放客户数据-日.csv'); // 设置下载文件名称
                    // aLink.click();
                    // document.body.appendChild(aLink)
                    // // this.$refs.loadElement.appendChild(aLink);


                    // if(res.status == "200") {
                    //   const aLink = document.createElement("a");
                    //   let blob = new Blob([res.data], {type: "application/vnd.ms-excel"})
                    //   aLink.href = URL.createObjectURL(blob)
                    //   // aLink.setAttribute('download', '客户资源' + '.xlsx') // 设置下载文件名称
                    //   aLink.click()
                    //   //document.body.appendChild(aLink)
                    //   this.$refs.loadElement.appendChild(aLink)
                    // }else{
                    //     this.$message({ message: res.msg, type: 'warning' });
                    // }
                    // if(res.code == 1){


                    // }else{
                    //     this.$message({ message: res.msg, type: 'warning' });
                    // }


                })
            },
            handleClick(){
                let that=this;

//                console.log(this.activeName);
                if (this.activeName == "first") {
                    //点击回车进行搜索
                    document.onkeypress = function(e) {
                        var keycode = document.all ? event.keyCode : e.which;
                        if (keycode == 13) {
                            that.getMediaCustomerList();
                            return false;
                        }
                    };
                    this.getMediaCustomerList();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics, 5000);

                } else if (this.activeName == "second") {
                    //点击回车进行搜索
                    document.onkeypress = function(e) {
                        var keycode = document.all ? event.keyCode : e.which;
                        if (keycode == 13) {
                            that.getMediaCustomerMonthList();
                            return false;
                        }
                    };
                    this.getMediaCustomerMonthList();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics2, 5000);

                } else if (this.activeName == "third") {
                    this.getPlatformList();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics3, 5000);

                } else if (this.activeName == "fourth") {
                    this.getKaList();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics4, 5000);

                } else if (this.activeName == "fifth") {
                    this.getUpData();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics5, 5000);

                } else if (this.activeName == "sixth") {
                    this.getDownData();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics6, 5000);

                }

            },
            handleSelectionChange(){
                //应收总表多选
            },

            enterMediaCustomerList() {
                this.firstPage=1;

                this.getMediaCustomerList(1);
            },
            //媒介投放客户数据表--日
            getMediaCustomerList(page){
                this.loading1=true;
                this.firstPage=page;
                clearTimeout(this.timer);//清除定时定时器

                if (this.ajaxDataSearch.applicationTime == null) {
                    this.ajaxDataSearch.applicationTime = ['', '']
                }

                //媒介投放客户数据总表-日报
                this.axios('GET', `${this.$phpBaseURL}v1/media/clientDataDay`, {
                    project_name: this.ajaxDataSearch.project_name,
                    user_id: this.ajaxDataSearch.user_id,
                    dept_id: this.ajaxDataSearch.dept_id,
                    industry: this.ajaxDataSearch.industry,
                    company_id: this.ajaxDataSearch.company_id,
                    client_level: this.ajaxDataSearch.client_level,
                    platform: this.ajaxDataSearch.platform,
                    from_date: this.ajaxDataSearch.applicationTime[0],
                    to_date: this.ajaxDataSearch.applicationTime[1],
                    page: page ? page : 1,

                }).then(res => {
                    this.loading1=false;
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData = res.data.data;
                        this.total = res.data.total;
                        this.pageSize = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });



            },

            enterMediaCustomerMonthList() {

                this.firstPage=1;

                this.getMediaCustomerMonthList(1);
            },
            //媒介投放客户数据总表-月报
            getMediaCustomerMonthList(page){
                this.loading2=true;
                clearTimeout(this.timer);//清除定时定时器

                if (this.ajaxDataSearchMonth.applicationTime == null) {
                    this.ajaxDataSearchMonth.applicationTime = ['', '']
                }

                var startDate='';
                var endDate='';
                if(this.ajaxDataSearchMonth.applicationTime) {
                    startDate=this.ajaxDataSearchMonth.applicationTime[0];
                    endDate=this.ajaxDataSearchMonth.applicationTime[1];
                }
                this.axios('GET', `${this.$phpBaseURL}v1/media/clientDataMonth`, {
                    project_name: this.ajaxDataSearchMonth.project_name,
                    user_id: this.ajaxDataSearchMonth.user_id,
                    dept_id: this.ajaxDataSearchMonth.dept_id,
                    industry: this.ajaxDataSearchMonth.industry,
                    company_id: this.ajaxDataSearchMonth.company_id,
                    client_level: this.ajaxDataSearchMonth.client_level,
                    platform: this.ajaxDataSearchMonth.platform,
                    from_date: startDate,
                    to_date: endDate,
                    page: page ? page : 1,

                }).then(res => {
                    this.loading2=false;
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData = res.data.data;
                        this.total = res.data.total;
                        this.pageSize = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },

            //项目回款弹窗列表
            getProjectMoneyBack(id){
                this.axios('GET', `${this.$phpBaseURL}v1/media/projectMoneyBack`, {
                    project_id: id
                }).then(res => {
//                    console.log(res.data)
                    if (res.code == 1) {
                        this.projectMoneyBackList = res.data;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },


            getPlatformList(){
                this.firstPage=1;
                //平台完成情况
                this.init3();
            },

            enterKaList() {
                this.firstPage=1;

                this.getKaList();
            },
            //KA客户消耗变化
            getKaList(page){
                this.loading4=true;
                this.firstPage=page;
                clearTimeout(this.timer);//清除定时定时器

                //KA客户消耗情况
                this.axios('get', `${this.$phpBaseURL}v1/kaConsumeChange/getList`, {
                    token: JSON.parse(localStorage.getItem('user_info')).token,
                    client_company_id:this.kaInfo.client_company_id,
                    industry_id:this.kaInfo.industry_id,
                    platform:this.kaInfo.platform,
                    year:this.kaInfo.year,
                    month:this.kaInfo.month,
                    page:page?page:1

                }).then(res => {
                    this.loading4=false;
                    if (res.code == 1) {
                        this.kaList = res.data.data;
                        this.total4 = res.data.total;
                        this.pageSize4 = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },

            //消耗增长最多客户TOP10
            getUpData(){
                clearTimeout(this.timer);//清除定时定时器

                //增长top10
                axios.defaults.headers.common['language'] = store.state.lang;
                axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
                this.axios('get', `${this.$phpBaseURL}v1/kaConsumeAdds/getList?client_company_id=${this.topInfo.client_company_id}&industry_id=${this.topInfo.industry_id}&level_id=${this.topInfo.level_id}&data_date=${this.topInfo.data_date}`, {
                    token: JSON.parse(localStorage.getItem('user_info')).token,
                }).then(res => {
                    this.loading5=false;
                    if (res.code == 1) {
                        this.upList = res.data
                        this.total5 = res.data.total;
                        this.pageSize5 = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            getDownData(){
                clearTimeout(this.timer);//清除定时定时器

                //下降top10
                axios.defaults.headers.common['language'] = store.state.lang;
                axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
                this.axios('get', `${this.$phpBaseURL}v1/kaConsumeCuts/getList?client_company_id=${this.topInfo.client_company_id}&industry_id=${this.topInfo.industry_id}&level_id=${this.topInfo.level_id}&data_date=${this.topInfo.data_date}`, {
                    token: JSON.parse(localStorage.getItem('user_info')).token,
                }).then(res => {
                    this.loading6=false;
                    if (res.code == 1) {
                        this.downList = res.data
                        this.total6 = res.data.total;
                        this.pageSize6 = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            clientCompanys(){
                axios.defaults.headers.common['language'] = store.state.lang;
                axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
                this.axios('get', `${this.$javaBaseURL}clientCompany/selectAll`, {}).then(res => {
                    if (res.code == 1) {
                        this.clientCompanyList = res.data;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },
            getKaInfo(obj){
                console.log(obj)

                console.log(this.kadaterange)
                /*kaDetail:{
                 client_company_name:'',
                 industry:'',
                 info:{

                 }
                 }*/
                /*  查询图表的请求数据封装  */

                if (obj) {
                    this.kaDetail.client_company_name = obj.client_company_name;
                    this.kaDetail.platform = obj.platform;
                    this.kaDetail.industry = obj.industry_name;
                    this.kaAjaxDData.client_company_id = obj.client_company_id;
                    this.kaAjaxDData.industry_id = obj.industry_id;
                    this.kaAjaxDData.platform = obj.platform.toString();
                }
                if (this.kadaterange == null) {
                    this.kaAjaxDData.from_date = '';
                    this.kaAjaxDData.to_date = '';
                } else {
                    this.kaAjaxDData.from_date = moment(this.kadaterange).format('YYYY-MM-DD');
                    this.kaAjaxDData.to_date = moment(this.kaAjaxDData.from_date).subtract(14, 'days').format('YYYY-MM-DD');
                }


                this.axios('get', `${this.$phpBaseURL}v1/kaConsumeChange/kaConsumeChart?client_company_id=${this.kaAjaxDData.client_company_id}&platform=${this.kaAjaxDData.platform}`, {
                    token: JSON.parse(localStorage.getItem('user_info')).token,
                }).then(res => {
                    if (res.code == 1) {
                        this.kaDetail.info = res.data;
                        this.drawChart();
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },
            drawChart(){
                var dom = document.getElementById("chart");
                var myChart = echarts.init(dom);
                var option = null;

                let legenddata = [];
                let xAxisdata = [];
                let seriesdata = [];

                /*"1": [
                 {
                 "data_date": "2020-04-26",
                 "consume": "0.00"
                 },
                 {
                 "data_date": "2020-04-27",
                 "consume": "0.00"
                 },
                 {
                 "data_date": "2020-04-28",
                 "consume": "0.00"
                 }
                 ]*/

                let that = this;
                for (let key in this.kaDetail.info) {
                    let tmpArr = [];
                    this.kaDetail.info[key].forEach(function (v) {
                        if (xAxisdata.length < that.kaDetail.info[key].length) {

                            xAxisdata.push(v.data_date)
                        }
                        tmpArr.push(v.consume)
                    })


                    if (key == '1') {
                        legenddata.push('Facebook');
                        seriesdata.push(
                            {
                                name: 'Facebook',
                                type: 'line',
//                          stack: '总量',
                                data: tmpArr
                            }
                        );
                    } else if (key == '2') {
                        legenddata.push('Twitter');
                        seriesdata.push(
                            {
                                name: 'Twitter',
                                type: 'line',
//                          stack: '总量',
                                data: tmpArr
                            }
                        );
                    } else if (key == '3') {
                        legenddata.push('Google');
                        seriesdata.push(
                            {
                                name: 'Google',
                                type: 'line',
//                          stack: '总量',
                                data: tmpArr
                            }
                        );
                    } else {
                        legenddata.push('TikTok');
                        seriesdata.push(
                            {
                                name: 'TikTok',
                                type: 'line',
//                          stack: '总量',
                                data: tmpArr
                            }
                        );
                    }

                }


                option = {
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
//                data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
                        data: legenddata
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
//                data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                        data: xAxisdata
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: /*[
                     {
                     name: '邮件营销',
                     type: 'line',
                     stack: '总量',
                     data: [120, 132, 101, 134, 90, 230, 210]
                     },
                     {
                     name: '联盟广告',
                     type: 'line',
                     stack: '总量',
                     data: [220, 182, 191, 234, 290, 330, 310]
                     },
                     {
                     name: '视频广告',
                     type: 'line',
                     stack: '总量',
                     data: [150, 232, 201, 154, 190, 330, 410]
                     },
                     {
                     name: '直接访问',
                     type: 'line',
                     stack: '总量',
                     data: [320, 332, 301, 334, 390, 330, 320]
                     },
                     {
                     name: '搜索引擎',
                     type: 'line',
                     stack: '总量',
                     data: [820, 932, 901, 934, 1290, 1330, 1320]
                     }
                     ]*/ seriesdata
                };
                if (option && typeof option === "object") {
                    myChart.setOption(option, true);
                }
            },
            //获取搜索列表
            getQueryOptions(type){
                this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {
                    type: type
                }).then(res => {
                    console.log(res.data)
                    if (res.code == 1) {
                        this.configurationData = res.data;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });
            },


            /*平台完成情况相关*/
            init3(page){
                this.loading3=true;
                clearTimeout(this.timer);//清除定时定时器

                this.ajaxDataSearch3.page = page ? page : 1;
                this.axios('get', `${this.$phpBaseURL}v1/media/platformCompletime`, this.ajaxDataSearch3).then(res => {
                    this.loading3=false;
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData3 = res.data.data;
                        this.total3 = res.data.total;
                        console.log('table2:', this.tableData3)
                        //                    this.pageSize = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            cancleFacebook(){
                //隐藏添加Facebook弹框
                this.eidtFacebookVisible = false
            }
            ,
            editFacebookNew(){
                this.addFacebookFormData.year = '';
                this.addFacebookFormData.quarter = '';
                this.addFacebookFormData.quarter_kpi = '';
                this.addFacebookFormData.curr_cost = '';
                this.addFacebookFormData.dep_id = '';
                this.addFacebookFormData.contribution_rate = '';
                this.addFacebookFormData.income = '';
                this.addFacebookFormData.cost = '';
                this.addFacebookFormData.gross_profit = '';

                this.eidtFacebookVisible = true
            },
            //计算完成情况
            countCompletionStatus() {
                console.log(this.addFacebookFormData.curr_cost);
                console.log(this.addFacebookFormData.quarter_kpi);
                if (this.addFacebookFormData.quarter_kpi && this.addFacebookFormData.curr_cost) {
                    var tt = this.addFacebookFormData.curr_cost / this.addFacebookFormData.quarter_kpi;
                    this.completionStatus = tt.toFixed(2);
                }

            },
            //新建Facebook--新客数据提交
            addFacebook(){

                //添加Facebook
                this.isFacebookLoading = true
                this.axios('post', `${this.$phpBaseURL}v1/media/xinkeStore`, this.addFacebookFormData).then(res => {
                    this.isFacebookLoading = false
                    if (res.code == 1) {
                        this.common.statisticsAdd('新建Facebook--新客数据','2');

                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });

                        this.eidtFacebookVisible = false;
                        this.getPlatformList();

                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },
            editKPI(){
                //新建季度KPI
                this.kpiFormData.year = '';
                this.kpiFormData.quarter = '';
                this.kpiFormData.platform = ''
                this.kpiFormData.quarter_kpi = ''
                this.eidtDataVisible = true
            },
            //新建季度KPI提交
            addKpi(){

                //添加KPI
                this.isKPILoading = true;
                this.axios('post', `${this.$phpBaseURL}v1/media/platformCompletimeStore`, this.kpiFormData).then(res => {
                    this.isKPILoading = false
                    if (res.code == 1) {
                        this.common.statisticsAdd('新建季度KPI','2');

                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });

                        this.eidtDataVisible = false
                        this.getPlatformList();

                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }

                })

            },
            cancleKPI(){
                this.eidtDataVisible = false
            },

            //频次统计
            addStatistics() {
                this.common.statisticsAdd('媒介投放客户数据表--日','1')
            },
            addStatistics2() {
                this.common.statisticsAdd('媒介投放客户数据表--月','1')
            },
            addStatistics3() {
                this.common.statisticsAdd('平台完成情况','1')
            },
            addStatistics4() {
                this.common.statisticsAdd('KA客户消耗变化','1')
            },
            addStatistics5() {
                this.common.statisticsAdd('消耗增长最多客户TOP10','1')
            },
            addStatistics6() {
                this.common.statisticsAdd('消耗下降最多客户TOP10','1')
            },
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>

<style lang="scss" scoped>
    .w150 {
        width: 150px
    }

    .mediaDelivery {
        .custom-status {
            width: 64px;
            height: 24px;
            display: inline-block;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            &.unusual {
                // 异常用户
                background: rgba(254, 102, 100, 0.15);
                color: #FE6664;
            }
            &.regular {
                //一般用户
                background: rgba(38, 196, 184, 0.15);
                color: #26C4B8;
            }
            &.bad-debt {
                background: rgba(247, 185, 77, 0.15);
                color: #F7B94D
            }
        }
        .el-dialog-self main {
            max-height: 500px;
            overflow-y: auto;
        }
    }
    
</style>
<style >
    .el-select-dropdown__wrap{
        max-width: 350px!important;
    }
</style>