<template>
  <div class="main-div">
    <div v-for="(i, k) in infoTable" :key="k" style="margin-bottom: 40px">
      <p class="main-title">
        {{ i.ioName }}
        <span style="font-size: 14px" class="bluefont" @click="openDetail(i)"
          >修改IO</span
        >
      </p>
      <el-table :data="i.arr">
        <el-table-column
          prop="accountName"
          label="广告账户名称"
        ></el-table-column>
        <el-table-column prop="accountId" label="广告账户ID"></el-table-column>
        <el-table-column prop="accountStatus" label="状态">
          <template slot-scope="scope">
            {{ scope.row.accountStatus == 0 ? "取消授权" : "" }}
            {{ scope.row.accountStatus == 1 ? "正常" : "" }}
            {{ scope.row.accountStatus == 2 ? "关闭" : "" }}
            {{ scope.row.accountStatus == 3 ? "封户" : "" }}
            {{ scope.row.accountStatus == 4 ? "空户" : "" }}
          </template>
        </el-table-column>
        <el-table-column label="时间范围">
          <template slot-scope="scope">
            {{ scope.row.startTime }} ~
            {{ scope.row.endTime ? scope.row.endTime : "至今" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="clientCompanyName"
          label="归属客户"
        ></el-table-column>
        <el-table-column
          prop="accountCompanyName"
          label="开户公司"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arr: [],
      infoTable: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.arr = Array.from(new Set(this.$route.query.id.split(",")));
      this.getIOAccountIds();
    }
  },
  methods: {
    openDetail(data) {
      this.$router.push({
        name: "ioDetail",
        query: {
          id: data.ioId,
          from: "errorIOs",
          param: this.$route.query.id,
        },
      });
    },
    getIOAccountIds() {
      this.axios(
        "POST",
        `${this.$javaBaseURL}accountMedia/queryIOAccountIds`,
        this.arr
      ).then((res) => {
        if (res.code == 1) {
          this.infoTable = res.data;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
  },
};
</script>
