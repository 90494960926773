<template>
  <div style="margin:24px">
    <div style="background:#fff;padding:24px;display:flex;align-items:center;">
      <i class="el-icon-back" @click="backHistory" style="cursor:pointer;"></i>
      <p class="main-title" style="margin-bottom:0;margin-left:20px;">申请开户</p>
    </div>
    <div class="openApplication" style="margin-top:24px;">
      <el-tabs type="border-card" v-model="activeTabName">
        <el-tab-pane label="Facebook" name="1">
          <div class="whitebg">
            <el-button size="mini" type="primary" @click="goFacebook">跳转到Facebook的OE链接开户</el-button>
            <el-button type="text" @click.prevent="copy(facebookUrl)">复制链接</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Twitter" name="2">
          <div class="whitebg">
            <p class="main-title">账户基本设置</p>
            <el-form :inline="true" label-position="left" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="开户数量" required style="width:31%">
                <el-input v-model="applicationData.accountOpenQuantity" @blur="addAccountInfo()" type="number" min="0"></el-input>
              </el-form-item>
              <el-form-item label="账户时区" required style="width:31%">
                <el-select v-model="applicationData.timeZone" clearable placeholder="账户时区">
                  <el-option
                    v-for="(item,k) in timeZoneList"
                    :key="k"
                    :label="item.timeZone"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="投放目标" required style="width:33%">
                <el-checkbox-group v-model="applicationData.serviceTargetId">
                  <el-checkbox :label="1">Brand Awarenes</el-checkbox>
                  <el-checkbox :label="2">App Installs</el-checkbox>
                  <el-checkbox :label="3">Drive Traffic to Website</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="投放方式" required style="width:31%">
                <el-select v-model="applicationData.serviceType" clearable placeholder="投放方式">
                  <el-option
                    v-for="(item,k) in serviceTypeList"
                    :key="k"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="投放国家" required style="width:31%">
                <el-select
                  filterable
                  v-model="applicationData.serviceCountry"
                  clearable
                  placeholder="投放国家"
                >
                  <el-option
                    v-for="(item,k) in countryList"
                    :key="k"
                    :label="item.code+' | '+item.countryName+' | ' +item.country"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="预计出价(美元)" required style="width:31%">
                <el-input v-model="applicationData.estimatedBid" type="number"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="whitebg" style="margin-top:24px;">
            <p class="main-title">广告账户信息</p>
            <el-table
              :data="applicationData.accountInfo"
              border
              style="width: 100%">
              <el-table-column label="广告账户">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.accountName" placeholder="请输入"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="充值金额（美元）">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.defaultRecharge"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="whitebg" style="margin-top:24px;">
            <p class="main-title" style="display:flex;align-items:center;">开户公司 <el-alert style="margin-left:20px;display:inline-block;width:300px;" title="首次录入的公司信息将被加入开户公司库" type="success"></el-alert></p>
            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="公司名称（英文）" required style="width:31%">
                <el-input v-model="applicationData.accountCompanyEnglishName"></el-input>
              </el-form-item>
              <el-form-item label="公司地址（英文）" required style="width:31%">
                <el-input v-model="applicationData.accountCompanyEnglishAddress"></el-input>
              </el-form-item>
              <el-form-item label="客户类别" required style="width:31%">
                <el-select style="width:157px;" v-model="applicationData.clientType" clearable placeholder="客户类别">
                  <el-option v-for="(item,k) in TWClientType" :key="k" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="联系人姓名" style="width:31%">
                <el-input v-model="applicationData.contactPerson"></el-input>
              </el-form-item>
              <el-form-item label="联系人电话" style="width:31%">
                <el-input v-model="applicationData.contactInformation"></el-input>
              </el-form-item>
              <el-form-item label="营业执照" style="width:31%;display:inline-flex;">
                  <el-upload
                    style="margin-left:13px;"
                    :headers="uploaderHeader"
                    :action="common.java+'open/upload'"
                    :show-file-list="true"
                    :on-success="handleAvatarSuccess"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <p style="font-size:12px;line-height: 16px;margin-top: 10px;color: #f00;margin-left:13px;">请输入jpg、png格式不超过5M的营业执照图片</p>
              </el-form-item>
            </el-form>
            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="产品链接" required style="width:31%">
                <el-input v-model="applicationData.productLink"></el-input>
              </el-form-item>
              <el-form-item label="粉丝页链接" required style="width:31%">
                <el-input v-model="applicationData.pageLink"></el-input>
              </el-form-item>
              <el-form-item label="官网链接" required style="width:31%">
                <el-input v-model="applicationData.officialWebsiteLink"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="whitebg" style="margin-top:24px;">
            <el-button type="primary" @click="submitApply(2)">提交</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Google" name="3">
          <div class="whitebg">
            <p class="main-title">账户基本设置</p>
            <el-form :inline="true" label-position="left" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="开户数量" required style="width:31%">
                <el-input v-model="applicationData.accountOpenQuantity" @blur="addAccountInfo()" type="number" min="0"></el-input>
              </el-form-item>
              <el-form-item label="账户时区" required style="width:31%">
                <el-select v-model="applicationData.timeZone" clearable placeholder="账户时区">
                  <el-option
                    v-for="(item,k) in googleTimeZoneList"
                    :key="k"
                    :label="item.timeZone"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="币种" required style="width:33%">
                <el-select v-model="applicationData.currency" clearable placeholder="请选择币种">
                  <el-option
                    v-for="(item,k) in currencyList"
                    :key="k"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="whitebg" style="margin-top:24px;">
            <p class="main-title">广告账户信息</p>
            <el-table
              :data="applicationData.accountInfo"
              border
              style="width: 100%">
              <el-table-column label="广告账户">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.accountName"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="whitebg" style="margin-top:24px;">
            <p class="main-title" style="display:flex;align-items:center;">开户公司 <el-alert style="margin-left:20px;display:inline-block;width:300px;" title="首次录入的公司信息将被加入开户公司库" type="success"></el-alert></p>
            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="开户公司（英文）" required style="width:31%">
                <el-input v-model="applicationData.accountCompanyEnglishName"></el-input>
              </el-form-item>
              <el-form-item label="公司地址（英文）" required style="width:31%">
                <el-input v-model="applicationData.accountCompanyEnglishAddress"></el-input>
              </el-form-item>
              <el-form-item label="客户类别" required style="width:31%">
                <el-select v-model="applicationData.clientType" clearable placeholder="客户类别">
                  <el-option v-for="(item,k) in TWClientType" :key="k" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="联系人姓名" style="width:31%">
                <el-input v-model="applicationData.contactPerson"></el-input>
              </el-form-item>
              <el-form-item label=" 联系人电话" style="width:31%">
                <el-input v-model="applicationData.contactInformation"></el-input>
              </el-form-item>
              <el-form-item label=" 营业执照" style="width:31%;display:inline-flex;">
                <el-upload
                  style="margin-left:13px;"
                  :headers="uploaderHeader"
                  :action="common.java+'open/upload'"
                  :show-file-list="true"
                  :on-success="handleAvatarSuccess"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
                <p style="font-size:12px;line-height: 16px;margin-top: 10px;color: #f00;margin-left:13px;">请输入jpg、png格式不超过5M的营业执照图片</p>
              </el-form-item>
            </el-form>
            <el-form label-position="left" :inline="true" :model="applicationData" size="mini" label-width="140px">
              <el-form-item label="产品链接" required style="width:31%">
                <el-input v-model="applicationData.productLink"></el-input>
              </el-form-item>
              <el-form-item label="粉丝页链接" required style="width:31%">
                <el-input v-model="applicationData.pageLink"></el-input>
              </el-form-item>
              <el-form-item label="官网链接" required style="width:31%">
                <el-input v-model="applicationData.officialWebsiteLink"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="whitebg" style="margin-top:24px;">
            <el-button type="primary" @click="submitApply(3)">提交</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  
</template>

<script>
/*import  from '';*/
import moment from "moment";
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      activeTabName: "1",
      operate_item: {},
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 0,
      user_id: "", //选中的被授权人
      ajaxDataSearch: {
        account_status: "",
        project_name: "", //否	string	项目名称；如：今日头条-cscs
        client_company_name: "", //否	string	客户名称；如：今日头条-cscs
        project_created_at: ["", ""], //否	string	项目创建时间；如：2020-04-24
        business_type: "", //是	int	业务类(1:整合营销,2:媒介投放,3:技术产品)；如：1
        project_manager_id: "", //是	int	项目负责人即客户负责人；如：1
        dep_id: "", //否	string	部门ID；如：968485f47cc0b7ac7cf36d4868689866
        approval_status: "", //否	int	审批状态(1:待审批,2:审批通过,3:审批失败)
        status: "" //否	int	合同状态(1:正常,2:暂停,3:延期,4:取消)
      },
      optionsTrade: [],
      optionsStatus: [
        {
          value: "1",
          label: "待审核"
        },
        {
          value: "2",
          label: "审核通过"
        },
        {
          value: "3",
          label: "审核失败"
        }
      ],
      projectList: [],
      projectSpeedLists: [
        {
          amount_income: 0.0,
          cost_amount: 0.0,
          paid_outgoing_amount: 0.0,
          settlement_amount_received: 0.0
        }
      ],
      payOutCostIsTrue: true,
      projectCostIsTrue: true,
      info: {
        basic_data: {
          project_numbering: "vrg34gtrht",
          project_id: "wefew43yt45h",
          project_name: "cdcdcd",
          business_type: 1,
          project_manager_id: "4F81D19F600000011924017E51357265",
          approval_status: 0,
          status: 0,
          sales_id: "4F81D19F600000011924017E51357265",
          project_created_at: "2020-04-23 16:33:18",
          cycle_start: "2020-04-23",
          cycle_end: "2020-04-23",
          applicant_id: "4F81D19F600000011924017E51357265",
          remark: "cscs",
          customer_product_type: "vsvs",
          project_overview: "sssss",
          date: "2020-04-23",
          estimated_income: "0.00",
          estimated_cost: "0.00",
          estimated_outsourcing_cost: "0.00",
          project_cost: "0.00",
          estimated_gross_profit: "0.00",
          estimated_gross_margin: "0.00",
          estimated_taxe: "0.00",
          estimated_after_tax: "0.00",
          amount_income: "0.00",
          cost_amount: "0.00",
          paid_outgoing_amount: "0.00",
          settlement_amount_received: "0.00",
          change_ratio: "0.00",
          client_company_name: "宇宙company",
          industry_name:
            "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等",
          contract_name: null,
          department_name: null,
          contact_name: null,
          project_manager_name: "王凯",
          sales_name: "王凯",
          applicant_name: "王凯"
        },
        project_member: [
          {
            member_id: "dgerg45yrherg432",
            dep_id: "efdsvsdf2",
            department_name: "部门名称cs",
            user_name: "王玉影_cscs"
          }
        ],
        expected_pay_out_cost_details: [
          {
            purchasing_channel: "1",
            supplier_name: "1",
            business_content: "1",
            business_cycle: "1",
            purchase_amount: "1.00",
            remark: "1"
          }
        ],
        expected_project_cost_details: [
          {
            project_cost_content: "1",
            purchase_amount: "1.00",
            remark: "1"
          }
        ],
        project_schedule_happening: [
          {
            amount_income: "0.00",
            cost_amount: "0.00",
            paid_outgoing_amount: "0.00",
            settlement_amount_received: "0.00",
            change_ratio: "0.00"
          }
        ],
        project_tender_file: [
          {
            file_name: "cs",
            file_size: 0
          }
        ],
        project_meeting_summary: [],
        project_purchase_acceptance_file: [],
        project_case_report_file: [],
        project_case_file: [],
        project_file: [
          {
            file_name: "cs",
            file_size: 0
          }
        ]
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      },
      project_id: "",
      project_status: "",
      openZhPop: false,
      radio: "1",
      isPre: true,
      editingProObj: {
        project_id: "fwef2fre",
        project_name: "今日头条-cscs",
        business_type: 1,
        project_manager_id: "dgerg45yrherg432",
        approval_status: 1,
        status: 1,
        sales_id: "dgerg45yrherg432",
        project_created_at: "2020-04-20 16:31:44",
        project_cycle: "",
        applicant_id: "dgerg45yrherg432",
        remark: "cs",
        client_company_name: "client_company--cscs",
        level_id: "耗费iuwehiw76gfie",
        level_name: "其他",
        level_consume_value: "一事一议",
        industry_name: "cs",
        contract_name: "合同名称cs",
        contract_id: "扫地yiewbieb",
        contract_start: "2020-04-16 16:50:35",
        contract_end: "2020-04-16 16:50:35",
        name: "cs",
        contact_name: "contact_name--cscs",
        status_name: "正常",
        approval_status_name: "",
        business_type_name: "整合营销",
        project_manager_name: "王玉影_cscs",
        sales_name: "王玉影_cscs",
        applicant_name: "王玉影_cscs",
        is_change: "1",
        project_numbering: "dsifh8273yfgf"
      },
      accountInfo: {
        account_info: [
          {
            account: "这是平台账号1",
            max_number_accounts: "",
            daily_account_opening_limit: "",
            is_prepaid_recharge: "0",
            credit_amount: "",
            distribution_credit_amount: "0.00",
            sales_id: "",
            front_end_id: "",
            am_id: "",
            optimizer_id: "",
            contract_start_time: null,
            contract_end_time: null,
            email_address: ""
          }
        ],
        project_principal: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        sales_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        front_end_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        am_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ],
        optimizer_list: [
          {
            id: "4F81D19F600000011924017E51357265",
            name: "王凯"
          }
        ]
      },
      ajaxDataOpen: {
        project_id: "", //	是	string	项目ID；如：968485f47cc0b7ac7cf36d4868689866
        account: "", //	是	string	账户；如：36d4868689866
        account_password: "", //	是	string	密码；如：968485f47cc0
        max_number_accounts: "", //	是	int	最大开户数量；如：1
        daily_account_opening_limit: "", //	是	int	每日开户上限；如：1
        is_prepaid_recharge: "1", //	是	int	是否是预付；如：1
        credit_amount: "", //	是	int	客户可用授信额度；如：1
        distribution_credit_amount: "", //	是	int	分配授信额度；如：1
        contract_id: "", //	是	string	合同ID；如：968485f47cc0b7ac7cf36d4868689866
        contract_start_time: "", //	是	string	合同有效期开始时间；如：2020-04-28
        contract_end_time: "", //	是	string	合同有效期结束时间；如：2020-04-28
        sales_id: "", //	是	int	销售ID；如：1
        //                    front_end_id:'',//	是	int	前端ID；如：1
        am_id: "", //	是	int	AMID；如：1
        optimizer_id: "", //	是	int	优化师ID；如：1
        email_address: "" //	是	string	邮箱地址；如：9023878237@qq.com
      },
      project_id: "", //选中的项目ID
      selRow: {}, //选中的行
      authPerson: [], //登录授权弹框--被授权人
      applicationData: {
        openListId: "",
        platform: "",
        applicationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        serviceType: "",
        isFirst: "",
        amId: "",
        accountOpenQuantity: "",
        timeZone: "",
        isRechargeAmount: "",
        remarks: "",
        accountCompanyChineseName: "",
        accountCompanyEnglishName: "",
        firstClassAgent: "",
        accountCompanyBmId: "",
        accountCompanyIndustry: "",
        advertiserIndustry: "",
        clientName: "",
        clientEmail: "",
        officialWebsiteLink: "",
        isSecondaryAgent: "",
        secondaryAgentBmId: "",
        businessLicenseNumber: "",
        accountCompanyChineseAddress: "",
        accountCompanyEnglishAddress: "",
        accountCompanyPostalCode: "",
        pageLink: "",
        pageId: "",
        verification: "",
        productLink: "",
        promoteObject: "",
        businessLicenseLink: "",
        isRecharge: "",
        rechargeRemarks: "",
        defaultRecharge: 5000,
        clientType: "",
        contactPerson: "",
        contactInformation: "",
        serviceTargetId: [],
        estimatedBid: "",
        serviceCountry: "",
        accountBudget: "",
        agentId: "",
        isContainMcc: "",
        currency: "",
        registrationArea: "",
        bytedanceEmail: "",
        bytedancePassword: "",
        accountOpeningRemarks: "",
        isAgree: "",
        accountName: "",
        accountInfo:[]
      },
      googleapplydata: {
        applicationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        accountOpenQuantity: "",
        accountNamestr: "",
        timeZone: "",
        currency: ""
      },
      currencyList: [
        {
          label: "美元",
          value: "1"
        },
        {
          label: "人民币",
          value: "2"
        },
        {
          label: "港元",
          value: "3"
        }
      ],
      timeZoneList: [],
      googleTimeZoneList: [],
      facebookUrl: "",
      TWClientType: [],
      countryList: [],
      serviceTypeList: [
        {
          label: "自投",
          value: "1"
        },
        {
          label: "代投",
          value: "2"
        }
      ],
      applyadsData: {},
      uploaderHeader: {}
    };
  },
  computed: {
    curRouter() {
      return this.$store.state.curRouter;
    }
  },
  watch: {
    curRouter(val) {
      if (val) {
        this.showFlag = 1;
      }
      console.log("val:", val);
    }
  },

  filters: {
    formatTime(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },

    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回未提交";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
    statusProjectClassFilter(value) {
      // 项目状态(1:正常,2:暂停,3:延期,4:取消
      let m = "";
      switch (value) {
        case 1:
          m = "status-zhengchang";
          break;
        case 2:
          m = "status-zanting";
          break;
        case 3:
          m = "status-yanqi";
          break;
        case 4:
          m = "status-quxiao";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.uploaderHeader.language = store.state.lang;
    this.uploaderHeader.token = JSON.parse(
      localStorage.getItem("user_info")
    ).token;

    this.getTimeZoneList();
    this.getGoogleTimeZoneList();
    this.getTWClientType();
    this.getCountrys();
    console.log(this.$route.query);
    if (this.$route.query.fburl) {
      this.facebookUrl = this.$route.query.fburl.oeLink
    }
  },
  methods: {
    backHistory(){
      if (this.$route.query.from) {
        this.$router.push({
          name:this.$route.query.from,
          query:this.$route.query
        })
      }
    },
    addAccountInfo(){
      this.applicationData.accountInfo = []
      for (let i = 0; i < this.applicationData.accountOpenQuantity; i++) {
        this.applicationData.accountInfo.push(
          {
            accountName:"",
            accountId:"",
            defaultRecharge:0
          }
        )
      }
    },
    // 复制
    copy(text) {
      var textArea = document.createElement("textarea");
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$message({
            message: "复制成功",
            type: "success"
          });
        }
      } catch (err) {
        console.log("err:", err);
      }

      document.body.removeChild(textArea);
    },
    //跳转连接
    goFacebook() {
      window.open(this.facebookUrl, "_blank");
    },
    // 获取tw时区
    getTimeZoneList() {
      this.axios("GET", `${this.$javaBaseURL}open/getTimeZone`).then(res => {
        if (res.code == 1) {
          this.timeZoneList = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 获取google时区
    getGoogleTimeZoneList() {
      this.axios("GET", `${this.$javaBaseURL}open/getGoogleTimeZone`).then(res => {
        if (res.code == 1) {
          this.googleTimeZoneList = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 获取国家
    getCountrys() {
      this.axios("GET", `${this.$javaBaseURL}open/getCountry`).then(res => {
        if (res.code == 1) {
          this.countryList = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 获取twitter客户类别
    getTWClientType() {
      this.axios("GET", `${this.$javaBaseURL}open/getTWClientType`).then(res => {
        if (res.code == 1) {
          this.TWClientType = res.data;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 申请开户按钮
    submitApply(value) {
      var postdata = {};
      if (value == 3) {
        // google申请
        this.applicationData.platform = 3;
        if (this.applicationData.accountOpenQuantity < 1) {
          this.$message({ message: "请输入开户数量", type: "warning" });
          return;
        } else if (this.applicationData.clientType == "") {
          this.$message({ message: "请选择客户类别", type: "warning" });
          return;
        } else if (this.applicationData.currency == "") {
          this.$message({ message: "请选择币种", type: "warning" });
          return;
        } else if (this.applicationData.accountCompanyEnglishName == "") {
          this.$message({ message: "请输入开户公司（英文）", type: "warning" });
          return;
        } else if (this.applicationData.accountCompanyEnglishAddress == "") {
          this.$message({ message: "请输入公司地址（英文）", type: "warning" });
          return;
        } else if (this.applicationData.productLink == "") {
          this.$message({ message: "请输入产品链接", type: "warning" });
          return;
        } else if (this.applicationData.pageLink == "") {
          this.$message({ message: "请输入粉丝页地址", type: "warning" });
          return;
        } else if (this.applicationData.officialWebsiteLink == "") {
          this.$message({ message: "请输入官网地址", type: "warning" });
          return;
        } else if (this.applicationData.timeZone == "") {
          this.$message({ message: "请选择账户时区", type: "warning" });
          return;
        } else {
          postdata = this.applicationData;
        }
      } else {
        // twitter申请
        this.applicationData.platform = 2;
        if (this.applicationData.accountOpenQuantity < 1) {
          this.$message({ message: "请输入开户数量", type: "warning" });
          return;
        } else if (this.applicationData.accountCompanyEnglishName == "") {
          this.$message({ message: "请输入开户公司（英文）", type: "warning" });
          return;
        } else if (this.applicationData.accountCompanyEnglishAddress == "") {
          this.$message({ message: "请输入公司地址（英文）", type: "warning" });
          return;
        } else if (this.applicationData.clientType == "") {
          this.$message({ message: "请选择客户类别", type: "warning" });
          return;
        } else if (this.applicationData.productLink == "") {
          this.$message({ message: "请输入产品链接", type: "warning" });
          return;
        } else if (this.applicationData.pageLink == "") {
          this.$message({ message: "请输入粉丝页地址", type: "warning" });
          return;
        } else if (this.applicationData.officialWebsiteLink == "") {
          this.$message({ message: "请输入官网地址", type: "warning" });
          return;
        } else if (this.applicationData.serviceTargetId.length < 1) {
          this.$message({ message: "请选择投放目标", type: "warning" });
          return;
        } else if (this.applicationData.estimatedBid == "") {
          this.$message({ message: "请输入预计出价", type: "warning" });
          return;
        } else if (this.applicationData.serviceCountry == "") {
          this.$message({ message: "请选择投放国家", type: "warning" });
          return;
        } else if (this.applicationData.serviceType == "") {
          this.$message({ message: "请选择投放方式", type: "warning" });
          return;
        } else if (this.applicationData.timeZone == "") {
          this.$message({ message: "请选择账户时区", type: "warning" });
          return;
        } else {
          postdata = this.applicationData;
        }
      }
      let flag_ = true
      console.log(postdata);
      if (postdata.accountInfo.length > 0) {
        postdata.accountInfo.forEach(item => {
          if (!item.accountName) {
            this.$message({ message: "请填写完整广告账户名称", type: "warning" });
            flag_ = false;
            return;            
          }
        })
      }
      if (!flag_) {
        return
      }
      postdata.clientCompanyId = this.$route.query.clientCompanyId
      this.axios("POST", `${this.$javaBaseURL}open/application`, postdata).then(
        res => {
          if (res.code == 1) {
            console.log(res.data);
            this.$message({ message: "操作成功", type: "success" });
            this.backHistory()
          } else if (res.code == 2) {
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.code == 1) {
        this.applicationData.fileName = res.data.fileName
        this.applicationData.businessLicenseLink = res.data.businessLicense
        console.log(this.applicationData.businessLicenseLink);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },

    downloadfile() {
      let token = JSON.parse(localStorage.getItem("user_info")).token;
      let url = `${this.$phpBaseURL}v1/project/projectDownload?token=${token}&project_id=${this.project_id}`;
      window.open(url, "_blank");
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("项目列表", "1");
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info-row {
  margin-bottom: 24px;
}
.zanwushuju {
  text-align: center !important;
  color: #909399;
}
.openZhPop {
  .main-inputBox {
    width: 290px;
  }
}
.main-info-paper_td {
  height: auto;
  min-height: 40px;
}
.status-zhengchang {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(38, 196, 184, 1);
    border-radius: 50%;
  }
}
.status-zanting {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(247, 185, 77, 1);
    border-radius: 50%;
  }
}
.status-yanqi {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(254, 102, 100, 1);
    border-radius: 50%;
  }
}
.status-quxiao {
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 7px;
    width: 6px;
    height: 6px;
    background: rgba(173, 179, 188, 1);
    border-radius: 50%;
  }
}
.main-selectBox .authperson {
  border: #01ace4 solid 1px;
  border-radius: 4px;
  padding: 0px 8px;
  margin-right: 16px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  box-sizing: border-box;
  color: #01ace4;
  margin-top: 4px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  // transition: all .3s;
}
.main-selectBox .authperson i {
  color: #adb3bc;
  font-size: 14px;
  position: absolute;
  right: -9px;
  top: -14px;
  cursor: pointer;
  display: none;
}
.main-selectBox .authperson:hover i {
  display: block !important;
}

</style>

<style lang="scss">
.openApplication {
  .el-tabs__content .whitebg{
    padding: 24px!important;
    background:#fff;
  }
}
</style>
