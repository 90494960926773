import Backstage from "../views/backstage/Index.vue";
//需确认路由
import CustomerList from "../views/backstage/mgt/CustomerList.vue";
import customerGroup from "../views/backstage/mgt/customerGroup/customerGroup.vue";
import GradeList from "../views/backstage/mgt/GradeList.vue";
import ContactsList from "../views/backstage/mgt/ContactsList.vue";
import ContractList from "../views/backstage/mgt/ContractList.vue";
import ProjectList from "../views/backstage/mgt/ProjectList.vue";
import media from "../views/backstage/mgt/recharge/media.vue";
import errorIOs from "../views/backstage/mgt/recharge/errorIOs.vue";
import open from "../views/backstage/mgt/recharge/open.vue";
import blackList from "../views/backstage/mgt/recharge/blackList.vue";
import exchangeList from "../views/backstage/mgt/recharge/exchangeList.vue";
import ioList from "../views/backstage/mgt/recharge/ioList.vue";
import accRecharge from "../views/backstage/mgt/recharge/accRecharge.vue";
import accZero from "../views/backstage/mgt/recharge/accZero.vue";
import createIO from "../views/backstage/mgt/recharge/createIO.vue";
import ioDetail from "../views/backstage/mgt/recharge/ioDetail.vue";
import suppliers from "../views/backstage/mgt/suppliers/suppliers.vue";
import apply from "../views/backstage/mgt/suppliers/apply.vue";
import detail from "../views/backstage/mgt/suppliers/detail.vue";
import user from "../views/backstage/mgt/user/user.vue";
import role from "../views/backstage/mgt/user/role.vue";
import auth from "../views/backstage/mgt/recharge/auth.vue";
import accountOpApply from "../views/backstage/mgt/recharge/accountOpApply.vue";
import operations from "../views/backstage/mgt/recharge/operations.vue";
import batch from "../views/backstage/mgt/recharge/batch.vue";
import appropriation from "../views/backstage/mgt/recharge/appropriation.vue";
//import rechargeManagement from "../views/backstage/mgt/recharge/rechargeManagement.vue"
import rechargeMedia from "../views/backstage/mgt/recharge/rechargeMedia.vue";
import operationLog from "../views/backstage/mgt/sys/operation.log.vue";
import frequencyStatistics from "../views/backstage/mgt/sys/frequencyStatistics.vue";
import rebateTplList from "../views/backstage/mgt/sys/rebateTplList.vue";
import closedAccount from "../views/backstage/mgt/sys/closedAccount.vue";
import serviceSetting from "../views/backstage/mgt/sys/serviceSetting";
import balanceRecharge from "../views/backstage/mgt/recharge/balanceRecharge";
//shine
import gatherData from "../views/backstage/mgt/datamanage/gatherdata.vue";
import gatherManamentData from "../views/backstage/mgt/datamanage/gatherManagementData.vue";
import shouldRecover from "@/views/backstage/mgt/datamanage/shouldRecover.vue";
import mediaDeliveryCustomersData from "@/views/backstage/mgt/datamanage/mediaDeliveryCustomersData.vue";

import rebate from "../views/backstage/mgt/data/rebate.vue";
import openApplication from "../views/backstage/mgt/recharge/openApplication.vue";

// 概况
import survey from "../views/backstage/survey.vue";

//消耗报表
import accountCostReport from "../views/backstage/costReport/accountCostReport.vue";
import customerCostReport from "../views/backstage/costReport/customerCostReport.vue";
import saleCostReport from "../views/backstage/costReport/saleCostReport.vue";
import companyCostReport from "../views/backstage/costReport/companyCostReport.vue";


const act = [{
  path: "/backstage",
  name: "Backstage",
  component: Backstage,
  children: [
    {
      path: "/survey",
      name: "survey",
      component: survey,
      meta: {
        title: '概况',
        requireAuth: true,
        crumbs: 'survey',
        nav: 0
      }
    },
    {
      path: "/accountCostReport",
      name: "accountCostReport",
      component: accountCostReport,
      meta: {
        title: '广告账户消耗报表',
        requireAuth: true,
        crumbs: '广告账户消耗报表',
        nav: 0
      }
    },
    {
      path: "/customerCostReport",
      name: "customerCostReport",
      component: customerCostReport,
      meta: {
        title: '客户消耗报表',
        requireAuth: true,
        crumbs: '客户消耗报表',
        nav: 0
      }
    },
    {
      path: "/saleCostReport",
      name: "saleCostReport",
      component: saleCostReport,
      meta: {
        title: '销售消耗报表',
        requireAuth: true,
        crumbs: '销售消耗报表',
        nav: 0
      }
    },
    {
      path: "/companyCostReport",
      name: "companyCostReport",
      component: companyCostReport,
      meta: {
        title: '开户公司消耗报表',
        requireAuth: true,
        crumbs: '开户公司消耗报表',
        nav: 0
      }
    },
    {
      path: "/customer",
      name: "CustomerList",
      component: CustomerList,
      meta: {
        title: '客户',
        requireAuth: true,
        crumbs: '客户/客户列表',
        nav: 0
      }
    },
    {
      path: "/customerGroup",
      name: "customerGroup",
      component: customerGroup,
      meta: {
        title: '客户',
        requireAuth: true,
        crumbs: '客户/客户组列表',
        nav: 0
      }
    },
    {
      path: "/errorIOs",
      name: "errorIOs",
      component: errorIOs,
      meta: {
        title: '归属IO',
        requireAuth: true,
        crumbs: '',
        nav: 0
      }
    },
    {
      path: "/level",
      name: "GradeList",
      component: GradeList,
      meta: {
        title: '客户',
        requireAuth: true,
        crumbs: '客户/客户级别列表',
        nav: 0
      }
    },
    {
      path: "/contacts",
      name: "ContactsList",
      component: ContactsList,
      meta: {
        title: '客户',
        requireAuth: true,
        crumbs: '客户/联系人',
        nav: 0
      }
    },
    {
      path: "/contract",
      name: "ContractList",
      component: ContractList,
      meta: {
        title: '合同',
        requireAuth: true,
        crumbs: '合同/合同列表',
        nav: 1
      }
    },
    {
      path: "/project",
      name: "ProjectList",
      component: ProjectList,
      meta: {
        title: '合同',
        requireAuth: true,
        crumbs: '项目/项目列表',
        nav: 2
      }
    },
    {
      path: "/media",
      name: "media",
      component: media,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/广告账户列表',
        nav: 3
      }
    },
    {
      path: "/open",
      name: "open",
      component: open,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/开户申请审批列表',
        nav: 3
      }
    },
    {
      path: "/rechargeMedia",
      name: "rechargeMedia",
      component: rechargeMedia,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/充值申请审批列表',
        nav: 3
      }
    },
    {
      path: "/balanceRecharge",
      name: "balanceRecharge",
      component: balanceRecharge,
      meta: {
        title: '余额充值列表',
        requireAuth: true,
        crumbs: '余额充值列表',
        nav: 3
      }
    },
    // {
    //   path: "/rechargeManagement",
    //   name: "rechargeManagement",
    //   component: rechargeManagement,
    //   meta: { title: '海外业务管理', requireAuth: true, crumbs:'海外业务管理/管理平台账户充值申请列表',nav:3 }
    // },
    {
      path: "/appropriation",
      name: "appropriation",
      component: appropriation,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/拨款申请审批列表',
        nav: 3
      }
    },
    {
      path: "/batch",
      name: "batch",
      component: batch,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/批量操作申请审批列表',
        nav: 3
      }
    },
    {
      path: "/auth",
      name: "auth",
      component: auth,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/授权记录列表',
        nav: 3
      }
    },
    {
      path: "/accountOpApply",
      name: "accountOpApply",
      component: accountOpApply,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/账户操作申请审批列表',
        nav: 3
      }
    },
    {
      path: "/operations",
      name: "operations",
      component: operations,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/账户操作申请审批列表',
        nav: 3
      }
    },
    {
      path: "/blackList",
      name: "blackList",
      component: blackList,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/黑名单列表',
        nav: 3
      }
    },
    {
      path: "/exchangeList",
      name: "exchangeList",
      component: exchangeList,
      meta: {
        title: '海外业务管理',
        requireAuth: true,
        crumbs: '海外业务管理/汇率列表',
        nav: 3
      }
    },
    {
      path: "/ioList",
      name: "ioList",
      component: ioList,
      meta: {
        title: 'IO管理',
        requireAuth: true,
        crumbs: 'IO管理/IO列表',
        nav: 3
      }
    },
    {
      path: "/accRecharge",
      name: "accRecharge",
      component: accRecharge,
      meta: {
        title: '账户充值',
        requireAuth: true,
        crumbs: '账户充值',
        nav: 3
      }
    },
    {
      path: "/accZero",
      name: "accZero",
      component: accZero,
      meta: {
        title: '账户清零',
        requireAuth: true,
        crumbs: '账户清零',
        nav: 3
      }
    },
    {
      path: "/createIO",
      name: "createIO",
      component: createIO,
      meta: {
        title: 'IO管理',
        requireAuth: true,
        crumbs: 'IO管理/IO列表/新建IO',
        nav: 3
      }
    },
    {
      path: "/ioDetail",
      name: "ioDetail",
      component: ioDetail,
      meta: {
        title: 'IO管理',
        requireAuth: false,
        crumbs: 'IO管理/IO列表/IO详情',
        nav: 3
      }
    },
    {
      path: "/suppliers",
      name: "suppliers",
      component: suppliers,
      meta: {
        title: '供应商管理',
        requireAuth: true,
        crumbs: '供应商管理/供应商列表',
        nav: 4
      }
    },
    {
      path: "/apply",
      name: "apply",
      component: apply,
      meta: {
        title: '供应商管理',
        requireAuth: true,
        crumbs: '供应商管理/采购申请列表',
        nav: 4
      }
    },
    {
      path: "/detail",
      name: "detail",
      component: detail,
      meta: {
        title: '供应商管理',
        requireAuth: true,
        crumbs: '供应商管理/供应商明细表',
        nav: 4
      }
    },
    {
      path: "/rebate",
      name: "rebate",
      component: rebate,
      meta: {
        title: '平台返点比例表',
        requireAuth: true,
        crumbs: '数据管理/平台返点比例表',
        nav: 5
      }
    },
    {
      path: "/user",
      name: "user",
      component: user,
      meta: {
        title: '用户管理',
        requireAuth: true,
        crumbs: '用户管理/用户列表',
        nav: 6
      }
    },
    {
      path: "/role",
      name: "role",
      component: role,
      meta: {
        title: '用户管理',
        requireAuth: true,
        crumbs: '用户管理/角色权限列表',
        nav: 5
      }
    },
    {
      path: "/gatherdata",
      name: "gatherdata",
      component: gatherData,
      meta: {
        title: '管理数据汇总表',
        requireAuth: true,
        crumbs: '数据管理/管理数据汇总表',
        nav: 5
      }
    },
    {
      path: "/gathermanagementdata",
      name: "gathermanagementdata",
      component: gatherManamentData,
      meta: {
        title: '项目管理汇总表',
        requireAuth: true,
        crumbs: '数据管理/项目管理汇总表',
        nav: 5
      }
    },
    {
      path: "/shouldrecover",
      name: "shouldrecover",
      component: shouldRecover,
      meta: {
        title: '应收总表',
        requireAuth: true,
        crumbs: '数据管理/应收总表',
        nav: 5
      }
    },
    {
      path: "/mediadeliverycustomersdata",
      name: "mediadeliverycustomersdata",
      component: mediaDeliveryCustomersData,
      meta: {
        title: '媒介投放客户数据总表',
        requireAuth: true,
        crumbs: '数据管理/媒介投放客户数据总表',
        nav: 5
      }
    },
    {
      path: "/operationLog",
      name: "operationLog",
      component: operationLog,
      meta: {
        title: '操作日志',
        requireAuth: true,
        crumbs: '操作日志',
        nav: 6
      }
    },
    {
      path: "/frequencyStatistics",
      name: "frequencyStatistics",
      component: frequencyStatistics,
      meta: {
        title: '频次统计',
        requireAuth: true,
        crumbs: '频次统计',
        nav: 6
      }
    },
    {
      path: "/serviceSetting",
      name: "serviceSetting",
      component: serviceSetting,
      meta: {
        title: '专属客服设置',
        requireAuth: true,
        crumbs: '专属客服设置',
        nav: 6
      }
    },
    {
      path: "/closedAccount",
      name: "closedAccount",
      component: closedAccount,
      meta: {
        title: '频次统计',
        requireAuth: true,
        crumbs: 'FB封户账户',
        nav: 6
      }
    },
    {
      path: "/openApplication",
      name: "openApplication",
      component: openApplication,
      meta: {
        title: '开户申请',
        requireAuth: true,
        crumbs: '创建开户申请',
        nav: 3
      }
    },
    //   path: "/rebateTplList",
    //   name: "rebateTplList",
    //   component: rebateTplList,
    //   meta: { title: '阶梯返点模板', requireAuth: true, crumbs:'阶梯返点模板',nav:6 }
    // },
  ]
}];

export default act;