import Vue from "vue";
import VueRouter from "vue-router";
import common from '@/assets/js/common';
import act from "./act.js";
import login from "./login.js";
import axios from 'axios';
import store from "../store";
import ElementUI from 'element-ui';


Vue.use(VueRouter);

const routes = [
  ...login,
  ...act,
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
});
router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    if (store.state.navList.length == 0) {
      getMenu().then(() => {
        next()
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

function getMenu() {
  return new Promise((resolve, reject) => {
    let m = localStorage.getItem('user_info');
    if (!m) {
      router.push("/login");
      return
    }
    var user_info_obj = JSON.parse(m);


    axios.defaults.headers.common['language'] = store.state.lang;
    axios.defaults.headers.common['token'] = user_info_obj.token;

    axios.get(`${common.php}v1/role/updateinfo?id=${user_info_obj.user_info.role_id}`, {}).then((res) => {
      if (res.data.code == 1) {
        store.commit('setNavList', res.data.data.perm['0'].children)
      } else {
        ElementUI.Message({
          title: '警告',
          message: res.data.msg,
          type: 'error'
        });
      }
      resolve()
    })
    .catch((err) => {
      console.log(err)
      reject(err)
    })
  })
}
export default router;