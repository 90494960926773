import Vue from "vue";
Vue.prototype.redTip = function(msg) {
    // var html='<div class=" sprite">'+msg+'</div>';
    var div = document.createElement('div');
    div.classList.add('redtip');
    div.innerHTML = "<span>" + msg + "</span><a class='yellowdelete btnope redbg sprite'></a>";
    $(document.body).append(div);
    $(div).css({ "opacity": 0, "display": "block" });
    var top = "90px";
    $(div).css({ "top": "60px" })
    $(div).animate({ top: top, 'opacity': 1 }, 300);
    // window.setTimeout(function(){
    //     $(div).remove();
    // },3000)
    $(div).children(".yellowdelete").click(function() {
        $(div).remove();
    })

}
// 向div中插入字符
Vue.prototype.shakeEditor = function(clear) {
    document.querySelector('.buttoncontainer').classList.add("shaker");
    window.setTimeout(function() {
        document.querySelector('.shaker').classList.remove("shaker");
    }, 500)

}
var seq = [];
Vue.prototype.initSeq = function(blocks) {
    seq = [];
    blocks.forEach(function(e) {
        var reg = /.*\#(\d*)/ig;
        // if(e.block_type==1 || e.block_type==2){
        var group = reg.exec(e.block_name);
        if (group && group[1]) {
            if (!seq[group[1]]) {
                seq[group[1]] = 1;
            } else {
                seq[group[1]] += 1;
            }
            // seq[group[1]]=seq[group[1]]?1:(seq[group[1]]+1);
        }
        // }
    })
    console.log(seq)
    // seq;
}
Vue.prototype.calcOneSeq = function(e) {
    var reg = /.*\#(\d*)/ig;
    var group = reg.exec(e.block_name);
    if (group && group[1]) {
        if (!seq[group[1]]) {
            seq[group[1]] = 1;
        } else {
            seq[group[1]] += 1;
        }
    }
}
Vue.prototype.addSeq = function(justget) {
    var newid = null;
    if (seq.length < 1) {
        newid = 1;
        // seq[i]=1;
    }
    // console.log(seq)
    for (let i = 1; i < seq.length; i++) {
        if (!seq[i]) {
            // seq[i]=1;
            newid = i;
            break;
        }
    }
    if (!newid) {
        newid = seq.length;
    }
    if (!justget) {
        seq[newid] = 1;
    }
    return newid;
}
Vue.prototype.delSeq = function(block_name) {
    var reg = /.*#(\d*)/ig;
    var group = reg.exec(block_name);
    if (group && group[1]) {
        if (seq[group[1]]) {
            if (seq[group[1]] > 1) {
                seq[group[1]] -= 1;
            } else {
                delete seq[group[1]];
            }
        }

    }
}
Vue.prototype.tips = function(dir, ele, content) {
    var left = ele.getBoundingClientRect().left + 'px';
    var top = ele.getBoundingClientRect().top + 'px';
    var id = new Date().getTime();
    var html = `<div id="` + id + `" class="mytips" style="top:` + top + `;left:` + left + `">
        <p>` + content + `</p>
    </div>`;
    $(document.body).append(html);
    $('#' + id).fadeIn();
    window.setTimeout(function() {
        $('#' + id).fadeOut(function() {
            $('#' + id).remove();
        });
    }, 4000)
}
Vue.prototype.showmodal = function(ele) {
    $(ele).fadeIn();
    var $inner = $(ele).children('.modalinner');
    $inner.css({ "opacity": 0, "display": "block" });
    var top = (window.innerHeight - $inner.height()) / 2;
    top = top < 0 ? '0px' : (top + 'px');
    if($(ele).hasClass("nobg")){
        top = parseFloat($inner.css("top"));
        $inner.css({ "top": top-10+"px" })
        $(ele).children('.modalinner').animate({ top: top, 'opacity': 1 }, 300);
    }
    else{
        $inner.css({ "top": "10px" })
        $(ele).children('.modalinner').animate({ top: top, 'opacity': 1 }, 300);
    }
}
Vue.prototype.hidemodal = function(ele) {
    $(ele).fadeOut();
    $(ele).children('.modalinner').animate({ top: '10px', 'opacity': 0 }, 300, function() {

        $(ele).children('.modalinner').hide();
    });
}
/*Vue.prototype.converttime = function({ type, m, h, d }) {
    // 从分钟改成小时和天
    if (type == 1) {
        var d = parseInt(m / (60 * 24));
        var h = parseInt((m - (d * 60 * 24)) / 60);
        var m = m - d * 60 * 24 - h * 60;
        return { d: d, h: h, m: m }
    } else {
        var minute = d * 24 * 60 + h * 60 + m;
        return minute
    }
}*/
Vue.prototype.scrollToPos = function(pele, toele) {
    var y = 100;
    if (!toele) {
        y = pele.children[0].offsetHeight;
    } else {
        y = toele.getBoundingClientRect().y - pele.children[0].getBoundingClientRect().y - 100;
    }
    pele.scrollTo({ top: y, left: 0, behavior: 'smooth' })
    if (toele) {
        toele.classList.add("flashborder");
        window.setTimeout(function() {
            toele.classList.remove("flashborder");
        }, 2000)
    }
}
Vue.prototype.changeSaving = function(type) {
    var message = 'saved'
    var class1 = 'successratio sprite savingstatus';
    switch (type) {
        case 0:
            message = 'saved';
            break;
        case 1:
            message = 'saving';
            class1 = 'savingratio sprite savingstatus'
            break;
        case 2:
            message = 'savefail';
            class1 = 'failratio sprite savingstatus';
            break;
        default:
            message = 'saved';
            break;
    }
    var ele = document.querySelector('.savingstatus');
    ele.className = class1;
    ele.innerText = window[lang][message];
}
Vue.prototype.pasteHtmlAtCaret = function(html) {
    var sel, range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            var el = document.createElement("div");
            el.innerHTML = html;
            var frag = document.createDocumentFragment(),
                node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            };
            range.insertNode(frag);
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            };
        };
    } else if (document.selection && document.selection.type != "Control") {
        // IE9以下
        document.selection.createRange().pasteHTML(html);
    };
}
Vue.prototype.setFocus = function(el) {
    el = el[0]; // jquery 对象转dom对象
    el.focus();
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    //判断光标位置，如不需要可删除
    if (sel.anchorOffset != 0) {
        return;
    };
    sel.removeAllRanges();
    sel.addRange(range);
}
Vue.prototype.selectEdit = function(el) {
    if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(el);
        range.select();
    } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNodeContents(el);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }
}
Vue.prototype.insertText = function(tc, str) {
    var tclen = tc.value.length;
    // tc.focus();
    if (typeof document.selection != "undefined") {
        document.selection.createRange().text = str;
        console.log(document.selection.createRange())
    } else {
        tc.value = tc.value.substr(0, tc.selectionStart) + str + tc.value.substring(tc.selectionStart, tclen);
    }
}
Vue.prototype.getRandomOid = function() {
    return new Date().getTime() + '' + parseInt(Math.random() * (9999999 - 1000000 + 1) + 1000000, 10);
}
Vue.prototype.charToUnicode = function(str) {
    let temp;
    let i = 0;
    let r = '';

    for (let val of str) {
        temp = val.codePointAt(0).toString(16);

        while (temp.length < 4)
            temp = '0' + temp;

        r += '\\u' + temp;
    };

    return r;
}
Vue.prototype.$myajax = function(type, url, postdata, callback, errorcallback) {
    $.ajax({
        type: type,
        data: postdata,
        url: '' + url,
        dataType: 'json',
        // contentType: "application/json",
        success: function(data) {
            if (typeof callback == "function") {
                callback(data);
            }
        },
        error: function(res) {
            layer.msg("系统错误")
            // if (errorcallbak) {
            //     if (typeof errorcallbak == "function") {
            //         errorcallbak(res);
            //     }
            // }
        }
    })
}
Vue.prototype.$getajax = function(type, url, postdata, callback, errorcallback) {
    $.ajax({
        type: type,
        data: postdata,
        url: url,
        dataType: 'json',
        // contentType : "application/json",
        success: function(data) {
            if (typeof callback == "function") {
                callback(data);
            }
        },
        error: function(res) {
            layer.msg("系统错误")
            // if (errorcallbak) {
            //     if (typeof errorcallbak == "function") {
            //         errorcallbak(res);
            //     }
            // }
        }
    })
}
Vue.prototype.$getquery = function(name, url1) {
    // 获取参数
    var url = window.location.search;
    if (url1) {
        url = url1.substr(url1.indexOf('?') + 1);
    }
    // 正则筛选地址栏
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    // 匹配目标参数
    var result = url.substr(1).match(reg);
    //返回参数值
    return result ? decodeURIComponent(result[2]) : null;
}
Vue.prototype.$popup = function(e) {
    layer.tips("<span style='color:#fff'>" + $(e.currentTarget).attr("data-content") + "</span>", "#" + e.currentTarget.id, {
        tips: [1, "#999"],
        time:false
    });
}
Vue.prototype.$popdown = function(name) {
    layer.closeAll('tips');
}
Vue.prototype.$serialize = function(json) {
    var str = '';
    for (key in json) {
        str = str + key + "=" + (json[key] || '') + "&";
    }
    return str;
}
Vue.prototype.$copy = function(text) {
    // if (text.indexOf('-') !== -1) {
    //     let arr = text.split('-');
    //     text = arr[0] + arr[1];
    // }
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? '成功复制到剪贴板' : '该浏览器不支持点击复制到剪贴板';
        layer.msg(msg,{skin:"suclayer"});
    } catch (err) {
        layer.msg('该浏览器不支持点击复制到剪贴板',{skin:"errorlayer"});
    }

    document.body.removeChild(textArea);
}

Vue.prototype.thArrowDefault=function(e){
    var by= e.offsetY >6 ? "desc":"asc";
    var sortArr=$(e.target).parents("table").find(".sort");
    for(i=0;i<sortArr.length;i++){
        $(sortArr[i]).attr("class","sort");
    }
    return by;
}

Vue.prototype.dealDownLoad=function(url,queryobj){
    var querystr="";
    for(var i in queryobj){
        if(i != "sortby"){
            querystr+= "&"+i+"="+queryobj[i];
        }
    }
    if(queryobj.sortby && queryobj.sortby.length!=0){
        querystr+="&field="+queryobj.sortby[0].field+"&by="+queryobj.sortby[0].by;
    }
    if(querystr.charAt(0)=="&"){
        querystr=querystr.substr(1);
    }
    location.href=url+querystr;
}
Vue.prototype.showTips=function(selector,e){
    e.stopPropagation();
    var $t=$(e.target);
    $t.css({"position":"relative","overflow":"visible"});
    var tips=document.querySelector(selector);
    if(tips.style.display!='block'){
        $t.append(tips);
        $(tips).slideDown(100);}
    else{
        $(tips).hide();}
    document.addEventListener("click",function(e){
        if($(e).parents(".more-tips").length<=0){
            $(".more-tips").hide();
        }
    })
}

Vue.prototype.$selectText=function(selector,ele) {
    if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(document.querySelector(selector)||ele);
        range.select();
    } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNodeContents(document.querySelector(selector)||ele);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }
}

Vue.prototype.base64ToBlob=function(code) {
    let parts = code.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

Vue.prototype.getQueryString= function (url) {
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
};
Vue.prototype.userPassword= function (str,val) {
    var reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{6,20}$/;
    if (!(reg.test(str))) {
        layer.msg(val);
        return false;
    }else{
        return true;
    }
};
Vue.prototype.chosenPlug= function (VM,eleId,optionarr,actid,width,isAll) {
    if(!width){  var width="300px"; }
    if(eleId){
        var chosenSelect=$("#"+eleId);
        $(chosenSelect).chosen({width:width,search_contains: true});
        $(chosenSelect).change(function(){
            VM.changechosen(eleId,Number($(chosenSelect).val()))
        })
    }
    VM.reloadChosenPlug(eleId,optionarr,isAll);
};
Vue.prototype.reloadChosenPlug = function (eleId,optionarr,isAll) {
    if(optionarr.length>0){
        if(isAll && isAll=="no"){
            var optionval="";
        }else{
            var optionval="<option value='0'>全部</option>";
        }
        for (item in optionarr){
            optionval += `<option value="${optionarr[item]['id']}">${optionarr[item]["name"]}</option>`
        }
        $("#"+eleId).html(optionval).trigger("chosen:updated");
    }
};
Vue.prototype.updateChosenPlug= function (eleId,actid) {
    $("#" + eleId).val(actid).trigger("chosen:updated");
};
Vue.prototype.msg= function (msg,option) {
    var nmsg=msg;
    if(window[lang][msg]){
        nmsg=window[lang][msg];
    }
    layer.msg(msg,option)
};

Vue.prototype.openLoad = function () {
    var LoadAnl = Layer.load(1, {
        shade: [0.1, "#FFF"],
        time: 60000,
    })
};
Vue.prototype.$get = function(url,postdata,showstatus){
    return new Promise(function(resolve,reject){
        axios.create({baseURL:process.env.VUE_APP_API_URL }).
        get(url,{params:postdata}).then(function(res){
            resolve(res.data);
            if(showstatus && res.data.status==1){
                layer.msg(res.data.statusinfo);
            }
        }).catch(function(e){
                reject(e);
            })
    })
}
Vue.prototype.$post = function(url,postdata,showstatus){
    return new Promise(function(resolve,reject){
        axios.create({baseURL:process.env.VUE_APP_API_URL }).
        post(url,postdata).then(function(res){
            resolve(res.data);
            if(showstatus && res.data.status==1){
                layer.msg(res.data.statusinfo);
            }
        }).catch(function(e){
                reject(e);
            })
    })
}

Vue.prototype.drConst={
    domain:process.env.VUE_APP_API_URL,
    sourceUrl:"activity/v1/source",
};
