<template>
  <div class="main-div media" @click="visible=false">
    <div v-show="showFlag==1">
      <p class="main-title">汇率列表</p>
      <div>
        <div class="main-inputBox daterange" style="width:255px!important">
          <el-date-picker
						v-model="timer"
						@change="datepickerchange"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
        </div>
				<div class="main-inputBox" style="width:200px!important">
          <el-select v-model="ajaxDataSearch.original" @change="init(1)" placeholder="请选择币种">
            <el-option value="">全部</el-option>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
        </div>
				<div class="main-inputBox">
					<div class="main-button" @click="createandedit = true">新增汇率</div>
				</div>
      </div>
      <div class="need-reTd">
        <el-table
          v-loading="loading"
          :data="blackLists"
          border
          style="width: 100%"
        >
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
					<el-table-column
            type="index"
            width="80"
						label="序号">
					</el-table-column>
					<el-table-column
						label="汇率">
            <template slot-scope="scope">
              {{scope.row.rate}}
            </template>
					</el-table-column>
					<el-table-column
						label="税率">
            <template slot-scope="scope">
              {{scope.row.tax_rate}}
            </template>
					</el-table-column>
					<el-table-column
            width="250px"
						label="有效期">
            <template slot-scope="scope">
              {{scope.row.start_time}}至{{scope.row.end_time}}
            </template>
					</el-table-column>
					<el-table-column
						label="添加人">
            <template slot-scope="scope">
              {{scope.row.create_people_name}}
            </template>
					</el-table-column>
					<el-table-column
						label="修改日期">
            <template slot-scope="scope">
              {{scope.row.update_time}}
            </template>
					</el-table-column>
					<el-table-column
						label="操作">
						<template slot-scope="scope">
              <span class="main-tableToInfo" @click="editMethod(scope.row)">编辑</span>
              <span class="main-tableToInfo" @click="delMethod(scope.row)">删除</span>
						</template>
					</el-table-column>
        </el-table>
        <pagination-vue
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="ajaxDataSearch.firstPage"
        />
      </div>
    </div>
    <div class="main-pop" v-show="createandedit">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">{{editObj.id?'编辑汇率':"新增汇率"}}</span>
          <i class="gongzuotai gztguanbi" @click="cancel()"></i>
        </div>
        <div class="body">
          <div class="flexd">
            <label>汇率</label>
            <el-select style="width:100px;margin-right:10px;" size="mini" v-model="editObj.original" placeholder="请选择币种">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input style="width:80px;margin-right:10px;" size="mini" disabled="disabled" value="100"></el-input>            
            <span style="margin-right:10px;">=</span>
            <el-select style="width:100px;margin-right:10px;" size="mini" v-model="editObj.target">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input style="width:80px;margin-right:10px;" size="mini" v-model="editObj.rate" placeholder=""></el-input>
          </div>
          <div class="flexd" style="justify-content:flex-start;">
            <label>税率</label>
            <el-input size="mini" style="width:80%;" v-model="editObj.tax_rate" placeholder=""></el-input>
          </div>
          <div class="flexd" style="justify-content:flex-start;">
            <label>有效期</label>
            <el-date-picker
              v-model="timer1"
              @change="datepickerchange1"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <!-- <el-date-picker
              v-model="editObj.start_time"
              type="date"
              style="width:150px;margin-right:10px;"
              size="mini"
              format="yyyy-MM-dd"
						  value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
            <label>结束时间</label>
            <el-date-picker
              v-model="editObj.end_time"
              size="mini"
              type="date"
              format="yyyy-MM-dd"
						  value-format="yyyy-MM-dd"
              style="width:150px"
              placeholder="选择日期">
            </el-date-picker> -->
          </div>
        </div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l"  @click="cancel()">取消</button>
          <button class="main-button r" @click="saveRate()">确定</button>
        </div>
      </div>
    </div>
    <!--删除确认框-->
    <div class="main-pop" v-show="delPop">
      <div class="mask"></div>
      <div class="center" style="width:300px;">
        <div class="head">
          <span style="font-size: 24px">提示</span>
          <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
        </div>
        <div class="body" style="margin: auto">确定要删除？</div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="delPop=false">取消</button>
          <button class="main-button r" @click="back_del_com()">确定</button>
        </div>
      </div>
    </div>
    <!--删除确认框-->
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import { mkdir } from 'fs';

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      createandedit:false,
			delPopText:"",
			options:[
				{
					label:'人民币',
					value:'CNY',
				},
				{
					label:'美元',
					value:'USD',
				},
				{
					label:'港币',
					value:'HKD',
				},
				{
					label:'日元',
					value:'JPY',
				},
			],
			delPop:false,
			loading:false,
      timer: "",
      timer1:[],
      blackLists: [],
      visible: false,
      pageSize: 15,
      token: "",
      showFlag: 1,
      total: 1,
      ajaxDataSearch: {
        start_time: "", //
				end_time: "",
				original:"",
        firstPage:1
      },
      pageSize:15,
      editObj:{
        id:"",
        original:"CNY",
        target:"",
        rate:"",
        start_time:"",
        end_time:"",
        tax_rate:""
      }
    };
  },
  filters: {
  },
  mounted() {},
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    this.init(1)
    
  },
  methods: {
    cancel(){
      this.createandedit = false;
      this.createReset();
    },
    editMethod(data){
      this.timer1 = []
      this.axios("post", `${this.$phpBaseURL}v1/exchange/exchange_detail`, {id:data.id}).then(res => {
        if (res.code == 1) {
          this.editObj = res.data;    
          if (this.editObj.start_time && this.editObj.end_time) {
            this.timer1.push(this.editObj.start_time)
            this.timer1.push(this.editObj.end_time)
          }      
          this.createandedit = true;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      this.loading = true;

      this.axios("get", `${this.$phpBaseURL}v1/exchange/lists`, this.ajaxDataSearch).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.blackLists = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
		},
		datepickerchange(){
      console.log(this.timer);
      if (this.timer) {
        this.ajaxDataSearch.start_time = this.timer[0]
        this.ajaxDataSearch.end_time = this.timer[1]
      }else{
         this.ajaxDataSearch.start_time = ''
        this.ajaxDataSearch.end_time = ''
      }
      this.init(1)      
    },
    datepickerchange1(){
      if (this.timer1) {
        this.editObj.start_time = this.timer1[0]
        this.editObj.end_time = this.timer1[1]
      }else{
        this.editObj.start_time = ''
        this.editObj.end_time = ''
      }
    },
    delMethod(data){
      this.delPop = true
      this.editObj = data
    },
    back_del_com(){
      this.axios("post", `${this.$phpBaseURL}v1/exchange/exchange_del`, {id:this.editObj.id}).then(res => {
        if (res.code == 1) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.delPop = false;
          this.init(1)
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    saveRate(){
      var url = `${this.$phpBaseURL}v1/exchange/exchange_add`
      if (this.editObj.id) {
        url = `${this.$phpBaseURL}v1/exchange/exchange_edit`
      }
      if (this.editObj.original == '' || this.editObj.target == '') {
        this.$message({ message: '请选择币种', type: "warning" });       
        return 
      }else if(this.editObj.rate == ''){
        this.$message({ message: '汇率不能为空', type: "warning" });        
        return 
      }else if(this.editObj.start_time == '' || this.editObj.end_time == ''){
        this.$message({ message: '开始时间和结束时间不能为空', type: "warning" });        
        return 
      }

      this.axios("post", url, this.editObj).then(res => {
          if (res.code == 1) {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.createandedit = false;
            this.createReset();
            this.init(1)
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
    },
    createReset(){
      this.editObj = {
        id:"",
        original:"CNY",
        target:"",
        rate:"",
        start_time:"",
        end_time:"",
        tax_rate:""
      }
    },
  },

  mounted() {},

  beforeDestroy() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-pop .center .head {
  border-bottom: none;
}
.main-pop .center .head span {
  margin: auto;
}

.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}
.flexd{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.flexd label{
  width: 50px;
  font-size: 14px;
}
.flexd.time label{
  width: 65px;
}
.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style>
.main-btnBox .main-button span {
  color: #01ace4 !important;
}
.date-picker-input input {
  padding-left: 30px !important;
  height: 32px !important;
}
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>