<template>
  <div class="main-div">
    <h2 class="main-title">专属客服设置</h2>
     <el-form
              :model="formData"
              :rules="formDataRule"
              ref="formData"
              label-width="150px" size="mini"
      >
          <el-form-item label="开启客服功能">
              <el-switch
                      v-model="formData.status"
                      active-value="1"
                      inactive-value="0"
                      >
              </el-switch>
              <span class="tips">{{"开启后，此用户将默认成为官网注册客户的销售和AM，同时在前端展示服务二维码"}}</span>
          </el-form-item>
          <el-form-item label="选择用户：" prop="userid">
              <el-select @change="getPersonInfo" class="width300" v-model="formData.userid" filterable placeholder="选择用户">
                  <el-option
                          v-for="item in userArray"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="部门：" >
              <span>{{formData.dept_name}}</span>
          </el-form-item>
          <el-form-item label="手机号：" >
              <span>{{formData.phone}}</span>
          </el-form-item>
          <el-form-item label="邮箱：">
              <span>{{formData.email}}</span>
          </el-form-item>
          <el-form-item label="岗位：">
              <span>{{formData.station_name}}</span>
          </el-form-item>
          <el-form-item label="企微二维码:">
              <el-upload
                      class="avatar-uploader"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
          </el-form-item>
          <el-form-item>
              <el-button  @click="save('formData')" type="primary"
              >保存</el-button>
          </el-form-item>
      </el-form>

  </div>
</template>

<script>
import axios from 'axios';
import store from '@/store/index.js';
export default {
  name: "serviceSetting",
  data(){
    return {
      userArray:[],
      imageUrl:"",
      formData:{
          status:false,
          userid:"",
          dept_name:"",
          phone:"",
          email:"",
          station_name:"",
          qr_code:""
      },
      formDataRule: {
        userid: [
          { required: true, message: "请选择用户", trigger: "change" }
        ],
        qr_code:[
          {required: true, message: "请上传企微二维码", trigger: "change"}
        ]
      }

    }
  },
  created() {
    this.getServiceSet();
    this.getUserList();
  },
  methods:{
    getServiceSet(){
      this.axios("get", `${this.$phpBaseURL}v1/exclusiveService/getExclusiveService`).then(
          res => {
              if(res.code ==1 ){
                this.formData = res.data;
                this.formData.status = String(res.data.status);
                this.imageUrl = res.data.image;
              }
          }
      );
    },
    save(refname){
      this.$refs[refname].validate(valid => {
        if (valid) {
            let param = {
                userid: this.formData.userid,
                status:this.formData.status,
                qr_code:this.formData.qr_code
            }
          this.axios("POST", `${this.$phpBaseURL}v1/exclusiveService/updateExclusiveService`, param).then(
              res => {
                  if(res.code ==1 ){
                      this.$message({ message: res.msg, type: "success" });
                  }  else {
                      this.$message({ message: res.msg, type: "warning" });
                  }
              }
          );
        }
      })
    },
    getUserList(){
          this.axios("get", `${this.$phpBaseURL}v1/exclusiveService/getUserList`).then(
              res => {
                  if(res.code ==1 ){
                      this.userArray = res.data;
                  }else {
                      this.$message({ message: res.msg, type: "warning" });
                  }
              }
          );
      },
      getPersonInfo(){
          this.axios("get", `${this.$phpBaseURL}v1/exclusiveService/getUserDetails?userid=${this.formData.userid}`).then(
              res => {
                  if(res.code ==1 ){
                      this.formData.dept_name= res.data.dept_name;
                      this.formData.email = res.data.email;
                      this.formData.phone = res.data.phone;
                      this.formData.station_name = res.data.station_name;
                  }else {
                      this.$message({ message: res.msg, type: "warning" });
                  }
              }
          );
      },

      beforeAvatarUpload(file) {
          let fd = new FormData();
          fd.append('file',file);
          fd.append('fileType',8);
          this.axios("POST", `${this.$javaBaseURL}file/upload`, fd).then(
              res => {
                  if(res.code == 1){
                      this.formData.qrCode = res.data.name;
                      this.imageUrl = res.data.url;
                  }else {
                      this.$message({ message: res.msg, type: "warning" });
                  }

              }
          );
      }
  }
}
</script>

<style scoped  lang="scss">
    .width300{
        width:300px;
    }
    /deep/.avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    .avatar {
        width: 120px;
        height: 120px;
        display: block;
    }
    .tips{
        padding-left: 30px;
        opacity: 0.6;
    }
</style>