<template>
    <div class="shouldrecover main-div">

        <el-tabs v-model="activeName" class="tab-shine" type="card" @tab-click="handleClick">
            <el-tab-pane label="应收总表" name="first">
                <div class="tab-1">
                    <div class="main-inputBox">
                        <el-input v-model="ajaxDataSearch.project_name" placeholder="请输入项目名称" clearable @change="enterSearch">
                            <i class="el-icon-search el-input__icon" slot="suffix" @click="init()"></i>
                        </el-input>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.sales_id" @change="enterSearch" clearable placeholder="请选择销售">
                            <el-option
                                    v-for="item in configurationData.sales_list"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.client_status" @change="enterSearch" clearable placeholder="请选择客户状态">
                            <el-option
                                    v-for="(v,k) in configurationData.receivable_client_status"
                                    :key="k"
                                    :label="v"
                                    :value="k">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.dept_id" @change="enterSearch" clearable placeholder="请选择部门">
                            <el-option
                                    v-for="(v,k) in configurationData.departments_data"
                                    :key="v.department_id"
                                    :label="v.department_name"
                                    :value="v.department_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.company_id" @change="enterSearch" clearable placeholder="请选择公司名称">
                            <el-option
                                    v-for="(v,k) in configurationData.affiliated_company"
                                    :key="v.client_company_id"
                                    :label="v.st_name"
                                    :value="v.client_company_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-selectBox">
                        <el-select v-model="ajaxDataSearch.is_overdue" @change="enterSearch" clearable placeholder="请选择是否逾期">
                            <el-option
                                    v-for="(v,k) in configurationData.is_overdue_config"
                                    :key="k"
                                    :label="v"
                                    :value="k">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="main-inputBox gatherdata-self-shy">
                        <el-date-picker @change="enterSearch" value-format="yyyy-MM" format="yyyy-MM"
                                        v-model="ajaxDataSearch.year_month"
                                        type="month" clearable
                                        placeholder="年度月度">
                        </el-date-picker>
                    </div>
                </div>
                <div class="main-btnBox" id="fileParent">
                    <div v-show="common.permFilter(`${common.php}v1/receivable/import`)" class="main-button" @click="selectFile()">上传数据
                        <input id="file" type="file" value="" style="display: none">
                    </div>

                    <!-- <div v-show="common.permFilter(`${common.php}v1/receivable/destroy`)" class="primary-button-whitebg" @click="delPop = true">删除数据</div> -->
                    <div v-show="common.permFilter(`${common.php}v1/receivable/destroy`)" class="primary-button-whitebg" @click="reallyDel()">删除数据</div>
                </div>
                <el-table
                        v-loading="loading"
                        border
                        :data="tableData"
                        style="width: 100%"
                        @selection-change="handleSelectionChange">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="客户状态"
                            width="180">
                        <template slot-scope="scope">
                            <div v-for="(v,k) in configurationData.receivable_client_status">
                                <span style="cursor: pointer" v-if="k == scope.row.client_status" :title="v">{{v}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="部门"
                            width="180">
                        <template slot-scope="scope">
                            <div v-for="v in configurationData.departments_data">
                                <span style="cursor: pointer" v-if="v.department_id == scope.row.dept_id" :title="v.department_name">{{v.department_name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            show-overflow-tooltip
                            label="销售人员"
                            width="180">
                        <template slot-scope="scope">
                            <div v-for="v in configurationData.sales_list">
                                <span v-if="v.id==scope.row.user_id">{{v.name}}</span>
                            </div>
                            <!--              <span style="cursor: pointer" :title="configurationData.sales_list[scope.row.user_id]">{{configurationData.sales_list[scope.row.user_id]}}</span>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="公司名称"
                            width="180"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-for="v in configurationData.affiliated_company">
                                <span style="cursor: pointer" v-if="v.client_company_id == scope.row.company_id" :title="v.st_name">{{v.st_name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="项目名称"
                            width="240"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.project_name">{{scope.row.project_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="年度"
                            width="180">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.year">{{scope.row.year}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="月度"
                            width="180">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.month">{{scope.row.month}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="currency"
                            label="收入币种"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="未开票（未回款）"
                            width="200">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.no_invoice">{{scope.row.no_invoice}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="（已开票）未回款"
                            width="200">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.invoiced">{{scope.row.invoiced}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="未开票&未回款合计"
                            width="230">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.no_invoice_total">{{scope.row.no_invoice_total}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop=""
                            label="是否逾期"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.is_overdue==1?'是':'否'">{{scope.row.is_overdue == 1 ? '是' : '否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="逾期时间"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.overdue_time">{{scope.row.overdue_time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="已计提坏账比例"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.bad_debt_ratio+'%'">{{scope.row.bad_debt_ratio + '%'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="已确认坏账金额"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.bad_debt_amount">{{scope.row.bad_debt_amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="是否有足额担保"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.is_full_guarantee==1?'是':'否'">{{scope.row.is_full_guarantee == 1 ? '是' : '否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="异常状态"
                            width="150">
                        <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.unusual_status==1?'正常':(scope.row.unusual_status==2?'诉讼仲裁':'业务协商中')">
                {{scope.row.unusual_status == 1 ? '正常' : (scope.row.unusual_status == 2 ? '诉讼仲裁' : '业务协商中')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="诉讼仲裁情况"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.arbitrate_feedback">{{scope.row.arbitrate_feedback}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="客户情况说明"
                            width="150">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.client_feedback">{{scope.row.client_feedback}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="操作"
                            width="200">
                        <template slot-scope="scope">
                            <span class="main-tableToInfo" v-show="common.permFilter(`${common.php}v1/receivable/store`)" @click="editPopLow=true;getInfoPopLow(scope.row)">法务编辑</span>
                            <div class="main-20px"></div>
                            <span class="main-tableToInfo" v-show="common.permFilter(`${common.php}v1/receivable/saleStore`)" @click="editPopSel=true;getInfoPopSel(scope.row)">销售编辑</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination-vue
                        @currentChange="init"
                        :pageSize="pageSize"
                        :total="total"
                        :firstPage="firstPage"
                />
            </el-tab-pane>

            <el-tab-pane label="部门回款情况" name="second">
                <div class="main-selectBox">
                    <el-select v-model="ajaxDataSearch2.dept_id" @change="init2()" clearable placeholder="请选择部门">
                        <el-option
                                v-for="(v,k) in configurationData.departments_data"
                                :key="v.department_id"
                                :label="v.department_name"
                                :value="v.department_id">
                        </el-option>
                    </el-select>
                </div>
                <div class="main-inputBox gatherdata-self-shy">
                    <el-date-picker @change="init2()" value-format="yyyy-MM" format="yyyy-MM"
                                    v-model="ajaxDataSearch2.year_month"
                                    type="month" clearable
                                    placeholder="时间(精确到月)">
                    </el-date-picker>
                </div>
                <main v-loading="loading2" class="nest-table-self">
                    <h2 class="tablehead">汇总分析表</h2>
                    <div class="table-wrap">
                        <table border="1" class="table-left" align="center" style="text-align: center;width:120px!important;">
                            <tr>
                                <td style="height:80px" class="bg-gray">部门</td>
                            </tr>
                            <!-- ------ -->
                            <tr v-for="v in tableData2">
                                <td class="bg-gray" style="">{{v.department_name}}</td>
                            </tr>
                            <!--<tr>
                              <td  class="bg-gray" style="height:160px">销售部</td>
                            </tr>-->
                        </table>
                        <table class="table-right" style="min-width:calc(100% - 124px)!important;flex:auto">
                            <tr>
                                <td rowspan="2" height="80">销售人员</td>
                                <td colspan="2">一般客户（未开票&未回款）</td>
                                <td colspan="2">异常客户（业务协商中+诉讼仲裁）</td>
                                <td rowspan="2">确认坏账客户（业务确认）</td>
                                <td rowspan="2">应收合计</td>
                            </tr>
                            <tr>
                                <td>账期内金额</td>
                                <td>逾期金额</td>
                                <td>业务协商中金额</td>
                                <td>诉讼仲裁金额</td>
                            </tr>
                            <tr v-for="v in tableData2">
                                <td>{{v.name}}</td>
                                <td>{{v.term_amount}}</td>
                                <td>{{v.overdue_amount}}</td>
                                <td>{{v.consulting_amount}}</td>
                                <td>{{v.arbitration_amount}}</td>
                                <td>{{v.bad_debt_amount}}</td>
                                <td>{{v.total_amount}}</td>
                            </tr>

                        </table>
                    </div>
                </main>
            </el-tab-pane>

            <el-tab-pane label="应收回款情况汇总" name="third">
                <div class="main-inputBox gatherdata-self-shy">
                    <el-date-picker @change="init3()" value-format="yyyy-MM" format="yyyy-MM"
                                    v-model="ajaxDataSearch3.year_month"
                                    type="month" clearable
                                    placeholder="时间(精确到月)">
                    </el-date-picker>
                </div>
                <main v-loading="loading3" class="nest-table-self">
                    <h2 class="tablehead">应收回款情况分析表</h2>
                    <div class="table-wrap">
                        <table border="1" class="table-left" align="center" style="text-align: center;width:300px!important;">
                            <tr>
                                <td class="bg-gray">客户类别</td>
                            </tr>
                            <!-- ------ -->
                            <tr>
                                <td class="bg-gray" style="height:120px">一般客户（未开票&未回款）</td>
                            </tr>
                            <tr>
                                <td class="bg-gray" style="height:120px">异常客户（业务协商中+诉讼仲裁）</td>
                            </tr>
                            <tr>
                                <td class="bg-gray">确认坏账客户（业务确认）</td>
                            </tr>
                            <tr>
                                <td class="bg-gray">应收账款总额</td>
                            </tr>
                        </table>
                        <table class="table-right" style="overflow:hidden;width:calc(100% - 300px)!important;flex:auto">
                            <tr>
                                <td style="min-width:220px">应收情况</td>
                                <td style="min-width:220px">未开票</td>
                                <td style="min-width:220px">（已开票）未回款</td>
                                <td style="min-width:220px">未开票未回款合计</td>
                            </tr>

                            <tr v-if="tableData3some(1)">
                                <td>正常应收款</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 1">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 1">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 1">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(1)">
                                <td>正常应收款</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr v-if="tableData3some(2)">
                                <td>逾期应收款</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 2">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 2">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 2">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(2)">
                                <td>逾期应收款</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr v-if="tableData3some(3)">
                                <td>合计</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 3">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 3">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 3">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(3)">
                                <td>合计</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr v-if="tableData3some(4)">
                                <td>业务协商中应收款</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 4">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 4">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 4">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(4)">
                                <td>业务协商中应收款</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr v-if="tableData3some(5)">
                                <td>诉讼仲裁应收款</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 5">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 5">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 5">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(5)">
                                <td>诉讼仲裁应收款</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr v-if="tableData3some(6)">
                                <td>合计</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 6">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 6">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 6">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(6)">
                                <td>合计</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr v-if="tableData3some(7)">
                                <td>合计</td>
                                <template v-for="v in tableData3">
                                    <td v-if="v.data_type == 7">{{v.no_invoice}}</td>
                                    <td v-if="v.data_type == 7">{{v.invoiced}}</td>
                                    <td v-if="v.data_type == 7">{{v.no_invoice_total}}</td>
                                </template>
                            </tr>
                            <tr v-if="!tableData3some(7)">
                                <td>合计</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr>
                                <td>合计</td>
                                <td>{{colSum('no_invoice')}}</td>
                                <td>{{colSum('invoiced')}}</td>
                                <td>{{colSum('no_invoice_total')}}</td>
                            </tr>
                        </table>
                    </div>
                </main>
            </el-tab-pane>
        </el-tabs>

        <!--编辑法务-->
        <div class="main-pop editPop" v-show="editPopLow">
            <div class="mask"></div>
            <div class="center" style="width:516px;max-height: 835px">
                <div class="head">
                    <span>法务编辑</span>
                    <i class="gongzuotai gztguanbi" @click="editPopLow=false"></i>
                </div>
                <div class="body" style="">
                    <div class=" inline top">
                        <p class="info-title t-7w" style="line-height: 10px">是否有足额担保:</p><br>
                        <div>
                            <div class="main-inputBox" style="width: 465px">
                                <el-select v-model="editPopLowObj.is_full_guarantee" @change="" clearable placeholder="是否有足额担保">
                                    <el-option
                                            v-for="(v,k) in is_full_guarantee_arr"
                                            :key="k"
                                            :label="v"
                                            :value="k">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <p class="info-title" style="line-height: 10px">异常状态:</p><br>
                        <div>
                            <div class="main-inputBox" style="width: 465px">
                                <el-select v-model="editPopLowObj.unusual_status" @change="" clearable placeholder="异常状态">
                                    <el-option
                                            v-for="(v,k) in unusual_status_arr"
                                            :key="k"
                                            :label="v"
                                            :value="k">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <p class="info-title" style="line-height: 10px">诉讼仲裁情况:</p><br>
                        <div>
                            <div class="main-inputBox" style="height: auto;width: 465px">
                                <el-input v-model="editPopLowObj.arbitrate_feedback" type="textarea" :rows="5" placeholder="诉讼仲裁情况">
                                </el-input>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="foot">
                    <button class="main-button l" @click="editPopLow=false">取消</button>
                    <button class="main-button r" @click="editPopLow=false;doEditPopLow()">确定</button>
                </div>
            </div>
        </div>
        <!--销售编辑-->
        <div class="main-pop editPop" v-show="editPopSel">
            <div class="mask"></div>
            <div class="center" style="width:516px;max-height: 835px">
                <div class="head">
                    <span>销售编辑</span>
                    <i class="gongzuotai gztguanbi" @click="editPopSel=false"></i>
                </div>
                <div class="body" style="">
                    <div class=" inline top">
                        <p class="info-title" style="line-height: 10px">客户情况说明:</p><br>
                        <div>
                            <div class="main-inputBox" style="height: auto;width: 465px">
                                <el-input v-model="editPopSelObj.client_feedback" type="textarea" :rows="5" placeholder="客户情况说明">
                                </el-input>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="foot">
                    <button class="main-button l" @click="editPopSel=false">取消</button>
                    <button class="main-button r" @click="editPopSel=false;doEditPopSel()">确定</button>
                </div>
            </div>
        </div>


        <!--删除弹框-->
        <div class="main-pop editPop" v-show="delPop">
            <div class="mask"></div>
            <div class="center" style="width:516px;max-height: 835px">
                <div class="head">
                    <span>删除确认</span>
                    <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
                </div>
                <div class="body" style="">
                    <div class=" inline top">
                        <p class="info-title" style="line-height: 10px" v-show="ids.ids.length>0">确定删除选中的{{ids.ids.length}}项数据吗？</p>
                        <p class="info-title" style="line-height: 10px" v-show="ids.ids.length==0">请选择要删除的数据</p>
                    </div>

                </div>
                <div class="foot">
                    <button class="main-button l" @click="delPop=false">取消</button>
                    <button class="main-button r" @click="delPop=false;doDel()" v-show="ids.ids.length>0">确定</button>
                    <button class="main-button r" @click="delPop=false" v-show="ids.ids.length==0">确定</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import paginationVue from '@/components/paginationVue.vue'
    import axios from 'axios';
    import $ from 'jquery';
    import store from '@/store/index.js';
    export default {
        name: "gatherManament",
        components: {
            paginationVue
        },
        data(){
            return {
                firstPage:1,
                loading:true,
                loading2:true,
                loading3:true,
                timer:'',
                activeName: 'first',
                pageSize: 15,
                total: 0,
                ajaxDataSearch: {
                    project_name: '',//	否	string	lxn项目01
                    sales_id: '',//	否	string	4F81D19F600000011924017E51357265
                    client_status: '',//	否	int	1
                    dept_id: '',//	否	string	6F667513E0000001192B70CA67A09F52
                    company_id: '',//	否	string	lxn123
                    is_overdue: '',//	否	int	1
                    year_month: '',//搜索项的时间；如：2020-06
                },
                is_full_guarantee_arr: {
                    1: '是', 2: '否'
                },
                unusual_status_arr: {
                    1: '正常', 2: '诉讼仲裁', 3: '业务协商中'
                },
                client_status_array: {
                    1: '一般客户', 2: '异常客户', 3: '坏账客户'
                },
                tableData: [
                    {
                        "id": "4F81D19F600000011924017E51357265",
                        "client_status": 1,
                        "dept_id": "6F667513E0000001192B70CA67A09F52",
                        "user_id": "4F81D19F600000011924017E51357265",
                        "company_id": "lxn123",
                        "project_name": "lxn项目01",
                        "year": 2020,
                        "month": 4,
                        "currency": "美元",
                        "no_invoice": "6.66",
                        "invoiced": "6.66",
                        "no_invoice_total": "66.66",
                        "is_overdue": 1,
                        "overdue_time": "20",
                        "bad_debt_ratio": "44.44",
                        "bad_debt_amount": "4.44",
                        "is_full_guarantee": 1,
                        "unusual_status": 3,
                        "arbitrate_feedback": "诉讼仲裁情况",
                        "client_feedback": "客户情况说明",
                        "created_at": "2020-04-23 17:14:40",
                        "updated_at": "2020-04-23 17:14:40"
                    }
                ],

                /*返回客户管理下搜索项用到的数据接口*/
                configurationData: {
                    "affiliated_company": [],
                    "receivable_client_status": {
                        /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
                    },
                    "is_overdue_config": {
                        /*"1": "是",
                         "2": "否"*/
                    },
                    "client_status_array": {
                        /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
                    },
                    "client_source_array": {
                        /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
                    },
                    "client_connection_relation_array": {
                        /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
                    },
                    "client_relation_company_array": {
                        /* "1": "拥有者",
                         "2": "共享者"*/
                    },
                    "is_contain_config": {
                        /*"1": "有",
                         "2": "无"*/
                    },
                    "platform_array": {
                        /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
                    },
                    "contact_status_array": {
                        /*"1": "审批通过",
                         "2": "审批失败"*/
                    },
                    "gender_array": {
                        /*"1": "男",
                         "2": "女"*/
                    },
                    "contract_status_array": {
                        /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
                    },
                    "business_type_array": {
                        /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
                    },
                    "contract_type_array": {
                        /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
                    },
                    "contract_subdivision_array": {
                        /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
                    },
                    "is_guarantee_archive_prepaid_array": {
                        /*"1": "是",
                         "2": "否"*/
                    },
                    "project_status_array": {
                        /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
                    },
                    "project_approval_status": {
                        /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
                    },
                    "file_upload_status": {
                        /*"1": "上传成功",
                         "2": "上传失败"*/
                    },
                    "platform_name_array": {
                        /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
                    },
                    "return_point_ratio_accuracy_array": {
                        /*"1": "预估",
                         "2": "确认"*/
                    },
                    "departments_data": [
                        /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
                    ],
                    "industry_data": [
                        /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
                    ]
                },

                configurationData2: [
                    {
                        "id": "6BD47F61E0000001D1CC52983B488BD4",
                        "name": "卢婷婷"
                    }
                ],

                editPopLow: false,
                editPopLowObj: {
                    id: '',//	是	string	应收总表主键id
                    is_full_guarantee: '',//	是	int	是否有足额担保:1-是，2-否
                    unusual_status: '',//	是	int	异常状态:1-正常 2-诉讼仲裁 3-业务协商中
                    arbitrate_feedback: '',//	是	string	诉讼仲裁情况
                },
                editPopSel: false,
                editPopSelObj: {
                    id: '',
                    client_feedback: ''
                },


                /*页签2*/
                pageSize2: 15,
                total2: 0,
                ajaxDataSearch2: {
                    dept_id: '',//	否	string	部门id
                    year_month: null,//	否	string	搜索时间
                },
                tableData2: [
                    {
                        "department_name": "企业销售部",
                        "name": "王凯",
                        "id": 0,
                        "dept_id": "8EA5C1BC231F944B8BCC4533B4346CF0",
                        "user_id": "4F81D19F600000011924017E51357265",
                        "year": 2020,
                        "month": 4,
                        "term_amount": "0.00",
                        "overdue_amount": "0.00",
                        "consulting_amount": "0.00",
                        "arbitration_amount": "0.00",
                        "bad_debt_amount": "0.00",
                        "total_amount": "0.00",
                        "created_at": "2020-04-28 17:43:33",
                        "updated_at": "2020-04-28 17:43:37"
                    }, {
                        "department_name": "企业销售部",
                        "name": "王凯",
                        "id": 0,
                        "dept_id": "8EA5C1BC231F944B8BCC4533B4346CF0",
                        "user_id": "4F81D19F600000011924017E51357265",
                        "year": 2020,
                        "month": 4,
                        "term_amount": "0.00",
                        "overdue_amount": "0.00",
                        "consulting_amount": "0.00",
                        "arbitration_amount": "0.00",
                        "bad_debt_amount": "0.00",
                        "total_amount": "0.00",
                        "created_at": "2020-04-28 17:43:33",
                        "updated_at": "2020-04-28 17:43:37"
                    }, {
                        "department_name": "企业销售部",
                        "name": "王凯",
                        "id": 0,
                        "dept_id": "8EA5C1BC231F944B8BCC4533B4346CF0",
                        "user_id": "4F81D19F600000011924017E51357265",
                        "year": 2020,
                        "month": 4,
                        "term_amount": "0.00",
                        "overdue_amount": "0.00",
                        "consulting_amount": "0.00",
                        "arbitration_amount": "0.00",
                        "bad_debt_amount": "0.00",
                        "total_amount": "0.00",
                        "created_at": "2020-04-28 17:43:33",
                        "updated_at": "2020-04-28 17:43:37"
                    },
                ],

                /*页签3*/
                pageSize3: 15,
                total3: 0,
                ajaxDataSearch3: {
                    year_month: null,//	否	string	搜索时间
                },
                tableData3: [
                    {
                        "id": 0,
                        "year": 2020,
                        "month": 4,
                        "no_invoice": "10.00",
                        "invoiced": "8.00",
                        "no_invoice_total": "20.00",
                        "data_type": 1,
                        "created_at": null,
                        "updated_at": null
                    }
                ],

                /*删除的ids*/
                ids: {
                    "ids": []
                },
                delPop: false,
            }
        },
        mounted() {
            /*返回客户管理下搜索项用到的数据接口*/
            axios.defaults.headers.common['language'] = store.state.lang;
            axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
            this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {
                type: 'client_contact_person,sales_list'
            }).then(res => {
//                console.log(res.data)
                if (res.code == 1) {
                    this.configurationData = res.data;

                    this.init();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics, 5000);

                } else if(res.code == 2){
                    //token过期处理
                    this.common.tokenExpired(res.msg);
                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }
            })
            /*搜索项下拉框：销售，优化师，AM 用户数据 1-销售，2-优化师，3-AM*/
            this.axios('POST', `${this.$phpBaseURL}v1/config/roleUserdatas`, {
                type: 1
            }).then(res => {
//                console.log(res.data)
                if (res.code == 1) {
                    this.configurationData2 = res.data;
                } else if (res.code == 2) {
                    localStorage.setItem('user_info', '');
                    this.$router.push({path: '/login'});
                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }
            })

        },
        methods: {
            enterSearch(){
                this.firstPage=1;

                if(this.ajaxDataSearch.customer_created_at == null){
                    this.ajaxDataSearch.customer_created_at = ['','']
                }
                this.init(1);
            },
            /*应收总表*/
            init(page){
                this.loading=true;
                this.firstPage=page;
                clearTimeout(this.timer);

                this.ajaxDataSearch.page = page ? page : 1;
                this.axios('get', `${this.$phpBaseURL}v1/receivable/getList`, this.ajaxDataSearch).then(res => {
                    this.loading=false;

//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData = res.data.data;
                        this.total = res.data.total;
                        //                    this.pageSize = res.data.per_page;
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });


            },
            /*部门回款情况*/
            init2(page){
                clearTimeout(this.timer);

                this.ajaxDataSearch2.page = page ? page : 1;
                this.ajaxDataSearch2.page_size = 10000;
                this.axios('get', `${this.$phpBaseURL}v1/receivable/deptMoneyback`, this.ajaxDataSearch2).then(res => {
                    this.loading2=false;
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData2 = res.data.data;
                        this.total2 = res.data.total;
                        //                    this.pageSize = res.data.per_page;
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            /*应收回款情况汇总*/
            init3(page){
                clearTimeout(this.timer);

                this.ajaxDataSearch3.page = page ? page : 1;
                this.ajaxDataSearch3.page_size = 10000;
                this.axios('get', `${this.$phpBaseURL}v1/receivable/receivableSummary`, this.ajaxDataSearch3).then(res => {
                    this.loading3=false;
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData3 = res.data;
//                this.total3 = res.data.total;
                        //                    this.pageSize = res.data.per_page;
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },

            /*法务编辑弹框*/
            getInfoPopLow(obj){
                this.editPopLowObj = {
                    id: obj.id,//	是	string	应收总表主键id
                    is_full_guarantee: obj.is_full_guarantee.toString(),//	是	int	是否有足额担保:1-是，2-否
                    unusual_status: obj.unusual_status.toString(),//	是	int	异常状态:1-正常 2-诉讼仲裁 3-业务协商中
                    arbitrate_feedback: obj.arbitrate_feedback,//	是	string	诉讼仲裁情况
                }
            },
            /*销售编辑弹框*/
            getInfoPopSel(obj){
                this.editPopSelObj = {
                    id: obj.id,//	是	string	应收总表主键id
                    client_feedback: obj.client_feedback,//	是	string	诉讼仲裁情况
                }
            },
            //法务编辑提交
            doEditPopLow(){

                this.axios('POST', `${this.$phpBaseURL}v1/receivable/store`, {
                    id: this.editPopLowObj.id,
                    is_full_guarantee: parseInt(this.editPopLowObj.is_full_guarantee),
                    unusual_status: parseInt(this.editPopLowObj.unusual_status),
                    arbitrate_feedback: this.editPopLowObj.arbitrate_feedback,
                }).then(res => {
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.common.statisticsAdd('法务编辑','2');

                        this.editPopLow = false;
                        this.init();
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },
            //销售编辑提交
            doEditPopSel(){

                this.axios('POST', `${this.$phpBaseURL}v1/receivable/saleStore`, this.editPopSelObj).then(res => {
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.common.statisticsAdd('销售编辑','2');

                        this.editPopSel = false;
                        this.init();
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },

            handleClick(){
                let that=this;

                if (this.activeName == "first") {

                    //点击回车进行搜索
                    document.onkeypress = function(e) {
                        var keycode = document.all ? event.keyCode : e.which;
                        if (keycode == 13) {
                            that.init();
                            return false;
                        }
                    };

                    this.init();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics, 5000);

                } else if (this.activeName == 'second') {

                    //点击回车进行搜索
                    document.onkeypress = function(e) {
                        var keycode = document.all ? event.keyCode : e.which;
                        if (keycode == 13) {
                            that.init2();
                            return false;
                        }
                    };

                    this.init2();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics2, 5000);

                } else {

                    //点击回车进行搜索
                    document.onkeypress = function(e) {
                        var keycode = document.all ? event.keyCode : e.which;
                        if (keycode == 13) {
                            that.init3();
                            return false;
                        }
                    };

                    this.init3();

                    //频次统计
                    this.timer = setTimeout(this.addStatistics3, 5000);

                }
            },
            handleSelectionChange(v){
                //应收总表多选
//            console.log(v)
                this.ids.ids = [];
                let that = this;
                v.forEach(function (item) {
                    that.ids.ids.push(item.id);
                })
                console.log(this.ids)
            },
            doDel(){
                this.axios('POST', `${this.$phpBaseURL}v1/receivable/destroy`, this.ids).then(res => {
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.common.statisticsAdd('应收表删除数据','2');

                        this.init();
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                })
            },

            tableData3some(num){
                return this.tableData3.some(function (v) {
                    return v.data_type == num;
                });
            },
            colSum(key){
                return this.tableData3.reduce(function (sum, item) {
                    return sum + parseFloat(item[key]);
                }, 0)
            },

            /*上传数据*/
            selectFile(){
                let that = this;
                document.getElementById('file').click();

                let fileInput = document.getElementById('file');
                fileInput.addEventListener('change', function () {
//              that.fileMsg = fileInput.files[0].name;
                    that.upload();
                })
            },
            upload(){

                let excel = document.getElementById('file').files[0];
                let wfForm = new FormData();
                wfForm.append('file', excel);
                this.axios('post', `${this.$phpBaseURL}v1/receivable/import`, wfForm).then(res => {
                    if (res.code == 1) {
                        this.common.statisticsAdd('应收表上传数据','2');

                        this.$message({message: '导入成功', type: 'success'});
                        this.addPop = false;
                        this.init();
                        $("#file").replaceWith('<input id="file" type="file" value="" style="display: none">');

//              document.getElementById('fileParent').replaceChild(document.getElementById('file'),'<input id="file" type="file" value="" style="display: none">');
                        /*fileParent*/
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                        $("#file").replaceWith('<input id="file" type="file" value="" style="display: none">');

                    }
                })
//            document.getElementById('fileParent').replaceChild(document.getElementById('file'),'<input id="file" type="file" value="" style="display: none">');
            },
            //删除数据
            reallyDel(){
                let l = this.ids.ids.length
                let t = l > 0 ? `确定删除选中的${l}项数据吗？` : '请选择要删除的数据'

                this.$confirm(t, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.doDel()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            //频次统计
            addStatistics() {
                this.common.statisticsAdd('应收表列表','1')
            },
            addStatistics2() {
                this.common.statisticsAdd('部门回款情况列表','1')
            },
            addStatistics3() {
                this.common.statisticsAdd('应收回款情况汇总列表','1')
            },
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>

<style lang="scss" scoped>
    .shouldrecover {
        .custom-status {
            width: 64px;
            height: 24px;
            display: inline-block;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            &.unusual {
                // 异常用户
                background: rgba(254, 102, 100, 0.15);
                color: #FE6664;
            }
            &.regular {
                //一般用户
                background: rgba(38, 196, 184, 0.15);
                color: #26C4B8;
            }
            &.bad-debt {
                background: rgba(247, 185, 77, 0.15);
                color: #F7B94D
            }
        }
    }
</style>
