<template>
    <div class="main-div">
        <p class="main-title" style="margin-bottom: 32px">客户级别列表</p>
        <div v-loading="loading">
            <el-table

                    :data="levelList"
                    border
                    style="width: 100%">
                <template slot="empty">
                    <div class="nodata">
                        <img src="@/assets/img/nodata.png">
                        <span>暂无数据</span>
                    </div>
                </template>
                <el-table-column
                        prop=""
                        label="序号"
                        type="index"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="level_name"
                        label="等级名称"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="level_standard"
                        label="等级标准"
                        width="">
                </el-table-column>
                <!-- <el-table-column
                        prop="consume_limit"
                        label="消耗限额"
                        width="">
                </el-table-column> -->
                <el-table-column
                        prop="consume_limit"
                        label="消耗限额（美元）"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="consume_cny_limit"
                        label="消耗限额（人民币）"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="level_created_at"
                        label="创建时间"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="level_updated_at"
                        label="修改时间"
                        width="">
                </el-table-column>

            </el-table>
            <pagination-vue @currentChange="init" :pageSize="pageSize" :total="total"/>
        </div>
    </div>
</template>

<script>
    /*import  from '';*/
    import paginationVue from '@/components/paginationVue.vue'
    import axios from 'axios';
    import store from '@/store/index.js';
    export default {
        name: '',
        components: {
            paginationVue
        },
        data () {
            return {
                loading: true,
                timer: '',
                msg: '',
                pageSize: 10,
                total: 100,
                levelList: [
                    /*{
                     "level_name": "cscs ",
                     "level_standard": "",
                     "consume_limit": "0.00",
                     "level_created_at": "2020-04-15 12:36:13",
                     "level_updated_at": "2020-04-15 12:36:05"
                     }*/
                ]
            }
        },
        created(){
            axios.defaults.headers.common['language'] = store.state.lang;
            axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
            this.init();

            this.timer = setTimeout(this.addStatistics, 5000);
        },
        methods: {
            init(page){
                this.loading=true;
                clearTimeout(this.timer);

                this.axios('POST', `${this.$phpBaseURL}v1/clientLevel/lists`, {
                    page: page ? page : 1,
                }).then(res => {
                    this.loading=false;

//                        console.log(res.data)
                    if (res.code == 1) {
                        this.levelList = res.data.data;
                        this.total = res.data.total;
                        this.pageSize = res.data.per_page;
                    } else if (res.code == 2) {

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },

            //频次统计
            addStatistics() {
                this.common.statisticsAdd('客户级别列表', '1')
            },
        },
        //mounted 挂载结束状态
        mounted() {
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>