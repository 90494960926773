const login = [
  {// 官网
    path: "/",
    name: "index",
    //component: () => import('../views/index.vue')
    component: () => import('../views/login/login.vue')
  },{ // 登录
    path: "/login",
    name: "login",
    component: () => import('../views/login/login.vue')
  },{ // 单点登录
    path: "/ssologin",
    name: "ssologin",
    component: () => import('../views/login/ssoLogin.vue')
  },{ // 忘记密码
    path: "/forgetpwd",
    name: "forgetpwd",
    component: () => import('../views/login/forgetpwd.vue')
  },{ // 注册
    path: "/register",
    name: "register",
    component: () => import('../views/login/register.vue')
  },

];

export default login;
