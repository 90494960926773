<template>
  <header>
    <div class="logo-wrapper" v-if="retract">
      <img src="@/assets/img/logo.png" alt="logo" class="logo-img">
      <i class="gongzuotai gztshousuo shrink" @click="navShrink"></i>
    </div>
    <div class="logo-wrapper-shrink" v-else>
      <img src="@/assets/img/logogzt.png" alt="logo" class="logoS-img">
    </div>
    <div class="headR">
      <div class="headR-crumbs">
        <i class="gongzuotai gztzhankai exhibition" @click="navExhibition" v-if="!retract"></i>
      </div>
      <div class="headR-personal" style="margin-right: 10px">
        <div style="margin-right: 20px;color:#192744;">{{user_info.user_info.username}}</div>
        <div class="eipenter" @click="openEip('xjkh')">
          <img src="@/assets/img/eip.png" alt>
        </div>
        <div class="notice" @click="delPop = true">
          <i class="gongzuotai gzttuichu" title="退出"></i>
        </div>
      </div>
      <!--删除确认框-->
      <div class="main-pop" v-show="delPop">
        <div class="mask"></div>
        <div class="center" style="width:300px;">
          <div class="head">
            <span style="font-size: 24px">提示</span>
            <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
          </div>
          <div class="body" style="margin: auto">确定要退出？</div>
          <div class="foot" style="margin: auto;border-top: none!important">
            <button class="main-button l" @click="delPop=false">取消</button>
            <button class="main-button r" @click="logut()">确定</button>
          </div>
        </div>
      </div>
      <!--删除确认框-->
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "Head",
  props: {
    crumbs: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState(["corpid", "agentid"])
  },
  data() {
    return {
      delPop: false,
      user_info: {
        user_info: {},
        token: ""
      },
      crumbsList: null,
      retract: true,
      personal: null,
      isPersonal: true,
      isCorpid: false,
      isAgentid: false
    };
  },
  watch: {
    crumbs(val) {
      this.crumbsList = this.crumbs.split("/");
    },
    corpid() {
      if (this.corpid == "" || this.corpid == undefined) {
        this.isCorpid = true;
      } else {
        this.isCorpid = false;
      }
    },
    agentid() {
      if (this.agentid == "" || this.agentid == undefined) {
        this.isAgentid = true;
      } else {
        this.isAgentid = false;
      }
    }
  },
  methods: {
    init() {
      localStorage.getItem("user_info")
        ? (this.user_info = JSON.parse(localStorage.getItem("user_info")))
        : "";
    },
    navShrink() {
      this.retract = false;
      this.$emit("navShrink", false);
    },
    navExhibition() {
      this.retract = true;
      this.$emit("navExhibition", true);
    },
    logut() {
      this.axios("post", `${this.$phpBaseURL}v1/logout`, {}).then(res => {
        if (res.code == 1) {
          localStorage.removeItem("token");
        } else {
          localStorage.removeItem("token");
          this.$message({ message: res.msg, type: "warning" });
        }
        this.$router.push("/");
      });
    },
    itemClick() {
      this.$emit("headTo", this.$route.meta);
    },
    logOut() {
      this.logut();
    },
    /*打开eip*/
    openEip(id) {
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("get", `${this.$phpBaseURL}v1/client/${id}`, {
        token: JSON.parse(localStorage.getItem("user_info")).token
      }).then(res => {
        console.log(res.data);
        if (res.code == 1) {
          window.open(res.data.url.replace("/openfunction,XJKH", ""), "_blank");
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    }
  },
  mounted() {
    var isChrome =  navigator.userAgent.indexOf('Chrome') > -1  // 是否是谷歌 
    if (!isChrome) {
      // this.$message({ message: "为获取更好的体验，请使用Chrome浏览器!", showClose: true, type: "warning" });     
      this.$notify({
        title: '提示',
        message: '为获取更好的体验，请使用Chrome浏览器!',
        duration: 0
      }); 
    }
    if (!this.crumbsList) {
      this.crumbsList = this.$route.meta.crumbs.split("/");
    }
    this.init();
  }
};
</script>

<style scoped lang="scss">
header {
  height: 48px;
  display: flex;
  background-color: #fff;
  .personal-model {
    display: none;
    position: absolute;
    right: 16px;
    top: 40px;
    width: 228px;
    height: 300px;
    padding-top: 20px;
    z-index: 999;
    .personal-wrap {
      width: 228px;
      height: 277px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 6px 0px rgba(206, 206, 206, 0.5);
      border-radius: 4px;
      display: flex;
      flex-flow: column;

      .personal-model-head {
        padding: 27px 24px 16px 24px;
        border-bottom: 1px solid rgba(223, 226, 231, 1);
        .item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(25, 39, 68, 1);
            i {
              font-size: 14px;
              color: #b0b4b9;
              margin-right: 8px;
            }
          }
          .position {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 20px;
            background: rgba(255, 110, 90, 0.15);
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 110, 90, 1);
            line-height: 20px;
          }
          .modifyPass {
            font-size: 14px;
            font-weight: 400;
            color: rgba(71, 134, 255, 1);
            line-height: 20px;
          }
        }
      }
      .personal-model-body {
        flex: 1;
        padding: 16px 24px;
        .item,
        .items {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(25, 39, 68, 1);
            i {
              font-size: 14px;
              color: #b0b4b9;
              margin-right: 8px;
            }
          }
          .entName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .editionN,
          .accountN {
            font-size: 14px;
            font-weight: 400;
            color: rgba(120, 128, 144, 1);
            line-height: 20px;
          }
          .editionS {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 110, 90, 1);
            line-height: 20px;
          }
          .accountS {
            font-size: 14px;
            font-weight: 400;
            color: rgba(173, 179, 188, 1);
            line-height: 20px;
          }
        }
        .items {
          margin: 0;
          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(71, 134, 255, 1);
          }
        }
      }
      .personal-model-foot {
        height: 32px;
        display: flex;
        align-items: center;
        background: rgba(247, 248, 250, 1);
        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(120, 128, 144, 1);
          line-height: 20px;
          cursor: pointer;
          margin-left: 24px;
          i {
            color: #b0b3bb;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .logo-wrapper {
    width: 240px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 0px 6px 0px rgba(206, 206, 206, 0.34);
    .logo-img {
      width: 86px;
      height: auto;
      margin-left: 23px;
    }
    .shrink {
      margin-right: 11px;
    }
  }
  .logo-wrapper-shrink {
    width: 48px;
    height: 48px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .logoS-img {
      width: 20px;
      height: 20px;
    }
  }
  .headR {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    box-shadow: 4px 3px 6px 0px rgba(206, 206, 206, 0.34);
    .headR-crumbs {
      display: flex;
      .exhibition {
        margin-left: 24px;
      }
      .crumbs {
        margin-left: 34px;
        display: flex;
        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(173, 179, 188, 1);
        }
        i {
          margin: 0 8px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(173, 179, 188, 1);
        }
      }
    }
    .headR-authorize {
      width: 498px;
      height: 32px;
      background: rgba(255, 233, 230, 1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 110, 90, 1);
        line-height: 20px;
      }
      a {
        font-size: 14px;
        font-weight: 500;
        color: rgba(71, 134, 255, 1);
        line-height: 20px;
        margin-left: 16px;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .headR-personal {
      display: flex;
      position: relative;
      height: 100%;
      align-items: center;
      .eipenter {
        margin-right: 10px;
        cursor: pointer;
        img {
          width: 30px;
        }
      }
      .notice {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: rgba(242, 243, 245, 1);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        position: relative;
        &.active:after {
          content: "";
          display: block;
          position: absolute;
          width: 5px;
          height: 5px;
          background: red;
          border-radius: 50%;
          left: 58%;
          top: 24%;
        }
        i {
          color: #adb3bc;
        }
      }
      .personal {
        display: flex;
        position: relative;
        height: 100%;
        align-items: center;
        &:hover .personal-model {
          display: block;
        }
        .avatar {
          width: 30px;
          height: 30px;
          /*background:rgba(255,110,90,1);*/
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          img {
            width: 100%;
          }
          span {
          }
        }
        .personal-msg {
          margin-right: 24px;
          cursor: pointer;
          .entName {
            width: 72px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(25, 39, 68, 1);
            overflow: hidden;
            margin-bottom: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .name {
            width: 72px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(120, 128, 144, 1);
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              display: inline-block;
              width: 80%;
              overflow: hidden;
            }
            i {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%) scale(0.5);
              font-size: 12px;
              font-weight: 400;
              color: rgba(120, 128, 144, 1);
            }
          }
        }
      }
    }
  }
}
</style>