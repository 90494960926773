<template>
  <div class="main-div media" style="background:none;padding:0;">
    <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="goBack()">返回</el-button>
        <span>账户充值</span>
      </div>
    <div style="background:#fff;padding:24px;margin-bottom:24px;">
      <p class="main-title">充值设定</p>
      <el-form label-width="180px" size="mini" style="width:500px;">
        <el-form-item label="选择客户">
          <el-select
            v-model="rechargeclientCompany"
            filterable
            clearable
            remote
            value-key="clientCompanyId"
            :remote-method="remoteMethod"
            placeholder="客户公司名称"
            @clear="clearSearch"
            @focus="focusCom"
            @change="clientCompanyChange"
          >
            <el-option
              v-for="(item,index) in clientCompanyList"
              :key="index"
              :label="item.clientCompanyName"
              :value="item"
            ></el-option>
            <div style="float: right;margin-right:10px;padding-bottom: 10px">
              <el-pagination
                @current-change="getRelatedComList"
                :current-page="projectPostData.pageNum"
                :page-size="projectPostData.pageSize"
                layout="total, prev, pager, next"
                :total="projectPostData.alltotal"
              ></el-pagination>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="账户可用余额">
          {{clientBalance}}
        </el-form-item>
        <el-form-item label="本次充值方式">
          <el-radio v-model="rechargeradio" label="1">充值相同金额</el-radio>
          <el-radio v-model="rechargeradio" label="2">自定义金额</el-radio>
        </el-form-item>
        <el-form-item v-if="rechargeradio == '1'" label="单账户充值金额">
          <el-input v-model="singleRechargeNumber" @blur="projectAmounts()"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="background:#fff;padding:24px;margin-bottom:24px;">
      <p class="main-title">
        <span>充值账户</span>
        <el-button size="mini" type="primary" style="margin-left:15px;" @click="showAddAccAlert()">添加广告账户</el-button>
      </p>
      <el-table
        :data="paramsInfo"
        :selectable="checkSelect"
        border
        style="width: 100%">
        <el-table-column
          prop="accountName"
          label="账户名称">
        </el-table-column>
        <el-table-column
          prop="accountId"
          label="账户ID">
        </el-table-column>
        <el-table-column label="账户状态">
          <template slot-scope="scope">
            <span :class="scope.row.accountStatus | statusClassFilter">
              {{scope.row.accountStatus == 0 ? '取消授权' : ''}}
              {{scope.row.accountStatus == 1 ? '正常' : ''}}
              {{scope.row.accountStatus == 2 ? '关闭' : ''}}
              {{scope.row.accountStatus == 3 ? '封户' : ''}}
              {{scope.row.accountStatus == 4 ? '空户' : ''}}
              {{scope.row.accountStatus == 5 ? '违规' : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="clientCompanyName"
          label="归属客户">
        </el-table-column>
        <el-table-column
            v-if="rechargeradio == '2'"
            label="充值金额">
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.rechargeAmount" @blur="projectAmounts(scope.$index)"></el-input>
            </template>
          </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="deleteAccount(scope.row,scope.$index)">删除账户</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="background:#fff;padding:24px;">
      <div class="info-row">
        <div class="info-cell w-80">
          <div class="info-text calc-2w">
            <button class="main-button r" @click="add">保存</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加广告账户 -->
    <div class="main-pop" v-show="showBatchAdd">
      <div class="mask"></div>
      <div class="center" style="width:900px;">
        <div class="head">
          <span>添加广告账户</span>
          <i class="gongzuotai gztguanbi" @click="showBatchAdd=false"></i>
        </div>
        <div class="body" style="padding-right:0px;overflow:scroll;">
          <div style="display:flex;align-items:center;margin-bottom:10px">
            <div class="main-inputBox" style="width: 420px!important;margin-bottom:0;">
              <el-input v-model="alertGetAccList.accounts" clearable placeholder="请输入账户名称或账户ID，支持用英文“,”分隔输入多个"></el-input>
            </div>
            <div class="main-selectBox" style="margin-bottom:0;">
              <div class="main-button" @click="enterSearch(alertGetAccList)">查询</div>
              <div class="main-button main-button-first" @click="resetList1()">重置</div>
            </div>
          </div>
          <div class="searchedAcc" v-if="nonexistentAcc">已找到{{alertGetAccList.total}}个广告账户 <span>有{{nonexistentAcc.length}}个广告账户ID不存在</span> <el-button type="text" @click="shownoexist = true">详情</el-button></div>
          <el-table ref="multipleTable" :data="fbzhtableData" tooltip-effect="dark" style="width:98%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="账户名称" prop="accountName" show-overflow-tooltip></el-table-column>
            <el-table-column label="账户ID" prop="accountId"></el-table-column>
            <el-table-column label="账户状态">
              <template slot-scope="scope">
                <span :class="scope.row.accountStatus | statusClassFilter">
                  {{scope.row.accountStatus == 0 ? '取消授权' : ''}}
                  {{scope.row.accountStatus == 1 ? '正常' : ''}}
                  {{scope.row.accountStatus == 2 ? '关闭' : ''}}
                  {{scope.row.accountStatus == 3 ? '封户' : ''}}
                  {{scope.row.accountStatus == 4 ? '空户' : ''}}
                  {{scope.row.accountStatus == 5 ? '违规' : ''}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="客户公司" prop="clientCompanyName" show-overflow-tooltip></el-table-column>
          </el-table>
          <pagination-vue :isSevenPagin="true" @currentChange="getAccList" @currentSizeChange="sizeInit" :pageSize="alertGetAccList.pageSize" :total="alertGetAccList.total" :firstPage="firstPage"/>
        </div>
        <div class="foot">
          <button class="main-button l" @click="showBatchAdd=false">取消</button>
          <button class="main-button r" @click="subfbzh">确定</button>
        </div>
      </div>
    </div>
    <!-- 不存在的账户列表 -->
    <el-dialog title="不存在的账户列表" :visible.sync="shownoexist">
      <div style="padding:20px;">
        <el-input
          autosize
          type="textarea"
          v-model="nonexistentAccStr">
        </el-input>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import { log } from "util";

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      shownoexist:false,
      nonexistentAccStr:"",
      nonexistentAcc:[],
      rechargeclientCompany:{},
      ramarkstext:"",
      currentRow:{},
      rechargeradio:"1",
      rechargeclientCompanyId:"",
      singleRechargeNumber:"",
      clientBalance:0,
      fbzhtableData: [],
      multipleSelection: [],
      showBatchAdd: false,
      bigOutAmount: "",
      inIDs: [],
      showAppropriationrow: {
        projectName: "",
        accountId: ""
      },
      firstPage: 1,
      loading: true,
      timer: "",
      visible: false,
      istrue: false,
      token: "",
      msg: "",
      showFlag: 1,
      total: 1,
      alertGetAccList:{
        filterClient:true,
        clientCompanyId:"",
        accountCompanyId:"",
        accounts:"",
        total:0,
        pageNum:1,
        pageSize:7
      },
      accountStatus: "",
      paramsInfo: [],
      rechargeMediaAdd: {
        platform: "", //平台:1 facebook;2 twitter,3 google,5 tiktok
        accountId: "", //广告账户ID
        rechargeAmount: "", //充值金额,
        status: ""
      },
      pausePop: false,
      list: [],
      clientCompanyList: [],
      accountCompanyList: [],
      projectList: [],
      accountList: [],
      creditAmount: "",
      accountPlatformName: "",
      accountPlatform: "",
      accountCost: "",
      accountLimit: "",
      /*返回客户管理下搜索项用到的数据接口*/
      editStatusPop: false,
      project_id: "",
      project_status: "",
      openZhPop: false,
      radio: "1",
      editID:1,
      accountListOptions: [], //开户公司下拉
      projectPostData: {
        alltotal: 0,
        pageSize: 10,
        pageNum: 1,
        clientCompanyName:""
      },
      selectedclientCompanyData:[],
      errorNotify:{},
    };
  },
  filters: {
    statusClassFilter(value) {
      //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 3:
          m = "shenpi-bohui";
          break;
        case 4:
          m = "shenpi-yiguoqi";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    },
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    this.getRelatedComList(1)
    console.log(this.$route.query);
    
    if (this.$route.query.currentAccount) {
      let elem = JSON.parse(this.$route.query.currentAccount)
      this.paramsInfo.push({
        clientCompanyId:elem.clientCompanyId,
        clientCompanyName:elem.clientCompanyName,
        accountStatus:elem.accountStatus,
        accountId:elem.accountId,
        accountName:elem.accountName,
        platform:elem.platform,
        rechargeAmount:0.00
      })
    }
  },
  mounted() {
  },
  methods: {
    goBack(){
      this.$router.push({
        name:this.$route.query.from,
        query:{
          postdata:this.$route.query.mediapostdata
        }
      })
    },
    projectAmounts(index){
      if (index!=null) {
        if(isNaN(parseFloat(this.paramsInfo[index].rechargeAmount))){
          this.$set(this.paramsInfo[index], 'rechargeAmount', 0.00);
        }else{
          this.$set(this.paramsInfo[index], 'rechargeAmount', this.common.toThousands(this.paramsInfo[index].rechargeAmount,2));
        }
      }else{
        if(isNaN(parseFloat(this.singleRechargeNumber))){
          this.singleRechargeNumber= 0.00;
        }else{
          this.singleRechargeNumber=this.common.toThousands(this.singleRechargeNumber,2);
        }
      }
    },
    getinfo(row){
      console.log(row);
      this.showFlag = 3
    },
    async openCloseReason(row,val){
      this.editID = val
      this.currentRow = row
      let ress = await this.checkBlackList(row);
      if (ress.code == 1) {
        if (ress.data == 1) {
          this.$message({
            message: "该用户在有效黑名单中，暂时不能充值",
            type: "warning"
          });
          return false;
        }
      } else {
        this.$message({message: ress.msg,type: "error"});
        return false;
      }
      this.showFlag = 2;
    },
    showAddAccAlert(){
      if (!this.rechargeclientCompanyId) {
        this.$message({ message: "请先选择客户", type: "warning" });
        return        
      }
      this.showBatchAdd = true;
      this.getAccList();
    },
    checkSelect (row,index) {
      let isChecked = true;
      if (row.accountStatus == 1 || row.accountStatus == 4) { // 判断里面是否存在某个参数
        isChecked = true
      } else {
        isChecked = false
      }
      return isChecked
    },
    //充值申请 确定
    add() {
      if (!this.rechargeclientCompanyId) {
        this.$message({ message: "请选择客户", type: "warning" });
        return;
      }
      if (this.rechargeradio == 1) {
        if (!this.singleRechargeNumber) {
          this.$message({ message: "请输入单账户充值金额", type: "warning" });
          return;
        }else if(this.singleRechargeNumber > 99999999){
          this.$message({message: "输入的充值金额较大，请重新输入",type: "warning"});
          return;
        }else{
          for (let i = 0; i < this.paramsInfo.length; i++) {
            this.paramsInfo[i].rechargeAmount = this.singleRechargeNumber
          }
        }
      }
      if (this.rechargeradio == 2) {
        this.singleRechargeNumber = 0
        for (let i = 0; i < this.paramsInfo.length; i++) {
          if (!this.paramsInfo[i].rechargeAmount || Number(this.paramsInfo[i].rechargeAmount) < 1) {
            this.$message({ message: "请填写完整充值金额", type: "warning" });
            return;
          }
        }
      }
      if (this.paramsInfo.length <1) {
        this.$message({ message: "请选择广告账户", type: "warning" });
        return;
      }else{
        for (let i = 0; i < this.paramsInfo.length; i++) {
          this.paramsInfo[i].rechargeAmount = (this.paramsInfo[i].rechargeAmount)
          this.paramsInfo[i].clientCompanyId = this.rechargeclientCompanyId
        }
        console.log(this.paramsInfo);
      }
      this.paramsInfo.map((i)=>{
        i.rechargeAmount = i.rechargeAmount.indexOf(',') > 0 ? i.rechargeAmount.replace(/\,/g,'') : i.rechargeAmount
        i.rechargeAmount = parseFloat(i.rechargeAmount)
        return i
      })
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
      });
      this.axios("POST",`${this.$javaBaseURL}recharge/add`,this.paramsInfo).then(res => {
        if (res.code == 1) {
          // this.common.statisticsAdd("新增充值", "2");
          this.$confirm('操作完成，您可以进行以下操作', '提示', {
            confirmButtonText: '继续操作',
            cancelButtonText: '查看操作结果',
            type: 'warning'
          }).then(() => {
            this.rechargeclientCompany = "";
            this.rechargeradio = "1";
            this.singleRechargeNumber = "";
            this.paramsInfo = [];
            this.clientBalance = '0.00'
          }).catch(() => {
            this.$router.push({
              name:"rechargeMedia"
            })       
          });
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
        loading.close();
      });
    },
    zeroClear(){
      var arr = [];
      this.paramsInfo.forEach(item => {
        arr.push({
          platform:1,
          accountId:item.accountId,
          operateType:5,
          remark:this.ramarkstext
        })
      });
      this.axios("POST",`${this.$javaBaseURL}accountOperate/add`,arr).then(res => {
        if (res.code == 1) {
          this.init();
          this.$message({ message: "清零成功", type: "success" });
          this.showFlag = 1;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    deleteAccount(row,index){
      this.paramsInfo.splice(index,1)
    },
    sizeInit(size){
      this.alertGetAccList.pageSize = size ? size : 7;
      this.getAccList(1)
    },
    getAccList(page){
      this.alertGetAccList.clientCompanyId = this.rechargeclientCompanyId
      page = page ? page : 1
      this.init(page,this.alertGetAccList)
    },
    resetList1(){
      this.alertGetAccList = {
        accountCompanyId:"",
        accounts:"",
        total:0,
        pageNum:1,
        pageSize:7
      }
      this.getAccList()
    },
    clientCompanyChange() {
      if (this.errorNotify.id) {
        this.$notify.closeAll()
      }
      this.rechargeclientCompanyId = this.rechargeclientCompany.clientCompanyId
      if (!this.rechargeclientCompanyId) {
        this.clientBalance = 0.00
        return
      }
      this.projectPostData.clientCompanyName = this.rechargeclientCompany.clientCompanyName
      console.log(this.projectPostData.clientCompanyName);
      this.accountCompanyChangeList(this.rechargeclientCompanyId)
      this.checkClientStatus(this.rechargeclientCompanyId)
      this.axios(
        "get",
        `${this.$javaBaseURL}clientCompany/getClientBalance`,
        {clientCompanyId:this.rechargeclientCompanyId}
      ).then(res => {
        if (res.code == 1) {
          this.clientBalance = res.data.balance ? res.data.balance : 0
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    checkClientStatus (clientCompanyId){
      this.axios("get", `${this.$phpBaseURL}v1/client/checkClientStatus`, {
        client_company_id: clientCompanyId
      }).then(res=>{
        if (res.code == 1) {
          this.errorNotify = this.$notify.error({
            offset:100,
            duration: 0,
            title: '客户异常',
            showClose: false,
            message: res.data.reason
          });
          console.log(this.errorNotify);
        }else{
          if (this.errorNotify.id) {
            this.$notify.closeAll()
            this.errorNotify = {}
          }
        }
      })
    },
    // 充值
    async clientCompanys(row,val) {
      this.editID = val
      this.currentRow = row
      // 检查 开户公司是否在黑名单
      let ress = await this.checkBlackList(row);
      if (ress.code == 1) {
        if (ress.data == 1) {
          this.$message({
            message: "该用户在有效黑名单中，暂时不能充值",
            type: "warning"
          });
          return false;
        }
      } else {
        this.$message({message: ress.msg,type: "error"});
        return false;
      }
      this.getRelatedComList(1)
      this.showFlag = 2;
    },
    remoteMethod(query){
      this.projectPostData.clientCompanyName = query
      this.getRelatedComList()
    },
    clearSearch(){
      this.projectPostData.clientCompanyName = ''
      this.getRelatedComList()
    },
    focusCom(){
      if (this.clientCompanyList.length == 0) {
        this.projectPostData.clientCompanyName = ''        
      }
      this.getRelatedComList()
    },
    //客户公司下拉框
    getRelatedComList(page){
      var that = this
      this.projectPostData.pageNum = page ? page :1;
      this.axios("get", `${this.$javaBaseURL}clientCompany/selectAll`, this.projectPostData).then(
        res => {
          if (res.code == 1) {
            this.clientCompanyList = res.data.list;
            this.projectPostData.alltotal = res.data.total;
            if (this.projectPostData.alltotal == 1) {
              this.$nextTick(function () {
                that.rechargeclientCompanyId = that.clientCompanyList[0].clientCompanyId
                that.projectPostData.clientCompanyName = that.clientCompanyList[0].clientCompanyName
                that.clientCompanyChange()
              });
            }
          } else if (res.code == 20001) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        }
      );
    },
    formatTimes(event){
      this.ajaxDataSearch.openTimeStart = this.ajaxDataSearch.applicationTimeArrange_[0]
      this.ajaxDataSearch.openTimeEnd = this.ajaxDataSearch.applicationTimeArrange_[1]
    },
    init(page,data) {
      var postdata = {}
      if (data) {
        data.pageNum = page?page:1
      }else{
        this.ajaxDataSearch.pageNum = page ? page : 1
        this.loading = true;
        clearTimeout(this.timer);
      }
      this.firstPage = page;
      this.axios("get", `${this.$javaBaseURL}accountMedia/list`,data ? data : {
        accounts: this.ajaxDataSearch.accounts,
        accountCompanyId: this.ajaxDataSearch.accountCompanyId,
        clientCompanyName: this.ajaxDataSearch.clientCompanyName,
        accountCompanyName: this.ajaxDataSearch.accountCompanyName,
        platform: this.ajaxDataSearch.platform,
        accountStatus: this.ajaxDataSearch.accountStatus,
        openTimeStart: this.ajaxDataSearch.openTimeStart,
        openTimeEnd: this.ajaxDataSearch.openTimeEnd,
        pageNum: this.ajaxDataSearch.pageNum,
        pageSize: this.ajaxDataSearch.pageSize
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          if (data) {
            this.fbzhtableData = res.data.list
            this.alertGetAccList.total = res.data.total;
            this.alertGetAccList.pageSize = res.data.pageSize;
            this.nonexistentAcc = res.data.nonexistent
            this.nonexistentAccStr = res.data.nonexistent ? res.data.nonexistent.join('\n') : ""
          }else{
            this.list = res.data.list;
            this.total = res.data.total;
            this.pageSize = res.data.pageSize;
          }
          
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch(data) {
      this.firstPage = 1;
      this.init(1,data);
    },
    //根据客户公司ID获取开户公司列表
    accountCompanyChangeList(clientCompanyId) {
      this.axios(
        "get",
        `${this.$javaBaseURL}accountCompany/select?clientCompanyId=${clientCompanyId}`,
        {}
      ).then(res => {
        if (res.code == 1) {
          this.accountListOptions = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //验证账号开户公司是否在黑名单中
    checkBlackList(obj) {
      return new Promise(resolve => {
        var req_api = `accountCompany/selectBlacklistByName?accountCompanyName=${obj.accountCompanyName}`;
        this.axios("GET", `${this.$javaBaseURL}${req_api}`, {})
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            this.$message({
              showClose: true,
              message: err,
              type: "error"
            });
            console.log(err);
            reject(err);
          });
      });
    },
    getErrorStatusMsg(status){
      var str = ''
      switch (status) {
        case 0:
          str = '账户不存在，请检查广告账户是否正确'
          break;
        case 2:
          str = '账户已关闭，不能执行账户操作'
          break;
        case 3:
          str = '账户已封户，不能执行账户操作'
          break;
        case 4:
          str = '账户已解除授权，不能执行账户操作'
          break;
        case 5:
          str = '开户公司在黑名单中，不能执行账户操作'
          break;
        case 6:
          str = '账户已清零，不能执行账户操作'
          break;
        case 7:
          str = '账户已关停，不能执行账户操作'
          break;
        default:
          break;
      }
       console.log(str);
        return str;
    },
    subfbzh(){
      if (this.multipleSelection.length>0) {
        this.multipleSelection.forEach(elem => {
          this.paramsInfo.push({
            clientCompanyId:this.rechargeclientCompanyId,
            clientCompanyName:elem.clientCompanyName,
            accountStatus:elem.accountStatus,
            accountId:elem.accountId,
            accountName:elem.accountName,
            platform:elem.platform,
            rechargeAmount:0.00
          })
        });
        this.multipleSelection = []
        this.showBatchAdd=false
      }else{
        this.$message({message: '请选择账户', type: 'warning'});        
      }
    },
    delfbzh(){
      var msg = ''
      if (this.multipleSelection.length>0) {
        this.multipleSelection.forEach(ele => {
          if (ele.errorStattus != 1) {
            msg+=ele.accountId+','
          }
        });
        msg += '这些账户不符合批量操作的标准，是否删除这些数据？'
        this.$alert(msg, '确认', {
          confirmButtonText: '确定',
          callback: action => {
            for (let i = 0; i < this.multipleSelection.length; i++) {
              for (let k = 0; k < this.fbzhtableData.length; k++) {
                if (this.multipleSelection[i].accountId == this.fbzhtableData[k].accountId) {
                  this.fbzhtableData.splice(k,1)
                }
              }
            }
          }
        });
      }else{
        this.$message({message: '请选择账户', type: 'warning'});        
      }
    },
    getImportxlsx(){
      window.open(`${this.$javaBaseURL}download/Import.xlsx`)
    },
    fbzhbeforeUpload (file) {
      var that = this
      let fd = new FormData();
      fd.append('file', file);
      axios.defaults.headers.common['Content-Type'] = "multipart/form-data";
      this.axios('POST', `${this.$javaBaseURL}batch/dataImport`, fd).then(res => {
          if (res.code == 1) {
            this.$message({message: res.msg, type: 'success'});
            this.fbzhtableData = res.data
          } else if(res.code == 20001){
              //token过期处理
              this.common.tokenExpired(res.msg);
          } else {
              this.$message({message: res.msg, type: 'warning'});
          }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
  },
  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}
.main-inputBox {
  width: 200px !important;
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}

// 以下不起作用，可能是因为下拉框是动态添加的
// ::v-deep .el-dropdown-menu-self{
//     min-width:436px!important;
//     padding:10px!important;
//     box-sizing:border-box!important;
// }
// ::v-deep .el-dropdown-menu-self ul li{
//     list-style: none;
//     line-height: 36px;
//     padding: 0 20px;
//     margin: 0;
//     font-size: 14px;
//     color: #606266;
//     cursor: pointer;
//     outline: none;
// }
.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style scoped lang="scss">
.searchedAcc{
  line-height: 30px;
  margin-bottom: 0;
  font-size: 14px;
  span{
    color: red;
  }
}
</style>
<style>
.el-input {
  /*width: 108%!important;*/
}
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>