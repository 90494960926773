<template>
  <div class="main-div media" @click="visible=false" style="background:none;padding:0;">
    <div class="detail_header">
      <el-button size="small" icon="el-icon-back" @click="createReset()">返回</el-button>
      <span>{{editObj.id?'编辑IO':"新建IO"}}</span>
    </div>
    <div style="background:#fff;padding:24px;margin-bottom:24px;">
      <!-- <p class="main-title">{{editObj.id?'编辑IO':"新增IO"}}</p> -->
      <el-form label-width="80px" size="mini">
        <el-form-item label="IO名称" required >
          <el-input
            style="width:350px"
            v-model="editObj.name"
            placeholder="新IO命名规范请参考已有IO"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联客户" required>
          <el-select
            :disabled="noChooseCustom"
            style="width:350px;"
            size="mini"
            v-model="ajaxDataSearch_custom.client_company_name"
            filterable
            remote
            :remote-method="remoteMethod"
            placeholder="请选择"
            @change="updateValue"
          >
            <el-option
              v-for="(item,k) in projectList"
              :key="k"
              :label="item.client_company_name"
              :value="item.client_company_id"
            ></el-option>
            <div style="float: right;margin-right:10px;padding-bottom: 10px">
              <el-pagination
                @current-change="getProjectList"
                :current-page="ajaxDataSearch_custom.page"
                :page-size="ajaxDataSearch_custom.page_num"
                layout="total, prev, pager, next"
                :total="ajaxDataSearch_custom.total"
              ></el-pagination>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="投放类型" required>
          <el-radio v-model="editObj.delivery_type" :label="1">自投</el-radio>
          <el-radio v-model="editObj.delivery_type" :label="2">代投</el-radio>
        </el-form-item>
        <el-form-item label="IO状态" required>
          <el-radio v-model="editObj.status" :label="1">激活</el-radio>
          <el-radio v-model="editObj.status" :label="2">暂停</el-radio>
        </el-form-item>
        <el-form-item label="结算类型" required>
          <el-radio v-model="editObj.settlement_type" :label="1">常规消费制</el-radio>
          <el-radio v-model="editObj.settlement_type" :label="2">项目完成制(合同包含签约额)</el-radio>
        </el-form-item>
        <el-form-item label="返点规则" required>
          <el-radio v-model="editObj.client_rebate" :label="1">跟随客户返点</el-radio>
          <el-radio v-model="editObj.client_rebate" :label="2">自定义返点</el-radio>
        </el-form-item>
      </el-form>
      <el-table v-show="editObj.client_company_id && editObj.client_rebate == 1" :data="tableData" border style="width: 100%">
        <el-table-column prop="network_show" label="媒体平台"></el-table-column>
        <el-table-column label="有效期">
          <template slot-scope="scope">
            {{scope.row.starttime}} - {{scope.row.endtime}}
          </template>
        </el-table-column>
        <el-table-column prop="ratio_type_show" label="返点类型"></el-table-column>
        <el-table-column label="返点比例">
          <template slot-scope="scope">
            {{scope.row.ratio_type == 1 ? scope.row.ratio : getSectionRatio(scope.row.section_ratio)}}
          </template>
        </el-table-column>
      </el-table>
      <pagination-vue @currentChange="getCustomerRebateList" :pageSize="clientRebateData.page_num" :total="clientRebateData.total" :firstPage="clientRebateData.page" />
      <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
        <div class="info-cell w-80">
          <div class="info-text calc-2w">
            <!-- <button class="main-button l" @click="createReset()">取消</button> -->
            <button class="main-button r" style="margin-left:80px;" @click="saveRate()">保存</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import { mkdir, mkdirSync } from "fs";

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      noChooseCustom:false,
      projectList: [],
      createandedit: false,
      serviceTypeList: [
        {
          label: "自投",
          value: "1"
        },
        {
          label: "代投",
          value: "2"
        }
      ],
      iostatusoptions: [
        {
          label: "激活",
          value: 1
        },
        {
          label: "暂停",
          value: 2
        },
        {
          label: "删除",
          value: 3
        }
      ],
      options: [
        {
          label: "Facebook",
          value: 1
        },
        {
          label: "Google",
          value: 2
        },
        {
          label: "Twitter",
          value: 3
        }
      ],
      delPop: false,
      loading: false,
      blackLists: [],
      visible: false,
      token: "",
      showFlag: 1,
      total: 1,
      editObj: {
        id: "",
        name: "",
        client_rebate: 1,
        status: 1,
        delivery_type: 1,
        settlement_type: 1
      },
      radio1: 1,
      ajaxDataSearch: {
        page: 1,
        page_num:15,
        network: "",
        io_id: "",
        io_name: "",
        io_status: "",
        account_id: "",
        delivery_type:"",
        client_company_name:"",
      },
      configurationData: {
        receivable_client_status: {},
        is_overdue_config: {},
        client_status_array: {},
        client_source_array: {},
        client_connection_relation_array: {},
        client_relation_company_array: {},
        is_contain_config: {},
        platform_array: {},
        contact_status_array: {},
        gender_array: {},
        contract_status_array: {},
        business_type_array: {},
        contract_type_array: {},
        contract_subdivision_array: {},
        is_guarantee_archive_prepaid_array: {},
        project_status_array: {},
        project_approval_status: {},
        file_upload_status: {},
        platform_name_array: {},
        return_point_ratio_accuracy_array: {},
        departments_data: [],
        industry_data: []
      },
      ajaxDataSearch_custom: {
        client_company_name: "",
        page:1,
        total:0,
        page_num:15
      },
      tableData: [],
      clientRebateData:{
        client_company_id:"",
        page:1,
        total:0,
        page_num:15
      },
      errorNotify:{}
    };
  },
  filters: {},
  mounted() {
    this.common.getConfigData('client_level,project_principal,project_authorization_list').then((res)=>{
      this.configurationData = res.data
    }).catch(err => {
      this.$message({ message: err.msg, type: "warning" });      
    })
  },
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    if (this.$route.query.from == 'open') {
      this.noChooseCustom = true;
      this.ajaxDataSearch_custom.client_company_name = localStorage.getItem('khemItem') ? JSON.parse(localStorage.getItem('khemItem')).clientCompanyName : ""
      this.editObj.client_company_id = localStorage.getItem('khemItem') ? JSON.parse(localStorage.getItem('khemItem')).clientCompanyId : ""
    }
    
    this.getProjectList();    
  },
  methods: {
    getSectionRatio(arr){
      console.log(arr);
      var str = ''
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        str += element.start+'-'+element.end+":"+element.ratio+';'
      }
      return str
    },
    remoteMethod(query){
      this.ajaxDataSearch_custom.client_company_name = query
      this.getProjectList()
    },
    // 获取客户列表
    getProjectList(page) {
      this.ajaxDataSearch_custom.page = page ? page : 1
      this.axios("get", `${this.$phpBaseURL}v1/io/ioClientSelect`, this.ajaxDataSearch_custom).then(res => {
        if (res.code == 1) {
          this.projectList = res.data.data;
          this.ajaxDataSearch_custom.total = res.data.total;
          this.ajaxDataSearch_custom.page_num = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    updateValue(event) {
      if (this.errorNotify.id) {
        this.$notify.closeAll()
      }
      this.editObj.client_company_id = event;
      this.getCustomerRebateList(1,this.editObj.client_company_id)
      this.checkClientStatus(this.editObj.client_company_id)
    },
    checkClientStatus (clientCompanyId){
      this.axios("get", `${this.$phpBaseURL}v1/client/checkClientStatus`, {
        client_company_id: clientCompanyId
      }).then(res=>{
        if (res.code == 1) {
          this.errorNotify = this.$notify.error({
            offset:100,
            duration: 0,
            title: '客户异常',
            showClose: false,
            message: res.data.reason
          });
        }else{
          if (this.errorNotify.id) {
            this.$notify.closeAll()
            this.errorNotify = {}
          }
        }
      })
    },
    getCustomerRebateList(page,client_company_id){
      if (client_company_id) {
        this.clientRebateData.client_company_id = client_company_id
      }
      this.axios("get", `${this.$phpBaseURL}v1/io/clientRebate`, this.clientRebateData).then(res => {
        if (res.code == 1) {
          this.tableData = res.data.data;
          this.clientRebateData.total = res.data.total;
          this.clientRebateData.page_num = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 点击编辑按钮
    editMethod(data) {
      this.editObj.id = data.id;
      this.axios("get", `${this.$phpBaseURL}v1/io/edit`, {
        id: data.id
      }).then(res => {
        if (res.code == 1) {
          this.ajaxDataSearch_custom.client_company_name = res.data.client_company_name
          this.editObj = res.data;
          this.showFlag = 2;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    saveRate() {
      if (!this.editObj.name) {
        this.$message({ message: '请填写IO名称', type: "warning" });        
        return;
      }else if (!this.editObj.status) {
        this.$message({ message: '请选择IO状态', type: "warning" });        
        return;
      }else if (!this.editObj.client_rebate) {
        this.$message({ message: '请选择返点规则', type: "warning" });        
        return;
      }else if (!this.editObj.client_company_id) {
        this.$message({ message: '请选择关联客户', type: "warning" });        
        return;
      }else if (!this.editObj.settlement_type) {
        this.$message({ message: '请选择结算类型', type: "warning" });        
        return;
      }else if (!this.editObj.delivery_type) {
        this.$message({ message: '请选择投放类型', type: "warning" });        
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var url = `${this.$phpBaseURL}v1/io/add`;
      if (this.editObj.id) {
        url = `${this.$phpBaseURL}v1/io/editSubmit`;
      }
      this.axios("post", url, this.editObj).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          loading.close();
          if (this.$route.query.from == 'open') {
            location.href = "/#/open?from=createIO&io_id="+res.data.io_id
            this.$router.push({path: '/open',query:{from:'createIO',showFlag:this.$route.query.showFlag,io_id:res.data.io_id}})            
          }else{
            this.$router.push({path: '/ioDetail',query:{from:'createIO',id:res.data.io_id}})            
          }
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    createReset() {
      if (this.$route.query.from == 'open') {
        this.$router.push(
          {
            path:"/open",
            query:{
              from:'createIO',
              showFlag:this.$route.query.showFlag,
            }
          }
        )
      }else{
        this.$router.push(
          {
            path:"/ioList",
            query:{
              from:'createIO',
              searchData:this.$route.query.from == 'ioList' ? this.$route.query.searchData :''
            }
          }
        )
      }
      this.editObj = {
        id: "",
        name: "",
        client_rebate: 1,
        status: 1,
        delivery_type: 1,
        settlement_type: 1
      };
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-pop .center .head {
  border-bottom: none;
}
.main-pop .center .head span {
  margin: auto;
}

.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}
.flexd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.flexd label {
  width: 90px;
  font-size: 14px;
}
.flexd label.longlabel {
  width: 150px;
}
.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style>
.main-btnBox .main-button span {
  color: #01ace4 !important;
}
.date-picker-input input {
  padding-left: 30px !important;
  height: 32px !important;
}
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>