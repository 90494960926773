<template>
    <div>
        <!-- 列表  -->
        <div v-if="showFlag==1" style="background:#fff;padding:24px;">
            <p class="main-title" style="margin-bottom: 32px">余额充值记录</p>
            <div>
                <div class="main-inputBox" style="width:440px!important;">
                    <el-input v-model="ajaxDataSearch.client_company_name" placeholder="请输入客户名称" clearable></el-input>
                </div>
                <div class="main-selectBox">
                    <el-select v-model="ajaxDataSearch.pay_method" clearable placeholder="充值渠道">
                        <el-option
                                v-for="(v,k) in configurationData.payment_method_config"
                                :key="k"
                                :label="v"
                                :value="k"
                        ></el-option>
                    </el-select>
                </div>
                <div class="main-selectBox">
                    <el-select v-model="ajaxDataSearch.order_status" clearable placeholder="充值状态">
                        <el-option
                                v-for="(item,k) in configurationData.order_status_options"
                                :key="k"
                                :label="item"
                                :value="k"
                        ></el-option>
                    </el-select>
                </div>
                <div class="main-inputBox daterange" style="width: 255px !important">
                    <el-date-picker
                            v-model="ajaxDataSearch.timeRange"
                            @change="formatTimes"
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            type="daterange"
                            clearable
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    ></el-date-picker>
                </div>
                <div class="main-selectBox">
                    <div class="main-button" @click="enterSearch()">搜索</div>
                    <div class="main-button" @click="reset()">重置</div>
                </div>
            </div>
            <!-- 列表 start -->
            <div v-loading="loading">
                <el-table :data="dataList" border style="width: 100%">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column prop="order_no" label="交易流水号"></el-table-column>
                    <el-table-column prop="pay_amount_usd" label="充值金额(美元)"></el-table-column>
                    <el-table-column prop label="充值状态">
                        <template slot-scope="scope">
              <span v-show="scope.row.pay_method == 3"
                    :class="scope.row.orderStatus | statusClassFilter"
              >{{configurationData.offline_order_status[scope.row.order_status]}}</span>
                            <span v-show="scope.row.pay_method != 3"
                                  :class="scope.row.orderStatus | statusClassFilter"
                            >{{configurationData.order_status[scope.row.order_status]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop label="充值渠道">
                        <template slot-scope="scope">{{configurationData.payment_method_config[scope.row.pay_method]}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="client_company_name" label="客户名称"></el-table-column>
                    <el-table-column prop="created_at" label="创建时间"></el-table-column>
                    <el-table-column prop label="操作">
                        <template slot-scope="scope">
              <span
                      v-show="common.permFilter(`${common.java}recharge/details`)"
                      class="main-tableToInfo"
                      @click="gotoDetail(scope.row,2)"
              >详情</span>&nbsp;
                            <!-- 线下充值并且是待审核状态显示审批按钮-->
                            <span v-show="scope.row.pay_method==3 && scope.row.order_status==1"
                                    class="main-tableToInfo"
                                    @click="gotoDetail(scope.row,3)"
                            >审批</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination-vue
                        @currentSizeChange="sizeInit"
                        @currentChange="getList"
                        :pageSize="pageSize"
                        :total="total"
                        :firstPage="firstPage"
                />
            </div>
            <!-- 列表 end -->
        </div>
        <!-- 详情-->
        <div v-if="showFlag==2 || showFlag==3">
            <div class="detail_header">
                <el-button size="small" icon="el-icon-back" @click="showFlag = 1">返回</el-button>
                <span>余额充值详情</span>
            </div>
            <div style="background:#fff;padding:24px;margin-bottom:24px;">
                <p class="main-title">
                    基本信息</p>
                <div class="infoBox">
                    <el-row class="elrow_item" :span="8">
                        <el-col>
                            <div class="label_">交易流水号：</div>
                            <div class="value_">{{detailInfo.payment_no}}</div>
                        </el-col>
                        <el-col>
                            <div class="label_">充值金额：</div>
                            <div class="value_">{{detailInfo.pay_amount_usd}}</div>
                        </el-col>
                        <el-col>
                            <div class="label_">汇款人：</div>
                            <div class="value_">{{detailInfo.pay_user?detailInfo.pay_user:"-"}}</div>
                        </el-col>
                        <el-col>
                            <div class="label_">销售负责人：</div>
                            <div class="value_">
                                <div v-for="(item,k) in detailInfo.sale"
                                     :key="k"
                                >{{item.user_name}}/{{item.phone}}
                                </div>
                            </div>
                        </el-col>
                        <el-col v-show="detailInfo.fail_reason">
                            <div class="label_ red">审批结果：</div>
                            <div class="value_ red">{{detailInfo.fail_reason}}</div>
                        </el-col>


                    </el-row>
                    <el-row class="elrow_item" :span="7">
                        <el-col>
                            <div class="label_">充值渠道：</div>
                            <div class="value_">{{configurationData.payment_method_config[detailInfo.pay_method]}}</div>
                        </el-col>
                        <el-col>
                            <div class="label_">充值状态：</div>
                            <div v-show="detailInfo.pay_method !=3" class="value_">{{
                                configurationData.order_status[detailInfo.order_status]}}
                            </div>
                            <div v-show="detailInfo.pay_method ==3" class="value_">{{
                                configurationData.offline_order_status[detailInfo.order_status]}}
                            </div>
                        </el-col>
                        <el-col>
                            <div class="label_">AM负责人：</div>
                            <div class="value_">
                                <div v-for="(item,k) in detailInfo.am"
                                     :key="k"
                                >{{item.user_name}}/{{item.phone}}
                                </div>
                            </div>
                        </el-col>

                    </el-row>
                    <el-row class="elrow_item" :span="9">

                        <el-col>
                            <div class="label_">创建时间：</div>
                            <div class="value_">{{detailInfo.created_at}}</div>
                        </el-col>
                        <el-col>
                            <div class="label_">客户名称：</div>
                            <div class="value_">{{detailInfo.client_company_name}}</div>
                        </el-col>
                        <el-col>
                            <div class="label_">交易截图：</div>
                            <div class="value_">
                                <img class="pay-bill-img" v-if="detailInfo.pay_bill_img" @click="viewImgDialogOpen = true"
                                     :src="detailInfo.pay_bill_img" alt="">
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!--  详情-->
            <div v-show="showFlag==2" style="background: #fff; padding: 24px; margin-bottom: 24px">
                <p class="main-title withLinkBtn">关键时间信息</p>
                <div class="info-row flex-box flex-space-between">
                    <el-table :data="detailInfo.critical">
                        <el-table-column label="时间" prop="critical_time"></el-table-column>
                        <el-table-column label="操作" prop="operation"></el-table-column>
                        <el-table-column label="说明" prop="explain"></el-table-column>
                        <el-table-column label="操作人">
                            <template slot-scope="scope">
                                {{ scope.row.operator ? scope.row.operator : "-" }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- 审批-->
            <div v-show="showFlag==3" style="background:#fff;padding:24px;margin-bottom:24px;">
                <p class="main-title">审批</p>
                <div class="info-row info-row-self">
                    <el-form size="small" label-width="130px">
                        <el-form-item class="width300" label="审批状态：">
                            <el-select
                                    v-model="examineDatas.orderStatus"
                                    clearable
                                    placeholder="请选择审批状态"
                                    @change="dealSelect"
                            >
                                <el-option
                                        v-for="(item,index) in examineStatus"
                                        :key="index"
                                        :label="item"
                                        :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="width300" label="备注：">
                            <el-input
                                    type="textarea"
                                    :rows="3"
                                    placeholder="请输入备注"
                                    maxlength="50"
                                    v-model="examineDatas.fail_reason"
                            >
                            </el-input>
                        </el-form-item>
                        <el-button class="submit" type="primary" @click="examine">确认</el-button>
                    </el-form>
                </div>
            </div>
        </div>
        <el-dialog class="view-img" title="交易截图" :visible.sync="viewImgDialogOpen">
            <img :src="payBillImg" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import paginationVue from "@/components/paginationVue.vue";
    import axios from "axios";
    import store from "@/store/index.js";

    export default {
        name: "balanceRecharge",
        components: {
            paginationVue
        },
        data() {
            return {
                dataList: [],
                detailInfo: {},
                pageSize: 15,
                total: 100,
                showFlag: 1,
                loading: true,
                firstPage: 1,
                payBillImg: "",
                timer: "",
                viewImgDialogOpen: false,
                configurationData: {
                    order_status_options: {},
                    order_status: {},
                    offline_order_status: {},
                    payment_method_config: {}
                },
                ajaxDataSearch: {
                    client_company_name: "", //客户公司名称
                    pay_method: "", //支付方式:1 支付宝,2 微信,3 线下转账
                    start_date: "",
                    end_date: "",
                    order_status: "", //订单状态:1 待审批,2 充值中,3 已充值,4 充值失败(拒绝充值),5 交易失败(API返回失败)
                    page: 1, //
                    page_num: 15,
                    timeRange: ""
                },
                //审批
                examineDatas: {
                    order_no: "",
                    orderStatus: "",
                    fail_reason: ""
                },
                //审批状态
                examineStatus: {
                    2: "审批通过",
                    3: "审批失败"
                },
            }
        },
        created() {
            this.getList();
            this.getFilterData();
        },
        methods: {
            sizeInit(size) {
                this.pageSize = size ? size : 15;
                this.getList(1);
            },
            //获取列表数据
            getList(page) {
                this.loading = true;
                this.firstPage = page;
                clearTimeout(this.timer);
                let obj = this.ajaxDataSearch;
                obj.page = page ? page : 1;
                obj.page_num = this.pageSize;
                axios.defaults.headers.common["language"] = store.state.lang;
                axios.defaults.headers.common["token"] = JSON.parse(
                    localStorage.getItem("user_info")
                ).token;
                this.axios("get", `${this.common.php}v1/rechargeRecords/lists`, obj).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        this.dataList = res.data.data;
                        this.total = res.data.total;
                        this.pageSize = res.data.pageSize;
                    } else if (res.code == 20001) {
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: "warning"});
                    }
                });
            },
            enterSearch() {
                this.firstPage = 1;
                this.getList();
            },
            formatTimes(event) {

                if (this.ajaxDataSearch.timeRange) {
                    this.ajaxDataSearch.start_date =
                        this.ajaxDataSearch.timeRange[0];
                    this.ajaxDataSearch.end_date =
                        this.ajaxDataSearch.timeRange[1];
                } else {
                    this.ajaxDataSearch.end_date = "";
                    this.ajaxDataSearch.end_date = "";
                }
            },
            reset() {
                this.ajaxDataSearch = {
                    client_company_name: "", //客户公司名称
                    pay_method: "", //支付方式:1 支付宝,2 微信,3 线下转账
                    start_date: "",
                    end_date: "",
                    order_status: "", //订单状态:1 待审批,2 充值中,3 已充值,4 充值失败(拒绝充值),5 交易失败(API返回失败)
                    page: 1, //
                    page_num: 15
                };
                this.enterSearch();
            },
            getFilterData() {
                axios.defaults.headers.common["language"] = store.state.lang;
                axios.defaults.headers.common["token"] = JSON.parse(
                    localStorage.getItem("user_info")
                ).token;
                this.axios("POST", `${this.common.php}v1/config/datas`, {}).then(res => {
                    if (res.code == 1) {
                        this.configurationData = res.data;
                        this.getList();
                        this.timer = setTimeout(this.addStatistics, 5000);
                    } else if (res.code == 2) {
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: "warning"});
                    }
                });
            },
            //获取详情数据
            gotoDetail(obj, showFlagVal) {
                this.detailInfo = {};
                this.payBillImg="";
                this.examineDatas= {
                    order_no: "",
                    orderStatus: "",
                    fail_reason: ""
                };
                this.examineDatas.order_no = obj.order_no;
                this.showFlag = showFlagVal;
                var req_api = "v1/rechargeRecords/getRechargeRecords";
                this.axios("GET", `${this.common.php}${req_api}`, {order_no: obj.order_no}).then(res => {

                    if (res.code == 1) {
                        this.detailInfo = res.data;
                        this.payBillImg = this.detailInfo.pay_bill_img ? this.detailInfo.pay_bill_img : "";
                        if (showFlagVal == 3) {
                            this.examineDatas.fail_reason = res.data.fail_reason;
                            this.examineDatas.orderStatus = res.data.order_status;
                        }
                    } else if (res.code == 20001) {
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: "warning"});
                    }
                });
            },
            //处理下拉框数据 审批状态
            dealSelect(selVal) {
                this.examineDatas.orderStatus = selVal;
            },
            //审批提交
            examine() {
                if (!this.examineDatas.order_no) {
                    this.$message({message: "请选择审批状态", type: "warning"});
                    return false;
                }
                if (this.examineDatas.order_no == 3 && !this.examineDatas.fail_reason) {
                    this.$message({message: "请填写充值失败的原因", type: "warning"});
                    return false;
                }
                this.axios("POST", `${this.common.php}v1/rechargeRecords/gexamineRechargeRecords`, {
                    order_no: this.examineDatas.order_no,
                    order_status: Number(this.examineDatas.orderStatus),
                    fail_reason: this.examineDatas.fail_reason
                }).then(res => {
                    // console.log(res.data);
                    if (res.code == 1) {
                        this.common.statisticsAdd("充值申请审批", "2");
                        this.showFlag = 1;
                    } else if (res.code == 20001) {
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: "warning"});
                    }

                    this.init();
                });
            },
        },
        filters: {
            statusClassFilter(value) {
                //申请状态:1 待审核,2 通过,3 驳回 5已开户 6平台审核被拒
                let m = "";
                switch (value) {
                    case 1:
                        m = "shenpi-daishenhe";
                        break;
                    case 2:
                        m = "shenpi-yikaihu";
                        break;
                    case 3:
                        m = "shenpi-tongguo";
                        break;
                    case 4:
                        m = "shenpi-bohui";
                    case 5:
                        m = "shenpi-yiguoqi";
                    default:
                        break;
                }
                return m;
            },
            examineFilter(val) {
                // 审批状态:1 待审批,2 充值中,3 已充值,4 充值失败(拒绝充值),5 交易失败(操作失败或API返回失败)
                let text = "";
                switch (val) {
                    case 1:
                        text = "待审核";
                        break;
                    case 2:
                        text = "充值中";
                        break;
                    case 3:
                        text = "已充值";
                        break;
                    case 4:
                        text = "充值失败(拒绝充值)";
                        break;
                    case 5:
                        text = "交易失败(操作失败或API返回失败)";
                        break;
                    default:
                        break;
                }
                return text;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .infoBox {
        display: flex;
    }

    .el-col {
        margin-bottom: 24px;
    }

    .elrow_item .value_ {
        vertical-align: text-top;
        .pay-bill-img{
            width:150px;
            height:150px;
            cursor: pointer;
        }
    }

    .width300 {
        width: 450px;
    }

    .submit {
        margin-left: 130px;
    }
    /deep/.elrow_item .label_.red{
        color: red;
    }

    /deep/ .view-img {
        .el-dialog {
            height: 80%;
            .el-dialog__header{
                border-bottom: 1px solid #cccccc;
                .el-dialog__title{
                    font-weight: 500;
                }
            }
            .el-dialog__body {
                width: 100%;
                height: 100%;
                padding:30px;
                box-sizing: border-box;

                img {
                    max-height: 100%;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
</style>