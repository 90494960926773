<template>
  <div class="pagination-wrap">
    <el-pagination
      :hide-on-single-page="!isSevenPagin"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="firstPage"
      :page-sizes="getSizes()"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400">
    </el-pagination> -->
  </div>
</template>

<script>
export default {
  name: "paginationVue",
  props: {
    isSevenPagin: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 100,
    },
    pageSize: {
      type: Number,
      default: 15,
    },
    firstPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage4: 4,
    };
  },
  methods: {
    getSizes() {
      if (this.isSevenPagin) {
        return [7, 15, 30, 50, 100, 300];
      } else {
        return [15, 30, 50, 100, 300];
      }
    },
    handleCurrentChange(pageNum) {
      this.$forceUpdate();
      console.log(pageNum);
      this.$emit("currentChange", pageNum);
    },
    handleSizeChange(val) {
      console.log(val);
      this.$emit("currentSizeChange", val);
    },
  },
};
</script>
