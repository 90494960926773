<template>
  <div class="main-div" style="background: none; padding: 0px;">
    <div v-show="showFlag==1" style="background:#fff;padding:24px;">
      <p class="main-title" style="margin-bottom: 32px">广告账户充值记录</p>
      <div>
        <div class="main-inputBox" style="width:440px!important;">
          <el-input v-model="ajaxDataSearch.accounts" placeholder="请输入广告账户ID或名称，支持通过英文“,”和空格分隔输入多个" clearable></el-input>
        </div>
        <div class="main-selectBox">
          <el-select v-model="ajaxDataSearch.orderStatus" clearable placeholder="全部状态">
            <el-option
              v-for="(v,k) in configurationData.media_order_status"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select v-model="ajaxDataSearch.platform" clearable placeholder="全部平台">
            <el-option
              v-for="(item,k) in configurationData.network_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox" style="width:auto!important;display:inline-flex;">
          <el-select style="width:120px;" placeholder="客户名称" v-model="searchType" @change="searchTypeChange()">
            <el-option :value="1" label="开户公司">开户公司</el-option>
            <el-option :value="2" label="客户公司">客户公司</el-option>
          </el-select>
          <el-input
            v-if="searchType == 1"
            style="width:200px;font-size:14px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.accountCompanyName"
          >
          </el-input>
          <el-input
            v-if="searchType == 2"
            style="width:200px;font-size:14px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.clientCompanyName"
          >
          </el-input>
        </div>
        <!-- <div class="main-selectBox">
          <el-select v-model="ajaxDataSearch.rechargeType" clearable placeholder="充值类型">
            <el-option
              v-for="(v,k) in configurationData.recharge_type_config"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select v-model="ajaxDataSearch.paymentMethod" clearable placeholder="请选择支付方式">
            <el-option
              v-for="(v,k) in configurationData.payment_method_config"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div> -->
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">搜索</div>
          <div class="main-button" @click="reset()">重置</div>
        </div>
      </div>
      <div style="display:flex;align-items:center;justify-content:space-between;">
        <div class="main-btnBox m-r-16">
          <!-- <div class="main-button" @click="relateCustomer(1)">关联客户</div>
          <div class="main-button" @click="bindBM()">绑定BM</div>
          <div class="main-button" @click="unbindBM()">解绑BM</div>
          <div class="main-button" @click="bindPixel()">绑定Pixel</div>
          <div class="main-button" @click="unbindPixel()">解绑Pixel</div>-->
        </div>
        <el-button type="text" @click="editTableColumns('rechargeMedia')">自定义列表项</el-button>
      </div>
      <!-- 列表 start -->
      <div v-loading="loading">
        <el-table :data="dataList" border style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column
            show-overflow-tooltip
            prop="accountName"
            label="广告账户名称"
            min-width="200"
            fixed
          ></el-table-column>
          <el-table-column prop="accountId" label="广告账户ID" width="180" fixed></el-table-column>
          <el-table-column prop="rechargeAmount" label="充值金额(美元)" width="140" fixed></el-table-column>
          <el-table-column prop label="订单状态" width="240">
            <template slot-scope="scope">
              <span
                :class="scope.row.orderStatus | statusClassFilter"
              >{{configurationData.media_order_status[scope.row.orderStatus]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="平台" min-width="120" v-if="rechargeTableColumnVals.platform">
            <template slot-scope="scope">{{configurationData.platform_array[scope.row.platform]}}</template>
          </el-table-column>
          <el-table-column prop="accountCompanyName" label="开户公司" width="120" v-if="rechargeTableColumnVals.accountCompanyName"></el-table-column>
          <el-table-column show-overflow-tooltip prop="clientCompanyName" label="归属客户" width="220" v-if="rechargeTableColumnVals.clientCompanyName"></el-table-column>
          <el-table-column prop="createdAt" label="申请时间" width="200" v-if="rechargeTableColumnVals.applicationTime"></el-table-column>
          <el-table-column prop="updatedAt" label="最后更新时间" width="200" v-if="rechargeTableColumnVals.updatedAt"></el-table-column>
          <el-table-column prop="operator" label="最后操作人" width="130" v-if="rechargeTableColumnVals.updater"></el-table-column>
          <el-table-column prop label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <span
                v-show="common.permFilter(`${common.java}recharge/details`)"
                class="main-tableToInfo"
                @click="examineDetail(scope.row,2)"
              >详情</span>&nbsp;
              <span
                v-if="scope.row.orderStatus == 5"
                class="main-tableToInfo"
                @click="resubmit(scope.row)"
              >重新提交</span>&nbsp;
              <span
                v-show="scope.row.orderStatus==1 && common.permFilter(`${common.java}recharge/details`)"
                class="main-tableToInfo"
                @click="examineDetail(scope.row,3)"
              >审批</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
      <!-- 列表 end -->
    </div>
    <el-dialog title="自定义列表项" :visible.sync="editTableColumnDialog">
      <el-table size="small" :data="mediaTableColumns" style="width:96%;margin:0 auto;">
        <el-table-column label="列表项">
          <template slot-scope="scope">{{scope.row.name}}</template>
        </el-table-column>
        <el-table-column label="是否展示">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">不可修改</span>
            <el-switch v-else v-model="scope.row.value"></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editTableColumnDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="hideColumns()">确定</el-button>
      </div>
    </el-dialog>
    <div v-show="showFlag==2 || showFlag==3">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1">返回</el-button>
        <span>授信充值申请审批</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">申请单信息</p>
        <div class="infoBox">
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="label_">广告账户名称：</div>
              <div class="value_">{{detailList.accountName}}</div>
            </el-col>
            <el-col :span="7">
              <div class="label_">广告账户ID：</div>
              <div class="value_">{{detailList.accountId}}</div>
            </el-col>
            <el-col :span="9">
              <div class="label_">媒体平台：</div>
              <div class="value_">{{configurationData.platform_array[detailList.platform]}}</div>
            </el-col>
            <!-- 
            <el-col :span="7">
              <div class="label_">归属客户：</div>
              <div class="value_">{{detailList.clientCompanyName}}</div>
            </el-col>
            <el-col :span="9">
              <div class="label_">开户公司：</div>
              <div class="value_">{{detailList.accountCompanyName}}</div>
            </el-col> -->
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="label_">状态：</div>
              <div class="value_">{{configurationData.media_order_status[detailList.orderStatus]}}</div>
            </el-col>
            <el-col :span="7">
              <div class="label_">开户公司：</div>
              <div class="value_">{{detailList.accountCompanyName}}</div>
            </el-col>
            <el-col :span="9">
              <div class="label_">归属客户：</div>
              <div class="value_">{{detailList.clientCompanyName}}</div>
            </el-col>
          </el-row>
          <el-row class="elrow_item" type="flex" justify="space-between">
            <el-col :span="8">
              <div class="label_">充值金额(美元)：</div>
              <div class="value_">{{detailList.rechargeAmount}}</div>
            </el-col>
            <el-col :span="7">
              <div class="label_">申请时间：</div>
              <div class="value_">{{detailList.createdAt}}</div>
            </el-col>
            <el-col :span="9" >
              <div class="label_">申请人：</div>
              <div class="value_">{{'-'}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <p class="main-title">审批</p>      
        <div class="infoBox">
          <div class="info-row info-row-self" v-show="showFlag==3">
            <el-form size="small" label-width="130px">
              <el-form-item label="审批状态："> 
                <el-select
                  v-model="examineDatas.examineStatus"
                  clearable
                  style="width:300px;"
                  placeholder="请选择审批状态"
                  @change="dealSelect"
                >
                  <el-option
                    v-for="(item,index) in examineStatus"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input
                  v-model="examineDatas.examineRemarks"
                  clearable
                  placeholder="请输入备注"
                  style="width:300px;"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="info-row">
            <div class="info-text calc-2w">
              <div v-show="showFlag==3" class="main-button" @click="examine()">确认</div>
            </div>
          </div>
        </div>  
      </div>

      <!-- <div style="background:#fff;padding:24px;margin-bottom:24px;" v-show="showFlag==2">
        <p class="main-title">关键时间信息</p>      
        <section class="info-cell examin-history" style="width:100%">
          <div
            style="display:block"
            class="history-wrap"
            v-if="detailList.examineRecords && detailList.examineRecords.length>0"
          >
            <p class="history" v-for="item in detailList.examineRecords">
              <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
              <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
              <i>{{item.examineRemarks}}</i>
            </p>
          </div>
        </section>
      </div> -->
      
    </div>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      searchType:1,
      editTableColumnDialog: false,
      rechargeTableColumnVals:{
        accountName:true,
        accountId:true,
        accountBalance:true,
        orderStatus:true,
        platform:true,
        accountCompanyName:true,
        clientCompanyName:true,
        applicationTime:true,
        updatedAt:true,
        updater:true,
      },
      mediaTableColumns: [
        {
          id:"accountName",
          name: "广告账户名称",
          edit: false,
          value: false
        },
        {
          id:"accountId",
          name: "广告账户ID",
          edit: false,
          value: false
        },
        {
          id:"accountBalance",
          name: "充值金额",
          edit: false,
          value: false
        },
        {
          id:"orderStatus",
          name: "状态",
          edit: false,
          value: false
        },
        {
          id:"platform",
          name: "平台",
          edit: true,
          value: true
        },
        {
          id:"accountCompanyName",
          name: "开户公司",
          edit: true,
          value: true
        },
        {
          id:"clientCompanyName",
          name: "归属客户",
          edit: true,
          value: true
        },
        {
          id:"applicationTime",
          name: "申请时间",
          edit: true,
          value: true
        },
        {
          id:"updatedAt",
          name: "最后更新时间",
          edit: true,
          value: true
        },
        {
          id:"updater",
          name: "最后操作人",
          edit: true,
          value: true
        }
      ],
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      pageSize: 15,
      total: 100,
      showFlag: 1,
      ajaxDataSearch: {
        clientCompanyName: "", //客户公司名称
        accountCompanyName: "", //开户公司名称
        accountId: "", //账户ID
        platform: "", //平台:1 facebook,2 twitter,3 google,5 tiktok
        orderStatus: "", //订单状态:1 待审批,2 充值中,3 已充值,4 充值失败(拒绝充值),5 交易失败(API返回失败)
        rechargeType: "", //	充值类型:1 付款充值,2 授信充值
        paymentMethod: "" //支付方式:1 支付宝,2 微信,3 银行卡
      },
      dataList: [],
      configurationData: {
        platform_array: {},
        media_order_status: {},
        recharge_type_config: {},
        payment_method_config: {}
      },
      //详情页面数据
      detailList: {},
      examineDatas: {
        id: "",
        orderStatus: "",
        examineRemarks: ""
      },
      //审批状态
      examineStatus: {
        3: "审批通过",
        4: "审批失败"
      }
    };
  },
  created() {
    if (this.$route.query.postdata) {
      this.ajaxDataSearch = JSON.parse(this.$route.query.postdata)
    }
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      if (res.code == 1) {
        this.configurationData = res.data;
        this.init();
        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });
    if (localStorage.getItem("rechargeTableColumnVals")) {
      this.rechargeTableColumnVals = JSON.parse(localStorage.getItem("rechargeTableColumnVals"))
      this.mediaTableColumns = this.mediaTableColumns.map(item => {
        item.value = this.rechargeTableColumnVals[item.id]
        return item
      });
    }

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };

    
  },
  filters: {
    statusClassFilter(value) {
      //申请状态:1 待审核,2 通过,3 驳回 5已开户 6平台审核被拒
      let m = "";
      switch (value) {
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-yikaihu";
          break;
        case 3:
          m = "shenpi-tongguo";
          break;
        case 4:
          m = "shenpi-bohui";
        case 5:
          m = "shenpi-yiguoqi";
        default:
          break;
      }
      return m;
    },
    examineFilter(val) {
      // 审批状态:1 待审批,2 充值中,3 已充值,4 充值失败(拒绝充值),5 交易失败(操作失败或API返回失败)
      let text = "";
      switch (val) {
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "充值中";
          break;
        case 3:
          text = "已充值";
          break;
        case 4:
          text = "充值失败(拒绝充值)";
          break;
        case 5:
          text = "交易失败(操作失败或API返回失败)";
          break;
        default:
          break;
      }
      return text;
    }
  },
  methods: {
    resubmit(row){
      console.log(row);
      this.axios(
        "get",
        `${this.$javaBaseURL}accountMedia/list`,
        {
          accounts: row.accountId,
        }
      ).then(res => {
        if (res.code == 1) {
          this.$router.push({
            path: "/accRecharge",
            query: {
              from: "rechargeMedia",
              currentAccount: JSON.stringify(res.data.list[0]),
              mediapostdata: JSON.stringify(this.ajaxDataSearch)
            }
          })
          // if (row.operateType == 1) { //绑定BM
          //   this.bindBM(res.data.list)
          // }else if (row.operateType == 2) {
          //   this.unbindBM(res.data.list)
          // }else if (row.operateType == 3) {
          //   this.bindPixel(res.data.list)
          // }else if (row.operateType == 4) {
          //   this.unbindPixel(res.data.list)
          // }else if (row.operateType == 5) {
          //   this.$router.push({
          //     path: "/accZero",
          //     query: {
          //       from: "media",
          //       currentAccount: JSON.stringify(res.data.list[0]),
          //       mediapostdata: JSON.stringify(this.ajaxDataSearch)
          //     }
          //   })
          // }else if (row.operateType == 6) {
          //   this.showFlag = 2;
          //   this.currentRow = res.data.list[0]
          // }
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    searchTypeChange(){
      if (this.searchType == 1) {
        this.ajaxDataSearch.clientCompanyName = ""
      }else if(this.searchType == 2){
        this.ajaxDataSearch.accountCompanyName = ""
      }
    },
    hideColumns(){
      this.mediaTableColumns.forEach(ele => {
        this.rechargeTableColumnVals[ele.id] = ele.value
      });
      console.log(this.rechargeTableColumnVals);
      localStorage.setItem('rechargeTableColumnVals',JSON.stringify(this.rechargeTableColumnVals))
      this.editTableColumnDialog = false;
    },
    editTableColumns(viewpage) {
      this.editTableColumnDialog = true;
    },
    sizeInit(size) {
      this.pageSize = size ? size : 15;
      this.init(1);
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      let obj = this.ajaxDataSearch;
      obj.pageNum = page ? page : 1;
      obj.pageSize = this.pageSize;
      axios.defaults.headers.common["language"] = store.state.lang;
      axios.defaults.headers.common["token"] = JSON.parse(
        localStorage.getItem("user_info")
      ).token;
      this.axios("get", `${this.$javaBaseURL}recharge/list`, obj).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.dataList = res.data.list;
          this.total = res.data.total;
          this.pageSize = res.data.pageSize;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      this.init();
    },
    reset() {
      this.ajaxDataSearch = {
        clientCompanyName: "", //客户公司名称
        accountCompanyName: "", //开户公司名称
        accountId: "", //账户ID
        platform: "", //平台:1 facebook,2 twitter,3 google,5 tiktok
        orderStatus: "", //订单状态:1 待审批,2 充值中,3 已充值,4 充值失败(拒绝充值),5 交易失败(API返回失败)
        rechargeType: "", //	充值类型:1 付款充值,2 授信充值
        paymentMethod: "" //支付方式:1 支付宝,2 微信,3 银行卡
      };
      this.enterSearch();
    },

    //审批提交
    examine() {
      if (!this.examineDatas.examineStatus) {
        this.$message({ message: "请选择审批状态", type: "warning" });
        return false;
      }
      // var req_api = `recharge/examine?id=${this.examineDatas.id}&orderStatus=${this.examineDatas.orderStatus}&examineRemarks=${this.examineDatas.examineRemarks}`;
      this.axios("POST", `${this.$javaBaseURL}recharge/examine`, {
        id: this.examineDatas.id,
        orderStatus: this.examineDatas.orderStatus,
        examineRemarks: this.examineDatas.examineRemarks
      }).then(res => {
        // console.log(res.data);
        if (res.code == 1) {
          this.common.statisticsAdd("充值申请审批", "2");
          this.showFlag = 1;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }

        this.init();
      });
    },
    //处理下拉框数据 审批状态
    dealSelect(selVal) {
      this.examineDatas.orderStatus = selVal;
    },
    //获取 详情和审核页面列表数据
    async examineDetail(obj, showFlagVal) {
      //检查 开户公司是否在黑名单
      if (showFlagVal == 3) {
        let ress = await this.checkBlackList(obj);

        if (ress.code == 1) {
          if (ress.data == 1) {
            this.$message({
              message: "该用户在有效黑名单中，暂时不能审批此申请",
              type: "warning"
            });
            return false;
          }
        } else {
          this.$message({
            showClose: true,
            message: ress.msg,
            type: "error"
          });
          return false;
        }
      }
      this.showFlag = showFlagVal;

      // console.log(examineId);
      this.examineDatas.id = obj.id;

      var req_api = "recharge/details";
      var req_data = {
        id: this.examineDatas.id
      };
      this.axios("GET", `${this.$javaBaseURL}${req_api}`, req_data).then(res => {
        console.log(res.data);

        if (res.code == 1) {
          this.detailList = res.data;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
        return false;
      });
    },
    checkBlackList(obj) {
      //验证账号开户公司是否在黑名单中
      return new Promise(resolve => {
        var req_api = `accountCompany/selectBlacklistByName?accountCompanyName=${obj.accountCompanyName}`;
        this.axios("GET", `${this.$javaBaseURL}${req_api}`, {})
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            this.$message({
              showClose: true,
              message: err,
              type: "error"
            });
            console.log(err);
            reject(err);
          });
      });
    },

    //频次统计
    addStatistics() {
      this.value++;
      this.common.statisticsAdd("广告账户充值记录列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
//   case 1:
//                         m = 'status-daishenhe'
//                         break;
//                     case 2:
//                         m = 'status-chongzhizhong'
//                         break;
//                     case 3:
//                         m = 'status-yichongzhi'
//                         break;
//                     case 5:
//                         m='status-chongzhishibai'
//                     case 6:
//                         m='status-jiaoyishibai'
</style>
<style lang="scss">
.info-row-self .el-select {
  width: auto;
}

.info-row-self .el-select > .el-input {
  display: block;
  height: 32px;
  line-height: 32px;
}

.info-row-self .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.info-row-self .el-input__suffix {
  top: 4px;
}
</style>