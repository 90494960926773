<template>
  <div class="main-div user-list" style="background:none;padding:0;">
    <div v-show="showFlag==1" style="background:#fff;padding:24px;">
      <p class="main-title">用户列表</p>
      <div>
        <div class="main-inputBox">
          <el-input v-model="ajaxDataSearch.user_name" placeholder="请输入用户名称" clearable></el-input>
        </div>
        <div class="main-inputBox">
          <el-input v-model="ajaxDataSearch.real_name" placeholder="请输入真实姓名" clearable></el-input>
        </div>
        <div class="main-selectBox">
          <el-select v-model="ajaxDataSearch.role_id" clearable placeholder="请选择角色">
            <el-option
              v-for="(item,k) in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="main-inputBox daterange" style="width: 255px!important;">
          <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="更新开始日期"
            clearable
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
        <div class="main-selectBox">
          <div class="main-button" @click="enterSearch()">查询</div>
          <div class="main-button main-button-first" @click="resetList()">重置</div>
        </div>
      </div>
      <div v-loading="loading">
        <el-table :data="list" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop="username" label="用户名" width></el-table-column>
          <el-table-column prop="role_name" label="角色" width></el-table-column>
          <el-table-column prop="name" label="真实姓名" width></el-table-column>
          <el-table-column prop label="邮箱地址" width>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.email">{{scope.row.email}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号" width></el-table-column>
          <el-table-column prop="dept_name" label="所属部门" width></el-table-column>
          <el-table-column prop="station_name" label="岗位" width></el-table-column>
          <el-table-column prop label="操作" width>
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                v-show="common.permFilter(`${common.php}v1/user/update`)"
                @click="editPop=true;getInfo(scope.row)"
              >编辑</span>
              <span class="main-tableToInfo" @click="getRelatedCustomerList(scope.row)">客户信息</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag==2">
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag = 1">返回</el-button>
        <span>客户信息</span>
      </div>
      <div style="background:#fff;padding:24px;">
        <div>
          <div class="main-inputBox">
            <el-input
              v-model="getRelatedCustomerListPostData.client_company_name"
              placeholder="请输入客户名称"
              clearable
            ></el-input>
          </div>
          <div class="main-selectBox">
            <div class="main-button" @click="getRelatedCustomerListHandle()">查询</div>
            <div class="main-button main-button-first" @click="resetRelatedCustomer()">重置</div>
          </div>
        </div>
        <div class="main-btnBox m-r-16">
          <div class="main-button" @click="addCustomers()">添加客户</div>
          <div class="main-button main-button-first" @click="batchDelete()">批量删除</div>
        </div>
        <el-table :data="relatedCustomerList" ref="multipleTable" style="width: 100%" @selection-change="handleSelectionChange">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="client_company_name" label="客户名称" width></el-table-column>
          <el-table-column prop="type_str" label="特殊角色" width></el-table-column>
          <el-table-column prop="created_at" label="添加时间" width></el-table-column>
          <el-table-column prop label="操作">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="delRelatedCustomerList(scope.row)">删除</span>
              <span class="main-tableToInfo" @click="editRelatedCustomer(scope.row)">编辑</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="getRelatedCustomerListHandlesizeInit"
          @currentChange="getRelatedCustomerListHandle"
          :pageSize="getRelatedCustomerListPostData.page_num"
          :total="getRelatedCustomerListPostData.total"
        />
      </div>
    </div>
    <div class="main-pop" v-show="showAddCustomerDia">
      <div class="mask"></div>
      <div class="center" style="width: 600px">
        <div class="head">
          <span>添加客户</span>
          <i class="gongzuotai gztguanbi" @click="hideAddCustomerDia()"></i>
        </div>
        <div class="body">
          <el-form size="small" label-position="right" label-width="110px">
            <el-form-item label="客户：">
              <el-select
                v-model="saveRelatedCom.recid"
                filterable
                clearable
                remote
                :remote-method="remoteMethod"
                placeholder="请选择客户"
                @focus="getAllCustomer()"
                @change="changeCustomer()"
                :disabled="!canChangeSelect"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.recid"
                  :label="item.client_company_name"
                  :value="item.recid"
                ></el-option>
                <div style="float: right;margin-right:10px;padding-bottom: 10px">
                  <el-pagination
                    @current-change="customersInit"
                    :current-page="projectPostData.page"
                    :page-size="projectPostData.page_num"
                    layout="prev, pager, next"
                    :total="projectPostData.total"
                  ></el-pagination>
                </div>
              </el-select>
              <p style="color:red;font-size: 12px;position: absolute;line-height: 26px;" v-if="showTips">客户已存在</p>
            </el-form-item>
            <el-form-item label="特殊角色：">
              <el-checkbox-group v-model="saveRelatedCom.am_sale">
                <el-checkbox :label="1">销售负责人</el-checkbox>
                <el-checkbox :label="2">AM负责人</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot">
          <button class="main-button l" @click="hideAddCustomerDia()">取消</button>
          <button class="main-button r" @click="saveAddCustomer()">确定</button>
        </div>
      </div>
    </div>
    <!-- 编辑用户 -->
    <div class="main-pop editPop" v-show="editPop">
      <div class="mask"></div>
      <div class="center" style="width:696px;max-height: 835px">
        <div class="head">
          <span>编辑用户</span>
          <i class="gongzuotai gztguanbi" @click="editPop=false"></i>
        </div>
        <div class="body" style="max-height: 550px;">
          <div class="w-50 inline top">
            <p class="info-title" style="line-height: 10px">真实姓名:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.name" disabled placeholder="系统生成"></el-input>
              </div>
            </div>
            <p class="info-title t-4w" style="line-height: 10px">用户名:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.username" disabled placeholder="系统生成"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">手机号码:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.phone" disabled placeholder></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">角色:</p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select v-model="editingProObj.role_id" placeholder="请选择角色">
                  <el-option
                    v-for="(item,k) in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="w-50 inline top">
            <p class="info-title" style="line-height: 10px">所属部门:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.dept_name" disabled placeholder="系统生成"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">岗位:</p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input :value="editingProObj.station_name" disabled placeholder></el-input>
              </div>
            </div>
            <p class="info-title t-5w" style="line-height: 10px">
              <i style="color:red;">*</i> 邮箱地址:
            </p>
            <br>
            <div>
              <div class="main-inputBox">
                <el-input v-model="editingProObj.email" placeholder="请输入邮箱地址"></el-input>
              </div>
            </div>
            <p class="info-title" style="line-height: 10px">数据范围:</p>
            <br>
            <div>
              <div class="main-selectBox" style="width: 290px">
                <el-select v-model="editingProObj.data_range" placeholder="请选择前端">
                  <el-option
                    v-for="item in optionsData_range"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <button class="main-button l" @click="editPop=false">取消</button>
          <button class="main-button r" @click="doEdit()">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      showTips:false,
      projectPostData:{
        page:1,
        page_num:15,
        total:0,
        client_company_name:""
      },
      customerList:[],
      saveRelatedCom: {
        recid: "",
        user_id: "",
        am_sale: []
      },
      showAddCustomerDia: false,
      getRelatedCustomerListPostData: {
        total: 0,
        page: 1,
        page_num: 15,
        user_id: "",
        client_company_name: ""
      },
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 0,
      date: ["", ""],
      ajaxDataSearch: {
        user_name: "", //	否	string	用户名
        real_name: "", //	否	string	真实姓名
        role_id: "", //	    否	string	角色id
        from_date: "", //	否	string	更新时间：开始时间 如：2020-04-09
        to_date: "" //	    否	string	更新时间：结束时间 如：2020-04-16
      },
      data_range: "",
      optionsData_range: [
        {
          value: 1,
          label: "仅自己"
        },
        {
          value: 2,
          label: "全部门"
        },
        {
          value: 3,
          label: "全公司"
        }
      ],
      list: [],
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {},
      editPop: false,
      editingProObj: {
        id: "",
        username: "",
        email: "",
        name: "",
        phone: "",
        dept_id: "",
        station: "",
        data_range: 2,
        modifytime: "",
        created_at: "",
        updated_at: "",
        role_id: "",
        station_name: "",
        role_name: "",
        dept_name: ""
      },
      roleList: [],
      selectedUserId: "",
      relatedCustomerList: [],
      selectedList:[],
      canChangeSelect:false
    };
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      if (res.code == 1) {
        this.configurationData = res.data;

        this.init();
        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    /*获取角色信息*/
    this.axios("GET", `${this.$phpBaseURL}v1/role/getList`, {
      status: 1,
      page_num: 10000
    }).then(res => {
      if (res.code == 1) {
        this.roleList = res.data.data;
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  methods: {
    editRelatedCustomer(row){
      console.log(row);
      this.saveRelatedCom = {
        recid: row.recid,
        user_id: row.relate_id,
        am_sale: row.type_arr
      }
      this.showAddCustomerDia = true;
      this.remoteMethod(row.client_company_name)
      this.canChangeSelect = false;
    },
    resetRelatedCustomer(){
      this.getRelatedCustomerListPostData = {
        total: 0,
        page: 1,
        page_num: 15,
        user_id: "",
        client_company_name: ""
      }
      this.getRelatedCustomerListHandle()
    },
    saveAddCustomer(){
      console.log(this.saveRelatedCom);
      this.axios("POST", `${this.$phpBaseURL}v1/clientRelatdAmSale/uptAmOrSale`,this.saveRelatedCom).then(res => {
        if (res.code == 1) {
          this.getRelatedCustomerListHandle(1)
          this.hideAddCustomerDia();
          this.$message({ message: "操作成功", type: "success" });
        }else{
          this.showTips = false;
        }
      });
    },
    hideAddCustomerDia(){
      this.saveRelatedCom.recid="";
      this.showTips = false;
      this.saveRelatedCom.am_sale = [];
      this.showAddCustomerDia = false;
    },
    changeCustomer(){
      console.log(this.saveRelatedCom.recid);
      this.axios("get", `${this.$phpBaseURL}v1/clientRelatdAmSale/checkClient`,{
        recid:this.saveRelatedCom.recid
      }).then(res => {
        if (res.code == 0) {
          this.showTips = true;
        }else{
          this.showTips = false;
        }
      });
    },
    getAllCustomer(){
      this.remoteMethod(this.projectPostData.client_company_name)
    },
    handleSelectionChange(val){
      this.selectedList = val;
    },
    remoteMethod(query){
      this.projectPostData.client_company_name = query;
      this.customersInit(1)
    },
    customersInit(page) {
      this.projectPostData.page = page?page:1;
      this.axios("get", `${this.$phpBaseURL}v1/client/lists`,this.projectPostData).then(res => {
        if (res.code == 1) {
          this.customerList = res.data.data;
          this.projectPostData.total = res.data.total;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    addCustomers() {
      this.canChangeSelect = true
      this.showAddCustomerDia = true;
    },
    delRelatedCustomerList(row){
      this.deleteCustomerHandle([{
        recid:row.recid,
        user_id:row.relate_id
      }])
    },
    batchDelete() {
      let ids = this.selectedList.map((item,index)=>{
        return {
          recid:item.recid,
          user_id:item.relate_id
        }
      })
      this.deleteCustomerHandle(ids)
    },
    deleteCustomerHandle(ids){
      this.axios("POST", `${this.$phpBaseURL}v1/clientRelatdAmSale/delAmOrSale`,{
        ids:ids
      }).then(res => {
        if (res.code == 1) {
          this.getRelatedCustomerListHandle(1)
          this.$message({ message: "删除成功", type: "success" });
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    getRelatedCustomerList(row) {
      this.showFlag = 2;
      this.selectedUserId = row.id;
      this.saveRelatedCom.user_id = this.selectedUserId
      this.getRelatedCustomerListPostData.user_id = row.id;
      this.getRelatedCustomerListHandle(1);
    },
    getRelatedCustomerListHandle(page) {
      this.getRelatedCustomerListPostData.page = page ? page : 1;
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/clientRelatdAmSale/lists`,
        this.getRelatedCustomerListPostData
      ).then(res => {
        if (res.code == 1) {
          this.relatedCustomerList = res.data.data;
          this.getRelatedCustomerListPostData.total = res.data.total;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    resetList() {
      this.ajaxDataSearch = {
        user_name: "", //	否	string	用户名
        real_name: "", //	否	string	真实姓名
        role_id: "", //	    否	string	角色id
        from_date: "", //	否	string	更新时间：开始时间 如：2020-04-09
        to_date: "" //	    否	string	更新时间：结束时间 如：2020-04-16
      };
      this.init(1);
    },
    getRelatedCustomerListHandlesizeInit(size) {
      this.getRelatedCustomerListPostData.page_num = size ? size : 15;
      this.getRelatedCustomerListHandle(1);
    },
    sizeInit(size) {
      this.ajaxDataSearch.page_num = size ? size : 15;
      this.init(1);
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;

      clearTimeout(this.timer);
      var startDate = "";
      var endDate = "";
      if (this.date) {
        startDate = this.date[0];
        endDate = this.date[1];
      }

      let obj = this.ajaxDataSearch;
      obj.page = page ? page : 1;
      obj.from_date = startDate;
      obj.to_date = endDate;
      this.axios("POST", `${this.$phpBaseURL}v1/user/lists`, obj).then(res => {
        this.loading = false;
        //                        console.log(res.data)
        if (res.code == 1) {
          this.list = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      this.init();
    },
    /*详情*/
    getInfo(obj) {
      this.editingProObj = JSON.parse(JSON.stringify(obj));
      this.editingProObj.role_id = obj.role_id;
      this.data_range = obj.data_range;
    },
    /*编辑*/
    doEdit() {
      if (!this.editingProObj.role_id) {
        this.$message({
          message: "请选择角色",
          type: "warning"
        });
        return false;
      }
      if (!this.editingProObj.email) {
        this.$message({
          message: "请输入邮箱地址",
          type: "warning"
        });
        return false;
      }
      if (this.editingProObj.data_range != this.data_range) {
        this.$confirm(" 设置用户数据范围后, 自动退出, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.axios(
              "POST",
              `${this.$phpBaseURL}v1/user/update`,
              this.editingProObj
            ).then(res => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: "保存成功!"
                });
                this.editPop = false;
                this.init();
              } else if (res.code == 2) {
                this.common.tokenExpired(res.msg);
              } else {
                this.$message({ message: res.msg, type: "warning" });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作"
            });
          });
      } else {
        // {
        //   id: this.editingProObj.id,
        //   role_id: this.editingProObj.role_id,
        //   data_range: this.editingProObj.data_range
        // }
        this.axios(
          "POST",
          `${this.$phpBaseURL}v1/user/update`,
          this.editingProObj
        ).then(res => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "保存成功!"
            });

            this.editPop = false;
            this.init();
          } else if (res.code == 2) {
            //token过期处理
            this.common.tokenExpired(res.msg);
          } else {
            this.$message({ message: res.msg, type: "warning" });
          }
        });
      }
    },
    logOut() {
      localStorage.setItem("user_info", "");
      this.$router.push({ path: "/login" });
    },
    //频次统计
    addStatistics() {
      this.common.statisticsAdd("用户列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editPop {
  .main-inputBox {
    width: 290px;
  }
}
</style>
<style>
.el-button.el-button--primary.el-button--small {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
  padding: 9px 15px !important;
  font-size: 12px !important;
}
</style>