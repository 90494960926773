<template>
    <div class="main-div">
        <div v-show="showFlag==1">
            <p class="main-title">平台返点比例表</p>
            <!-- 搜索项 -->
            <div>
                <div class="main-selectBox">
                    <el-select
                            v-model="ajaxDataSearch.platform"
                            @change="enterSearch"
                            clearable
                            placeholder="平台/渠道"
                    >
                        <el-option
                                v-for="(item,index) in configurationData.platform_name_array"
                                :key="index"
                                :label="item"
                                :value="index"
                        ></el-option>
                    </el-select>
                </div>

                <div class="main-inputBox daterange date-picker-input-width">
                    <el-date-picker
                            v-model="ajaxDataSearch.customer_created_at"
                            @change="enterSearch()"
                            value-format="yyyy-MM"
                            format="yyyy-MM"
                            type="monthrange"
                            start-placeholder="有效期开始日期"
                            clearable
                            end-placeholder="结束日期"
                            placeholder="创建时间"
                            :picker-options="pickerOptions"
                    >>
                    </el-date-picker>
                </div>
            </div>
            <!-- 搜索项 end-->

            <!--<div style="margin-bottom: 32px">-->
            <div class="main-btnBox">
                <div class="main-button" @click="showFlag=2">新增返点比例</div>
            </div>
            <!-- 列表 start -->
            <div
                    v-loading="loading">
                <el-table
                        :data="rebateList"
                        border
                        height="550"
                        style="width: 100%">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column prop label="平台/渠道" type="index" width="220">
                        <template slot-scope="scope">
                            <!--判断显示字符串-->
                            <span
                                    v-for="(v,k) in configurationData.platform_name_array"
                                    :key="k"
                                    v-show="k==scope.row.platform"
                                    style="cursor: pointer"
                                    :title="v"
                            >{{v}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop label="有效期（季度为单位）" width="220">
                        <template slot-scope="scope">
              <span
                      style="cursor: pointer"
                      :title="scope.row.expiration_date_start"
              >{{scope.row.expiration_date_start}} 至 {{scope.row.expiration_date_end}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop label="返点比例" width="220">
                        <template slot-scope="scope">
              <span
                      style="cursor: pointer"
                      :title="scope.row.rebate_ratio"
              >{{scope.row.rebate_ratio}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_real_name" label="更新人"></el-table-column>
                    <el-table-column prop="updated_at" label="更新日期" width="200"></el-table-column>
                    <el-table-column prop label="当前返点比例精准度" width="200">
                        <template slot-scope="scope">
                            <!--判断显示字符串-->
                            <span
                                    v-for="(item,index) in configurationData.return_point_ratio_accuracy_array"
                                    :key="index"
                                    v-show="index==scope.row.rebate_ratio_accuracy"
                                    style="cursor: pointer"
                                    :title="item"
                            >{{item}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop label="操作" width="200">
                        <template slot-scope="scope">
                            <span v-show="common.permFilter(`${common.php}v1/platformRebate/updateRebate`)" class="main-tableToInfo" @click="showFlag=2;editRebate(scope.row.id)">编辑</span>&nbsp;
                            <span v-show="common.permFilter(`${common.php}v1/platformRebate/deleteRebate`)" class="main-tableToInfo" @click="delRebate(scope.row.id)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination-vue
                        @currentChange="init"
                        :pageSize="pageSize"
                        :total="total"
                        :firstPage="firstPage"
                />
            </div>
            <!-- 列表 end -->
        </div>

        <!-- 新增 or 编辑 start-->
        <div v-show="showFlag==2">
            <p class="main-title">新增返点比例</p>
            <div class="infoBox">
                <div class="info-row">
                    <div class="info-cell w-33">
                        <p class="info-title t-4w">平台/渠道:</p>

                        <div class="main-selectBox info-text calc-4w">
                            <el-select
                                    v-model="create_rebate_datas.platform"
                                    clearable
                                    placeholder="请选择平台/渠道"
                                    @change="dealSelect"
                            >
                                <el-option
                                        v-for="(v,k) in configurationData.platform_name_array"
                                        :key="k"
                                        :label="v"
                                        :value="k"
                                ></el-option>
                            </el-select>
                        </div>

                    </div>
                    <div class="info-cell w-33">
                        <p class="info-title t-3w">有效期:</p>

                        <div class="main-inputBox daterange info-text calc-3w date-picker-input-width">
                            <el-date-picker
                                    v-model="create_rebate_datas.customer_created_at"
                                    value-format="yyyy-MM"
                                    format="yyyy-MM"
                                    type="monthrange"
                                    start-placeholder="有效期开始月份"
                                    clearable
                                    end-placeholder="结束月份"
                                    placeholder="有效期"
                                    :picker-options="pickerOptions"
                            ></el-date-picker>
                        </div>

                    </div>
                </div>
                <div class="info-row">
                    <div class="info-cell w-33">
                        <p class="info-title t-4w">返点比例:</p>
                        <div class="main-selectBox info-text calc-4w">
                            <el-input v-model="create_rebate_datas.rebate_ratio" clearable placeholder="请输入返点比例">
                            </el-input>
                        </div>
                    </div>
                    <div class="info-cell w-33">
                        <p class="info-title t-7w">返点比例精准度:</p>
                        <div class="main-selectBox info-text calc-4w">
                            <el-select
                                    v-model="create_rebate_datas.rebate_ratio_accuracy"
                                    clearable
                                    placeholder="请选择精准度"
                                    @change="dealSelectRatio"
                            >
                                <el-option
                                        v-for="(item,index) in configurationData.return_point_ratio_accuracy_array"
                                        :key="index"
                                        :label="item"
                                        :value="index"
                                ></el-option>
                            </el-select>
                        </div>


                    </div>
                </div>
                <div class="info-row">
                    <div class="info-cell w-33">
                        <p class="info-title "></p>
                        <div class="info-text ">

                            <div class="main-button" @click="showFlag=1">返回</div>
                            <div class="main-button" v-show="common.permFilter(`${common.php}v1/platformRebate/insertRebate`)" @click="createRebate()">提交</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /*import  from '';*/
    import paginationVue from "@/components/paginationVue.vue";
    import axios from "axios";
    import store from "@/store/index.js";
    export default {
        name: "",
        components: {
            paginationVue
        },
        data() {
            return {
                firstPage:1,
                loading:true,
                timer: '',
                //日历控件设置季度选项
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "第一季度",
                            onClick(picker) {
                                const start = new Date(new Date().getFullYear(), 0);
                                const end = new Date(new Date().getFullYear(), 2);
                                picker.$emit("pick", [start, end]);
                            }
                        },
                        {
                            text: "第二季度",
                            onClick(picker) {
                                const start = new Date(new Date().getFullYear(), 3);
                                const end = new Date(new Date().getFullYear(), 5);
                                picker.$emit("pick", [start, end]);
                            }
                        },
                        {
                            text: "第三季度",
                            onClick(picker) {
                                const start = new Date(new Date().getFullYear(), 6);
                                const end = new Date(new Date().getFullYear(), 8);
                                picker.$emit("pick", [start, end]);
                            }
                        },
                        {
                            text: "第四季度",
                            onClick(picker) {
                                const start = new Date(new Date().getFullYear(), 9);
                                const end = new Date(new Date().getFullYear(), 11);
                                picker.$emit("pick", [start, end]);
                            }
                        }
                    ]
                },
                msg: "",
                showFlag: 1,
                pageSize: 15,
                total: 1,
                ajaxDataSearch: {
                    platform: "",
                    customer_created_at: ["", ""]
                },
                rebateId: "",
                platform: "",
                rebate_ratio_accuracy: "",
                //新增平台返点数据
                create_rebate_datas: {
                    platform: "",
                    customer_created_at: ["", ""],
                    rebate_ratio: "",
                    rebate_ratio_accuracy: ""
                },
                rebateList: [
                    /* {
                     "id": "1",
                     "platform": 1,
                     "expiration_date_start": "2020-01",
                     "expiration_date_end": "2020-03",
                     "rebate_ratio": "50%",
                     "rebate_ratio_accuracy": 1,
                     "updated_at": "2020-04-26 14:46:55",
                     "user_real_name": "王凯"
                     }*/
                ],
                /*返回客户管理下搜索项用到的数据接口*/
                configurationData: {
                    platform_name_array: {
                        /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
                    },
                    return_point_ratio_accuracy_array: {
                        /*
                         1: "预估"
                         2: "确认"*/
                    }
                },

                eipTitle: "",
                eipPop: false,
                eipUrl: "",

                is_create:true,
            };
        },
        created() {
            /*返回客户管理下搜索项用到的数据接口*/
            axios.defaults.headers.common["language"] = store.state.lang;
            axios.defaults.headers.common["token"] = JSON.parse(
                localStorage.getItem("user_info")
            ).token;

            this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
                //console.log(res.data);

                if (res.code == 1) {
                    this.configurationData = res.data;
                    this.init();

                    this.timer = setTimeout(this.addStatistics, 5000);
                }else if(res.code == 2){

                    //token过期处理
                    this.common.tokenExpired(res.msg);

                } else {
                    this.$message({message: res.msg, type: "warning"});
                }
            });

        },
        methods: {
            init(page) {
                this.loading=true;
                this.firstPage=page;
                clearTimeout(this.timer);

                let param = {
                    platform: this.ajaxDataSearch.platform, //否	int	平台；如：1
                    expiration_date_start: this.ajaxDataSearch.customer_created_at ? this.ajaxDataSearch.customer_created_at[0] : '', //	否	string	有效期；如：2020-01
                    expiration_date_end: this.ajaxDataSearch.customer_created_at ? this.ajaxDataSearch.customer_created_at[1] : '', //否	string	有效期；如：2020-03

                    page: page ? page : 1
                }
                this.axios("POST", `${this.$phpBaseURL}v1/platformRebate/lists`, param).then(res => {
                    this.loading=false;
                    //console.log(res.data);
                    if (res.code == 1) {
                        this.rebateList = res.data.data;
                        this.total = res.data.total;
                        this.pageSize = res.data.per_page;
                    }else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: "warning"});
                    }
                });

            },
            enterSearch() {
                this.firstPage=1;
                this.init(1);
            },

            //处理下拉框数据 平台
            dealSelect(selVal) {
                this.platform = selVal;
            },
            //处理下拉框数据 平台
            dealSelectRatio(selVal) {
                this.rebate_ratio_accuracy = selVal;
            },

            //新增返点比例
            createRebate() {

                var req_api = "v1/platformRebate/insertRebate";
                var req_data = {
                    platform: this.platform, //否	int	平台；如：1
                    expiration_date_start: this.create_rebate_datas.customer_created_at[0], //	否	string	有效期；如：2020-01
                    expiration_date_end: this.create_rebate_datas.customer_created_at[1], //否	string	有效期；如：2020-03
                    rebate_ratio: this.create_rebate_datas.rebate_ratio,
                    rebate_ratio_accuracy: this.rebate_ratio_accuracy
                };
                if (this.rebateId) {

                    this.is_create = false;

                    req_api = "v1/platformRebate/updateRebate";
                    req_data.id = this.rebateId;
                }

                this.axios("POST", `${this.$phpBaseURL}${req_api}`, req_data).then(res => {
                    //console.log(res.data);

                    if (res.code == 1) {
                       this.showFlag=1;

                       //区分 新增、编辑返点比例
                       this.common.statisticsAdd('编辑返点比例','2');
                       if(this.is_create){
                           this.common.statisticsAdd('新建返点比例','2');
                       }

                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: "warning"});
                        return false;
                    }
                    this.init(res.data.per_page);
                });

            },
            //编辑返点比例
            editRebate(rebateId) {
                var x;
                for (x in this.rebateList) {
                    if (this.rebateList[x].id == rebateId) {
                        this.platform = this.rebateList[x].platform;
                        this.rebate_ratio_accuracy = this.rebateList[x].rebate_ratio_accuracy;

                        this.create_rebate_datas.platform = this.configurationData.platform_name_array[
                            this.rebateList[x].platform
                            ];
                        this.create_rebate_datas.rebate_ratio = this.rebateList[
                            x
                            ].rebate_ratio;
                        this.create_rebate_datas.rebate_ratio_accuracy = this.configurationData.return_point_ratio_accuracy_array[
                            this.rebateList[x].rebate_ratio_accuracy
                            ];
                        this.create_rebate_datas.customer_created_at = [
                            this.rebateList[x].expiration_date_start,
                            this.rebateList[x].expiration_date_end
                        ];

                        this.rebateId = this.rebateList[x].id;
                        break;
                    }
                }
            },
            //删除返点比例
            delRebate(rebateId) {
                this.$confirm("确认删除吗？", "提示", {type: "warning"}).then(() => {
                    var req_api = "v1/platformRebate/deleteRebate";
                    var req_data = {
                        id: rebateId //返点自增id
                    };
                    this.axios("POST", `${this.$phpBaseURL}${req_api}`, req_data).then(
                        res => {
                            //console.log(res.data);

                            if (res.code == 1) {
                                this.$message({message: res.msg, type: "sucess"});
                            } else if(res.code == 2){

                                //token过期处理
                                this.common.tokenExpired(res.msg);

                            } else {
                                this.$message({message: res.msg, type: "warning"});
                                return false;
                            }

                            this.init(res.data.per_page);

                        }
                    );
                });

            },

            //频次统计
            addStatistics() {
                this.common.statisticsAdd('平台返点比例表列表','1')
            },
        },
        //mounted 挂载结束状态
        mounted() {
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>