import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang:'zh',
    staffScaleArr: [],
    industrysArr : [],
    personal: null,
    corpid: '',
    agentid: '',
    curRouter:true,
    buttonPower:[],
    navList:[]
  },
  mutations: {
    getStaffScaleArr(state,arr){
      state.staffScaleArr = arr;
    },
    getIndustrysArr(state,arr){
      state.industrysArr = arr;
    },
    getPersonal(state,obj){
      state.personal = obj;
      console.log(state.personal)
    },
    changeLang(state,v){
      if(v){
        state.lang=v;
      }
    },
    setCorpid(state,id){
      state.corpid=id;
    },
    setAgentid(state,id){
      state.agentid=id;
    },
    curRouter(state,val){
      //当前路由
      state.curRouter = val;
    },
    setButtonPower(state,val){
      state.buttonPower = val
    },
    setNavList(state,val){
      state.navList = val
    },
  },
  actions: {
    getStaffScaleArr(context,arr){
      context.commit('getStaffScaleArr',arr)
    },
    getIndustrysArr(context,arr){
      context.commit('getIndustrysArr',arr)
    },
    getPersonal(context,obj){
      context.commit('getPersonal',obj)
    }
  },
  modules: {}
});
