<template>
  <div class="main-div">
    <div v-show="showFlag==1">
      <p class="main-title">合同列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.contract_name"
            clearable
            placeholder="请输入合同名称"
            @change="enterSearch"
          ></el-input>
        </div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.client_company_name"
            clearable
            placeholder="请输入客户名称"
            @change="enterSearch"
          ></el-input>
        </div>
        <div class="main-inputBox daterange date-picker-input-width">
          <el-date-picker
            v-model="ajaxDataSearch.contract_created_at"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="创建开始日期"
            clearable
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.status"
            @change="enterSearch"
            clearable
            placeholder="合同状态"
          >
            <el-option
              v-for="(item,k) in configurationData.contract_status_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.dep_id"
            @change="enterSearch"
            clearable
            placeholder="归属部门"
          >
            <el-option
              v-for="item in configurationData.departments_data"
              :key="item.department_id"
              :label="item.department_name"
              :value="item.department_id"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.is_archive"
            @change="enterSearch"
            clearable
            placeholder="是否存档"
          >
            <el-option
              v-for="(item,k) in configurationData.is_guarantee_archive_prepaid_array"
              :key="k"
              :label="item"
              :value="k"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div style="margin-bottom: 32px">
        <div
          class="main-button"
          v-show="common.permFilter(`${common.php}v1/contract/getSalesContract`)"
          @click="common.openEip('contract/getSalesContract')"
        >销售合同</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getContractApproval`)"
          @click="common.openEip('contract/getContractApproval')"
        >合同审批</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getContractPurchase`)"
          @click="common.openEip('contract/getContractPurchase')"
        >采购合同</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getOtherContract`)"
          @click="common.openEip('contract/getOtherContract')"
        >其他合同</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getSalesContractApproval`)"
          @click="common.openEip('contract/getSalesContractApproval')"
        >销售合同审批</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getContractPurchaseApproval`)"
          @click="common.openEip('contract/getContractPurchaseApproval')"
        >采购合同审批</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getOtherContractApproval`)"
          @click="common.openEip('contract/getOtherContractApproval')"
        >其他合同审批</div>
        <!--<div class="main-button" @click="common.openEip('clientContact/getNewContact')">AM编辑审批</div>-->
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/contract/getContractArchiving`)"
          @click="common.openEip('contract/getContractArchiving')"
        >合同存档</div>
      </div>
      <div v-loading="loading">
        <el-table :data="contactList" border height="550" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop label="序号" type="index" width="80"></el-table-column>
          <el-table-column prop label="合同编号" width="200">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.contract_serial"
              >{{scope.row.contract_serial}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="contract_name" label="合同名称" width="220" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer">{{scope.row.contract_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="业务类型" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.business_type_name"
              >{{scope.row.business_type_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="合同类型" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.contract_type_name"
              >{{scope.row.contract_type_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="合同细分" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.contract_type_name"
              >{{scope.row.contract_breakdown_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="客户名称" width="260">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.client_company_name"
              >{{scope.row.client_company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="合同状态" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :class="scope.row.status | statusClassFilter"
                :title="scope.row.status"
              >{{checkStatus(scope.row.status)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="department_name" label="归属部门" width="220" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer">{{scope.row.department_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="contract_amount" label="合同金额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer">{{scope.row.contract_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="回款跟踪人" width="110">
            <template slot-scope="scope">
              {{scope.row.payment_contract_name}}
            </template>
          </el-table-column>
          <el-table-column prop="track_contract_name" label="合同跟踪人" width="110">
            <template slot-scope="scope">
              {{scope.row.track_contract_name}}
            </template>
          </el-table-column>
          <el-table-column prop="applicant_name" label="申请人" width="100"></el-table-column>
          <el-table-column prop label="合同开始时间" width="120">
            <template
              slot-scope="scope"
              v-if="scope.row.contract_start.indexOf('1970')==-1"
            >{{scope.row.contract_start}}</template>
            <template slot-scope="scope" v-else>-</template>
          </el-table-column>
          <el-table-column prop label="合同结束时间" width="120">
            <template
              slot-scope="scope"
              v-if="scope.row.contract_end.indexOf('1970')==-1"
            >{{scope.row.contract_end}}</template>
            <template slot-scope="scope" v-else>-</template>
          </el-table-column>
          <el-table-column prop="contact_name" label="联系人" width="120"></el-table-column>
          <el-table-column prop="company_email" label="公司邮箱" width="200"></el-table-column>
          <el-table-column prop label="是否存档" width="120">
            <template
              slot-scope="scope"
            >{{configurationData.is_guarantee_archive_prepaid_array[scope.row.is_archive]}}</template>
          </el-table-column>
          <el-table-column prop label="是否作废" width="120">
            <template
              slot-scope="scope"
            >{{configurationData.is_guarantee_archive_prepaid_array[scope.row.is_invalid]}}</template>
          </el-table-column>
          <el-table-column prop label="操作" width="120">
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                v-if="scope.row.contract_type_name=='销售合同'"
                v-show="common.permFilter(`${common.php}v1/contract/detail`)"
                @click="showFlag=2;getInfo(scope.row.contract_id)"
              >合同详情</span>
              <span
                class="main-tableToInfo"
                v-if="scope.row.contract_type_name=='采购合同'"
                v-show="common.permFilter(`${common.php}v1/contract/detail`)"
                @click="showFlag=3;getInfo(scope.row.contract_id)"
              >合同详情</span>
              <span
                class="main-tableToInfo"
                v-if="scope.row.contract_type_name=='其他业务合同'"
                v-show="common.permFilter(`${common.php}v1/contract/detail`)"
                @click="showFlag=4;getInfo(scope.row.contract_id)"
              >合同详情</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag==2">
      <p class="main-title">合同申请人：{{info.applicant_name}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同编号:</p>
            <div class="info-text calc-4w">{{info.contract_serial}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同名称:</p>
            <div class="info-text calc-4w">{{info.contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">业务类型:</p>
            <div class="info-text calc-4w">{{info.business_type_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">归属部门:</p>
            <div class="info-text calc-4w">{{info.department_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">合同跟踪人:</p>
            <div class="info-text calc-5w">{{info.track_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">回款跟踪人:</p>
            <div class="info-text calc-5w">{{info.payment_contract_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同类型:</p>
            <div class="info-text calc-4w">{{info.contract_type_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同细分:</p>
            <div class="info-text calc-4w">{{info.contract_breakdown_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同签订时间:</p>
            <div class="info-text calc-6w">{{info.contract_signing_time}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">原销售合同:</p>
            <div class="info-text calc-6w">{{info.original_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">关联主框架合同:</p>
            <div class="info-text calc-7w">{{info.related_main_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">币种:</p>
            <div class="info-text calc-4w">{{info.currency_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同金额:</p>
            <div class="info-text calc-4w">{{info.contract_amount}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">我方公司名称:</p>
            <div class="info-text calc-6w">{{info.contract_owner_company_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同开始时间:</p>
            <div class="info-text calc-6w">{{info.contract_start}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同结束时间:</p>
            <div class="info-text calc-6w">{{info.contract_end}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">预付比例:</p>
            <div class="info-text calc-4w">{{info.prepaid_ratio}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">预计毛利率:</p>
            <div class="info-text calc-6w">{{info.estimated_gross_margin}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">AM负责人:</p>
            <div class="info-text calc-4w">{{info.am_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">客户名称:</p>
            <div class="info-text calc-4w">{{info.client_company_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-3w">联系人:</p>
            <div class="info-text calc-3w">{{info.contact_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">公司邮箱:</p>
            <div class="info-text calc-4w">{{info.company_email}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">客户信用级别:</p>
            <div class="info-text calc-6w">{{info.level_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">客户信用额度:</p>
            <div class="info-text calc-6w">{{info.customer_credit_limit}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">关联项目:</p>
            <div class="info-text calc-4w">{{info.project_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">项目负责人:</p>
            <div class="info-text calc-6w">{{info.project_manager_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">是否关联交易:</p>
            <div
              class="info-text calc-6w"
            >{{configurationData.is_guarantee_archive_prepaid_array[info.is_related_party_transactions]}}</div>
          </div>
        </div>

        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">交易类别:</p>
            <div class="info-text calc-4w">{{info.contract_transaction_type_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">备注:</p>
            <div class="info-text calc-4w">{{info.remark}}</div>
          </div>
        </div>

        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">是否客户返点:</p>
            <div class="info-text calc-6w">
              <el-table
                v-if="configurationData && info.contract_rebate"
                :data="info.contract_rebate"
                style="width: 100%"
              >
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="100"></el-table-column>
                <el-table-column label="平台" width="120">
                  <template slot-scope="scope">{{platform_filter(scope.row.platform)}}</template>
                </el-table-column>
                <el-table-column prop="delivery_type_name" label="投放类型" width="120"></el-table-column>
                <el-table-column prop="location_name" label="地区（头条）" width="120"></el-table-column>
                <el-table-column prop="products" label="平台产品（头条）" width="200"></el-table-column>
                <el-table-column prop="ad_type_name" label="广告类型（头条）" width="200"></el-table-column>
                <el-table-column prop="rebate_begin" label="返点阶梯(开始值)/万元" width="150"></el-table-column>
                <el-table-column prop="rebate_end" label="返点阶梯(结束值)/万元" width="150"></el-table-column>
                <el-table-column prop="rebate_ratio" label="返点比例（%）" width="150"></el-table-column>
                <el-table-column prop label="返点周期" width="120">
                  <template slot-scope="scope" v-if="scope.row.rebate_period == 'quarter'">季度</template>
                  <template slot-scope="scope" v-else-if="scope.row.rebate_period == 'month'">月度</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p
              class="info-title t-3w"
              style="margin-bottom: 12px"
              v-if="info.fine_closing_down==1 || info.ncs_policy==1"
            >罚则:</p>
            <div>
              <p
                class="info-title t-160w"
                style="margin-bottom: 6px;margin-left: 30px"
                v-if="info.fine_closing_down==1"
              >封户罚金--被封户数量*1000美金，罚金与返点一起结算</p>
            </div>
            <div>
              <p
                class="info-title t-160w"
                style="margin-bottom: 6px;margin-left: 30px"
                v-if="info.ncs_policy==1"
              >受到NCS政策处罚的，将扣除违规账户当季度全部返点</p>
            </div>
          </div>
        </div>
        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">合同内容:</p>
            <div class="info-text calc-6w">
              <el-table :data="info.contract_content_file" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="100"></el-table-column>
                <el-table-column prop="file_name" label="文件名称" width></el-table-column>
                <el-table-column label="文件大小" width>
                  <template
                    slot-scope="scope"
                    v-if="scope.row.file_size"
                  >{{(scope.row.file_size / 1024 / 1024).toFixed(2)}}MB</template>
                  <template v-else>-</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!--                        <p class="info-title t-6w"></p>-->
            <div class="info-text calc-6w">
              <!--                            <div class="main-button download-self" v-show="common.permFilter(`${common.php}v1/contract/contractDownload`)" @click="downloadfile()">-->
              <!--                                <svg class="gongzuotai-01" aria-hidden="true">-->
              <!--                                    <use xlink:href="#gztxiazai"></use>-->
              <!--                                </svg>-->
              <!--                            </div>-->
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showFlag==3">
      <p class="main-title">合同申请人1：{{info.applicant_name}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同编号:</p>
            <div class="info-text calc-4w">{{info.contract_serial}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同名称:</p>
            <div class="info-text calc-4w">{{info.contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">业务类型:</p>
            <div class="info-text calc-4w">{{info.business_type_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">归属部门:</p>
            <div class="info-text calc-4w">{{info.department_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">合同跟踪人:</p>
            <div class="info-text calc-5w">{{info.track_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">付款跟踪人:</p>
            <div class="info-text calc-5w">{{info.payment_follower_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同类型:</p>
            <div class="info-text calc-4w">{{info.contract_type_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同细分:</p>
            <div class="info-text calc-4w">{{info.contract_breakdown_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">关联销售合同:</p>
            <div class="info-text calc-6w">{{info.related_sales_contract_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">原销售合同:</p>
            <div class="info-text calc-6w">{{info.original_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">关联主框架合同:</p>
            <div class="info-text calc-7w">{{info.related_main_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同签订时间:</p>
            <div class="info-text calc-6w">{{info.contract_signing_time}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">币种:</p>
            <div class="info-text calc-6w">{{info.currency_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同金额:</p>
            <div class="info-text calc-4w">{{info.contract_amount}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">我方公司名称:</p>
            <div class="info-text calc-6w">{{info.contract_owner_company_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同开始时间:</p>
            <div class="info-text calc-6w">{{info.contract_start}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同结束时间:</p>
            <div class="info-text calc-6w">{{info.contract_end}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">预付比例:</p>
            <div class="info-text calc-4w">{{info.prepaid_ratio}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-8w">剩余可申请金额:</p>
            <div class="info-text calc-8w">{{info.remaining_application_amount}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">关联项目:</p>
            <div class="info-text calc-4w">{{info.project_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">项目负责人:</p>
            <div class="info-text calc-6w">{{info.project_manager_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">供应商名称:</p>
            <div class="info-text calc-6w">{{info.supplier_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">供应商联系人:</p>
            <div class="info-text calc-6w">{{info.supplier_contact_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-8w">供应商公司邮箱:</p>
            <div class="info-text calc-8w">{{info.supplier_company_email}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">采购申请单:</p>
            <div class="info-text calc-6w">{{info.purchase_sn}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-8w">合同实际付款金额:</p>
            <div class="info-text calc-8w">{{info.actual_contract_payment}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">渠道返点金额:</p>
            <div class="info-text calc-6w">{{info.channel_rebate_amount}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-8w">预充值消耗金额:</p>
            <div class="info-text calc-8w">{{info.prepaid_recharge_amount}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-9w">供应商返点消耗金额:</p>
            <div class="info-text calc-9w">{{info.supplier_rebate_consumption_amount}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">备注:</p>
            <div class="info-text calc-2w">{{info.remark}}</div>
          </div>
        </div>

        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">合同内容:</p>
            <div class="info-text calc-6w">
              <el-table :data="info.contract_content_file" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="100"></el-table-column>
                <el-table-column prop="file_name" label="文件名称" width></el-table-column>
                <el-table-column label="文件大小" width>
                  <template
                    slot-scope="scope"
                    v-if="scope.row.file_size"
                  >{{(scope.row.file_size / 1024 / 1024).toFixed(2)}}MB</template>
                  <template v-else>-</template>
                </el-table-column>
                <!-- <el-table-column
                                        prop=""
                                        label="文件状态"
                                        width="">
                                    <template slot-scope="scope">
                                        {{configurationData.file_upload_status[scope.row.status]}}
                                    </template>
                </el-table-column>-->
                <!-- <el-table-column
                                        prop="user_name"
                                        label="上传人"
                                        width="">
                </el-table-column>-->
                <!-- <el-table-column
                                        prop="related_relationship_name"
                                        label="操作"
                                        width="">
                </el-table-column>-->
              </el-table>
            </div>
          </div>
        </div>

        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!--                        <p class="info-title t-6w"></p>-->
            <div class="info-text calc-6w">
              <!--                            <div class="download-self main-button" v-show="common.permFilter(`${common.php}v1/contract/contractDownload`)" @click="downloadfile()">-->
              <!--                                &lt;!&ndash; <svg class="gongzuotai-01" aria-hidden="true">-->
              <!--                                    <use xlink:href="#gztxiazai"></use>-->
              <!--                                </svg> &ndash;&gt;-->
              <!--                                <i class="gongzuotai gztxiazai"></i>-->
              <!--                                下载-->
              <!--                            </div>-->
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showFlag==4">
      <p class="main-title">合同申请人：{{info.applicant_name}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同编号:</p>
            <div class="info-text calc-4w">{{info.contract_serial}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同名称:</p>
            <div class="info-text calc-4w">{{info.contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">业务类型:</p>
            <div class="info-text calc-4w">{{info.business_type_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">归属部门:</p>
            <div class="info-text calc-4w">{{info.department_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">合同跟踪人:</p>
            <div class="info-text calc-5w">{{info.track_contract_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">付款跟踪人:</p>
            <div class="info-text calc-5w">{{info.payment_follower_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同类型:</p>
            <div class="info-text calc-4w">{{info.contract_type_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同细分:</p>
            <div class="info-text calc-4w">{{info.contract_breakdown_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">关联合同:</p>
            <div class="info-text calc-4w">{{info.related_contract_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同签订时间:</p>
            <div class="info-text calc-6w">{{info.contract_signing_time}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">币种:</p>
            <div class="info-text calc-6w">{{info.currency_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">合同金额:</p>
            <div class="info-text calc-4w">{{info.contract_amount}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">我方公司名称:</p>
            <div class="info-text calc-6w">{{info.contract_owner_company_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同开始时间:</p>
            <div class="info-text calc-6w">{{info.contract_start}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">合同结束时间:</p>
            <div class="info-text calc-6w">{{info.contract_end}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">预付比例:</p>
            <div class="info-text calc-4w">{{info.prepaid_ratio}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">关联项目:</p>
            <div class="info-text calc-4w">{{info.project_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">项目负责人:</p>
            <div class="info-text calc-6w">{{info.project_manager_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-8w">剩余可申请金额:</p>
            <div class="info-text calc-8w">{{info.remaining_application_amount}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">供应商名称:</p>
            <div class="info-text calc-6w">{{info.supplier_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">供应商联系人:</p>
            <div class="info-text calc-6w">{{info.supplier_contact_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-8w">供应商公司邮箱:</p>
            <div class="info-text calc-8w">{{info.supplier_company_email}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">是否关联交易:</p>
            <div
              class="info-text calc-6w"
            >{{configurationData.is_guarantee_archive_prepaid_array[info.is_related_party_transactions]}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">交易类别:</p>
            <div class="info-text calc-4w">{{info.contract_transaction_type_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-80">
            <p class="info-title t-2w">备注:</p>
            <div class="info-text calc-2w">{{info.remark}}</div>
          </div>
        </div>

        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">合同内容:</p>
            <div class="info-text calc-6w">
              <el-table :data="info.contract_content_file" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="100"></el-table-column>
                <el-table-column prop="file_name" label="文件名称" width></el-table-column>
                <el-table-column label="文件大小" width>
                  <template
                    slot-scope="scope"
                    v-if="scope.row.file_size"
                  >{{(scope.row.file_size / 1024 / 1024).toFixed(2)}}MB</template>
                  <template v-else>-</template>
                </el-table-column>
                <!-- <el-table-column
                                        prop=""
                                        label="文件状态"
                                        width="">
                                    <template slot-scope="scope">
                                        {{configurationData.file_upload_status[scope.row.status]}}
                                    </template>
                </el-table-column>-->
                <!-- <el-table-column
                                        prop="user_name"
                                        label="上传人"
                                        width="">
                </el-table-column>-->
                <!-- <el-table-column
                                        prop="related_relationship_name"
                                        label="操作"
                                        width="">
                </el-table-column>-->
              </el-table>
            </div>
          </div>
        </div>

        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!--                        <p class="info-title t-6w"></p>-->
            <div class="info-text calc-6w">
              <!--                            <div class="main-button download-self" v-show="common.permFilter(`${common.php}v1/contract/contractDownload`)" @click="downloadfile()">下载-->
              <!--                                <svg class="gongzuotai-01" aria-hidden="true">-->
              <!--                                    <use xlink:href="#gztxiazai"></use>-->
              <!--                                </svg>-->
              <!--                            </div>-->
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--合同跟踪人-->
    <div class="main-pop" v-show="paymentPop">
      <div class="mask"></div>
      <div class="center" style="width: 696px">
        <div class="head">
          <span>销售</span>
          <i class="gongzuotai gztguanbi" @click="paymentPop=false"></i>
        </div>
        <div class="body">
          <el-table :data="paymentList" style="width: 100%">
            <el-table-column prop label="序号" type="index" width="100"></el-table-column>
            <el-table-column prop="tracker_name" label="回款跟踪人" width></el-table-column>
            <el-table-column prop="track_time" label="变更时间" width></el-table-column>
          </el-table>
          <pagination-vue @currentChange="init" :pageSize="pageSize" :total="total"/>
        </div>
        <div class="foot">
          <button class="main-button l" @click="paymentPop=false">取消</button>
          <button class="main-button r" @click="paymentPop=false">确定</button>
        </div>
      </div>
    </div>
    <!--回款跟踪人-->
    <div class="main-pop" v-show="trackPop">
      <div class="mask"></div>
      <div class="center" style="width: 696px">
        <div class="head">
          <span>销售</span>
          <i class="gongzuotai gztguanbi" @click="trackPop=false"></i>
        </div>
        <div class="body">
          <el-table :data="trackList" style="width: 100%">
            <el-table-column prop label="序号" type="index" width="100"></el-table-column>
            <el-table-column prop="tracker_name" label="回款跟踪人" width></el-table-column>
            <el-table-column prop="track_time" label="变更时间" width></el-table-column>
          </el-table>
          <pagination-vue @currentChange="init" :pageSize="pageSize" :total="total"/>
        </div>
        <div class="foot">
          <button class="main-button l" @click="trackPop=false">取消</button>
          <button class="main-button r" @click="trackPop=false">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 1,
      ajaxDataSearch: {
        contract_name: "", //	否	string	合同名称；如：968485f47cc0b7ac7cf36d4868689866
        client_company_name: "", //	否	string	客户名称；如：968485f47cc0b7ac7cf36d4868689866
        contract_created_at: ["", ""], //	否	string	合同创建日期；如：968485f47cc0b7ac7cf36d4868689866
        dep_id: "", //	否	string	归属部门；如：968485f47cc0b7ac7cf36d4868689866
        status: "", //	否	int	合同状态；如：1
        is_archive: "", //否	int	是否存档；如：1
        page_num: 15
      },
      optionsTrade: [],
      optionsStatus: [
        {
          value: "1",
          label: "待审核"
        },
        {
          value: "2",
          label: "审核通过"
        },
        {
          value: "3",
          label: "审核失败"
        }
      ],
      contactList: [],
      info: {
        contract_id: "dfer4545",
        contract_name: "合同名称cs",
        related_contract_id: "dfer4545",
        contract_serial: "df34t45hrt",
        business_type: 1,
        contract_type: 1,
        contract_subdivision: 1,
        status: 1,
        dep_id: "efdsvsdf2",
        contract_amount: "1.00",
        applicant_id: "dgerg45yrherg432",
        approver_id: "dgerg45yrherg432",
        contract_start: "2020-04-16 16:50:31",
        contract_end: "2020-04-16 16:50:35",
        contact_id: "dfverg34g45",
        company_email: "123",
        is_guarantee: 1,
        guarantee_amount: "2.00",
        is_archive: 1,
        archive_time: "2020-04-16 16:51:41",
        is_invalid: 1,
        invalid_time: "2020-04-16 16:51:51",
        is_prepaid: 1,
        prepaid_ratio: "3.00",
        guarantee_company: "vvgerg45t54h",
        remark: "cs",
        contract_created_at: "2020-04-16 16:52:11",
        our_side_company_id: "0",
        contract_signing_time: null,
        financial_client_company_id: "",
        is_related_party_transactions: 0,
        transaction_type_id: "",
        client_company_name: "client_company--cscs",
        departments_name: "cs",
        contact_name: "contact_name--cscs",
        project_name: "今日头条-cscs",
        project_manager_id: "dgerg45yrherg432",
        contract_owner_company_name: null,
        contract_financial_clients_name: null,
        contract_transaction_type_name: null,
        status_name: "待审核",
        business_type_name: "整合营销",
        contract_type_name: "销售合同",
        contract_subdivision_name: "普通合同",
        is_guarantee_name: "是",
        is_archive_name: "是",
        is_invalid_name: "是",
        is_prepaid_name: "是",
        is_related_party_transactions_name: "",
        applicant_name: "王玉影_cscs",
        approver_name: "王玉影_cscs",
        project_manager_name: "王玉影_cscs",
        payment_contract_name: "王玉影_cscs",
        track_contract_name: "王玉影_cscs",
        related_contract_name: "合同名称cs"
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      },
      paymentPop: false,
      paymentList: [
        {
          tracker_id: "dgerg45yrherg432",
          track_time: "2020-04-17 17:39:52",
          tracker_name: "王玉影_cscs"
        }
      ],
      trackPop: false,
      trackList: [
        {
          tracker_id: "dgerg45yrherg432",
          track_time: "2020-04-17 17:39:52",
          tracker_name: "王玉影_cscs"
        }
      ],
      contract_id: "" //选中的合同的id
    };
  },
  computed: {
    curRouter() {
      return this.$store.state.curRouter;
    }
  },
  watch: {
    curRouter(val) {
      if (val) {
        this.showFlag = 1;
      }
      console.log("val:", val);
    }
  },
  filters: {
    formatTime(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },

    statusClassFilter(value) {
      // 合同状态(0未提交，1待审批，2审核通过，5驳回未提交，6已过期，7已作废)
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        case 6:
          m = "shenpi-yiguoqi";
          break;
        case 7:
          m = "shenpi-yizoufei";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    if (this.$route.query.from == "customer") {
      this.ajaxDataSearch.client_company_name = this.$route.query.client_company_name;
    }
    if (
      localStorage.getItem("firstToContractList") &&
      localStorage.getItem("firstToContractList") == "1"
    ) {
      localStorage.setItem("firstToContractList", "");
      window.location.reload();
      return false;
    } else if (localStorage.getItem("client_company_name")) {
      this.ajaxDataSearch.client_company_name = localStorage.getItem(
        "client_company_name"
      );
      localStorage.setItem("client_company_name", "");
    }

    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;

        this.init();

        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  methods: {
    sizeInit(size){
      this.ajaxDataSearch.page_num = size ? size : 15
      this.init(1)
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      var startDate = "";
      var endDate = "";
      if (this.ajaxDataSearch.contract_created_at) {
        startDate = this.ajaxDataSearch.contract_created_at[0];
        endDate = this.ajaxDataSearch.contract_created_at[1];
      }
      this.axios("POST", `${this.$phpBaseURL}v1/contract/lists`, {
        contract_name: this.ajaxDataSearch.contract_name, //	否	string	合同名称；如：968485f47cc0b7ac7cf36d4868689866
        client_company_name: this.ajaxDataSearch.client_company_name, //	否	string	客户名称；如：968485f47cc0b7ac7cf36d4868689866
        contract_start: startDate, //	否	string	搜索合同的创建日期；如：2020-04-23
        contract_end: endDate, //	否	string	搜索合同的创建日期；如：2020-04-23
        dep_id: this.ajaxDataSearch.dep_id, //	否	string	归属部门；如：968485f47cc0b7ac7cf36d4868689866
        status: this.ajaxDataSearch.status, //	否	int	合同状态；如：1
        is_archive: this.ajaxDataSearch.is_archive, //否	int	是否存档；如：1
        page: page ? page : 1,
        page_num:this.ajaxDataSearch.page_num
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.contactList = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;

      if (this.ajaxDataSearch.contract_created_at == null) {
        this.ajaxDataSearch.contract_created_at = ["", ""];
      }
      this.init();
    },
    /*详情*/
    getInfo(id) {
      this.contract_id = id;
      this.$store.commit("curRouter", false);
      this.axios("POST", `${this.$phpBaseURL}v1/contract/detail`, {
        contract_id: id
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*回款跟踪*/
    payment(id) {
      this.axios("POST", `${this.$phpBaseURL}v1/contract/payment`, {
        contract_id: id
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.paymentList = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*合同跟踪人*/
    track(id) {
      this.axios("POST", `${this.$phpBaseURL}v1/contract/contractTrack`, {
        contract_id: id
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.trackList = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    platform_filter(value) {
      //过滤平台（filters this不指向Vue）
      let text = "";
      let that = this;
      console.log("pp000:", that.info);

      for (let key in that.configurationData.platform_array) {
        if (key == value) text = that.configurationData.platform_array[key];
      }
      return text;
    },
    downloadfile() {
      let token = JSON.parse(localStorage.getItem("user_info")).token;
      let url = `${this.$phpBaseURL}v1/contract/contractDownload?token=${token}&contract_id=${this.contract_id}`;
      window.open(url, "_blank");
    },
    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = this.configurationData.contract_status_array[value];
      return text;
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("合同管理列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>