<template>
  <div class="main-div media" @click="visible=false">
    <div v-show="showFlag==1">
      <p class="main-title">黑名单列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.companyName"
            clearable
            placeholder="请输入公司名称"
            @change="enterSearch"
          ></el-input>
        </div>

        <div class="main-inputBox daterange" style="width:255px!important">
          <el-date-picker
            v-model="ajaxDataSearch.createDate"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            clearable
            placeholder="更新时间"
            class="date-picker-input"
          ></el-date-picker>
        </div>
      </div>
      <div class="main-btnBox">
        <div class="main-button" @click="download()">黑名单模板下载</div>
        <el-upload
          class="main-button"
          style="padding:0"
          action
          :before-upload="beforeUpload"
          :show-file-list="false"
          :multiple="false"
        >
          <el-button
            style="width:100%;display:block;padding-top:0px;padding-bottom:0px;border:#01ace4 solid 1px;background: white;"
            class="main-button"
          >上传黑名单</el-button>
        </el-upload>

        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/client/khjj`)"
          @click="black_batch_del_before()"
        >批量删除</div>

        <div class="main-button main-button-first" @click="export_file()">导出</div>
      </div>
      <div class="need-reTd">
        <el-table
          v-loading="loading"
          :data="blackLists"
          border
          height="550"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>

          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column show-overflow-tooltip prop="company_zh" label="中文公司名称">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.company_zh">{{scope.row.company_zh}}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="company_en" label="英文公司名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="remark" label="备注"></el-table-column>
          <el-table-column show-overflow-tooltip prop="client_company_name" label="归属客户"></el-table-column>
          <el-table-column show-overflow-tooltip label="负责AM">
            <template slot-scope="scope">
              {{scope.row.am_name && scope.row.am_name.length > 0 ? scope.row.am_name.toString() : "-"}}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="create_people" label="录入人">
            <template
              slot-scope="scope"
            >{{scope.row.create_people_name?scope.row.create_people_name:scope.row.create_people}}</template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="update_time" label="更新时间"></el-table-column>

          <el-table-column prop label="操作" width="180">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="black_del_pre(scope.row.id)">删除</span>
              <div class="main-20px"></div>

              <div class="main-20px"></div>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>

    <!--删除确认框-->
    <div class="main-pop" v-show="delPop">
      <div class="mask"></div>
      <div class="center">
        <div class="head">
          <span style="font-size: 24px">提示</span>
          <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
        </div>
        <div class="body" style="margin: auto">{{ delPopText }}</div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="delPop=false">取消</button>
          <button class="main-button r" @click="back_del_com()">确定</button>
        </div>
      </div>
    </div>
    <!--删除确认框-->
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import wx from "@/assets/img/wx.png";
import yl from "@/assets/img/yl.png";
import zfb from "@/assets/img/zfb.png";
import ElButton from "../../../../../node_modules/element-ui/packages/button/src/button";

export default {
  name: "",
  components: {
    ElButton,
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      timer: "",
      blackLists: [],
      visible: false,

      pageSize: 15,
      token: "",
      showFlag: 1,
      total: 1,
      ajaxDataSearch: {
        companyName: "", //
        createDate: ""
      },
      multipleSelection: [],

      delPop: false,
      delPopText: "", //删除确认框中提示语
      listId: 0,
      multipleIds: "",
      is_batch: false
    };
  },
  filters: {
    closeReason(value) {
      //关闭原因：账户关闭原因:1 客户要求,2 账户违规,3 与客户解除合作
      let t = "";
      switch (value) {
        case 1:
          t = "客户要求";
          break;
        case 2:
          t = "账户违规";
          break;
        case 3:
          t = "与客户解除合作";
          break;
        default:
          break;
      }
      return t;
    }
  },
  mounted() {},
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;

    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;

    this.init(1);
    this.timer = setTimeout(this.addStatistics, 5000);

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  methods: {
    testsss(e) {
      return;
    },
    sizeInit(size){
      this.pageSize = size ? size : 15;
      this.init(1)
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      this.loading = true;

      clearTimeout(this.timer);

      this.axios("get", `${this.$phpBaseURL}v1/black_list/lists`, {
        company_name: this.ajaxDataSearch.companyName, //	否	string	客户公司名称
        date: this.ajaxDataSearch.createDate,
        page: page ? page : 1,
        page_num: this.pageSize
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.blackLists = res.data.data;
          this.total = res.data.total;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    handleSelectionChange(val) {
      var multiple_ids = [];
      val.forEach(function(item) {
        multiple_ids.push(item.id);
      });
      this.multipleIds = multiple_ids.join(",");
    },
    enterSearch() {
      this.firstPage = 1;

      this.init();
    },

    //批量删除前准备
    black_batch_del_before() {
      if (!this.multipleIds) {
        this.delPop = false;
        this.$message({ message: "请选择要删除的数据。", type: "warning" });
        return false;
      }

      this.delPopText = "确认批量删除数据吗？";
      this.delPop = true;
      this.is_batch = true;
    },
    //批量删除
    black_batch_del() {
      this.axios("post", `${this.$phpBaseURL}v1/black_list/black_batch_del`, {
        ids: this.multipleIds
      }).then(res => {
        if (res.code == 1) {
          this.$message({ message: "批量删除成功", type: "success" });

          this.delPop = false;

          this.init();
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
          this.delPop = false;
        }
      });
    },

    //删除前准备
    black_del_pre(id) {
      this.delPopText = "确认删除数据吗？";
      this.delPop = true;
      this.is_batch = false;
      this.listId = id;
    },
    //删除
    black_del() {
      this.axios("post", `${this.$phpBaseURL}v1/black_list/black_del`, {
        id: this.listId
      }).then(res => {
        if (res.code == 1) {
          this.delPop = false;

          this.$message({ message: "删除成功", type: "success" });

          this.init();
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    //删除公共方法
    back_del_com() {
      if (this.is_batch) {
        this.black_batch_del();
      } else {
        this.black_del();
      }
    },

    //导出
    export_file() {
      window.location.href =
        `${this.$phpBaseURL}v1/black_list/black_export?token=` +
        this.token +
        `&company_name=` +
        this.ajaxDataSearch.companyName +
        `&date=` +
        this.ajaxDataSearch.createDate;
    },
    /*下载黑名单模板*/
    download() {
      window.location.href =
        `${this.$phpBaseURL}v1/black_list/black_download?token=` + this.token;
    },
    beforeUpload(file) {
      let fd = new FormData();
      fd.append("file", file);
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.axios(
        "POST",
        `${this.$phpBaseURL}v1/black_list/black_upload`,
        fd
      ).then(res => {
        if (res.code == 1) {
          this.$message({ message: res.msg, type: "success" });
          this.init();
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("黑名单列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-pop .center .head {
  border-bottom: none;
}
.main-pop .center .head span {
  margin: auto;
}

.main-pop .center {
  width: 300px;
}

.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}

// 以下不起作用，可能是因为下拉框是动态添加的
// ::v-deep .el-dropdown-menu-self{
//     min-width:436px!important;
//     padding:10px!important;
//     box-sizing:border-box!important;
// }
// ::v-deep .el-dropdown-menu-self ul li{
//     list-style: none;
//     line-height: 36px;
//     padding: 0 20px;
//     margin: 0;
//     font-size: 14px;
//     color: #606266;
//     cursor: pointer;
//     outline: none;
// }
.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style>
.main-btnBox .main-button span {
  color: #01ace4 !important;
}
.date-picker-input input {
  padding-left: 30px !important;
  height: 32px !important;
}
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>