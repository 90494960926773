<template>
  <div class="main-div media" @click="visible=false"  style="background:none;padding:0;">
    <div v-show="showFlag==1" style="background:#fff;padding:24px;">
      <p class="main-title">IO列表</p>
      <div>
        <div class="main-inputBox" style="width:auto!important;display:inline-flex;">
          <el-select style="width:130px;" placeholder="IO状态" v-model="searchType" @change="searchTypeChange()">
            <el-option :value="'1'" label="IO名称">IO名称</el-option>
            <el-option :value="'2'" label="客户名称">客户名称</el-option>
            <el-option :value="'3'" label="广告账户ID">广告账户ID</el-option>
          </el-select>
          <el-input
            v-if="searchType == '1'"
            style="width:200px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.io_name"
          >
          </el-input>
          <el-input
            v-if="searchType == '2'"
            style="width:200px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.client_company_name"
          >
          </el-input>
          <el-input
            v-if="searchType == '3'"
            style="width:200px;"
            size="mini"
            clearable
            placeholder="请输入"
            v-model="ajaxDataSearch.account_id"
          >
          </el-input>
        </div>
        <div class="main-inputBox" style="width:200px!important">
          <el-select v-model="ajaxDataSearch.io_status" placeholder="全部IO状态">
            <el-option
              v-for="item in iostatusoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox" style="width:200px!important">
          <el-select v-model="ajaxDataSearch.network" placeholder="全部媒体平台">
            <el-option value>全部</el-option>
            <el-option
              v-for="(v,k) in configurationData.io_plan_network"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox" style="width:200px!important">
          <el-select v-model="ajaxDataSearch.delivery_type" placeholder="投放方式">
            <el-option
              v-for="(item,k) in serviceTypeList"
              :key="k"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox" style="width:200px!important">
          <div class="main-button" @click="init(1)">搜索</div>
          <div class="main-button" @click="reset()">重置</div>
        </div>
        <div style="margin-bottom:20px;">
          <div class="main-button" @click="createIOMet">新增IO</div>
        </div>
      </div>
      <div class="need-reTd">
        <el-table v-loading="loading" :data="blackLists" border style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop="name" width="200px" label="IO名称"></el-table-column>
          <el-table-column prop="client_company_name" show-overflow-tooltip width="300px" label="客户名称"></el-table-column>
          <el-table-column label="IO状态" width="100px">
            <template slot-scope="scope">{{scope.row.status == 1 ? '激活' : (scope.row.status == 2 ? '暂停' : '删除') }}</template>
          </el-table-column>
          <el-table-column prop="rebate_radio_new" label="返点"></el-table-column>
          <el-table-column prop="spend_radio_new" label="核减"></el-table-column>
          <el-table-column label="投放方式">
            <template slot-scope="scope">{{scope.row.delivery_type == 1 ? '自投' : '代投'}}</template>
          </el-table-column>
          <el-table-column prop="updated_at" label="更新时间" width="200px"></el-table-column>
          <el-table-column width="200px" label="操作">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="editMethod(scope.row)">编辑</span>
              <span class="main-tableToInfo" @click="openDetail(scope.row)">计划账号详情</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue @currentSizeChange="sizeInit" @currentChange="init" :pageSize="page_num" :total="total" :firstPage="page" />
      </div>
    </div>
    <div v-show="showFlag == 2">
      <!-- <p class="main-title">{{editObj.id?'编辑IO':"新增IO"}}</p> -->
      <div class="detail_header">
        <el-button size="small" icon="el-icon-back" @click="showFlag=1;createReset()">返回</el-button>
        <span>编辑IO</span>
      </div>
      <div style="background:#fff;padding:24px;margin-bottom:24px;">
        <el-form label-width="80px" size="mini">
          <el-form-item label="IO名称" required >
            <el-input
              style="width:350px"
              v-model="editObj.name"
              placeholder="新IO命名规范请参考已有IO"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联客户" required>
            <el-select
              style="width:350px;"
              size="mini"
              v-model="ajaxDataSearch_custom.client_company_name"
              filterable
              remote
              :remote-method="remoteMethod"
              placeholder="请选择"
              @change="updateValue"
            >
              <el-option
                v-for="(item,k) in projectList"
                :key="k"
                :label="item.client_company_name"
                :value="item.client_company_id"
              ></el-option>
              <div style="float: right;margin-right:10px;padding-bottom: 10px">
                <el-pagination
                  @current-change="getProjectList"
                  :current-page="ajaxDataSearch_custom.page"
                  :page-size="ajaxDataSearch_custom.page_num"
                  layout="total, prev, pager, next"
                  :total="ajaxDataSearch_custom.total"
                ></el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="投放类型" required>
            <el-radio v-model="editObj.delivery_type" :label="1">自投</el-radio>
            <el-radio v-model="editObj.delivery_type" :label="2">代投</el-radio>
          </el-form-item>
          <el-form-item label="IO状态" required>
            <el-radio v-model="editObj.status" :label="1">激活</el-radio>
            <el-radio v-model="editObj.status" :label="2">暂停</el-radio>
          </el-form-item>
          <el-form-item label="结算类型" required>
            <el-radio v-model="editObj.settlement_type" :label="1">常规消费制</el-radio>
            <el-radio v-model="editObj.settlement_type" :label="2">项目完成制(合同包含签约额)</el-radio>
          </el-form-item>
          <el-form-item label="返点规则" required>
            <el-radio v-model="editObj.client_rebate" :label="1">跟随客户返点</el-radio>
            <el-radio v-model="editObj.client_rebate" :label="2">自定义返点</el-radio>
          </el-form-item>
        </el-form>
        <el-table v-show="editObj.client_company_id && editObj.client_rebate == 1" :data="tableData" border style="width: 100%">
          <el-table-column prop="network_show" label="媒体平台"></el-table-column>
          <el-table-column label="有效期">
            <template slot-scope="scope">
              {{scope.row.starttime}} - {{scope.row.endtime}}
            </template>
          </el-table-column>
          <el-table-column prop="ratio_type_show" label="返点类型"></el-table-column>
          <el-table-column label="返点比例">
            <template slot-scope="scope">
              {{scope.row.ratio_type == 1 ? scope.row.ratio : getSectionRatio(scope.row.section_ratio)}}
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue @currentSizeChange="getCustomerRebateListSize" @currentChange="getCustomerRebateList" :pageSize="clientRebateData.page_num" :total="clientRebateData.total" :firstPage="clientRebateData.page" />
        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <div class="info-text calc-2w">
              <!-- <button class="main-button l" @click="showFlag=1;createReset()">取消</button> -->
              <button class="main-button r" @click="saveRate()">保存</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <!--删除确认框-->
    <div class="main-pop" v-show="delPop">
      <div class="mask"></div>
      <div class="center" style="width:300px;">
        <div class="head">
          <span style="font-size: 24px">提示</span>
          <i class="gongzuotai gztguanbi" @click="delPop=false"></i>
        </div>
        <div class="body" style="margin: auto">确定要删除？</div>
        <div class="foot" style="margin: auto;border-top: none!important">
          <button class="main-button l" @click="delPop=false">取消</button>
          <button class="main-button r" @click="back_del_com()">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";

export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      searchType: '1',
      projectList: [],
      createandedit: false,
      serviceTypeList: [
        {
          label: "自投",
          value: "1"
        },
        {
          label: "代投",
          value: "2"
        }
      ],
      iostatusoptions: [
        {
          label: "激活",
          value: 1
        },
        {
          label: "暂停",
          value: 2
        },
        {
          label: "删除",
          value: 3
        }
      ],
      options: [
        {
          label: "Facebook",
          value: 1
        },
        {
          label: "Google",
          value: 2
        },
        {
          label: "Twitter",
          value: 3
        }
      ],
      delPop: false,
      loading: false,
      blackLists: [],
      visible: false,
      token: "",
      showFlag: 1,
      total: 1,
      editObj: {
        id: "",
        name: "",
        client_rebate: 1,
        status: 1,
        delivery_type: 1,
        settlement_type: 1
      },
      radio1: 1,
      ajaxDataSearch: {
        page: 1,
        page_num:15,
        network: "",
        io_id: "",
        io_name: "",
        io_status: "",
        account_id: "",
        delivery_type:"",
        client_company_name:"",
      },
      configurationData: {
        receivable_client_status: {},
        is_overdue_config: {},
        client_status_array: {},
        client_source_array: {},
        client_connection_relation_array: {},
        client_relation_company_array: {},
        is_contain_config: {},
        platform_array: {},
        contact_status_array: {},
        gender_array: {},
        contract_status_array: {},
        business_type_array: {},
        contract_type_array: {},
        contract_subdivision_array: {},
        is_guarantee_archive_prepaid_array: {},
        project_status_array: {},
        project_approval_status: {},
        file_upload_status: {},
        platform_name_array: {},
        return_point_ratio_accuracy_array: {},
        departments_data: [],
        industry_data: []
      },
      ajaxDataSearch_custom: {
        client_company_name: "",
        page:1,
        total:0,
        page_num:15
      },
      tableData: [],
      clientRebateData:{
        client_company_id:"",
        page:1,
        total:0,
        page_num:15
      },
      page_num:15,
      page:1,
    };
  },
  created() {
    axios.defaults.headers.common["language"] = store.state.lang;
    this.token = JSON.parse(localStorage.getItem("user_info")).token;
    axios.defaults.headers.common["token"] = this.token;
    this.getProjectList();
    if (this.$route.query.from == 'createIO' && this.$route.query.searchData) {
      this.ajaxDataSearch = JSON.parse(this.$route.query.searchData)
    }
    if (this.$route.query.from == 'customer') {
      this.ajaxDataSearch.client_company_name = this.$route.query.client_company_name;
      this.searchType = this.$route.query.searchType;
    }
    if (this.$route.params.page) {
      this.ajaxDataSearch = this.$route.params
      this.page_num = this.$route.params.page_num
      this.page = this.$route.params.page
      this.init(this.$route.params.page);
    }else{
      this.init(1);
    }

    //点击回车进行搜索
    let that=this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  mounted() {
    this.common.getConfigData('client_level,project_principal,project_authorization_list').then((res)=>{
      this.configurationData = res.data
    }).catch(err => {
      this.$message({ message: err.msg, type: "warning" });      
    })
    
  },
  
  methods: {
    searchTypeChange(){
      if (this.searchType == 1) {
        this.ajaxDataSearch.client_company_name = "";
        this.ajaxDataSearch.account_id = "";
      }else if (this.searchType == 2) {
        this.ajaxDataSearch.io_name = "";
        this.ajaxDataSearch.account_id = "";
      }else {
        this.ajaxDataSearch.io_name = "";
        this.ajaxDataSearch.client_company_name = "";
      }
    },
    createIOMet(){
      this.$router.push({
        path:"/createIO",
        query:{
          from:'ioList',
          searchData:JSON.stringify(this.ajaxDataSearch)
        }
      })
    },
    getSectionRatio(arr){
      console.log(arr);
      var str = ''
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        str += element.start+'-'+element.end+":"+element.ratio+';'
      }
      return str
    },
    remoteMethod(query){
      this.ajaxDataSearch_custom.client_company_name = query
      this.getProjectList()
    },
    openDetail(data) {
      this.$router.push({
        name:"ioDetail",
        query:{
          id:data.id,
          from:"ioList",
          param:JSON.stringify(this.ajaxDataSearch)
        }
      })
    },
    // 搜索重置
    reset() {
      this.ajaxDataSearch = {
        page: 1,
        network: "",
        io_id: "",
        io_name: "",
        io_status: "",
        account_id: ""
      };
      this.init(1);
    },
    // 获取客户列表
    getProjectList(page) {
      this.ajaxDataSearch_custom.page = page ? page : 1
      this.axios("get", `${this.$phpBaseURL}v1/io/ioClientSelect`, this.ajaxDataSearch_custom).then(res => {
        if (res.code == 1) {
          this.projectList = res.data.data;
          this.ajaxDataSearch_custom.total = res.data.total;
          this.ajaxDataSearch_custom.page_num = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    updateValue(event) {
      this.editObj.client_company_id = event;
      this.getCustomerRebateList(1,this.editObj.client_company_id)
    },
    getCustomerRebateListSize(size){
      this.clientRebateData.page_num = size ? size : 15;
      this.getCustomerRebateList(1)
    },
    getCustomerRebateList(page,client_company_id){
      if (client_company_id) {
        this.clientRebateData.client_company_id = client_company_id
      }
      this.axios("get", `${this.$phpBaseURL}v1/io/clientRebate`, this.clientRebateData).then(res => {
        if (res.code == 1) {
          this.tableData = res.data.data;
          this.clientRebateData.total = res.data.total;
          this.clientRebateData.page_num = res.data.per_page;
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 点击编辑按钮
    editMethod(data) {
      this.editObj.id = data.id;
      this.axios("get", `${this.$phpBaseURL}v1/io/edit`, {
        id: data.id
      }).then(res => {
        if (res.code == 1) {
          this.ajaxDataSearch_custom.client_company_name = res.data.client_company_name
          this.editObj = res.data;
          this.showFlag = 2;
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    sizeInit(size){
      this.page_num = size ? size : 15;
      this.init(1)
    },
    // 获取列表
    init(page) {
      this.loading = true;
      this.ajaxDataSearch.page = page ? page : 1;
      this.ajaxDataSearch.page_num = this.page_num;
      this.axios(
        "get",
        `${this.$phpBaseURL}v1/io/lists`,
        this.ajaxDataSearch
      ).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.blackLists = res.data.data;
          this.total = res.data.total;
          this.page_num = res.data.per_page;
          this.page = res.data.current_page;
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    // 删除
    delMethod(data) {
      this.delPop = true;
      this.editObj = data;
    },
    // 确定删除
    back_del_com() {
      this.axios("post", `${this.$phpBaseURL}v1/exchange/exchange_del`, {
        id: this.editObj.id
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.delPop = false;
          this.init(1);
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    saveRate() {
      console.log(this.editObj);
      if (!this.editObj.name) {
        this.$message({ message: '请填写IO名称', type: "warning" });        
        return;
      }else if (!this.editObj.status) {
        this.$message({ message: '请选择IO状态', type: "warning" });        
        return;
      }else if (!this.editObj.client_rebate) {
        this.$message({ message: '请选择返点规则', type: "warning" });        
        return;
      }else if (!this.editObj.client_company_id) {
        this.$message({ message: '请选择关联客户', type: "warning" });        
        return;
      }else if (!this.editObj.settlement_type) {
        this.$message({ message: '请选择结算类型', type: "warning" });        
        return;
      }else if (!this.editObj.delivery_type) {
        this.$message({ message: '请选择投放类型', type: "warning" });        
        return;
      }
      var url = `${this.$phpBaseURL}v1/io/add`;
      if (this.editObj.id) {
        url = `${this.$phpBaseURL}v1/io/editSubmit`;
      }
      this.axios("post", url, this.editObj).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          this.showFlag = 1;
          this.createReset();
          this.init(1);
        } else if (res.code == 20001) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    createReset() {
      this.editObj = {
        id: "",
        name: "",
        client_rebate: 1,
        status: 1,
        delivery_type: 1,
        settlement_type: 1
      };
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-pop .center .head {
  border-bottom: none;
}
.main-pop .center .head span {
  margin: auto;
}

.openZhPop {
  .main-inputBox {
    width: 200px !important;
  }
}

::v-deep .el-dropdown-self {
  width: 100%;
}

::v-deep .el-dropdown-selfdefine {
  outline: none !important;
}
.flexd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.flexd label {
  width: 90px;
  font-size: 14px;
}
.flexd label.longlabel {
  width: 150px;
}
.poverSelect {
  background: rgba(1, 172, 228, 0.05);
}
</style>
<style>
.main-btnBox .main-button span {
  color: #01ace4 !important;
}
.date-picker-input input {
  padding-left: 30px !important;
  height: 32px !important;
}
.el-dropdown-menu-self {
  min-width: 436px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  max-height: 300px;
  overflow-y: auto;
}

.el-dropdown-menu-self li {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
}

.el-pagination-self .number {
  min-width: 20px !important;
}

.el-dropdown-menu-self li:hover {
  background: rgba(1, 172, 228, 0.05);
}
</style>