<template>
    <div class="gathermanagement main-div">
        <p class="main-title">项目管理汇总表</p>
        <div>
            <div class="main-inputBox">
                <el-input placeholder="请输入项目号" v-model="ajaxDataSearch.project_numbering" clearable @change="init()">
                </el-input>
            </div>
            <div class="main-inputBox">
                <el-input placeholder="请输入项目名称" v-model="ajaxDataSearch.project_name" clearable @change="init()">
                </el-input>
            </div>
            <div class="main-inputBox gatherdata-self-shy">
                <el-date-picker v-model="ajaxDataSearch.cycle_date"
                                @change="init()" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                type="date" clearable
                                placeholder="项目时间">
                </el-date-picker>
            </div>
            <div class="main-selectBox">
                <el-select @change="init()" clearable v-model="ajaxDataSearch.business_type" placeholder="请选择业务类型">
                    <el-option
                            v-for="(v,k) in configurationData.business_type_array"
                            :key="v.recid"
                            :label="v.st_name"
                            :value="v.recid">
                    </el-option>
                </el-select>
            </div>
            <div class="main-selectBox">
                <el-select @change="init()" clearable v-model="ajaxDataSearch.dep_id" placeholder="请选择部门">
                    <el-option
                            v-for="(v,k) in configurationData.departments_data"
                            :key="v.department_id"
                            :label="v.department_name"
                            :value="v.department_id">
                    </el-option>
                </el-select>
            </div>
            <div class="main-selectBox" style="width:160px">
                <el-select @change="init()" clearable v-model="ajaxDataSearch.project_manager_id" placeholder="请选择项目负责人">
                    <el-option
                            v-for="(v,k) in configurationData.project_principal"
                            :key="v.id"
                            :label="v.name"
                            :value="v.id">
                    </el-option>
                </el-select>
            </div>
            <div class="main-selectBox" style="width:160px">
                <el-select @change="init()" clearable v-model="ajaxDataSearch.sales_id" placeholder="请选择销售人员">
                    <el-option
                            v-for="(item,index) in configurationData.sales_list"
                            :key="index"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="main-btnBox">
            <div class="main-button" @click="addPop=true">自助建表</div>
        </div>
        <!-- 不能所有td都要设置宽度 -->
        <el-table
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%"
        >
            <template slot="empty">
                <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                </div>
            </template>
            <el-table-column label="项目基本情况" align="center" v-if="checkedColUse[1].children.business_type.checked">
                <el-table-column
                        prop=""
                        label="业务类型"
                        width="160">
                    <template slot-scope="scope">
                        <div v-for="v in configurationData.business_type_array">
                            <span style="cursor: pointer" v-if="v.recid == scope.row.business_type" :title="v.st_name">{{v.st_name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.dep_id.checked"
                                 prop=""
                                 label="部门"
                                 width="160">
                    <template slot-scope="scope">
                        <div v-for="v in configurationData.departments_data">
                            <span style="cursor: pointer" v-if="v.department_id == scope.row.dep_id" :title="v.department_name">{{v.department_name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.project_manager_id.checked"
                                 prop=""
                                 label="项目负责人"
                                 width="110">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.project_manager_id">{{scope.row.project_manager_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.sales_id.checked"
                                 prop=""
                                 label="销售人员"
                                 width="100">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.sales_id">{{scope.row.sales_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.status.checked"
                                 label="项目状态"
                                 width="100">
                    <!--<template slot-scope="scope">
                      &lt;!&ndash; 此处根据状态显示不同颜色的点 &ndash;&gt;
                      &lt;!&ndash;<i class="dot-status cancel"></i>&ndash;&gt;
                      <span>取消</span>
                    </template>-->
                    <template slot-scope="scope">
                        <div v-for="(v,k) in configurationData.project_status_array">
                            <span style="cursor: pointer" v-if="k == scope.row.status" :title="v">{{v}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.project_numbering.checked"
                                 prop=""
                                 label="项目号"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.project_numbering">{{scope.row.project_numbering}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.project_name.checked"
                                 prop=""
                                 label="项目名称"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.project_name">{{scope.row.project_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[1].children.cycle_start.checked"
                                 prop="address"
                                 label="项目执行周期"
                                 width="220">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" v-if="scope.row.cycle_start!='1970-01-01' || scope.row.cycle_end!='1970-01-01'"
                              :title="scope.row.cycle_start + '~' + scope.row.cycle_end">{{scope.row.cycle_start + '~' + scope.row.cycle_end}}</span>
                    </template>
                </el-table-column>
            </el-table-column>

            <el-table-column label="项目预算数据" align="center" v-if="checkedColUse[2].children.estimated_income.checked">
                <el-table-column
                        prop="address"
                        label="初始项目收入"
                        width="120">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.estimated_income">{{scope.row.estimated_income}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.estimated_cost.checked"
                                 prop="address"
                                 label="初始项目成本"
                                 width="120">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.estimated_cost">{{scope.row.estimated_cost}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.estimated_gross_profit.checked"
                                 prop="address"
                                 label="初始项目毛利"
                                 width="120">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.estimated_gross_profit">{{scope.row.estimated_gross_profit}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.estimated_gross_margin.checked"
                                 prop="address"
                                 label="初始项目毛利率"
                                 width="140">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.estimated_gross_margin">{{addPercentSign(scope.row.estimated_gross_margin)}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.change_money.checked"
                                 prop="address"
                                 label="其他成本金额"
                                 width="120">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.change_money">{{scope.row.change_money}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.project_estimated_cost.checked"
                                 prop="address"
                                 label="预计项目成本"
                                 width="120">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.project_estimated_cost">{{scope.row.project_estimated_cost}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.project_estimated_gross_profit.checked"
                                 prop="address"
                                 label="预计项目毛利"
                                 width="120">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.project_estimated_gross_profit">{{scope.row.project_estimated_gross_profit}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[2].children.project_estimated_gross_margin.checked"
                                 prop="address"
                                 label="预计项目毛利率"
                                 width="140">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.project_estimated_gross_margin">{{addPercentSign(scope.row.project_estimated_gross_margin)}}</span>
                    </template>
                </el-table-column>
            </el-table-column>

            <el-table-column label="项目实际数据" align="center" v-if="checkedColUse[3].children.actual_income.checked">
                <el-table-column
                        prop="address"
                        label="收入"
                        width="200"
                >
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.actual_income">{{scope.row.actual_income}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[3].children.actual_cost.checked"
                                 prop="address"
                                 label="成本"
                                 width="200"
                >
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.actual_cost">{{scope.row.actual_cost}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[3].children.actual_gross_profit.checked"
                                 prop="address"
                                 label="毛利"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.actual_gross_profit">{{scope.row.actual_gross_profit}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[3].children.actual_gross_margin.checked"
                                 prop="address"
                                 label="毛利率"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.actual_gross_margin">{{addPercentSign(scope.row.actual_gross_margin)}}</span>
                    </template>
                </el-table-column>
            </el-table-column>

            <el-table-column label="项目最终数据" align="center" v-if="checkedColUse[4].children.final_paid.checked">
                <el-table-column
                        prop="address"
                        label="报销"
                        width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.final_paid">{{scope.row.final_paid}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[4].children.final_money_back.checked"
                                 prop="address"
                                 label="回款"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.final_money_back">{{scope.row.final_money_back}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[4].children.final_cost.checked"
                                 prop="address"
                                 label="最终成本"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.final_cost">{{scope.row.final_cost}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[4].children.final_gross_profit.checked"
                                 prop="address"
                                 label="最终毛利"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.final_gross_profit">{{scope.row.final_gross_profit}}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="checkedColUse[4].children.final_gross_margin.checked"
                                 prop="address"
                                 label="最终毛利率"
                                 width="200">
                    <template slot-scope="scope">
                        <span style="cursor: pointer" :title="scope.row.final_gross_margin">{{addPercentSign(scope.row.final_gross_margin)}}</span>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
        <pagination-vue
                @currentChange="init"
                :pageSize="pageSize"
                :total="total"
        />


        <!--自助建表-->
        <div class="main-pop editPop" v-show="addPop">
            <div class="mask"></div>
            <div class="center" style="width:516px;max-height: 835px">
                <div class="head">
                    <span>自助选择项目表字段</span>
                    <i class="gongzuotai gztguanbi" @click="addPop=false"></i>
                </div>
                <div class="body" style="">
                    <div class=" inline top">
                        <p class="info-title" style="line-height: 10px">
                            <el-checkbox v-model="all" @change="allCheck(all)">全部</el-checkbox>
                        </p>
                        <br>
                        <div>
                            <div class="main-inputBox" style="padding-left: 20px;width: 445px;height: 300px;overflow-y: scroll;">
                                <div v-for="v in checkedCol">
                                    <p class="info-title">{{v.title}}:</p>
                                    <div></div>
                                    <p style="display: inline-block;margin: 8px" v-for="(child,k) in v.children">
                                        <el-checkbox v-model="child.checked" @change="checkboxChange(child.checked)">{{child.title}}</el-checkbox>

                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="foot">
                    <button class="main-button l" @click="addPop=false">取消</button>
                    <button class="main-button r" @click="addPop=false;doCheck()">确定</button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import paginationVue from '@/components/paginationVue.vue'
    import axios from 'axios';
    import store from '@/store/index.js';
    export default {
        name: "gatherManament",
        components: {
            paginationVue
        },
        data(){
            return {
                loading:true,
                timer: '',
                pageSize: 15,
                total: 0,
                ajaxDataSearch: {
                    project_numbering: '',//	否	string	项目编号就是单据编号；如: SCBJXH202000001
                    project_name: '',//	否	string	项目名称；如：lxn项目
                    business_type: '',//	否	int	业务类型(1整合营销 2媒介投放 3技术产品)(报价单中字段)；如：15
                    dep_id: '',//	否	string	部门id；如：001867748f27465982ab4c87c7d3c8a0
                    project_manager_id: '',//	否	string	负责人id；如：4F81D19F600000011924017E51357265
                    sales_id: '',//	否	string	销售人；如：4F81D19F600000011924017E51357265
                },
                tableData: [
                    {
                        "business_type": '717718FF000000016E2A9380C3DB3465',
                        "dep_id": "6F6669A5800000011CC37F23010E6404",
                        "project_manager_id": "4F81D19F600000011924017E51357265",
                        "sales_id": "4F81D19F600000011924017E51357265",
                        "status": 1,
                        "project_numbering": "SCBJXH202000001",
                        "project_name": "lxn项目01",
                        "cycle_start": "2020-04-23",
                        "cycle_end": "2020-04-23",
                        "change_ratio": "0.00",
                        "estimated_income": "0.00",
                        "estimated_cost": "0.00",
                        "estimated_gross_profit": "0.00",
                        "estimated_gross_margin": "0.00",
                        "change_money": "0.00",
                        "project_estimated_cost": "0.00",
                        "project_estimated_gross_profit": "0.00",
                        "project_estimated_gross_margin": "0.00",
                        "actual_income": "0.00",
                        "actual_cost": "0.00",
                        "actual_gross_profit": "0.00",
                        "actual_gross_margin": "0.00",
                        "final_paid": "0.00",
                        "final_money_back": "0.00",
                        "final_cost": "0.00",
                        "final_gross_profit": "0.00",
                        "final_gross_margin": "0.00"
                    }
                ],

                /*返回客户管理下搜索项用到的数据接口*/
                configurationData: {
                    "receivable_client_status": {
                        /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
                    },
                    "is_overdue_config": {
                        /*"1": "是",
                         "2": "否"*/
                    },
                    "client_status_array": {
                        /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
                    },
                    "client_source_array": {
                        /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
                    },
                    "client_connection_relation_array": {
                        /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
                    },
                    "client_relation_company_array": {
                        /* "1": "拥有者",
                         "2": "共享者"*/
                    },
                    "is_contain_config": {
                        /*"1": "有",
                         "2": "无"*/
                    },
                    "platform_array": {
                        /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
                    },
                    "contact_status_array": {
                        /*"1": "审批通过",
                         "2": "审批失败"*/
                    },
                    "gender_array": {
                        /*"1": "男",
                         "2": "女"*/
                    },
                    "contract_status_array": {
                        /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
                    },
                    "business_type_array": {
                        /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
                    },
                    "contract_type_array": {
                        /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
                    },
                    "contract_subdivision_array": {
                        /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
                    },
                    "is_guarantee_archive_prepaid_array": {
                        /*"1": "是",
                         "2": "否"*/
                    },
                    "project_status_array": {
                        /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
                    },
                    "project_approval_status": {
                        /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
                    },
                    "file_upload_status": {
                        /*"1": "上传成功",
                         "2": "上传失败"*/
                    },
                    "platform_name_array": {
                        /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
                    },
                    "return_point_ratio_accuracy_array": {
                        /*"1": "预估",
                         "2": "确认"*/
                    },
                    "departments_data": [
                        /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
                    ],
                    "industry_data": [
                        /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
                    ]
                },

                configurationData2: [
                    {
                        "id": "6BD47F61E0000001D1CC52983B488BD4",
                        "name": "卢婷婷"
                    }
                ],

                addPop: false,
                all: true,
                unCheckCount: 0,
                checkedCol: {
                    1: {
                        title: '项目基本情况',
                        children: {
                            business_type: {
                                checked: true,
                                title: '业务类型'
                            },
                            dep_id: {
                                checked: true,
                                title: '部门'
                            },
                            project_manager_id: {
                                checked: true,
                                title: '项目负责人'
                            },
                            sales_id: {
                                checked: true,
                                title: '销售人员'
                            },
                            status: {
                                checked: true,
                                title: '项目状态'
                            },
                            project_numbering: {
                                checked: true,
                                title: '项目号'
                            },
                            project_name: {
                                checked: true,
                                title: '项目名称'
                            },
                            cycle_start: {
                                checked: true,
                                title: '项目执行周期'
                            },
                        }
                    },
                    2: {
                        title: '项目预算数据',
                        children: {
                            estimated_income: {
                                checked: true,
                                title: '初始项目收入'
                            },
                            estimated_cost: {
                                checked: true,
                                title: '初始项目成本'
                            },
                            estimated_gross_profit: {
                                checked: true,
                                title: '初始项目毛利'
                            },
                            estimated_gross_margin: {
                                checked: true,
                                title: '初始项目毛利率'
                            },
                            change_money: {
                                checked: true,
                                title: '其他成本金额'
                            },
                            project_estimated_cost: {
                                checked: true,
                                title: '预计项目成本'
                            },
                            project_estimated_gross_profit: {
                                checked: true,
                                title: '预计项目毛利'
                            },
                            project_estimated_gross_margin: {
                                checked: true,
                                title: '预计项目毛利率'
                            },
                        }
                    },
                    3: {
                        title: '项目实际数据',
                        children: {
                            actual_income: {
                                checked: true,
                                title: '收入'
                            },
                            actual_cost: {
                                checked: true,
                                title: '成本'
                            },
                            actual_gross_profit: {
                                checked: true,
                                title: '毛利'
                            },
                            actual_gross_margin: {
                                checked: true,
                                title: '毛利率'
                            },
                        }
                    },
                    4: {
                        title: '项目最终数据',
                        children: {
                            final_paid: {
                                checked: true,
                                title: '报销'
                            },
                            final_money_back: {
                                checked: true,
                                title: '回款'
                            },
                            final_cost: {
                                checked: true,
                                title: '最终成本'
                            },
                            final_gross_profit: {
                                checked: true,
                                title: '最终毛利'
                            },
                            final_gross_margin: {
                                checked: true,
                                title: '最终毛利率'
                            },
                        }
                    },
                    /*business_type:true,
                     dep_id:true,
                     project_manager_id:true,
                     sales_id:true,
                     status:true,
                     project_numbering:true,
                     project_name:true,
                     cycle_start:true,
                     cycle_end:true,
                     change_ratio:true,
                     estimated_income:true,
                     estimated_cost:true,
                     estimated_gross_profit:true,
                     estimated_gross_margin:true,
                     change_money:true,
                     project_estimated_cost:true,
                     project_estimated_gross_profit:true,
                     project_estimated_gross_margin:true,
                     actual_income:true,
                     actual_cost:true,
                     actual_gross_profit:true,
                     actual_gross_margin:true,
                     final_paid:true,
                     final_money_back:true,
                     final_cost:true,
                     final_gross_profit:true,
                     final_gross_margin:true,*/
                },
                checkedColUse: {
                    1: {
                        title: '项目基本情况',
                        children: {
                            business_type: {
                                checked: true,
                                title: '业务类型'
                            },
                            dep_id: {
                                checked: true,
                                title: '部门'
                            },
                            project_manager_id: {
                                checked: true,
                                title: '项目负责人'
                            },
                            sales_id: {
                                checked: true,
                                title: '销售人员'
                            },
                            status: {
                                checked: true,
                                title: '项目状态'
                            },
                            project_numbering: {
                                checked: true,
                                title: '项目号'
                            },
                            project_name: {
                                checked: true,
                                title: '项目名称'
                            },
                            cycle_start: {
                                checked: true,
                                title: '项目执行周期'
                            },
                        }
                    },
                    2: {
                        title: '项目预算数据',
                        children: {
                            estimated_income: {
                                checked: true,
                                title: '初始项目收入'
                            },
                            estimated_cost: {
                                checked: true,
                                title: '初始项目成本'
                            },
                            estimated_gross_profit: {
                                checked: true,
                                title: '初始项目毛利'
                            },
                            estimated_gross_margin: {
                                checked: true,
                                title: '初始项目毛利率'
                            },
                            change_money: {
                                checked: true,
                                title: '其他成本金额'
                            },
                            project_estimated_cost: {
                                checked: true,
                                title: '预计项目成本'
                            },
                            project_estimated_gross_profit: {
                                checked: true,
                                title: '预计项目毛利'
                            },
                            project_estimated_gross_margin: {
                                checked: true,
                                title: '预计项目毛利率'
                            },
                        }
                    },
                    3: {
                        title: '项目实际数据',
                        children: {
                            actual_income: {
                                checked: true,
                                title: '收入'
                            },
                            actual_cost: {
                                checked: true,
                                title: '成本'
                            },
                            actual_gross_profit: {
                                checked: true,
                                title: '毛利'
                            },
                            actual_gross_margin: {
                                checked: true,
                                title: '毛利率'
                            },
                        }
                    },
                    4: {
                        title: '项目实际数据',
                        children: {
                            final_paid: {
                                checked: true,
                                title: '报销'
                            },
                            final_money_back: {
                                checked: true,
                                title: '回款'
                            },
                            final_cost: {
                                checked: true,
                                title: '最终成本'
                            },
                            final_gross_profit: {
                                checked: true,
                                title: '最终毛利'
                            },
                            final_gross_margin: {
                                checked: true,
                                title: '最终毛利率'
                            },
                        }
                    },
                },
            }
        },
        created() {
            /*返回客户管理下搜索项用到的数据接口*/
            axios.defaults.headers.common['language'] = store.state.lang;
            axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;
            this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {
                type: 'project_principal,sales_list'
            }).then(res => {
//                console.log(res.data)
                if (res.code == 1) {
                    this.configurationData = res.data;

                    this.search_item_data();

                    this.init();

                    this.timer = setTimeout(this.addStatistics, 5000);


                } else if(res.code == 2){

                    //token过期处理
                    this.common.tokenExpired(res.msg);

                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }
            });


            //点击回车进行搜索
            let that=this;
            document.onkeypress = function(e) {
                var keycode = document.all ? event.keyCode : e.which;
                if (keycode == 13) {
                    that.init();
                    return false;
                }
            };
        },
        methods: {
            init(page){
                this.loading=true;
                clearTimeout(this.timer);

                this.ajaxDataSearch.page = page ? page : 1;
                this.axios('get', `${this.$phpBaseURL}v1/project/summary`, this.ajaxDataSearch).then(res => {
                    this.loading=false;
//                        console.log(res.data)
                    if (res.code == 1) {
                        this.tableData = res.data.data;
                        this.total = res.data.total;
                        //                    this.pageSize = res.data.per_page;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            search_item_data(){

                /*搜索项下拉框：销售，优化师，AM 用户数据 1-销售，2-优化师，3-AM*/
                this.axios('POST', `${this.$phpBaseURL}v1/config/roleUserdatas`, {
                    type: 1
                }).then(res => {
//                console.log(res.data)
                    if (res.code == 1) {
                        this.configurationData2 = res.data;
                    } else if(res.code == 2){

                        //token过期处理
                        this.common.tokenExpired(res.msg);

                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });
            },
            //添加百分号
            addPercentSign(val){
                if (!val) {
                    val = '0.00';
                }
                return val + '%';
            },
            checkboxChange(flag){
                !flag ? (this.unCheckCount += 1) : (this.unCheckCount -= 1);
                if (this.unCheckCount > 0) {
                    this.all = false;
                } else {
                    this.all = true;
                }
            },
            allCheck(flag){
                for (let i in this.checkedCol) {
                    for (let o in this.checkedCol[i].children) {
                        this.checkedCol[i].children[o].checked = flag;
                    }
                }
            },
            doCheck(){
                this.checkedColUse = JSON.parse(JSON.stringify(this.checkedCol));
                console.log(this.checkedColUse)
//            this.init()
            },

            //频次统计
            addStatistics() {
                this.common.statisticsAdd('项目管理汇总表列表','1')
            },

        },
        //mounted 挂载结束状态
        mounted() {
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>

<style scoped lang="scss">
    .gathermanagement .dot-status {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        &.cancel {
            background: #ADB3BC;
        }
        &.normal {
            background: #26C4B8;
        }
        &.stop {
            background: #F7B94D;
        }
        &.delay {
            background: #FE6664;
        }
    }
</style>