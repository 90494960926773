<template>
  <div class="main-div contactsList">
    <div v-show="showFlag==1">
      <p class="main-title">联系人列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.contact_name"
            clearable
            placeholder="请输入联系人名称"
            @change="enterSearch"
          >
          </el-input>
        </div>
        <div class="main-inputBox daterange" style="width: 255px!important;">
          <el-date-picker
            v-model="ajaxDataSearch.contact_created_at"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="创建开始日期"
            clearable
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.client_company_id"
            @change="enterSearch"
            clearable
            placeholder="所属公司"
          >
            <el-option
              :title="item.st_name"
              v-for="item in configurationData.affiliated_company"
              :key="item.client_company_id"
              :label="item.st_name"
              :value="item.client_company_id"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.create_people_name"
            @change="enterSearch"
            clearable
            placeholder="创建人"
          >
            <el-option
              v-for="item in configurationData.contact_create_people_name"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

      </div>
      <div style="margin-bottom: 32px">
        <div
          class="main-button"
          v-show="common.permFilter(`${common.php}v1/clientContact/getNewContact`)"
          @click="common.openEip('clientContact/getNewContact')"
        >新建联系人</div>
      </div>
      <div v-loading="loading">
        <el-table :data="contactList" border height="550" style="width:100%" :fit="true">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop="contact_name" label="联系人" width="220"></el-table-column>
          <el-table-column prop="phone" label="手机号码" width="180"></el-table-column>
          <el-table-column prop="client_company_name" label="所属公司" min-width="220">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.client_company_name"
              >{{scope.row.client_company_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dep_name" label="所属部门" width="300"></el-table-column>
          <el-table-column prop="job" label="职务" width="180"></el-table-column>
          <el-table-column prop="create_people_name" label="创建人" width="120"></el-table-column>
          <el-table-column prop label="创建时间" width="150">
            <template slot-scope="scope">{{scope.row.contact_created_at | formatTime}}</template>
          </el-table-column>
          <el-table-column prop label="操作" width="200">
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                v-show="common.permFilter(`${common.php}v1/clientContact/detail`)"
                @click="showFlag=2;getInfo(scope.row.contact_id)"
              >联系人信息</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="ajaxDataSearch.page_num"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag==2">
      <p class="main-title">联系人信息</p>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">客户跟进人:</p>
            <div class="info-text calc-5w">{{info.client_follower_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">联系人姓名:</p>
            <div class="info-text calc-5w">{{info.contact_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-5w">联系人性别:</p>
            <div class="info-text calc-5w">{{configurationData.gender_array[info.gender]}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">所属公司:</p>
            <div class="info-text calc-4w">{{info.client_company_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">所属部门:</p>
            <div class="info-text calc-4w">{{info.department_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">职务:</p>
            <div class="info-text calc-2w">{{info.job}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">联系电话:</p>
            <div class="info-text calc-4w">{{info.phone}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">公司邮箱:</p>
            <div class="info-text calc-4w">{{info.email}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-3w">所在地:</p>
            <div class="info-text calc-3w">{{info.location}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-7w">上级负责人姓名:</p>
            <div class="info-text calc-7w">{{info.superior_principal_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">上级负责人性别:</p>
            <div
              class="info-text calc-7w"
            >{{configurationData.gender_array[info.superior_principal_gender]}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-80">
            <p class="info-title t-2w">备注:</p>
            <div class="info-text calc-2w">{{info.remark}}</div>
          </div>
        </div>
        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!-- <p class="info-title t-2w"></p> -->
            <div class="info-text calc-2w">
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import moment from "moment";
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      is_refresh: false,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 100,
      ajaxDataSearch: {
        contact_id: "", //	否	string	联系人ID；如： 715408DE60000001F2208D09661F0C8D
        contact_name: "", //	否	string	联系人名称；如：深圳市宏海飞扬科技有限公司
        contact_created_at: ["", ""], //	否	string	联系人创建日期；如：2020-04-13
        client_company_id: "", //	否	string	所属公司；如：715408DE60000001F2208D09661F0C8D
        create_people_name: "", //	否	string	创建人；如：lixuenan
        contact_create_people_name: "",
        status: "",
        page_num:15
      },
      optionsTrade: [],
      optionsStatus: [
        {
          value: "0",
          label: "待审批"
        },
        {
          value: "1",
          label: "审批通过"
        },
        {
          value: "2",
          label: "审批失败"
        }
      ],
      contactList: [
        
      ],
      info: {
        client_follower_name: "测试",
        contact_name: "cscs",
        gender: "男",
        job: "cxy",
        phone: 123,
        email: "1",
        location: "上海",
        superior_principal_name: "cs",
        superior_principal_gender: "女",
        remark: "此生此世",
        client_company_name: "cscs",
        departments_name: "cs",
        st_name: "cs"
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      }
    };
  },

  filters: {
    formatTime(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回未提交";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    var query=this.$route.query;
    if (query.contact_name) {
        this.ajaxDataSearch.contact_name = query.contact_name
    }

    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {
      type: "client_contact_person"
    }).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;

        this.init();
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    if (!this.is_refresh) {
      this.timer = setTimeout(this.addStatistics, 5000);
    }

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },

  computed: {
    curRouter() {
      return this.$store.state.curRouter;
    }
  },
  watch: {
    curRouter(val) {
      if (val) {
        this.showFlag = 1;
      }
      console.log("val:", val);
    }
  },
  methods: {
    sizeInit(size){
      this.ajaxDataSearch.page_num = size ? size : 15;
      this.init(1)
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      var startDate = "";
      var endDate = "";
      if (this.ajaxDataSearch.contact_created_at) {
        startDate = this.ajaxDataSearch.contact_created_at[0];
        endDate = this.ajaxDataSearch.contact_created_at[1];
      }
      this.axios("POST", `${this.$phpBaseURL}v1/clientContact/lists`, {
        contact_id: this.ajaxDataSearch.contact_id, //	否	string	联系人ID；如： 715408DE60000001F2208D09661F0C8D
        contact_name: this.ajaxDataSearch.contact_name, //	否	string	联系人名称；如：深圳市宏海飞扬科技有限公司
        start_date: startDate, //	否	string	搜索合同的创建日期；如：2020-04-23
        end_date: endDate,
        client_company_id: this.ajaxDataSearch.client_company_id, //	否	string	所属公司；如：715408DE60000001F2208D09661F0C8D
        create_people_name: this.ajaxDataSearch.create_people_name, //	否	string	创建人；如：715408DE60000001F2208D09661F0C8D
        status: this.ajaxDataSearch.status,
        page: page ? page : 1,
        page_num:this.ajaxDataSearch.page_num
      }).then(res => {
        this.loading = false;
        //                        console.log(res.data)
        if (res.code == 1) {
          this.contactList = res.data.data;
          this.total = res.data.total;
          this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;

      if (this.ajaxDataSearch.contact_created_at == null) {
        this.ajaxDataSearch.contact_created_at = ["", ""];
      }
      this.init();
    },
    /*详情*/
    getInfo(id) {
      this.$store.commit("curRouter", false);
      this.axios("POST", `${this.$phpBaseURL}v1/clientContact/detail`, {
        contact_id: id
      }).then(res => {
        //                    console.log(res.data)
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },

    //频次统计
    addStatistics() {
      this.common.statisticsAdd("联系人列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
<style>
.contactsList .main-tableToInfo {
  /* white-space: nowrap;
    display: block;
    min-width: 200px; */
}
</style>