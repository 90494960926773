<template>
  <div class="main-div">
    <div v-show="showFlag==1">
      <p class="main-title">供应商列表</p>
      <div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.name"
            placeholder="请输入供应商名称"
            clearable
            @change="enterSearch"
          ></el-input>
        </div>
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.username"
            placeholder="请输入创建人"
            clearable
            @change="enterSearch"
          ></el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.level"
            @change="enterSearch"
            clearable
            placeholder="供应商级别"
          >
            <el-option
              v-for="(v,k) in configurationData.supplier_level_array"
              :key="v.recid"
              :label="v.st_name"
              :value="v.recid"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.status"
            @change="enterSearch"
            clearable
            placeholder="供应商状态"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.service_type"
            @change="enterSearch"
            clearable
            placeholder="服务类型"
          >
            <el-option
              v-for="(v,k) in configurationData.service_type_array"
              :key="v.recid"
              :label="v.st_name"
              :value="v.recid"
            ></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.scale"
            @change="enterSearch"
            clearable
            placeholder="供应商规模"
          >
            <el-option
              v-for="(v,k) in configurationData.personnel_size_array"
              :key="v.recid"
              :label="v.st_name"
              :value="v.recid"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox daterange" style="width: 255px!important;">
          <el-date-picker
            v-model="ajaxDataSearch.contract_created_at"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="创建开始日期"
            clearable
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
      </div>
      <div style="margin-bottom: 32px">
        <div
          class="main-button"
          v-show="common.permFilter(`${common.php}v1/suppliers/getNewSupplier`)"
          @click="common.openEip('suppliers/getNewSupplier')"
        >新建供应商</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/suppliers/getSupplierApproval`)"
          @click="common.openEip('suppliers/getSupplierApproval')"
        >供应商审批</div>
      </div>
      <div v-loading="loading">
        <el-table :data="list" border height="550" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop label="供应商名称" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.name">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="level_name" label="供应商级别" width="150"></el-table-column>
          <el-table-column prop label="供应商状态" width="150">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :class="scope.row.status | statusClassFilter"
                :title="scope.row.status"
              >{{scope.row.status | checkStatus}}</span>
              <!--{{scope.row.business_type}}
              {{configurationData.business_type_array[scope.row.business_type]}}-->
              <!--                            <div v-for="v in configurationData.business_type_array">-->
              <!--                                <span v-if="v.recid == scope.row.business_type">{{v.st_name}}</span>-->
              <!--                            </div>
                            状态1：待审核2：合作中3：冻结4：审核失败
              -->
              <!-- <span style="cursor: pointer" v-if="scope.row.status == 0" title="未提交">未提交</span>
                            <span style="cursor: pointer" v-if="scope.row.status == 1" title="待审批">待审批</span>
                            <span style="cursor: pointer" v-if="scope.row.status == 2" title="审批通过">审批通过</span>
              <span style="cursor: pointer" v-if="scope.row.status == 5" title="驳回未提交">驳回未提交</span>-->
            </template>
          </el-table-column>
          <el-table-column prop label="服务类型" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.service_type_names"
              >{{scope.row.service_type_names}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="规模" width="200">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.scale_name">{{scope.row.scale_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="创建时间" width="200">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.created_at">{{scope.row.created_at}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="username" label="创建人" width="150"></el-table-column>
          <el-table-column prop label="操作" min-width="150">
            <template slot-scope="scope">
              <span class="main-tableToInfo" @click="showFlag=2;getInfo(scope.row.supplier_id)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <div v-show="showFlag==2">
      <p class="main-title" style="display: inline-block">编号：{{info.number}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">创建时间：{{info.created_at}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">申请部门：{{info.department_name}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">申请人：{{info.username}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-5w">供应商名称:</p>
            <div class="info-text calc-5w">{{info.name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-3w">联系人:</p>
            <div class="info-text calc-3w">{{info.contact}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">注册资金:</p>
            <div class="info-text calc-4w">{{info.reg_money}}万</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">联系电话:</p>
            <div class="info-text calc-4w">{{info.phone}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">成立时间:</p>
            <div class="info-text calc-4w">{{info.establish_date}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">邮箱:</p>
            <div class="info-text calc-2w">{{info.email}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">人员规模:</p>
            <div class="info-text calc-4w">{{info.scale_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">其他联系方式:</p>
            <div class="info-text calc-6w">{{info.other_phone}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">注册地址:</p>
            <div class="info-text calc-4w">{{info.reg_address}}</div>
          </div>
        </div>
        <div class="info-row detail-line">
          <div class="info-cell w-33">
            <p class="info-title t-8w">统一社会信用代码:</p>
            <div class="info-text calc-8w">{{info.credit_code}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">实际工作地址:</p>
            <div class="info-text calc-6w">{{info.work_address}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">其他:</p>
            <div class="info-text calc-2w">{{info.other_desc}}</div>
          </div>
        </div>
      </div>

      <div class="main-title_1">服务内容</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">服务类型:</p>
            <div class="info-text calc-4w">{{info.service_type_names}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">服务客户清单:</p>
            <div class="info-text calc-6w">{{info.service_client_list}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">服务擅长领域:</p>
            <div class="info-text calc-6w">{{info.service_good}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-14w">案例及合作价格附件内容描述:</p>
            <div class="info-text calc-14w">{{info.service_desc}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">其他:</p>
            <div class="info-text calc-2w">{{info.service_other}}</div>
          </div>
        </div>

        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">附件:</p>
            <div class="info-text calc-6w">
              <el-table :data="info.files" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="180"></el-table-column>
                <el-table-column prop="file_name" label="文件" width></el-table-column>

                <el-table-column prop label="文件大小" width>
                  <template
                    slot-scope="scope"
                    v-if="scope.row.file_size"
                  >{{(scope.row.file_size / 1024 / 1024).toFixed(2)}}MB</template>
                  <template v-else>-</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="main-title_1">财务信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">银行账户名称:</p>
            <div class="info-text calc-6w">{{info.bank_account_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">发票税率:</p>
            <div class="info-text calc-4w">{{info.invoice_tax_rate_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">银行账号:</p>
            <div class="info-text calc-4w">{{info.bank_account}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">付款周期:</p>
            <div class="info-text calc-4w">{{info.payment_cycle_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-3w">开户行:</p>
            <div class="info-text calc-3w">{{info.opening_bank}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">垫资能力:</p>
            <div class="info-text calc-4w">{{info.fund_situation}}万</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-2w">支行:</p>
            <div class="info-text calc-2w">{{info.subbranch}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">预付比例:</p>
            <div class="info-text calc-4w">{{info.prepay_proportion_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">其他:</p>
            <div class="info-text calc-2w">{{info.bank_other_desc}}</div>
          </div>
        </div>

        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!--<p class="info-title t-6w"></p>-->
            <div class="info-text calc-6w">
              <div
                class="download-self main-button"
                v-show="common.permFilter(`${common.php}v1/suppliers/supplierDownload`)"
                @click="downloadfile()"
              >
                <i class="gongzuotai gztxiazai"></i>
                下载
              </div>
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 15,
      total: 1,
      ajaxDataSearch: {
        name: "", //	否	string	供应商名称
        username: "", //	否	string	创建人
        level: "", //	否	string	供应商等级主键id，下拉框中的key值
        status: "", //	否	int	状态1：待审核2：合作中3：冻结4：审核失败
        service_type: "", //	否	string	服务类型
        scale: "", //	否	string	人员规模主键id
        contract_created_at: ["", ""] //	否	string
      },
      levelOptions: [],
      statusOptions: [
        /*状态1：待审核2：合作中3：冻结4：审核失败*/
        {
          value: 0,
          label: "未提交"
        },
        {
          value: 1,
          label: "待审核"
        },
        {
          value: 2,
          label: "审核通过"
        },
        {
          value: 5,
          label: "驳回未提交"
        }
      ],
      list: [
        {
          id: 30,
          name: "明日之后",
          level: "710F493CC00000010AD24038C1967B5E",
          status: 2,
          service_type:
            ",710A50ED20000001E6F3D7C6047F39AB,710A511920000001AAAE0F80FC69A3D2,710A518160000001123DB454EC548940,",
          scale_name: "200-300人",
          created_at: "2020-05-22 12:06:47",
          username: "xiaojianbin",
          level_name: "优选",
          service_type_names: "emc机构/电商运营/红包充值"
        }
      ],
      info: {
        id: 2,
        supplier_id: "7133D0E800000001F4FF73776DCFE541",
        number: "GYSSQ02220120200004",
        dept_id: "6F666DF4C00000017E45C85CF11A6756",
        applicant: "5AB1EE2680000001D05C302118DAFDCC",
        name: "123",
        contact: "刘",
        reg_money: "1000万",
        phone: "15501111111",
        establish_date: "2020-04-01",
        email: "ha@126.com",
        scale: "710A59AE000000012E3936E66DF8BE89",
        other_phone: "",
        reg_address: "北京东城",
        credit_code: "51360827356549264E",
        work_address: "亦庄",
        other_desc: "",
        status: 0,
        service_type: "710A546C40000001847A5033E1D120AE",
        service_client_list: "abc",
        service_good: "微信",
        service_desc: null,
        service_other: null,
        bank_account_name: "123",
        invoice_tax_rate: "710A5ED740000001143F939376B90026",
        bank_account: "1010101010101010",
        payment_cycle: "710A6092800000017EB7A730438B1C3F",
        opening_bank: "招商银行",
        subbranch: "招商银行一桩",
        fund_situation: 2,
        prepay_proportion: "710A627C6000000198CFFAB859E55135",
        bank_other_desc: "",
        created_at: "2020-05-07 15:52:23",
        updated_at: "2020-05-07 15:52:23",
        level: "710F4966400000019703C52C7A4CEA14",
        username: null,
        department_name: "数传国内供应商管理部",
        scale_name: "20-30人",
        service_type_names: "框架采购",
        invoice_tax_rate_name: "6%",
        payment_cycle_name: "60-90天",
        prepay_proportion_name: "100%",
        level_name: "合格",
        files: [
          {
            id: 58325,
            supplier_id: "7133D0E800000001F4FF73776DCFE541",
            accessory_id: "4EF2FD50EA88AB681033A4E00071604F",
            file_name: "灿烂星光&瑞意-小度年货节-内容营销服务协议.docx",
            file_size: null,
            created_at: "2020-05-07 16:02:41"
          }
        ]
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      }
    };
  },
  filters: {
    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'

      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回待提交";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    }
  },
  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });
    this.init();

    this.timer = setTimeout(this.addStatistics, 5000);

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  computed: {
    curRouter() {
      return this.$store.state.curRouter;
    }
  },
  watch: {
    curRouter(val) {
      if (val) {
        this.showFlag = 1;
      }
      console.log("val:", val);
    }
  },
  methods: {
    sizeInit(size){
      this.pageSize = size ? size : 15
      this.init(1)
    },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      var startDate = "";
      var endDate = "";
      if (this.ajaxDataSearch.contract_created_at) {
        startDate = this.ajaxDataSearch.contract_created_at[0];
        endDate = this.ajaxDataSearch.contract_created_at[1];
      }
      this.axios("get", `${this.$phpBaseURL}v1/suppliers/list`, {
        name: this.ajaxDataSearch.name, //	否	string	供应商名称
        username: this.ajaxDataSearch.username, //	否	string	创建人
        level: this.ajaxDataSearch.level, //	否	string	供应商等级主键id，下拉框中的key值
        status: this.ajaxDataSearch.status, //	否	int	状态1：待审核2：合作中3：冻结4：审核失败
        service_type: this.ajaxDataSearch.service_type, //	否	string	服务类型
        scale: this.ajaxDataSearch.scale, //	否	string	人员规模主键id
        start_date: startDate, //	否	string	创建开始日期
        end_date: endDate, //	否	string	创建结束日期
        offset: page ? (page - 1) * this.pageSize : 0, //	否	int	获取数据开始位置，默认0
        page_num: this.pageSize //否	int	每次获取条数，默认10
      }).then(res => {
        this.loading = false;
        //                        console.log(res.data)
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
          //                    this.pageSize = res.data.per_page;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      if (this.ajaxDataSearch.contract_created_at == null) {
        this.ajaxDataSearch.contract_created_at = ["", ""];
      }
      this.init();
    },
    /*详情*/
    getInfo(id) {
      this.supplier_id = id;
      this.$store.commit("curRouter", false);
      this.axios("get", `${this.$phpBaseURL}v1/suppliers/info`, {
        supplier_id: id
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //供应商附件下载
    downloadfile() {
      let token = JSON.parse(localStorage.getItem("user_info")).token;
      let url = `${this.$phpBaseURL}v1/suppliers/supplierDownload?token=${token}&supplier_id=${this.info.supplier_id}`;
      window.open(url, "_blank");
    },

    //频次统计
    addStatistics() {
      this.value++;
      this.common.statisticsAdd("供应商列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>