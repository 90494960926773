<template>
  <div class="main-div frequencyStatistics">
    <p class="main-title">FB封户账户</p>
    <div>
      <div class="main-inputBox">
        <el-input
          v-model="queryOptions.eipCompanyName"
          clearable
          placeholder="eip公司名称"
        >
        </el-input>
      </div>
      <div class="main-inputBox">
        <el-input
          v-model="queryOptions.accountId"
          clearable
          placeholder="账户ID"
        >
        </el-input>
      </div>
      <div class="main-selectBox">
        <el-select
          v-model="queryOptions.deliveryType"
          clearable
          placeholder="投放类型"
        >
          <el-option
            v-for="(item,index) in deliveryList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="main-inputBox daterange" style="width: 255px!important;">
        <el-date-picker
          v-model="timeRange"
          @change="changeQuery"
          @keyup.enter.native="changeQuery"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="开始日期"
          clearable
          end-placeholder="结束日期"
          placeholder="封户时间"
        ></el-date-picker>
      </div>
      <div style="margin-bottom:20px;">
        <div class="main-button" @click="getList(1)">搜索</div>
        <div class="main-button" @click="reset()">重置</div>
        <div class="main-button" @click="exportList()">导出</div>
      </div>
    </div>

    <div v-loading="loading">
      <el-table :data="list" border height="550" style="width: 100%;">
        <template slot="empty">
          <div class="nodata">
            <img src="@/assets/img/nodata.png">
            <span>暂无数据</span>
          </div>
        </template>
        <el-table-column prop="eipCompanyName" label="eip公司名称"></el-table-column>
        <el-table-column prop="clientCompanyName" label="客户公司名称"></el-table-column>
        <el-table-column prop="accountName" label="账户名称"></el-table-column>
        <el-table-column prop="accountId" label="账户ID"></el-table-column>
        <el-table-column label="投放类型">
          <template slot-scope="scope">
            {{scope.row.deliveryType == 1 ? '自投' : '代投'}}
          </template>
        </el-table-column>
        <el-table-column label="封户情况">
          <template>
            <!-- {{scope.row.deliveryType == 1 ? '自投' : '代投'}} -->
            {{'封户'}}
          </template>
        </el-table-column>
        <el-table-column prop="closedType" label="封户方式"></el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" label="结束时间"></el-table-column>
        <el-table-column prop="totalCost" label="截至目前消耗"></el-table-column>
      </el-table>
      <pagination-vue
        @currentSizeChange="sizeInit"
        @currentChange="getList"
        :pageSize="queryOptions.pageSize"
        :total="total"
        :firstPage="firstPage"
      />
    </div>
  </div>
</template>

<script>
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
import moment from "moment";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      list: [],
      curPageNum: "",
      timeRange: [], //时间段
      configurationData: [],
      total: 0,
      page_total: 0,
      queryOptions: {
        eipCompanyName: "",
        accountId:"",
        deliveryType:"",
        startTime:"",
        endTime:"",
        pageSize:15,
        pageNum: 1
      },
      deliveryList:[
        {
          value:1,
          label:'自投'
        },
        {
          value:2,
          label:'代投'
        }
      ],
    };
  },
  created() {
    this.getList()
  },
  computed: {},
  methods: {
    sizeInit(size){
      this.queryOptions.pageSize = size ? size : 1;
      this.getList(1)
    },
    getList(page) {
      this.loading = true;
      this.firstPage = page;
      //获取日志接口
      this.queryOptions.pageNum = page ? page : 1;
      this.axios(
        "get",
        `${this.$javaBaseURL}accountClose/select`,
        this.queryOptions
      ).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total
        } else if (res.code == 2) {
          this.common.tokenExpired(res.msg);
        }
      });
    },
    changeQuery(data) {
      this.firstPage = 1;
      this.queryOptions.startTime = data && data[0] ? data[0] : "";
      this.queryOptions.endTime = data && data[1] ? data[1] : "";
      this.getList(1);
    },
    exportList(){
      window.open(`${this.$javaBaseURL}accountClose/export?eipCompanyName=${this.queryOptions.eipCompanyName}&accountId=${this.queryOptions.accountId}&deliveryType=${this.queryOptions.deliveryType}&startTime=${this.queryOptions.startTime}&endTime=${this.queryOptions.endTime}`)
    },
    reset(){
      this.queryOptions = {
        eipCompanyName: "",
        accountId:"",
        deliveryType:"",
        startTime:"",
        endTime:"",
        pageSize:10,
        pageNum: 1
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep {
  .append-row {
    border-top: solid 1px #ebeef5;
    border-bottom: none;
  }
}
</style>
<style>
.append-row {
  border-bottom: 1px solid #ebeef5;
  display: flex;
}
.append-row .summary {
  flex: auto;
  text-indent: 12px;
  line-height: 48px;
}
.append-row .sum-number {
  flex: 0 0 150px;
  width: 150px;
  border-left: 1px solid #ebeef5;
  text-indent: 24px;
  height: 48px;
  line-height: 48px;
  /* box-sizing: border-box; */
}
</style>