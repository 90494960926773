<template>
    <div class="main-div batch">
        <!-- FB账户批量操作审批列表 start -->
        <div v-show="showFlag==1">
            <p class="main-title" style="margin-bottom: 32px">FB账户批量操作审批</p>
            <div>
                <div class="main-inputBox">
                    <el-input v-model="ajaxDataSearch.clientCompanyName" placeholder="请输入客户公司" clearable @change="enterSearch">
                    </el-input>
                </div>
                <div class="main-inputBox">
                    <el-input v-model="ajaxDataSearch.projectName" placeholder="请输入项目名称" clearable @change="enterSearch">
                    </el-input>
                </div>
                <div class="main-inputBox">
                    <el-input v-model="ajaxDataSearch.accountId" placeholder="请输入账户ID" clearable @change="enterSearch">
                    </el-input>
                </div>
                <div class="main-selectBox">
                    <el-select v-model="ajaxDataSearch.examineStatus" @change="enterSearch" clearable placeholder="审批状态">
                        <el-option
                                v-for="(v,k) in configurationData.batch_approval_status"
                                :key="k"
                                :label="v"
                                :value="k">
                        </el-option>
                    </el-select>
                </div>
                <div class="main-inputBox daterange date-picker-input-width">
                    <el-date-picker
                            v-model="ajaxDataSearch.applicationTime" @change="enterSearch()" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                            type="daterange" clearable start-placeholder="申请开始日期"
                            end-placeholder="结束日期"
                            placeholder="创建时间">
                    </el-date-picker>
                </div>
                <div class="main-inputBox daterange date-picker-input-width">
                    <el-date-picker
                            v-model="ajaxDataSearch.examineTime" @change="enterSearch()" @ value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                            type="daterange" clearable start-placeholder="审批开始日期"
                            end-placeholder="结束日期"
                            placeholder="创建时间">
                    </el-date-picker>
                </div>
            </div>
            <!-- 搜索项 end -->

            <!-- 列表 start -->
            <div v-loading="loading">
                <!-- stripe -->
                <el-table
                        :data="dataList"
                        border
                        style="width: 100%">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column
                            prop=""
                            label="序号"
                            type="index"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="clientCompanyName"
                            label="客户公司"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="projectName"
                            label="项目名称"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="accountCompanyName"
                            label="开户公司"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="accountId"
                            label="账户ID"
                            width="440">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :title="scope.row.accountId"> {{scope.row.accountId}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="审批状态"
                            width="120">
                        <template slot-scope="scope">
                            <span style="cursor: pointer" :class="scope.row.examineStatus | statusClassFilter"> {{configurationData.batch_approval_status[scope.row.examineStatus]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="applicant"
                            label="申请人"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="applicationTime"
                            label="申请时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="examineTime"
                            label="审批时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="操作"
                            min-width="200">
                        <template slot-scope="scope">
                            <span v-show="common.permFilter(`${common.java}batch/details`)" class="main-tableToInfo" @click="showFlag=2;examineDetail(scope.row.id)">详情</span>&nbsp;
                            <span v-show="scope.row.examineStatus==1 && common.permFilter(`${common.java}batch/details`)" class="main-tableToInfo" @click="showFlag=3;examineDetail(scope.row.id)">审批</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination-vue
                        @currentChange="init"
                        :pageSize="pageSize"
                        :total="total"
                        :firstPage="firstPage"
                />
            </div>
            <!-- 列表 end -->

        </div>
        <!-- FB账户批量操作审批列表 end -->


        <!-- FB账户批量操作审批详情 and FB账户批量操作审批 start-->
        <div v-show="showFlag==2 || showFlag==3">
            <!-- 列表 start -->
            <div>
                <el-table
                        :data="detailList.details"
                        border
                        style="width: 100%">
                    <template slot="empty">
                        <div class="nodata">
                            <img src="@/assets/img/nodata.png">
                            <span>暂无数据</span>
                        </div>
                    </template>
                    <el-table-column
                            prop="accountCompanyName"
                            label="开户公司名称"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="accountId"
                            label="账号"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="accountCost"
                            label="已花费金额"
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="accountLimit"
                            label="上限"
                            width="70">
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="充值金额"
                            width="100">
                        <template slot-scope="scope">
                            <span v-show="scope.row.rechargeError" class="main-tableToInfo" @click="batchRetry('7',scope.row.id)">重试</span>
                            <span v-show="!scope.row.rechargeError" class="">{{scope.row.rechargeAmount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="绑定BM"
                            width="150"
                            show-overflow-tooltip>
                        <template slot-scope="scope">

                            <!-- icon 鼠标滑过图标时显示提示 显示提示 -->
                            <el-popover v-show="scope.row.addBmError" trigger="hover" placement="top">
                                <p>姓名: {{ scope.row.addBmError }}</p>
                                <i class="el-icon-question" slot="reference"></i>
                            </el-popover>

                            <span v-show="scope.row.addBmError" class="main-tableToInfo" @click="batchRetry('1',scope.row.id)">重试</span>
                            <span v-show="!scope.row.addBmError" class="">{{scope.row.addBmId}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="解绑BM"
                            width="150"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-show="scope.row.delBmError" class="main-tableToInfo" @click="batchRetry('2',scope.row.id)">重试</span>
                            <span v-show="!scope.row.delBmError" class="">{{scope.row.delBmId}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="绑定Pixel"
                            width="150"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-show="scope.row.addPixelError" class="main-tableToInfo" @click="batchRetry('3',scope.row.id)">重试</span>
                            <span v-show="!scope.row.addPixelError" class="">{{scope.row.addPixelId}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="解绑Pixel"
                            width="150"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-show="scope.row.delPixelError" class="main-tableToInfo" @click="batchRetry('4',scope.row.id)">重试</span>
                            <span v-show="!scope.row.delPixelError" class="">{{scope.row.delPixelId}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop=""
                            label="清零"
                            width="70">
                        <template slot-scope="scope">
                            <span v-show="scope.row.accountClearError" class="main-tableToInfo" @click="batchRetry('5',scope.row.id)">重试</span>
                            <span v-show="!scope.row.accountClearError && scope.row.accountClear==1" class="">是</span>
                            <span v-show="!scope.row.accountClearError && scope.row.accountClear==2" class="">否</span>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column-->
                    <!--                            prop="accountDeactivate"-->
                    <!--                            label="关停"-->
                    <!--                            width="70">-->
                    <!--                        <template slot-scope="scope">-->
                    <!--                            <span v-if="scope.row.accountDeactivate==1">是</span>-->
                    <!--                            <span v-if="scope.row.accountDeactivate==2">否</span>-->
                    <!--                        </template>-->
                    <!--                    </el-table-column>-->
                    <el-table-column
                            prop="remarks"
                            label="备注"
                            width="">
                    </el-table-column>
                </el-table>
            </div>
            <!-- 列表 end -->
        </div>
        <!-- FB账户批量操作审批详情 and FB账户批量操作审批 列表 end-->
        <section class="info-cell examin-history" style="width:100%;margin-top:16px" v-if="detailList.examineRecords && detailList.examineRecords.length>0">
            <label>申请历史:</label>
            <div style="display:block" class="history-wrap">
                <p class="history" v-for="item in detailList.examineRecords">
                    <i style="width:150px;display:inline-block">{{item.examineTime}}</i>
                    <i style="width:160px;display:inline-block">{{item.examineStatus | examineFilter}}</i>
                    <i>{{item.examineRemarks}}</i>
                </p>
            </div>
        </section>
        <div class="info-row-line" v-if="detailList.examineRecords && detailList.examineRecords.length>0"></div>
        <!-- FB账户批量操作审批详情  start-->
        <div v-show="showFlag==2">

            <div class="info-text calc-2w m_t">
                <div class="main-button" @click="showFlag=1">返回</div>
            </div>
        </div>
        <!-- FB账户批量操作审批详情 end-->

        <!-- FB账户批量操作审批  start-->
        <div v-show="showFlag==3">
            <div class="infoBox">
                <div class="info-row" style="display:inline-block;margin-top:24px">
                    <div class="info-cell info-cell-self" style="width:33%">
                        <p class="info-title t-4w" style="line-height:32px;">审批状态:</p>

                        <el-select
                                v-model="examineStatus"
                                clearable
                                placeholder="请选择审批状态"
                                @change="dealSelect"
                        >
                            <el-option
                                    v-for="(item,index) in configurationData.batch_approval_status"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                            ></el-option>
                        </el-select>
                    </div>

                    <div class="info-cell w-33  info-cell-self" style="width:66%">
                        <p class="info-title" style="width:50px;line-height:32px;">备注:</p>

                        <el-input v-model="examineRemarks" clearable placeholder="请输入备注" style="width:calc(100% - 50px)">
                        </el-input>
                    </div>

                </div>

                <div class="info-row">
                    <div class="info-text calc-2w">
                        <div class="main-button" @click="showFlag=1">返回</div>
                        <div class="main-button" @click="batchExamine()">确认</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FB账户批量操作审批  start-->

    </div>
</template>

<script>
    import paginationVue from '@/components/paginationVue.vue'
    import axios from 'axios';
    import store from '@/store/index.js';
    export default {
        name: '',
        components: {
            paginationVue
        },
        data () {
            return {
                firstPage:1,
                loading:true,
                timer:'',
                msg: '',
                pageSize: 15,
                total: 100,
                showFlag: 1,
                examineId: '',
                examineStatus: '',
                examineRemarks: '',
                ajaxDataSearch: {
                    clientCompanyName: '',//客户公司名称
                    projectName: '',//项目名称
                    accountId:'',//账户ID
                    examineStatus: '', //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
                    applicationTime: ['', ''],//申请时间（格式：2020/04/01）
                    examineTime: ['', ''] //	审批时间（格式：2020/04/01）
                },
                dataList: [],
                //申请审批 和 申请详情 列表页面数据
                detailList: '',

                configurationData: {
                    batch_approval_status: {}
                }
            }
        },
        created(){
            axios.defaults.headers.common['language'] = store.state.lang;
            axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;

            this.axios('POST', `${this.$phpBaseURL}v1/config/datas`, {}).then(res => {
                if (res.code == 1) {
                    this.configurationData = res.data;
                    this.init();
                    this.timer = setTimeout(this.addStatistics, 5000);

                } else if(res.code == 2){
                    //token过期处理
                    this.common.tokenExpired(res.msg);
                } else {
                    this.$message({message: res.msg, type: 'warning'});
                }
            });


            //点击回车进行搜索
            let that=this;
            document.onkeypress = function(e) {
                var keycode = document.all ? event.keyCode : e.which;
                if (keycode == 13) {
                    that.init();
                    return false;
                }
            };
        },
        filters: {
            statusClassFilter(value) {
                // 审批状态:1 待审核,2 通过,3 驳回,4 操作失败 5部分成功
                let m = '';
                switch (value) {
                    case 1:
                        m = 'shenpi-daishenhe'
                        break;
                    case 2:
                        m = 'shenpi-tongguo'
                        break;
                    case 3:
                        m = 'shenpi-bohui'
                        break;
                    case 4:
                        m = 'shenpi-yiguoqi'
                        break;
                    case 5:
                        m = 'shenpi-yikaihu'
                        break;
                    default:
                        break;
                }
                return m
            },
            examineFilter(val) {
                // 审批状态:1 待审核,2 通过,3 驳回,4 操作失败 5部分成功
                let text = "";
                switch (val) {
                    case 1:
                        text = "待审核";
                        break;
                    case 2:
                        text = "审核通过";
                        break;
                    case 3:
                        text = "驳回";
                        break;
                    case 4:
                        text = "操作失败";
                        break;
                    case 5:
                        text = "部分成功";
                        break;
                        break;
                }
                return text;
            }
        },
        methods: {
            init(page){
                this.loading=true;
                this.firstPage=page;
                clearTimeout(this.timer);

                // axios.defaults.headers.common['language'] = store.state.lang;
                // axios.defaults.headers.common['token'] = JSON.parse(localStorage.getItem('user_info')).token;

                var startDate_sq='';
                var endDate_sq='';
                if(this.ajaxDataSearch.applicationTime) {
                    startDate_sq=this.ajaxDataSearch.applicationTime[0];
                    endDate_sq=this.ajaxDataSearch.applicationTime[1];
                }

                var startDate='';
                var endDate='';
                if(this.ajaxDataSearch.examineTime) {
                    startDate=this.ajaxDataSearch.examineTime[0];
                    endDate=this.ajaxDataSearch.examineTime[1];
                }
                this.axios('get', `${this.$javaBaseURL}batch/list`, {
                    clientCompanyName: this.ajaxDataSearch.clientCompanyName,//客户公司名称
                    projectName: this.ajaxDataSearch.projectName,//项目名称名称
                    accountId: this.ajaxDataSearch.accountId, //账户ID
                    examineStatus: this.ajaxDataSearch.examineStatus, //审批状态:1 待审核,2 通过,3 驳回,4 操作失败
                    applicationTimeStart: startDate_sq,//申请时间（格式：2020/04/01）
                    applicationTimeEnd: endDate_sq,//申请时间（格式：2020/04/01）
                    examineTimeStart: startDate, //	审批时间（格式：2020/04/01）
                    examineTimeEnd: endDate, //	审批时间（格式：2020/04/01）
                    pageNum: page ? page : 1,
                    pageSize:15
                }).then(res => {
                    this.loading=false;
                    if (res.code == 1) {
                        this.dataList = res.data.list;
                        this.total = res.data.total;
                        this.pageSize = res.data.pageSize;
                    } else if(res.code == 20001){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: 'warning'});
                    }
                });

            },
            enterSearch(){
                this.firstPage=1;
                if (this.ajaxDataSearch.applicationTime == null) {
                    this.ajaxDataSearch.applicationTime = ['', '']
                }
                if (this.ajaxDataSearch.examineTime == null) {
                    this.ajaxDataSearch.examineTime = ['', '']
                }
                this.init();
            },

            //FB账户批量操作审批提交
            batchExamine() {
                if(!this.examineStatus){
                    this.$message({message:'请选择审批状态', type: "warning"});
                    return false
                }
                var req_api = `batch/examine?id=${this.examineId}&examineStatus=${this.examineStatus}&examineRemarks=${this.examineRemarks}`;

                this.axios("POST", `${this.$javaBaseURL}${req_api}`, {}).then(res => {
                    if(res.code == 1) {
                        this.showFlag=1;
                        this.common.statisticsAdd('批量操作审批','2');

                    } else if(res.code == 20001){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: "warning"});

                    }

                    this.init();
                });
            },

            //处理下拉框数据 审批状态
            dealSelect(selVal) {
                this.examineStatus = selVal;


            },
            //获取 详情和审核页面列表数据
            examineDetail(examineId) {
                // console.log(examineId);
                this.examineId = examineId;

                var req_api = "batch/details";
                var req_data = {
                    id: this.examineId
                };
                this.axios("GET", `${this.$javaBaseURL}${req_api}`, req_data).then(res => {
                    console.log(res.data);

                    if (res.code == 1) {
                        this.detailList = res.data;
                    } else if(res.code == 20001){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else {
                        this.$message({message: res.msg, type: "warning"});
                    }
                    return false;
                });

            },
            //详情列表中的重试
            batchRetry(retryType, detailId) {

                var req_api = `batch/retry?id=${detailId}&type=${retryType}`;

                this.axios("POST", `${this.$javaBaseURL}${req_api}`, {}).then(res => {
                    // console.log(res.data);

                    if (res.code == 1) {
                        this.common.statisticsAdd('批量操作详情--重试','2');

                    } else if(res.code == 20001){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    } else{
                        this.$message({message: res.msg, type: "warning"});

                    }
                    this.examineDetail(this.examineId);

                });
            },

            //频次统计
            addStatistics() {
                this.value ++;
                this.common.statisticsAdd('FB账户批量操作审批列表','1')
            },
        },
        //mounted 挂载结束状态
        mounted() {
        },

        //beforeDestroy 销毁前状态
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>
<style scoped lang="scss">
    .m_l {
        margin-left: 15px;
    }

    .m_t {
        margin-top: 10px;
    }
</style>
<style lang="scss">
    .batch {
        .info-cell-self {
            .el-select {
                width: auto;
            }
            .el-input__inner {
                height: 32px;
                line-height: 32px;
            }
            .el-input__suffix {
                top: 4px
            }
        }
    }
</style>