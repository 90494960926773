<template>
  <div class="main-div">
    <div v-show="showFlag==1">
      <p class="main-title">采购需求申请列表</p>
      <div>
        <!--
                    project_name	否	string	项目
                    project_progress	否	int	项目进展1：客户询价阶段2：客户确认开始执行
                    purchase_type	否	int	采购类型:1-单一来源采购，2-直接采购，3-多方比价，4-招标
                    status	否	int	项目采购状态:1-紧急采购，2-非紧急采购
                    check_status	否	int	审核状态1：待审核2：审核通过3：审核失败
                    service_type	否	string	服务类型
        -->
        <div class="main-inputBox">
          <el-input
            v-model="ajaxDataSearch.project_name"
            placeholder="请输入项目名称"
            clearable
            @change="enterSearch"
          ></el-input>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.project_progress"
            @change="enterSearch"
            clearable
            placeholder="项目进展"
          >
            <el-option v-for="(v,k) in project_progressOptions" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.purchase_type"
            @change="enterSearch"
            clearable
            placeholder="采购类型"
          >
            <el-option v-for="(v,k) in purchase_typeOptions" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.status"
            @change="enterSearch"
            clearable
            placeholder="项目采购状态"
          >
            <el-option v-for="(v,k) in statusOptions" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.check_status"
            @change="enterSearch"
            clearable
            placeholder="审批状态"
          >
            <el-option v-for="(v,k) in check_statusOptions" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </div>
        <div class="main-selectBox">
          <el-select
            v-model="ajaxDataSearch.service_type"
            @change="enterSearch"
            clearable
            placeholder="服务类型"
          >
            <el-option
              v-for="(v,k) in configurationData.service_type_array"
              :key="v.recid"
              :label="v.st_name"
              :value="v.recid"
            ></el-option>
          </el-select>
        </div>
        <div class="main-inputBox daterange" style="width: 255px!important;">
          <el-date-picker
            v-model="ajaxDataSearch.contract_created_at"
            @change="enterSearch()"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="执行开始日期"
            clearable
            end-placeholder="结束日期"
            placeholder="创建时间"
          ></el-date-picker>
        </div>
      </div>
      <div style="margin-bottom: 32px">
        <div
          class="main-button"
          v-show="common.permFilter(`${common.php}v1/suppliers/getNewPurchaseRequisition`)"
          @click="common.openEip('suppliers/getNewPurchaseRequisition')"
        >新建采购申请</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/suppliers/getPurchaseRequisitionApproval`)"
          @click="common.openEip('suppliers/getPurchaseRequisitionApproval')"
        >采购申请审批</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/suppliers/getSupplierOption`)"
          @click="common.openEip('suppliers/getSupplierOption')"
        >供应商选择</div>
        <div
          class="main-button main-button-first"
          v-show="common.permFilter(`${common.php}v1/suppliers/getSupplierSelectionApproval`)"
          @click="common.openEip('suppliers/getSupplierSelectionApproval')"
        >供应商选择审批</div>
      </div>
      <div v-loading="loading">
        <el-table :data="list" border height="550" style="width: 100%">
          <template slot="empty">
            <div class="nodata">
              <img src="@/assets/img/nodata.png">
              <span>暂无数据</span>
            </div>
          </template>
          <el-table-column prop label="项目号" width>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.id">{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目名称" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_name"
              >{{scope.row.project_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="采购申请单单号" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.purchase_sn">{{scope.row.purchase_sn}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目进展" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_progress_name"
              >{{scope.row.project_progress_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目业务类型" width="150">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_service_type"
              >{{scope.row.project_service_type}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="采购类型" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.purchase_type_name"
              >{{scope.row.purchase_type_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="供应商来源" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.supplier_from"
              >{{scope.row.supplier_from}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目采购状态" width="150">
            <template slot-scope="scope">
              <span style="cursor: pointer" :title="scope.row.status_name">{{scope.row.status_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="审批状态" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :class="scope.row.check_status | statusClassFilter"
                :title="scope.row.check_status"
              >{{scope.row.check_status | checkStatus}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目执行开始时间" width="150">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_start_time"
              >{{scope.row.project_start_time}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="项目执行结束时间" width="150">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.project_end_time"
              >{{scope.row.project_end_time}}</span>
            </template>
          </el-table-column>
          <el-table-column prop label="服务类型" width="120">
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                :title="scope.row.service_type_name"
              >{{scope.row.service_type_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="budget" label="预算金额" width="120"></el-table-column>
          <el-table-column prop label="操作" width="260">
            <template slot-scope="scope">
              <span
                class="main-tableToInfo"
                v-show="common.permFilter(`${common.php}v1/suppliers/purchaseApplysInfo`)"
                @click="showFlag=2;getInfo(scope.row.id)"
              >采购申请详情</span>
              <div class="main-20px"></div>
              <span
                class="main-tableToInfo"
                v-show="common.permFilter(`${common.php}v1/suppliers/supplierSelect`)"
                @click="getInfoCheck(scope.row.purchase_sn)"
              >供应商选择详情</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination-vue
          @currentSizeChange="sizeInit"
          @currentChange="init"
          :pageSize="pageSize"
          :total="total"
          :firstPage="firstPage"
        />
      </div>
    </div>
    <!--采购申请详情-->
    <div v-show="showFlag==2">
      <p class="main-title" style>采购申请详情</p>
      <p class="main-title" style="display: inline-block">申请部门：{{info.department_name}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">申请人：{{info.username}}</p>
      <div class="main-20px"></div>
      <div class="main-20px"></div>
      <p class="main-title" style="display: inline-block">创建时间：{{info.created_at}}</p>
      <div class="main-title_1">基本信息</div>
      <div class="infoBox">
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-3w">项目号:</p>
            <div class="info-text calc-3w">{{info.project_sn}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">项目名称:</p>
            <div class="info-text calc-4w">{{info.project_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">客户:</p>
            <div class="info-text calc-2w">{{info.client_company_name}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">项目进展:</p>
            <div class="info-text calc-4w">{{info.project_progress_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-4w">采购类型:</p>
            <div class="info-text calc-4w">{{info.purchase_type_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-8w">单一来源采购原因:</p>
            <div class="info-text calc-8w">{{info.reason}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">外包原因详述:</p>
            <div class="info-text calc-6w">{{info.reasons_for_out}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">推荐供应商名称:</p>
            <div class="info-text calc-7w">{{info.rec_supplier_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">推荐供应商原因:</p>
            <div class="info-text calc-7w">{{info.rec_supplier_reason}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-6w">项目采购状态:</p>
            <div class="info-text calc-6w">{{info.status_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-8w">项目执行开始时间:</p>
            <div class="info-text calc-8w">{{info.project_start_time}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-8w">项目执行结束时间:</p>
            <div class="info-text calc-8w">{{info.project_end_time}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">服务类型:</p>
            <div class="info-text calc-4w">{{info.service_type_name}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-2w">预算:</p>
            <div class="info-text calc-2w">{{info.budget}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-7w">项目背景&内容:</p>
            <div class="info-text calc-7w">{{info.project_content}}</div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-cell w-33">
            <p class="info-title t-4w">投放平台:</p>
            <div class="info-text calc-4w">{{info.platform}}</div>
          </div>
          <div class="info-cell w-33">
            <p class="info-title t-6w">具体需求内容:</p>
            <div class="info-text calc-6w">{{info.specific_demand}}</div>
          </div>
        </div>
      </div>
      <div class="infoBox">
        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-6w">附件:</p>
            <div class="info-text calc-6w">
              <el-table :data="info.demands" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="180"></el-table-column>
                <el-table-column prop="file_name" label="文件" width></el-table-column>
                <el-table-column prop="e" label="文件大小" width="120">
                  <template
                    slot-scope="scope"
                    v-if="scope.row.file_size"
                  >{{(scope.row.file_size / 1024 / 1024).toFixed(2)}}MB</template>
                  <template v-else>-</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="infoBox">
        <div class="info-row">
          <div class="info-row">
            <div class="info-cell w-80">
              <p class="info-title t-2w">备注:</p>
              <div class="info-text calc-2w">{{info.remark}}</div>
            </div>
          </div>
        </div>

        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <!--<p class="info-title t-6w"></p>-->
            <div class="info-text calc-6w">
              <div
                class="main-button"
                v-show="common.permFilter(`${common.php}v1/suppliers/purchaseApplysDownload`)"
                @click="downloadfile()"
              >下载</div>
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--供应商选择详情-->
    <div v-show="showFlag==3">
      <p class="main-title" style>供应商选择详情</p>
      <!--<div class="main-title_1">供应商信息</div>-->
      <div class="infoBox">
        <div class="info-row" style="margin-bottom: 24px">
          <div class="info-cell w-100">
            <p class="info-title t-5w">供应商信息:</p>
            <div class="info-text calc-5w">
              <el-table border :data="infoCheck" style="width: 100%">
                <template slot="empty">
                  <div class="nodata">
                    <img src="@/assets/img/nodata.png">
                    <span>暂无数据</span>
                  </div>
                </template>
                <el-table-column prop label="序号" type="index" width="80"></el-table-column>
                <el-table-column label="选中状态" width="100">
                  <template slot-scope="scope">
                    <img v-if="scope.row.gx==1" src="@/assets/img/select.png" style="width:20px">
                    <img v-if="scope.row.gx==0" src="@/assets/img/noselect.png" style="width:20px">
                  </template>
                </el-table-column>
                <el-table-column prop label="采购申请单单号" width="250">
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.purchase_sn"
                    >{{scope.row.purchase_sn}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop label="项目业务类型" width="180">
                  <template slot-scope="scope">
                    <div v-for="v in configurationData.business_type_array">
                      <span
                        v-if="v.recid == scope.row.business_type"
                        style="cursor: pointer"
                        :title="v.st_name"
                      >{{v.st_name}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop label="供应商来源" width="180">
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.supplier_source_name"
                    >{{scope.row.supplier_source_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop label="采购类型" width="150">
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.purchase_type_name"
                    >{{scope.row.purchase_type_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop label="供应商名称" width="250">
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.supplier_name"
                    >{{scope.row.supplier_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="supplier_level_id" label="供应商级别" width="150">
                  <template slot-scope="scope">
                    <div v-for="v in configurationData.supplier_level_array">
                      <span
                        v-if="v.recid == scope.row.supplier_level_id"
                        style="cursor: pointer"
                        :title="v.st_name"
                      >{{v.st_name}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop label="服务类型" width="150">
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.service_type_name"
                    >{{scope.row.service_type_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop label="采购金额" width="150">
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.purchase_amount"
                    >{{scope.row.purchase_amount}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop label="税率" width>
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      :title="scope.row.invoice_taxrate_name"
                    >{{scope.row.invoice_taxrate_name}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop label="付款周期" width="150">
                  <template slot-scope="scope">
                    <div v-for="v in configurationData.payment_period_list" :key="v.recid">
                      <span
                        v-if="v.recid == scope.row.payment_cycle"
                        style="cursor: pointer"
                        :title="v.st_name"
                      >{{v.st_name}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop label="预付比例" width="150">
                  <template slot-scope="scope">
                    <div v-for="v in configurationData.advance_ratio_list" :key="v.recid">
                      <span
                        v-if="v.recid == scope.row.prepay_proportion"
                        style="cursor: pointer"
                        :title="v.st_name"
                      >{{v.st_name}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" width="150"></el-table-column>
                <el-table-column prop="final_score" label="招标最终评分" width="150"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>

      <div class="infoBox">
        <div class="info-row" style="margin-top: 8px;margin-bottom: 0px">
          <div class="info-cell w-80">
            <div class="info-text calc-6w">
              <div class="main-button" @click="showFlag=1">返回</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import  from '';*/
import paginationVue from "@/components/paginationVue.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  name: "",
  components: {
    paginationVue
  },
  data() {
    return {
      firstPage: 1,
      loading: true,
      timer: "",
      msg: "",
      showFlag: 1,
      pageSize: 10,
      total: 1,
      ajaxDataSearch: {
        project_name: "", //	否	string	项目
        project_progress: "", //	否	int	项目进展1：客户询价阶段2：客户确认开始执行
        purchase_type: "", //	否	int	采购类型:1-单一来源采购，2-直接采购，3-多方比价，4-招标
        status: "", //	否	int	项目采购状态:1-紧急采购，2-非紧急采购
        check_status: "", //	否	int	审核状态1：待审核2：审核通过3：审核失败
        service_type: "", //	否	string	服务类型
        contract_created_at: ["", ""] //	否	string
      },

      /*
                * project_progress	否	int	项目进展1：客户询价阶段2：客户确认开始执行
                 purchase_type	否	int	采购类型:1-单一来源采购，2-直接采购，3-多方比价，4-招标
                 status	否	int	项目采购状态:1-紧急采购，2-非紧急采购
                 check_status	否	int	审核状态1：待审核2：审核通过3：审核失败
                * */

      project_progressOptions: {
        1: "客户询价阶段",
        2: "客户确认开始执行"
      },
      purchase_typeOptions: {
        1: "单一来源采购",
        2: "直接采购",
        3: "多方比价",
        4: "招标"
      },
      statusOptions: {
        1: "紧急采购",
        2: "非紧急采购"
      },
      check_statusOptions: {
        0: "未提交",
        1: "待审核",
        2: "待审核",
        5: "驳回未提交"
      },

      list: [
        {
          id: 4,
          project_name: "李宁超轻16视频改尺寸",
          purchase_sn: "CGSQD022201202000130",
          project_progress: "710BD21280000001D295FB6EF947F0D7",
          project_progress_name: "客户确认开始执行",
          project_service_type: "7128FBAE200000018A2FD8F5860434C6",
          purchase_type: "710BD025E0000001572B8E91DC20EB80",
          purchase_type_name: "单一来源采购",
          supplier_from: "7129047A800000017B3CFD4E75948284",
          status: "710BD33F80000001BE3EB3EE5DED4821",
          project_start_time: "2020-04-15 00:00:00",
          project_end_time: "2020-04-11 00:00:00",
          service_type: "710A56AE800000011D6BD3C872AE033C",
          service_type_name: "视频制作",
          budget: "50000"
        }
      ],
      info: {
        id: 5,
        recid: "71CB236AC00000016C248C027A5A9D07",
        dept_id: "6F666DF4C00000017E45C85CF11A6756",
        applicant: "5AB1EE2680000001D05C302118DAFDCC",
        purchase_sn: "CGSQD020227202000150",
        project_sn: "2",
        project_id: "6371F51A400000019AEAF9EF544E1368",
        client_company_id: "",
        project_progress: "710BD21280000001D295FB6EF947F0D7",
        purchase_type: "710BD096C0000001EFCCBE28F635E22A",
        reason: "222",
        status: "710BD36B2000000112EBC932B0BCE394",
        project_start_time: "2020-05-05 00:00:00",
        project_end_time: "2020-05-06 00:00:00",
        service_type: "710A51A780000001F5504D90905B142A",
        budget: "100",
        project_content: "222",
        platform: "222",
        specific_demand: "222",
        remark: "222",
        created_at: "2020-05-14 12:28:23",
        updated_at: "2020-05-14 12:28:23",
        project_service_type: "7128FA71400000014DE80E3335C0D9F0",
        supplier_from: null,
        check_status: null,
        project_name: null,
        username: "teng.teng",
        department_name: "数传国内供应商管理部",
        project_progress_name: "客户确认开始执行",
        purchase_type_name: "直接采购",
        service_type_name: "配音",
        demands: [
          {
            id: 24,
            purchase_apply_id: "71CB236AC00000016C248C027A5A9D07",
            accessory_id: "6A1237F804653204B53F13407F9AA30E",
            file_name: "[2020]016 关于调整午休时间的通知(2020-04-30).pdf",
            file_size: null,
            created_at: "2020-05-14 12:28:23",
            updated_at: null
          },
          {
            id: 25,
            purchase_apply_id: "71CB236AC00000016C248C027A5A9D07",
            accessory_id: "3817D30AEC2D3F0E1BF958E10D224546",
            file_name: "[2020]016 关于调整午休时间的通知(2020-04-30).pdf",
            file_size: null,
            created_at: "2020-05-14 12:28:23",
            updated_at: null
          },
          {
            id: 26,
            purchase_apply_id: "71CB236AC00000016C248C027A5A9D07",
            accessory_id: "E21B1BDFCFD00E52C190766DB17814DC",
            file_name: "[2020]016 关于调整午休时间的通知(2020-04-30).pdf",
            file_size: null,
            created_at: "2020-05-14 12:28:23",
            updated_at: null
          }
        ]
      },
      /*返回客户管理下搜索项用到的数据接口*/
      configurationData: {
        receivable_client_status: {
          /*"1": "一般客户",
                         "2": "异常客户",
                         "3": "坏账客户"*/
        },
        is_overdue_config: {
          /*"1": "是",
                         "2": "否"*/
        },
        client_status_array: {
          /*"1": "未提交",
                         "2": "待审批",
                         "3": "审核通过",
                         "4": "驳回",
                         "5": "部分通过",
                         "6": "驳回待提交"*/
        },
        client_source_array: {
          /*"1": "自主开发",
                         "2": "公司内推",
                         "3": "市场宣传"*/
        },
        client_connection_relation_array: {
          /*"1": "母子公司",
                         "2": "子公司之间",
                         "3": "分公司之间"*/
        },
        client_relation_company_array: {
          /* "1": "拥有者",
                         "2": "共享者"*/
        },
        is_contain_config: {
          /*"1": "有",
                         "2": "无"*/
        },
        platform_array: {
          /*"1": "facebook",
                         "2": "twitter",
                         "3": "google",
                         "5": "tiktok"*/
        },
        contact_status_array: {
          /*"1": "审批通过",
                         "2": "审批失败"*/
        },
        gender_array: {
          /*"1": "男",
                         "2": "女"*/
        },
        contract_status_array: {
          /*"1": "待审核",
                         "2": "审核中",
                         "3": "审核通过",
                         "4": "审核失败",
                         "5": "已过期",
                         "6": "已作废"*/
        },
        business_type_array: {
          /*"1": "整合营销",
                         "2": "媒介投放",
                         "3": "技术产品"*/
        },
        contract_type_array: {
          /*"1": "销售合同",
                         "2": "采购合同",
                         "3": "其他合同"*/
        },
        contract_subdivision_array: {
          /*"1": "普通合同",
                         "2": "框架合同",
                         "3": "订单合同",
                         "4": "补充协议",
                         "5": "合作证明材料"*/
        },
        is_guarantee_archive_prepaid_array: {
          /*"1": "是",
                         "2": "否"*/
        },
        project_status_array: {
          /*"1": "正常",
                         "2": "暂停",
                         "3": "延期",
                         "4": "取消"*/
        },
        project_approval_status: {
          /*"1": "待审批",
                         "2": "审批通过",
                         "3": "审批失败"*/
        },
        file_upload_status: {
          /*"1": "上传成功",
                         "2": "上传失败"*/
        },
        platform_name_array: {
          /*"1": "Facebook",
                         "2": "Twitter",
                         "3": "Google",
                         "5": "今日头条"*/
        },
        return_point_ratio_accuracy_array: {
          /*"1": "预估",
                         "2": "确认"*/
        },
        departments_data: [
          /*{
                         "department_id": "5238BEB540000001A6E7CC8373C750AE",
                         "department_name": "传统总经办"
                         }*/
        ],
        industry_data: [
          /*{
                         "industry_id": "7139BF82C0000001B65DEED0E458F302",
                         "industry_name": "银行、保险公司、证券公司、期货公司、基金公司、信托公司、投资公司等金融机构等"
                         }*/
        ]
      },
      infoCheck: [
        {
         
        }
      ],
      fileList: []
    };
  },

  created() {
    /*返回客户管理下搜索项用到的数据接口*/
    axios.defaults.headers.common["language"] = store.state.lang;
    axios.defaults.headers.common["token"] = JSON.parse(
      localStorage.getItem("user_info")
    ).token;
    this.axios("POST", `${this.$phpBaseURL}v1/config/datas`, {
      type: "supplier_detail_list"
    }).then(res => {
      //                console.log(res.data)
      if (res.code == 1) {
        this.configurationData = res.data;
        this.init();
        this.timer = setTimeout(this.addStatistics, 5000);
      } else if (res.code == 2) {
        //token过期处理
        this.common.tokenExpired(res.msg);
      } else {
        this.$message({ message: res.msg, type: "warning" });
      }
    });

    //点击回车进行搜索
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.init();
        return false;
      }
    };
  },
  filters: {
    checkStatus(value) {
      //0未提交，1待审批，2审核通过，5驳回待提交'
      let text = "";
      switch (value) {
        case 0:
          text = "未提交";
          break;
        case 1:
          text = "待审核";
          break;
        case 2:
          text = "审核通过";
          break;
        case 5:
          text = "驳回待提交";
          break;
        default:
          break;
      }
      return text;
    },
    statusClassFilter(value) {
      let m = "";
      switch (value) {
        case 0:
          m = "shenpi-weitijiao";
          break;
        case 1:
          m = "shenpi-daishenhe";
          break;
        case 2:
          m = "shenpi-tongguo";
          break;
        case 5:
          m = "shenpi-bohui";
          break;
        default:
          break;
      }
      return m;
    }
  },

  methods: {
      sizeInit(size){
          this.pageSize = size ? size : 15
          this.init(1)
      },
    init(page) {
      this.loading = true;
      this.firstPage = page;
      clearTimeout(this.timer);

      var startDate = "";
      var endDate = "";
      if (this.ajaxDataSearch.contract_created_at) {
        startDate = this.ajaxDataSearch.contract_created_at[0];
        endDate = this.ajaxDataSearch.contract_created_at[1];
      }
      this.axios("get", `${this.$phpBaseURL}v1/suppliers/purchaseApplysList`, {
        project_name: this.ajaxDataSearch.project_name, //	否	string	项目
        project_progress: this.ajaxDataSearch.project_progress, //	否	int	项目进展1：客户询价阶段2：客户确认开始执行
        purchase_type: this.ajaxDataSearch.purchase_type, //	否	int	采购类型:1-单一来源采购，2-直接采购，3-多方比价，4-招标
        status: this.ajaxDataSearch.status, //	否	int	项目采购状态:1-紧急采购，2-非紧急采购
        check_status: this.ajaxDataSearch.check_status, //	否	int	审核状态1：待审核2：审核通过3：审核失败
        service_type: this.ajaxDataSearch.service_type, //	否	string	服务类型
        start_date: startDate, //	否	string	创建开始日期
        end_date: endDate, //	否	string	创建结束日期
        /*offset:page?(page-1)*this.pageSize:0,//	否	int	获取数据开始位置，默认0
                    limit:this.pageSize,//否	int	每次获取条数，默认10*/
        page: page ? page : 1,
        page_num : this.pageSize
      }).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.data;
          this.total = res.data.total;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    enterSearch() {
      this.firstPage = 1;
      if (this.ajaxDataSearch.contract_created_at == null) {
        this.ajaxDataSearch.contract_created_at = ["", ""];
      }
      this.init();
    },
    /*采购申请详情*/
    getInfo(id) {
      this.axios("get", `${this.$phpBaseURL}v1/suppliers/purchaseApplysInfo`, {
        id: id
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data;
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    /*供应商选择详情*/
    getInfoCheck(id) {
      this.axios("get", `${this.$phpBaseURL}v1/suppliers/supplierSelect`, {
        purchase_sn: id
      }).then(res => {
        if (res.code == 1) {
          this.showFlag = 3;
          this.infoCheck = res.data.list; //供应商信息列表数据
          // this.fileList=res.data.supplier_files_list;//供应商附件列表数据
        } else if (res.code == 2) {
          //token过期处理
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({ message: res.msg, type: "warning" });
        }
      });
    },
    //采购附件下载
    downloadfile() {
      let token = JSON.parse(localStorage.getItem("user_info")).token;
      let url = `${this.$phpBaseURL}v1/suppliers/purchaseApplysDownload?token=${token}&purchase_apply_id=${this.info.recid}`;
      window.open(url, "_blank");
    },
    //频次统计
    addStatistics() {
      this.value++;
      this.common.statisticsAdd("采购需求申请列表", "1");
    }
  },
  //mounted 挂载结束状态
  mounted() {},

  //beforeDestroy 销毁前状态
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style> 
